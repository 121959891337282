import React, { Component, useRef } from 'react'
//import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
//import { Document, pdfjs } from 'react-pdf'
import { Document } from 'react-pdf'
//import { Document } from 'react-pdf/dist/umd/entry.parcel';
//import { Document } from 'react-pdf/dist/esm/entry.webpack'
import { pdfjs } from 'react-pdf';
import {
    getAnnotationsByClientId,
    apiGetAllCanvasPoints,
    apiUpdateCanvasPoints,
    getPreviewRedactingSarDocByThumbnailId,
    saveRedactedDocument, getDocNotes
} from '../../../api/Sar'
import '../../../style/common.scss'
import '../../Osp/Users/InviteUser/InviteUser.scss'
import './RedactionTool.scss'
import * as html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import CanvasPage from './CanvasPage'
import CanvasPageCopy from './CanvasPageCopy'
import { Spin, Select, Affix, Drawer } from 'antd'
import { groupArrayBy } from '../../../utils'
import Icon from 'antd/es/icon'
import Search from 'antd/es/input/Search'
//import ButtonGroup from 'antd/es/button/button-group'
import Button from '../../Common/Button'
import swal from 'sweetalert2'
import Mark from 'mark.js'
import DocumentNote from '../Tracker/DocumentNote/DocumentNote'
import MetadataPage from './MetadataPage'
import AnnotateSelectedRedaction from './AnnotateSelectedRedaction'
import RedactedSidebar from '../RedactedSidebar'
import CommentsSidebar from './CommentsSidebar'
import PageSidebarContent from './PageSidebarContent'
import SearchImagesContent from './SearchImagesContent'

import AiAnnotationsSidebar from './AiAnnotationsSidebar'
import AiAnnotationsSidebarCopy from './AiAnnotationsSidebarCopy'
import SpellCheckerPage from './SpellCheckerPage'
import {
    apiSmartBoxGetAllCanvasPoints,
    apiSmartBoxGetAnnotationsByClientId,
    apiSmartBoxGetNewQueryStatus,
    apiSmartBoxGetPreviewRedactingSarDocByThumbnailId,
    apiSmartBoxUpdateCanvasPoints,
    getIdFinaliseDocument,
    apiSmartBoxClearAllKeysOnPage,
    getRedactedKeys,
    apiSmartBoxClearCanvasPoints,
    setFileStatusOpenSmartView,
    apiSmartBoxUpdateCanvasPointsSaved,
    setClassificationStatusOpenSmartView,
    apiSmartBoxRemoveCanvas,
    apiSmartBoxGetPageCanvasPoints,
    apiSmartBoxAddTermToSearchHistory,
    apiGetSmartviewSearchHistoryTerms,
    apiSmartBoxSetDocumentRedactAllStatus,
    apiGetSetDocumentRedactAllStatus,
    apiRemoveAllSmartviewRedactions,
    apiSmartBoxSaveCanvasDocumentSmartview,
    apiGetTagDocumentClient,
    geRectanglesCoordsOcr,
    getRectanglesCoordsOcr,
    getRectanglesCoordsOcrPost,
    getSmartBoxDocNotes,
    apiSaveSelectedTextComment,
    apiUnlockSmartviewDocument,
    apiUpdateLockStatusSmartviewDocument,
    apiRedactionColourProfileGet,
    apiSetBoxRedactionColorProfile,
    apiRedactionColourProfileGetCategories,
    getSmartBoxScanAnnotationsCopy,
    apiSmartBoxSaveCanvasDocumentSmartviewMixed,
    getRedactedKeysMixed,
    apiSmartBoxSaveCanvasDocumentSmartviewMixed2,
    apiSmartBoxSaveQueryStatusMixed
} from '../../../api/SmartBox'
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import SearchHistory from "./SearchHistory";
import SearchHistoryCopy from "./SearchHistoryCopy";
//import ReviewPrevNextAiTag from "./ReviewPrevNextAiTag";
//import {timerCheckCanvas} from "../../../utils/SmartBox/SmartBoxMethods";
import cookie from "react-cookies";

const fabric = require('fabric').fabric

pdfjs.GlobalWorkerOptions.workerSrc = '/libs/pdfjs/build/pdf.worker.js'

const { Option } = Select
const Tools = {
    Circle: 'circle',
    Line: 'line',
    Pencil: 'pencil',
    Rectangle: 'rectangle',
    Select: 'select',
    Pan: 'pan'
}

export class RedactionToolCopy extends Component {

    settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        arrows: true,
        focusOnSelect: true
    }

    constructor(props) {
        super(props)
        this.myDiv = React.createRef()
        // this.isRunning = false
        this.state = {
            pdfBytes: '',
            file: null,
            numPages: 0,
            pageNumber: 1,
            fillColor: 'transparent',
            width: null,
            height: null,
            pages: [],
            canUndo: false,
            canRedo: false,
            divPrint: '',
            annotationText: '',
            canvasData: [],
            canvasDataHighlightingMixed: [],
            canvasDataCopy: [],
            canvasDataMixedOcr: [],
            canvasPoints: [],
            tool: Tools.Select,
            history: [],
            modal: false,
            removeSelectedBtnDisabled: true,
            sketch: false,
            forPageNumber: null,
            totalPages: null,
            totalThumbnails: null,
            selectedTool: 'select',
            isDocumentLoaded: false,
            loading: true,
            loadingBeforeMark: false,
            annotationList: {},
            clientId: props.clientId,
            documentId: props.documentId,
            haveChanges: false,
            landscape: null,
            finalizeLoading: false,
            aspectRatio: null,
            dbWidth: null,
            dbHeight: null,
            printAnnotation: true,
            selectionMode: false,
            redactingMode: true,
            btnClasses: {
                'undo': { 'class': 'btn-action mr-2', 'disabled': false },
                'select': { 'class': 'btn-action mr-2', 'disabled': false },
                'draw': { 'class': 'btn-action mr-2', 'disabled': false },
                'clear': { 'class': 'btn-action mr-2', 'disabled': false },
                'preview': { 'class': 'btn-action mr-2', 'disabled': false },
            },
            navigationType: 'find-asset',
            btnAnnotationActiveIndex: 0,
            showCloseButton: true,
            matchedText: [],
            searchIndex: -1,
            currentMarkedIndex: 0,
            renderSearch: !!props.searchedValue,
            top: 0,
            annotationListVisible: false,
            objectHasAnnotation: false,
            viewAnnotationsRedaction: false,
            renderSelectText: false,
            renderCommentsText: false,
            renderSelectDraw: false,
            renderSelectTool: false,
            renderSelectToolMove: false,
            docAnnotations: [],
            searchValue: props.searchedValue,
            annotatedAiHTML: '',
            printLoading: false,
            disableClickOnPage: !props.searchedValue,
            documentNoteVisible: false,
            isDocumentReady: false,
            isSmartBoxApplication: props.isSmartBoxApplication,
            smartBoxDocumentId: props.smartBoxDocumentId,
            annotationSelectedItem: 0,
            itemsSelected: [],
            oldAnnotationSelectedItem: '',
            newRedactedLabel: '',
            idDoc: '',
            annotateSelectedModalVisible: false,
            numberOfAnnotateSelected: 0,
            colorPikerDisplay: false,
            colorSelected: { 'input': 'rgba(107, 91, 149, 1)', 'select': 'rgba(254, 178, 54, 1)' },
            annotateSelectBtnDisabled: true,
            annotateLabel: '',
            renderedPagesStatus: [],
            pageSidebarVisible: true,
            pageSidebarVisibleBefore: true,
            docAnnotationCategories: [],
            treeData: [],
            pagePointSumList: '',
            displaySearchButtons: !!props.searchedValue,
            alreadyRedactedKeys: [],
            alreadyRedactedList: [],
            treeDataAnnotations: [],
            newListKeysStandard: [],
            newListKeysManual: [],
            newListKeysBusiness: [],
            verificationOnClickClearAll: false,
            verificationOnClickClearAllManual: false,
            newListKeysRegEx: [],
            verificationOnClickClearAllRegEx: false,
            verificationOnClickClearAllBusiness: false,
            newListKeys: [],
            canvasCleaned: false,
            loadingProcess: false,
            loadingProcessMessage: 'Please wait until finalise process is finalised.',
            statusSelectAgainRedacted: '',
            statusKeysEmptyOnClearAll: false,
            valueStatusFile: 'File Status',
            statusSavedSmartView: false,
            drawByHandText: '!@#draw-by-hand#@!',
            emptiedPages: [],
            clearSketch: false,
            spanCoords: {},
            activeDocumentNote: false,
            activePrintDocument: false,
            activeClearPage: false,
            activeClearSelected: false,
            activeSearchWord: false,
            activeAnnotation: false,
            activeComments: false,
            activeSelectTool: false,
            activeSelectDraw: false,
            activeSelectText: false,
            activeUndo: false,
            activeRedo: false,
            treeDataSearchHistory: [],
            valueSelectNavigation: 'ai-results',
            isNextDocument: false,
            isNextDocumentId: {},
            disabledNextDocument: null,
            nextDocumentLength: 0,
            disabledBackDocument: null,
            checkStartRedactAllPagesNextFiles: false,
            checkStartRedactAllPagesBackFiles: false,
            valueStatusClassification: '',
            notifyNote: '',
            searchMatchedElement: [],
            searchMatchId: 0,
            checkObjectRefsStandard: [],
            statusSaveQuery: false,
            statusAfterRemove: false,
            activePreview: false,
            treeDataRegEx: [],
            treeDataDict: [],
            statusClickPageSidebar: '',
            visibleDropdownStatus: false,
            visibleDropdownNavigation: false,
            drawSelectedMouse: false,
            allRedactedKeys: [],
            checkStartRedaction: null,
            statusElement: '',
            sourceActive: '',
            stateSidebarPages: false,
            clearPageDisabled: false,
            statusLastPages: true,
            statusRedactAll: "false",
            extractPointsToSave: [],
            extractPageNumber: [],
            dtoRedactAll: [],
            statusCloseSmartviewRedactAll: false,
            statusNextDocumentBusiness: false,
            statusNextDocumentRegEx: false,
            statusNextDocumentStandard: false,
            statusDrawRedaction: false,
            selectTag: {},
            previewPageDisabled: false,
            dtoRedactWord: [],
            canvasDataOcrDrawBox: {},
            canvasDataOcrDownload: {},
            listAllDocAnnotationOcr: ['Dictionary', 'RegEx', 'doc_annotate_address_email', 'doc_annotate_address_url', 'doc_annotate_address_phone', 'doc_annotate_address_postcode',
                'doc_annotate_address_complete', 'doc_annotate_address_ip', 'doc_annotate_person_female', 'doc_annotate_person_male', 'doc_annotate_person', 'doc_annotate_identifier',
                'doc_annotate_password', 'doc_annotate_location_country', 'doc_annotate_location_city', 'doc_annotate_location_region', 'doc_annotate_location_airport',
                'doc_annotate_location_country_abbrev', 'doc_annotate_location_county', 'doc_annotate_location_province', 'doc_annotate_location_other', 'doc_annotate_location_pre',
                'doc_annotate_money', 'doc_annotate_percent', 'doc_annotate_ssn', 'doc_annotate_creditcard', 'doc_annotate_organization_company', 'doc_annotate_organization_department',
                'doc_annotate_organization_government', 'doc_annotate_organization_newspaper', 'doc_annotate_organization_university', 'doc_annotate_organization_team', 'doc_annotate_date'],
            allCategoryVisibleOcr: [],
            listSearchHistory: [],
            documentNoteGet: '',
            canvasDataComments: {},
            closeRedactingStatus: false,
            statusSaveCanvasDeleteSection: false,
            deleteSelectedRedactionBox: false,
            checkUpdateLockStatus: '',
            setIntervalUpdateLockStatus: 0,
            hasError: false,
            error: null,
            valueSelectProfile: null,
            listRedactionColour: [],
            listCategories: [],
            onSelectResetProfilesStatus: '',
            statusHideAllHighlightingMixed: true,
            startDrawRedactMatchedWordsMixed: false,
            pagesCountChildrenMixed: [],
            pagesCountChildrenMixedAll: [],
            statusSaveSmartviewMixed2: null,
            closeRedactingMixedStatus: false,
            getChildrenAllCategories: {
                childrenStandard: [],
                childrenStandardOcr: [],
                childrenBusiness: [],
                childrenBusinessOcr: [],
                childrenRegEx: [],
                childrenRegExOcr: [],
            }
        }
        this.next = this.next.bind(this)
        this.previous = this.previous.bind(this)
        this.onScanAnnotateClick = this.onScanAnnotateClick.bind(this)
    }

    componentWillUnmount() {
        this.props.setHeaderVisibility(true)
        this.props.setSidebarVisibility(true)
        this.props.setFooterVisibility(true)
        window.URL.revokeObjectURL(this.props.pdfBytes)
    }

    componentDidMount() {
        window.scrollTo(0,0)

        if (this.state.documentId) {
            const { setIntervalUpdateLockStatus } = this.state
            cookie.remove('checkLockStatusSmartviewDocument', { path: '/' })
            clearInterval(setIntervalUpdateLockStatus)
            this.setState({setIntervalUpdateLockStatus}, () => {
                this.onGetUpdateLockStatusSmartviewDocument(this.state.documentId)
            })
            if (!this.state.isSmartBoxApplication) {
                //console.log('R 11111111111111111111111111111')
                this.fetchAllData(this.state.documentId, this.state.clientId)
            } else {
                //console.log('R 222222222222222222222222222')
                // if (this.props.isOcr) {
                //     this.onGeRectanglesCoordsOcr(this.state.documentId, this.state.clientId)
                // }
                this.onPageRectangleMapCategoryMixed(this.state.documentId, this.state.clientId)
            }
            this.onGetDocumentNotes(this.state.documentId)
            // if (this.props.lockSmartViewChanges) {
            //   CustomNotificationManager.info('This document is already opened by another user, changes are disabled.', 'Info')
            // }
        }
        const { systemPropertyFileStatus, systemPropertyFileStatusColor, systemPropertyFileStatusIcon, boxRedactionColorProfileId } = this.props
        this.getTagDocumentClient()
        this.onGetRedactionColour()
        this.onRedactionColourProfileGetCategories()
        this.setState({
            valueStatusFile: systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
            valueSelectProfile: boxRedactionColorProfileId !== null || boxRedactionColorProfileId !== undefined ? boxRedactionColorProfileId : null,
            selectTag: {
                tagName: systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
                color: systemPropertyFileStatusColor === null ? null : systemPropertyFileStatusColor,
                icon: systemPropertyFileStatusIcon === null ? null : systemPropertyFileStatusIcon
            }
        })
        cookie.remove('sendNoteResponse', { path: '/' })
    }

    onPageRectangleMapCategoryMixed = async (documentId, clientId) => {
        //const { documentId, clientId } = this.props
        const { listAllDocAnnotationOcr, smartBoxDocumentId } = this.state
        let responseAnnotationsCopy
        const categories = null
         responseAnnotationsCopy = await getSmartBoxScanAnnotationsCopy(smartBoxDocumentId, categories)
        //console.log('responseAnnotationsCopy BBBBBBBBBBBBBBBB', responseAnnotationsCopy.data)
        if (responseAnnotationsCopy.data.messageTextResponseDTO  && responseAnnotationsCopy.data.messageTextResponseDTO.title && responseAnnotationsCopy.data.messageTextResponseDTO.title.toLowerCase() === 'success') {
            CustomNotificationManager.success(responseAnnotationsCopy.data.messageTextResponseDTO.message, 'Success')
        } else if (responseAnnotationsCopy.data.messageTextResponseDTO && responseAnnotationsCopy.data.messageTextResponseDTO.title && responseAnnotationsCopy.data.messageTextResponseDTO.title.toLowerCase() === 'info') {
            CustomNotificationManager.info(responseAnnotationsCopy.data.messageTextResponseDTO.message, 'Info')
        }
        const response2 = await apiSmartBoxGetPreviewRedactingSarDocByThumbnailId(documentId)
        const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
        const response1Ocr = responseAnnotationsCopy.data.pageRectangleMapCategoryOCR
        const response1OcrNew = responseAnnotationsCopy.data.pageRectangleMapCategoryOCR
        const response10 = await apiSmartBoxGetAllCanvasPoints(documentId)
        //const response10Ocr = responseAnnotationsCopy.data.pageRectangleMapCategoryOCR

        const response3 = await apiSmartBoxGetAnnotationsByClientId(clientId)
        const response4 = await apiGetSetDocumentRedactAllStatus(documentId)
        //console.log('response1.data.pageRectangleMap TTTTTTTTTTTTTTTTTTTTTTT 1111111111111111111111111', response1.data.pageRectangleMap)
        //console.log('response1Ocr TTTTTTTTTTTTTTTTTTTTTTT 111111111111111111111111111', response1Ocr)
        let pageRectangleMap = await this.convertCanvasDataCopy(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
        const pageRectangleMapOcr = await this.convertCanvasData(response1Ocr === null ? {} : response1Ocr)
        const pageRectangleMapOcrNewCopy = await this.convertCanvasDataOcrNewCopy(response1OcrNew === null ? {} : response1OcrNew)
        let newCanvasDataNew = await this.convertCanvasDataNew(response10.data.pageRectangleMap === null ? {} : response10.data.pageRectangleMap)
        //const newCanvasDataNewOcr = await this.convertCanvasDataNew(response10Ocr === null ? {} : response10Ocr)
        //const alreadyRedactedKeys = await getRedactedKeys({ id: documentId })
        const alreadyRedactedKeys = await getRedactedKeysMixed({ id: documentId })
        // console.log('pageRectangleMap TTTTTTTTTTTTTTTTTTTTTTT 2222222222222222222222222', pageRectangleMap)
        // console.log('pageRectangleMapOcr TTTTTTTTTTTTTTTTTTTTTTT 2222222222222222222222222', pageRectangleMapOcr)
        //console.log('pageRectangleMapOcr TTTTTTTTTTTTTTTTTTTTTTT 33333333333333333333333333', pageRectangleMapOcr)

        // Object.keys(pageRectangleMapOcr).forEach((page) => {
        //     pageRectangleMapOcr[page].forEach((value) => {
        //         let valueDecoded = value
        //         JSON.parse(value).nonRedacted = true
        //         JSON.parse(value).DRAGOS = 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa'
        //         console.log('JSON.parse(value) 00000000000000000000000000000000000', JSON.parse(value))
        //     })
        // })
        if (Object.keys(pageRectangleMapOcr) && Object.keys(pageRectangleMapOcr).length > 0 && Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length > 0) {
            Object.keys(pageRectangleMapOcr).forEach((item) => {
                Object.keys(pageRectangleMap).forEach((item2) => {
                    if (item === item2) {
                        pageRectangleMap[item2] = pageRectangleMapOcr[item].concat(pageRectangleMap[item2])
                    }

                })
            })
        } else if (Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length > 0) {
            pageRectangleMap = pageRectangleMap
        } else if (Object.keys(pageRectangleMapOcr) && Object.keys(pageRectangleMapOcr).length > 0) {
            pageRectangleMap = pageRectangleMapOcr
        }

        // if (Object.keys(pageRectangleMapOcrNewCopy) && Object.keys(pageRectangleMapOcrNewCopy).length > 0 && Object.keys(newCanvasDataNew) && Object.keys(newCanvasDataNew).length > 0) {
        //     Object.keys(pageRectangleMapOcrNewCopy).forEach((item) => {
        //         Object.keys(newCanvasDataNew).forEach((item2) => {
        //             if (item === item2) {
        //                 newCanvasDataNew[item2] = pageRectangleMapOcrNewCopy[item].concat(newCanvasDataNew[item2])
        //             }
        //
        //         })
        //     })
        // } else if (Object.keys(newCanvasDataNew) && Object.keys(newCanvasDataNew).length > 0) {
        //     newCanvasDataNew = newCanvasDataNew
        // } else if (Object.keys(pageRectangleMapOcrNewCopy) && Object.keys(pageRectangleMapOcrNewCopy).length > 0) {
        //     newCanvasDataNew = pageRectangleMapOcrNewCopy
        // }
        // console.log('pageRectangleMap TTTTTTTTTTTTTTTTTTTTTTT 33333333333333333333333333', pageRectangleMap)
        // console.log('newCanvasDataNew TTTTTTTTTTTTTTTTTTTTTTT 33333333333333333333333333', newCanvasDataNew)
        alreadyRedactedKeys.data.mixed = []
        this.setState({
            canvasData: pageRectangleMap,
            canvasDataCopy: newCanvasDataNew,
            canvasDataMixedOcr: pageRectangleMapOcr,
            pdfBytes: this.props.pdfBytes,
             totalPages: this.props.totalPages,
             landscape: this.props.landscape,
            divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
            width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
            height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
            forPageNumber: 1,
            totalThumbnails: response2.data,
            annotationList: response3.data,
            scale: this._generateScale(this.props.landscape),
            loading: true,
             idDoc: this.props.documentId,
            renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
            alreadyRedactedKeys: alreadyRedactedKeys.data,
            statusRedactAll: response4.data.document_redact_all_status === null ? 'false' : response4.data.document_redact_all_status,
            responseAnnotationsCopy
        }, ()=>{ /*console.log(2)*/ })
        //this.onGetRedactStatus(documentId)
        if (Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length === 0) {
            this.setState({
                previewPageDisabled: true,
            })
        }
        this.setState({
            clearPageDisabled: true,
        })
        setTimeout(() => {
            this.setState({
                renderStatus: true
            })
        }, 5000)
    }

    onRedactionColourProfileGetCategories = () => {
        const { boxRedactionColorProfileId } = this.props
        const getRedactionColorProfileCategoriesRequestDTO = {
            defaultColorProfile: null,
            redactionColorProfileId: boxRedactionColorProfileId,
        }
        apiRedactionColourProfileGetCategories(getRedactionColorProfileCategoriesRequestDTO).then((response) => {
            this.setState({
                listCategories: response.data.categoryResponseDTOList,
            })
        })

    }

    onGetRedactionColour = () => {
        apiRedactionColourProfileGet().then((response) => {
            //console.log('response.data  AAAAAAAAAAAAAAAAAAA', response.data)
            this.setState({
                listRedactionColour: response.data.redactionColorProfileItemDTOS
            })
        })
    }

    onGetDocumentNotes = (documentId) => {
        if (this.props.isSmartBoxApplication) {
            getSmartBoxDocNotes(this.props.documentId).then((res) => {
                if (res.data) {
                    this.setState({
                        documentNoteGet: res.data.note
                    })
                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        } else {
            getDocNotes(this.props.documentId).then((res) => {
                if (res.data) {
                    this.setState({
                        documentNoteGet: res.data.note
                    })
                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        }
    }
    getTagDocumentClient = () => {
        apiGetTagDocumentClient(this.props.clientId).then((response) => {
            this.setState({
                customerStatus: response.data
            })
        })
    }

    onGetRedactStatus = (documentId) => {
        apiGetSetDocumentRedactAllStatus(documentId).then((response) => {
            this.setState({
                statusRedactAll: response.data.document_redact_all_status === null ? "false" : response.data.document_redact_all_status
            })
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.documentId !== this.props.documentId) {
            if (!this.state.isSmartBoxApplication) {
                this.fetchAllData(this.props.documentId, this.props.clientId)
            }
        }
        if (prevProps.documentId !== this.props.documentId) {
            const { systemPropertyFileStatus, systemPropertyFileStatusColor, systemPropertyFileStatusIcon } = this.props
            this.setState({
                valueStatusFile: this.props.systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
                selectTag: {
                    tagName: systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
                    color: systemPropertyFileStatusColor === null ? null : systemPropertyFileStatusColor,
                    icon: systemPropertyFileStatusIcon === null ? null : systemPropertyFileStatusIcon,
                }
            })
        }
        if (prevState.pageNumber !== this.state.pageNumber) {
            window.scrollTo(0,0)
            const myDiv = document.getElementById('page-pdf-redacting');
            myDiv.scrollTop = 0;
        }
        // if (prevProps !== this.props && this.props.checkRedactionCount === false) {
        //   this.setState({
        //     checkStartRedaction: false
        //   })
        // }
        // if (prevState !== this.state && prevState.statusSavedSmartView === true) {
        //   this.setState({
        //     checkStartRedaction: false
        //   })
        // }
    }
    onGetUpdateLockStatusSmartviewDocument = (documentId) => {
        const itemLockStatusSmartviewDocument = cookie.load('checkLockStatusSmartviewDocument', { path: '/' })
        apiUpdateLockStatusSmartviewDocument(documentId).then((response) => {
            cookie.save('checkLockStatusSmartviewDocument', 'getLockStatus', {path: '/'})
            this.setIntervalUpdateLockStatusSmartviewDocument()
        })
    }

    setIntervalUpdateLockStatusSmartviewDocument = () => {
        let { setIntervalUpdateLockStatus } = this.state
        const itemLockStatusSmartviewDocument = cookie.load('checkLockStatusSmartviewDocument', { path: '/' })
        if (itemLockStatusSmartviewDocument === 'getLockStatus') {
            if (setIntervalUpdateLockStatus === 0) {
                setIntervalUpdateLockStatus = setInterval(this.callBackUpdateLockStatusSmartviewDocument, 30000)
                this.setState({
                    setIntervalUpdateLockStatus
                })
            } else {
                clearInterval(setIntervalUpdateLockStatus)
            }
        } else {
            clearInterval(setIntervalUpdateLockStatus)
            this.setState({setIntervalUpdateLockStatus})
        }
    }

    callBackUpdateLockStatusSmartviewDocument = () => {
        const { setIntervalUpdateLockStatus } = this.state
        const itemLockStatusSmartviewDocument = cookie.load('checkLockStatusSmartviewDocument', { path: '/' })
        if (itemLockStatusSmartviewDocument === 'getLockStatus') {
            apiUpdateLockStatusSmartviewDocument(this.state.documentId).then((response) => {
                cookie.save('checkLockStatusSmartviewDocument', 'getLockStatus', {path: '/'})
                this.setState({setIntervalUpdateLockStatus})
            })
        } else {
            clearInterval(setIntervalUpdateLockStatus)
            this.setState({setIntervalUpdateLockStatus})
        }
    }
    /*shouldComponentUpdate(nextProps, nextState) {
    let allRendered = true
    for(let i=0; i < nextState.renderedPagesStatus.length; i++) {
      if(nextState.renderedPagesStatus[i] === false) {
        allRendered = false;
        break;
      }
    }
    return true;
  }*/
    onActionHideAllHighlightingCopy = async (status) => {
        const { listAllDocAnnotationOcr, smartBoxDocumentId, canvasData } = this.state
        let responseAnnotationsCopy
      // console.log('status SSSSSSSSSSSSSSSSSSSSSS 33333333333333333333333', status)
        const categories = null

        if (status) {
            const { listAllDocAnnotationOcr, smartBoxDocumentId } = this.state
            const categories = null
            responseAnnotationsCopy = await getSmartBoxScanAnnotationsCopy(smartBoxDocumentId, categories)
            if (responseAnnotationsCopy.data.messageTextResponseDTO  && responseAnnotationsCopy.data.messageTextResponseDTO.title && responseAnnotationsCopy.data.messageTextResponseDTO.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(responseAnnotationsCopy.data.messageTextResponseDTO.message, 'Success')
            } else if (responseAnnotationsCopy.data.messageTextResponseDTO && responseAnnotationsCopy.data.messageTextResponseDTO.title && responseAnnotationsCopy.data.messageTextResponseDTO.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(responseAnnotationsCopy.data.messageTextResponseDTO.message, 'Info')
            }
            const response10Ocr = responseAnnotationsCopy.data.pageRectangleMapCategoryOCR
             //console.log('response10Ocr SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', response10Ocr)
            const newCanvasDataNewOcr = await this.convertCanvasDataNew(response10Ocr === null ? {} : response10Ocr)
            //console.log('newCanvasDataNewOcr SSSSSSSSSSSSSSSSSSSSSS 33333333333333333333333', newCanvasDataNewOcr)
            //console.log('responseAnnotationsCopy.status SSSSSSSSSSSSSSSSSSSSSS 33333333333333333333333', responseAnnotationsCopy.status)
            Object.keys(newCanvasDataNewOcr) && Object.keys(newCanvasDataNewOcr).length > 0 && Object.keys(newCanvasDataNewOcr).forEach((item2) => {
               //console.log('item AAAAAAAAAA', item2)
            })
            if (responseAnnotationsCopy.status === 200) {
                this.setState({
                    canvasDataHighlightingMixed: newCanvasDataNewOcr,
                    statusHideAllHighlightingMixed: status
                })
            }
        } else {
            const { listAllDocAnnotationOcr, smartBoxDocumentId } = this.state
            const categories = null
            responseAnnotationsCopy = await getSmartBoxScanAnnotationsCopy(smartBoxDocumentId, categories)
            if (responseAnnotationsCopy.data.messageTextResponseDTO  && responseAnnotationsCopy.data.messageTextResponseDTO.title && responseAnnotationsCopy.data.messageTextResponseDTO.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(responseAnnotationsCopy.data.messageTextResponseDTO.message, 'Success')
            } else if (responseAnnotationsCopy.data.messageTextResponseDTO && responseAnnotationsCopy.data.messageTextResponseDTO.title && responseAnnotationsCopy.data.messageTextResponseDTO.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(responseAnnotationsCopy.data.messageTextResponseDTO.message, 'Info')
            }
            const response10Ocr = responseAnnotationsCopy.data.pageRectangleMapCategoryOCR
            //console.log('response10Ocr SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', response10Ocr)
            const newCanvasDataNewOcr = await this.convertCanvasDataNew(response10Ocr === null ? {} : response10Ocr)
            //console.log('newCanvasDataNewOcr SSSSSSSSSSSSSSSSSSSSSS 33333333333333333333333', newCanvasDataNewOcr)
            //console.log('responseAnnotationsCopy.status SSSSSSSSSSSSSSSSSSSSSS 33333333333333333333333', responseAnnotationsCopy.status)
            Object.keys(newCanvasDataNewOcr) && Object.keys(newCanvasDataNewOcr).length > 0 && Object.keys(newCanvasDataNewOcr).forEach((item2) => {
                //console.log('item AAAAAAAAAA', item2)
            })
            if (responseAnnotationsCopy.status === 200) {
                this.setState({
                    canvasDataHighlightingMixed: newCanvasDataNewOcr,
                    statusHideAllHighlightingMixed: status
                })
            }
        }
    }
    showHideCategoryOcr = async (e, category, state, item) => {
        const { listAllDocAnnotationOcr, allCategoryVisibleOcr } = this.state
        //console.log('eeeeeeeeee', e)
        //console.log('category', category)
        //console.log('state', state)
        //console.log('item', item)
        const docAnnotateCategory = []
        const docAnnotateCategoryFilter = []
        let uniqueVerificationVisited = []
        let allCategoryVisibleOcr2 = []
        if (item && item.children && item.children.length > 0) {
            item.children.forEach((item2) => {
                docAnnotateCategory.push(item2.categoryNameUpdate)
                listAllDocAnnotationOcr && listAllDocAnnotationOcr.length > 0 && listAllDocAnnotationOcr.forEach((item3) => {
                    if (!listAllDocAnnotationOcr.includes(item2.categoryNameUpdate)) {
                        docAnnotateCategoryFilter.push(item3)
                    }
                })
                uniqueVerificationVisited = listAllDocAnnotationOcr && listAllDocAnnotationOcr.length > 0 && listAllDocAnnotationOcr.filter((a, i) => a !== item2.categoryNameUpdate)
            })
        }
        allCategoryVisibleOcr2 = this.state.allCategoryVisibleOcr.concat(docAnnotateCategory)
        if (state) {
            allCategoryVisibleOcr2 = allCategoryVisibleOcr2 && allCategoryVisibleOcr2.length > 0 && allCategoryVisibleOcr2.filter((a, i) => !docAnnotateCategory.includes(a))
        }
        const uniqueVerificationVisited2 = listAllDocAnnotationOcr && listAllDocAnnotationOcr.length > 0 && listAllDocAnnotationOcr.filter((a, i) => !allCategoryVisibleOcr2.includes(a))
        const getColoredRectanglesDTO = {
            docId: this.state.documentId,
            categories: uniqueVerificationVisited2,
            //solrIp: '18.157.167.245'
        }
        // const response1 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
        // const newCanvasData = this.convertCanvasData(response1.data.pageRectangleMapCategory === null ? {} : response1.data.pageRectangleMapCategory)
        const response5 = await apiSmartBoxGetAllCanvasPoints(this.props.documentId)
        const newCanvasData2 = await this.convertCanvasData(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap,)
        getRectanglesCoordsOcrPost(getColoredRectanglesDTO).then((response1) => {
            const newCanvasData = this.convertCanvasDataPost(response1.data.pageRectangleMapCategory === null ? {} : response1.data.pageRectangleMapCategory)
            Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData).forEach((item) => {
                Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData2).forEach((item2) => {
                    if (item === item2) {
                        newCanvasData[item] = newCanvasData2[item2].concat(newCanvasData[item])
                    }

                })
            })
            this.setState({
                pdfBytes: ''
            }, () => {
                this.setState({
                    canvasData: newCanvasData,
                    pdfBytes: this.props.pdfBytes,
                    totalPages: this.props.totalPages,
                    landscape: this.props.landscape,
                    allCategoryVisibleOcr: allCategoryVisibleOcr2,
                })
            })
        })
    }
    onGeRectanglesCoordsOcr = async(documentId, clientId) => {
        const { listAllDocAnnotationOcr } = this.state
        const getColoredRectanglesDTO = {
            docId: documentId,
            categories: listAllDocAnnotationOcr,
            //solrIp: '18.157.167.245'
        }
        //console.log('get-rectangles-coords-ocr 11111111111111111111111111111111111111111')
        const response1 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
        const response10 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
        //const response1 = await geRectanglesCoordsOcr(documentId)
        const response5 = await apiSmartBoxGetAllCanvasPoints(documentId)
        const response2 = await apiSmartBoxGetPreviewRedactingSarDocByThumbnailId(documentId)
        const response3 = await apiSmartBoxGetAnnotationsByClientId(clientId)
        const response4 = await apiGetSetDocumentRedactAllStatus(documentId)
        let newCanvasData = await this.convertCanvasData(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
        let newCanvasDataNew = await this.convertCanvasData(response10.data.pageRectangleMap === null ? {} : response10.data.pageRectangleMap)
        const newCanvasData2 = await this.convertCanvasData(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap)

        if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0) {
            Object.keys(newCanvasData).forEach((item) => {
                Object.keys(newCanvasData2).forEach((item2) => {
                    if (item === item2) {
                        newCanvasData[item] = newCanvasData2[item2].concat(newCanvasData[item])
                    }

                })
            })
        } else if (Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData).length === 0) {
            newCanvasData = newCanvasData2
        } else if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData2).length === 0) {
            newCanvasData = newCanvasData
        }

        if (Object.keys(newCanvasDataNew) && Object.keys(newCanvasDataNew).length > 0 && Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0) {
            Object.keys(newCanvasDataNew).forEach((item) => {
                Object.keys(newCanvasData2).forEach((item2) => {
                    if (item === item2) {
                        newCanvasDataNew[item] = newCanvasData2[item2].concat(newCanvasDataNew[item])
                    }

                })
            })
        } else if (Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasDataNew).length === 0) {
            newCanvasDataNew = newCanvasData2
        } else if (Object.keys(newCanvasDataNew) && Object.keys(newCanvasDataNew).length > 0 && Object.keys(newCanvasData2).length === 0) {
            newCanvasDataNew = newCanvasDataNew
        }

        //const alreadyRedactedKeys = await getRedactedKeys({ id: documentId })
        const alreadyRedactedKeys = await getRedactedKeysMixed({ id: documentId })
        this.setState({
            canvasData: newCanvasData,
            canvasDataCopy: newCanvasDataNew,
            //canvasData: newCanvasData3,
            canvasDataOcrDownload: response5.data,
            pdfBytes: this.props.pdfBytes,
            totalPages: this.props.totalPages,
            landscape: this.props.landscape,
            divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
            width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
            height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
            forPageNumber: 1,
            totalThumbnails: response2.data,
            annotationList: response3.data,
            scale: this._generateScale(this.props.landscape),
            loading: true,
            idDoc: response1.data.idDoc,
            renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
            alreadyRedactedKeys: alreadyRedactedKeys.data,
            statusRedactAll: response4.data.document_redact_all_status === null ? 'false' : response4.data.document_redact_all_status
        }, () => {
            if (response2.status === 200) {
                if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length === 0) {
                    this.setState({
                        previewPageDisabled: true,
                    })
                }
                this.setState({
                    clearPageDisabled: true,
                })
            }
        })
    }
    setNumberOfAnnotateSelected = async(checkedKeys) => {
        this.setState({ itemsSelected: checkedKeys, numberOfAnnotateSelected: checkedKeys.length })
    }

    setAnnotationSelectedItem = (annotationSelectedItem) => this.setState({ annotationSelectedItem })

    setTreeDataAnnotations = (treeDataAnnotations) => {
        this.setState({treeDataAnnotations}, () => {
            this.setState({
                renderStatus: true
            })
        })
    }

    fetchAllData = async(documentId, clientId) => {
        const response1 = await apiGetAllCanvasPoints(documentId)
        const response10 = await apiGetAllCanvasPoints(documentId)
        const response2 = await getPreviewRedactingSarDocByThumbnailId(documentId)
        const response3 = await getAnnotationsByClientId(clientId)
        const response4 = await apiGetSetDocumentRedactAllStatus(documentId)
        this.setState({
            canvasData: response1.data.pageRectangleMap,
            //canvasDataCopy: response1.data.pageRectangleMap,
            pdfBytes: this.props.pdfBytes,
            totalPages: this.props.totalPages,
            landscape: this.props.landscape,
            divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
            width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
            height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
            forPageNumber: 1,
            totalThumbnails: response2.data,
            annotationList: response3.data,
            loading: true,
            scale: this._generateScale(this.props.landscape),
            renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
            statusRedactAll: response4.data.document_redact_all_status === null ? 'false' : response4.data.document_redact_all_status,
            previewPageDisabled: false,
        })
    }

    convertCanvasData = async(canvasData) => {
        for (const canvasPageKey of Object.keys(canvasData)) {
            canvasData[canvasPageKey] = Object.values(canvasData[canvasPageKey])
        }
        return canvasData
    }

    convertCanvasDataOcrNewCopy = async(canvasData) => {
        for (const canvasPageKey of Object.keys(canvasData)) {
            canvasData[canvasPageKey] = Object.values(canvasData[canvasPageKey])
        }
        return canvasData
    }

    convertCanvasDataCopy = async(canvasData) => {
        for (const canvasPageKey of Object.keys(canvasData)) {
            canvasData[canvasPageKey] = Object.values(canvasData[canvasPageKey])
        }
        return canvasData
    }
    convertCanvasDataCopyMixed = (canvasData) => {
        for (const canvasPageKey of Object.keys(canvasData)) {
            canvasData[canvasPageKey] = Object.values(canvasData[canvasPageKey])
        }
        return canvasData
    }
    convertCanvasDataCopyMixedNew = (canvasDataNew) => {
        for (const canvasPageKey of Object.keys(canvasDataNew)) {
            canvasDataNew[canvasPageKey] = Object.values(canvasDataNew[canvasPageKey])
        }
        return canvasDataNew
    }
    convertCanvasDataNew = async(canvasDataNew) => {
        for (const canvasPageKey of Object.keys(canvasDataNew)) {
            canvasDataNew[canvasPageKey] = Object.values(canvasDataNew[canvasPageKey])
        }
        return canvasDataNew
    }

    convertCanvasDataPost = (canvasData) => {
        for (const canvasPageKey of Object.keys(canvasData)) {
            canvasData[canvasPageKey] = Object.values(canvasData[canvasPageKey])
        }
        return canvasData
    }

    convertCanvasDataOcr = async(canvasData, canvasDataUpdate) => {

        const pageRectangleMap1 = canvasData
        const pageRectangleMap3 = canvasDataUpdate
        const pageRectangleMap2 = {}

        const table2 = []
        let table3 = {}

        for (let canvasPageKey of Object.keys(pageRectangleMap1)) {
            if (canvasPageKey !== 'doc_annotate_location_city') {
                for (let canvasPageKey2 of Object.keys(pageRectangleMap1[canvasPageKey])) {
                    //table3 = (pageRectangleMap1[canvasPageKey])[canvasPageKey2]
                    for (let canvasPageKey3 of Object.keys((pageRectangleMap1[canvasPageKey])[canvasPageKey2])) {
                        table2.push(((pageRectangleMap1[canvasPageKey])[canvasPageKey2])[canvasPageKey3])
                    }
                    // (pageRectangleMap1[canvasPageKey])[canvasPageKey2] = table2
                    table3 = (pageRectangleMap1[canvasPageKey])[canvasPageKey2] = table2
                    pageRectangleMap1[canvasPageKey2] = table2
                }
            }
        }

        for (const canvasPageKey of Object.keys(pageRectangleMap3)) {
            pageRectangleMap3[canvasPageKey] = table3
        }
        return pageRectangleMap3
    }

    _generateScale = (documentType) => {
        return documentType ? 1.3 : 1.4
        //todo add scale for different devices
    }

    checkOnClearAll = (status) => {
        this.setState({
            verificationOnClickClearAll: status
        })
    }

    fetchSmartBoxDataCanvasOcr = async() => {
        const { documentId, clientId, responseAnnotationsCopy } = this.state
        const response2 = await apiSmartBoxGetPreviewRedactingSarDocByThumbnailId(documentId)
        const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
        const response3 = await apiSmartBoxGetAnnotationsByClientId(clientId)
        const { listAllDocAnnotationOcr } = this.state
        const getColoredRectanglesDTO = {
            docId: documentId,
            categories: listAllDocAnnotationOcr,
            //solrIp: '18.157.167.245'
        }
        //console.log('get-rectangles-coords-ocr 22222222222222222222222222222222222')
        //const response5 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
        const response5 = responseAnnotationsCopy.data.pageRectangleMapCategoryOCR
        //const response5 = await geRectanglesCoordsOcr(documentId)

        const newCanvasData = await this.convertCanvasData(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
        const newCanvasData2 = await this.convertCanvasData(response5 === null ? {} : response5)
        Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData2).forEach((item) => {
            Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData).forEach((item2) => {
                if (item === item2) {
                    newCanvasData2[item2] = newCanvasData[item].concat(newCanvasData2[item2])
                }

            })
        })
        //const alreadyRedactedKeys = await getRedactedKeys({ id: documentId })
        const alreadyRedactedKeys = await getRedactedKeysMixed({ id: documentId })

        this.setState({
            //canvasData: newCanvasData,
            canvasData: newCanvasData2,
            pdfBytes: this.props.pdfBytes,
            totalPages: this.props.totalPages,
            landscape: this.props.landscape,
            divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
            width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
            height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
            forPageNumber: 1,
            totalThumbnails: response2.data,
            annotationList: response3.data,
            scale: this._generateScale(this.props.landscape),
            loading: true,
            idDoc: response1.data.idDoc,
            renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
            alreadyRedactedKeys: alreadyRedactedKeys.data
        }, () => {
            this.setState({
                canvasData: newCanvasData2,
            })
        })
        if (response2.status === 200) {
            if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length === 0) {
                this.setState({
                    previewPageDisabled: true,
                })
            }
            this.setState({
                clearPageDisabled: true
            })
        }
    }

    checkOnClearAllManual = (status) => {
        this.setState({
            verificationOnClickClearAllManual: status
        })
    }
    checkOnClearAllRegEx = (status) => {
        this.setState({
            verificationOnClickClearAllRegEx: status
        })
    }
    checkOnClearAllBusiness = (status) => {
        this.setState({
            verificationOnClickClearAllBusiness: status
        })
    }

    onSendStatusOnClearAll = (status) => {
        this.setState({
            statusKeysEmptyOnClearAll: status
        })
    }

    clearAllKeysRedactedOnPage = () => {
        const { pageNumber } = this.state
        const data = {
            id: this.props.documentId,
            page: pageNumber
        }
        this.setState({
            verificationOnClickClearAll: true,
            verificationOnClickClearAllManual: true,
            verificationOnClickClearAllRegEx: true,
            verificationOnClickClearAllBusiness: true
        })

        if (this.props.isSmartBoxApplication) {
            apiSmartBoxClearAllKeysOnPage(data).then((response) => {
                const listItems = response.data
                const listKeysStandard = []
                const listKeysManual = []
                const listKeysRegex = []
                const listKeysBusiness = []

                listItems && listItems.map((list) => {
                    list && Object.keys(list).map((item) => {
                        if (item === 'standard') {
                            list[item] && Object.keys(list[item]).map((listStandard) => {
                                if (list[item][listStandard]) {
                                    list[item][listStandard].forEach((itemStandard) => {
                                        listKeysStandard.push(itemStandard.key)
                                    })
                                    return true
                                }
                                return true
                            })
                        } else if (item === 'manual') {
                            list[item] && Object.keys(list[item]).map((listManual) => {
                                if (list[item][listManual]) {
                                    list[item][listManual].forEach((itemManual) => {
                                        listKeysManual.push(itemManual.key)
                                    })
                                    return true
                                }
                                return true
                            })
                        } else if (item === 'regex') {
                            list[item] && Object.keys(list[item]).map((item3) => {
                                if (list[item][item3]) {
                                    list[item][item3].forEach((item4) => {
                                        listKeysRegex.push(item4.key)
                                    })
                                    return true
                                }
                                return true
                            })
                        } else if (item === 'businessterms') {
                            list[item] && Object.keys(list[item]).map((item3) => {
                                if ((list[item][item3])) {
                                    list[item][item3].forEach((item4) => {
                                        listKeysBusiness.push(item4.key)
                                    })
                                    return true
                                }
                                return true
                            })
                        }
                        return true
                    })
                    return true
                })

                if (listKeysStandard && listKeysStandard.length === 0) {
                    //   this.setState({
                    //     statusKeysEmptyOnClearAll: true
                    //   })
                    // } else {
                    //   this.setState({
                    //     statusKeysEmptyOnClearAll: false
                    //   })
                }

                this.setState({
                    newListKeysStandard: listKeysStandard,
                    newListKeysManual: listKeysManual,
                    newListKeysRegEx: listKeysRegex,
                    newListKeysBusiness: listKeysBusiness
                })
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        }
    }
    clearPageAfter = () => {

    }

    onGeRectanglesAfterClearOcr = async(documentId, clientId) => {
        const { listAllDocAnnotationOcr, responseAnnotationsCopy } = this.state
        const getColoredRectanglesDTO = {
            docId: documentId,
            categories: listAllDocAnnotationOcr,
            //solrIp: '18.157.167.245'
        }
        //console.log('get-rectangles-coords-ocr 333333333333333333333333333333333333333333333')
        //const response1 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
        const response1 = responseAnnotationsCopy.data.pageRectangleMapCategoryOCR
        //const response1 = await geRectanglesCoordsOcr(documentId)
        const response5 = await apiSmartBoxGetAllCanvasPoints(documentId)
        const newCanvasData = await this.convertCanvasData(response1 === null ? {} : response1)
        const newCanvasData2 = await this.convertCanvasData(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap)

        Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData).forEach((item) => {
            Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData2).forEach((item2) => {
                if (item === item2) {
                    newCanvasData[item] = newCanvasData2[item2].concat(newCanvasData[item])
                }

            })
        })
        this.setState({
            pdfBytes: '',
            renderSearch: false,
            renderSelectText: false,
            renderCommentsText: false,
            viewAnnotationsRedaction: false,
            renderSelectTool: false,
            renderSelectToolMove: false,
            displaySearchButtons: false,
            drawSelectedMouse: false,
            renderSelectDraw: false,
            selectedTool: 'select',
            tool: Tools.Select,
            disableClickOnPage: true
        }, () => {
            this.setState({
                canvasData: newCanvasData,
                canvasDataOcrDownload: response5.data,
                pdfBytes: this.props.pdfBytes,
                totalPages: this.props.totalPages
            })
        })
    }
    _clearCopy = () => {
        const { clearPageDisabled, alreadyRedactedKeys, getChildrenAllCategories, pageNumber } = this.state
        const { childrenStandard, childrenStandardOcr, childrenBusiness, childrenBusinessOcr, childrenRegEx, childrenRegExOcr } = getChildrenAllCategories
        let { standard, standardOCR, business, businessOCR, regex, regexOCR } = alreadyRedactedKeys
        const rectangleOnCurrentPage = this.state.canvasData
        rectangleOnCurrentPage[this.state.pageNumber] = []
        const clearPageStandard = []
        const clearPageBusiness = []
        const clearPageRegEx = []
        this.onCallToDisableScroll()
         this.setState({
             checkStartRedaction: true
         }, () => {
             //STANDARD
             if (childrenStandard && childrenStandard.length > 0) {
                 childrenStandard.forEach((standardElem) => {
                     if (pageNumber.toString() === standardElem.pageNumber.toString()) {
                         clearPageStandard.push(standardElem.keyMixtRedaction)
                     }
                 })
             }
             if (clearPageStandard && clearPageStandard.length > 0 && standard && standard.length > 0) {
                 standard = standard.filter((obj) => !clearPageStandard.includes(obj))
             }

             //BUSINESS
             if (childrenBusiness && childrenBusiness.length > 0) {
                 childrenBusiness.forEach((businessElem) => {
                     if (pageNumber.toString() === businessElem.pageNumber.toString()) {
                         clearPageBusiness.push(businessElem.keyMixtRedaction)
                     }
                 })
             }
             if (clearPageBusiness && clearPageBusiness.length > 0 && business && business.length > 0) {
                 business = business.filter((obj) => !clearPageBusiness.includes(obj))
             }

             //REG EX
             if (childrenRegEx && childrenRegEx.length > 0) {
                 childrenRegEx.forEach((regExElem) => {
                     if (pageNumber.toString() === regExElem.pageNumber.toString()) {
                         clearPageRegEx.push(regExElem.keyMixtRedaction)
                     }
                 })
             }
             if (clearPageRegEx && clearPageRegEx.length > 0 && regex && regex.length > 0) {
                 regex = regex.filter((obj) => !clearPageRegEx.includes(obj))
             }
             let updateStatus = false
             if ((standard && standard.length > 0) || (business && business.length > 0) || (regex && regex.length > 0)) {
                 updateStatus = true
             } else {
                 updateStatus = false
             }
             const saveAnnotationWithStateDTO = {
                 keyWordOcr: standardOCR,
                 keyWordOcrRegex: regexOCR,
                 keyWordOcrDictionary: businessOCR,
                 keyWordNonOcr: standard,
                 keyWordNonOcrRegex: regex,
                 keyWordNonOcrDictionary: business,
                 updateRedactions: updateStatus,
                 idDocument: this.state.documentId
             }
             apiSmartBoxSaveQueryStatusMixed(saveAnnotationWithStateDTO).then((response) => {
                 //this.onGetRedactedKeysMixed()
             })

             if (clearPageDisabled) {
                 this._sketch.setBackgroundFromDataUrl('')
                 this._sketch.clear()
             }
             this.onSetDocumentRedactAllStatus("false")
             this.setState({
                 controlledValue: null,
                 backgroundColor: 'transparent',
                 fillWithBackgroundColor: false,
                 canUndo: this._sketch.canUndo(),
                 canRedo: this._sketch.canRedo(),
                 canvasData: rectangleOnCurrentPage,
                 haveChanges: true,
             }, () => {
                 const { pageNumber } = this.state
                 const data = {
                     id: this.props.documentId,
                     page: pageNumber
                 }
                 this.setState({
                     verificationOnClickClearAll: true,
                     verificationOnClickClearAllManual: true,
                     verificationOnClickClearAllRegEx: true,
                     verificationOnClickClearAllBusiness: true
                 })
                 if (this.props.isSmartBoxApplication) {
                     apiSmartBoxClearAllKeysOnPage(data).then((response) => {
                         const listItems = response.data
                         const listKeysStandard = []
                         const listKeysManual = []
                         const listKeysRegex = []
                         const listKeysBusiness = []
                         if (this.props.isOcr) {
                             this.onGeRectanglesAfterClearOcr(this.state.documentId, this.state.clientId)
                         }
                         listItems && listItems.map((list) => {
                             list && Object.keys(list).map((item) => {
                                 if (item === 'standard') {
                                     list[item] && Object.keys(list[item]).map((listStandard) => {
                                         if (list[item][listStandard]) {
                                             list[item][listStandard].forEach((itemStandard) => {
                                                 listKeysStandard.push(itemStandard.key)
                                             })
                                             return true
                                         }
                                         return true
                                     })
                                 } else if (item === 'manual') {
                                     list[item] && Object.keys(list[item]).map((listManual) => {
                                         if (list[item][listManual]) {
                                             list[item][listManual].forEach((itemManual) => {
                                                 listKeysManual.push(itemManual.key)
                                             })
                                             return true
                                         }
                                         return true
                                     })
                                 } else if (item === 'regex') {
                                     list[item] && Object.keys(list[item]).map((item3) => {
                                         if (list[item][item3]) {
                                             list[item][item3].forEach((item4) => {
                                                 listKeysRegex.push(item4.key)
                                             })
                                             return true
                                         }
                                         return true
                                     })
                                 } else if (item === 'businessterms') {
                                     list[item] && Object.keys(list[item]).map((item3) => {
                                         if ((list[item][item3])) {
                                             list[item][item3].forEach((item4) => {
                                                 listKeysBusiness.push(item4.key)
                                             })
                                             return true
                                         }
                                         return true
                                     })
                                 }
                                 return true
                             })
                             return true
                         })
                         this.onGetRedactedKeysMixed()
                         this.setState({
                             newListKeysStandard: listKeysStandard,
                             newListKeysManual: listKeysManual,
                             newListKeysRegEx: listKeysRegex,
                             newListKeysBusiness: listKeysBusiness,
                             checkStartRedaction: false,
                         })
                         this.onRemoveEventDisableScroll()
                     }).catch((error) => {
                         if (error?.response?.status === 504) {
                             CustomNotificationManager.cleanBuffer('warning', '')
                         }else{
                             console.log(error)
                         }
                     })
                 }
                 this.hideCanvasModals()

             })
             this.hideCanvasModals()
         })
    }
    _clear = () => {
        const { clearPageDisabled } = this.state
        const rectangleOnCurrentPage = this.state.canvasData
        rectangleOnCurrentPage[this.state.pageNumber] = []
        if (clearPageDisabled) {
            this._sketch.setBackgroundFromDataUrl('')
            this._sketch.clear()
        }
        this.onSetDocumentRedactAllStatus("false")
        // if (this.props.isSmartBoxApplication) {
        //   this.clearAllKeysRedactedOnPage()
        // }
        const alreadyRedactedKeys= {}
        alreadyRedactedKeys.business= []
        alreadyRedactedKeys.manual= []
        alreadyRedactedKeys.regex= []
        alreadyRedactedKeys.standard= []
        this.setState({
            controlledValue: null,
            backgroundColor: 'transparent',
            fillWithBackgroundColor: false,
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
            canvasData: rectangleOnCurrentPage,
            haveChanges: true,
            alreadyRedactedKeys
        } , () => {
            const { pageNumber } = this.state
            const data = {
                id: this.props.documentId,
                page: pageNumber
            }
            this.setState({
                verificationOnClickClearAll: true,
                verificationOnClickClearAllManual: true,
                verificationOnClickClearAllRegEx: true,
                verificationOnClickClearAllBusiness: true
            })
            if (this.props.isSmartBoxApplication) {
                apiSmartBoxClearAllKeysOnPage(data).then((response) => {
                    const listItems = response.data
                    const listKeysStandard = []
                    const listKeysManual = []
                    const listKeysRegex = []
                    const listKeysBusiness = []
                    if (this.props.isOcr) {
                        this.onGeRectanglesAfterClearOcr(this.state.documentId, this.state.clientId)
                    }
                    listItems && listItems.map((list) => {
                        list && Object.keys(list).map((item) => {
                            if (item === 'standard') {
                                list[item] && Object.keys(list[item]).map((listStandard) => {
                                    if (list[item][listStandard]) {
                                        list[item][listStandard].forEach((itemStandard) => {
                                            listKeysStandard.push(itemStandard.key)
                                        })
                                        return true
                                    }
                                    return true
                                })
                            } else if (item === 'manual') {
                                list[item] && Object.keys(list[item]).map((listManual) => {
                                    if (list[item][listManual]) {
                                        list[item][listManual].forEach((itemManual) => {
                                            listKeysManual.push(itemManual.key)
                                        })
                                        return true
                                    }
                                    return true
                                })
                            } else if (item === 'regex') {
                                list[item] && Object.keys(list[item]).map((item3) => {
                                    if (list[item][item3]) {
                                        list[item][item3].forEach((item4) => {
                                            listKeysRegex.push(item4.key)
                                        })
                                        return true
                                    }
                                    return true
                                })
                            } else if (item === 'businessterms') {
                                list[item] && Object.keys(list[item]).map((item3) => {
                                    if ((list[item][item3])) {
                                        list[item][item3].forEach((item4) => {
                                            listKeysBusiness.push(item4.key)
                                        })
                                        return true
                                    }
                                    return true
                                })
                            }
                            return true
                        })
                        return true
                    })

                    // if (listKeysStandard && listKeysStandard.length === 0) {
                    //     this.setState({
                    //       statusKeysEmptyOnClearAll: true
                    //     })
                    //   } else {
                    //     this.setState({
                    //       statusKeysEmptyOnClearAll: false
                    //     })
                    // }

                    this.setState({
                        newListKeysStandard: listKeysStandard,
                        newListKeysManual: listKeysManual,
                        newListKeysRegEx: listKeysRegex,
                        newListKeysBusiness: listKeysBusiness
                    })
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            }
            this.hideCanvasModals()
        })

        this.hideCanvasModals()
    }

    deleteAllImages = (item) => {
        this._sketch.setBackgroundFromDataUrl('')
        this._sketch.removeSelectedImage(item)
        this.setState({
            controlledValue: null,
            backgroundColor: 'transparent',
            fillWithBackgroundColor: false,
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
            haveChanges: true
        })
        this.hideCanvasModals()
    }

    _undo = () => {
        this.hideCanvasModals()
        this._sketch.undo()
        this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
            haveChanges: true
        })
    }

    _redo = () => {
        this.hideCanvasModals()
        this._sketch.redo()
        this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
            haveChanges: true
        })
    }
    updateCanvasAnnotateTextMixed = (list, label, color, backgroundColor) => {
        const { canvasData, statusPageSidebar } = this.state
        const newData = []
        //console.log('Annotate 1111111111111111111111111111111111111111111111')
        //console.log('Annotate 1111111111111111111111111111111111111111111111 canvasData', canvasData)
        //console.log('Annotate 1111111111111111111111111111111111111111111111 list', list)
        const annotation = this.state.annotationText
        //console.log('addAnnotation 11111111111111111111111111111 annotation', annotation)
        if (annotation) {
            if (this.state.objectHasAnnotation) {
                //console.log('addAnnotation 777777777777777777777777')
                this._sketch.editAnnotation(annotation)
                this.hideCanvasModals()
            } else {
                //console.log('addAnnotation 8888888888888888888888888')
                this._sketch.addAnnotation(annotation)
                this.setState({
                    objectHasAnnotation: true
                })
                this.hideCanvasModals()
            }
        }
    }
    updateCanvasText = (list, label, color, backgroundColor) => {
        const { canvasData, statusPageSidebar } = this.state
        const newData = []
        //console.log('Annotate 1111111111111111111111111111111111111111111111')
        if (list.length && Object.keys(canvasData).length) {
            list.map((item) => {
                Object.keys(canvasData).map((page) => {
                    canvasData[page].map((value) => {
                        let valueDecoded = value

                        if (typeof valueDecoded === 'string') {
                            valueDecoded = JSON.parse(value)
                        }
                        if (valueDecoded.text === item && (valueDecoded.type === null || valueDecoded.type === 1)) {
                            const temp = valueDecoded
                            temp.type= 2
                            temp.y1 = valueDecoded.y1 - 20
                            temp.y2 = temp.y1 + 12
                            temp.text = label
                            temp.color = color
                            temp.background = backgroundColor
                            newData.push({ item: temp, page: page })
                        }
                        return true
                    })
                    return true
                })
                return true
            })
        }
        const pagesWithChanges = []
        if (newData.length) {
            newData.map((item) => {
                if (!pagesWithChanges.includes(item.page)) {
                    pagesWithChanges.push(item.page)
                }
                return true
            })
        }
        if (pagesWithChanges) {
            pagesWithChanges.map((page) => {
                const data = {}
                const pageRectangleMapData = []
                newData.map((item) => {
                    if (item.page === page) {
                        pageRectangleMapData.push(JSON.stringify(item.item))
                    }
                    return true
                })
                //data.idDoc= this.props.documentId
                data.page= Number(page)
                data.pageRectangleMap= {}
                data.pageRectangleMap[page] = pageRectangleMapData
                const dtoRedactEachWord = []
                dtoRedactEachWord.push(data)
                const saveCanvasDocumentSmartViewDTO = {
                    idDoc: this.props.documentId,
                    pageCoordinates: dtoRedactEachWord

                }
                if (!this.props.isOcr) {
                    //console.log('SAVE ---- 111111111111111111111111111111')
                    apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                        this.setState({
                            statusDrawRedaction: false
                        })
                    }).catch((error) => {
                        if (error?.response?.status === 504) {
                            CustomNotificationManager.cleanBuffer('warning', '')
                        } else {
                            console.log(error)
                        }
                    })
                }

                // apiSmartBoxUpdateCanvasPoints(data).then(() => {
                //
                // }).catch((error) => {
                //   if (error?.response?.status === 504) {
                //     CustomNotificationManager.cleanBuffer('warning', '')
                //   }else{
                //     console.log(error)
                //   }
                // })
                this.setState({
                    statusSavedSmartView: true
                })
                return true
            })
        }

        apiSmartBoxGetAllCanvasPoints(this.props.documentId).then((response) => {
            if (response.status === 200) {

            }
        })
    }

    clearAnnotation = () => {
        const { totalPages } = this.state
        const data = {}
        data.idDoc= this.props.documentId
        apiSmartBoxClearCanvasPoints(data).then((response) => {
            if (response.status === 200) {

                const initialCanvasData = this.state.canvasData
                for (let i = 1; i <= totalPages; i++) {
                    initialCanvasData[i] = []
                }
                this.setState({
                    canvasData: initialCanvasData,
                    haveChanges: false,
                    clearSketch: true
                }, () => {
                });

                // for (let i = 1; i <= totalPages; i++) {
                // this._sketch.clear()
                // remove only redacted words and keep drawn boxes

                // if(this.state.pageNumber !== i) {
                //   const initialCanvasData = this.state.canvasData
                //   initialCanvasData[i] = []
                //   this.setState({
                //     canvasData: initialCanvasData,
                //     haveChanges: false,
                //     pageNumber: i
                //   }, () => {
                //     this._sketch._clear()
                //   });
                // this.onSearchChangePage(null, i).then(() => {
                //   this._clear()
                /*const history = this._sketch._fc._objects
              for (let i = 0; i < history.length; i++) {
                if(typeof history[i].text !== drawByHandText) {
                  this._sketch._fc.remove(history[i])
                }
              }*/
                // })
                // } else {
                // const initialCanvasData = this.state.canvasData
                // initialCanvasData[this.state.pageNumber] = []
                //
                // this.setState({
                //   canvasData: initialCanvasData,
                //   haveChanges: false,
                // }, () => {
                //   this._sketch._clear()
                // });
                /*const history = this._sketch._fc._objects
            for (let i = 0; i < history.length; i++) {
              if(typeof history[i].text !== drawByHandText) {
                this._sketch._fc.remove(history[i])
              }
            }*/
            }

            // }
            // }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
        // this._sketch.clear()
        // remove only redacted words and keep drawn boxes
        /*const history = this._sketch._fc._objects
    for (let i = 0; i < history.length; i++) {
      if(typeof history[i].text !== drawByHandText) {
        this._sketch._fc.remove(history[i])
      }
    }*/
    }

    getSelectedWords = () => {
        const selection = document.getSelection()
        if (!this.state.renderCommentsText) {
            if (selection && selection.rangeCount) {
                //console.log('SELECT 111111111111111111111111111111111111111111111111')
                const poligonPoints = this.setPolygonPoints(selection)
                const poly = new fabric.Polygon(poligonPoints, {
                    fill: 'rgba(0, 0, 0, 0.4)',
                    angle: 0,
                    padding: 0,
                    selectable: true,
                    annotation: selection.toString() ? selection.toString() : '',
                    text: selection.toString() ? selection.toString() : '',
                    annotationType: 3,
                    type: 3,
                    typeMixed: 11
                })
                this._sketch._fc.add(poly)

                if (window.getSelection) {
                    if (window.getSelection().empty) { // Chrome
                        window.getSelection().empty()
                    } else if (window.getSelection().removeAllRanges) { // Firefox
                        window.getSelection().removeAllRanges()
                    }
                } else if (document.selection) { // IE?
                    document.selection.empty()
                }
            }
        } else if (this.state.renderCommentsText) {
            if (selection && selection.rangeCount) {
                const poligonPoints = this.setPolygonPoints(selection)
                const poly = new fabric.Polygon(poligonPoints, {
                    fill: 'rgba(0, 0, 0, 0.4)',
                    angle: 0,
                    padding: 0,
                    selectable: true,
                    annotation: selection.toString() ? selection.toString() : '',
                    text: selection.toString() ? selection.toString() : '',
                    annotationType: 4,
                    type: 4,
                })
                this._sketch._fc.add(poly)

                if (window.getSelection) {
                    if (window.getSelection().empty) { // Chrome
                        window.getSelection().empty()
                    } else if (window.getSelection().removeAllRanges) { // Firefox
                        window.getSelection().removeAllRanges()
                    }
                } else if (document.selection) { // IE?
                    document.selection.empty()
                }
            }
        }
    }

    setPolygonPoints = (selection) => {
        const parentWr = document.querySelector('div.canvas-page-1')
        const selectionPositions = selection.getRangeAt(0).getClientRects()
        let left = 9999
        let top = 9999
        let right = 0
        let bottom = 0
        const leftFix = parentWr.getBoundingClientRect().left + window.scrollX
        const topFix = parentWr.getBoundingClientRect().top - 2
        const bottomFix = parentWr.getBoundingClientRect().top - 1
        const poligonPoints = []
        for (let i = 0; i < selectionPositions.length; i++) {
            if (selectionPositions[i].left - leftFix < left) {
                left = selectionPositions[i].left - leftFix
            }
            if (selectionPositions[i].top - topFix < top) {
                top = selectionPositions[i].top - topFix
            }
            if (selectionPositions[i].right - leftFix > right) {
                right = selectionPositions[i].right - leftFix
            }
            if (selectionPositions[i].bottom - bottomFix > bottom) {
                bottom = selectionPositions[i].bottom - bottomFix
            }
        }

        poligonPoints.push({ x: left, y: top })
        poligonPoints.push({ x: (right + left) / 2, y: top })
        poligonPoints.push({ x: right, y: top })
        poligonPoints.push({ x: right, y: (bottom + top) / 2 })
        poligonPoints.push({ x: right, y: bottom })
        poligonPoints.push({ x: (right + left) / 2, y: bottom })
        poligonPoints.push({ x: left, y: bottom })
        poligonPoints.push({ x: left, y: (bottom + top) / 2 })
        if (Object.keys(selectionPositions)) {
            switch (Object.keys(selectionPositions).length) {
                case 2:
                    poligonPoints[0] = {
                        x: selectionPositions[0].left - leftFix,
                        y: selectionPositions[0].bottom - bottomFix
                    }
                    poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                    poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                    poligonPoints[4] = { x: selectionPositions[1].right - leftFix, y: selectionPositions[1].top - topFix }
                    poligonPoints[3] = { x: right, y: selectionPositions[1].top - topFix }
                    poligonPoints[5] = { x: selectionPositions[1].right - leftFix, y: bottom }
                    break
                case 3:
                    poligonPoints[0] = {
                        x: selectionPositions[0].left - leftFix,
                        y: selectionPositions[0].bottom - bottomFix
                    }
                    poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                    poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                    poligonPoints[4] = { x: selectionPositions[2].right - leftFix, y: selectionPositions[2].top - topFix }
                    poligonPoints[3] = { x: right, y: selectionPositions[2].top - topFix }
                    poligonPoints[5] = { x: selectionPositions[2].right - leftFix, y: bottom }
                    break
                case 4:
                    poligonPoints[0] = {
                        x: selectionPositions[0].left - leftFix,
                        y: selectionPositions[0].bottom - bottomFix
                    }
                    poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                    poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                    poligonPoints[4] = { x: selectionPositions[3].right - leftFix, y: selectionPositions[3].top - topFix }
                    poligonPoints[3] = { x: right, y: selectionPositions[3].top - topFix }
                    poligonPoints[5] = { x: selectionPositions[3].right - leftFix, y: bottom }
                    break
                case 5:
                    poligonPoints[0] = {
                        x: selectionPositions[0].left - leftFix,
                        y: selectionPositions[0].bottom - bottomFix
                    }
                    poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                    poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                    poligonPoints[4] = { x: selectionPositions[4].right - leftFix, y: selectionPositions[4].top - topFix }
                    poligonPoints[3] = { x: right, y: selectionPositions[4].top - topFix }
                    poligonPoints[5] = { x: selectionPositions[4].right - leftFix, y: bottom }
                    break
                case 6:
                    poligonPoints[0] = {
                        x: selectionPositions[0].left - leftFix,
                        y: selectionPositions[0].bottom - bottomFix
                    }
                    poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                    poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                    poligonPoints[4] = { x: selectionPositions[5].right - leftFix, y: selectionPositions[5].top - topFix }
                    poligonPoints[3] = { x: right, y: selectionPositions[5].top - topFix }
                    poligonPoints[5] = { x: selectionPositions[5].right - leftFix, y: bottom }
                    break
                case 7:
                    poligonPoints[0] = {
                        x: selectionPositions[0].left - leftFix,
                        y: selectionPositions[0].bottom - bottomFix
                    }
                    poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                    poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                    poligonPoints[4] = { x: selectionPositions[6].right - leftFix, y: selectionPositions[6].top - topFix }
                    poligonPoints[3] = { x: right, y: selectionPositions[6].top - topFix }
                    poligonPoints[5] = { x: selectionPositions[6].right - leftFix, y: bottom }
                    break
                case 8:
                    poligonPoints[0] = {
                        x: selectionPositions[0].left - leftFix,
                        y: selectionPositions[0].bottom - bottomFix
                    }
                    poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                    poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                    poligonPoints[4] = { x: selectionPositions[7].right - leftFix, y: selectionPositions[7].top - topFix }
                    poligonPoints[3] = { x: right, y: selectionPositions[7].top - topFix }
                    poligonPoints[5] = { x: selectionPositions[7].right - leftFix, y: bottom }
                    break
                default:
                    if (Object.keys(selectionPositions).length > 9 && Object.keys(selectionPositions).length < 20) {
                        poligonPoints[0] = {
                            x: selectionPositions[0].left - leftFix,
                            y: selectionPositions[0].bottom - bottomFix
                        }
                        poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                        poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                        poligonPoints[4] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
                        poligonPoints[3] = { x: right, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
                        poligonPoints[5] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: bottom }
                    } else if (Object.keys(selectionPositions).length > 19 && Object.keys(selectionPositions).length < 30) {
                        poligonPoints[0] = {
                            x: selectionPositions[0].left - leftFix,
                            y: selectionPositions[0].bottom - bottomFix
                        }
                        poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                        poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                        poligonPoints[4] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
                        poligonPoints[3] = { x: right, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
                        poligonPoints[5] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: bottom }
                    } else if (Object.keys(selectionPositions).length > 29 && Object.keys(selectionPositions).length < 200) {
                        poligonPoints[0] = {
                            x: selectionPositions[0].left - leftFix,
                            y: selectionPositions[0].bottom - bottomFix
                        }
                        poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
                        poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
                        poligonPoints[4] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
                        poligonPoints[3] = { x: right, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
                        poligonPoints[5] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: bottom }
                    } else  {
                        for (let i = 0; i < selectionPositions.length; i++) {
                            if ((selectionPositions[i].top - topFix === top) && (selectionPositions[i].left - leftFix > left)) {
                                poligonPoints[0] = {
                                    x: selectionPositions[0].left - leftFix,
                                    y: selectionPositions[i].bottom - bottomFix
                                }
                                poligonPoints[7] = {x: left, y: selectionPositions[i].bottom - bottomFix}
                                poligonPoints[1] = {x: selectionPositions[0].left - leftFix, y: top}
                            }

                            if ((selectionPositions[i].bottom - bottomFix === bottom) && (selectionPositions[i].right - leftFix < right)) {
                                poligonPoints[4] = {x: selectionPositions[i].right - leftFix, y: selectionPositions[i].top - topFix}
                                poligonPoints[3] = {x: right, y: selectionPositions[i].top - topFix}
                                poligonPoints[5] = {x: selectionPositions[i].right - leftFix, y: bottom}
                            }
                        }
                    }
                    break
            }
        }

        return poligonPoints
    }

    async printDocument() {
        const {visibleToDownload} = this.props
        const { canvasDataOcrDownload , documentId} = this.state
        if (visibleToDownload !== false) {
            if (this.state.matchedText.length > 0) {
                await this.clearPreviousSearchedData()
            }
            window.scrollTo(0, 0)
            const {landscape, scale, totalPages, width, height, printAnnotation, divPrint, redactingMode} = this.state
            this.setState({
                showCloseButton: false,
                printLoading: true
            })
            const elements = document.querySelectorAll(`div#${divPrint}`)
            for (let i = 0; i < totalPages; i++) {
                elements[i].classList.add('show-pdf-page')
            }
            const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
            let canvasData = response1.data.pageRectangleMap;
            if (this.props.isOcr && canvasDataOcrDownload && Object.keys(canvasDataOcrDownload.pageRectangleMap) && Object.keys(canvasDataOcrDownload.pageRectangleMap).length > 0) {
                this.fill('rgba(0, 0, 0, 1)', documentId,1, canvasData)
                this._sketch.showAnnotation(printAnnotation)
            } else if (!this.props.isOcr) {
                this.fill('rgba(0, 0, 0, 1)', documentId,1, canvasData)
                this._sketch.showAnnotation(printAnnotation)
            }

            const parentElement = document.querySelector('#pdf-page')
            const HTML_Width = parentElement.clientWidth
            const PDF_Width = HTML_Width / scale
            const PDF_Height = height / scale
            let position = 0
            let canvas_image_width = parentElement.clientWidth / scale
            let canvas_image_height = parentElement.clientHeight / scale
            let heightLeft = canvas_image_height

            let pdf = null
            if (landscape) {
                pdf = new jsPDF('landscape', 'pt', [PDF_Width, PDF_Height])
            } else {
                pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
                pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
            }

            if (totalPages > 5) {
                let counter = totalPages
                let step = 5
                let from = 0
                const pages = Array.from(document.querySelectorAll(landscape ?
                    '#div-to-print-landscape' :
                    '#div-to-print-portrait'))
                while (counter > 0) {
                    if (counter < step) {
                        if (counter > 0) {
                            step = counter
                        }
                    }
                    counter -= step
                    const printElements = pages.slice(from, from + step + 1)
                    const printParent = document.createElement('div')
                    const parent = document.querySelectorAll('.redacted-section')[0]
                    printElements.forEach((item) => printParent.appendChild(item))
                    parent.appendChild(printParent)
                    from += step

                    canvas_image_width = printParent.clientWidth / scale
                    canvas_image_height = printParent.clientHeight / scale
                    heightLeft = canvas_image_height
                    await this.renderPdf(printParent, position, totalPages, scale, width, height, heightLeft, canvas_image_width, canvas_image_height, PDF_Height, landscape, pdf)

                    printParent.remove()
                    printElements.forEach((item) => parent.appendChild(item))
                }
                pdf.save(`${this.props.initialName.substring(0, this.props.initialName.lastIndexOf('.'))}.pdf`)
                this.setState({
                    showCloseButton: true,
                    printLoading: false
                })
                if (this.props.isOcr && canvasDataOcrDownload && Object.keys(canvasDataOcrDownload.pageRectangleMap) && Object.keys(canvasDataOcrDownload.pageRectangleMap).length > 0) {
                    this.hidePrintMode(totalPages, elements, redactingMode)
                } else if (!this.props.isOcr) {
                    this.hidePrintMode(totalPages, elements, redactingMode)
                }
            } else {
                html2canvas(parentElement, {allowTaint: true, scale}).then((canvas) => {
                    pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
                    heightLeft -= PDF_Height
                    while (heightLeft > 0) {
                        position = heightLeft - canvas_image_height
                        //pdf.addPage(landscape ? 'l' : 'p', [width / scale, height / scale])
                        pdf.addPage(landscape ? 'l' : 'p', 'p')
                        pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
                        heightLeft -= PDF_Height
                    }
                    pdf.save(`${this.props.initialName.substring(0, this.props.initialName.lastIndexOf('.'))}.pdf`)
                    this.setState({
                        showCloseButton: true,
                        printLoading: false
                    })
                    if (this.props.isOcr && canvasDataOcrDownload && Object.keys(canvasDataOcrDownload.pageRectangleMap) && Object.keys(canvasDataOcrDownload.pageRectangleMap).length > 0) {
                        this.hidePrintMode(totalPages, elements, redactingMode)
                    } else if (!this.props.isOcr) {
                        this.hidePrintMode(totalPages, elements, redactingMode)
                    }
                    //this.hidePrintMode(totalPages, elements, redactingMode)
                })
            }
        }
    }

    hidePrintMode = async (totalPages, elements, redactionMode) => {
        const {documentId} = this.state
        //hide back all pages except current
        for (let i = 0; i < totalPages; i++) {
            if (Number(elements[i].getAttribute('data-pageid')) === 1) {
                elements[i].classList.add('show-pdf-page')
            } else {
                elements[i].classList.remove('show-pdf-page')
            }
        }
        if (redactionMode) {
            const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
            let canvasData = response1.data.pageRectangleMap;
            this.fill('rgba(0, 0, 0, 0.5)', documentId, 0.5, canvasData )
        }
        this._sketch.showAnnotation(true)
    }

    renderPdf(printParent, position, totalPages, scale, width, height, heightLeft, canvas_image_width, canvas_image_height, PDF_Height, landscape, pdf) {
        return new Promise(function(resolve) {
            html2canvas(printParent, { allowTaint: true, dpi: 300 }).then((canvas) => {
                pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
                heightLeft -= PDF_Height
                while (heightLeft > 0) {
                    position = heightLeft - canvas_image_height
                    //pdf.addPage(landscape ? 'l' : 'p', [ width / scale, height / scale ])
                    pdf.addPage(landscape ? 'l' : 'p', 'p')
                    pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
                    heightLeft -= PDF_Height
                }
                resolve()
            })
        })
    }


    fill = (color, documentId, alpha, canvasData) => {
        for (let i = 1; i <= this.state.totalPages; i++) {
            this.refs[i].fill(color, documentId, i, alpha, canvasData)
        }
    }


    removeObj = () => {
        this.hideCanvasModals()
        this._sketch.removeSelected()
        this.setState({
            haveChanges: true,
            deleteSelectedRedactionBox: true,
        }, () => {
            setTimeout(() => {
                this.savePageOnButton()
            }, 1000)
        })
    }

    setPage = (pageNumber) => {
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        for (let i = 0; i < this.state.totalPages; i++) {
            if (Number(elements[i].getAttribute('data-pageid')) === +pageNumber) {
                elements[i].classList.add('show-pdf-page')
            } else {
                elements[i].classList.remove('show-pdf-page')
            }
        }
        this.setState({ pageNumber: pageNumber })

    }

    loadPoints = (canvasData) => {
        if (canvasData) {
            if (this._sketch) {
                this._sketch.clearTemporary()
            }
            const elements = canvasData
            const elemstsJson = []
            elements.forEach((item) => {
                elemstsJson.push(JSON.parse(item))
            })
            elemstsJson.forEach((point) => {
                if (this._sketch) {
                    this._sketch.fillPoints(point, 'rgba(0,0,0,0.5)')
                }
            })
        }

    }

    changePageWithClear = (newPageNumber, annt, statusPageSidebar) => {
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        if (elements) {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
                    elements[i].classList.add('show-pdf-page')
                } else if (elements[i]) {
                    elements[i].classList.remove('show-pdf-page')
                }
            }
        }
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }

        const points = this.getRectanglesForPreviewPage()
        //console.log('START SAVE 3333333333333333333333333333333333333333333333333333')
        this.updateRectangles(points, statusPageSidebar)
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.hideCanvasModals()
        this.setState({
            pageNumber: newPageNumber,
            canvasData: initialCanvasData,
            forPageNumber: newPageNumber,
            haveChanges: false,
        }, () => {
            if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                    if (annt) {
                        if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                            || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
                            rectangle.set('stroke', 'rgb(255,244,11)')
                            rectangle.set('strokeWidth', 2)
                        }
                    }
                })
                canvas.renderAll()
            } else {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                })
                canvas.renderAll()
            }
        })
        this._sketch.clear()
    }
    onDefaultChange = () => {
        return true
    }

    onRectanglesToRemoveMixed2 = (pointsToSave, pagePointRectanglesMove) => {
        const { canvasDataCopy } = this.state
        const pagePointSumNewUpdate = []
        const pagePointSumTotalPoints = []
        let pagePointSumDeleted = []
        const rectanglesToRemove = []
         //console.log('canvasDataCopy REMOVE 11111111111111111111111 ', canvasDataCopy)
         //console.log('pointsToSave REMOVE 11111111111111111111111 ', pointsToSave)
        if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
            canvasDataCopy[this.state.pageNumber].forEach((item) => {
                if (item) {
                    let itemDecoded = item
                    if (typeof item === 'string') {
                        itemDecoded = JSON.parse(item)
                    } else if (typeof item === 'object') {
                        itemDecoded = item
                    }
                    if (itemDecoded.pagePointSum && !itemDecoded.defaultSmartviewOCR) {
                        ////console.log('itemDecoded REMOVE 11111111111111111111111 AAAAAAAAAAAAAAAAAAAAAAAAAAAAA ', itemDecoded)
                        pagePointSumTotalPoints.push(itemDecoded.pagePointSum)
                    }
                }
            })
        }

        Object.keys(pointsToSave).forEach((key) => {
            if (pointsToSave[key] && pointsToSave[key].length) {
                pointsToSave[key].forEach((item) => {
                    if (item) {
                        let itemDecoded = item
                        if (typeof item === 'string') {
                            itemDecoded = JSON.parse(item)
                        } else if (typeof item === 'object') {
                            itemDecoded = item
                        }
                        if (itemDecoded.pagePointSum) {
                            ////console.log('itemDecoded REMOVE 11111111111111111111111 BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB ', itemDecoded)
                            pagePointSumNewUpdate.push(itemDecoded.pagePointSum)
                        }
                    }
                })
            }
        })
        // //console.log('pagePointSumTotalPoints REMOVE 2222222222222222222222 ', pagePointSumTotalPoints)
        // //console.log('pagePointSumNewUpdate REMOVE 2222222222222222222222 ', pagePointSumNewUpdate)
        pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3))

        if (pagePointRectanglesMove && pagePointRectanglesMove.length > 0) {
            pagePointSumDeleted = pagePointSumDeleted.concat(pagePointRectanglesMove)
        }

        if (pagePointSumDeleted && pagePointSumDeleted.length > 0) {
            pagePointSumDeleted.forEach((elem) => {
                rectanglesToRemove.push({
                    key: elem,
                    type: 'manual'
                })
            })
        }
        return rectanglesToRemove
    }

    onRectanglesToRemoveMixed = (pointsToSave) => {
        const { canvasDataCopy } = this.state
        const dtoRedactEachWordOcr = []

        const pagePointSumNewUpdate = []
        const pagePointSumTotalPoints = []
        let pagePointSumDeleted = []
        const rectanglesToRemove = []
        if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
            canvasDataCopy[this.state.pageNumber].forEach((item) => {
                if (item) {
                    let itemDecoded = item
                    if (typeof item === 'string') {
                        itemDecoded = JSON.parse(item)
                    } else if (typeof item === 'object') {
                        itemDecoded = item
                    }
                    if (itemDecoded.pagePointSum && itemDecoded.x1 && itemDecoded.x2 && itemDecoded.y1 && itemDecoded.y2) {
                        pagePointSumTotalPoints.push({
                            stringValue: `x1-${itemDecoded.x1}-x2-${itemDecoded.x2}-y1-${itemDecoded.y1}-y2-${itemDecoded.y2}-pagePointSum-${itemDecoded.pagePointSum}`,
                            pagePointSumValue: itemDecoded.pagePointSum
                        })
                    }
                }
            })
        }

        Object.keys(pointsToSave).forEach((key) => {
            if (pointsToSave[key] && pointsToSave[key].length) {
                pointsToSave[key].forEach((item) => {
                    if (item) {
                        let itemDecoded = item
                        if (typeof item === 'string') {
                            itemDecoded = JSON.parse(item)
                        } else if (typeof item === 'object') {
                            itemDecoded = item
                        }
                        //console.log('itemDecoded 777777777777777777777777777777777777777777', itemDecoded)
                        if (itemDecoded.pagePointSum && itemDecoded.x1 && itemDecoded.x2 && itemDecoded.y1 && itemDecoded.y2) {
                            pagePointSumNewUpdate.push(`x1-${itemDecoded.x1}-x2-${itemDecoded.x2}-y1-${itemDecoded.y1}-y2-${itemDecoded.y2}-pagePointSum-${itemDecoded.pagePointSum}`)
                        }
                    }
                })
            }
        })
        //console.log('pagePointSumTotalPoints REMOVE 33333333333333333333333 ', pagePointSumTotalPoints)
        //console.log('pagePointSumNewUpdate REMOVE 33333333333333333333333333 ', pagePointSumNewUpdate)
        pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3.stringValue))
        //console.log('pagePointSumDeleted REMOVE 444444444444444444444444444444 ', pagePointSumDeleted)
        const pagePointSumDeletedFinish = []
        const pointsToSaveNew = []
        const pointsToSaveNew2 = {}
        pagePointSumDeleted && pagePointSumDeleted.length > 0 && pagePointSumDeleted.forEach((elem) => {
            pagePointSumDeletedFinish.push(elem.pagePointSumValue)
        })

        Object.keys(pointsToSave).forEach((key) => {
            if (pointsToSave[key] && pointsToSave[key].length) {
                pointsToSave[key].forEach((item) => {
                    if (item) {
                        let itemDecoded = item
                        if (typeof item === 'string') {
                            itemDecoded = JSON.parse(item)
                        } else if (typeof item === 'object') {
                            itemDecoded = item
                        }
                        if (pagePointSumDeletedFinish.includes(itemDecoded.pagePointSum)) {
                            pointsToSaveNew.push(item)
                            pointsToSaveNew2[this.state.pageNumber] = pointsToSaveNew
                        }
                    }
                })
            }
        })
        //console.log('pagePointSumDeletedFinish REMOVE 444444444444444444444444444444 ', pagePointSumDeletedFinish)
        if (pagePointSumDeletedFinish && pagePointSumDeletedFinish.length > 0) {
            pagePointSumDeletedFinish.forEach((elem) => {
                rectanglesToRemove.push({
                    key: elem,
                    type: 'manual'
                })
            })
        }
        return rectanglesToRemove
    }
    onRectanglesToRemove = (pointsToSave) => {
        const { canvasDataCopy } = this.state
        const pagePointSumNewUpdate = []
        const pagePointSumTotalPoints = []
        let pagePointSumDeleted = []
        const rectanglesToRemove = []
        //console.log('canvasDataCopy REMOVE 11111111111111111111111 ', canvasDataCopy)
        //console.log('pointsToSave REMOVE 11111111111111111111111 ', pointsToSave)
        if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
            canvasDataCopy[this.state.pageNumber].forEach((item) => {
                if (item) {
                    let itemDecoded = item
                    if (typeof item === 'string') {
                        itemDecoded = JSON.parse(item)
                    } else if (typeof item === 'object') {
                        itemDecoded = item
                    }
                    if (itemDecoded.pagePointSum) {
                        pagePointSumTotalPoints.push(itemDecoded.pagePointSum)
                    }
                }
            })
        }

        Object.keys(pointsToSave).forEach((key) => {
            if (pointsToSave[key] && pointsToSave[key].length) {
                pointsToSave[key].forEach((item) => {
                    if (item) {
                        let itemDecoded = item
                        if (typeof item === 'string') {
                            itemDecoded = JSON.parse(item)
                        } else if (typeof item === 'object') {
                            itemDecoded = item
                        }
                        if (itemDecoded.pagePointSum) {
                            pagePointSumNewUpdate.push(itemDecoded.pagePointSum)
                        }
                    }
                })
            }
        })
        //console.log('pagePointSumTotalPoints REMOVE 2222222222222222222222 ', pagePointSumTotalPoints)
        //console.log('pagePointSumNewUpdate REMOVE 2222222222222222222222 ', pagePointSumNewUpdate)
        pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3))

        if (pagePointSumDeleted && pagePointSumDeleted.length > 0) {
            pagePointSumDeleted.forEach((elem) => {
                rectanglesToRemove.push({
                    key: elem,
                    type: 'manual'
                })
            })
        }
        return rectanglesToRemove
    }

    changePageMixed = (newPageNumber, annt, statusPageSidebar, indexEl, arrayQuery) => {
        const { canvasDataCopy, deleteSelectedRedactionBox, canvasDataMixedOcr } = this.state
        this.setState({
            statusClickPageSidebar: statusPageSidebar,
            stateSidebarPages: false
        })
        const listPoints = []
        const listPointsNewAdd = []
        const dtoRedact = []
        const pointsToSave = {}
        const pointsToSaveNewAdd = {}
        const pointsToSave2 = {}
        let pagePointSumDeleted = []
        let rectanglesToRemove = []
        let rectanglesToRemoveMixed = []
        let rectanglesToRemoveMixed2 = []
        const pagePointRectanglesMove = []
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        if (elements) {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
                    elements[i].classList.remove('show-pdf-page')
                    elements[i].classList.add('show-pdf-page')
                } else if (elements[i]) {
                    elements[i].classList.remove('show-pdf-page')
                }
            }
        }

        //add active class to pages thumbnails
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }
        let maxHeight //= 10000
        const elementsMax = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elementsMax.length > 0) {
            maxHeight = elementsMax[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        const points = this.getRectanglesForPreviewPageMixed()
        const uniqueIdPagePointSum = Math.floor(Math.random() * 10000000000).toString()
        ////console.log('START SAVE 33333333333333333333333333333333333333333333333333 points points', points)
        points.forEach((element) => {
                //console.log('element AAAAAAAAAAAAAAAAAAA', element)
                let y1 = element.y1 - 1
                if (y1 > maxHeight) {
                    y1 = y1 - maxHeight
                }

                let y2 = element.y2
                if (y2 > maxHeight) {
                    y2 = y2 - maxHeight
                }
                const obj = {
                    x1: element.x1,
                    x2: element.x2 - 1,
                    y1: y1,
                    y2: y2,
                    annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                    text: element.text ? element.text : '',
                    type: element.type,
                    typeMixed: element.typeMixed,
                    pagePointSum: element.typeMixed === 13 ? uniqueIdPagePointSum : element.pagePointSum,
                    pagePointSumAnnotate: element.pagePointSum,
                    points: element.points,
                    label: element.label,
                    picture: true,
                    keyUI: element.keyUI,
                    category: element.category ? element.category : 'Smartviewer',
                    subcategory: element.subcategory ? element.subcategory : 'Freehand',
                    color: element.color,
                    colorRedaction: element.colorRedaction,
                    annotationRedaction: element.annotationRedaction,
                }

                const stringObj = JSON.stringify(obj)
                listPoints.push(stringObj)
                pointsToSave[this.state.pageNumber] = listPoints
            if (element.typeMixed && (element.typeMixed === 10 || element.typeMixed === 11 || element.typeMixed === 12 || element.typeMixed === 13)) {
                const stringObjNewAdd = JSON.stringify(obj)
                listPointsNewAdd.push(stringObjNewAdd)
                pointsToSaveNewAdd[this.state.pageNumber] = listPointsNewAdd
            }
            if (element.typeMixed && (element.typeMixed === 12)) {
                pagePointRectanglesMove.push(element.pagePointSum)
            }
        })
        // console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 this.state.pageNumber', this.state.pageNumber)
        // console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 newPageNumber', newPageNumber)
        const dataToSaveRedactAll = {
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSaveNewAdd

        }
        dtoRedact.push(dataToSaveRedactAll)

        // console.log('START SAVE 444444444444444444444444444444444444444444444444 part2')
        // console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 pointsToSave', pointsToSave)
        // console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 pointsToSaveNewAdd', pointsToSaveNewAdd)
        // console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 canvasDataCopy', canvasDataCopy)
        // console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 pagePointRectanglesMove', pagePointRectanglesMove)
        // console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 dataToSaveRedactAll', dataToSaveRedactAll)
            rectanglesToRemove = this.onRectanglesToRemove(pointsToSave)
            //rectanglesToRemoveMixed = this.onRectanglesToRemoveMixed(pointsToSave)
            rectanglesToRemoveMixed2 = this.onRectanglesToRemoveMixed2(pointsToSave, pagePointRectanglesMove)
        //console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 rectanglesToRemove', rectanglesToRemove)
        //console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 rectanglesToRemoveMixed', rectanglesToRemoveMixed)
        //console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 rectanglesToRemoveMixed2', rectanglesToRemoveMixed2)
        //console.log('START SAVE 444444444444444444444444444444444444444444444444 part2 dtoRedact', dtoRedact)
        const saveCanvasSmartviewMixedDTO = {
            idDoc: this.props.documentId,
            keyWordNonOcr: [],
            keyWordNonOcrRegex: [],
            keyWordNonOcrDictionary: [],
            keyWordOcr: [],
            keyWordOcrRegex: [],
            keyWordOcrDictionary: [],
            rectanglesToRemove: rectanglesToRemoveMixed2,
            markedAsAudit: true,
            pageCoordinates: dtoRedact,
            //coordsToDelete: []
        }
        if ((Object.keys(pointsToSaveNewAdd) && Object.keys(pointsToSaveNewAdd).length > 0) || (rectanglesToRemoveMixed2 && rectanglesToRemoveMixed2.length > 0)) {
            this.onCallToDisableScroll()
            this.setState({
                checkStartRedaction: true
            }, () => {
                apiSmartBoxSaveCanvasDocumentSmartviewMixed2(saveCanvasSmartviewMixedDTO).then((response1) => {
                    //console.log('response1.data.pageRectangleMap HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH', response1.data.pageRectangleMap)
                    let pageRectangleMap = this.convertCanvasDataCopyMixed(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
                    let pageRectangleMapNew = this.convertCanvasDataCopyMixedNew(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)

                    //console.log('pageRectangleMap GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', pageRectangleMap)
                    if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0 && Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length > 0) {
                        Object.keys(canvasDataMixedOcr).forEach((item) => {
                            Object.keys(pageRectangleMap).forEach((item2) => {
                                if (item === item2) {
                                    pageRectangleMap[item2] = canvasDataMixedOcr[item].concat(pageRectangleMap[item2])
                                }

                            })
                        })
                    } else if (Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length > 0) {
                        pageRectangleMap = pageRectangleMap
                    } else if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0) {
                        pageRectangleMap = canvasDataMixedOcr
                    }


                    // if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0 && Object.keys(pageRectangleMapNew) && Object.keys(pageRectangleMapNew).length > 0) {
                    //     Object.keys(canvasDataMixedOcr).forEach((item) => {
                    //         Object.keys(pageRectangleMapNew).forEach((item2) => {
                    //             if (item === item2) {
                    //                 pageRectangleMapNew[item2] = canvasDataMixedOcr[item].concat(pageRectangleMapNew[item2])
                    //             }
                    //
                    //         })
                    //     })
                    // } else if (Object.keys(pageRectangleMapNew) && Object.keys(pageRectangleMapNew).length > 0) {
                    //     pageRectangleMapNew = pageRectangleMapNew
                    // } else if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0) {
                    //     pageRectangleMapNew = canvasDataMixedOcr
                    // }

                    this.setState({
                        canvasData: pageRectangleMap,
                        canvasDataCopy: pageRectangleMapNew,
                        statusSaveSmartviewMixed2: response1.status,
                        deleteSelectedRedactionBox: false,
                        checkStartRedaction: false,
                        annotationText: ''
                    })
                    this.onRemoveEventDisableScroll()
                })
            })
        }

        //const points = this.getRectanglesForPreviewPage()
        // this.updateRectangles(points, statusPageSidebar, indexEl, arrayQuery)
        //  const initialCanvasData = this.state.canvasData
        //  initialCanvasData[this.state.pageNumber] = points
        this.hideCanvasModals()
        this.setState({
            pageNumber: newPageNumber,
            //canvasData: initialCanvasData,
            forPageNumber: newPageNumber,
            haveChanges: false,
        }, () => {
            if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                    if (annt) {
                        if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                            || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
                            rectangle.set('stroke', 'rgb(255,244,11)')
                            rectangle.set('strokeWidth', 2)
                        }
                    }
                })
                canvas.renderAll()
            } else {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                })
                canvas.renderAll()
            }
        })
    }

    onGetRedactedKeysMixed = () => {
        getRedactedKeysMixed({id: this.props.documentId}).then((response) => {
            //console.log('responseAAAAAAAAAAAAAAAAAAA SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', response.data)
            this.setState({
                alreadyRedactedKeys: response.data,
            })
        })
    }
    changePage = (newPageNumber, annt, statusPageSidebar, indexEl, arrayQuery) => {
        this.setState({
            statusClickPageSidebar: statusPageSidebar,
            stateSidebarPages: false
        })
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        if (elements) {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
                    elements[i].classList.remove('show-pdf-page')
                    elements[i].classList.add('show-pdf-page')
                } else if (elements[i]) {
                    elements[i].classList.remove('show-pdf-page')
                }
            }
        }

        //add active class to pages thumbnails
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }

        const points = this.getRectanglesForPreviewPage()
       // console.log('START SAVE 444444444444444444444444444444444444444444444444')
        this.updateRectangles(points, statusPageSidebar, indexEl, arrayQuery)
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.hideCanvasModals()
        this.setState({
            pageNumber: newPageNumber,
            canvasData: initialCanvasData,
            forPageNumber: newPageNumber,
            haveChanges: false,
        }, () => {
            if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                    if (annt) {
                        if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                            || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
                            rectangle.set('stroke', 'rgb(255,244,11)')
                            rectangle.set('strokeWidth', 2)
                        }
                    }
                })
                canvas.renderAll()
            } else {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                })
                canvas.renderAll()
            }
        })
    }

    changePageRedacted = (newPageNumber, annt) => {
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        for (let i = 0; i < this.state.totalPages; i++) {
            if (Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
                elements[i].classList.add('show-pdf-page')
            } else {
                elements[i].classList.remove('show-pdf-page')
            }
        }
        //add active class to pages thumbnails
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }

        this.setState({
            pageNumber: newPageNumber,
            forPageNumber: newPageNumber,
            haveChanges: false,
        }, () => {
            const canvas = this._sketch._fc
            canvas._objects.forEach((rectangle) => {
                rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                rectangle.set('strokeWidth', 1)
                if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                    || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
                    rectangle.set('stroke', 'rgb(255,244,11)')
                    rectangle.set('strokeWidth', 2)
                }
            })
            canvas.renderAll()
        })
    }

    savePointsOnPage = (points, pageNr) => {
       // console.log('START SAVE 55555555555555555555555555555555555555555555555555555555')
        this.updateRectangles(points)
        this.setState((canvasData) => ({ ...canvasData, [pageNr]: points }))
    }

    savePage = () => {
        const points = this.getRectanglesForPreviewPage()
       // console.log('START SAVE 111111111111111111111111111111111111111111111')
        this.updateRectangles(points, 'pageSidebar')
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.setState({
            haveChanges: true,
            canvasData: initialCanvasData
        })
    }
    savePageAfterRemove = (dtoRemoveCanvas, alreadyRedactedKeys, parameters, unCheckedKeys, classNameList, source, dtoRemoveCanvasAllCanvas) => {
        const points = this.getRectanglesForPreviewPage()
       // console.log('VECHI 333333333333333333333333333333333333333')
        this.updateRectanglesAfterRemove(points, dtoRemoveCanvas, alreadyRedactedKeys, parameters, unCheckedKeys, classNameList, source, dtoRemoveCanvasAllCanvas)
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.setState({
            haveChanges: true,
            canvasData: initialCanvasData
        })
    }
    updateRectanglesAfterRemove = (points, dtoRemoveCanvas, alreadyRedactedKeys, parameters, unCheckedKeys, classNameList, source, dtoRemoveCanvasAllCanvas) => {
        const { treeDataAnnotations, canvasDataCopy, canvasData } = this.state
        const pointsToSave = {}
        const listPoints = []
        let maxHeight //= 10000
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elements.length > 0) {
            maxHeight = elements[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        points.forEach((element) => {
            let y1 = element.y1 - 1
            if(y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if(y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true
            }
            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            this.setState({
                pagePointSumList: element.pagePointSum,
            })
        })

        //START
        const canvasDataCopyListFinally = []
        let key2 = 0
        if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
            for (let i = 1; i <= this.state.totalPages; i++) {
                if (canvasDataCopy[i] && canvasDataCopy[i].length > 0) {
                    for (let j = 0; j < canvasDataCopy[i].length; j++) {
                        key2++
                        const parsed = typeof canvasDataCopy[i][j].annotation === 'undefined' ?
                            JSON.parse(canvasDataCopy[i][j]) : canvasDataCopy[i][j]
                        canvasDataCopyListFinally.push(parsed)
                    }
                }
            }
        }
        const pointsUpdate2 = canvasDataCopyListFinally
        const groupsChildren2 = {}
        const groupsChildren3 = []
        pointsUpdate2 && pointsUpdate2.length > 0 && pointsUpdate2.forEach((child) => {
            const { text, annotation } = child
            if (text) {
                if (!groupsChildren2[text]) {
                    groupsChildren2[text] = {
                        name: text,
                        children: []
                    }
                }
                child.children = groupsChildren2[text].children
                groupsChildren2[text].children.push(child)
            } else if (annotation) {
                if (!groupsChildren2[annotation]) {
                    groupsChildren2[annotation] = {
                        name: annotation,
                        children: []
                    }
                }
                child.children = groupsChildren2[annotation].children
                groupsChildren2[annotation].children.push(child)
            }

        })

        pointsUpdate2 && pointsUpdate2.length > 0 && pointsUpdate2.forEach((child2) => {
            child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3, index) => {
                if (child3.text) {
                    const keyName = child3.text.replace(/\W/g, 'a')
                    child3.indexChild = index
                    child3.key3 = `${keyName}-${index}`
                } else if (child3.annotation) {
                    const keyName = child3.annotation.replace(/\W/g, 'a')
                    child3.indexChild = index
                    child3.key4 = child3.annotation
                }
            })
        })

        const pagePointSumNewUpdate = []
        const pagePointSumTotalPoints = []
        let pagePointSumDeleted = []

        const dtoRemoveCanvasAllCanvasIds = []
        const dtoRemoveCanvasAllCanvasIds2 = []
        const pointsToSaveDelete = []
        dtoRemoveCanvasAllCanvas && dtoRemoveCanvasAllCanvas.length > 0 && dtoRemoveCanvasAllCanvas.forEach((elem) => {
            if (elem.key3) {
                dtoRemoveCanvasAllCanvasIds.push(elem.key3.toLowerCase())
            }
            if (elem.key) {
                dtoRemoveCanvasAllCanvasIds2.push(elem.key.toLowerCase())
            }
        })

        pointsUpdate2 && pointsUpdate2.length > 0 && pointsUpdate2.forEach((elem) => {
            elem.children && elem.children.length > 0 && elem.children.forEach((elem2) => {
                if (elem2.key3) {
                    if (dtoRemoveCanvasAllCanvasIds.includes(elem2.key3.toLowerCase())) {
                        pointsToSaveDelete.push(elem2.pagePointSum)
                    }
                } else if (elem2.key4) {
                    if (dtoRemoveCanvasAllCanvasIds2.includes(elem2.key4.toLowerCase())) {
                        pointsToSaveDelete.push(elem2.pagePointSum)
                    }
                }
            })
        })
        const uniquePointsToSaveDelete = [...new Set(pointsToSaveDelete.map((item) => item))]
        //solutions 1
        const saveCanvasDocumentSmartViewDTO = {
            idDoc: this.props.documentId,
            pageCoordinates: [],
            coordsToDelete: uniquePointsToSaveDelete

        }

        const dataToSave = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSave

        }
        // const dataGetPoints = {
        //   idDoc: this.props.documentId,
        //   page: this.state.pageNumber,
        // }
        const selectAllItemsIds = []
        treeDataAnnotations && treeDataAnnotations.length > 0 && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    child3 && child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                        selectAllItemsIds.push(child4.key)
                    })
                })
            })
        })
        // const selectedStandard = []
        // selectAllItemsIds && selectAllItemsIds.length > 0 && selectAllItemsIds.forEach((item) => {
        //      if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
        //        if (alreadyRedactedKeys.standard.includes(item)) {
        //          selectedStandard.push(item)
        //        }
        //      }
        // })
        const uniqueStandard = selectAllItemsIds && selectAllItemsIds.length > 0 && selectAllItemsIds.filter((a, i) => selectAllItemsIds.findIndex((s) => a === s) === i)
        if (this.props.isSmartBoxApplication) {
            if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
                // console.log('SAVE ---- 2222222222222222222222222222222')
                //console.log('VECHI 4444444444444444444444444444444444444444444444444444')
                apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                    this.setState({
                        statusDrawRedaction: false
                    })
                    if (response.status === 200) {
                        apiSmartBoxRemoveCanvas(dtoRemoveCanvas).then((response) => {
                            if (response.status === 200) {
                                this.setState({
                                    statusAfterRemove: true,
                                    checkStartRedaction: false
                                }, () => {
                                    this.fetchSmartBoxAllAfterRemove(this.state.documentId, this.state.clientId)
                                    this.onRemoveEventDisableScroll()
                                    if (source === 'standard') {
                                        this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
                                        if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
                                            this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
                                        }
                                        if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
                                            this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
                                        }
                                    } else if (source === 'dictionaries') {
                                        this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
                                        if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
                                            this.initialUpdateCanvas(parameters, uniqueStandard, unCheckedKeys, 'standard')
                                        }
                                        if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
                                            this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
                                        }
                                    } else if (source === 'regex') {
                                        this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
                                        if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
                                            this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
                                        }
                                        if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
                                            this.initialUpdateCanvas(parameters, uniqueStandard, unCheckedKeys, 'standard')
                                        }
                                    }
                                })
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    } else {
                        console.log(error)
                    }
                })
            }
            // if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
            //   apiSmartBoxUpdateCanvasPointsSaved(dataToSave).then((response) => {
            //       if (response.status === 200) {
            //         apiSmartBoxRemoveCanvas(dtoRemoveCanvas).then((response) => {
            //           if (response.status === 200) {
            //             this.setState({
            //               statusAfterRemove: true,
            //               checkStartRedaction: false
            //             }, () => {
            //               this.fetchSmartBoxAllAfterRemove(this.state.documentId, this.state.clientId)
            //               this.onRemoveEventDisableScroll()
            //               if (source === 'standard') {
            //                 this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
            //                 if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
            //                   this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
            //                 }
            //                 if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
            //                   this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
            //                 }
            //               } else if (source === 'dictionaries') {
            //                 this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
            //                 if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
            //                   this.initialUpdateCanvas(parameters, uniqueStandard, unCheckedKeys, 'standard')
            //                 }
            //                 if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
            //                   this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
            //                 }
            //               } else if (source === 'regex') {
            //                 this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
            //                 if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
            //                   this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
            //                 }
            //                 if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
            //                   this.initialUpdateCanvas(parameters, uniqueStandard, unCheckedKeys, 'standard')
            //                 }
            //               }
            //             })
            //           }
            //         })
            //       }
            //     }).catch((error) => {
            //       if (error.response.status === 504) {
            //         CustomNotificationManager.cleanBuffer('warning', '')
            //       }else{
            //         console.log(error)
            //       }
            //     })
            // }
            this.setState({
                statusSavedSmartView: true
            })
        }
    }
    savePageOnButton = () => {
        const points = this.getRectanglesForPreviewPage()
        //this.updateRectanglesSaved(points)
        // const initialCanvasData = this.state.canvasData
        // initialCanvasData[this.state.pageNumber] = points
        this.setState({
            haveChanges: true,
            //canvasData: initialCanvasData
        })
    }
    updateRectanglesSaved = (points) => {
        const { canvasDataCopy, pageNumber, deleteSelectedRedactionBox, canvasData} = this.state
        const pointsToSave = {}
        const listPoints = []
        points.forEach((element) => {
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: element.y1 - 1,
                y2: element.y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true
            }
            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            this.setState({
                pagePointSumList: element.pagePointSum,
            })
        })
        const pagePointSumNewUpdate = []
        const pagePointSumTotalPoints = []
        let pagePointSumDeleted = []
        //Object.keys(canvasDataCopy).forEach((key) => {
        if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
            canvasDataCopy[this.state.pageNumber].forEach((item) => {
                if (item) {
                    let itemDecoded = item
                    if (typeof item === 'string') {
                        itemDecoded = JSON.parse(item)
                    } else if (typeof item === 'object') {
                        itemDecoded = item
                    }
                    if (itemDecoded.pagePointSum) {
                        pagePointSumTotalPoints.push(itemDecoded.pagePointSum)
                    }
                }
            })
        }
        //})
        Object.keys(pointsToSave).forEach((key) => {
            if (pointsToSave[key] && pointsToSave[key].length) {
                pointsToSave[key].forEach((item) => {
                    if (item) {
                        let itemDecoded = item
                        if (typeof item === 'string') {
                            itemDecoded = JSON.parse(item)
                        } else if (typeof item === 'object') {
                            itemDecoded = item
                        }
                        if (itemDecoded.pagePointSum) {
                            pagePointSumNewUpdate.push(itemDecoded.pagePointSum)
                        }
                    }
                })
            }
        })
        pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3))
        const dataToSave = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
            coordsToDelete: pagePointSumDeleted

        }
        if (this.props.isSmartBoxApplication) {
            if (deleteSelectedRedactionBox) {
                //SAVE ---- 33333333333333333333333333333333')
                //console.log('SAVE ---- 33333333333333333333333333333333')
                apiSmartBoxSaveCanvasDocumentSmartview(dataToSave).then(() => {
                    this.setState({
                        statusSaveCanvasDeleteSection: false,
                        deleteSelectedRedactionBox: false,
                    })
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            } else {
                this.setState({
                    statusSaveCanvasDeleteSection: true
                }, () => {
                     //console.log('SAVE ---- 44444444444444444444444444444444444444')
                    apiSmartBoxSaveCanvasDocumentSmartview(dataToSave).then(() => {
                        this.setState({
                            statusSaveCanvasDeleteSection: false,
                            deleteSelectedRedactionBox: false,
                        })
                    }).catch((error) => {
                        if (error?.response?.status === 504) {
                            CustomNotificationManager.cleanBuffer('warning', '')
                        }else{
                            console.log(error)
                        }
                    })
                })
            }
            this.setState({
                statusSavedSmartView: true
            })
        } else {
            apiUpdateCanvasPoints(dataToSave).then(() => {

            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        }
    }
    getRectanglesForPreviewPage = () => {
        const points = []
        if (!this.state.renderCommentsText) {
            if (typeof this._sketch !== 'undefined') {
                const history = this._sketch._fc._objects
                for (let i = 0; i < history.length; i++) {
                    if (history[i].type !== 4) {
                        // console.log('history[i] AAAAAAAAAAAAAAAAAAAAAAAA', history[i])
                        points.push({
                            //idd: history[i].idd,
                            //Dragos: history[i].idd,
                            isOcr: false,
                            x1: history[i].aCoords.bl.x,
                            x2: history[i].aCoords.br.x,
                            y1: history[i].aCoords.bl.y,
                            y2: history[i].aCoords.tr.y,
                            text: history[i].text ? history[i].text : '',
                            annotation: history[i].annotation ? history[i].annotation : (history[i].text) ? history[i].text : '',
                            annotationType: Number(history[i].type),
                            type: Number(history[i].type),
                            pagePointSum: history[i].pagePointSum,
                            points: history[i].points,
                            picture: true
                        })
                    }
                    this.setState({
                        pagePointSumList: history[i].pagePointSum,
                    })
                }
            }
        }
        return points
    }

    updateRectangles = (points, statusPageSidebar, indexEl, arrayQuery) => {
        const { statusAfterRemove, stateSidebarPages, statusRedactAll, statusDrawRedaction, dtoRedactWord, sourceActive,
            canvasDataCopy, deleteSelectedRedactionBox } = this.state
        const pointsToSave = {}
        const pointsToSaveOcr = {}
        const listPoints = []
        const listPointsOcr = []
        const pointsOcr = []
        let maxHeight //= 10000
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elements.length > 0) {
            maxHeight = elements[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        if (this.props.isOcr) {
            points && points.length > 0 && points.forEach((itemPoint) => {
                if (itemPoint && itemPoint.annotationType && itemPoint.annotationType === 1) {
                    pointsOcr.push(itemPoint)
                }
            })
        }
        points.forEach((element) => {
            let y1 = element.y1 - 1
            if(y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if(y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true
            }
            this.onAddCategoryAndSubcategoryOnSavedRectangles(obj, element)
            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            this.setState({
                pagePointSumList: element.pagePointSum,
            })
        })
        if (this.props.isOcr) {
            pointsOcr.forEach((element) => {
                let y1 = element.y1 - 1
                if(y1 > maxHeight) {
                    y1 = y1 - maxHeight
                }

                let y2 = element.y2
                if(y2 > maxHeight) {
                    y2 = y2 - maxHeight
                }
                const obj = {
                    x1: element.x1,
                    x2: element.x2 - 1,
                    y1: y1,
                    y2: y2,
                    annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                    text: element.text ? element.text : '',
                    type: element.type,
                    pagePointSum: element.pagePointSum,
                    points: element.points,
                    label: element.label,
                    picture: true
                }
                this.onAddCategoryAndSubcategoryOnSavedRectangles(obj, element)
                const stringObj = JSON.stringify(obj)
                listPointsOcr.push(stringObj)
                pointsToSaveOcr[this.state.pageNumber] = listPointsOcr
                this.setState({
                    pagePointSumList: element.pagePointSum,
                })
            })
        }
        const dataToSave = {
            //idDoc: this.props.documentId,
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSave

        }
        const dataToSaveOcr = {
            //idDoc: this.props.documentId,
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSaveOcr

        }
        const dataGetPoints = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
        }
        if (this.props.isSmartBoxApplication) {
            if (!statusAfterRemove) {
                if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
                    if ((statusDrawRedaction || (statusPageSidebar && statusPageSidebar === 'pageSidebar')) && this.state.drawSelectedMouse === true && !stateSidebarPages) {
                        const dtoRedactEachWord = []
                        const dtoRedactEachWordOcr = []
                        dtoRedactEachWord.push(dataToSave)
                        dtoRedactEachWordOcr.push(dataToSaveOcr)

                        const pagePointSumNewUpdate = []
                        const pagePointSumTotalPoints = []
                        let pagePointSumDeleted = []
                        //Object.keys(canvasDataCopy).forEach((key) => {
                        if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
                            canvasDataCopy[this.state.pageNumber].forEach((item) => {
                                if (item) {
                                    let itemDecoded = item
                                    if (typeof item === 'string') {
                                        itemDecoded = JSON.parse(item)
                                    } else if (typeof item === 'object') {
                                        itemDecoded = item
                                    }
                                    if (itemDecoded.pagePointSum) {
                                        pagePointSumTotalPoints.push(itemDecoded.pagePointSum)
                                    }
                                }
                            })
                        }
                        Object.keys(pointsToSave).forEach((key) => {
                            if (pointsToSave[key] && pointsToSave[key].length) {
                                pointsToSave[key].forEach((item) => {
                                    if (item) {
                                        let itemDecoded = item
                                        if (typeof item === 'string') {
                                            itemDecoded = JSON.parse(item)
                                        } else if (typeof item === 'object') {
                                            itemDecoded = item
                                        }
                                        if (itemDecoded.pagePointSum) {
                                            pagePointSumNewUpdate.push(itemDecoded.pagePointSum)
                                        }
                                    }
                                })
                            }
                        })

                        pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3))


                        const saveCanvasDocumentSmartViewDTO = {
                            idDoc: this.props.documentId,
                            pageCoordinates: dtoRedactEachWord,
                            coordsToDelete: pagePointSumDeleted

                        }
                        const saveCanvasDocumentSmartViewDTOOcr = {
                            idDoc: this.props.documentId,
                            pageCoordinates: dtoRedactEachWordOcr,
                            coordsToDelete: pagePointSumDeleted

                        }
                        if (!this.props.isOcr) {
                            //console.log('SAVE ---- 5555555555555555555555555555555555')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                                this.setState({
                                    statusDrawRedaction: false
                                })
                            }).catch((error) => {
                                if (error?.response?.status === 504) {
                                    CustomNotificationManager.cleanBuffer('warning', '')
                                } else {
                                    console.log(error)
                                }
                            })
                        }
                        if (this.props.isOcr) {
                            //console.log('SAVE ---- 666666666666666666666666666666666666')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTOOcr).then((response) => {
                                this.setState({
                                    statusDrawRedaction: false
                                })
                            }).catch((error) => {
                                if (error?.response?.status === 504) {
                                    CustomNotificationManager.cleanBuffer('warning', '')
                                } else {
                                    console.log(error)
                                }
                            })
                        }
                        //update 15 Decembrie
                        // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
                        //     this.setState({
                        //       statusDrawRedaction: false
                        //     })
                        // }).catch((error) => {
                        //   if (error?.response?.status === 504) {
                        //     CustomNotificationManager.cleanBuffer('warning', '')
                        //   }else{
                        //     console.log(error)
                        //   }
                        // })

                        apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

                        }).catch((error) => {
                            if (error?.response?.status === 504) {
                                CustomNotificationManager.cleanBuffer('warning', '')
                            }else{
                                console.log(error)
                            }
                        })
                    } else if (this.state.statusAnnotationSidebar === 'annotationSidebar') {
                        const dtoRedactEachWord = []
                        const dtoRedactEachWordOcr = []
                        dtoRedactEachWord.push(dataToSave)
                        dtoRedactEachWordOcr.push(dataToSaveOcr)
                        const saveCanvasDocumentSmartViewDTO = {
                            idDoc: this.props.documentId,
                            pageCoordinates: dtoRedactEachWord

                        }
                        const saveCanvasDocumentSmartViewDTOOcr = {
                            idDoc: this.props.documentId,
                            pageCoordinates: dtoRedactEachWordOcr

                        }
                        if (!this.props.isOcr) {
                            //console.log('SAVE ---- 7777777777777777777777777777777')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                                this.setState({
                                    statusDrawRedaction: false,
                                    statusAnnotationSidebar: '',
                                })
                            }).catch((error) => {
                                if (error?.response?.status === 504) {
                                    CustomNotificationManager.cleanBuffer('warning', '')
                                } else {
                                    console.log(error)
                                }
                            })
                        }
                        if (this.props.isOcr) {
                             //console.log('SAVE ---- ttttttttttttttttttttttttttttttt')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTOOcr).then((response) => {
                                this.setState({
                                    statusDrawRedaction: false
                                })
                            }).catch((error) => {
                                if (error?.response?.status === 504) {
                                    CustomNotificationManager.cleanBuffer('warning', '')
                                } else {
                                    console.log(error)
                                }
                            })
                        }

                        apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

                        }).catch((error) => {
                            if (error?.response?.status === 504) {
                                CustomNotificationManager.cleanBuffer('warning', '')
                            }else{
                                console.log(error)
                            }
                        })
                    } else if (this.state.renderSelectToolMove && this.state.closeRedactingStatus) {
                        const dtoRedactEachWord = []
                        const dtoRedactEachWordOcr = []

                        const pagePointSumNewUpdate = []
                        const pagePointSumTotalPoints = []
                        let pagePointSumDeleted = []

                        if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
                            canvasDataCopy[this.state.pageNumber].forEach((item) => {
                                if (item) {
                                    let itemDecoded = item
                                    if (typeof item === 'string') {
                                        itemDecoded = JSON.parse(item)
                                    } else if (typeof item === 'object') {
                                        itemDecoded = item
                                    }
                                    if (itemDecoded.pagePointSum && itemDecoded.x1 && itemDecoded.x2 && itemDecoded.y1 && itemDecoded.y2) {
                                        pagePointSumTotalPoints.push({
                                            stringValue: `x1-${itemDecoded.x1}-x2-${itemDecoded.x2}-y1-${itemDecoded.y1}-y2-${itemDecoded.y2}-pagePointSum-${itemDecoded.pagePointSum}`,
                                            pagePointSumValue: itemDecoded.pagePointSum
                                        })
                                    }
                                }
                            })
                        }

                        Object.keys(pointsToSave).forEach((key) => {
                            if (pointsToSave[key] && pointsToSave[key].length) {
                                pointsToSave[key].forEach((item) => {
                                    if (item) {
                                        let itemDecoded = item
                                        if (typeof item === 'string') {
                                            itemDecoded = JSON.parse(item)
                                        } else if (typeof item === 'object') {
                                            itemDecoded = item
                                        }
                                        if (itemDecoded.pagePointSum && itemDecoded.x1 && itemDecoded.x2 && itemDecoded.y1 && itemDecoded.y2) {
                                            pagePointSumNewUpdate.push(`x1-${itemDecoded.x1}-x2-${itemDecoded.x2}-y1-${itemDecoded.y1}-y2-${itemDecoded.y2}-pagePointSum-${itemDecoded.pagePointSum}`)
                                        }
                                    }
                                })
                            }
                        })

                        pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3.stringValue))
                        const pagePointSumDeletedFinish = []
                        const pointsToSaveNew = []
                        const pointsToSaveNew2 = {}
                        pagePointSumDeleted && pagePointSumDeleted.length > 0 && pagePointSumDeleted.forEach((elem) => {
                            pagePointSumDeletedFinish.push(elem.pagePointSumValue)
                        })

                        Object.keys(pointsToSave).forEach((key) => {
                            if (pointsToSave[key] && pointsToSave[key].length) {
                                pointsToSave[key].forEach((item) => {
                                    if (item) {
                                        let itemDecoded = item
                                        if (typeof item === 'string') {
                                            itemDecoded = JSON.parse(item)
                                        } else if (typeof item === 'object') {
                                            itemDecoded = item
                                        }
                                        if (pagePointSumDeletedFinish.includes(itemDecoded.pagePointSum)) {
                                            pointsToSaveNew.push(item)
                                            pointsToSaveNew2[this.state.pageNumber] = pointsToSaveNew
                                        }
                                    }
                                })
                            }
                        })

                        const dataToSaveNew = {
                            page: this.state.pageNumber,
                            pageRectangleMap: pointsToSaveNew2,

                        }
                        const dataToSaveOcrNew = {
                            page: this.state.pageNumber,
                            // pageRectangleMap: pointsToSaveOcr,
                            pageRectangleMap: pointsToSaveNew2,

                        }
                        //dtoRedactEachWord.push(dataToSave)
                        dtoRedactEachWord.push(dataToSaveNew)
                        //dtoRedactEachWordOcr.push(dataToSaveOcr)
                        dtoRedactEachWordOcr.push(dataToSaveOcrNew)
                        const saveCanvasDocumentSmartViewDTO = {
                            idDoc: this.props.documentId,
                            pageCoordinates: dtoRedactEachWord,
                            coordsToDelete: pagePointSumDeletedFinish

                        }
                        const saveCanvasDocumentSmartViewDTOOcr = {
                            idDoc: this.props.documentId,
                            pageCoordinates: dtoRedactEachWordOcr,
                            coordsToDelete: pagePointSumDeletedFinish

                        }
                        if (!this.props.isOcr) {
                            //console.log('SAVE ---- 88888888888888888888888888888888888')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                                this.setState({
                                    statusDrawRedaction: false
                                })
                            }).catch((error) => {
                                if (error?.response?.status === 504) {
                                    CustomNotificationManager.cleanBuffer('warning', '')
                                } else {
                                    console.log(error)
                                }
                            })
                        }
                        if (this.props.isOcr) {
                            const pagePointSumNewUpdate3 = []
                            const pagePointSumTotalPoints3 = []
                            let pagePointSumDeleted3 = []
                            //Object.keys(canvasDataCopy).forEach((key) => {
                            if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
                                canvasDataCopy[this.state.pageNumber].forEach((item) => {
                                    if (item) {
                                        let itemDecoded = item
                                        if (typeof item === 'string') {
                                            itemDecoded = JSON.parse(item)
                                        } else if (typeof item === 'object') {
                                            itemDecoded = item
                                        }
                                        if (itemDecoded.pagePointSum) {
                                            pagePointSumTotalPoints3.push(itemDecoded.pagePointSum)
                                        }
                                    }
                                })
                            }
                            //})
                            Object.keys(pointsToSave).forEach((key) => {
                                if (pointsToSave[key] && pointsToSave[key].length) {
                                    pointsToSave[key].forEach((item) => {
                                        if (item) {
                                            let itemDecoded = item
                                            if (typeof item === 'string') {
                                                itemDecoded = JSON.parse(item)
                                            } else if (typeof item === 'object') {
                                                itemDecoded = item
                                            }
                                            if (itemDecoded.pagePointSum) {
                                                pagePointSumNewUpdate3.push(itemDecoded.pagePointSum)
                                            }
                                        }
                                    })
                                }
                            })
                            pagePointSumDeleted3 = pagePointSumTotalPoints3.filter((item3) => !pagePointSumNewUpdate3.includes(item3))
                            const saveCanvasDocumentSmartViewDTOOcr3 = {
                                idDoc: this.props.documentId,
                                pageCoordinates: dtoRedactEachWordOcr,
                                coordsToDelete: pagePointSumDeleted3

                            }
                              //console.log(' SAVE ---- rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTOOcr3).then((response) => {
                                this.setState({
                                    statusDrawRedaction: false
                                })
                            }).catch((error) => {
                                if (error?.response?.status === 504) {
                                    CustomNotificationManager.cleanBuffer('warning', '')
                                } else {
                                    console.log(error)
                                }
                            })
                        }
                    } else if (statusPageSidebar && statusPageSidebar === 'pageSidebar' && !stateSidebarPages) {
                        if (deleteSelectedRedactionBox) {
                            const points = this.getRectanglesForPreviewPage()
                            this.updateRectanglesSaved(points)
                        }
                        apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

                        }).catch((error) => {
                            if (error?.response?.status === 504) {
                                CustomNotificationManager.cleanBuffer('warning', '')
                            }else{
                                console.log(error)
                            }
                        })
                    } else if (deleteSelectedRedactionBox) {
                        const points = this.getRectanglesForPreviewPage()
                        this.updateRectanglesSaved(points)
                        apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

                        }).catch((error) => {
                            if (error?.response?.status === 504) {
                                CustomNotificationManager.cleanBuffer('warning', '')
                            }else{
                                console.log(error)
                            }
                        })
                    } else if(!statusPageSidebar && statusPageSidebar !== 'pageSidebar' && statusRedactAll !== 'true') {

                        const dtoRedactEachWord = []
                        dtoRedactEachWord.push(dataToSave)
                        const saveCanvasDocumentSmartViewDTO = {
                            idDoc: this.props.documentId,
                            pageCoordinates: dtoRedactEachWord

                        }
                        if (!this.props.isOcr) {
                             //console.log('SAVE ---- 999999999999999999999999999999999999999')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                                this.setState({
                                    statusDrawRedaction: false
                                })
                            }).catch((error) => {
                                if (error?.response?.status === 504) {
                                    CustomNotificationManager.cleanBuffer('warning', '')
                                } else {
                                    console.log(error)
                                }
                            })
                        }

                        // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
                        //
                        // }).catch((error) => {
                        //   if (error?.response?.status === 504) {
                        //     CustomNotificationManager.cleanBuffer('warning', '')
                        //   }else{
                        //     console.log(error)
                        //   }
                        // })
                    }
                } else {
                    if (deleteSelectedRedactionBox) {
                        const points = this.getRectanglesForPreviewPage()
                        this.updateRectanglesSaved(points)
                    }
                }
            }
            this.setState({
                statusSavedSmartView: true
            })
        } else {
            apiUpdateCanvasPoints(dataToSave).then(() => {

            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
            this.setState({
                statusAfterRemove: false
            })
        }
    }

    selectObject = (object, annotationObj) => {
        let annotationText = ''
        let objectHasAnnotation = false
        if (object.annotationType === 1) {
            if (annotationObj) {

                annotationText = annotationObj.text
                objectHasAnnotation = true
            }
        }
        // console.log('selectObject ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ', object)
        // console.log('annotationText ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ', annotationText)
        // console.log('annotationObj ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ', annotationObj)
        this.setState({
            annotationText: annotationText,
            objectHasAnnotation: objectHasAnnotation
        })
    }

    selectTool = () => {
        const allPagesCanvas = document.getElementsByClassName('canvas-area')
        this.setState({
            renderSelectTool: !this.state.renderSelectTool
        }, () => {
            if (!this.state.renderSelectTool) {
                this.setState({
                    disableClickOnPage: true,
                    removeSelectedBtnDisabled: true
                })
            } else {
                this.setState({
                    tool: Tools.Select,
                    selectedTool: 'select',
                    disableClickOnPage: false,
                    removeSelectedBtnDisabled: false
                })
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 1;')
                    }
                }
            }
        })
    }

    selectRectangle = () => {
        const allPagesCanvas = document.getElementsByClassName('canvas-area')
        this.setState({
            renderSelectDraw: !this.state.renderSelectDraw,
            renderSearch: false,
            renderSelectText: false,
            renderCommentsText: false,
            viewAnnotationsRedaction: false,
            renderSelectTool: false,
            displaySearchButtons: false,
            drawSelectedMouse: true,
        }, () => {
            if (this.state.renderSelectDraw) {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 1;')
                    }
                }
            } else {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 0;')
                    }
                }
            }
            this.setState({
                tool: Tools.Rectangle,
                selectedTool: 'draw',
            }, () => {
                if (!this.state.renderSelectDraw) {
                    this.setState({
                        disableClickOnPage: true
                    })
                } else {
                    this.setState({
                        disableClickOnPage: false
                    })
                }
            })
        })
    }

    selectText() {
        const allPagesCanvas = document.getElementsByClassName('canvas-area')
        //this._sketch._fc.remove()
        const history = this._sketch._fc._objects
        for (let i = 0; i < history.length; i++) {
            // if (history[i].type === 4) {
            //   this._sketch._fc.remove(history[i])
            // }
        }
        this.setState({
            renderSelectText: !this.state.renderSelectText,
            renderCommentsText: false,
            renderSelectDraw: false,
            renderSearch: false,
            renderSelectTool: false,
            viewAnnotationsRedaction: false,
            displaySearchButtons: false,
            drawSelectedMouse: true
        }, () => {
            if (this.state.renderSelectText) {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 0;')
                    }
                }
                this.setState({
                    valueSelectNavigation: 'review-by-page'
                })
                this.changeNavigation('redacting')
            } else {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 1;')
                    }
                }
                this.setState({
                    valueSelectNavigation: 'ai-results'
                })
                this.changeNavigation('find-asset')
            }
            this.setState({
                tool: Tools.Select,
            }, () => {
                if (!this.state.renderSelectText) {
                    this.setState({
                        disableClickOnPage: true
                    })
                } else {
                    this.setState({
                        disableClickOnPage: false
                    })
                }
            })
        })
    }

    selectComments() {
        const allPagesCanvas = document.getElementsByClassName('canvas-area')
        this.setState({
            //renderSelectText: !this.state.renderSelectText,
            renderSelectText: false,
            renderCommentsText: !this.state.renderCommentsText,
            renderSelectDraw: false,
            renderSearch: false,
            renderSelectTool: false,
            viewAnnotationsRedaction: false,
            displaySearchButtons: false,
            drawSelectedMouse: true
        }, () => {
            if (this.state.renderCommentsText) {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 0;')
                    }
                }
                this.setState({
                    valueSelectNavigation: 'comments'
                })
                this.changeNavigation('comments')
            } else {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 1;')
                    }
                }
                this.setState({
                    valueSelectNavigation: 'ai-results'
                })
                this.changeNavigation('find-asset')
            }
            this.setState({
                tool: Tools.Select,
            }, () => {
                if (!this.state.renderCommentsText) {
                    this.setState({
                        disableClickOnPage: true
                    })
                } else {
                    this.setState({
                        disableClickOnPage: false
                    })
                }
            })
        })
    }

    toggle = () => {
        this._sketch.selectedObject()
        this.setState((prevState) => ({
            modal: !prevState.modal
        }))
    }

    setCurrentSketch = (sketch) => {
        this._sketch = sketch
    }

    cavnasModalRemove = (type) => {
        if (type === 'object') {
            this._sketch.removeSelected()
        } else {
            this._sketch.removeSelectedAnnotation()
        }
        this.hideCanvasModals()
        this._sketch.unselectObject()
        this.setState({
            annotationText: ''
        })
    }

    hideCanvasModals = () => {
        let { pageSidebarVisibleBefore, pageSidebarVisible } = this.state
        if (pageSidebarVisibleBefore) {
            pageSidebarVisible = true
        }
        this.setState({
            annotationListVisible: false,
            pageSidebarVisible
        })
    }

    addAnnotation = (text) => {
        const annotation = (text) ? text : this.state.annotationText
       // console.log('addAnnotation cccccccccccccccccccccccccccccc annotation', annotation)
        if (annotation) {
            if (this.state.objectHasAnnotation) {
              //  console.log('addAnnotation AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
                this._sketch.editAnnotation(annotation)
            } else {
               // console.log('addAnnotation BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB')
                this._sketch.addAnnotation(annotation)
                this.setState({
                    objectHasAnnotation: true
                })
            }
        }
        this.setState({
            haveChanges: true
        })
    }

    editLabel = (obj, label) => {
        if (label) {
            this._sketch.editLabel(obj, label)
        }
    }

    changeAnnotationText = (text, type) => {
        if (type === 'old') {
            if (text) {
                this.setState({
                    annotationText: text
                })
            }
        } else {
            this.setState({ annotationText: text.target.value })
        }
    }
    changeAnnotationTextMixed = (text, type) => {
        if (type === 'old') {
            if (text) {
                this.setState({
                    annotationText: text
                })
            }
        } else {
            this.setState({ annotationText: text.target.value })
        }
    }

    onDocumentLoadSuccess = () => {
        this.setState({
            isDocumentLoaded: true
        })
    }

    initMouseUpEvent = () => {
        const pages = document.getElementsByClassName('react-pdf__Page__textContent')
        const that = this
        Array.from(pages).forEach((element) => {
            element.addEventListener('mouseup', () => that.getSelectedWords())
        })
    }

    loadingFinished = () => {
        this.setState({
            loading: false,
            showCloseButton: true,
            isDocumentReady: true,
            loadingBeforeMark: true
        }, () => {
            this.initMouseUpEvent()
            if (this.props.searchedValue) {
               // console.log('VVVVVVVVVVVVVVVVVVVVVVV 222222222222222222222222')
                this.searchTextInDoc(this.props.searchedValue)
            }
        })
    }

    annotationHandleChangeMixed = (value, mode, type) => {
        if (value.length) {
            if (type === 'select') {
                this.addAnnotation(value)
                if (mode === 'add') {
                    this.setState({
                        annotationText: value
                    })
                }
                if (mode === 'edit') {
                    this.setState({
                        annotationText: value
                    })
                }
            }
        }
    }

    annotationHandleChange = (value, mode, type) => {
        if (value.length) {
            if (type === 'select') {
                this.addAnnotation(value)
                if (mode === 'add') {
                    this.setState({
                        annotationText: value
                    })
                }
                if (mode === 'edit') {
                    this.setState({
                        annotationText: value
                    })
                }
            }
        }
    }

    handleRenderPage = (pageNumber) => {
        const newState = [...this.state.renderedPagesStatus]
        newState[pageNumber-1] = true

        this.setState({
            renderedPagesStatus: newState
        })
    }

    addObjectToCanvas = (sketchObj) => {
        const { canvasData, pageNumber, totalPages, canvasDataOcrDrawBox, canvasDataComments } = this.state
       // console.log('pagePointSum EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE')
        if (this.state.renderCommentsText) {
            let foundObj = false
            let foundObjOcr = false
            for(let i=1; i<= totalPages; i++){
                if(typeof canvasDataComments[i] !== 'undefined') {
                    const found = canvasDataComments[i].find(el => el.pagePointSum === sketchObj.pagePointSum)
                    if (typeof found !== 'undefined') {
                        foundObj = true
                        break
                    }
                }
            }
            if (this.props.isOcr) {
                for (let i = 1; i <= totalPages; i++) {
                    if (typeof canvasDataOcrDrawBox[i] !== 'undefined') {
                        const found = canvasDataOcrDrawBox[i].find(el => el.pagePointSum === sketchObj.pagePointSum)
                        if (typeof found !== 'undefined') {
                            foundObjOcr = true
                            break
                        }
                    }
                }
            }
            if(!foundObj) {
                if (typeof canvasDataComments[pageNumber] === 'undefined') {
                    canvasDataComments[pageNumber] = []
                }
                const obj = {
                    x1: sketchObj.aCoords.bl.x,
                    x2: sketchObj.aCoords.br.x,
                    y1: sketchObj.aCoords.bl.y,
                    y2: sketchObj.aCoords.tr.y,
                    text: sketchObj.text ? sketchObj.text : '',
                    annotation: (sketchObj.text) ? sketchObj.text : sketchObj.annotation ? sketchObj.annotation : '',
                    type: Number(sketchObj.type),
                    pagePointSum: sketchObj.pagePointSum,
                    picture: true,
                    drawTesdt: false
                }
                //const aCoordsblx = sketchObj.aCoords.br.x - sketchObj.aCoords.bl.x
                // if (aCoordsblx > 10) {
                if (obj) {
                    const saveSelectedTextCommentSmartViewDTO = {
                        text: sketchObj.text ? sketchObj.text : '',
                        textPosition: JSON.stringify(obj),
                        page: pageNumber,
                        idDocument: this.state.documentId,
                    }
                    // apiSaveSelectedTextComment(saveSelectedTextCommentSmartViewDTO).then((response) => {
                    //
                    // })
                    canvasDataComments[pageNumber].push(JSON.stringify(obj))
                }
                this.setState({
                    canvasDataComments
                })
                //}
            }

            if(!foundObjOcr && this.props.isOcr) {
                if (typeof canvasDataOcrDrawBox[pageNumber] === 'undefined') {
                    canvasDataOcrDrawBox[pageNumber] = []
                }
                const obj = {
                    x1: sketchObj.aCoords.bl.x,
                    x2: sketchObj.aCoords.br.x,
                    y1: sketchObj.aCoords.bl.y,
                    y2: sketchObj.aCoords.tr.y,
                    text: sketchObj.text ? sketchObj.text : '',
                    annotation: (sketchObj.text) ? sketchObj.text : sketchObj.annotation ? sketchObj.annotation : '',
                    type: Number(sketchObj.type),
                    pagePointSum: sketchObj.pagePointSum,
                    picture: true,
                    drawTesdt: true
                }
                //const aCoordsblx = sketchObj.aCoords.br.x - sketchObj.aCoords.bl.x
                // if (aCoordsblx > 10) {
                canvasDataOcrDrawBox[pageNumber].push(JSON.stringify(obj))
                this.setState({ canvasDataOcrDrawBox })
                //}
            }
        } else {
            let foundObj = false
            let foundObjOcr = false
            for(let i=1; i<= totalPages; i++){
                if(typeof canvasData[i] !== 'undefined') {
                    const found = canvasData[i].find(el => el.pagePointSum === sketchObj.pagePointSum)
                    if (typeof found !== 'undefined') {
                        foundObj = true
                        break
                    }
                }
            }
            if (this.props.isOcr) {
                for (let i = 1; i <= totalPages; i++) {
                    if (typeof canvasDataOcrDrawBox[i] !== 'undefined') {
                        const found = canvasDataOcrDrawBox[i].find(el => el.pagePointSum === sketchObj.pagePointSum)
                        if (typeof found !== 'undefined') {
                            foundObjOcr = true
                            break
                        }
                    }
                }
            }

            if(!foundObj) {
                if (typeof canvasData[pageNumber] === 'undefined') {
                    canvasData[pageNumber] = []
                }
                const obj = {
                    x1: sketchObj.aCoords.bl.x,
                    x2: sketchObj.aCoords.br.x,
                    y1: sketchObj.aCoords.bl.y,
                    y2: sketchObj.aCoords.tr.y,
                    text: sketchObj.text ? sketchObj.text : '',
                    annotation: (sketchObj.text) ? sketchObj.text : sketchObj.annotation ? sketchObj.annotation : '',
                    type: Number(sketchObj.type),
                    pagePointSum: sketchObj.pagePointSum,
                    picture: true,
                    drawTesdt: false
                }
                //const aCoordsblx = sketchObj.aCoords.br.x - sketchObj.aCoords.bl.x
                // if (aCoordsblx > 10) {
                canvasData[pageNumber].push(JSON.stringify(obj))
                this.setState({
                    canvasData})
                //}
            }

            if(!foundObjOcr && this.props.isOcr) {
                if (typeof canvasDataOcrDrawBox[pageNumber] === 'undefined') {
                    canvasDataOcrDrawBox[pageNumber] = []
                }
                const obj = {
                    x1: sketchObj.aCoords.bl.x,
                    x2: sketchObj.aCoords.br.x,
                    y1: sketchObj.aCoords.bl.y,
                    y2: sketchObj.aCoords.tr.y,
                    text: sketchObj.text ? sketchObj.text : '',
                    annotation: (sketchObj.text) ? sketchObj.text : sketchObj.annotation ? sketchObj.annotation : '',
                    type: Number(sketchObj.type),
                    pagePointSum: sketchObj.pagePointSum,
                    picture: true,
                    drawTesdt: true
                }
                //const aCoordsblx = sketchObj.aCoords.br.x - sketchObj.aCoords.bl.x
                // if (aCoordsblx > 10) {
                canvasDataOcrDrawBox[pageNumber].push(JSON.stringify(obj))
                this.setState({ canvasDataOcrDrawBox })
                //}
            }
        }
    }

    onUpdateAnswerToogleOcr = async(e , checkedPreview) => {
        let documentId = this.props.documentId;
        const allPagesCanvas = document.getElementsByClassName('canvas-area')

        const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
        let canvasData = response1.data.pageRectangleMap;

        if (checkedPreview) {
            this.fill('rgba(0, 0, 0, 0.3)',documentId, 0.3, canvasData)
        } else {
            this.fill('rgba(0, 0, 0, 1)', documentId, 1, canvasData)
        }
        if (this.state.renderSelectText) {
            if (checkedPreview) {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 0;')
                    }
                }
            } else {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 1;')
                    }
                }
            }
        }
        this._sketch.changeRedactingMode(checkedPreview)
        this.setState({
            redactingMode: checkedPreview
        })
    }

    onUpdateAnswerToogle = async(e , checkedPreview) => {
        let documentId = this.props.documentId;
        const { canvasData } = this.state
        const allPagesCanvas = document.getElementsByClassName('canvas-area')
        // console.log('AnswerToogle OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO', checkedPreview)
        // const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
        // let canvasData = response1.data.pageRectangleMap;
        if (checkedPreview) {
            this.fill('rgba(0, 0, 0, 0.3)', documentId, 0.3, canvasData)
        } else {
            this.fill('rgba(0, 0, 0, 1)', documentId, 1, canvasData)
        }
        if (this.state.renderSelectText) {
            if (checkedPreview) {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 0;')
                    }
                }
            } else {
                for (const page of allPagesCanvas) {
                    if (page) {
                        page.setAttribute('style', 'z-index: 1;')
                    }
                }
            }
        }
        this._sketch.changeRedactingMode(checkedPreview)
        this.setState({
            redactingMode: checkedPreview
        })
    }
    onEndPoints = (endx, endy) => {
        this.setState({
            endPointXDraw: endx,
            endPointYDraw: endy,
        })
    }
    renderPages = (totalPages, canvasData, forPageNumber, tool, pdfBytes, isDocumentLoaded, divPrint, height, width, scale, viewAnnotationsRedaction, renderSelectDraw, renderSelectText, drawByHandText, clearSketch, isOcr,
                   statusHideAllHighlightingMixed, canvasDataHighlightingMixed, statusSaveSmartviewMixed2) => {
        return (
            <Document
                ref={ (ref) => this.myDiv = ref }
                file={ pdfBytes ? pdfBytes : '' }
                onLoadSuccess={ () => this.onDocumentLoadSuccess() }
                noData={ <h4>Please select a file</h4> }
                loading={<div></div>}
                //loading={<div className="content-spinning-document"><Spin tip="Loading"></Spin></div>}
            >
                { [ ...Array(totalPages).keys() ].map((index) => {
                        return (
                            <CanvasPageCopy
                                ref={ index + 1 }
                                pageNumber={ index + 1 }
                                totalPages={ totalPages }
                                canvasData={ canvasData[index + 1] }
                                canvasDataHighlightingMixed={ canvasDataHighlightingMixed[index + 1] }
                                setSketch={ this.setCurrentSketch }
                                forPageNumber={ forPageNumber }
                                tool={ tool }
                                isDocumentLoaded={ isDocumentLoaded }
                                loadingFinished={ this.loadingFinished }
                                divPrint={ divPrint }
                                width={ width }
                                height={ height }
                                key={ index }
                                selectTool={ this.selectTool }
                                selectRectangle={ this.selectRectangle }
                                scale={ scale }
                                showAnnotationsList={ this.showAnnotationsList }
                                hideAnnotationsList={ this.hideAnnotationsList }
                                isModalShow={ viewAnnotationsRedaction }
                                selectObject={ this.selectObject }
                                renderSelectDraw={ renderSelectDraw }
                                renderSelectText={ renderSelectText }
                                handleRenderPage={ this.handleRenderPage }
                                setAlreadyRedactedList = { this.setAlreadyRedactedList }
                                drawByHandText={ drawByHandText }
                                clearSketch={ clearSketch }
                                addObjectToCanvas = { this.addObjectToCanvas }
                                onEndPoints = { this.onEndPoints }
                                isOcr={isOcr}
                                renderCommentsText={ this.state.renderCommentsText }
                                statusHideAllHighlightingMixed={ statusHideAllHighlightingMixed }
                                statusSaveSmartviewMixed2={ statusSaveSmartviewMixed2 }
                                onResetStatusSaveSmartviewMixed2={ this.onResetStatusSaveSmartviewMixed2 }
                            />
                        )
                    }
                ) }
            </Document>
        )
    }

    onAnswerToogle = (checked) => {
        if (checked) {
            this.fill('rgba(0, 0, 0, 0.5)')
        } else {
            this.fill('rgba(0, 0, 0, 1)')
        }
        this._sketch.changeRedactingMode(checked)
        this.setState({
            redactingMode: checked
        })
    }

    onAnnotationToogle = (checked) => {
        if (checked) {
            this.setState({ printAnnotation: true })
        } else {
            this.setState({ printAnnotation: false })
        }
    }

    async finalizeRedacting() {
        const { landscape, scale, totalPages, width, height, printAnnotation, divPrint, idDoc } = this.state
        const timeOut = totalPages > 10 ? 2000 : 1000
        if (this.props.visitedRedactionSmart === 'smart') {
            this.toggleLoadingProcess(true, 'finalise')
            this.savePage()
            setTimeout(() => {
                getIdFinaliseDocument(idDoc).then(() => {
                    this.toggleLoadingProcess(false)
                    this.props.closeRedacting(this.props.pdfBytes)
                    this.props.fetchFiles()
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            }, timeOut)
        } else {
            if (this.state.matchedText.length > 0) {
                await this.clearPreviousSearchedData()
            }

            window.scrollTo(0, 0)
            this.setState({
                showCloseButton: false
            })
            const elements = document.querySelectorAll(`div#${ divPrint }`)
            for (let i = 0; i < totalPages; i++) {
                elements[i].classList.add('show-pdf-page')
            }
            this.fill('rgba(0, 0, 0, 1)')
            this._sketch.showAnnotation(printAnnotation)
            const parentElement = document.querySelector('#pdf-page')
            const HTML_Width = parentElement.clientWidth
            const PDF_Width = HTML_Width / scale
            const PDF_Height = height / scale
            let position = 0
            let canvas_image_width = parentElement.clientWidth / scale
            let canvas_image_height = parentElement.clientHeight / scale
            let heightLeft = canvas_image_height

            let pdf = null
            if (landscape) {
                pdf = new jsPDF('landscape', 'pt', [PDF_Width, PDF_Height])
            } else {
                pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
            }

            if (totalPages > 5) {
                let counter = totalPages
                let step = 5
                let from = 0
                const pages = Array.from(document.querySelectorAll(landscape ?
                    '#div-to-print-landscape' :
                    '#div-to-print-portrait'))
                while (counter > 0) {
                    if (counter < step) {
                        if (counter > 0) {
                            step = counter
                        }
                    }
                    counter -= step
                    const printElements = pages.slice(from, from + step + 1)
                    const printParent = document.createElement('div')
                    const parent = document.querySelectorAll('.redacted-section')[0]
                    printElements.forEach((item) => printParent.appendChild(item))
                    parent.appendChild(printParent)
                    from += step

                    canvas_image_width = printParent.clientWidth / scale
                    canvas_image_height = printParent.clientHeight / scale
                    heightLeft = canvas_image_height
                    await this.renderPdf(printParent, position, totalPages, scale, width, height, heightLeft, canvas_image_width,
                        canvas_image_height, PDF_Height, landscape, pdf)

                    printParent.remove()
                    printElements.forEach((item) => parent.appendChild(item))
                }

                const pdfFile = pdf.output('blob')
                const data = new FormData()
                data.append('data', pdfFile)

                saveRedactedDocument(data, this.props.documentId).then(() => {
                    this.toggleLoadingProcess(false)
                    this.savePage()
                    this.props.closeRedacting(this.props.pdfBytes)
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
                this.toggleLoadingProcess(false)
            } else {
                html2canvas(document.getElementById('pdf-page'), { allowTaint: true }).then((canvas) => {
                    pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
                    heightLeft -= PDF_Height
                    while (heightLeft > 0) {
                        position = heightLeft - canvas_image_height
                        pdf.addPage(landscape ? 'l' : 'p', [width / scale, height / scale])
                        pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
                        heightLeft -= PDF_Height
                    }
                    const pdfFile = pdf.output('blob')
                    const data = new FormData()
                    data.append('data', pdfFile)

                    saveRedactedDocument(data, this.props.documentId).then(() => {
                        this.savePage()
                        this.toggleLoadingProcess(false)
                        this.props.closeRedacting(this.props.pdfBytes)
                    }).catch((error) => {
                        if (error?.response?.status === 504) {
                            CustomNotificationManager.cleanBuffer('warning', '')
                        }else{
                            console.log(error)
                        }
                    })

                    //hide back all pages except current
                    for (let i = 0; i < this.state.totalPages; i++) {
                        if (Number(elements[i].getAttribute('data-pageid')) === 1) {
                            elements[i].classList.add('show-pdf-page')
                        } else {
                            elements[i].classList.remove('show-pdf-page')
                        }
                    }
                    this.fill('rgba(0, 0, 0, 0.5)')
                    this._sketch.showAnnotation(true)
                })
            }
        }
    }

    savePageOnCloseRedactingMixed = () => {
        const { canvasDataCopy, deleteSelectedRedactionBox } = this.state
        const listPoints = []
        const listPointsNewAdd = []
        const dtoRedact = []
        const pointsToSave = {}
        const pointsToSaveNewAdd = {}
        const pointsToSave2 = {}
        let pagePointSumDeleted = []
        let rectanglesToRemove = []
        let rectanglesToRemoveMixed2 = []
        let pagePointRectanglesMove = []

        let maxHeight //= 10000
        const elementsMax = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elementsMax.length > 0) {
            maxHeight = elementsMax[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        const points = this.getRectanglesForPreviewPageMixed()
        const uniqueIdPagePointSum = Math.floor(Math.random() * 10000000000).toString()
        points.forEach((element) => {
            //console.log('element AAAAAAAAAAAAAAAAAAA 1111111111111111111111', element)
            let y1 = element.y1 - 1
            if (y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if (y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.typeMixed === 13 ? uniqueIdPagePointSum : element.pagePointSum,
                pagePointSumAnnotate: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true,
                keyUI: element.keyUI,
                category: element.category ? element.category : 'Smartviewer',
                subcategory: element.subcategory ? element.subcategory : 'Freehand',
                color: element.color,
                colorRedaction: element.colorRedaction,
                annotationRedaction: element.annotationRedaction,
            }

            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            if (element.typeMixed && (element.typeMixed === 10 || element.typeMixed === 11 || element.typeMixed === 12 || element.typeMixed === 13)) {
                const stringObjNewAdd = JSON.stringify(obj)
                listPointsNewAdd.push(stringObjNewAdd)
                pointsToSaveNewAdd[this.state.pageNumber] = listPointsNewAdd
            }
            if (element.typeMixed && (element.typeMixed === 12)) {
                pagePointRectanglesMove.push(element.pagePointSum)
            }
        })
        const dataToSaveRedactAll = {
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSaveNewAdd

        }
        dtoRedact.push(dataToSaveRedactAll)
        rectanglesToRemove = this.onRectanglesToRemove(pointsToSave)
        rectanglesToRemoveMixed2 = this.onRectanglesToRemoveMixed2(pointsToSave, pagePointRectanglesMove)
        const saveCanvasSmartviewMixedDTO = {
            idDoc: this.props.documentId,
            keyWordNonOcr: [],
            keyWordNonOcrRegex: [],
            keyWordNonOcrDictionary: [],
            keyWordOcr: [],
            keyWordOcrRegex: [],
            keyWordOcrDictionary: [],
            rectanglesToRemove: rectanglesToRemoveMixed2,
            markedAsAudit: true,
            pageCoordinates: dtoRedact,
            //coordsToDelete: []
        }
        if ((Object.keys(pointsToSaveNewAdd) && Object.keys(pointsToSaveNewAdd).length > 0) || (rectanglesToRemoveMixed2 && rectanglesToRemoveMixed2.length > 0)) {
            apiSmartBoxSaveCanvasDocumentSmartviewMixed2(saveCanvasSmartviewMixedDTO).then((response1) => {
               // console.log('response1.data.pageRectangleMap HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH', response1.data.pageRectangleMap)
                let pageRectangleMap = this.convertCanvasDataCopyMixed(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
                let pageRectangleMapNew = this.convertCanvasDataCopyMixedNew(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)

              //  console.log('pageRectangleMap GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', pageRectangleMap)

                this.setState({
                    canvasData: pageRectangleMap,
                    canvasDataCopy: pageRectangleMapNew,
                    statusSaveSmartviewMixed2: response1.status,
                    deleteSelectedRedactionBox: false,
                    checkStartRedaction: false,
                    annotationText: ''
                })
                this.props.closeRedacting(this.props.pdfBytes)
                this.onRemoveEventDisableScroll()
            })
        } else {
            this.props.closeRedacting(this.props.pdfBytes)
        }
    }
    closeRedactingMixed = () => {
        const { statusCloseSmartviewRedactAll, setIntervalUpdateLockStatus, checkStartRedaction } = this.state
            clearInterval(setIntervalUpdateLockStatus)
            this.setState({setIntervalUpdateLockStatus})
        if (!statusCloseSmartviewRedactAll) {
            this.setState({
                sourceActive: '',
                closeRedactingStatus: true,
                finalizeLoading: false,
            }, () => {
                //this.changePageMixed(this.state.pageNumber, null, 'pageSidebar')
                this.savePageOnCloseRedactingMixed()
                //this.props.closeRedacting(this.props.pdfBytes)
            })
            cookie.remove('sendNoteResponse', {path: '/'})
            this.onRemoveEventDisableScroll()
        }
            this.onUnlockSmartviewDocument()
    }

    closeRedacting = () => {
        const { statusCloseSmartviewRedactAll, setIntervalUpdateLockStatus } = this.state
       // console.log('closeRedacting AAAAAAAAAAAAAAAAAAAAAAA')
        cookie.remove('checkLockStatusSmartviewDocument', { path: '/' })
        clearInterval(setIntervalUpdateLockStatus)
        this.setState({setIntervalUpdateLockStatus})
        if (!statusCloseSmartviewRedactAll) {
            this.setState({
                sourceActive: '',
                closeRedactingStatus: true
            }, () => {
                this.savePage()
                //this.changePageMixed(this.state.numberPage, null, null)
                this.props.closeRedacting(this.props.pdfBytes)
            })
            this.setState({
                finalizeLoading: false,
            })
            cookie.remove('sendNoteResponse', {path: '/'})
            this.onRemoveEventDisableScroll()
        }
        this.onUnlockSmartviewDocument()
    }

    onUnlockSmartviewDocument = () => {
        apiUnlockSmartviewDocument(this.state.documentId).then((response) => {

        })
    }

    addAnnotationOptions = (list) => {
        const { Option } = Select
        const response = []
        for (const item of list) {
            response.push(<Option key={ item.exemptionCode } value={ item.exemptionCode }>{ item.exemptionCode }</Option>)
        }
        return response
    }

    addTimeForOpening(totalPages) {
        if (totalPages) {
            if (totalPages <= 10) {
                return Math.ceil(totalPages * 0.25)
            } else if (totalPages > 10 && totalPages <= 30) {
                return Math.ceil(totalPages * 0.35)
            } else if (totalPages > 30 && totalPages <= 60) {
                return Math.ceil(totalPages * 0.45)
            } else if (totalPages > 60 && totalPages <= 100) {
                return Math.ceil(totalPages * 0.60 / 60)
            } else if (totalPages > 100) {
                return Math.ceil(totalPages * 0.75 / 60)
            }
        }
    }

    calculatePrintingTime(totalPages) {
        if (totalPages) {
            if (totalPages <= 10) {
                return Math.ceil(totalPages * 0.85)
            } else if (totalPages > 10 && totalPages <= 30) {
                return Math.ceil(totalPages * 0.85)
            } else if (totalPages > 30 && totalPages <= 60) {
                return Math.ceil(totalPages * 0.90)
            } else if (totalPages > 60 && totalPages <= 100) {
                return Math.ceil(totalPages * 0.90 / 60)
            } else if (totalPages > 100) {
                return Math.ceil(totalPages * 0.99 / 60)
            }
        }
    }

    /**
     * This method searches the words in documents
     * */
    onSaveSearchItemHistory = (value, status) => {
        if (value) {
            const dtoSearch = {
                term: value,
                idDoc: this.props.documentId
            }
            apiSmartBoxAddTermToSearchHistory(dtoSearch).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        statusHistory: true
                    }, () => {
                        this.getSearchHistoryList()
                    })
                }
            })
        }
    }
    getSearchHistoryList = () => {
        apiGetSmartviewSearchHistoryTerms(this.props.documentId).then((response) => {
            let getTermsSearch = []
            let sortTermsSearch = []
            getTermsSearch = response.data
            const uniqueGetTermsSearch = [...new Set(getTermsSearch)]
            const uniqueTermsSearch = uniqueGetTermsSearch && uniqueGetTermsSearch.length > 0 && uniqueGetTermsSearch.filter((a, i) => uniqueGetTermsSearch.findIndex((s) => a.toLowerCase() === s.toLowerCase()) === i)
            if (uniqueTermsSearch && uniqueTermsSearch.length > 0) {
                sortTermsSearch = uniqueGetTermsSearch.sort((a, b) => b.length - a.length)
            }
            this.setState({
                listSearchHistory: sortTermsSearch
            })
            sortTermsSearch && sortTermsSearch.length > 0 && sortTermsSearch.forEach((item) => {
                this.searchTextInDoc(item, 'search-history', 'get')
            })
        })
    }

    async searchTextInDocHistory(value, status, status2) {
        const { listSearchHistory, searchMatchedElement, sortTermsSearch  } = this.state
        const valueHistoryList = []
        let sortTermsSearchHistory = []
        let uniqueListSearchHistory = []
        const parentClassName = this.props.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
        if (value) {
            searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
                const element = item
                if (element) {
                    element.id = 'matched-word--current'
                    element.style.background = 'transparent'
                }
            })

            sortTermsSearchHistory = uniqueListSearchHistory.sort((a, b) => b.length - a.length)
            searchMatchedElement && searchMatchedElement.length && searchMatchedElement.forEach((element) => {
                // const element2 = document.getElementsByClassName(element.className)
                // if (element2 && element2.length > 0) {
                //   element2[0].removeAttribute('id')
                // }
            })

            listSearchHistory && listSearchHistory.length > 0 && listSearchHistory.forEach((value2) => {
                    // const aiAnnotations = document.querySelectorAll(`.${ value2 }`)
                    // for (const aiAnnotate of aiAnnotations) {
                    //   aiAnnotate.style.background = 'transparent'
                    // }
                    //const instance = new Mark(document.querySelector('#pdf-page'))
                    // const instance = new Mark(document.querySelector('#pdf-page'))
                    //    instance.unmark({
                    //      element: markRef.current,
                    //      className: 'mark',
                    //      exclude: [new RegExp(`\\b${value2}\\b`, 'gi')]
                    //    })

                }
            )


            valueHistoryList.push(value)
            if (value && status === 'search-history' && status2 !== 'get') {
                this.onSaveSearchItemHistory(value)
            }
            this.setState({
                listSearchHistory: valueHistoryList.concat(listSearchHistory),
                searchMatchedWords: [],
                matchedText: [],
            }, () => {
                if (this.state.listSearchHistory && this.state.listSearchHistory.length > 0) {
                    uniqueListSearchHistory = this.state.listSearchHistory.filter((a, i) => this.state.listSearchHistory.findIndex((s) => a === s) === i)
                } else {
                    uniqueListSearchHistory = []
                }
                sortTermsSearchHistory = uniqueListSearchHistory.sort((a, b) => b.length - a.length)
                this.searchHistoryTextInDoc(value, status, status2)
                // sortTermsSearchHistory && sortTermsSearchHistory.length > 0 && sortTermsSearchHistory.forEach((value2) => {
                //   this.searchHistoryTextInDoc(value2, status, status2)
                // })
            })
        } else {
            this.setState({
                searchEmpty: true
            })
        }
    }

    async searchHistoryTextInDoc(value, status, status2) {
        let {searchMatchedElement} = this.state

        searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
            const element = item
            if (element) {
                element.style.background = 'transparent'
            }
        })
        let j = 0
        let i = 0
        const color = '#3dd8db'
        if (this.state.matchedText.length > 0) {
            await this.clearPreviousSearchedData()
        }
        const listSearchHistory = []
        listSearchHistory.push({
            key: `${value}-parent`,
            identifier: 'category-parent',
            name: value,
            children: []
        })
        const items = []
        const parentClassName = this.props.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
        const options = {
            'className': 'matched-word',
            //'className': `${ value } doc_annotate`,
            'ignoreJoiners': false,
            'element': 'span',
            'acrossElements': false,
            'separateWordSearch': false,
            'wildcards': 'withSpaces',
            //'accuracy': {'limiters': [' @', ',', ', ', ' .', '. ', ':', ';', '/>', '"', "'", "`", '<', '>', '[', ']', '(', ')']},
            //'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','’','’s','’ ','’s ','-','.','@','{','}','$','&','!','?','~','#','%','^','*','+','_','=','|','\\','/']},
            'accuracy': {'value': 'exactly'},
            each: (element) => {
                j++
                const parent = element.parentElement.closest(parentClassName)
                //add to element the class ${ keyName }-${ j }
                const item = {
                    key: `${ value }-${ j }`,
                    index: j,
                    identifier: 'children',
                    pageNumber: parent ? parent.getAttribute('data-pageid') : '',
                    name: value,
                    title: `${ value }`,
                    isSearchedWord: true,
                    children: [],
                    backgroundColor: element.style.background
                }
                const keyName = value.replace(/\W/g, 'a')
                element.classList.add(`${ keyName }-${ j }`)
                element.name = `${ value }-${ j }`
                element.setAttribute('data-page-nr', element.pageNumber)
                items.push(item)
                if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
                    element.style.background = 'transparent'
                }
                //   else {
                //   element.style.background = element.style.background
                // }
            }
        }
        const instance = new Mark(document.querySelector('#pdf-page'))
        const regex6 = new RegExp(`(?<![a-zA-Z])${this.excapeRegex(value.trim())}(?![a-zA-Z])`, "gi")
        instance.markRegExp(regex6, options)
        //instance.mark(value.trim(), options)
        const searchMatchedWords = document.querySelectorAll('span.matched-word')
        searchMatchedWords.forEach((v) => {
            const parent = v.closest(parentClassName)
            v.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))

        })
        searchMatchedWords && searchMatchedWords.length > 0 && searchMatchedWords.forEach((item) => {
            if (item.name.includes(value)) {
                i++
                listSearchHistory[0].redacted = false
                listSearchHistory[0].children.push({
                    key: `${value}-${i}`,
                    name: value,
                    pageNumber: `${Number(item.getAttribute('data-page-nr'))}`,
                    id: i - 1,
                    backgroundColor: 'rgba(255, 145, 87, 0.3)'
                })
            }
        })
        let updateListSearchHistory = []
        if (this.state.treeDataSearchHistory && this.state.treeDataSearchHistory.length > 0) {
            updateListSearchHistory = this.state.treeDataSearchHistory.concat(listSearchHistory)
        } else {
            updateListSearchHistory = listSearchHistory
        }

        const searchHistoryFinally = []
        const uniqueTreeDataSearchHistory = updateListSearchHistory && updateListSearchHistory.length > 0 && updateListSearchHistory.filter((a, i) => updateListSearchHistory.findIndex((s) => a.key.toLowerCase() === s.key.toLowerCase()) === i)
        const uniqueTreeDataSearchHistory2 = uniqueTreeDataSearchHistory && uniqueTreeDataSearchHistory.length > 0 && uniqueTreeDataSearchHistory.filter((a) =>  a.name.toLowerCase() !== '')
        if (uniqueTreeDataSearchHistory2 && uniqueTreeDataSearchHistory2.length > 0) {
            uniqueTreeDataSearchHistory2.forEach((item) => {
                if (item && item.children && item.children.length > 0) {
                    searchHistoryFinally.push(item)
                }
            })
        }
        const wordCounterHistory = {}
        searchHistoryFinally && searchHistoryFinally.length && searchHistoryFinally.forEach((elemHistory) => {
            elemHistory.children && elemHistory.children.length > 0 && elemHistory.children.forEach((elemHistory2) => {
                const { name, pageNumber } = elemHistory2
                if (!wordCounterHistory[pageNumber]) {
                    wordCounterHistory[pageNumber] = {}
                }
                if (!wordCounterHistory[pageNumber][name]) {
                    wordCounterHistory[pageNumber][name] = 1
                } else {
                    wordCounterHistory[pageNumber][name] += 1
                }
                elemHistory2.pageCount = wordCounterHistory[pageNumber][name]
                elemHistory2.keyMixtRedaction = `${elemHistory2.name}-${elemHistory2.pageNumber}-${elemHistory2.pageCount}`
                elemHistory2.categoryName = 'Smartviewer'
                elemHistory2.subcategoryName = 'Freehand'
            })
        })
        if (status && status === 'search-history') {
            this.setState({
                treeDataSearchHistory: searchHistoryFinally,
                navigationType: 'search-history'
            })
        }
        if (searchMatchedWords) {
            this.setState({
                matchedText: searchMatchedWords,
                searchIndex: 0,
                searchMatchedElement: searchMatchedWords,
                searchPage: searchMatchedWords[0] ? Number(searchMatchedWords[0].getAttribute('data-page-nr')) : 1,
                currentMarkedIndex: 1,
                borderColor: color,
                valueSelectNavigation: 'search-history'
            })
            const element = searchMatchedWords[0]
            if (element) {
                element.id = 'matched-word--current'
                element.style.borderColor = color
                this.onSearchChangePage(element).then(() => {
                    this.scrollToMatchedWord()
                })
            }
        }
    }
    excapeRegex = (word) => {
        return word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }
    async searchTextInDoc(value, status, status2) {
        const {searchMatchedElement} = this.state
        if (value && status === 'search-history' && status2 !== 'get') {
            this.onSaveSearchItemHistory(value)
        }
        searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
            const element = item
            if (element) {
                element.style.background = 'transparent'
            }
        })
        let j = 0
        let i = 0
        const color = '#3dd8db'
        if (this.state.matchedText.length > 0) {
            await this.clearPreviousSearchedData()
        }
        const listSearchHistory = []
        listSearchHistory.push({
            key: `${value}-parent`,
            identifier: 'category-parent',
            name: value,
            children: []
        })
        const items = []
        const parentClassName = this.props.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
        const options = {
            'className': 'matched-word',
            //'className': `${ value } doc_annotate`,
            'ignoreJoiners': true,
            'element': 'span',
            'acrossElements': false,
            'separateWordSearch': false,
            'wildcards': 'withSpaces',
            //'accuracy': {'limiters': [' @', ',', ', ', ' .', '. ', ':', ';', '/>', '"', "'", "`", '<', '>', '[', ']', '(', ')']},
            //'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','’','’s','’ ','’s ','-','.','@','{','}','$','&','!','?','~','#','%','^','*','+','_','=','|','\\','/']},
            'accuracy': {'value': 'exactly'},
            each: (element) => {
                j++
                const parent = element.parentElement.closest(parentClassName)
                //add to element the class ${ keyName }-${ j }
                const item = {
                    key: `${ value }-${ j }`,
                    index: j,
                    identifier: 'children',
                    pageNumber: parent ? parent.getAttribute('data-pageid') : '',
                    name: value,
                    title: `${ value }`,
                    isSearchedWord: true,
                    children: [],
                    backgroundColor: element.style.background
                }
                const keyName = value.replace(/\W/g, 'a')
                element.classList.add(`${ keyName }-${ j }`)
                element.name = `${ value }-${ j }`
                element.setAttribute('data-page-nr', element.pageNumber)
                items.push(item)
                if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
                    element.style.background = 'transparent'
                }
                //   else {
                //   element.style.background = element.style.background
                // }
            }
        }
        const instance = new Mark(document.querySelector('#pdf-page'))
        const regex6 = new RegExp(`(?<![a-zA-Z])${this.excapeRegex(value.trim())}(?![a-zA-Z])`, "gi")
        instance.markRegExp(regex6, options)
        //instance.mark(value.trim(), options)
        const searchMatchedWords = document.querySelectorAll('span.matched-word')
        searchMatchedWords.forEach((v) => {
            const parent = v.closest(parentClassName)
            v.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))

        })
        searchMatchedWords && searchMatchedWords.length > 0 && searchMatchedWords.forEach((item) => {
            if (item.name.includes(value)) {
                i++
                listSearchHistory[0].redacted = false
                listSearchHistory[0].children.push({
                    key: `${value}-${i}`,
                    name: value,
                    pageNumber: `${Number(item.getAttribute('data-page-nr'))}`,
                    backgroundColor: 'rgba(255, 145, 87, 0.3)',
                    id: i - 1
                })
            }
        })
        let updateListSearchHistory = []
        if (this.state.treeDataSearchHistory && this.state.treeDataSearchHistory.length > 0) {
            updateListSearchHistory = this.state.treeDataSearchHistory.concat(listSearchHistory)
        } else {
            updateListSearchHistory = listSearchHistory
        }
        const searchHistoryFinally = []

        const uniqueTreeDataSearchHistory = updateListSearchHistory && updateListSearchHistory.length > 0 && updateListSearchHistory.filter((a, i) => updateListSearchHistory.findIndex((s) => a.key.toLowerCase() === s.key.toLowerCase()) === i)
        const uniqueTreeDataSearchHistory2 = uniqueTreeDataSearchHistory && uniqueTreeDataSearchHistory.length > 0 && uniqueTreeDataSearchHistory.filter((a) =>  a.name.toLowerCase() !== '')
        if (uniqueTreeDataSearchHistory2 && uniqueTreeDataSearchHistory2.length > 0) {
            uniqueTreeDataSearchHistory2.forEach((item) => {
                if (item && item.children && item.children.length > 0) {
                    searchHistoryFinally.push(item)
                }
            })
        }
        const wordCounterHistory = {}
        searchHistoryFinally && searchHistoryFinally.length && searchHistoryFinally.forEach((elemHistory) => {
            elemHistory.children && elemHistory.children.length > 0 && elemHistory.children.forEach((elemHistory2) => {
                const { name, pageNumber } = elemHistory2
                if (!wordCounterHistory[pageNumber]) {
                    wordCounterHistory[pageNumber] = {}
                }
                if (!wordCounterHistory[pageNumber][name]) {
                    wordCounterHistory[pageNumber][name] = 1
                } else {
                    wordCounterHistory[pageNumber][name] += 1
                }
                elemHistory2.pageCount = wordCounterHistory[pageNumber][name]
                elemHistory2.keyMixtRedaction = `${elemHistory2.name}-${elemHistory2.pageNumber}-${elemHistory2.pageCount}`
                elemHistory2.categoryName = 'Smartviewer'
                elemHistory2.subcategoryName = 'Freehand'
            })
        })
        if (status && status === 'search-history') {
            this.setState({
                treeDataSearchHistory: searchHistoryFinally,
                navigationType: 'search-history'
            })
        }
        if (searchMatchedWords) {
            this.setState({
                matchedText: searchMatchedWords,
                searchIndex: 0,
                searchMatchedElement: searchMatchedWords,
                searchPage: searchMatchedWords[0] ? Number(searchMatchedWords[0].getAttribute('data-page-nr')) : 1,
                currentMarkedIndex: 1,
                borderColor: color,
                valueSelectNavigation: 'search-history'
            })
            const element = searchMatchedWords[0]
            if (element) {
                element.id = 'matched-word--current'
                element.style.borderColor = color
                this.onSearchChangePage(element).then(() => {
                    this.scrollToMatchedWord()
                })
            }
        }
    }

    /**
     * This method is for searching functionality, it is highlighting the next text adding a special id,
     * and change the page to the location of searched text
     * */
    highlightNextText = () => {
        let index = this.state.searchIndex
        if (index >= 0) {
            const prevElement = this.state.matchedText[index]
            if (prevElement) {
                prevElement.removeAttribute('id')
            }
        }
        index = index + 1

        if (this.state.matchedText) {
            const element = this.state.matchedText[index]
            if (element) {
                element.id = 'matched-word--current'
                element.style.borderColor = this.state.borderColor
                const currentMarkedIndex = this.state.currentMarkedIndex + 1
                this.setState({
                    searchIndex: index,
                    currentMarkedIndex: currentMarkedIndex
                })
                this.onSearchChangePage(element)
            }
        }
    }

    /**
     * This method is for searching functionality, it is highlighting the prev text adding a special id,
     * and change the page to the location of searched text
     * */
    highlightPrevText = () => {
        let index = this.state.searchIndex
        const nextElement = this.state.matchedText[index]
        if (nextElement) {
            nextElement.removeAttribute('id')
        }
        if (this.state.matchedText) {
            index = index - 1
            const element = this.state.matchedText[index]
            if (element) {
                element.id = 'matched-word--current'
                element.style.borderColor = this.state.borderColor
                const currentMarkedIndex = this.state.currentMarkedIndex > 0 ? this.state.currentMarkedIndex - 1 : 0
                this.setState({
                    searchIndex: index,
                    currentMarkedIndex: currentMarkedIndex
                })
                this.onSearchChangePage(element)
            }
        }
    }
    onSearchTextElementFind = (item) => {
        const { searchMatchedElement, searchMatchId } = this.state
       // console.log('find history BBBBBBBBBBBBBBBBBBBBBBBBB', item)
        const previousElement = searchMatchedElement[searchMatchId]
        //const previousElement = searchMatchedElement[item.id]
        if (searchMatchedElement) {
            if (item.id !== searchMatchId && previousElement)
                previousElement.removeAttribute('id')
        }
        const color = '#3dd8db'

        //const parentClassName = this.props.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
        // const options = {
        //   'className': 'matched-word',
        //   'ignoreJoiners': true,
        //   'element': 'span',
        //   'acrossElements': true,
        //   'separateWordSearch': false,
        //   'wildcards': 'withSpaces'
        // }
        // const instance = new Mark(document.querySelector('#pdf-page'))
        // instance.mark(item.name.trim(), options)
        // const searchMatchedWords = document.querySelectorAll('span.matched-word')
        // searchMatchedWords.forEach((v) => {
        //   const parent = v.closest(parentClassName)
        //   v.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))
        //
        // })
        if (searchMatchedElement) {
           // console.log('find history BBBBBBBBBBBBBBBBBBBBBBBBB 222222222222222222222222222', item)
            this.setState({
                matchedText: searchMatchedElement[item.id],
                searchIndex: item.id,
                searchPage: searchMatchedElement[item.id] ? Number(searchMatchedElement[item.id].getAttribute('data-page-nr')) : 1,
                currentMarkedIndex: 1,
                borderColor: color,
                searchMatchId: item.id,
                valueSelectNavigation: 'search-history'
            })
            let elementSelect = ''
            let elementSelect3 = []
            searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item2) => {
                const element = item2
                if (element.name.toLowerCase() === item.key.toLowerCase()) {
                    elementSelect = item2
                    elementSelect3.push(item2)
                    element.style.borderColor = color
                } else {
                    //elementSelect = item2
                    element.style.borderColor = 'transparent'
                }
            })
            //const element = searchMatchedElement[item.name]
            if (elementSelect) {
                elementSelect.id = 'matched-word--current'
                elementSelect.style.borderColor = color
                this.onSearchChangePage(elementSelect).then(() => {
                    //this.scrollToMatchedWord()
                })
            }
            // if (element) {
            //   element.id = 'matched-word--current'
            //   element.style.borderColor = color
            //   this.onSearchChangePage(element).then(() => {
            //     this.scrollToMatchedWord()
            //   })
            // }
        }
    }
    /**
     *  This method is for searching functionality, it changes the page to the location of searched text
     * */
    async onSearchChangePage(element, indexEl, arrayQuery, uniqueElementKeyList, pageNr = 1) {
        const elementsPage = element !== null ? Number(element.getAttribute('data-page-nr')) : pageNr
        if (elementsPage === this.state.totalPages) {
            // this.setState({
            //   checkStartRedaction: false
            // })
            this.setState({
                checkStartRedactAllPagesNextFiles: false,
                checkStartRedactAllPagesBackFiles: false
            })
        }
        if (elementsPage && (elementsPage !== this.state.pageNumber) && elementsPage !== 0) {
           // console.log('elementsPage RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR 000000000000000000000000000', elementsPage)
            // if (this.state.navigationType === 'pages') {
            //   this.slider.slickGoTo(elementsPage - 1)
            // }
            // this.setState({
            //   checkStartRedaction: false
            // })
            //this.changePage(elementsPage, null, null, indexEl, arrayQuery)
            //this.changePageWords(elementsPage, null, null, indexEl, arrayQuery, uniqueElementKeyList)
            this.changePageMixed(elementsPage, null, null, indexEl, arrayQuery)

            const elemPage = elementsPage + 1
            if (elementsPage === this.state.totalPages) {
                setTimeout(() => {
                    if (elementsPage === this.state.totalPages) {
                        this.setState({
                            checkStartRedaction: false
                        })
                        this.onRemoveEventDisableScroll()
                    }
                }, 5000)
            } else if (elemPage === this.state.totalPages) {
                setTimeout(() => {
                    if (elemPage === this.state.totalPages) {
                        this.setState({
                            checkStartRedaction: false
                        })
                        this.onRemoveEventDisableScroll()
                    }
                }, 5000)
            } else if (elemPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' ||
                this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                setTimeout(() => {
                    this.setState({
                        checkStartRedaction: false
                    })
                    this.onRemoveEventDisableScroll()
                }, 5000)
            }
        } else if ((indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) || (indexEl === 0 &&  arrayQuery.length === 1)) {
           // console.log('elementsPage RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR 111111111111111111111111111111', elementsPage)
            //this.changePage(elementsPage,null, null, indexEl, arrayQuery)
            //this.changePageWords(elementsPage,null, null, indexEl, arrayQuery, uniqueElementKeyList)
            this.changePageMixed(elementsPage,null, null, indexEl, arrayQuery)
            const elemPage = elementsPage + 1
            if (elementsPage === this.state.totalPages) {
                setTimeout(() => {
                    if (elementsPage === this.state.totalPages) {
                        this.setState({
                            checkStartRedaction: false
                        })
                        this.onRemoveEventDisableScroll()
                    }
                }, 5000)
            } else if (elemPage === this.state.totalPages) {
                setTimeout(() => {
                    if (elemPage === this.state.totalPages) {
                        this.setState({
                            checkStartRedaction: false
                        })
                        this.onRemoveEventDisableScroll()
                    }
                }, 5000)
            } else if (elemPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' ||
                this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                setTimeout(() => {
                    this.setState({
                        checkStartRedaction: false
                    })
                    this.onRemoveEventDisableScroll()
                }, 5000)
            }
        } else {
            // this.setState({
            //   checkStartRedaction: false
            // })
        }
    }

    changePageWords = (newPageNumber, annt, statusPageSidebar, indexEl, arrayQuery, uniqueElementKeyList) => {
        this.setState({
            statusClickPageSidebar: statusPageSidebar,
            stateSidebarPages: false
        })
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        if (elements) {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
                    elements[i].classList.remove('show-pdf-page')
                    elements[i].classList.add('show-pdf-page')
                } else if (elements[i]) {
                    elements[i].classList.remove('show-pdf-page')
                }
            }
        }

        //add active class to pages thumbnails
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }
        const points = this.getRectanglesForPreviewPage()
        this.updateRectanglesWords(points, statusPageSidebar, indexEl, arrayQuery, uniqueElementKeyList)
        if ((indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) || (indexEl === 0 &&  arrayQuery.length === 1)) {
            setTimeout(() => {
                const points = this.getRectanglesForPreviewPage()
                this.updateRectanglesWords(points, statusPageSidebar, indexEl, arrayQuery, uniqueElementKeyList)
                initialCanvasData[this.state.pageNumber] = points
            }, 5000)
        }
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.hideCanvasModals()
        this.setState({
            pageNumber: newPageNumber,
            canvasData: initialCanvasData,
            forPageNumber: newPageNumber,
            haveChanges: false,
        }, () => {
            if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                    if (annt) {
                        if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                            || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
                            rectangle.set('stroke', 'rgb(255,244,11)')
                            rectangle.set('strokeWidth', 2)
                        }
                    }
                })
                canvas.renderAll()
            } else {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                })
                canvas.renderAll()
            }
        })
    }

    updateRectanglesWords = (points, statusPageSidebar, indexEl, arrayQuery, uniqueElementKeyList) => {
        const { statusAfterRemove, stateSidebarPages, statusRedactAll, statusDrawRedaction, dtoRedactWord, sourceActive } = this.state
        const pointsToSave = {}
        const listPoints = []
        let maxHeight //= 10000
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elements.length > 0) {
            maxHeight = elements[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        points.forEach((element) => {
            let y1 = element.y1 - 1
            if(y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if(y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true
            }
            this.onAddCategoryAndSubcategoryOnSavedRectangles(obj, element)
            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            this.setState({
                pagePointSumList: element.pagePointSum,
            })
        })
        const dtoRedactEachWord = []
        let dtoRedactEachWordAll = []
        let uniqueDtoRedactEachWordAll = []
        const dataToSave = {
            //idDoc: this.props.documentId,
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSave

        }
        const dataGetPoints = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
        }
        dtoRedactEachWord.push(dataToSave)
        //dtoRedactEachWordAll = this.state.dtoRedactWord.concat(dtoRedactEachWord)
        dtoRedactEachWordAll = dtoRedactEachWord.concat(this.state.dtoRedactWord)
        dtoRedactEachWordAll && dtoRedactEachWordAll.length > 0 && dtoRedactEachWordAll.forEach((list) => {
        })

        uniqueDtoRedactEachWordAll = dtoRedactEachWordAll && dtoRedactEachWordAll.length > 0 && dtoRedactEachWordAll.filter((a, i) => dtoRedactEachWordAll.findIndex((s) => a.page === s.page) === i)
        this.setState({
            dtoRedactWord: dtoRedactEachWordAll
        }, () => {
        })
        if (this.props.isSmartBoxApplication) {
            if (!statusAfterRemove) {
                if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
                    if ((statusDrawRedaction || (statusPageSidebar && statusPageSidebar === 'pageSidebar')) && this.state.drawSelectedMouse === true && !stateSidebarPages) {
                        const dtoRedactEachWord = []
                        dtoRedactEachWord.push(dataToSave)
                        const saveCanvasDocumentSmartViewDTO = {
                            idDoc: this.props.documentId,
                            pageCoordinates: dtoRedactEachWord

                        }
                        if (!this.props.isOcr) {
                           // console.log('apiSmartBoxSaveCanvasDocumentSmartview LLLLLLLLLLLLLLLLLLLLLLLLL')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                                this.setState({
                                    statusDrawRedaction: false
                                })
                            }).catch((error) => {
                                if (error?.response?.status === 504) {
                                    CustomNotificationManager.cleanBuffer('warning', '')
                                } else {
                                    console.log(error)
                                }
                            })
                        }

                        // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
                        //   this.setState({
                        //     statusDrawRedaction: false
                        //   })
                        // }).catch((error) => {
                        //   if (error?.response?.status === 504) {
                        //     CustomNotificationManager.cleanBuffer('warning', '')
                        //   }else{
                        //     console.log(error)
                        //   }
                        // })
                        apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

                        }).catch((error) => {
                            if (error?.response?.status === 504) {
                                CustomNotificationManager.cleanBuffer('warning', '')
                            }else{
                                console.log(error)
                            }
                        })
                    } else
                    if (statusPageSidebar && statusPageSidebar === 'pageSidebar' && !stateSidebarPages) {
                        apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

                        }).catch((error) => {
                            if (error?.response?.status === 504) {
                                CustomNotificationManager.cleanBuffer('warning', '')
                            }else{
                                console.log(error)
                            }
                        })
                    } else if(!statusPageSidebar && statusPageSidebar !== 'pageSidebar' && statusRedactAll !== 'true') {

                        const saveCanvasDocumentSmartViewDTO = {
                            idDoc: this.props.documentId,
                            pageCoordinates: uniqueDtoRedactEachWordAll

                        }
                        if (!this.props.isOcr) {
                            //console.log('SAVE ---- qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq')
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {

                            })
                        }



                        // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
                        //
                        // }).catch((error) => {
                        //   if (error?.response?.status === 504) {
                        //     CustomNotificationManager.cleanBuffer('warning', '')
                        //   }else{
                        //     console.log(error)
                        //   }
                        // })
                    }
                }
            }
            this.setState({
                statusSavedSmartView: true
            })
        } else {
            apiUpdateCanvasPoints(dataToSave).then(() => {

            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
            this.setState({
                statusAfterRemove: false
            })
        }
    }

    async onChangePageWithClear(pageNr = 1) {
        if (pageNr && (pageNr !== this.state.pageNumber) && pageNr !== 0) {

            this.changePageWithClear(pageNr)

        }
    }

    /**
     *  This method is for searching functionality, it draw rectangle on current searched text
     * */
    drawRectangleOnMatchedWord = () => {
        const el = document.getElementById('matched-word--current')
        if (el) {
            const elementsText = el.innerText
            const selectionPosition = el.getBoundingClientRect()
            const parentWr = document.querySelector('div.canvas-page-1')
            const rect = new fabric.Rect({
                left: selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX,
                top: selectionPosition.top - parentWr.getBoundingClientRect().top - 3,
                width: selectionPosition.width,
                height: selectionPosition.height + 3,
                fill: 'rgba(0, 0, 0, 0.4)',
                angle: 0,
                padding: 0,
                selectable: true,
                annotation: elementsText ? elementsText : '',
                text: elementsText ? elementsText : ''
            })
            this._sketch._fc.add(rect)
        }
    }

    fireSwalToRedactAllMatchedWords = () => {
        swal.fire({
            text: `Do you want to hide all ${ this.state.matchedText.length } matched words ?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#D33D33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                this.drawAllMatchedWords()
            }
        })
    }

    /**
     *  This method is for searching functionality, it draw rectangle on current searched text
     * */
    drawAllMatchedWords() {

        const elements = document.querySelectorAll('span.matched-word')
        const firstElement = elements[0]
        const parentWr = document.querySelector('div.canvas-page-1')
        for (const el of elements) {
            const elRect = el.getBoundingClientRect()
            this.addSearchedRectangle(el, parentWr, false, elRect)
        }
        if (firstElement) {
            this.onSearchChangePage(firstElement)
        }
    }

    /**
     *  This method is for searching functionality, it draw rectangle on current searched text
     * */
    drawAllMatchedWordsByClassList = (classNameList, unCheckedKeys=[], source='standard', allWordsRedacted) => {
        const { idDoc, docAnnotationCategories, totalPages, divPrint } = this.state
        this.setState({
            checkStartRedaction: null,
            sourceActive: source
        })
        if (classNameList && idDoc) {
            const parameters = {
                idDocument: idDoc,
                orderCategory: docAnnotationCategories
            }
            let spanCoords = []
            for(let i=1; i<=totalPages; i++) {

                const otherPages = document.querySelectorAll('div#'+divPrint+':not([data-pageid="'+i+'"])')
                otherPages.forEach((page) => {
                    page.classList.remove('show-pdf-page')
                })

                const page = document.querySelectorAll('div#'+divPrint+'[data-pageid="'+i+'"]')
                if(page.length > 0) {
                    page[0].classList.add('show-pdf-page')
                }

                const spans = document.querySelectorAll('span[data-markjs][data-page-nr="'+i+'"]')

                spans.forEach((el) => {
                    const temp = el.className.split(' ').slice(-1)
                    const rect = el.getBoundingClientRect()
                    spanCoords[temp[0]] = { top: rect.top, left: rect.left, width: rect.width, height: rect.height }
                })
            }
            this.setState({ spanCoords }, () => {
                this.changeQueryStatus(parameters, classNameList, unCheckedKeys, source, allWordsRedacted)
                this.onSetDocumentRedactAllStatus("false")
            })
        }
    }

    setAlreadyRedactedList = (alreadyRedactedList) => this.setState({ alreadyRedactedList })

    changeDataAnnotationCategory = (docAnnotationCategories, unCheckedKeys) => {
        const { treeDataAnnotations } = this.state
        const newDocAnnotationCategories = []
        if (treeDataAnnotations && treeDataAnnotations.length) {
            const tree = treeDataAnnotations[0]
            if (tree.children && tree.children.length) {
                tree.children.forEach((parent) => {
                    if (parent.children && parent.children.length) {
                        parent.children.forEach((children) => {
                            const temp = `doc_annotate_${ children.key.split(' ').join('_') }`
                            if (docAnnotationCategories.includes(temp)) {
                                if (children.children.length) {
                                    let numberNumberOfChildren = 0
                                    children.children.forEach((nephew) => {
                                        numberNumberOfChildren += nephew.children.length
                                    })
                                    newDocAnnotationCategories.push({ 'name': temp, 'children': numberNumberOfChildren })
                                } else {
                                    newDocAnnotationCategories.push({ 'name': temp, 'children': children.children.length })
                                }
                            }
                        })
                    }
                })
            }
        }
        return newDocAnnotationCategories
    }
    checkedKeysObjectRefsStandard = (checkObjectRefsStandard) => {
        this.setState({
            checkObjectRefsStandard: checkObjectRefsStandard
        })
    }
    fetchSmartBoxAllAfterRemove = async(documentId, clientId) => {
        const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
        const newCanvasData = await this.convertCanvasData(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
        this.setState({
            canvasData: newCanvasData,
        }, () => {
            //this.initialUpdateCanvas()
        })
    }

    initialUpdateCanvas = (parameters, classNameList, unCheckedKeys, source) => {
        const { totalPages } = this.state
        const initialCanvasData = this.state.canvasData
        for (let i = 1; i <= totalPages; i++) {
            let keepOnPage = []
            if(typeof initialCanvasData[i] !== 'undefined') {
                for (let j = 0; j < initialCanvasData[i].length; j++) {
                    const parsed = typeof initialCanvasData[i][j].annotation === 'undefined' ?
                        JSON.parse(initialCanvasData[i][j]) : initialCanvasData[i][j]
                    if(parsed.type === 1 || parsed.type === 3) {
                        keepOnPage.push(parsed)
                    }
                }
            }
            initialCanvasData[i] = keepOnPage
        }
        this.setState({
            canvasData: initialCanvasData,
            haveChanges: false,
            clearSketch: true
        }, () => {
            this.setState({ clearSketch: false })
            //console.log('VECHI 5555555555555555555555555555555555555')
            this.onSearchChangePage(null, 1).then(() => {
               // console.log('VECHI 66666666666666666666666666666666')
                this.callQueryStatus(parameters, classNameList, unCheckedKeys, source, false)
            })
        })
    }
    setTreeDataRegEx = (treeDataRegEx) => this.setState({ treeDataRegEx })
    setTreeDataDict = (treeDataDict) => this.setState({ treeDataDict })

    changeQueryStatus = (parameters, classNameList, unCheckedKeys, source, allWordsRedacted) => {
        const { alreadyRedactedKeys, treeDataAnnotations, canvasData, treeDataRegEx, treeDataDict, treeDataSearchHistory } = this.state
        parameters.orderCategory = this.changeDataAnnotationCategory(parameters.orderCategory, unCheckedKeys)
        if (unCheckedKeys.length) {
           // console.log('VECHI 111111111111111111111111111111111111')
            alreadyRedactedKeys[source] = []
            this.setState({ alreadyRedactedKeys, alreadyRedactedList: [], unCheckedKeys: [], canvasCleaned: true, statusSaveQuery: true }, () => {

                //const { totalPages } = this.state
                const data = {}
                data.idDoc = this.props.documentId
                let unCheckedKeyWord = []
                let unCheckedKeyWordAllList = []
                let unCheckedKeyWordUpdate = []
                let unCheckedKeyWordHistory = []
                let unCheckedKeyWordRegEx = []
                let unCheckedKeyWordRegExAllList = []
                let unCheckedKeyWordDict = []
                let unCheckedKeyWordDictAllDict = []
                let unCheckedKeyWordDictKey = []
                const selectAllItems = []
                const selectAllItemsRegEx = []
                const selectAllItemsDict = []
                treeDataAnnotations && treeDataAnnotations.length > 0 && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((child) => {
                    child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                        child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                            child3 && child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                                selectAllItems.push(child4)
                            })
                        })
                    })
                })
                if (treeDataSearchHistory && treeDataSearchHistory.length > 0) {
                    treeDataSearchHistory.forEach((tree) => {
                        tree.children && tree.children.length > 0 && tree.children.forEach((tree2) => {
                            unCheckedKeys && unCheckedKeys.length > 0 && unCheckedKeys.forEach((key2) => {
                                if (tree2.key === key2) {
                                    unCheckedKeyWordHistory.push(`${tree2.key} => ${tree2.name} => ${tree2.pageNumber}`)
                                }
                            })
                        })
                    })
                }
                const wordSelected = []
                selectAllItems && selectAllItems.length > 0 && selectAllItems.forEach((obj) => {
                    unCheckedKeys && unCheckedKeys.length > 0 && unCheckedKeys.forEach((key2) => {
                        if (obj.key === key2) {
                            wordSelected.push(obj)
                            unCheckedKeyWord.push(`${obj.key} => ${obj.name} => ${obj.pageNumber}`)
                            unCheckedKeyWordAllList.push(obj)
                        }
                    })
                })
                treeDataRegEx && treeDataRegEx.length > 0 && treeDataRegEx[0].children && treeDataRegEx[0].children.length > 0 && treeDataRegEx[0].children.forEach((child) => {
                    child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                        child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                            selectAllItemsRegEx.push(child3)
                        })
                    })
                })
                selectAllItemsRegEx && selectAllItemsRegEx.length > 0 && selectAllItemsRegEx.forEach((obj) => {
                    unCheckedKeys && unCheckedKeys.length > 0 && unCheckedKeys.forEach((key2) => {
                        if (obj.key === key2) {
                            unCheckedKeyWordRegEx.push(`${obj.key} => ${obj.name} => ${obj.pageNumber}`)
                            unCheckedKeyWordRegExAllList.push(obj)
                        }
                    })
                })
                treeDataDict && treeDataDict.length > 0 && treeDataDict[0].children && treeDataDict[0].children.length > 0 && treeDataDict[0].children.forEach((child) => {
                    child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                        child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                            selectAllItemsDict.push(child3)
                            unCheckedKeyWordDictKey.push(child3.key)
                        })
                    })
                })
                selectAllItemsDict && selectAllItemsDict.length > 0 && selectAllItemsDict.forEach((obj) => {
                    unCheckedKeys && unCheckedKeys.length > 0 && unCheckedKeys.forEach((key2) => {
                        if (obj.key === key2) {
                            unCheckedKeyWordDict.push(`${obj.key} => ${obj.name} => ${obj.pageNumber}`)
                            unCheckedKeyWordDictAllDict.push(obj)
                        }
                    })
                })
                let pageRectangle = []
                Object.keys(canvasData).forEach((key) => {
                    if (canvasData[key] && canvasData[key].length) {
                        canvasData[key].forEach((item) => {
                            if (item) {
                                let itemDecoded = item
                                if (typeof item === 'string') {
                                    itemDecoded = JSON.parse(item)
                                }
                                wordSelected && wordSelected.length > 0 && wordSelected.forEach((item2) => {
                                    if ((itemDecoded.annotation === item2.key) || (itemDecoded.annotation === item2.name)) {
                                        itemDecoded.numberPage = key
                                        pageRectangle.push(itemDecoded)
                                    }
                                })
                            }
                        })
                    }
                })
                const uniquePageRectangle = pageRectangle && pageRectangle.length > 0 ? pageRectangle.filter((a, i) => pageRectangle.findIndex((s) => a.annotation === s.annotation) === i) : []
               // console.log('VECHI 222222222222222222222222222222222222')
                this.onPageRectangle(pageRectangle, 'key')
                //const jsonPageRectangle = JSON.stringify(this.onPageRectangle(pageRectangle, 'key'))
                const dtoRemoveCanvas = {
                    idDocument: this.props.documentId,
                    keyWordStandard: source === 'history' ? unCheckedKeyWordHistory : unCheckedKeyWord,
                    keywordDictionary: unCheckedKeyWordDict,
                    keywordRegex: unCheckedKeyWordRegEx,
                    pageRectangleList: uniquePageRectangle
                }
                const dtoRemoveCanvas2 = {
                    idDocument: this.props.documentId,
                    keyWordStandard: source === 'history' ? unCheckedKeyWordHistory : unCheckedKeyWord,
                    keywordDictionary: unCheckedKeyWordDict,
                    keywordRegex: unCheckedKeyWordRegEx,
                    pageRectangleList: uniquePageRectangle
                }
                let dtoRemoveCanvasAllCanvas = []
                dtoRemoveCanvasAllCanvas = unCheckedKeyWordAllList.concat(unCheckedKeyWordRegExAllList, unCheckedKeyWordDictAllDict)
                //de decomentat
                this.savePageAfterRemove(dtoRemoveCanvas, alreadyRedactedKeys, parameters, unCheckedKeys, classNameList, source, dtoRemoveCanvasAllCanvas)
            })
        } else {
            this.setState({ canvasCleaned: false }, () => {
                this.setState({
                    statusSaveQuery: false
                })
                this.callQueryStatus(parameters, classNameList, unCheckedKeys, source, true, allWordsRedacted)
            })
        }
    }
    onPageRectangle = (arr, key) => {
        return arr.reduce((obj, item) => {
            obj[item] = item
            return obj
        }, {})
    }
    callQueryStatus = (parameters, classNameList, unCheckedKeys, source, emptyUncheckedKeys, allWordsRedacted) => {
        const { alreadyRedactedKeys, redactingMode, spanCoords } = this.state
        if (source === 'standard') {
            apiSmartBoxGetNewQueryStatus(parameters).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        checkStartRedactAllPagesNextFiles: true,
                        checkStartRedactAllPagesBackFiles: true
                    })
                    if (this.state.pageNumber === 1) {
                        setTimeout(() => {
                            this.setState({
                                checkStartRedactAllPagesNextFiles: false,
                                checkStartRedactAllPagesBackFiles: false
                            })
                        }, 2000)
                    }
                    const queryStatus = response.data
                    const parentWr = document.querySelector('div.canvas-page-1')
                    const pageHeight = document.querySelector('#pdf-page').clientHeight
                    let elements = []
                    classNameList.forEach((name, index) => {
                        const elementsByClass = document.getElementsByClassName(name)
                        Object.keys(elementsByClass).forEach((idx) => {
                            elements.push(elementsByClass[idx])
                        })
                    })
                    elements = this.sortElementsByPage(elements)
                    const elementKeyList = []
                    // const parentWr = document.querySelector('div.canvas-page-1')
                    // let elements = document.getElementsByClassName(name)
                    // elements = this.sortElementsByPage(elements)
                    //for (const el of elements) {
                    allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((elem) => {
                        elements && elements.length > 0 && elements.forEach((el) => {
                            const temp = el.className.split(' ').slice(-1)
                            if (temp.length) {
                                const elementKey = temp[0]
                                if (elem.key === elementKey) {
                                    elem.el = el
                                }
                            }
                        })
                    })
                    if (allWordsRedacted && allWordsRedacted.length > 0) {
                        allWordsRedacted.forEach((ell, indexEl, arrayQuery) => {
                            const el = ell.el
                            const temp = el.className.split(' ').slice(-1)
                            if (temp.length && Object.keys(queryStatus).length) {
                                const elementKey = temp[0]
                                let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : el.getBoundingClientRect()
                                Object.keys(queryStatus).forEach((key) => {
                                    if (queryStatus[key].length) {
                                        queryStatus[key].forEach((item) => {
                                            if (!alreadyRedactedKeys[source].includes(elementKey)) {
                                                if (indexEl < arrayQuery.length - 2) {
                                                    //indexEl = arrayQuery.length - 1
                                                }
                                                const left = elRect.left
                                                const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                                                const width = elRect.width
                                                const height = elRect.height
                                                const elRectObj = {left, top, width, height}
                                                elementKeyList.push(elementKey)

                                                const uniqueElementKeyList = elementKeyList && elementKeyList.length > 0 && elementKeyList.filter((a, i) => elementKeyList.findIndex((s) => a === s) === i)
                                                if (item.key === elementKey) {
                                                    alreadyRedactedKeys[source].push(elementKey)
                                                    const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'

                                                    // const elRect = el.getBoundingClientRect();
                                                    setTimeout(() => {
                                                        this.addSearchedRectangle(el, parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, uniqueElementKeyList, ell)
                                                    }, 500)
                                                } else {
                                                    const tempItemKey = item.key.split('-')[0]
                                                    const tempElementKey = elementKey.split('-')[0]
                                                    if (tempItemKey === tempElementKey) {
                                                        alreadyRedactedKeys[source].push(elementKey)
                                                        const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                                                        setTimeout(() => {
                                                            this.addSearchedRectangle(el, parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, uniqueElementKeyList, ell)
                                                        }, 500)
                                                    } else {
                                                        this.setState({
                                                            checkStartRedaction: true
                                                        })
                                                        this.onCallToDisableScroll()
                                                    }
                                                }
                                            } else if (unCheckedKeys && unCheckedKeys.length > 0) {
                                                const left = elRect.left
                                                const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                                                const width = elRect.width
                                                const height = elRect.height
                                                const elRectObj = {left, top, width, height}

                                                if (item.key === elementKey) {
                                                    alreadyRedactedKeys[source].push(elementKey)
                                                    const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'

                                                    setTimeout(() => {
                                                        this.addSearchedRectangle(el, parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery,[] , ell)
                                                    }, 500)
                                                }
                                            } else {
                                                if (indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) {
                                                    const left = elRect.left
                                                    const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                                                    const width = elRect.width
                                                    const height = elRect.height
                                                    const elRectObj = {left, top, width, height}

                                                    if (item.key === elementKey) {
                                                        alreadyRedactedKeys[source].push(elementKey)
                                                        //const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                                                        const colorForRectangles = null

                                                        setTimeout(() => {
                                                            this.addSearchedRectangle(el, parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, [], ell)
                                                        }, 500)
                                                    }
                                                }
                                            }
                                        })
                                    }
                                })
                                if(emptyUncheckedKeys) {
                                    // this.setState({ unCheckedKeys: [] })
                                }
                            }
                            //}
                        })
                    } else {
                        elements && elements.length > 0 && elements.forEach((el, indexEl, arrayQuery) => {
                            const ell = el
                            const temp = el.className.split(' ').slice(-1)
                            if (temp.length && Object.keys(queryStatus).length) {
                                const elementKey = temp[0]
                                let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : el.getBoundingClientRect()
                                Object.keys(queryStatus).forEach((key) => {
                                    if (queryStatus[key].length) {
                                        queryStatus[key].forEach((item) => {
                                            if (!alreadyRedactedKeys[source].includes(elementKey)) {
                                                if (indexEl < arrayQuery.length - 2) {
                                                    //indexEl = arrayQuery.length - 1
                                                }
                                                const left = elRect.left
                                                const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                                                const width = elRect.width
                                                const height = elRect.height
                                                const elRectObj = {left, top, width, height}
                                                elementKeyList.push(elementKey)

                                                const uniqueElementKeyList = elementKeyList && elementKeyList.length > 0 && elementKeyList.filter((a, i) => elementKeyList.findIndex((s) => a === s) === i)
                                                if (item.key === elementKey) {
                                                    alreadyRedactedKeys[source].push(elementKey)
                                                    const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'

                                                    // const elRect = el.getBoundingClientRect();
                                                    setTimeout(() => {
                                                        this.addSearchedRectangle(el, parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, uniqueElementKeyList, ell)
                                                    }, 500)
                                                } else {
                                                    const tempItemKey = item.key.split('-')[0]
                                                    const tempElementKey = elementKey.split('-')[0]
                                                    if (tempItemKey === tempElementKey) {
                                                        alreadyRedactedKeys[source].push(elementKey)
                                                        const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                                                        setTimeout(() => {
                                                            this.addSearchedRectangle(el, parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, uniqueElementKeyList, ell)
                                                        }, 500)
                                                    } else {
                                                        this.setState({
                                                            checkStartRedaction: true
                                                        })
                                                        this.onCallToDisableScroll()
                                                    }
                                                }
                                            } else if (unCheckedKeys && unCheckedKeys.length > 0) {
                                                const left = elRect.left
                                                const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                                                const width = elRect.width
                                                const height = elRect.height
                                                const elRectObj = {left, top, width, height}

                                                if (item.key === elementKey) {
                                                    alreadyRedactedKeys[source].push(elementKey)
                                                    const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'

                                                    setTimeout(() => {
                                                        this.addSearchedRectangle(el, parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, [], ell)
                                                    }, 500)
                                                }
                                            } else {
                                                if (indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) {
                                                    const left = elRect.left
                                                    const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                                                    const width = elRect.width
                                                    const height = elRect.height
                                                    const elRectObj = {left, top, width, height}

                                                    if (item.key === elementKey) {
                                                        alreadyRedactedKeys[source].push(elementKey)
                                                        //const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                                                        const colorForRectangles = null

                                                        setTimeout(() => {
                                                            this.addSearchedRectangle(el, parentWr, colorForRectangles,unCheckedKeys, elRectObj, indexEl, arrayQuery, [], ell)
                                                        }, 500)
                                                    }
                                                }
                                            }
                                        })
                                    }
                                })
                                if(emptyUncheckedKeys) {
                                    // this.setState({ unCheckedKeys: [] })
                                }
                            }
                            //}
                        })
                    }
                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        } else if ((source === 'business22' || source === 'manual') && classNameList.length) {
            this.callQueryStatusForBusiness(classNameList, source, allWordsRedacted)
        } else if ((source === 'dictionaries' || source === 'regex')) {
            allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((elem) => {
                classNameList && classNameList.length > 0 && classNameList.forEach((name) => {
                    if (elem.key === name) {

                    }
                })
            })
            const pageHeight = document.querySelector('#pdf-page').clientHeight
            if (allWordsRedacted && allWordsRedacted.length > 0) {
                allWordsRedacted.forEach((name, indexEl, arrayQuery) => {
                    const parentWr = document.querySelector('div.canvas-page-1')
                    let elements = document.getElementsByClassName(name.key)
                    elements = this.sortElementsByPage(elements)
                    Object.keys(elements).forEach((index) => {
                        const temp = elements[index].className.split(' ').slice(-1)
                        if (temp.length) {
                            const elementKey = temp[0]

                            let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : elements[index].getBoundingClientRect()
                            const left = elRect.left
                            const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                            const width = elRect.width
                            const height = elRect.height
                            const elRectObj = {left, top, width, height}

                            if (!alreadyRedactedKeys[source]) {
                                alreadyRedactedKeys[source] = []
                            }
                            if (!alreadyRedactedKeys[source].includes(elementKey) || true) {
                                alreadyRedactedKeys[source].push(elementKey)
                                const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                                setTimeout(() => {
                                    this.addSearchedRectangle(elements[index], parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, [], name)
                                }, 500)
                            }
                        }
                    })
                })
            } else {
                classNameList.forEach((name, indexEl, arrayQuery) => {
                    const parentWr = document.querySelector('div.canvas-page-1')
                    let elements = document.getElementsByClassName(name)
                    elements = this.sortElementsByPage(elements)
                    Object.keys(elements).forEach((index) => {
                        const temp = elements[index].className.split(' ').slice(-1)
                        if (temp.length) {
                            const elementKey = temp[0]

                            let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : elements[index].getBoundingClientRect()
                            const left = elRect.left
                            const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                            const width = elRect.width
                            const height = elRect.height
                            const elRectObj = {left, top, width, height}

                            if (!alreadyRedactedKeys[source]) {
                                alreadyRedactedKeys[source] = []
                            }
                            if (!alreadyRedactedKeys[source].includes(elementKey) || true) {
                                alreadyRedactedKeys[source].push(elementKey)
                                const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                                setTimeout(() => {
                                    this.addSearchedRectangle(elements[index], parentWr, colorForRectangles,unCheckedKeys, elRectObj, indexEl, arrayQuery, [], name)
                                }, 500)
                            }
                        }
                    })
                })
            }
            this.toggleLoadingProcess(false)
        } else if (source === 'history') {
            const pageHeight = document.querySelector('#pdf-page').clientHeight
            const { searchMatchedElement } = this.state
            const classNameListNew = []
            classNameList && classNameList.length > 0 && classNameList.forEach((classItem) => {
                const keyName = classItem.replace(/\W/g, 'a')
                //classNameListNew.push(keyName.toLowerCase())
                classNameListNew.push(keyName)
            })
            const searchMatchedElement3 = []
            searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item3) => {
                const substringName = item3.className && item3.className.substring('matched-word'.length + 1, item3.className.length)
                const keyName2 = substringName.replace(/\W/g, 'a')
                if (classNameListNew.includes(keyName2)) {
                    searchMatchedElement3.push(item3)
                }
            })

            searchMatchedElement3 && searchMatchedElement3.length > 0 && searchMatchedElement3.forEach((item, indexEl, arrayQuery) => {
                const uniqueElementKeyList = classNameListNew
                const arrayQuery2 = []
                arrayQuery && arrayQuery.length > 0 && arrayQuery.forEach((elem2, indexClass) => {
                    arrayQuery2.push(elem2.className.split(' ').slice(-1))
                })

                const uniqueElementKeyList2 = arrayQuery2 && arrayQuery2.length > 0 && arrayQuery2.filter((a, i) => arrayQuery2.findIndex((s) => a === a) === i)

                const parentWr = document.querySelector('div.canvas-page-1')
                const substringName = item.className && item.className.substring('matched-word'.length + 1, item.className.length)
                const keyName2 = substringName.replace(/\W/g, 'a')
                if (classNameListNew.includes(keyName2)) {
                    if (item) {
                        const temp = item.className.split(' ').slice(-1)
                        const elementKey = temp[0]
                        let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : item.getBoundingClientRect()
                        const left = elRect.left
                        const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                        const width = elRect.width
                        const height = elRect.height
                        const elRectObj = {left, top, width, height}
                        const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                        setTimeout(() => {
                            this.addSearchedRectangleHistory(item, parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, uniqueElementKeyList)
                        }, 500)
                    }
                }
            })
            //const pageHeight = document.querySelector('#pdf-page').clientHeight
            this.toggleLoadingProcess(false)
        } else if (classNameList.length) {
            const pageHeight = document.querySelector('#pdf-page').clientHeight

            classNameList.forEach((name, indexEl, arrayQuery) => {
                const parentWr = document.querySelector('div.canvas-page-1')
                let elements = document.getElementsByClassName(name)
                elements = this.sortElementsByPage(elements)
                Object.keys(elements).forEach((index) => {
                    const temp = elements[index].className.split(' ').slice(-1)

                    if (temp.length) {
                        const elementKey = temp[0]

                        let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : elements[index].getBoundingClientRect()
                        const left = elRect.left
                        const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                        const width = elRect.width
                        const height = elRect.height
                        const elRectObj = {left, top, width, height}

                        if (!alreadyRedactedKeys[source]) {
                            alreadyRedactedKeys[source] = []
                        }
                        if (!alreadyRedactedKeys[source].includes(elementKey) || true) {
                            alreadyRedactedKeys[source].push(elementKey)
                            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                            setTimeout(() => {
                                this.addSearchedRectangle(elements[index], parentWr, colorForRectangles, unCheckedKeys, elRectObj, indexEl, arrayQuery, [], elements[index])
                            }, 500)

                        }
                    }
                })
            })
            this.toggleLoadingProcess(false)
        }
            // else if (unCheckedKeys && unCheckedKeys.length > 0) {
            //   alreadyRedactedKeys.business= []
            //   alreadyRedactedKeys.manual= []
            //   alreadyRedactedKeys.regex= []
            //   alreadyRedactedKeys.standard= []
            //   if (!alreadyRedactedKeys[source]) {
            //     alreadyRedactedKeys[source] = []
            //   }
            //   this.setState({
            //     alreadyRedactedKeys
            //   })
        // }
        else {
            this.toggleLoadingProcess(false)
        }
    }
    addSearchedRectangleHistory = (el, parentWr, color= false, elRect, unCheckedKeys = [], indexEl, arrayQuery, uniqueElementKeyList) => {
        const { canvasCleaned, alreadyRedactedList, redactingMode, totalPages, treeDataSearchHistory } = this.state

        if (el) {
            const temp = el.className.split(' ').slice(-1)
            if (temp.length) {
                const elementKey = temp[0]
                const elementsPage = Number(el.getAttribute('data-page-nr'))
                const elementsText = el.innerText
                this.setState({
                    checkStartRedaction: true
                })
                const elementKeyList = []
                elementKeyList.push(temp[0])
                this.onCallToDisableScroll()

                if (elementsPage !== this.state.pageNumber) {
                    this.onSearchChangePage(el, indexEl, arrayQuery, uniqueElementKeyList).then(() => {

                        const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
                        const selectionPosition = elRect //el.getBoundingClientRect()
                        const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
                        const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3

                        if (!canvasCleaned) {
                            if (!this.duplicateInCanvas(el, left, top)) {
                                const rect = new fabric.Rect({
                                    left: left,
                                    top: top,
                                    width: selectionPosition.width,
                                    height: selectionPosition.height + 3,
                                    fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                    angle: 0,
                                    padding: 0,
                                    selectable: true,
                                    text: elementsText ? elementsText : '',
                                    annotation: elementKey
                                })
                                this._sketch._fc.add(rect)
                                this._sketch._fc.renderAll()
                            }
                        } else if (!alreadyRedactedList.includes(elementKey)) {
                            alreadyRedactedList.push(elementKey)
                            const rect = new fabric.Rect({
                                left: left,
                                top: top,
                                width: selectionPosition.width,
                                height: selectionPosition.height + 3,
                                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                angle: 0,
                                padding: 0,
                                selectable: true,
                                text: elementsText ? elementsText : '',
                                annotation: elementKey
                            })
                            this._sketch._fc.add(rect)
                            this._sketch._fc.renderAll()
                        }
                        this.toggleLoadingProcess(false)
                        if (elementsPage !== totalPages) {
                            this.toggleLoadingProcess(false)
                            setTimeout(() => {
                                if (elementsPage === totalPages) {
                                    this.setState({
                                        checkStartRedaction: false
                                    })
                                    this.onRemoveEventDisableScroll()
                                }
                            }, 5000)
                        }
                    })
                    if (elementsPage !== totalPages) {
                        this.toggleLoadingProcess(false)
                        setTimeout(() => {
                            if (elementsPage === totalPages) {
                                this.setState({
                                    checkStartRedaction: false
                                })
                                this.onRemoveEventDisableScroll()
                            }
                        }, 5000)
                    }
                } else if ((indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) || (indexEl === 0 &&  arrayQuery.length === 1) || (indexEl === 0 &&  uniqueElementKeyList.length === 1)) {

                    this.onSearchChangePage(el, indexEl, arrayQuery, uniqueElementKeyList).then(() => {

                        const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
                        const selectionPosition = elRect //el.getBoundingClientRect()
                        const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
                        const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3

                        if (!canvasCleaned) {
                            if (!this.duplicateInCanvas(el, left, top)) {
                                const rect = new fabric.Rect({
                                    left: left,
                                    top: top,
                                    width: selectionPosition.width,
                                    height: selectionPosition.height + 3,
                                    fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                    angle: 0,
                                    padding: 0,
                                    selectable: true,
                                    text: elementsText ? elementsText : '',
                                    annotation: elementKey
                                })
                                this._sketch._fc.add(rect)
                                this._sketch._fc.renderAll()
                            }
                        } else if (!alreadyRedactedList.includes(elementKey)) {
                            alreadyRedactedList.push(elementKey)
                            const rect = new fabric.Rect({
                                left: left,
                                top: top,
                                width: selectionPosition.width,
                                height: selectionPosition.height + 3,
                                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                angle: 0,
                                padding: 0,
                                selectable: true,
                                text: elementsText ? elementsText : '',
                                annotation: elementKey
                            })
                            this._sketch._fc.add(rect)
                            this._sketch._fc.renderAll()
                        }
                        this.toggleLoadingProcess(false)
                        if (elementsPage !== totalPages) {
                            this.toggleLoadingProcess(false)
                            setTimeout(() => {
                                if (elementsPage === totalPages) {
                                    this.setState({
                                        checkStartRedaction: false
                                    })
                                    this.onRemoveEventDisableScroll()
                                }
                            }, 5000)
                        }
                    })
                    if (elementsPage !== totalPages) {
                        this.toggleLoadingProcess(false)
                        setTimeout(() => {
                            if (elementsPage === totalPages) {
                                this.setState({
                                    checkStartRedaction: false
                                })
                                this.onRemoveEventDisableScroll()
                            }
                        }, 5000)
                    }
                } else {
                    if (elementsPage === totalPages) {
                        setTimeout(() => {
                            if (elementsPage === totalPages) {
                                this.setState({
                                    checkStartRedaction: false
                                })
                                this.onRemoveEventDisableScroll()
                            }
                        }, 5000)
                    } else if (elementsPage !== totalPages) {
                        setTimeout(() => {
                            if (elementsPage !== totalPages) {
                                this.setState({
                                    checkStartRedaction: false
                                })
                                this.onRemoveEventDisableScroll()
                            }
                        }, 5000)
                    }
                    const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
                    const selectionPosition = elRect //el.getBoundingClientRect()
                    const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
                    const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3
                    if (!canvasCleaned) {
                        if (!this.duplicateInCanvas(el, left, top)) {
                            const rect = new fabric.Rect({
                                left: left,
                                top: top,
                                width: selectionPosition.width,
                                height: selectionPosition.height + 3,
                                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                angle: 0,
                                padding: 0,
                                selectable: true,
                                text: elementsText ? elementsText : '',
                                annotation: elementKey
                            })
                            this._sketch._fc.add(rect)
                            this._sketch._fc.renderAll()
                        }
                    } else if (!alreadyRedactedList.includes(elementKey)) {
                        alreadyRedactedList.push(elementKey)
                        const rect = new fabric.Rect({
                            left: left,
                            top: top,
                            width: selectionPosition.width,
                            height: selectionPosition.height + 3,
                            fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                            angle: 0,
                            padding: 0,
                            selectable: true,
                            text: elementsText ? elementsText : '',
                            annotation: elementKey
                        })
                        this._sketch._fc.add(rect)
                        this._sketch._fc.renderAll()
                    }
                    if (elementsPage === totalPages) {
                        this.toggleLoadingProcess(false)
                    }
                }
                treeDataSearchHistory && treeDataSearchHistory.length > 0 && treeDataSearchHistory.forEach((elem) => {
                    if (el.name.toUpperCase().includes(elem.name.toUpperCase())) {
                        elem.redacted = true
                    }
                })
            }
        }
        return 1
    }
    sortElementsByPage = (elements) => {
        const response = []
        const { totalPages } = this.state
        for (let i=1; i<=totalPages; i++) {
            if (Object.keys(elements).length) {
                Object.keys(elements).forEach((index) => {
                    const elementsPage = Number(elements[index].getAttribute('data-page-nr'))
                    if (elementsPage === i) {
                        response.push(elements[index])
                    }
                })
            }
        }
        return response
    }

    sortElementsByPageMixed = (allWordsRedacted) => {
        const response = []
        const { totalPages } = this.state
        //console.log('totalPages pppppppppppppppppppppp', totalPages)
        for (let i=1; i<=totalPages; i++) {
            if (allWordsRedacted.length) {
                allWordsRedacted.forEach((element) => {
                    const elementsPage = Number(element.pageNumber)
                    //console.log('elementsPage pppppppppppppppppppppp', elementsPage)
                    if (elementsPage === i) {
                        response.push(element)
                    }
                })
            }
        }
        //console.log('response pppppppppppppppppppppp', response)
        return response
    }

    callQueryStatusForBusiness = async(classNameList, source) => {
        const { alreadyRedactedKeys, redactingMode } = this.state
        classNameList.forEach((name) => {
            const parentWr = document.querySelector('div.canvas-page-1')
            let elements = document.getElementsByClassName(name)
            elements = this.sortElementsByPage(elements)
            Object.keys(elements).forEach((index) => {
                const temp = elements[index].className.split(' ').slice(-1)
                const elRect = elements[index].getBoundingClientRect()
                if (temp.length > 0) {
                    const elementKey = temp[0]
                    if (!alreadyRedactedKeys[source]) {
                        alreadyRedactedKeys[source] = []
                    }
                    if (!alreadyRedactedKeys[source].includes(elementKey) || true) {
                        const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                        alreadyRedactedKeys[source].push(elementKey)
                        setTimeout(() => {
                            this.addSearchedRectangle(elements[index], parentWr, colorForRectangles, elRect)
                        }, 500)

                    }
                }
            })
        })
        this.toggleLoadingProcess(false)
    }

    duplicateInCanvas = (el, left, top) => {
        const { canvasData } = this.state
        let response = false
        this.setState({

        }, () => {
            Object.keys(canvasData).forEach(function(key) {
                if (canvasData[key] && canvasData[key].length) {
                    canvasData[key].forEach((item) => {
                        if (item) {
                            let itemDecoded = item
                            if (typeof item === 'string') {
                                itemDecoded = JSON.parse(item)
                            }
                            if (Math.round(itemDecoded.x1) === Math.round(left) && Math.round(itemDecoded.y2) === Math.round(top)) {
                                response = true
                            }
                        }
                    })
                }
            })
            return response
        })
    }
    getStatusElementRedaction = (status) => {
        // this.setState({
        //   statusElement: status
        // })
        return status
    }
    addSearchedRectangle = (el, parentWr, color= false, unCheckedKeys = [], elRect, indexEl, arrayQuery, uniqueElementKeyList, ell) => {
        // while(this.isRunning) {
        //   await new Promise(r => setTimeout(r, 100))
        // }
        // this.isRunning = true
        const { canvasCleaned, alreadyRedactedList, redactingMode, totalPages } = this.state
        this.setState({
            checkStartRedaction: true
        })
        this.onCallToDisableScroll()
        if (el) {
            const temp = el.className.split(' ').slice(-1)
            if (temp.length) {
                const elementKey = temp[0]
                const elementsPage = Number(el.getAttribute('data-page-nr'))
                const elementsText = el.innerText
                if (elementsPage === this.state.pageNumber) {
                    //if (checkStartRedaction !== false) {
                    //   this.setState({
                    //     checkStartRedaction: false
                    //   })
                    //}
                }
                if (elementsPage !== this.state.pageNumber) {
                    //if (checkStartRedaction !== false) {
                    //   this.setState({
                    //     checkStartRedaction: true
                    //   })
                    //}
                    this.onSearchChangePage(el, indexEl, arrayQuery, uniqueElementKeyList).then((response) => {
                        // this.setState({
                        //   checkStartRedaction: false
                        // })
                        // if(unCheckedKeys.length > 0) {
                        //   let elementRemoved = false
                        //   const history = this._sketch._fc._objects
                        //   for (let i = 0; i < history.length; i++) {
                        //     if(unCheckedKeys.includes(history[i].annotation)) {
                        //       this._sketch._fc.remove(history[i])
                        //       elementRemoved = true
                        //     }
                        //   }
                        //   if(elementRemoved) {
                        //     this._sketch._fc.renderAll()
                        //   }
                        // }

                        const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
                        const selectionPosition = elRect //el.getBoundingClientRect()
                        const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
                        const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3

                        if (!canvasCleaned) {
                            if (!this.duplicateInCanvas(el, left, top)) {
                                const rect = new fabric.Rect({
                                    left: left,
                                    top: top,
                                    width: selectionPosition.width,
                                    height: selectionPosition.height + 3,
                                    fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                    // opacity: 0.4,
                                    // opacityType: 0.4,
                                    angle: 0,
                                    padding: 0,
                                    selectable: true,
                                    text: elementsText ? elementsText : '',
                                    annotation: elementKey
                                })
                                this._sketch._fc.add(rect)
                                this._sketch._fc.renderAll()
                            }
                        } else if (!alreadyRedactedList.includes(elementKey)) {
                            alreadyRedactedList.push(elementKey)
                            const rect = new fabric.Rect({
                                left: left,
                                top: top,
                                width: selectionPosition.width,
                                height: selectionPosition.height + 3,
                                fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                // opacity: 0.4,
                                // opacityType: 0.4,
                                angle: 0,
                                padding: 0,
                                selectable: true,
                                text: elementsText ? elementsText : '',
                                annotation: elementKey
                            })
                            this._sketch._fc.add(rect)
                            this._sketch._fc.renderAll()
                        }
                        this.toggleLoadingProcess(false)
                        // if(typeof spanCoords[elementKey] === 'undefined') {
                        //   spanCoords[elementKey] = elRect
                        //   this.setState({ spanCoords })
                        // }
                    })
                } else if ((indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) || (indexEl === 0 &&  arrayQuery.length === 1)) {
                    this.onSearchChangePage(el, indexEl, arrayQuery, uniqueElementKeyList).then((response) => {
                        const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
                        const selectionPosition = elRect //el.getBoundingClientRect()
                        const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
                        const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3

                        if (!canvasCleaned) {
                            if (!this.duplicateInCanvas(el, left, top)) {
                                const rect = new fabric.Rect({
                                    left: left,
                                    top: top,
                                    width: selectionPosition.width,
                                    height: selectionPosition.height + 3,
                                    fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                    // opacity: 0.4,
                                    // opacityType: 0.4,
                                    angle: 0,
                                    padding: 0,
                                    selectable: true,
                                    text: elementsText ? elementsText : '',
                                    annotation: elementKey
                                })
                                this._sketch._fc.add(rect)
                                this._sketch._fc.renderAll()
                            }
                        } else if (!alreadyRedactedList.includes(elementKey)) {
                            alreadyRedactedList.push(elementKey)
                            const rect = new fabric.Rect({
                                left: left,
                                top: top,
                                width: selectionPosition.width,
                                height: selectionPosition.height + 3,
                                fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                // opacity: 0.4,
                                // opacityType: 0.4,
                                angle: 0,
                                padding: 0,
                                selectable: true,
                                text: elementsText ? elementsText : '',
                                annotation: elementKey
                            })
                            this._sketch._fc.add(rect)
                            this._sketch._fc.renderAll()
                        }
                        this.toggleLoadingProcess(false)
                    })
                } else {
                    // const points = this.getRectanglesForPreviewPage()
                    // this.updateRectangles(points)

                    // if(unCheckedKeys.length > 0) {
                    //   const history = this._sketch._fc._objects
                    //   let elementRemoved = false
                    //   for (let i = 0; i < history.length; i++) {
                    //     if(unCheckedKeys.includes(history[i].annotation)) {
                    //       this._sketch._fc.remove(history[i])
                    //       elementRemoved = true
                    //     }
                    //   }
                    //   if(elementRemoved) {
                    //     this._sketch._fc.renderAll()
                    //   }
                    // }
                    const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
                    const selectionPosition = elRect //el.getBoundingClientRect()
                    const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
                    const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3
                    if (!canvasCleaned) {
                        if (!this.duplicateInCanvas(el, left, top)) {
                            const rect = new fabric.Rect({
                                left: left,
                                top: top,
                                width: selectionPosition.width,
                                height: selectionPosition.height + 3,
                                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                // opacity: 0.4,
                                // opacityType: 0.4,
                                angle: 0,
                                padding: 0,
                                selectable: true,
                                text: elementsText ? elementsText : '',
                                annotation: elementKey
                            })
                            this._sketch._fc.add(rect)
                            this._sketch._fc.renderAll()
                            if (elementsPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' || this.getStatusElementRedaction() === 'elementDict' || this.getStatusElementRedaction() === 'elementRegEx' ||
                                this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                                setTimeout(() => {
                                    this.setState({
                                        checkStartRedaction: false
                                    })
                                    this.onRemoveEventDisableScroll()
                                }, 5000)
                            }
                        }
                    } else if (!alreadyRedactedList.includes(elementKey)) {
                        alreadyRedactedList.push(elementKey)
                        const rect = new fabric.Rect({
                            left: left,
                            top: top,
                            width: selectionPosition.width,
                            height: selectionPosition.height + 3,
                            fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                            // opacity: 0.4,
                            // opacityType: 0.4,
                            angle: 0,
                            padding: 0,
                            selectable: true,
                            text: elementsText ? elementsText : '',
                            annotation: elementKey
                        })
                        this._sketch._fc.add(rect)
                        this._sketch._fc.renderAll()
                    }
                    if (elementsPage === totalPages) {
                        this.toggleLoadingProcess(false)
                        // this.setState({
                        //   checkStartRedaction: false
                        // })
                        // this.onRemoveEventDisableScroll()
                        setTimeout(() => {
                            if (elementsPage === totalPages) {
                                this.setState({
                                    checkStartRedaction: false
                                })
                                this.onRemoveEventDisableScroll()
                            }
                        }, 5000)
                    }
                    // if(typeof spanCoords[elementKey] === 'undefined') {
                    //   spanCoords[elementKey] = elRect
                    //   this.setState({ spanCoords })
                    // }
                }
            }
        }
        // this.isRunning = false
        return 1
    }

    /**
     * This method is for searching functionality, it removes all classes related to previous search
     * */
    async clearPreviousSearchedData() {
        this.setState({
            matchedText: [],
            searchIndex: 0,
            searchPage: 1,
            currentMarkedIndex: 1,
            //treeDataSearchHistory: [],
            valueSelectNavigation: 'ai-results',
            searchValue: ''
        }, () => {
            // const el = document.querySelector('span#matched-word--current')
            // if (el) {
            //   el.removeAttribute('id')
            //   const text = el.textContent
            //   el.parentNode.replaceChild(document.createTextNode(text), el)
            // }
            // const elements = document.querySelectorAll('span.matched-word')
            // if (elements) {
            //   for (let i = 0; i < elements.length; i++) {
            //     if (elements[i].classList) {
            //       elements[i].classList.remove('matched-word')
            //       const text = elements[i].textContent
            //       elements[i].parentNode.replaceChild(document.createTextNode(text), elements[i])
            //     }
            //   }
            // }
            // return
        })
        this.changeNavigation('find-asset')
    }

    changeNavigation = (type) => {
        let { pageSidebarVisible, pageSidebarVisibleBefore, navigationType, annotationListVisible } = this.state
       // console.log('type PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP', type)
        if (type === 'redacting') {
            this.changePage(this.state.pageNumber, '', 'pageSidebar')
            this.setState({
                renderCommentsText: false
            })
        }
        if (type === 'comments') {
            this.changePage(this.state.pageNumber, '', 'pageSidebar')
        }
        if (type === 'search-history') {
            this.getSearchHistoryList()
        }
        if (type === 'pages') {
            pageSidebarVisible = !this.state.pageSidebarVisible
            pageSidebarVisibleBefore = pageSidebarVisible
            if (pageSidebarVisible) {
                annotationListVisible = false
            }
            this.setState({
                renderCommentsText: false
            })
        } else {
            navigationType= type
        }
        this.setState({
            navigationType: navigationType,
            pageSidebarVisible,
            pageSidebarVisibleBefore,
            annotationListVisible
        }, () => {
            if (type === 'pages') {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                })
                canvas.renderAll()
            }
        })
    }

    next() {
        this.slider.slickNext()
    }

    previous() {
        this.slider.slickPrev()
    }

    setCanvasButtonsClasses = () => {
        const { selectedTool, btnClasses, selectionMode } = this.state
        if (selectionMode === false) {
            btnClasses.draw.disabled = true
            btnClasses.select.disabled = true
            btnClasses.draw.class = btnClasses.draw.class.replace(new RegExp('btn-action--border', 'g'), '')
            btnClasses.select.class = btnClasses.select.class.replace(new RegExp('btn-action--border', 'g'), '')
            if (btnClasses.draw.class.indexOf('disabled') === -1) {
                btnClasses.draw.class += ' disabled'
            }
            if (btnClasses.select.class.indexOf('disabled') === -1) {
                btnClasses.select.class += ' disabled'
            }
        } else {
            btnClasses.draw.disabled = false
            btnClasses.select.disabled = false
            btnClasses.draw.class = btnClasses.draw.class.replace(new RegExp('disabled', 'g'), '')
            btnClasses.select.class = btnClasses.select.class.replace(new RegExp('disabled', 'g'), '')
            if (selectedTool === 'draw') {
                if (btnClasses.draw.class.indexOf('btn-action--border') === -1) {
                    btnClasses.draw.class += ' btn-action--border'
                }
                btnClasses.select.class = btnClasses.select.class.replace(new RegExp('btn-action--border', 'g'), '')
            }
            if (selectedTool === 'select') {
                if (btnClasses.select.class.indexOf('btn-action--border') === -1) {
                    btnClasses.select.class += ' btn-action--border'
                }
                btnClasses.draw.class = btnClasses.draw.class.replace(new RegExp('btn-action--border', 'g'), '')
            }
        }
        if (this._sketch) {
            const nrOfCanvasObjects = this.state.canvasData[this.state.pageNumber] ? this.state.canvasData[this.state.pageNumber].length : 0
            if (nrOfCanvasObjects === 0 && this._sketch._fc._objects.length === 0) {
                btnClasses.undo.disabled = true
                btnClasses.clear.disabled = true
                btnClasses.preview.disabled = true
                btnClasses.undo.class = btnClasses.undo.class.replace(new RegExp('btn-action--border', 'g'), '')
                btnClasses.clear.class = btnClasses.clear.class.replace(new RegExp('btn-action--border', 'g'), '')
                btnClasses.preview.class = btnClasses.preview.class.replace(new RegExp('btn-action--border', 'g'), '')
                if (btnClasses.undo.class.indexOf('disabled') === -1) {
                    btnClasses.undo.class += ' disabled'
                }
                if (btnClasses.clear.class.indexOf('disabled') === -1) {
                    btnClasses.clear.class += ' disabled'
                }
                if (btnClasses.preview.class.indexOf('disabled') === -1) {
                    btnClasses.preview.class += ' disabled'
                }
            } else {
                btnClasses.undo.disabled = this._sketch._fc._objects.length === 0
                btnClasses.clear.disabled = false
                btnClasses.preview.disabled = false
                btnClasses.undo.class = this._sketch._fc._objects.length > 0
                    ? btnClasses.undo.class.replace(new RegExp('disabled', 'g'), '')
                    : btnClasses.undo.class.replace(new RegExp('btn-action--border', 'g'), '')
                btnClasses.clear.class = btnClasses.clear.class.replace(new RegExp('disabled', 'g'), '')
                btnClasses.preview.class = btnClasses.preview.class.replace(new RegExp('disabled', 'g'), '')
            }
        }
        return btnClasses
    }

    onScanAnnotateClick(value, index, color) {
        this.setState({
            searchValue: value,
            renderSearch: true,
            displaySearchButtons: true,
            activeAnnotation: index,
            disableClickOnPage: false
        })

        const allElements = document.querySelectorAll('.annotation-page__element')
        allElements.forEach((el) => {
            el.classList.remove('annotation-page__element--active')
            el.style.color = 'inherit'
        })
        index.classList.add('annotation-page__element--active')
        index.style.color = color
        //console.log('VVVVVVVVVVVVVVVVVVVVVVV 1111111111111111111')
        this.searchTextInDoc(value, color)
    }

    setQueryStatus = (docAnnotationCategories) => {
        this.setState({ docAnnotationCategories })
    }

    scrollToMatchedWord() {
        const elementToScrollTO = document.querySelector('#matched-word--current')
        if (elementToScrollTO) {
            elementToScrollTO.scrollIntoView(false)
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.addAnnotation()
            this.hideCanvasModals()
        }
    }
    handleKeyDownMixed = (e) => {
        if (e.key === 'Enter') {
            this.addAnnotation()
            this.hideCanvasModals()
        }
    }
    showAnnotationsList = () => {
        const { pageSidebarVisible } = this.state
        const pageSidebarVisibleBefore = pageSidebarVisible
        this.setState({
            annotationListVisible: true,
            pageSidebarVisible: false,
            pageSidebarVisibleBefore
        })
    }

    hideAnnotationsList = () => {
        let { pageSidebarVisibleBefore, pageSidebarVisible } = this.state
        if (pageSidebarVisibleBefore) {
            pageSidebarVisible = true
        }
        this.setState({
            annotationListVisible: false,
            pageSidebarVisible
        })
    }

    highlightRectangle = (convertedAnnotation, annotation, page) => {
        this.setState({
            btnAnnotationActiveIndex: annotation
        }, () => this.changePageRedacted(Number(page), convertedAnnotation))
    }

    renderSearch = () => {
        this.setState({
            renderSearch: !this.state.renderSearch,
            displaySearchButtons: !this.state.displaySearchButtons
        }, () => {
            if (!this.state.renderSearch) {
                this.setState({
                    disableClickOnPage: true
                })
            } else {
                this.setState({
                    disableClickOnPage: false
                })
            }
        })
    }

    async closeSearch() {
        if (this.state.matchedText.length > 0) {
            await this.clearPreviousSearchedData()
        }
        this.setState({
            renderSearch: false,
            displaySearchButtons: false,
            searchValue: ''
        }, () => {
            // this.setState({
            //   pdfBytes: this.props.pdfBytes,
            // })
        })
    }

    onSearchChangeValue = (e) => {
        // if (e.target.value) {
        this.setState({
            searchValue: e.target.value
        })
        //}
    }

    annotationRedactionView() {
        this.setState({
            viewAnnotationsRedaction: !this.state.viewAnnotationsRedaction
        }, () => {
            if (!this.state.viewAnnotationsRedaction) {
                this.setState({
                    disableClickOnPage: true
                })
            } else {
                this.setState({
                    disableClickOnPage: false
                })
            }
        })
    }

    addAnnotationsRedaction = () => {
        this.annotationRedactionView()
    }

    toggleLoadingProcess = (open, source= 'finalise') => {
        let { loadingProcessMessage } = this.state
        if (open) {
            if (source === 'redact') {
                loadingProcessMessage = 'Please wait until redacted process is finished.'
            } else {
                loadingProcessMessage = 'Please wait until finalise process is finished.'
            }
        }
        this.setState({ loadingProcess: open, loadingProcessMessage })
    }
    disabledAnswerToogle(value) {
        return true
    }
    chooseMethodRedacting(value) {
        const allPagesCanvas = document.getElementsByClassName('canvas-area')
        for (const page of allPagesCanvas) {
            if (page) {
                switch (value) {
                    case 'search':
                        //this.renderSearch(value)
                        this.setState({
                            renderSelectDraw: false,
                            renderSelectText: false,
                            renderCommentsText: false,
                            viewAnnotationsRedaction: false,
                            renderSelectTool: false,
                            tool: Tools.Select,
                            selectedTool: 'select',
                            renderSearch: !this.state.renderSearch,
                            displaySearchButtons: !this.state.displaySearchButtons
                        })
                        if (!this.state.renderSearch) {
                            this.setState({
                                disableClickOnPage: false
                            })
                        } else {
                            this.setState({
                                disableClickOnPage: true
                            })
                        }
                        break
                    case 'draw':
                        this.selectRectangle()
                        // this.setState({
                        //   renderSearch: false,
                        //   renderSelectText: false,
                        //   viewAnnotationsRedaction: false,
                        //   renderSelectTool: false,
                        //   // renderSelectDraw: !this.state.renderSelectDraw,
                        //   renderSelectDraw: false,
                        //   displaySearchButtons: false
                        // }, () => { this.selectRectangle() })
                        page.setAttribute('style', 'z-index: 1;')
                        break
                    case 'selectText':
                        this.selectText()
                        this.setState({
                            // renderSelectDraw: false,
                            // renderSelectText: false,
                            // renderSearch: false,
                            // renderSelectTool: false,
                            // viewAnnotationsRedaction: false,
                            // displaySearchButtons: false
                            stateSidebarPages: true
                        }, () => { })
                        break
                    case 'comments':
                        this.selectComments()
                        this.setState({
                            stateSidebarPages: true
                        }, () => { })
                        break
                    case 'annotation':
                        //this.addAnnotationsRedaction()
                        this.setState({
                            renderSelectDraw: false,
                            renderSearch: false,
                            renderSelectText: false,
                            renderCommentsText: false,
                            renderSelectTool: false,
                            tool: Tools.Select,
                            selectedTool: 'select',
                            displaySearchButtons: false,
                            viewAnnotationsRedaction: !this.state.viewAnnotationsRedaction
                        })
                        if (!this.state.viewAnnotationsRedaction) {
                            this.setState({
                                disableClickOnPage: false
                            })
                        } else {
                            this.setState({
                                disableClickOnPage: true
                            })
                        }
                        page.setAttribute('style', 'z-index: 1;')
                        break
                    case 'selectTool':
                        //this.selectTool()
                        this.setState({
                            renderSelectDraw: false,
                            renderSearch: false,
                            renderSelectText: false,
                            renderCommentsText: false,
                            displaySearchButtons: false,
                            renderSelectTool: !this.state.renderSelectTool,
                            renderSelectToolMove: true
                        })
                        const allPagesCanvas = document.getElementsByClassName('canvas-area')
                        if (!this.state.renderSelectTool) {
                            this.setState({
                                disableClickOnPage: false,
                                removeSelectedBtnDisabled: false,
                                tool: Tools.Select,
                                selectedTool: 'select',
                            }, () => {
                                for (const page of allPagesCanvas) {
                                    if (page) {
                                        page.setAttribute('style', 'z-index: 1;')
                                    }
                                }
                            })
                        } else {
                            this.setState({
                                tool: Tools.Select,
                                selectedTool: 'select',
                                disableClickOnPage: true,
                                removeSelectedBtnDisabled: true
                            }, () => {
                                for (const page of allPagesCanvas) {
                                    if (page) {
                                        page.setAttribute('style', 'z-index: 1;')
                                    }
                                }
                            })
                        }
                        break
                    default:
                        return false
                }
            }
        }
    }

    inSelectOption = (children) => {
        const { annotationText } = this.state
        let response = false
        if (children && children.length) {
            children.forEach((item) => {
                if (item.props) {
                    if (item.props.children && item.props.children) {
                        item.props.children.forEach((value) => {
                            if (value.key === annotationText) {
                                response = true
                            }
                        })
                    }
                }
            })
        }
        return response
    }
    updateRenderAnnotationsList = (children) => {
        const { annotationText, viewAnnotationsRedaction, annotationListVisible } = this.state
        if (viewAnnotationsRedaction) {
            return (
                <div className="annotations">
                    { annotationListVisible &&
                    <Drawer
                        getContainer={ this.state.landscape ? 'div.redacted-page-landscape' : 'div.redacted-page-portrait' }
                        placement="left"
                        closable={ false }
                        onClose={ this.hideAnnotationsList }
                        destroyOnClose={ true }
                        visible={ annotationListVisible }
                        mask={ false }
                        maskClosable={ true }
                        className="update-annotation-sidebar"
                        maskStyle={ { 'opacity': 0 } }
                    >
                        <div className="content-annotation-sidebar">
                            <div className="header-annotation-sidebar">
                                <Select
                                    placeholder="Annotate selected"
                                    className='custom-status-annotate'
                                    defaultValue="annotate-selected"
                                    dropdownClassName="new-dropdown-status-annotate"
                                >
                                    <Option className="options-annotate" key={1} value="annotate-selected">
                                        Annotate selected
                                    </Option>
                                    <Option className="options-annotate" key={2} value="annotate-all-redacted">
                                        Annotate all redacted
                                    </Option>
                                </Select>
                                <span onClick={this.hideAnnotationsList} className="icon-v17-close-black"></span>
                            </div>
                            <div className="body-annotation-sidebar">
                                <Search type={ 'text' } className="annotation-sidebar-search"
                                        value={ annotationText }
                                        onChange={ (e) => this.changeAnnotationTextMixed(e, 'new') }
                                        onBlur={ () => this.addAnnotation() }
                                        placeholder="Annotation"
                                        onKeyDown={ (e) => this.handleKeyDownMixed(e) }
                                />
                                <span className="spacing-select">or</span>
                                <Select
                                    value = { this.inSelectOption(children) ? annotationText : 'Custom list' }
                                    mode="single"
                                    showSearch={ true }
                                    style={ { width: '100%' } }
                                    autoFocus={ false }
                                    autoClearSearchValue={ false }
                                    notFoundContent=''
                                    className="select-custom-list"
                                    placeholder="Custom list"
                                    defaultValue="Custom list"
                                    onSelect={ (e) => this.annotationHandleChangeMixed(e, 'add', 'select') }
                                    dropdownClassName="dropdown-custom-list"
                                >
                                    { children }
                                </Select>
                                <Button onClick={ this.onClickAnnotateUpdate } disabled={ annotationText === '' }
                                    //onClick={ () => this.cavnasModalRemove('annotation') }
                                >
                                    Annotate
                                </Button>
                            </div>
                        </div>
                    </Drawer>
                    }
                </div>
            )
        }
    }
    onClickAnnotateUpdate = () => {
        const { annotationText } = this.state
        this.setState({
            statusAnnotationSidebar: 'annotationSidebar'
        })
        //this.updateCanvasText([], annotationText, 'rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)')
        this.updateCanvasAnnotateTextMixed([], annotationText, 'rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)')
    }
    renderAnnotationsList = (children) => {
        const { annotationText, objectHasAnnotation, viewAnnotationsRedaction, annotationListVisible } = this.state
        if (viewAnnotationsRedaction) {
            return (
                <div className="annotations">
                    { annotationListVisible &&
                    <Drawer
                        getContainer={ this.state.landscape ? 'div.redacted-page-landscape' : 'div.redacted-page-portrait' }
                        placement="right"
                        closable={ true }
                        onClose={ this.hideAnnotationsList }
                        destroyOnClose={ true }
                        visible={ annotationListVisible }
                        mask={ false }
                        maskClosable={ true }
                        className="annotation-sidebar"
                        maskStyle={ { 'opacity': 0 } }
                    >
                        <p>Add annotation text:</p>
                        <input type={ 'text' } className="annotation-sidebar__text"
                               value={ annotationText }
                               onChange={ (e) => this.changeAnnotationText(e, 'new') }
                               onBlur={ () => this.addAnnotation() }
                               onKeyDown={ (e) => this.handleKeyDown(e) }
                        />
                        <p>Add annotation from list </p>
                        <Select
                            value = { this.inSelectOption(children) ? annotationText : '' }
                            mode="single"
                            showSearch={ true }
                            style={ { width: '100%' } }
                            autoFocus={ false }
                            autoClearSearchValue={ false }
                            notFoundContent=''
                            onSelect={ (e) => this.annotationHandleChange(e, 'add', 'select') }
                        >
                            { children }
                        </Select>
                        { objectHasAnnotation &&
                        <div className="delete_annotation_wrapper text-center">
                            <button className="button-small-red-empty delete_annotation-extra" onClick={ () => this.cavnasModalRemove('annotation') }>
                                Delete Annotation
                            </button>
                        </div>
                        }
                    </Drawer>
                    }
                </div>
            )
        }
    }

    generateNavigationScroll = () => {
        if (this.state.navigationType === 'redacting' || this.state.navigationType === 'find-asset') {
            return 'auto'
        }
        return 'unset'
    }

    generateGridColumnProperties = () => {
        if (this.state.navigationType === 'redacting' || this.state.navigationType === 'find-asset' || this.state.navigationType === 'metadata' || this.state.navigationType === 'image' || this.state.navigationType === 'spell_checker' || this.state.navigationType === 'search-history') {
            //return '3% 18% 2% 64% 2% 0%'
            //return '0% 18% 5% 68% 2% 0%'
            return '20% 73% 7%'
        } else if (this.state.navigationType === 'pages') {
            //return '0% 0% 5% 72% 2% 10%'
            return '20% 73% 7%'
        }
        //return '3% 10% 2% 72% 2% 0%'
        //return '0% 10% 5% 76% 2% 0%'
        return '20% 73% 7%'

    }

    openDocumentNote = () => {
        this.setState({
            documentNoteVisible: true
        })
    }

    closeDocumentNote = () => {
        this.setState({
            documentNoteVisible: false
        })
    }
    addNotifyDocumentNote = (note) => {
        this.setState({
            notifyNote: note
        })
    }

    generateSVGColor = (navigationType, target) => {
        if (navigationType === target) {
            return '#00D3D8'
        }
        return '#6e7882'
    }

    generateHtmlForAnnotationPreview(canvasData) {
        const result = []
        let i = 1
        Object.keys(canvasData).map((page) => {
            const pageHtml = []
            if (canvasData[page].length) {
                pageHtml.push(
                    <RedactedSidebar
                        canvasData= { canvasData }
                        index= { i }
                        key= { i }
                        setPage= { this.setPage }
                        highlightRectangle={ this.highlightRectangle }
                        savePointsOnPage={ this.savePointsOnPage }
                        page = { page }
                        loadPoints = { this.loadPoints }
                        editLabel = { this.editLabel }
                        annotationSelectedItem = { this.state.annotationSelectedItem }
                        setAnnotationSelectedItem = { this.setAnnotationSelectedItem }
                        endPointXDraw={ this.state.endPointXDraw }
                        endPointYDraw={ this.state.endPointYDraw }
                        documentId={ this.props.documentId }
                    />
                )
                result.push(pageHtml)
                i++
            }
            return true
        })

        return result
    }

    generateCommentsPreview(canvasDataComments) {
        const result = []
        let i = 1
        Object.keys(canvasDataComments).map((page) => {
            const pageHtml = []
            if (canvasDataComments[page].length) {
                pageHtml.push(
                    <CommentsSidebar
                        canvasDataComments= { canvasDataComments }
                        canvasData= { this.state.canvasData }
                        index= { i }
                        key= { i }
                        setPage= { this.setPage }
                        highlightRectangle={ this.highlightRectangle }
                        savePointsOnPage={ this.savePointsOnPage }
                        page = { page }
                        loadPoints = { this.loadPoints }
                        editLabel = { this.editLabel }
                        annotationSelectedItem = { this.state.annotationSelectedItem }
                        setAnnotationSelectedItem = { this.setAnnotationSelectedItem }
                        endPointXDraw={ this.state.endPointXDraw }
                        endPointYDraw={ this.state.endPointYDraw }
                        documentId={ this.props.documentId }
                    />
                )
                result.push(pageHtml)
                i++
            }
            return true
        })

        return result
    }

    collapseAnnotateSelectModal = (e, action= true) => {
        e.stopPropagation()
        this.setState({ annotateSelectedModalVisible: action })
    }

    renderAnnotateSelected = () => {
        const { numberOfAnnotateSelected, colorSelected, annotationList, annotateLabel } = this.state
        let { annotateSelectBtnDisabled } = this.state
        const { Option } = Select
        if (numberOfAnnotateSelected && annotateLabel) {
            annotateSelectBtnDisabled = false
        }
        return (
            <div className="annotate" tabIndex="-1" onKeyDown={ (e) => this.pressEscapeKey(e) }>
                <div className="annotate__header">
                    <span className="annotate__header__title">ANNOTTE SELECTED REDACTION</span>
                    <span className="annotate__header__close" onClick={ (e) => this.collapseAnnotateSelectModal(e, false) }>X</span>
                </div>
                <div className="annotate__body">
                    <div className="annotate__body__line">
                        <div className="input-space" onChange={ (e) => this.changeAnnotateLabel(e) }>
                            <input type="text"/>
                        </div>
                        <div className="button-space">
                            <div className="button-space__circle" style={{ backgroundColor: colorSelected.input }}/>
                            <div className="icon-colourpalette-b" onClick={ () => this.toggleSimpleCustomColorPicker('input')}/>
                        </div>
                    </div>
                    <div className="annotate__body__line">
                        <div className="select-space">
                            <Select className="select-space__select" defaultValue={annotationList[0].exemptionCode} onChange={ (e) => this.changeAnnotationSelected() }>
                                {
                                    annotationList.map((item) => {
                                        return (<Option key={item.key} value={item.exemptionCode}>{item.exemptionCode}</Option>)
                                    })
                                }
                            </Select>
                        </div>
                        <div className="button-space">
                            <div className="button-space__circle" style={{ backgroundColor: colorSelected.select }}/>
                            <div className="icon-colourpalette-b" onClick={ () => this.toggleSimpleCustomColorPicker('select') }/>
                        </div>
                    </div>
                </div>
                <div className="annotate__footer">
                    <button className="finalize-button button-green annotate__footer__btn" disabled={annotateSelectBtnDisabled}>ANNOTATE</button>
                    <p className="annotate__footer__number">{ numberOfAnnotateSelected } names selected</p>
                </div>
                { this.renderSimpleCustomColorPicker() }
            </div>
        )
    }

    hideLoadingBeforeMark = () => {
        this.setState({ loadingBeforeMark: false })
    }
    onDropdownVisibleChange = (e) => {
        this.setState({
            visibleDropdownStatus: e
        })
    }
    updateValueStatus(e) {
        const { smartBoxDocumentPath, isSmartBoxApplication } = this.props
        const { customerStatus } = this.state
        let iconSelect = null
        let colorSelect = null
        const statusSmartView = e
        let tagSelect = {}
        const fileStatusValue = statusSmartView.toLowerCase() === 'for review' ? 'for review' : statusSmartView.toLowerCase()
        customerStatus && customerStatus.length > 0 && customerStatus.forEach((list) => {
            if (fileStatusValue.toLowerCase() === list.tagName.toLowerCase()) {
                iconSelect = list.icon
                colorSelect = list.color
                tagSelect = list
            }
        })
        this.setState({
            selectTag: tagSelect
        })
        const listIds = []
        listIds.push(this.props.documentId)
        const ToDoStatusSmartView = {
            fileStatus: fileStatusValue.toLowerCase(),
            //filePath: smartBoxDocumentPath,
            fileId: listIds,
            color: colorSelect,
            icon: iconSelect,
        }
        if (isSmartBoxApplication) {
            setFileStatusOpenSmartView(ToDoStatusSmartView).then((response) => {
                if (response.status === 200) {
                    this.props.onGetStatusTagsSmartView(tagSelect, listIds)
                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        }
        this.setState({
            valueStatusFile: e
        })
    }

    updateValueClassification(e) {
        const { documentId, isSmartBoxApplication } = this.props
        this.setState({
            valueStatusClassification: e
        })
        const ToDoStatusSmartView = {
            docId: documentId,
            docClassification: e
        }
        if (isSmartBoxApplication) {
            setClassificationStatusOpenSmartView(ToDoStatusSmartView).then((response) => {

            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        }
    }
    onCheckStatusSelectAgainRedacted = (status) => {
        this.setState({
            statusSelectAgainRedacted: status
        })
    }
    onMouseEnter(e, type) {
        switch (type) {
            case 'document-note':
                this.setState({
                    activeDocumentNote: true,
                    activePrintDocument: false
                })
                break
            case 'print-document':
                this.setState({
                    activePrintDocument: true,
                    activeDocumentNote: false
                })
                break
            case 'clear-page':
                this.setState({
                    activeClearPage: true
                })
                break
            case 'clear-selected':
                this.setState({
                    activeClearSelected: true
                })
                break
            case 'search-word':
                this.setState({
                    activeSearchWord: true
                })
                break
            case 'annotation':
                this.setState({
                    activeAnnotation: true
                })
                break
            case 'comments':
                this.setState({
                    activeComments: true
                })
                break
            case 'select-tool':
                this.setState({
                    activeSelectTool: true
                })
                break
            case 'select-draw':
                this.setState({
                    activeSelectDraw: true,
                    statusDrawRedaction: true
                })
                break
            case 'select-text':
                this.setState({
                    activeSelectText: true
                })
                break
            case 'select-undo':
                this.setState({
                    activeUndo: true,
                })
                break
            case 'select-redo':
                this.setState({
                    activeRedo: true,
                })
                break
            case 'preview':
                this.setState({
                    activePreview: true,
                })
                break
            default: break
        }
    }
    onMouseLeave() {
        this.setState({
            activeDocumentNote: false,
            activePrintDocument: false,
            activeClearPage: false,
            activeClearSelected: false,
            activeSearchWord: false,
            activeAnnotation: false,
            activeComments: false,
            activeSelectTool: false,
            activeSelectDraw: false,
            activeSelectText: false,
            activeUndo: false,
            activeRedo: false,
            activePreview: false,
        })
    }
    onVisibleDropdownNavigation = (e) => {
        this.setState({
            visibleDropdownNavigation: e
        })
    }
    changeNavigationBar(e) {
       // console.log('changeNavigationBar FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF', e)
        switch (e) {
            case 'ai-results':
                this.changeNavigation('find-asset')
                break
            case 'search-history':
                this.changeNavigation('search-history')
                //this.getSearchHistoryList()
                break
            // case 'images':
            //   this.changeNavigation('image')
            //       break
            // case 'spell-checker':
            //   this.changeNavigation('spell_checker')
            //   break
            case 'review-by-page':
                this.changeNavigation('redacting')
                break
            case 'comments':
                this.changeNavigation('comments')
                break
            case 'metadata':
                this.changeNavigation('metadata')
                break
            default: break
        }
        this.setState({
            valueSelectNavigation: e
        })
    }
    saveNextPreviousPage = () => {
        const points = this.getRectanglesForPreviewPage()
        //console.log('START SAVE 22222222222222222222222222222222222222222222222222')
        //this.updateRectangles(points)
        this.saveNextPreviousPageMixed()
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.setState({
            haveChanges: true,
            canvasData: initialCanvasData
        })
    }
    saveNextPreviousPageMixed = () => {
        const { canvasDataCopy, deleteSelectedRedactionBox } = this.state
        const listPoints = []
        const listPointsNewAdd = []
        const dtoRedact = []
        const pointsToSave = {}
        const pointsToSaveNewAdd = {}
        const pointsToSave2 = {}
        let pagePointSumDeleted = []
        let rectanglesToRemove = []
        let rectanglesToRemoveMixed2 = []
        let pagePointRectanglesMove = []

        let maxHeight //= 10000
        const elementsMax = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elementsMax.length > 0) {
            maxHeight = elementsMax[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        const points = this.getRectanglesForPreviewPageMixed()
        const uniqueIdPagePointSum = Math.floor(Math.random() * 10000000000).toString()
        points.forEach((element) => {
            let y1 = element.y1 - 1
            if (y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if (y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.typeMixed === 13 ? uniqueIdPagePointSum : element.pagePointSum,
                pagePointSumAnnotate: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true,
                keyUI: element.keyUI,
                category: element.category ? element.category : 'Smartviewer',
                subcategory: element.subcategory ? element.subcategory : 'Freehand',
                color: element.color,
                colorRedaction: element.colorRedaction,
                annotationRedaction: element.annotationRedaction,
            }

            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            if (element.typeMixed && (element.typeMixed === 10 || element.typeMixed === 11 || element.typeMixed === 12 || element.typeMixed === 13)) {
                const stringObjNewAdd = JSON.stringify(obj)
                listPointsNewAdd.push(stringObjNewAdd)
                pointsToSaveNewAdd[this.state.pageNumber] = listPointsNewAdd
            }
            if (element.typeMixed && (element.typeMixed === 12)) {
                pagePointRectanglesMove.push(element.pagePointSum)
            }
        })
        const dataToSaveRedactAll = {
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSaveNewAdd

        }
        dtoRedact.push(dataToSaveRedactAll)
        rectanglesToRemove = this.onRectanglesToRemove(pointsToSave)
        rectanglesToRemoveMixed2 = this.onRectanglesToRemoveMixed2(pointsToSave, pagePointRectanglesMove)
        const saveCanvasSmartviewMixedDTO = {
            idDoc: this.props.documentId,
            keyWordNonOcr: [],
            keyWordNonOcrRegex: [],
            keyWordNonOcrDictionary: [],
            keyWordOcr: [],
            keyWordOcrRegex: [],
            keyWordOcrDictionary: [],
            rectanglesToRemove: rectanglesToRemoveMixed2,
            markedAsAudit: true,
            pageCoordinates: dtoRedact,
            //coordsToDelete: []
        }
        if ((Object.keys(pointsToSaveNewAdd) && Object.keys(pointsToSaveNewAdd).length > 0) || (rectanglesToRemoveMixed2 && rectanglesToRemoveMixed2.length > 0)) {
            apiSmartBoxSaveCanvasDocumentSmartviewMixed2(saveCanvasSmartviewMixedDTO).then((response1) => {
                let pageRectangleMap = this.convertCanvasDataCopyMixed(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
                let pageRectangleMapNew = this.convertCanvasDataCopyMixedNew(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)

                this.setState({
                    canvasData: pageRectangleMap,
                    canvasDataCopy: pageRectangleMapNew,
                    statusSaveSmartviewMixed2: response1.status,
                    deleteSelectedRedactionBox: false,
                    checkStartRedaction: false,
                    annotationText: ''
                })
                this.onRemoveEventDisableScroll()
            })
        }
    }
    onClickForthDocument = () => {
        const { filesTable, documentId, systemPropertyFileStatus } = this.props
        const { disabledNextDocument, setIntervalUpdateLockStatus } = this.state
        cookie.remove('checkLockStatusSmartviewDocument', { path: '/' })
        clearInterval(setIntervalUpdateLockStatus)
        this.setState({setIntervalUpdateLockStatus})
        //this.props.closeRedacting(this.props.pdfBytes)
        //window.URL.revokeObjectURL(this.props.pdfBytes)

        // this.setState({
        //   documentBytes: null
        // }, () => {
        //   window.URL.revokeObjectURL(this.props.pdfBytes)
        // })


        //window.location.reload()

        ///TEST ---- 1111
        // const loadingTask = pdfjs.getDocument('/libs/pdfjs/build/pdf.worker.js')
        // loadingTask.destroy();
        //window.worker

        ///TEST ---- 22222
        //localStorage.clear()

        ///TEST ---- 3333333
        // window.URL.revokeObjectURL(this.props.pdfBytes)
        // delete window.worker
        // const myWorker = new Worker("worker.js");
        //
        // myWorker.terminate();
        //this.destroyed = true;

        ///TEST ---- 4444444
        // const transportDestroyed = !this._transport
        //     ? Promise.resolve()
        //     : this._transport.destroy();
        // return transportDestroyed.then(() => {
        //   this._transport = null;
        //   if (this._worker) {
        //     this._worker.destroy();
        //     this._worker = null;
        //   }
        // });

        // const worker = new Worker('worker.js');
        let nextDocument
        const filesTablePdf = []
        global.finalItems = {}
        global.items = {}
        global.markedTimes = 0
        global.itemsToBeMarked = 0
        global.arEl = []
        global.arOptions = []
        if (disabledNextDocument === false) {
            this.setState({
                disabledNextDocument: true
            }, () => {
                this.props.closeRedactingNext(this.props.pdfBytes)
                this.setState({
                    isDocumentReady: false,
                    pageNumber: 1,
                    isNextDocumentId: {}
                })
                this.saveNextPreviousPage()
                filesTable && filesTable.length > 0 && filesTable.forEach((file) => {
                    if (file.type !== 'DIRECTORY') {
                        filesTablePdf.push(file)
                    }

                })
                for (let i = 0; i < filesTablePdf.length; i++) {
                    if (filesTablePdf[i].id === documentId) {
                        while((i+1 < filesTablePdf.length) && filesTablePdf[i+1].errorMessage != "No error"){
                            i++;
                        }

                        nextDocument = i + 1;
                    }
                }
                if ((nextDocument) === filesTablePdf.length) {
                    // this.setState({
                    //   disabledNextDocument: true
                    // })
                    nextDocument--;
                } else {
                    this.props.handlePreviewDocumentNext(filesTablePdf[nextDocument])
                    this.setState({
                        //disabledNextDocument: false
                    })
                }
                this.setState({
                    isNextDocument: true,
                    isNextDocumentId: filesTablePdf[nextDocument],
                    nextDocumentLength: nextDocument,
                    documentId: filesTablePdf[nextDocument].id,
                    renderSelectDraw: false,
                    renderSearch: false,
                    renderSelectText: false,
                    renderCommentsText: false,
                    displaySearchButtons: false,
                    tool: Tools.Select,
                    selectedTool: 'select',
                })
                this.setState({
                    valueStatusFile: this.props.systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
                    statusNextDocumentBusiness: true,
                    statusNextDocumentRegEx: true,
                    statusNextDocumentStandard: true,
                })
            })
        }
        this.onUnlockSmartviewDocument()
    }

    onDisabledNextDocument = (status) => {
        this.setState({
            disabledNextDocument: status
        })
    }
    onGetStatusNextDocumentBusiness = (status) => {
        this.setState({
            statusNextDocumentBusiness: status
        })
    }

    onGetStatusNextDocumentRegEx = (status) => {
        this.setState({
            statusNextDocumentRegEx: status
        })
    }

    onGetStatusNextDocumentStandard = (status) => {
        this.setState({
            statusNextDocumentStandard: status
        })
    }
    onDisabledForthDocument = () => {
        return true
    }
    onClickBackDocument = () => {
        const { filesTable, documentId, systemPropertyFileStatus } = this.props
        const { disabledBackDocument, setIntervalUpdateLockStatus } = this.state
        cookie.remove('checkLockStatusSmartviewDocument', { path: '/' })
        clearInterval(setIntervalUpdateLockStatus)
        this.setState({setIntervalUpdateLockStatus})
        let backDocument
        const filesTablePdf = []
        this.setState({
            isDocumentReady: false
        })
        global.finalItems = {}
        global.items = {}
        global.markedTimes = 0
        global.itemsToBeMarked = 0
        global.arEl = []
        global.arOptions = []

        if (disabledBackDocument === false) {
            this.setState({
                disabledBackDocument: true
            }, () => {
                this.props.closeRedactingNext(this.props.pdfBytes)
                this.saveNextPreviousPage()
                filesTable && filesTable.length > 0 && filesTable.forEach((file) => {
                    if (file.type !== 'DIRECTORY') {
                        filesTablePdf.push(file)
                    }
                })
                for (let i = filesTablePdf.length - 1; 0 <= i; i--) {
                    if (filesTablePdf[i].id === documentId) {

                        while((i-1 >= 0) && filesTablePdf[i-1].errorMessage != "No error"){
                            i--;
                        }

                        backDocument = i - 1
                    }
                }
                if (backDocument < 0) {
                    backDocument = 0;
                    // this.setState({
                    //   disabledBackDocument: true
                    // })
                } else {
                    this.props.handlePreviewDocumentNext(filesTablePdf[backDocument])
                    // this.setState({
                    //   disabledBackDocument: false
                    // })
                }
                this.setState({
                    isNextDocument: true,
                    isNextDocumentId: filesTablePdf[backDocument],
                    nextDocumentLength: backDocument,
                    documentId: filesTablePdf[backDocument].id,
                    renderSelectDraw: false,
                    renderSearch: false,
                    renderSelectText: false,
                    renderCommentsText: false,
                    displaySearchButtons: false,
                    tool: Tools.Select,
                    selectedTool: 'select',
                })
                this.setState({
                    valueStatusFile: this.props.systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
                    statusNextDocumentBusiness: true,
                    statusNextDocumentRegEx: true,
                    statusNextDocumentStandard: true,
                    pageNumber: 1
                })
            })
        }
        this.onUnlockSmartviewDocument()
    }

    onDisabledBackDocument = (status) => {
        this.setState({
            disabledBackDocument: status
        })
    }
    onCallToDisableScroll = () => {
        let supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () { supportsPassive = true; return true }
            }))
        } catch(e) {}

        const wheelOpt = supportsPassive ? { passive: false } : false
        const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
        window.addEventListener('DOMMouseScroll', this.preventDefaultKeyDown, false)
        window.addEventListener(wheelEvent, this.preventDefaultKeyDown, wheelOpt)
        window.addEventListener('touchmove', this.preventDefaultKeyDown, wheelOpt)
        window.addEventListener('keydown', this.preventDefaultEventScroll, false)
    }
    onRemoveEventDisableScroll = () => {
        let supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () { supportsPassive = true; return true }
            }))
        } catch(e) {}

        const wheelOpt = supportsPassive ? { passive: false } : false
        const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
        window.removeEventListener('DOMMouseScroll', this.preventDefaultKeyDown, false)
        window.removeEventListener(wheelEvent, this.preventDefaultKeyDown, wheelOpt)
        window.removeEventListener('touchmove', this.preventDefaultKeyDown, wheelOpt)
        window.removeEventListener('keydown', this.preventDefaultEventScroll, false)
    }
    preventDefaultEventScroll = (e) => {
        this.preventDefaultKeyDown(e)
        return false
    }
    preventDefaultKeyDown = (e) => {
        e.preventDefault()
    }
    duplicateInCanvasMixed = (el, left, top) => {
        const { canvasData } = this.state
        let response = false
        //console.log('canvasData 5555555555555555555555555555555555', canvasData)
        this.setState({

        }, () => {
            Object.keys(canvasData).forEach(function(key) {
                if (canvasData[key] && canvasData[key].length) {
                    canvasData[key].forEach((item) => {
                        if (item) {
                            let itemDecoded = item
                            if (typeof item === 'string') {
                                itemDecoded = JSON.parse(item)
                            }
                            if (Math.round(itemDecoded.x1) === Math.round(left) && Math.round(itemDecoded.y2) === Math.round(top)) {
                                response = true
                            }
                        }
                    })
                }
            })
            return response
        })
    }
    updateAllRectanglesMixed = (points, indexEl, arrayQuery, objectListRedact, unCheckedKeys) => {
        const { statusAfterRemove, stateSidebarPages, extractPointsToSave, dtoRedactAll } = this.state
       // console.log('objectListRedact FINAL 0000000000000000000000000000000000000', objectListRedact)
        const pointsToSave = {}
        const listPoints = []
        let maxHeight //= 10000
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elements.length > 0) {
            maxHeight = elements[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        points.forEach((element) => {
            let y1 = element.y1 - 1
            if(y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if(y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true,
            }

            this.onAddCategoryAndSubcategoryOnSavedRectangles(obj, element, unCheckedKeys)
            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            this.setState({
                pagePointSumList: element.pagePointSum,
            })
        })
       // console.log('pointsToSave FINALLY 9999999999999999999999999999999999999', pointsToSave)
        const pointsCanvas = []
        let pointsCanvasAll = []
        const numberPage = []
        let numberPageAll = []
        pointsCanvas.push(pointsToSave)
        pointsCanvasAll = this.state.extractPointsToSave.concat(pointsCanvas)
        numberPage.push(this.state.pageNumber)
        numberPageAll = this.state.extractPageNumber.concat(numberPage)
        this.setState({
            extractPointsToSave: pointsCanvasAll,
            extractPageNumber: numberPageAll
        })
        const dtoRedact = []
        let redactAllDto = []
        const dataGetPoints = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
        }

        const dataToSaveRedactAll = {
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSave

        }
        dtoRedact.push(dataToSaveRedactAll)
        redactAllDto = this.state.dtoRedactAll.concat(dtoRedact)
        this.setState({
            dtoRedactAll: redactAllDto
        }, () => {
            if ((indexEl === arrayQuery.length - 1)) {
                const saveCanvasDocumentSmartViewDTO = {
                    idDoc: this.props.documentId,
                    markedAsAudit: true,
                    pageCoordinates: redactAllDto,
                    keyWordOcr: objectListRedact.selectRedactionStandardOcr ? objectListRedact.selectRedactionStandardOcr : [],
                    keyWordOcrRegex: objectListRedact.selectRedactionRegExOcr ? objectListRedact.selectRedactionRegExOcr : [],
                    keyWordOcrDictionary: objectListRedact.selectRedactionBusinessOcr ? objectListRedact.selectRedactionBusinessOcr : [],

                }
                    if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
                        apiSmartBoxSaveCanvasDocumentSmartviewMixed(saveCanvasDocumentSmartViewDTO).then((response) => {
                            this.setState({
                                statusCloseSmartviewRedactAll: false
                            })
                        })
                    }
            }
        })
    }
    changeAllPageMixed = (element, indexEl, arrayQuery, objectListRedact, unCheckedKeys, elementsPage) => {
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        if (elements) {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === Number(element.pageNumber)) {
                    elements[i].classList.remove('show-pdf-page')
                    elements[i].classList.add('show-pdf-page')
                } else if (elements[i]) {
                    elements[i].classList.remove('show-pdf-page')
                }
            }
        }
        //add active class to pages thumbnails
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === Number(element.pageNumber)) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }
        const points = this.getRectanglesForPreviewPage()
        this.updateAllRectanglesMixed(points, indexEl, arrayQuery, objectListRedact, unCheckedKeys)
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.hideCanvasModals()
       // console.log('initialCanvasData 66666666666666666666666666666666600', initialCanvasData)
      this.setState({
          //pageNumber: Number(element.pageNumber),
          pageNumber: elementsPage,
          canvasData: initialCanvasData,
          forPageNumber: elementsPage,
          haveChanges: false,
      })
    }
    async onAllSearchChangePageMixed(element, indexEl, arrayQuery, objectListRedact, unCheckedKeys, pageNr= 1) {
        const elementsPage = element !== null ? Number(element.el.getAttribute('data-page-nr')) : pageNr
        if (elementsPage !== this.state.pageNumber) {
          //  console.log('44444444444444444444444444444444444444444444')
            this.changeAllPageMixed(element, indexEl, arrayQuery, objectListRedact, unCheckedKeys, elementsPage)
        } else if (indexEl === arrayQuery.length - 1) {
            //last element finally
            this.changeAllPageMixed(element, indexEl, arrayQuery, objectListRedact, unCheckedKeys, elementsPage)
            this.setState({
                statusLastPages: false
            }, () => {
                this.setState({
                    statusLastPages: false,
                    //pageNumber: elementsPage
                })
                //this.changeAllPage(elementsPage)
            })
        }

    }
    addAllSearchedRectangleMixed = (element, parentWr, color= false, elRect, unCheckedKeys, indexEl, arrayQuery, allWordsRedacted, objectListRedact) => {
        const { canvasCleaned, redactingMode, totalPages } = this.state
         const alreadyRedactedList = []
        this.onAllSearchChangePageMixed(element, indexEl, arrayQuery, objectListRedact, unCheckedKeys).then((response) => {
            const parentWr2 = document.querySelector('div.canvas-page-'+this.state.pageNumber)
            const selectionPosition = elRect
            const left = selectionPosition.left - parentWr2.getBoundingClientRect().left + window.scrollX
            const top = selectionPosition.top - parentWr2.getBoundingClientRect().top - 3
            const elementsText = element.el.innerText
            // console.log('alreadyRedactedList 3333333333333333333333', alreadyRedactedList)
            // console.log('element 3333333333333333333333', element)
            if (!canvasCleaned) {
                if (!this.duplicateInCanvasMixed(element, left, top)) {
                   // console.log('33333333333333333333333333333333333333')
                    let rect = new fabric.Rect({
                        left: left,
                        top: top,
                        width: selectionPosition.width,
                        height: selectionPosition.height + 3,
                        fill: element.categoryColor ? element.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                        angle: 0,
                        padding: 0,
                        selectable: true,
                        text: element.title ? element.title : '',
                        annotation: element.key
                    })

                    this._sketch._fc.add(rect)
                    this._sketch._fc.renderAll()
                }
            } else if (!alreadyRedactedList.includes(element.key)) {
                alreadyRedactedList.push(element.key)
                let rect = new fabric.Rect({
                    left: left,
                    top: top,
                    width: selectionPosition.width,
                    height: selectionPosition.height + 3,
                    fill: element.categoryColor ? element.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                    angle: 0,
                    padding: 0,
                    selectable: true,
                    text: element.title ? element.title : '',
                    annotation: element.key ? element.key : '',
                })

                this._sketch._fc.add(rect)
                this._sketch._fc.renderAll()
            }
        })
    }

    changeAllQueryStatusMixed = (parameters, classNameList, classNameListMixed, unCheckedKeys, source, allWordsRedacted, objectListRedact) => {
        const { alreadyRedactedKeys, redactingMode, spanCoords, listCategories } = this.state
        const pageHeight = document.querySelector('#pdf-page').clientHeight
        const parentWr = document.querySelector('div.canvas-page-1')
        let elements = []
        //console.log('START REDACT 222222222222222222222 classNameList', classNameList)
        classNameList.forEach((name, index) => {
            const elementsByClass = document.getElementsByClassName(name)
            Object.keys(elementsByClass).forEach((idx) => {
                elements.push(elementsByClass[idx])
            })
        })
        elements = this.sortElementsByPage(elements)
        //console.log('START REDACT 222222222222222222222 allWordsRedacted', allWordsRedacted)
        //console.log('START REDACT 222222222222222222222 elements', elements)
        allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((elem) => {
            elements && elements.length > 0 && elements.forEach((el) => {
                const temp = el.className.split(' ').slice(-1)
                if (temp.length) {
                    const elementKey = temp[0]
                    if (elem.key === elementKey) {
                        elem.el = el
                    }
                }
            })
        })
        // elements && elements.length > 0 && elements.forEach((el, indexEl, arrayQuery) => {
        //     if (el) {
        //         console.log('el lllllllllllllllllllllllll', el)
        //         console.log('el.innerText lllllllllllllllllllllllll', el.innerText)
        //     }
        // })
        allWordsRedacted = this.sortElementsByPageMixed(allWordsRedacted)
        // console.log('START REDACT 222222222222222222222 ----- 11111111111111 allWordsRedacted', allWordsRedacted)
        // console.log('START REDACT 222222222222222222222 ----- 11111111111111 spanCoords', spanCoords)
        // console.log('START REDACT 222222222222222222222 ----- 11111111111111 alreadyRedactedKeys', alreadyRedactedKeys)
        // console.log('START REDACT 222222222222222222222 ----- 11111111111111 source', alreadyRedactedKeys)
        if (allWordsRedacted && allWordsRedacted.length > 0) {
            allWordsRedacted.forEach((element, indexEl, arrayQuery) => {
                const el = element.el
                //console.log('el innners TEXT', el.innerText)
                  if (element.key) {
                      //console.log('START REDACT 222222222222222222222 element', element)
                      const elementsPage = Number(element.pageNumber)
                      let elRect = typeof spanCoords[element.key] !== 'undefined' ? spanCoords[element.key] : el.getBoundingClientRect()
                      const left = elRect.left
                      const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top + 1
                      const width = elRect.width
                      const height = elRect.height
                      const elRectObj = {left, top, width, height}
                      // console.log('START REDACT 222222222222222222222 elementsPage', elementsPage)
                      // console.log('START REDACT 222222222222222222222 elRect', elRect)
                      //alreadyRedactedKeys.mixed = []
                      //  if (!alreadyRedactedKeys[source]) {
                      //      alreadyRedactedKeys[source] = []
                      //  }
                      if (!alreadyRedactedKeys[source].includes(element.key)) {
                          alreadyRedactedKeys[source].push(element.key)
                         // console.log('START REDACT 222222222222222222222 alreadyRedactedKeys', alreadyRedactedKeys)
                          const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                          setTimeout(() => {
                              this.addAllSearchedRectangleMixed(element, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery, allWordsRedacted, objectListRedact)
                          }, 800)
                      }
                  }
            })
        }
    }
    onGetPagesCountChildrenMixed = (pagesCountChildrenMixed, pagesCountChildrenMixedOcr, pagesCountChildrenMixedAll, getChildrenAllCategories) => {
        this.setState({
            pagesCountChildrenMixed: pagesCountChildrenMixed,
            pagesCountChildrenMixedAll: pagesCountChildrenMixedAll,
            getChildrenAllCategories: getChildrenAllCategories,
        })
    }
    getRectanglesForPreviewPageMixed = () => {
        const points = []
        if (!this.state.renderCommentsText) {
            if (typeof this._sketch !== 'undefined') {
                const history = this._sketch._fc._objects
                for (let i = 0; i < history.length; i++) {
                    //if (history[i].typeMixed && (history[i].typeMixed === 10 || history[i].typeMixed === 11)) {
                    if (history[i].type !== 4) {
                         //console.log('history[i] AAAAAAAAAAAAAAAAAAAAAAAA', history[i])
                        points.push({
                            //idd: history[i].idd,
                            //Dragos: history[i].idd,
                            isOcr: false,
                            x1: history[i].aCoords.bl.x,
                            x2: history[i].aCoords.br.x,
                            y1: history[i].aCoords.bl.y,
                            y2: history[i].aCoords.tr.y,
                            text: history[i].text ? history[i].text : '',
                            annotation: history[i].annotation ? history[i].annotation : (history[i].text) ? history[i].text : '',
                            annotationType: Number(history[i].type),
                            type: Number(history[i].type),
                            pagePointSum: history[i].pagePointSum,
                            points: history[i].points,
                            typeMixed: history[i].typeMixed,
                            pageNumber: history[i].pageNumber,
                            keyUI: history[i].keyUI,
                            category: history[i].category,
                            subcategory: history[i].subcategory,
                            color: history[i].color,
                            colorRedaction: history[i].colorRedaction,
                            annotationRedaction: history[i].annotationRedaction,
                            defaultSmartviewOCR: history[i].defaultSmartviewOCR ? history[i].defaultSmartviewOCR : null,
                            picture: true
                        })
                    }
                    this.setState({
                        pagePointSumList: history[i].pagePointSum,
                    })
                }
            }
        }
        return points
    }

    getRectanglesForPreviewPageMixed2 = () => {
        const points = []
        if (!this.state.renderCommentsText) {
            if (typeof this._sketch !== 'undefined') {
                const history = this._sketch._fc._objects
                for (let i = 0; i < history.length; i++) {
                    if (history[i].type !== 4 || history[i].type !== null) {
                        // console.log('history[i] AAAAAAAAAAAAAAAAAAAAAAAA', history[i])
                        points.push({
                            //idd: history[i].idd,
                            //Dragos: history[i].idd,
                            isOcr: false,
                            x1: history[i].aCoords.bl.x,
                            x2: history[i].aCoords.br.x,
                            y1: history[i].aCoords.bl.y,
                            y2: history[i].aCoords.tr.y,
                            text: history[i].text ? history[i].text : '',
                            annotation: history[i].annotation ? history[i].annotation : (history[i].text) ? history[i].text : '',
                            annotationType: Number(history[i].type),
                            type: Number(history[i].type),
                            pagePointSum: history[i].pagePointSum,
                            points: history[i].points,
                            typeMixed: history[i].typeMixed,
                            pageNumber: history[i].pageNumber,
                            picture: true
                        })
                    }
                    this.setState({
                        pagePointSumList: history[i].pagePointSum,
                    })
                }
            }
        }
        return points
    }

    onGetPageCoordinates = () => {
        const { canvasDataMixedOcr } = this.state
        const listPoints = []
        const listPointsNewAdd = []
        const pointsToSave = {}
        const pointsToSaveNewAdd = {}
        let pagePointSumDeleted = []
        let rectanglesToRemove = []
        let rectanglesToRemoveMixed2 = []
        const pagePointRectanglesMove = []
        const dtoRedact = []
        let maxHeight //= 10000
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elements.length > 0) {
            maxHeight = elements[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        const points = this.getRectanglesForPreviewPageMixed()
        const uniqueIdPagePointSum = Math.floor(Math.random() * 10000000000).toString()
       // console.log('START REDACT 222222222222222222222222222 points', points)
        points.forEach((element) => {
            let y1 = element.y1 - 1
            if(y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if(y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.typeMixed === 13 ? uniqueIdPagePointSum : element.pagePointSum,
                pagePointSumAnnotate: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true,
                keyUI: element.keyUI,
                category: element.category ? element.category : 'Smartviewer',
                subcategory: element.subcategory ? element.subcategory : 'Freehand',
                color: element.color,
                colorRedaction: element.colorRedaction,
                annotationRedaction: element.annotationRedaction,
            }

            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            if (element.typeMixed && (element.typeMixed === 10 || element.typeMixed === 11 || element.typeMixed === 12 || element.typeMixed === 13)) {
                const stringObjNewAdd = JSON.stringify(obj)
                listPointsNewAdd.push(stringObjNewAdd)
                pointsToSaveNewAdd[this.state.pageNumber] = listPointsNewAdd
            }
            if (element.typeMixed && (element.typeMixed === 12)) {
                pagePointRectanglesMove.push(element.pagePointSum)
            }
        })
        const dataToSaveRedactAll = {
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSaveNewAdd

        }
        dtoRedact.push(dataToSaveRedactAll)
        rectanglesToRemove = this.onRectanglesToRemove(pointsToSave)
        rectanglesToRemoveMixed2 = this.onRectanglesToRemoveMixed2(pointsToSave, pagePointRectanglesMove)
        // console.log('START REDACT 222222222222222222222222222 listPoints', listPoints)
        // console.log('START REDACT 222222222222222222222222222 pointsToSave', pointsToSave)
        // console.log('START REDACT 222222222222222222222222222 dtoRedact', dtoRedact)
       // console.log('START REDACT 222222222222222222222222222 canvasDataMixedOcr', canvasDataMixedOcr)
        const dtoRedactGet = {
            dtoRedact: dtoRedact,
            rectanglesToRemove: rectanglesToRemoveMixed2,
        }

        return dtoRedactGet
    }

    onSmartBoxSaveCanvasDocumentSmartviewMixed2 = async (keyWordsDto, unCheckedKeys, source) => {
       const { canvasDataMixedOcr } = this.state
        const pageCoordinates2 = this.onGetPageCoordinates()

       // console.log('START REDACT 222222222222222222222222222 pageCoordinates2', pageCoordinates2)
        let saveCanvasSmartviewMixedDTO = {}
       if (unCheckedKeys && unCheckedKeys.length > 0) {
           saveCanvasSmartviewMixedDTO = {
               idDoc: this.props.documentId,
               keyWordNonOcr: [],
               keyWordNonOcrRegex: [],
               keyWordNonOcrDictionary: [],
               keyWordOcr: [],
               keyWordOcrRegex: [],
               keyWordOcrDictionary: [],
               rectanglesToRemove: keyWordsDto.rectanglesToRemove.concat(pageCoordinates2.rectanglesToRemove),
               markedAsAudit: true,
               pageCoordinates: pageCoordinates2.dtoRedact,
           }
       } else {
           saveCanvasSmartviewMixedDTO = {
               idDoc: this.props.documentId,
               keyWordNonOcr: keyWordsDto.keyWordNonOcr,
               keyWordNonOcrRegex: keyWordsDto.keyWordNonOcrRegex,
               keyWordNonOcrDictionary: keyWordsDto.keyWordNonOcrDictionary,
               keyWordOcr: keyWordsDto.keyWordOcr,
               keyWordOcrRegex: keyWordsDto.keyWordOcrRegex,
               keyWordOcrDictionary: keyWordsDto.keyWordOcrDictionary,
               rectanglesToRemove: keyWordsDto.rectanglesToRemove.concat(pageCoordinates2.rectanglesToRemove),
               markedAsAudit: true,
               pageCoordinates: pageCoordinates2.dtoRedact,
           }
       }

        this.onCallToDisableScroll()
        this.setState({
            checkStartRedaction: true
        }, () => {
            apiSmartBoxSaveCanvasDocumentSmartviewMixed2(saveCanvasSmartviewMixedDTO).then((response5) => {
               // console.log('response5.data.pageRectangleMap HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH', response5.data.pageRectangleMap)
                let pageRectangleMap = this.convertCanvasDataCopyMixed(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap)
                let pageRectangleMapNew = this.convertCanvasDataCopyMixedNew(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap)
               // console.log('pageRectangleMap GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', pageRectangleMap)
               // console.log('canvasDataMixedOcr GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', canvasDataMixedOcr)
                if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0 && Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length > 0) {
                  //  console.log('PADURARU 111111111111111111111111111111111111111111111111111111')
                    Object.keys(pageRectangleMap).forEach((item) => {
                        Object.keys(canvasDataMixedOcr).forEach((item2) => {
                            if (item === item2) {
                                pageRectangleMap[item2] = canvasDataMixedOcr[item2].concat(pageRectangleMap[item])
                                //pageRectangleMap[item2] = pageRectangleMap[item2].concat(canvasDataMixedOcr[item])
                            }

                        })
                    })
                } else if (Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length > 0) {
                   // console.log('PADURARU 22222222222222222222222222222222222222222222222222222222222')
                    pageRectangleMap = pageRectangleMap
                } else if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0) {
                  //  console.log('PADURARU 33333333333333333333333333333333333333333333333333')
                    pageRectangleMap = canvasDataMixedOcr
                }


                // if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0 && Object.keys(pageRectangleMapNew) && Object.keys(pageRectangleMapNew).length > 0) {
                //     Object.keys(canvasDataMixedOcr).forEach((item) => {
                //         Object.keys(pageRectangleMapNew).forEach((item2) => {
                //             if (item === item2) {
                //                 pageRectangleMapNew[item2] = canvasDataMixedOcr[item].concat(pageRectangleMapNew[item2])
                //             }
                //
                //         })
                //     })
                // } else if (Object.keys(pageRectangleMapNew) && Object.keys(pageRectangleMapNew).length > 0) {
                //     pageRectangleMapNew = pageRectangleMapNew
                // } else if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0) {
                //     pageRectangleMapNew = canvasDataMixedOcr
                // }

                // console.log('pageRectangleMap JJJJJJJJJJJJJJJJJJJJJJJJJJJ', pageRectangleMap)
                // console.log('pageRectangleMapNew JJJJJJJJJJJJJJJJJJJJJJJJJJJ', pageRectangleMapNew)
                this.setState({
                    checkStartRedaction: false,
                    canvasData: pageRectangleMap,
                    canvasDataCopy: pageRectangleMapNew,
                    statusSaveSmartviewMixed2: response5.status,
                    annotationText: ''
                })
                this.onRemoveEventDisableScroll()
            })
        })

    }
    onResetStatusSaveSmartviewMixed2 = () => {
        this.setState({
            statusSaveSmartviewMixed2: null
        })
    }
    onGetDrawRedactAllMatchedWordsMixed = (classNameList, classNameListMixed, unCheckedKeys, source, objectListRedact) => {
        const { pagesCountChildrenMixed, statusRedactAll, pagesCountChildrenMixedAll } = this.state
        const keyWordNonOcr = []
        const keyWordOcr = []
        const keyWordNonOcrRegex = []
        const keyWordOcrRegex = []
        const keyWordNonOcrDictionary = []
        const keyWordOcrDictionary = []
        const rectanglesToRemove = []
        // console.log('START REDACT 222222222222222222222222222 pagesCountChildrenMixed', pagesCountChildrenMixed)
        // console.log('START REDACT 222222222222222222222222222 objectListRedact', objectListRedact)
        // console.log('START REDACT 222222222222222222222222222 unCheckedKeys', unCheckedKeys)
        if (objectListRedact.selectAllItemsStandard && objectListRedact.selectAllItemsStandard.length > 0) {
            objectListRedact.selectAllItemsStandard.forEach((standardWord) => {
                keyWordNonOcr.push({
                    word: standardWord.name,
                    category: standardWord.categoryName,
                    subcategory: standardWord.subcategoryName,
                    page: Number(standardWord.pageNumber),
                    pagePosition: standardWord.pageCount,
                    keyUI: standardWord.keyMixtRedaction,
                })
            })
        }
        if (objectListRedact.selectAllItemsStandardOcr && objectListRedact.selectAllItemsStandardOcr.length > 0) {
            objectListRedact.selectAllItemsStandardOcr.forEach((standardWord) => {
                keyWordOcr.push({
                    word: standardWord.name,
                    category: standardWord.categoryName,
                    subcategory: standardWord.subcategoryName,
                    page: Number(standardWord.pageNumber),
                    pagePosition: null,
                    keyUI: standardWord.key,
                })
            })
        }
        if (objectListRedact.selectAllItemsRegEx && objectListRedact.selectAllItemsRegEx.length > 0) {
            objectListRedact.selectAllItemsRegEx.forEach((regExWord) => {
                keyWordNonOcrRegex.push({
                    word: regExWord.name,
                    category: regExWord.categoryName,
                    subcategory: regExWord.subcategoryName,
                    page: Number(regExWord.pageNumber),
                    pagePosition: regExWord.pageCount,
                    keyUI: regExWord.keyMixtRedaction,
                })
            })
        }
        if (objectListRedact.selectAllItemsRegExOcr && objectListRedact.selectAllItemsRegExOcr.length > 0) {
            objectListRedact.selectAllItemsRegExOcr.forEach((regExWord) => {
                keyWordOcrRegex.push({
                    word: regExWord.name,
                    category: regExWord.categoryName,
                    subcategory: regExWord.subcategoryName,
                    page: Number(regExWord.pageNumber),
                    pagePosition: null,
                    keyUI: regExWord.key,
                })
            })
        }
        if (objectListRedact.selectAllItemsBusiness && objectListRedact.selectAllItemsBusiness.length > 0) {
            objectListRedact.selectAllItemsBusiness.forEach((businessWord) => {
                keyWordNonOcrDictionary.push({
                    word: businessWord.name,
                    category: businessWord.categoryName,
                    subcategory: businessWord.subcategoryName,
                    page: Number(businessWord.pageNumber),
                    pagePosition: businessWord.pageCount,
                    keyUI: businessWord.keyMixtRedaction,
                })
            })
        }
        if (objectListRedact.selectAllItemsBusinessOcr && objectListRedact.selectAllItemsBusinessOcr.length > 0) {
            objectListRedact.selectAllItemsBusinessOcr.forEach((businessWord) => {
                keyWordOcrDictionary.push({
                    word: businessWord.name,
                    category: businessWord.categoryName,
                    subcategory: businessWord.subcategoryName,
                    page: Number(businessWord.pageNumber),
                    pagePosition: null,
                    keyUI: businessWord.key,
                })
            })
        }
        // console.log('START REDACT 222222222222222222222222222 source', source)
        // console.log('START REDACT 222222222222222222222222222 statusRedactAll', statusRedactAll)
        // if (source === 'mixed' && statusRedactAll === "false") {
        //     this.onSetDocumentRedactAllStatus("true")
        // } else {
        //     this.onSetDocumentRedactAllStatus("false")
        // }
        if (source === 'mixed' && (objectListRedact.classListStandardMixedAll && objectListRedact.classListStandardMixedAll.length > 0 && objectListRedact.alreadyRedactedKeysAllMixed && objectListRedact.alreadyRedactedKeysAllMixed.length > 0
            && objectListRedact.classListStandardMixedAll.length === objectListRedact.alreadyRedactedKeysAllMixed.length)) {
           objectListRedact.allWordsRedactedMixed && objectListRedact.allWordsRedactedMixed.length > 0 && objectListRedact.allWordsRedactedMixed.forEach((elem) => {
               unCheckedKeys.push(elem.key)
               rectanglesToRemove.push({
                   key: elem.keyMixtRedaction ? elem.keyMixtRedaction : elem.key,
                   type: 'standard',
               })
           })
        } else if (unCheckedKeys && unCheckedKeys.length > 0) {
            pagesCountChildrenMixedAll && pagesCountChildrenMixedAll.length > 0 && pagesCountChildrenMixedAll.forEach((elem2) => {
                if (unCheckedKeys.includes(elem2.key)) {
                    rectanglesToRemove.push({
                        key: elem2.keyMixtRedaction ? elem2.keyMixtRedaction : elem2.key,
                        type: 'standard',
                    })
                }
            })
            // unCheckedKeys.forEach((elem) => {
            //     rectanglesToRemove.push({
            //         key: elem,
            //         type: 'standard',
            //     })
            // })
        }
        // console.log('START REDACT 222222222222222222222222222 keyWordNonOcr', keyWordNonOcr)
        // console.log('START REDACT 222222222222222222222222222 keyWordNonOcrRegex', keyWordNonOcrRegex)
        // console.log('START REDACT 222222222222222222222222222 keyWordNonOcrDictionary', keyWordNonOcrDictionary)
        // console.log('START REDACT 222222222222222222222222222 keyWordOcr', keyWordOcr)
        // console.log('START REDACT 222222222222222222222222222 keyWordOcrRegex', keyWordOcrRegex)
        // console.log('START REDACT 222222222222222222222222222 keyWordOcrDictionary', keyWordOcrDictionary)
        // console.log('START REDACT 222222222222222222222222222 rectanglesToRemove', rectanglesToRemove)
        const keyWordsDto = {
            keyWordNonOcr: keyWordNonOcr,
            keyWordNonOcrRegex: keyWordNonOcrRegex,
            keyWordNonOcrDictionary: keyWordNonOcrDictionary,
            keyWordOcr: keyWordOcr,
            keyWordOcrRegex: keyWordOcrRegex,
            keyWordOcrDictionary: keyWordOcrDictionary,
            rectanglesToRemove: rectanglesToRemove,
        }

        this.onSmartBoxSaveCanvasDocumentSmartviewMixed2(keyWordsDto, unCheckedKeys, source)
    }

    drawRedactAllMatchedWordsMixed = (classNameList, classNameListMixed, unCheckedKeys=[], source='redactAll',
                                      objectListRedact) => {
        const { idDoc, docAnnotationCategories, totalPages, divPrint, statusRedactAll, alreadyRedactedKeys } = this.state
           // console.log('START REDACT 11111111111111 objectListRedact', objectListRedact)
           // console.log('START REDACT 11111111111111 unCheckedKeys', unCheckedKeys)
           // console.log('START REDACT 11111111111111 alreadyRedactedKeys', alreadyRedactedKeys)
        if (classNameListMixed && idDoc) {
            this.onGetDrawRedactAllMatchedWordsMixed(classNameList, classNameListMixed, unCheckedKeys, source, objectListRedact)
        }

        // if (classNameListMixed && idDoc) {
        //     const parameters = {
        //         idDocument: idDoc,
        //         orderCategory: docAnnotationCategories
        //     }
        //     let spanCoords = []
        //     for(let i=1; i<=totalPages; i++) {
        //
        //         const otherPages = document.querySelectorAll('div#' + divPrint + ':not([data-pageid="' + i + '"])')
        //         otherPages.forEach((page) => {
        //             page.classList.remove('show-pdf-page')
        //         })
        //
        //         const page = document.querySelectorAll('div#' + divPrint + '[data-pageid="' + i + '"]')
        //         if (page.length > 0) {
        //             page[0].classList.add('show-pdf-page')
        //         }
        //
        //         const spans = document.querySelectorAll('span[data-markjs][data-page-nr="' + i + '"]')
        //         spans.forEach((el) => {
        //             const temp = el.className.split(' ').slice(-1)
        //             const rect = el.getBoundingClientRect()
        //             spanCoords[temp[0]] = {top: rect.top, left: rect.left, width: rect.width, height: rect.height}
        //         })
        //     }
        //     console.log('START REDACT 11111111111111 spanCoords', spanCoords)
        //     if (unCheckedKeys.length) {
        //         this.setState({ spanCoords,
        //             checkStartRedaction: null,
        //             sourceActive: source,
        //             alreadyRedactedList: [],
        //             alreadyRedactedKeys,
        //             unCheckedKeys: [],
        //             statusCloseSmartviewRedactAll: true,
        //             canvasCleaned: true,
        //             statusSaveQuery: true,
        //             //canvasData: initialCanvasData,
        //             haveChanges: false,
        //             clearSketch: true
        //         }, () => {
        //             this.setState({
        //                 clearSketch: false
        //             })
        //             if (objectListRedact.allWordsRedacted && objectListRedact.allWordsRedacted.length > 0) {
        //                 this.changeAllQueryStatusMixed(parameters, classNameList, classNameListMixed, unCheckedKeys, source, objectListRedact.allWordsRedacted, objectListRedact)
        //             } else if (objectListRedact.allWordsRedacted.length === 0 && objectListRedact.allWordsRedactedOcr && objectListRedact.allWordsRedactedOcr.length > 0) {
        //                 this.callAllQueryStatusCopyOcr(parameters, classNameList, classNameListMixed, unCheckedKeys, source, true, objectListRedact.allWordsRedacted, objectListRedact)
        //             }
        //         })
        //     } else {
        //         this.setState({
        //             spanCoords,
        //             checkStartRedaction: null,
        //             sourceActive: source,
        //             //statusCloseSmartviewRedactAll: true,
        //             canvasCleaned: false,
        //             // statusSaveQuery: false,
        //             //haveChanges: true,
        //             startDrawRedactMatchedWordsMixed: true,
        //             // pageNumber: 1,
        //         }, () => {
        //             console.log(' START REDACT MIXED 11111111111111111111 spanCoords', spanCoords)
        //             if (objectListRedact.allWordsRedacted && objectListRedact.allWordsRedacted.length > 0) {
        //                 this.changeAllQueryStatusMixed(parameters, classNameList, classNameListMixed, unCheckedKeys, source, objectListRedact.allWordsRedacted, objectListRedact)
        //             } else if (objectListRedact.allWordsRedacted.length === 0 && objectListRedact.allWordsRedactedOcr && objectListRedact.allWordsRedactedOcr.length > 0) {
        //                 this.callAllQueryStatusCopyOcr(parameters, classNameList, classNameListMixed, unCheckedKeys, source, true, objectListRedact.allWordsRedacted, objectListRedact)
        //             }
        //
        //         })
        //     }
        //
        // }


        // if (classNameListMixed && idDoc) {
        //     const parameters = {
        //         idDocument: idDoc,
        //         orderCategory: docAnnotationCategories
        //     }
        //     let spanCoords = []
        //     for(let i=1; i<=totalPages; i++) {
        //
        //         const otherPages = document.querySelectorAll('div#' + divPrint + ':not([data-pageid="' + i + '"])')
        //         otherPages.forEach((page) => {
        //             page.classList.remove('show-pdf-page')
        //         })
        //
        //         const page = document.querySelectorAll('div#' + divPrint + '[data-pageid="' + i + '"]')
        //         if (page.length > 0) {
        //             page[0].classList.add('show-pdf-page')
        //         }
        //
        //         const spans = document.querySelectorAll('span[data-markjs][data-page-nr="' + i + '"]')
        //         spans.forEach((el) => {
        //             const temp = el.className.split(' ').slice(-1)
        //             const rect = el.getBoundingClientRect()
        //             spanCoords[temp[0]] = {top: rect.top, left: rect.left, width: rect.width, height: rect.height}
        //         })
        //     }
        //    // console.log('statusRedactAll 11111111111111111111', statusRedactAll)
        //     if (unCheckedKeys.length) {
        //         // const initialCanvasData = this.state.canvasData
        //         // for (let i = 1; i <= totalPages; i++) {
        //         //     let keepOnPage = []
        //         //     if(typeof initialCanvasData[i] !== 'undefined') {
        //         //         for (let j = 0; j < initialCanvasData[i].length; j++) {
        //         //             const parsed = typeof initialCanvasData[i][j].annotation === 'undefined' ?
        //         //                 JSON.parse(initialCanvasData[i][j]) : initialCanvasData[i][j]
        //         //             if(parsed.type === 1 || parsed.type === 3) {
        //         //                 keepOnPage.push(parsed)
        //         //             }
        //         //         }
        //         //     }
        //         //     initialCanvasData[i] = keepOnPage
        //         // }
        //         alreadyRedactedKeys.business = []
        //         alreadyRedactedKeys.regex = []
        //         alreadyRedactedKeys.standard = []
        //         alreadyRedactedKeys.mixed = []
        //         this.setState({ spanCoords,
        //             checkStartRedaction: null,
        //             sourceActive: source,
        //             alreadyRedactedList: [],
        //             alreadyRedactedKeys,
        //             unCheckedKeys: [],
        //             statusCloseSmartviewRedactAll: true,
        //             canvasCleaned: true,
        //             statusSaveQuery: true,
        //             //canvasData: initialCanvasData,
        //             haveChanges: false,
        //             clearSketch: true
        //         }, () => {
        //             this.setState({
        //                 clearSketch: false
        //             })
        //             this.changeAllQueryStatusCopy(parameters, classNameList, classNameListMixed, unCheckedKeys, source, objectListRedact.allWordsRedacted, objectListRedact)
        //         })
        //     } else {
        //         this.setState({ spanCoords,
        //             checkStartRedaction: null,
        //             sourceActive: source,
        //             statusCloseSmartviewRedactAll: true,
        //             canvasCleaned: false,
        //             statusSaveQuery: false,
        //             haveChanges: true,
        //         }, () => {
        //             // if (objectListRedact.allWordsRedacted && objectListRedact.allWordsRedacted.length > 0) {
        //             //     this.callAllQueryStatusCopy(parameters, classNameList, classNameListMixed, unCheckedKeys, source, true, objectListRedact.allWordsRedacted, objectListRedact)
        //             // } else if (objectListRedact.allWordsRedacted.length === 0 && objectListRedact.allWordsRedactedOcr && objectListRedact.allWordsRedactedOcr.length > 0) {
        //             //     this.callAllQueryStatusCopyOcr(parameters, classNameList, classNameListMixed, unCheckedKeys, source, true, objectListRedact.allWordsRedacted, objectListRedact)
        //             // }
        //
        //             this.changeAllQueryStatusCopy(parameters, classNameList, classNameListMixed, unCheckedKeys, source, objectListRedact.allWordsRedacted, objectListRedact)
        //
        //             // if (statusRedactAll === "true") {
        //             //     //de decomentat 44444444444444444444444
        //             //     this.onRemoveAllQueryStatus()
        //             // } else {
        //             //     // this.setState({ canvasCleaned: false }, () => {
        //             //     //     this.setState({
        //             //     //         statusSaveQuery: false
        //             //     //     })
        //             //     //     this.callAllQueryStatusCopy(parameters, classNameList, classNameListMixed, unCheckedKeys, source, true, objectListRedact.allWordsRedacted, objectListRedact)
        //             //     // })
        //             //     //this.callAllQueryStatusCopy(parameters, classNameList, classNameListMixed, unCheckedKeys, source, true, objectListRedact.allWordsRedactedMixed, objectListRedact)
        //             //     //this.changeAllQueryStatusCopy(parameters, classNameList, classNameListMixed, unCheckedKeys, source, objectListRedact.allWordsRedacted, objectListRedact)
        //             // }
        //         })
        //     }
        // }

    }

    drawRedactAllMatchedWordsMixedHistory = (redactedListKeys, unCheckedKeys=[], source, objectListRedact) => {

         // console.log('DRAW REDACT HISTORY DDDDDDDDDDDDDDD 1111111111111111111111 redactedListKeys', redactedListKeys)
         // console.log('DRAW REDACT HISTORY DDDDDDDDDDDDDDD 1111111111111111111111 unCheckedKeys', unCheckedKeys)
         // console.log('DRAW REDACT HISTORY DDDDDDDDDDDDDDD 1111111111111111111111 source', source)
         // console.log('DRAW REDACT HISTORY DDDDDDDDDDDDDDD 1111111111111111111111 objectListRedact', objectListRedact)
        const keyWordNonOcr = []
        const keyWordOcr = []
        const keyWordNonOcrRegex = []
        const keyWordOcrRegex = []
        const keyWordNonOcrDictionary = []
        const keyWordOcrDictionary = []
        const rectanglesToRemove = []
        objectListRedact.selectWordsRedactedHistory && objectListRedact.selectWordsRedactedHistory.length > 0 && objectListRedact.selectWordsRedactedHistory.forEach((elemHistory) => {
            keyWordNonOcr.push({
                word: elemHistory.name,
                category: elemHistory.categoryName,
                subcategory: elemHistory.subcategoryName,
                page: Number(elemHistory.pageNumber),
                pagePosition: elemHistory.pageCount,
                keyUI: elemHistory.keyMixtRedaction,
            })
        })
        if (source === 'history all' && (objectListRedact.allWordsRedactedHistory && objectListRedact.allWordsRedactedHistory.length > 0 && objectListRedact.checkedKeys && objectListRedact.checkedKeys.length > 0
            && objectListRedact.allWordsRedactedHistory.length === objectListRedact.checkedKeys.length)) {
            objectListRedact.allWordsRedactedHistory && objectListRedact.allWordsRedactedHistory.length > 0 && objectListRedact.allWordsRedactedHistory.forEach((elem) => {
                unCheckedKeys.push(elem.key)
                rectanglesToRemove.push({
                    key: elem.keyMixtRedaction ? elem.keyMixtRedaction : elem.key,
                    type: 'standard',
                })
            })
        } else if (unCheckedKeys && unCheckedKeys.length > 0) {
            objectListRedact.allWordsRedactedHistory && objectListRedact.allWordsRedactedHistory.length > 0 && objectListRedact.allWordsRedactedHistory.forEach((elem2) => {
                if (unCheckedKeys.includes(elem2.key)) {
                    rectanglesToRemove.push({
                        key: elem2.keyMixtRedaction ? elem2.keyMixtRedaction : elem2.key,
                        type: 'standard',
                    })
                }
            })
        }
        const keyWordsDto = {
            keyWordNonOcr: keyWordNonOcr,
            keyWordNonOcrRegex: keyWordNonOcrRegex,
            keyWordNonOcrDictionary: keyWordNonOcrDictionary,
            keyWordOcr: keyWordOcr,
            keyWordOcrRegex: keyWordOcrRegex,
            keyWordOcrDictionary: keyWordOcrDictionary,
            rectanglesToRemove: rectanglesToRemove,
        }
        this.onSmartBoxSaveCanvasDocumentSmartviewMixed2History(keyWordsDto, unCheckedKeys, source)
    }

    onSmartBoxSaveCanvasDocumentSmartviewMixed2History = (keyWordsDto, unCheckedKeys, source) => {
        const { canvasDataMixedOcr } = this.state
        const pageCoordinates2 = this.onGetPageCoordinates()

      //  console.log('START REDACT 222222222222222222222222222 pageCoordinates2', pageCoordinates2)
        let saveCanvasSmartviewMixedDTO = {}
        if (unCheckedKeys && unCheckedKeys.length > 0) {
            saveCanvasSmartviewMixedDTO = {
                idDoc: this.props.documentId,
                keyWordNonOcr: [],
                keyWordNonOcrRegex: [],
                keyWordNonOcrDictionary: [],
                keyWordOcr: [],
                keyWordOcrRegex: [],
                keyWordOcrDictionary: [],
                rectanglesToRemove: keyWordsDto.rectanglesToRemove.concat(pageCoordinates2.rectanglesToRemove),
                markedAsAudit: true,
                pageCoordinates: pageCoordinates2.dtoRedact,
            }
        } else {
            saveCanvasSmartviewMixedDTO = {
                idDoc: this.props.documentId,
                keyWordNonOcr: keyWordsDto.keyWordNonOcr,
                keyWordNonOcrRegex: keyWordsDto.keyWordNonOcrRegex,
                keyWordNonOcrDictionary: keyWordsDto.keyWordNonOcrDictionary,
                keyWordOcr: keyWordsDto.keyWordOcr,
                keyWordOcrRegex: keyWordsDto.keyWordOcrRegex,
                keyWordOcrDictionary: keyWordsDto.keyWordOcrDictionary,
                rectanglesToRemove: keyWordsDto.rectanglesToRemove.concat(pageCoordinates2.rectanglesToRemove),
                markedAsAudit: true,
                pageCoordinates: pageCoordinates2.dtoRedact,
            }
        }

        this.onCallToDisableScroll()
        this.setState({
            checkStartRedaction: true
        }, () => {
            apiSmartBoxSaveCanvasDocumentSmartviewMixed2(saveCanvasSmartviewMixedDTO).then((response1) => {
                let pageRectangleMap = this.convertCanvasDataCopyMixed(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
                let pageRectangleMapNew = this.convertCanvasDataCopyMixedNew(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
                if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0 && Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length > 0) {
                    Object.keys(canvasDataMixedOcr).forEach((item) => {
                        Object.keys(pageRectangleMap).forEach((item2) => {
                            if (item === item2) {
                                pageRectangleMap[item2] = pageRectangleMap[item2].concat(canvasDataMixedOcr[item])
                            }

                        })
                    })
                } else if (Object.keys(pageRectangleMap) && Object.keys(pageRectangleMap).length > 0) {
                    pageRectangleMap = pageRectangleMap
                } else if (Object.keys(canvasDataMixedOcr) && Object.keys(canvasDataMixedOcr).length > 0) {
                    pageRectangleMap = canvasDataMixedOcr
                }
                this.setState({
                    checkStartRedaction: false,
                    canvasData: pageRectangleMap,
                    canvasDataCopy: pageRectangleMapNew,
                    statusSaveSmartviewMixed2: response1.status,
                    annotationText: ''
                })
                this.onRemoveEventDisableScroll()
            })
        })
    }

    changeAllQueryStatusCopy = (parameters, classNameList, classNameListMixed, unCheckedKeys, source, allWordsRedacted, objectListRedact) => {
        this.setState({

        }, () => {
            this.setState({
                statusSaveQuery: false
            })
            if (objectListRedact.allWordsRedacted && objectListRedact.allWordsRedacted.length > 0) {
                this.callAllQueryStatusCopy(parameters, classNameList, classNameListMixed, unCheckedKeys, source, true, objectListRedact.allWordsRedacted, objectListRedact)
            } else if (objectListRedact.allWordsRedacted.length === 0 && objectListRedact.allWordsRedactedOcr && objectListRedact.allWordsRedactedOcr.length > 0) {
                this.callAllQueryStatusCopyOcr(parameters, classNameList, classNameListMixed, unCheckedKeys, source, true, objectListRedact.allWordsRedacted, objectListRedact)
            }
        })
    }

    callAllQueryStatusCopyOcr = (parameters, classNameList, classNameListMixed, unCheckedKeys, source, emptyUncheckedKeys, allWordsRedacted, objectListRedact) => {
        const saveCanvasDocumentSmartViewDTO = {
            idDoc: this.props.documentId,
            markedAsAudit: true,
            pageCoordinates: [],
            keyWordOcr: objectListRedact.selectRedactionStandardOcr ? objectListRedact.selectRedactionStandardOcr : [],
            keyWordOcrRegex: objectListRedact.selectRedactionRegExOcr ? objectListRedact.selectRedactionRegExOcr : [],
            keyWordOcrDictionary: objectListRedact.selectRedactionBusinessOcr ? objectListRedact.selectRedactionBusinessOcr : [],

        }

        apiSmartBoxSaveCanvasDocumentSmartviewMixed(saveCanvasDocumentSmartViewDTO).then((response) => {
            this.setState({
                statusCloseSmartviewRedactAll: false
            })
        })
    }
    callAllQueryStatusCopy = (parameters, classNameList, classNameListMixed, unCheckedKeys, source, emptyUncheckedKeys, allWordsRedacted, objectListRedact) => {
        const { alreadyRedactedKeys, redactingMode, spanCoords, listCategories } = this.state
        const pageHeight = document.querySelector('#pdf-page').clientHeight
        const parentWr = document.querySelector('div.canvas-page-1')
        let elements = []
        //console.log('START REDACT 222222222222222222222 classNameList', classNameList)
        classNameList.forEach((name, index) => {
            const elementsByClass = document.getElementsByClassName(name)
            Object.keys(elementsByClass).forEach((idx) => {
                elements.push(elementsByClass[idx])
            })
        })
        elements = this.sortElementsByPage(elements)
        // console.log('START REDACT 222222222222222222222 allWordsRedacted', allWordsRedacted)
        // console.log('START REDACT 222222222222222222222 elements', elements)
        allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((elem) => {
                elements && elements.length > 0 && elements.forEach((el) => {
                    const temp = el.className.split(' ').slice(-1)
                    if (temp.length) {
                        const elementKey = temp[0]
                        if (elem.key === elementKey) {
                            elem.el = el
                        }
                    }
                })
        })
        //elements era
      //  console.log('allWordsRedacted 333333333333333333333333333333', allWordsRedacted)
        allWordsRedacted = this.sortElementsByPageMixed(allWordsRedacted)
        if (allWordsRedacted && allWordsRedacted.length > 0) {
            allWordsRedacted.forEach((ell, indexEl, arrayQuery) => {
                const el = ell.el
                if (el) {
                    const temp = el.className.split(' ').slice(-1)
                    if (temp.length) {
                        const elementKey = temp[0]
                        const elementsPage = Number(el.getAttribute('data-page-nr'))
                        const elementsText = el.innerText
                        // console.log('elementsPage 333333333333333333333333333333 SSSSSSSSSSSSSSSSSSSSSSSSSS', elementsPage)
                        // console.log('this.state.pageNumber 333333333333333333333333333333 SSSSSSSSSSSSSSSSSSSSSSSSSS', this.state.pageNumber)
                        let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : el.getBoundingClientRect()
                        const left = elRect.left
                        const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top + 1
                        const width = elRect.width
                        const height = elRect.height
                        const elRectObj = {left, top, width, height}

                        // if (!alreadyRedactedKeys[source]) {
                        //     alreadyRedactedKeys[source] = []
                        // }
                         //console.log('alreadyRedactedKeys 333333333333333333333333333333 SSSSSSSSSSSSSSSSSSSSSSSSSS', alreadyRedactedKeys)
                        // console.log('source 333333333333333333333333333333 SSSSSSSSSSSSSSSSSSSSSSSSSS', source)
                        // console.log('elementKey 333333333333333333333333333333 SSSSSSSSSSSSSSSSSSSSSSSSSS', elementKey)
                        alreadyRedactedKeys.mixed = alreadyRedactedKeys.standard.concat(alreadyRedactedKeys.regex, alreadyRedactedKeys.business)
                        if (!alreadyRedactedKeys[source].includes(elementKey)) {
                            alreadyRedactedKeys[source].push(elementKey)
                            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                            //const colorForRectangles = this.onGetColorForRectangles(redactingMode, el, elementKey, allWordsRedacted)

                            setTimeout(() => {
                                this.addAllSearchedRectangleCopy(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery, elementKey, allWordsRedacted, ell, objectListRedact)
                            }, 800)
                        }
                    }
                }
                //finsih
            })
        } else {
            elements && elements.length > 0 && elements.forEach((el, indexEl, arrayQuery) => {
                const ell = el
                if (el) {
                    const temp = el.className.split(' ').slice(-1)
                    if (temp.length) {
                        const elementKey = temp[0]
                        let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : el.getBoundingClientRect()
                        const left = elRect.left
                        const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top + 1
                        const width = elRect.width
                        const height = elRect.height
                        const elRectObj = {left, top, width, height}

                        if (!alreadyRedactedKeys[source]) {
                            alreadyRedactedKeys[source] = []
                        }
                        if (!alreadyRedactedKeys[source].includes(elementKey)) {
                            alreadyRedactedKeys[source].push(elementKey)
                            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                            //const colorForRectangles = this.onGetColorForRectangles(redactingMode, el, elementKey, allWordsRedacted)
                            setTimeout(() => {
                                this.addAllSearchedRectangleCopy(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery, elementKey, allWordsRedacted, ell, objectListRedact)
                            }, 800)
                        }
                    }
                }
                //finsih
            })
        }

        //de decomentat 1111111111111
        this.onSetDocumentRedactAllStatus("true")
    }


    addAllSearchedRectangleCopy = (el, parentWr, color= false, elRect, unCheckedKeys, indexEl, arrayQuery, elementKey, allWordsRedacted, ell, objectListRedact) => {
        const { canvasCleaned, alreadyRedactedList, redactingMode, totalPages } = this.state
        this.setState({
            checkStartRedaction: true
        })
        this.onCallToDisableScroll()
        //console.log('alreadyRedactedList RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR alreadyRedactedList', alreadyRedactedList)
        if (el) {
            const temp = el.className.split(' ').slice(-1)
            if (temp.length) {
                const elementKey = temp[0]
                const elementsPage = Number(el.getAttribute('data-page-nr'))
                const elementsText = el.innerText
                    this.onAllSearchChangePageCopy(el, indexEl, arrayQuery, null, objectListRedact, unCheckedKeys).then((response) => {
                        //console.log('response RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR', response)
                        const parentWr = document.querySelector('div.canvas-page-' + this.state.pageNumber)
                        const selectionPosition = elRect //el.getBoundingClientRect()
                        const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
                        const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3
                        if (!canvasCleaned) {
                            //console.log('alreadyRedactedKeys 4444444444444444444444444444444444 ----- 11111111111111111 SSSSSSSSSSSSSSSSSSSSSSSSSS', alreadyRedactedList)
                            if (!this.duplicateInCanvas(el, left, top)) {
                                let rect = new fabric.Rect({
                                    left: left,
                                    top: top,
                                    width: selectionPosition.width,
                                    height: selectionPosition.height + 3,
                                    //fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                    fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                    // opacity: 0.4,
                                    // opacityType: 0.4,
                                    //fill: this.onGetColorForRectangles(redactingMode, el, elementKey, allWordsRedacted),
                                    angle: 0,
                                    padding: 0,
                                    selectable: true,
                                    text: elementsText ? elementsText : '',
                                    annotation: elementKey
                                })

                                this._sketch._fc.add(rect)
                                this._sketch._fc.renderAll()
                                if (elementsPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' || this.getStatusElementRedaction() === 'elementDict' || this.getStatusElementRedaction() === 'elementRegEx' ||
                                    this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                                    setTimeout(() => {
                                        this.setState({
                                            checkStartRedaction: false
                                        })
                                        this.onRemoveEventDisableScroll()
                                    }, 5000)
                                }
                            }
                        } else if (!alreadyRedactedList.includes(elementKey)) {
                           // console.log('alreadyRedactedKeys 4444444444444444444444444444444444 --------- 2222222222222222 SSSSSSSSSSSSSSSSSSSSSSSSSS', alreadyRedactedList)
                            alreadyRedactedList.push(elementKey)
                            let rect = new fabric.Rect({
                                left: left,
                                top: top,
                                width: selectionPosition.width,
                                height: selectionPosition.height + 3,
                                fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                //fill: this.onGetColorForRectangles(redactingMode, el, elementKey, allWordsRedacted),
                                // opacity: 0.4,
                                // opacityType: 0.4,
                                angle: 0,
                                padding: 0,
                                selectable: true,
                                text: elementsText ? elementsText : '',
                                annotation: elementKey
                            })

                            this._sketch._fc.add(rect)
                            this._sketch._fc.renderAll()
                        }
                        if (elementsPage === totalPages) {
                            this.toggleLoadingProcess(false)

                            setTimeout(() => {
                                if (elementsPage === totalPages) {
                                    this.setState({
                                        checkStartRedaction: false
                                    })
                                    this.onRemoveEventDisableScroll()
                                }
                            }, 5000)
                        }
                    })

            }
        }
    }


    async onAllSearchChangePageCopy(element, indexEl, arrayQuery, pageNr = 1, objectListRedact, unCheckedKeys) {
        const { statusLastPages } = this.state
        const elementsPage = element !== null ? Number(element.getAttribute('data-page-nr')) : pageNr
        if (elementsPage === this.state.totalPages) {
            this.setState({
                checkStartRedactAllPagesNextFiles: false,
                checkStartRedactAllPagesBackFiles: false
            })
        }

        //start elements
        if (elementsPage && (elementsPage !== this.state.pageNumber) && elementsPage !== 0) {
            this.changeAllPageCopy(elementsPage, indexEl, arrayQuery, objectListRedact, unCheckedKeys)
            const elemPage = elementsPage + 1

            //check disabled page
            if (elementsPage === this.state.totalPages) {
                setTimeout(() => {
                    if (elementsPage === this.state.totalPages) {
                        this.setState({
                            checkStartRedaction: false
                        })
                        this.onRemoveEventDisableScroll()
                    }
                }, 5000)
            } else if (elemPage === this.state.totalPages) {
                setTimeout(() => {
                    if (elemPage === this.state.totalPages) {
                        this.setState({
                            checkStartRedaction: false
                        })
                        this.onRemoveEventDisableScroll()
                    }
                }, 5000)
            } else if (elemPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' ||
                this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                setTimeout(() => {
                    this.setState({
                        checkStartRedaction: false
                    })
                    this.onRemoveEventDisableScroll()
                }, 5000)
            }
        } else if (indexEl === arrayQuery.length - 1) {
            //last element finally
            this.changeAllPageCopy(elementsPage, indexEl, arrayQuery, objectListRedact, unCheckedKeys)
            this.setState({
                statusLastPages: false
            }, () => {
                this.setState({
                    statusLastPages: false,
                    pageNumber: elementsPage
                })
                //this.changeAllPage(elementsPage)
            })
        }
    }

    changeAllPageCopy = (newPageNumber, indexEl, arrayQuery, objectListRedact, unCheckedKeys, annt, statusPageSidebar) => {
        this.setState({
            statusClickPageSidebar: statusPageSidebar,
            stateSidebarPages: false
        })
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        if (elements) {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
                    elements[i].classList.remove('show-pdf-page')
                    elements[i].classList.add('show-pdf-page')
                } else if (elements[i]) {
                    elements[i].classList.remove('show-pdf-page')
                }
            }
        }

        //add active class to pages thumbnails
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }

        const points = this.getRectanglesForPreviewPage()
        //de decomentat 22222222222222222
        //console.log('RRRRR intra 555555555555555555555555555555555555')
        this.updateAllRectanglesCopy(points, statusPageSidebar, indexEl, arrayQuery, objectListRedact, unCheckedKeys)
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.hideCanvasModals()
        this.setState({
            pageNumber: newPageNumber,
            canvasData: initialCanvasData,
            forPageNumber: newPageNumber,
            haveChanges: false,
        }, () => {
            if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                    if (annt) {
                        if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                            || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
                            rectangle.set('stroke', 'rgb(255,244,11)')
                            rectangle.set('strokeWidth', 2)
                        }
                    }
                })
                canvas.renderAll()
            } else {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                })
                canvas.renderAll()
            }
        })
    }

    updateAllRectanglesCopy = (points, statusPageSidebar, indexEl, arrayQuery, objectListRedact, unCheckedKeys) => {
        const { statusAfterRemove, stateSidebarPages, extractPointsToSave, dtoRedactAll } = this.state
       // console.log('objectListRedact FINAL 0000000000000000000000000000000000000', objectListRedact)
        const pointsToSave = {}
        const listPoints = []
        let maxHeight //= 10000
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elements.length > 0) {
            maxHeight = elements[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        points.forEach((element) => {
            let y1 = element.y1 - 1
            if(y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if(y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true,
            }

            this.onAddCategoryAndSubcategoryOnSavedRectangles(obj, element, unCheckedKeys)
            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            this.setState({
                pagePointSumList: element.pagePointSum,
            })
        })
        //console.log('pointsToSave FINALLY 9999999999999999999999999999999999999', pointsToSave)
        const pointsCanvas = []
        let pointsCanvasAll = []
        const numberPage = []
        let numberPageAll = []
        pointsCanvas.push(pointsToSave)
        pointsCanvasAll = this.state.extractPointsToSave.concat(pointsCanvas)
        numberPage.push(this.state.pageNumber)
        numberPageAll = this.state.extractPageNumber.concat(numberPage)
        this.setState({
            extractPointsToSave: pointsCanvasAll,
            extractPageNumber: numberPageAll
        })
        const dataToSave = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSave

        }
        const dtoRedact = []
        let redactAllDto = []
        const dataGetPoints = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
        }

        const dataToSaveRedactAll = {
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSave

        }
        dtoRedact.push(dataToSaveRedactAll)
        redactAllDto = this.state.dtoRedactAll.concat(dtoRedact)
        this.setState({
            dtoRedactAll: redactAllDto
        }, () => {
            if ((indexEl === arrayQuery.length - 1)) {
                const saveCanvasDocumentSmartViewDTO = {
                    idDoc: this.props.documentId,
                    markedAsAudit: true,
                    pageCoordinates: redactAllDto,
                    keyWordOcr: objectListRedact.selectRedactionStandardOcr ? objectListRedact.selectRedactionStandardOcr : [],
                    keyWordOcrRegex: objectListRedact.selectRedactionRegExOcr ? objectListRedact.selectRedactionRegExOcr : [],
                    keyWordOcrDictionary: objectListRedact.selectRedactionBusinessOcr ? objectListRedact.selectRedactionBusinessOcr : [],

                }
                if (this.props.isSmartBoxApplication) {
                    if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
                            apiSmartBoxSaveCanvasDocumentSmartviewMixed(saveCanvasDocumentSmartViewDTO).then((response) => {
                                this.setState({
                                    statusCloseSmartviewRedactAll: false
                                })
                            })
                            // apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                            //     this.setState({
                            //         statusCloseSmartviewRedactAll: false
                            //     })
                            // })
                    }
                }
            }
        })
        if (this.props.isSmartBoxApplication) {
            if (!statusAfterRemove) {
                if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
                    // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
                    //
                    // }).catch((error) => {
                    //   if (error?.response?.status === 504) {
                    //     CustomNotificationManager.cleanBuffer('warning', '')
                    //   }else{
                    //     console.log(error)
                    //   }
                    // })
                }
            }
            this.setState({
                statusSavedSmartView: true
            })
        } else {
            apiUpdateCanvasPoints(dataToSave).then(() => {

            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
            this.setState({
                statusAfterRemove: false
            })
        }
    }


    drawRedactAllMatchedWords = (classNameList, classListStandard, classListBusiness, classListRegEx, unCheckedKeys=[], source='redactAll', allWordsRedacted) => {
        const { idDoc, docAnnotationCategories, totalPages, divPrint, statusRedactAll } = this.state
        let classAllNameList = []
        classAllNameList = classListStandard.concat(classListBusiness, classListRegEx)
        this.setState({
            checkStartRedaction: null,
            sourceActive: source,
            statusCloseSmartviewRedactAll: true
        })
        if (classNameList && idDoc) {
            const parameters = {
                idDocument: idDoc,
                orderCategory: docAnnotationCategories
            }
            let spanCoords = []
            for(let i=1; i<=totalPages; i++) {

                const otherPages = document.querySelectorAll('div#' + divPrint + ':not([data-pageid="' + i + '"])')
                otherPages.forEach((page) => {
                    page.classList.remove('show-pdf-page')
                })

                const page = document.querySelectorAll('div#' + divPrint + '[data-pageid="' + i + '"]')
                if (page.length > 0) {
                    page[0].classList.add('show-pdf-page')
                }

                const spans = document.querySelectorAll('span[data-markjs][data-page-nr="' + i + '"]')
                spans.forEach((el) => {
                    const temp = el.className.split(' ').slice(-1)
                    const rect = el.getBoundingClientRect()
                    spanCoords[temp[0]] = {top: rect.top, left: rect.left, width: rect.width, height: rect.height}
                })
            }
            this.setState({ spanCoords }, () => {
                if (statusRedactAll === "true") {
                    this.onRemoveAllQueryStatus()
                } else {
                    this.changeAllQueryStatus(parameters, classNameList, unCheckedKeys, source, allWordsRedacted)
                }
            })
        }
    }

    onRemoveAllQueryStatus = (parameters, classNameList, unCheckedKeys, source) => {
        // this.onSearchChangePage(null, 1).then(() => {
        //
        // })
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        for (let i = 1; i < this.state.totalPages + 1; i++) {
            setTimeout(() => {
                this.changePageRemoveAllRedact(i)

            }, 500)
        }
        this.onSetDocumentRedactAllStatus("false")
        this.onRemoveAllRedaction()
    }
    changePageRemoveAllRedact = (newPageNumber, annt, statusPageSidebar) => {
        this.setState({
            statusClickPageSidebar: statusPageSidebar,
            stateSidebarPages: false
        })
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        if (elements) {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
                    elements[i].classList.remove('show-pdf-page')
                    elements[i].classList.add('show-pdf-page')
                } else if (elements[i]) {
                    elements[i].classList.remove('show-pdf-page')
                }
            }
        }

        //add active class to pages thumbnails
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }

        //const points = this.getRectanglesForPreviewPage()
        //this.updateRectangles(points, statusPageSidebar)
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = []
        this.hideCanvasModals()
        this.setState({
            pageNumber: newPageNumber,
            canvasData: initialCanvasData,
            forPageNumber: newPageNumber,
            haveChanges: false,
        }, () => {
            const rectangleOnCurrentPage = this.state.canvasData
            rectangleOnCurrentPage[newPageNumber] = []
            this._sketch.setBackgroundFromDataUrl('')
            this._sketch.clear()
            const alreadyRedactedKeys= {}
            alreadyRedactedKeys.business= []
            alreadyRedactedKeys.manual= []
            alreadyRedactedKeys.regex= []
            alreadyRedactedKeys.standard= []
            this.setState({
                controlledValue: null,
                backgroundColor: 'transparent',
                fillWithBackgroundColor: false,
                canUndo: this._sketch.canUndo(),
                canRedo: this._sketch.canRedo(),
                canvasData: rectangleOnCurrentPage,
                haveChanges: true,
                alreadyRedactedKeys: {},
                newListKeysStandard: [],
                newListKeysManual: [],
                newListKeysRegEx: [],
                newListKeysBusiness: [],
                verificationOnClickClearAll: true,
                verificationOnClickClearAllManual: true,
                verificationOnClickClearAllRegEx: true,
                verificationOnClickClearAllBusiness: true
            })
        })
    }
    changeAllQueryStatus = (parameters, classNameList, unCheckedKeys, source, allWordsRedacted) => {
        this.setState({ canvasCleaned: false }, () => {
            this.setState({
                statusSaveQuery: false
            })
            this.callAllQueryStatus(parameters, classNameList, unCheckedKeys, source, true, allWordsRedacted)
        })
    }

    onSetDocumentRedactAllStatus = (status) => {
        const smartViewRedactAllDTO = {
            document_redact_all_status: status,
            documentId: this.props.documentId
        }
        apiSmartBoxSetDocumentRedactAllStatus(smartViewRedactAllDTO).then((response) => {
            this.setState({
                statusRedactAll: status
            })
        })
    }
    onRemoveAllRedaction = () => {
        const smartViewRedactAllDTO = {
            docId: this.props.documentId
        }
        apiRemoveAllSmartviewRedactions(smartViewRedactAllDTO).then((response) => {
            this.setState({
                statusCloseSmartviewRedactAll: false
            })
        })
    }
    onGetColorForRectangles = (redactingMode, el, elementKey, allWordsRedacted) => {
        allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((elem) => {
            if (elementKey === elem.key) {
                return elem.categoryColor
            }
        })
        //return redactingMode ? 'rgba(232,14,14,0.4)' : 'rgb(243,2,2)'
    }
    callAllQueryStatus = (parameters, classNameList, unCheckedKeys, source, emptyUncheckedKeys, allWordsRedacted) => {
        const { alreadyRedactedKeys, redactingMode, spanCoords, listCategories } = this.state
        const pageHeight = document.querySelector('#pdf-page').clientHeight
        const parentWr = document.querySelector('div.canvas-page-1')
        let elements = []
        classNameList.forEach((name, index) => {
            const elementsByClass = document.getElementsByClassName(name)
            Object.keys(elementsByClass).forEach((idx) => {
                elements.push(elementsByClass[idx])
            })
        })
        elements = this.sortElementsByPage(elements)
        allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((elem) => {
            elements && elements.length > 0 && elements.forEach((el) => {
                const temp = el.className.split(' ').slice(-1)
                if (temp.length) {
                    const elementKey = temp[0]
                    if (elem.key === elementKey) {
                        elem.el = el
                    }
                }
            })
        })
        //elements era
        if (allWordsRedacted && allWordsRedacted.length > 0) {
            allWordsRedacted.forEach((ell, indexEl, arrayQuery) => {
                const el = ell.el
                if (el) {
                    const temp = el.className.split(' ').slice(-1)
                    if (temp.length) {
                        const elementKey = temp[0]
                        let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : el.getBoundingClientRect()
                        const left = elRect.left
                        const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top + 1
                        const width = elRect.width
                        const height = elRect.height
                        const elRectObj = {left, top, width, height}

                        if (!alreadyRedactedKeys[source]) {
                            alreadyRedactedKeys[source] = []
                        }
                        if (!alreadyRedactedKeys[source].includes(elementKey)) {
                            alreadyRedactedKeys[source].push(elementKey)
                            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                            //const colorForRectangles = this.onGetColorForRectangles(redactingMode, el, elementKey, allWordsRedacted)
                            setTimeout(() => {
                                this.addAllSearchedRectangle(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery, elementKey, allWordsRedacted, ell)
                            }, 800)
                        }
                    }
                }
                //finsih
            })
        } else {
            elements && elements.length > 0 && elements.forEach((el, indexEl, arrayQuery) => {
                const ell = el
                if (el) {
                    const temp = el.className.split(' ').slice(-1)
                    if (temp.length) {
                        const elementKey = temp[0]
                        let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : el.getBoundingClientRect()
                        const left = elRect.left
                        const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top + 1
                        const width = elRect.width
                        const height = elRect.height
                        const elRectObj = {left, top, width, height}

                        if (!alreadyRedactedKeys[source]) {
                            alreadyRedactedKeys[source] = []
                        }
                        if (!alreadyRedactedKeys[source].includes(elementKey)) {
                            alreadyRedactedKeys[source].push(elementKey)
                            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                            //const colorForRectangles = this.onGetColorForRectangles(redactingMode, el, elementKey, allWordsRedacted)
                            setTimeout(() => {
                                this.addAllSearchedRectangle(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery, elementKey, allWordsRedacted, ell)
                            }, 800)
                        }
                    }
                }
                //finsih
            })
        }

        this.onSetDocumentRedactAllStatus("true")
    }

    addAllSearchedRectangle = (el, parentWr, color= false, elRect, unCheckedKeys, indexEl, arrayQuery, elementKey, allWordsRedacted, ell) => {
        const { canvasCleaned, alreadyRedactedList, redactingMode, totalPages } = this.state
        this.setState({
            checkStartRedaction: true
        })
        this.onCallToDisableScroll()
        if (el) {
            const temp = el.className.split(' ').slice(-1)
            if (temp.length) {
                const elementKey = temp[0]
                const elementsPage = Number(el.getAttribute('data-page-nr'))
                const elementsText = el.innerText
                //if (elementsPage !== this.state.pageNumber) {
                this.onAllSearchChangePage(el, indexEl, arrayQuery).then((response) => {
                    const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
                    const selectionPosition = elRect //el.getBoundingClientRect()
                    const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
                    const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3
                    if (!canvasCleaned) {
                        if (!this.duplicateInCanvas(el, left, top)) {
                            let rect = new fabric.Rect({
                                left: left,
                                top: top,
                                width: selectionPosition.width,
                                height: selectionPosition.height + 3,
                                //fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                                // opacity: 0.4,
                                // opacityType: 0.4,
                                //fill: this.onGetColorForRectangles(redactingMode, el, elementKey, allWordsRedacted),
                                angle: 0,
                                padding: 0,
                                selectable: true,
                                text: elementsText ? elementsText : '',
                                annotation: elementKey
                            })

                            this._sketch._fc.add(rect)
                            this._sketch._fc.renderAll()
                            if (elementsPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' || this.getStatusElementRedaction() === 'elementDict' || this.getStatusElementRedaction() === 'elementRegEx' ||
                                this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                                setTimeout(() => {
                                    this.setState({
                                        checkStartRedaction: false
                                    })
                                    this.onRemoveEventDisableScroll()
                                }, 5000)
                            }
                        }
                    } else if (!alreadyRedactedList.includes(elementKey)) {
                        alreadyRedactedList.push(elementKey)
                        let rect = new fabric.Rect({
                            left: left,
                            top: top,
                            width: selectionPosition.width,
                            height: selectionPosition.height + 3,
                            fill: ell.categoryColor ? ell.categoryColor : color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                            //fill: this.onGetColorForRectangles(redactingMode, el, elementKey, allWordsRedacted),
                            // opacity: 0.4,
                            // opacityType: 0.4,
                            angle: 0,
                            padding: 0,
                            selectable: true,
                            text: elementsText ? elementsText : '',
                            annotation: elementKey
                        })

                        this._sketch._fc.add(rect)
                        this._sketch._fc.renderAll()
                    }
                    if (elementsPage === totalPages) {
                        this.toggleLoadingProcess(false)
                        // this.setState({
                        //   checkStartRedaction: false
                        // })
                        // this.onRemoveEventDisableScroll()
                        setTimeout(() => {
                            if (elementsPage === totalPages) {
                                this.setState({
                                    checkStartRedaction: false
                                })
                                this.onRemoveEventDisableScroll()
                            }
                        }, 5000)
                    }
                })

                //}

            }
        }
    }

    async onAllSearchChangePage(element, indexEl, arrayQuery, pageNr = 1) {
        const { statusLastPages } = this.state
        const elementsPage = element !== null ? Number(element.getAttribute('data-page-nr')) : pageNr
        if (elementsPage === this.state.totalPages) {
            this.setState({
                checkStartRedactAllPagesNextFiles: false,
                checkStartRedactAllPagesBackFiles: false
            })
        }
        if (elementsPage === 2) {
        }
        if (elementsPage && (elementsPage !== this.state.pageNumber) && elementsPage !== 0) {
            this.changeAllPage(elementsPage, indexEl, arrayQuery)
            const elemPage = elementsPage + 1
            if (elementsPage === this.state.totalPages) {
                setTimeout(() => {
                    if (elementsPage === this.state.totalPages) {
                        this.setState({
                            checkStartRedaction: false
                        })
                        this.onRemoveEventDisableScroll()
                    }
                }, 5000)
            } else if (elemPage === this.state.totalPages) {
                setTimeout(() => {
                    if (elemPage === this.state.totalPages) {
                        this.setState({
                            checkStartRedaction: false
                        })
                        this.onRemoveEventDisableScroll()
                    }
                }, 5000)
            } else if (elemPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' ||
                this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                setTimeout(() => {
                    this.setState({
                        checkStartRedaction: false
                    })
                    this.onRemoveEventDisableScroll()
                }, 5000)
            }
        } else if (indexEl === arrayQuery.length - 1) {
            this.changeAllPage(elementsPage, indexEl, arrayQuery)
            this.setState({
                statusLastPages: false
            }, () => {
                this.setState({
                    statusLastPages: false,
                    pageNumber: elementsPage
                })
                //this.changeAllPage(elementsPage)
            })
        } else if (this.state.totalPages !== elementsPage) {
        }
    }

    changeAllPage = (newPageNumber, indexEl, arrayQuery, annt, statusPageSidebar) => {
        this.setState({
            statusClickPageSidebar: statusPageSidebar,
            stateSidebarPages: false
        })
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
        if (elements) {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
                    elements[i].classList.remove('show-pdf-page')
                    elements[i].classList.add('show-pdf-page')
                } else if (elements[i]) {
                    elements[i].classList.remove('show-pdf-page')
                }
            }
        }

        //add active class to pages thumbnails
        const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
        if (this.state.navigationType === 'pages') {
            for (let i = 0; i < this.state.totalPages; i++) {
                if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
                    elementsSmall[i].classList.add('previewpdf__container__item--active')
                } else {
                    elementsSmall[i].classList.remove('previewpdf__container__item--active')
                }
            }
        }

        const points = this.getRectanglesForPreviewPage()
        this.updateAllRectangles(points, statusPageSidebar, indexEl, arrayQuery)
        const initialCanvasData = this.state.canvasData
        initialCanvasData[this.state.pageNumber] = points
        this.hideCanvasModals()
        this.setState({
            pageNumber: newPageNumber,
            canvasData: initialCanvasData,
            forPageNumber: newPageNumber,
            haveChanges: false,
        }, () => {
            if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                    if (annt) {
                        if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                            || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
                            rectangle.set('stroke', 'rgb(255,244,11)')
                            rectangle.set('strokeWidth', 2)
                        }
                    }
                })
                canvas.renderAll()
            } else {
                const canvas = this._sketch._fc
                canvas._objects.forEach((rectangle) => {
                    rectangle.set('stroke', 'rgba(0,0,0,0.41)')
                    rectangle.set('strokeWidth', 1)
                })
                canvas.renderAll()
            }
        })
    }

    onAddCategoryAndSubcategoryOnSavedRectangles = (obj, element, unCheckedKeys) => {
        const { treeDataAnnotations, treeDataRegEx, treeDataDict } = this.state
        // console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        // console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX unCheckedKeys', unCheckedKeys)
        if (obj.type === 3 || obj.type === 1) {
            obj.category = 'Smartviewer'
            obj.subcategory = 'Freehand'
        }
        treeDataAnnotations && treeDataAnnotations.length > 0 && treeDataAnnotations[0] && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 &&  treeDataAnnotations[0].children.forEach((item) => {
            item.children && item.children.length > 0 && item.children.forEach((item2) => {
                item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
                    item3.children && item3.children.length > 0 && item3.children.forEach((item4) => {
                        if ((element.annotation === item4.key) || element.text === item4.key) {
                                obj.smartviewTreeKey = `${item4.key} => ${item4.name} => ${item4.pageNumber}`
                            obj.category = item.key
                            obj.subcategory = item2.key
                        }
                    })
                })
            })
        })
        treeDataDict && treeDataDict.length > 0 && treeDataDict[0].children && treeDataDict[0].children.length > 0 && treeDataDict[0].children.forEach((item) => {
            item && item.children && item.children.length > 0 && item.children.forEach((item2) => {
                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
                    if ((element.annotation === item3.key) || element.text === item3.key) {
                            obj.smartviewTreeKey = `${item3.key} => ${item3.name} => ${item3.pageNumber}`
                        obj.category = 'Dictionaries'
                        //obj.subcategory = item2.name
                        obj.subcategory = item.key
                    }
                })
            })
        })
        treeDataRegEx && treeDataRegEx.length > 0 && treeDataRegEx[0].children && treeDataRegEx[0].children.length > 0 && treeDataRegEx[0].children.forEach((item) => {
            item && item.children && item.children.length > 0 && item.children.forEach((item2) => {
                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
                    if ((element.annotation === item3.key) || element.text === item3.key) {
                            obj.smartviewTreeKey = `${item3.key} => ${item3.name} => ${item3.pageNumber}`
                        obj.category = 'Regular Expressions'
                        //obj.subcategory = item2.name
                        obj.subcategory = item.key
                    }
                })
            })
        })
        return obj
    }
    updateAllRectangles = (points, statusPageSidebar, indexEl, arrayQuery) => {
        const { statusAfterRemove, stateSidebarPages, extractPointsToSave, dtoRedactAll } = this.state
        const pointsToSave = {}
        const listPoints = []
        let maxHeight //= 10000
        const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
        if(elements.length > 0) {
            maxHeight = elements[0].getBoundingClientRect().height
        } else {
            maxHeight = 10000
        }
        points.forEach((element) => {
            let y1 = element.y1 - 1
            if(y1 > maxHeight) {
                y1 = y1 - maxHeight
            }

            let y2 = element.y2
            if(y2 > maxHeight) {
                y2 = y2 - maxHeight
            }
            const obj = {
                x1: element.x1,
                x2: element.x2 - 1,
                y1: y1,
                y2: y2,
                annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
                text: element.text ? element.text : '',
                type: element.type,
                pagePointSum: element.pagePointSum,
                points: element.points,
                label: element.label,
                picture: true,
            }
            this.onAddCategoryAndSubcategoryOnSavedRectangles(obj, element)
            const stringObj = JSON.stringify(obj)
            listPoints.push(stringObj)
            pointsToSave[this.state.pageNumber] = listPoints
            this.setState({
                pagePointSumList: element.pagePointSum,
            })
        })
        const pointsCanvas = []
        let pointsCanvasAll = []
        const numberPage = []
        let numberPageAll = []
        pointsCanvas.push(pointsToSave)
        pointsCanvasAll = this.state.extractPointsToSave.concat(pointsCanvas)
        numberPage.push(this.state.pageNumber)
        numberPageAll = this.state.extractPageNumber.concat(numberPage)
        this.setState({
            extractPointsToSave: pointsCanvasAll,
            extractPageNumber: numberPageAll
        })
        const dataToSave = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSave

        }
        const dtoRedact = []
        let redactAllDto = []
        const dataGetPoints = {
            idDoc: this.props.documentId,
            page: this.state.pageNumber,
        }

        const dataToSaveRedactAll = {
            page: this.state.pageNumber,
            pageRectangleMap: pointsToSave

        }
        dtoRedact.push(dataToSaveRedactAll)
        redactAllDto = this.state.dtoRedactAll.concat(dtoRedact)
        this.setState({
            dtoRedactAll: redactAllDto
        }, () => {
            if ((indexEl === arrayQuery.length - 1)) {
                const saveCanvasDocumentSmartViewDTO = {
                    idDoc: this.props.documentId,
                    pageCoordinates: redactAllDto

                }
                if (this.props.isSmartBoxApplication) {
                    if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
                        if (!this.props.isOcr) {
                            //console.log('SAVE ---- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww pointsToSave', pointsToSave)
                            apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                                this.setState({
                                    statusCloseSmartviewRedactAll: false
                                })
                            })
                        }
                    }
                }
            }
        })
        if (this.props.isSmartBoxApplication) {
            if (!statusAfterRemove) {
                if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
                    // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
                    //
                    // }).catch((error) => {
                    //   if (error?.response?.status === 504) {
                    //     CustomNotificationManager.cleanBuffer('warning', '')
                    //   }else{
                    //     console.log(error)
                    //   }
                    // })
                }
            }
            this.setState({
                statusSavedSmartView: true
            })
        } else {
            apiUpdateCanvasPoints(dataToSave).then(() => {

            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
            this.setState({
                statusAfterRemove: false
            })
        }
    }

    onSelectResetProfiles = (e) => {
        this.setState({
            valueSelectProfile: e,
        }, () => {
            this.onSetBoxRedactionColorProfile()
        })
    }
    onSetBoxRedactionColorProfile = () => {
        const { valueSelectProfile } = this.state
        const setBoxRedactionColorProfileDTO = {
            redactionProfileId: valueSelectProfile,
            boxId: this.state.documentId
        }
        apiSetBoxRedactionColorProfile(setBoxRedactionColorProfileDTO).then((response) => {
            if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
                this.setState({
                    onSelectResetProfilesStatus: 'start'
                })
            } else if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
        })
    }
    onSelectResetProfilesStatusUpdate = () => {
        this.setState({
            onSelectResetProfilesStatus: ''
        })
    }
    render() {
        const { OptGroup } = Select
        const {
            totalPages,
            tool,
            forPageNumber,
            canvasData,
            pdfBytes,
            totalThumbnails,
            isDocumentLoaded,
            divPrint,
            landscape,
            height,
            width,
            annotationList,
            finalizeLoading,
            //printAnnotation,
            scale,
            showCloseButton,
            renderSearch,
            viewAnnotationsRedaction,
            renderSelectDraw,
            renderSelectText,
            renderCommentsText,
            annotatedAiHTML,
            printLoading,
            disableClickOnPage,
            documentNoteVisible,
            isDocumentReady,
            pageNumber,
            annotateSelectedModalVisible,
            itemsSelected,
            numberOfAnnotateSelected,
            idDoc,
            renderedPagesStatus,
            loadingBeforeMark,
            pageSidebarVisible,
            displaySearchButtons,
            newListKeys,
            loadingProcess,
            loadingProcessMessage,
            //statusSelectAgainRedacted,
            newListKeysStandard,
            verificationOnClickClearAll,
            newListKeysManual,
            verificationOnClickClearAllManual,
            newListKeysRegEx,
            verificationOnClickClearAllRegEx,
            newListKeysBusiness,
            verificationOnClickClearAllBusiness,
            statusKeysEmptyOnClearAll,
            statusSavedSmartView,
            drawByHandText,
            clearSketch,
            activeDocumentNote,
            activePrintDocument,
            activeClearPage,
            activeClearSelected,
            activeSearchWord,
            activeAnnotation,
            activeSelectTool,
            activeSelectDraw,
            activeSelectText,
            activeUndo,
            activeRedo,
            treeDataSearchHistory,
            valueSelectNavigation,
            isNextDocument,
            isNextDocumentId,
            disabledNextDocument,
            nextDocumentLength,
            filesTable,
            disabledBackDocument,
            checkStartRedactAllPagesNextFiles,
            checkStartRedactAllPagesBackFiles,
            notifyNote,
            searchMatchedElement,
            statusSaveQuery,
            alreadyRedactedKeys,
            activePreview,
            visibleDropdownStatus,
            visibleDropdownNavigation,
            clearPageDisabled,
            statusRedactAll,
            extractPointsToSave,
            statusCloseSmartviewRedactAll,
            statusNextDocumentBusiness,
            statusNextDocumentRegEx,
            statusNextDocumentStandard,
            customerStatus,
            selectTag,
            checkedPreview,
            redactingMode,
            removeSelectedBtnDisabled,
            renderSelectTool,
            previewPageDisabled,
            canvasDataOcrDrawBox,
            statusPageSidebar,
            documentNoteGet,
            activeComments,
            canvasDataComments,
            statusSaveCanvasDeleteSection,
            valueSelectProfile,
            listRedactionColour,
            onSelectResetProfilesStatus,
            responseAnnotationsCopy,
            statusHideAllHighlightingMixed,
            canvasDataHighlightingMixed,
            startDrawRedactMatchedWordsMixed,
            statusSaveSmartviewMixed2
        } = this.state
        let { valueStatusFile } = this.state
        let { checkStartRedaction } = this.state
        const { smartBoxDocumentId, isOcr, smartBoxDocumentPath, documentId, visibleToDownload, fileWarnings, lockSmartViewChanges } = this.props
        const { client, boxRedactionColorProfileId, statusOpenRedactionTool } = this.props
        const itemSearch = cookie.load('sendNoteResponse', { path: '/' })
        //console.log('canvasData --- RENDER zzzzzzzzzzzzz ---', canvasData)
       // console.log('statusRedactAll --- RENDER REDACTION TOOL ---', statusRedactAll)
        // if (typeof this._sketch !== 'undefined') {

        // }
        // if (statusSavedSmartView === true) {
        //   checkStartRedaction = false
        // }
        //valueStatusFile = systemPropertyFileStatus
        //valueStatusClassification = doc_classification
        const btnClasses = this.setCanvasButtonsClasses()
        let canvasModalText = ''
        if (this._sketch) {
            const canvasModalObjectSelected = this._sketch.state.selectedObject
            const relatedObj = this._sketch.findByPagePointSum(canvasModalObjectSelected.pagePointSum, canvasModalObjectSelected.annotationType)
            if (relatedObj) {
                canvasModalText = relatedObj.text
            }
        }
        const filesPdf = []
        let disabledNextFiles = false
        let disabledBackFiles = false
        this.props.filesTable && this.props.filesTable.length > 0 && this.props.filesTable.forEach((file) => {
            if (file.type !== 'DIRECTORY') {
                filesPdf.push(file)
            }
        })
        if (filesPdf && filesPdf.length > 1) {
            if (filesPdf[filesPdf.length-1].id === documentId) {
                disabledNextFiles = true
            } else {
                disabledNextFiles = false
            }
        }
        if (filesPdf && filesPdf.length > 1) {
            if (filesPdf[0].id === documentId) {
                disabledBackFiles = true
            } else {
                disabledBackFiles = false
            }
        }
        const children = []
        if (annotationList) {
            const groupedAnnotationList = groupArrayBy(annotationList, 'annotationCategoryEnum')
            Object.keys(groupedAnnotationList).forEach((category, key) => {
                const item = groupedAnnotationList[category]
                if (item) {
                    children.push(
                        <OptGroup label={ category } key={ key }>
                            { this.addAnnotationOptions(item) }
                        </OptGroup>
                    )
                }
            })
        }
        this.settings.slidesToShow = 3
        return (
            <React.Fragment>
                { (loadingProcess) &&
                <div className="redacting-mask">
                    <div className="redacting-mask-div">
                        <Spin className="redacting-mask-spin mt-20" spinning={true}/>
                        <p className="redacting-mask-text">{ loadingProcessMessage }</p>
                    </div>
                </div>
                }
                { this.props.isSmartBoxApplication &&
                <div className="content-name-saved"><span className="content-document-name">
            { isDocumentReady ?
                <span
                    className={statusOpenRedactionTool && statusOpenRedactionTool === 'warning' ? 'icon-v17-back-1 disabled-back-1' :
                        checkStartRedactAllPagesBackFiles ? 'icon-v17-back-1 disabled-back-1' : disabledBackDocument ? 'icon-v17-back-1 disabled-back-1' :
                        (filesPdf && filesPdf.length === 1) ? 'icon-v17-back-1 disabled-back-1' :
                            disabledBackFiles ? 'icon-v17-back-1 disabled-back-1' : 'icon-v17-back-1 active-back-1'}
                    onClick={ statusOpenRedactionTool && statusOpenRedactionTool === 'warning' ? (e) => this.onDisabledForthDocument(e) :
                        checkStartRedactAllPagesBackFiles ? (e) => this.onDisabledForthDocument(e)
                        : disabledBackDocument ? (e) => this.onDisabledForthDocument(e) :
                            (filesPdf && filesPdf.length === 1) ? (e) => this.onDisabledForthDocument(e) :
                                disabledBackFiles ? (e) => this.onDisabledForthDocument(e) :
                                    (e) => this.onClickBackDocument(e)}></span> :
                <span
                    className={checkStartRedactAllPagesBackFiles ? 'icon-v17-back-1 disabled-back-1' : disabledBackDocument ? 'icon-v17-back-1 disabled-back-1' :
                        filesPdf && filesPdf.length === 1 ? 'icon-v17-back-1 disabled-back-1' :
                            disabledBackFiles ? 'icon-v17-back-1 disabled-back-1' :
                                !isDocumentReady ? 'icon-v17-back-1 disabled-back-1' : 'icon-v17-back-1 active-back-1'}></span>
            }
                    <span className="document-name">{
                        smartBoxDocumentId.substr(smartBoxDocumentId.lastIndexOf('/') + 1, smartBoxDocumentId.length) }
          </span>
                    {isDocumentReady ?
                        <span
                            className={statusOpenRedactionTool && statusOpenRedactionTool === 'warning' ? 'icon-v17-forth-1 disabled-v17-forth' :
                                checkStartRedactAllPagesNextFiles ? 'icon-v17-forth-1 disabled-v17-forth'
                                : disabledNextDocument ? 'icon-v17-forth-1 disabled-v17-forth' :
                                    (filesPdf && filesPdf.length === 1) ? 'icon-v17-forth-1 disabled-v17-forth' :
                                        disabledNextFiles ? 'icon-v17-forth-1 disabled-v17-forth' : 'icon-v17-forth-1 active-v17-forth' }
                            onClick={statusOpenRedactionTool && statusOpenRedactionTool === 'warning' ? (e) => this.onDisabledForthDocument(e) :
                                checkStartRedactAllPagesNextFiles ? (e) => this.onDisabledForthDocument(e) :
                                disabledNextDocument ? (e) => this.onDisabledForthDocument(e) :
                                    (filesPdf && filesPdf.length === 1) ? (e) => this.onDisabledForthDocument(e) :
                                        disabledNextFiles ?  (e) => this.onDisabledForthDocument(e)
                                            : (e) => this.onClickForthDocument(e) }></span> :
                        <span
                            className={checkStartRedactAllPagesNextFiles ? 'icon-v17-forth-1 disabled-v17-forth' :
                                disabledNextDocument ? 'icon-v17-forth-1 disabled-v17-forth' :
                                    filesPdf && filesPdf.length === 1 ? 'icon-v17-forth-1 disabled-v17-forth' :
                                        disabledNextFiles ? 'icon-v17-forth-1 disabled-v17-forth' :
                                            !isDocumentReady ? 'icon-v17-forth-1 disabled-v17-forth' : 'icon-v17-forth-1 active-v17-forth'}></span>
                    }
            </span></div>
                }
                <div className={ checkStartRedaction ? "redacting redacting-opacity" : 'redacting' }
                     style={ { height: 'calc(100vh - 0px)', gridTemplateColumns: this.generateGridColumnProperties() } }>
                    <div className="previewpdf" style={ {
                        overflowY: this.generateNavigationScroll(),
                        pointerEvents: documentNoteVisible ? 'none' : 'all'
                    } }>

                        <div className="new-navigation-smartview">
                            <Select
                                value={ <span className="content-value-navigation"><span>{ valueSelectNavigation === 'ai-results' ?
                                    <span className="nav-option"><span className="icon-v17-personal icon-nav-pii"></span>
                            <span className="text-option">Ai results</span></span> :
                                    valueSelectNavigation === 'search-history' ?
                                        <span className="nav-option"> <span className="icon-v17-search-history icon-nav"></span>
                            <span className="text-option">Search history</span></span> :
                                        valueSelectNavigation === 'images' ?
                                            <span className="nav-option"><span className="icon-v17-images icon-nav"></span>
                                <span className="text-option">Images</span></span> :
                                            valueSelectNavigation === 'review-by-page' ?
                                                <span className="nav-option"><span className="icon-v17-db-documents icon-nav"></span>
                                  <span className="text-option">Redactions</span></span> :
                                                valueSelectNavigation === 'metadata' ?
                                                    <span className="nav-option"><span className="icon-v17-metadata-1 icon-nav-metadata"></span>
                                  <span className="text-option">Metadata</span></span> : ''}</span>
                      <span className={ visibleDropdownNavigation ? "icon-v19-arrow-medium icon-v19-arrow-medium-rotate" : 'icon-v19-arrow-medium' }></span></span> }
                                className='navigation-bar-smartview'
                                dropdownClassName="new-navigation-bar-smartview"
                                onChange={ (e) => this.changeNavigationBar(e) }
                                showArrow={ false }
                                onDropdownVisibleChange={ (e) => this.onVisibleDropdownNavigation(e) }
                            >
                                <Option className="options-bar-smartview" key={1} value="ai-results">
                                    <span className="icon-v17-personal icon-nav-pii"></span>
                                    <span className="text-option">Ai results</span>
                                </Option>
                                <Option className="options-bar-smartview" key={2} value="search-history">
                                    <span className="icon-v17-search-history icon-nav"></span>
                                    <span className="text-option">Search history</span>
                                </Option>
                                <Option className="options-bar-smartview" key={5} value="review-by-page">
                                    <span className="icon-v17-db-documents icon-nav"></span>
                                    <span className="text-option">Redactions</span>
                                </Option>
                                <Option className="options-bar-smartview" key={6} value="metadata">
                                    <span className="icon-v17-metadata-1 icon-nav-metadata"></span>
                                    <span className="text-option">Metadata</span>
                                </Option>
                            </Select>
                        </div>

                        { this.state.navigationType === 'redacting' &&
                        <div className="previewpdf__container previewpdf__container-review">
                            <div className="header-contain-page-review">
                                <div className="content-option-ai">
                                    {/*<span className="icon-v17-redaction-hover"></span>*/}
                                    <Select className="select-profiles-colours"
                                            placeholder="Select redaction color"
                                            value={ valueSelectProfile ? valueSelectProfile : 'Select redaction color' }
                                            onChange={(e) => this.onSelectResetProfiles(e)}
                                    >
                                        { listRedactionColour && listRedactionColour.length > 0 && listRedactionColour.map((elem, index) => {
                                            return (
                                                <Option key={index} value={elem.redactionColorProfileId}>{elem.redactionColorProfileName}</Option>
                                            )
                                        })

                                        }
                                    </Select>
                                    <Select
                                        placeholder="show-all"
                                        className='custom-option-review'
                                        dropdownClassName="dropdown-custom-option-review"
                                    >
                                        <Option className="item-options-review" key={1} value="review-all">
                                            show all
                                        </Option>
                                        <Option className="item-options-review" key={2} value="review-redacted">
                                            redacted
                                        </Option>
                                        <Option className="item-options-review" key={3} value="review-annotated">
                                            annotated
                                        </Option>
                                    </Select>
                                </div>
                                {/*<ReviewPrevNextAiTag/>*/}
                            </div>
                            {
                                this.generateHtmlForAnnotationPreview(canvasData)
                            }
                        </div>
                        }


                        { this.state.navigationType === 'annotation' &&
                        <div className="previewpdf__container" style={{ width: '14vw' }}>
                            <p>Annotation sidebar</p>
                        </div>
                        }
                        { this.state.navigationType === 'security-scan' &&
                        <div className="previewpdf__container" style={{ width: '14vw' }}>
                            {
                                annotatedAiHTML
                            }
                        </div>
                        }

                        { this.state.navigationType === 'image' &&
                        <div className="previewpdf__container" style={{ width: '17vw' }}>
                            <SearchImagesContent documentId = { this.props.documentId }
                                                 canvasData = { canvasData }
                                                 highlightRectangle={ this.highlightRectangle }
                                                 isDocumentReady={ isDocumentReady }
                                                 changePage={ this.changePage }
                                                 drawAllMatchedWordsByClassList={this.drawAllMatchedWordsByClassList}
                                                 pageNumber={this.state.pageNumber}
                                                 deleteAllImages={ this.deleteAllImages }
                                                 removeObj={this.removeObj}
                                                 smartBoxDocumentId={ this.props.smartBoxDocumentId }
                                                 initialName={ this.props.initialName}
                                                 isSmartBoxApplication = { this.props.isSmartBoxApplication }
                                                 totalPages={ totalPages }
                                                 totalThumbnails={ totalThumbnails }
                                                 isNextDocumentId={ isNextDocumentId }
                            />
                        </div>
                        }
                        { this.state.navigationType === 'spell_checker' &&
                        <div className="previewpdf__container" style={{ width: '15vw' }}>
                            <SpellCheckerPage
                                highlightRectangle={ this.highlightRectangle }
                                isDocumentReady={ isDocumentReady }
                                changePage={this.changePage}
                                drawAllMatchedWordsByClassList={this.drawAllMatchedWordsByClassList}
                                documentId = { this.props.documentId }
                                totalPages={ totalPages }
                                smartBoxDocumentId={ this.props.smartBoxDocumentId }
                                isSmartBoxApplication = { this.props.isSmartBoxApplication }
                                pageNumber={ this.state.pageNumber }
                                isNextDocumentId={ isNextDocumentId }
                            />
                        </div>
                        }
                        { this.state.navigationType === 'search-history' &&
                        <div className="previewpdf__container" style={{ width: '100%' }}>
                            <SearchHistoryCopy isDocumentReady={ isDocumentReady }
                                           treeDataSearchHistory={ treeDataSearchHistory }
                                           highlightNextText={ this.highlightNextText }
                                           highlightPrevText={ this.highlightPrevText }
                                           matchedText={this.state.matchedText}
                                           isOcr={ isOcr }
                                           onSearchTextElementFind={ this.onSearchTextElementFind }
                                           searchMatchedElement={ searchMatchedElement }
                                           idDocument={ this.state.documentId }
                                           isSmartBoxApplication={ this.props.isSmartBoxApplication }
                                           drawAllMatchedWordsByClassList={this.drawAllMatchedWordsByClassList}
                                           changePage={this.changePage}
                                           getSearchHistoryList= { this.getSearchHistoryList }
                                           alreadyRedactedKeys= { alreadyRedactedKeys }
                                           lockSmartViewChanges= { lockSmartViewChanges }
                                               drawRedactAllMatchedWordsMixed= { this.drawRedactAllMatchedWordsMixed }
                                               drawRedactAllMatchedWordsMixedHistory= { this.drawRedactAllMatchedWordsMixedHistory }
                                               onGetRedactedKeysMixed={ this.onGetRedactedKeysMixed }
                            />
                        </div>
                        }
                        { this.state.navigationType === 'metadata' &&
                        <div className="previewpdf__container" style={{ width: '14vw', marginLeft: '10px' }}>
                            <MetadataPage
                                isSmartBoxApplication = { this.props.isSmartBoxApplication }
                                documentId = { isNextDocumentId.id ? isNextDocumentId.id : this.props.documentId }
                                isNextDocumentId={ isNextDocumentId }
                            />
                        </div>
                        }
                        { this.state.navigationType === 'security-scan' &&
                        <div className="previewpdf__container" style={{ width: '14vw' }}>
                            {
                                annotatedAiHTML
                            }
                        </div>
                        }
                        <div className="previewpdf__container" style={{ display: this.state.navigationType === 'find-asset' ? 'initial' : 'none', width: '100%' }}>
                            {
                                <AiAnnotationsSidebarCopy
                                    documentId={ this.props.isSmartBoxApplication ? smartBoxDocumentId : this.props.documentId }
                                    idDocument={ this.state.documentId }
                                    onScanAnnotateClick={ this.onScanAnnotateClick }
                                    isDocumentReady={ isDocumentReady }
                                    isSmartBoxApplication={ this.props.isSmartBoxApplication }
                                    landscape={ landscape }
                                    changePage={this.changePage}
                                    pageNumber={ this.state.pageNumber }
                                    drawAllMatchedWordsByClassList={this.drawAllMatchedWordsByClassList}
                                    collapseAnnotateSelectModal={this.collapseAnnotateSelectModal}
                                    setNumberOfAnnotateSelected={this.setNumberOfAnnotateSelected}
                                    hideLoadingBeforeMark={this.hideLoadingBeforeMark}
                                    allPagesRendered={ renderedPagesStatus.length > 0 && renderedPagesStatus.indexOf(false) === -1 }
                                    setQueryStatus={this.setQueryStatus}
                                    highlightRectangle={ this.highlightRectangle }
                                    setTreeDataAnnotations = { this.setTreeDataAnnotations }
                                    newListKeys = { newListKeys }
                                    smartBoxDocumentPath={ this.props.isSmartBoxApplication ? smartBoxDocumentPath : this.props.documentId }
                                    toggleLoadingProcess = { this.toggleLoadingProcess }
                                    onCheckStatusSelectAgainRedacted= { this.onCheckStatusSelectAgainRedacted }
                                    newListKeysStandard = { newListKeysStandard }
                                    verificationOnClickClearAll = { verificationOnClickClearAll }
                                    checkOnClearAll = { this.checkOnClearAll }
                                    newListKeysManual = { newListKeysManual }
                                    verificationOnClickClearAllManual = { verificationOnClickClearAllManual }
                                    checkOnClearAllManual = { this.checkOnClearAllManual }newListKeysRegEx = { newListKeysRegEx }
                                    checkOnClearAllRegEx = { this.checkOnClearAllRegEx }
                                    verificationOnClickClearAllRegEx = { verificationOnClickClearAllRegEx }
                                    newListKeysBusiness = { newListKeysBusiness }
                                    checkOnClearAllBusiness = { this.checkOnClearAllBusiness }
                                    verificationOnClickClearAllBusiness = { verificationOnClickClearAllBusiness }
                                    statusKeysEmptyOnClearAll = { statusKeysEmptyOnClearAll }
                                    onSendStatusOnClearAll = { this.onSendStatusOnClearAll }
                                    isOcr={ isOcr }
                                    fetchSmartBoxDataCanvasOcr={ this.fetchSmartBoxDataCanvasOcr }
                                    isNextDocument={ isNextDocument }
                                    isNextDocumentId={ isNextDocumentId }
                                    disabledNextDocument={ disabledNextDocument }
                                    nextDocumentLength={ nextDocumentLength }
                                    filesTable={ filesTable }
                                    checkedKeysObjectRefsStandard={this.checkedKeysObjectRefsStandard}
                                    statusSaveQuery={ statusSaveQuery }
                                    alreadyRedactedKeys={ alreadyRedactedKeys }
                                    setTreeDataRegEx={ this.setTreeDataRegEx }
                                    setTreeDataDict={ this.setTreeDataDict }
                                    savePage={ this.savePage }
                                    getStatusElementRedaction={ this.getStatusElementRedaction }
                                    drawRedactAllMatchedWords={ this.drawRedactAllMatchedWords }
                                    drawRedactAllMatchedWordsMixed={ this.drawRedactAllMatchedWordsMixed }
                                    statusRedactAll={ statusRedactAll }
                                    statusNextDocumentBusiness={ statusNextDocumentBusiness }
                                    onGetStatusNextDocumentBusiness={ this.onGetStatusNextDocumentBusiness }
                                    statusNextDocumentRegEx={ statusNextDocumentRegEx }
                                    onGetStatusNextDocumentRegEx={ this.onGetStatusNextDocumentRegEx }
                                    statusNextDocumentStandard={ statusNextDocumentStandard }
                                    onGetStatusNextDocumentStandard={ this.onGetStatusNextDocumentStandard }
                                    onDisabledNextDocument={ this.onDisabledNextDocument }
                                    onDisabledBackDocument={ this.onDisabledBackDocument }
                                    showHideCategoryOcr={ this.showHideCategoryOcr }
                                    onActionHideAllHighlightingCopy={ this.onActionHideAllHighlightingCopy }
                                    lockSmartViewChanges={ lockSmartViewChanges }
                                    boxRedactionColorProfileId={ boxRedactionColorProfileId }
                                    onSelectResetProfilesStatus={ onSelectResetProfilesStatus }
                                    onSelectResetProfilesStatusUpdate={ this.onSelectResetProfilesStatusUpdate }
                                    responseAnnotationsCopy={ responseAnnotationsCopy }
                                    startDrawRedactMatchedWordsMixed={ startDrawRedactMatchedWordsMixed }
                                    onGetPagesCountChildrenMixed={ this.onGetPagesCountChildrenMixed }
                                    onGetRedactedKeysMixed={ this.onGetRedactedKeysMixed }
                                />
                            }
                        </div>
                    </div>

                    <div className= { renderSearch ? 'previewpdf-right previewpdf-right-open-search' : 'previewpdf-right' } style={ {
                        overflowY: this.generateNavigationScroll(),
                        pointerEvents: documentNoteVisible ? 'none' : 'all',
                        display: pageSidebarVisible ? 'flex' : 'none'
                    } }>
                        { pageSidebarVisible &&
                        <div
                            className={ `previewpdf__container__pages ${ landscape ?
                                'previewpdf__container__pages--preview-landscape' :
                                '' }` } >
                            <h3 className="previewpdf__container__pages__title">{ totalPages !== null ? `PAGES ${ pageNumber } / ${ totalPages }` : '' }</h3>
                            <PageSidebarContent
                                totalThumbnails={ totalThumbnails }
                                totalPages={ totalPages }
                                canvasData={ canvasData }
                                changePage={ this.changePage }
                                pageNumber={ pageNumber }
                                checkStartRedaction={ checkStartRedaction }
                                onDefaultChange={ this.onDefaultChange }
                                changePageMixed={ this.changePageMixed }
                            />
                        </div>
                        }
                    </div>

                    { renderSearch ?
                        <Affix aria-hidden="false" style={{position:'fixed'}} className={ displaySearchButtons ? 'search-affix buttons-search-affix' : 'search-affix'}>
                            <div aria-hidden="false" className="update-wr-search-text">
                                <div className="header-search-text">
                                    <span className="title-header">Manual search</span>
                                    <span className="close-search"
                                          onClick={ () => this.closeSearch() }><span
                                        className="icon-v17-close-black"/></span>
                                </div>
                                <Search
                                    placeholder="Search"
                                    className="search-text-input"
                                    //onSearch={ (value) => this.searchTextInDoc(value, 'search-history') }
                                    onSearch={ (value) => this.searchTextInDocHistory(value, 'search-history') }
                                    onChange={ this.onSearchChangeValue }
                                    value={ this.state.searchValue }
                                />
                            </div>
                        </Affix> : ''

                    }

                    <div className="wr-buttons sticky-header">

                        <div className="update-document-note">
                            <React.Fragment>
                                { (documentNoteGet || notifyNote || itemSearch) &&
                                <span className="icon-v17-process-error"></span>
                                }
                                <button onMouseEnter={(e) => this.onMouseEnter(e, 'document-note')}
                                        onMouseLeave={ () => this.onMouseLeave() }
                                        disabled={lockSmartViewChanges === true}
                                        className={ activeDocumentNote ? 'icon-v17-document-note-active-1' : 'icon-v17-document-note-1' } onClick={ this.openDocumentNote }></button>
                            </React.Fragment>
                            <button className={ visibleToDownload === false ? 'icon-v17-download icon-download-disabled' : activePrintDocument ? 'icon-v17-download-active' : 'icon-v17-download' }
                                    onMouseEnter={(e) => this.onMouseEnter(e, 'print-document')}
                                    onMouseLeave={ () => this.onMouseLeave() }
                                    disabled={lockSmartViewChanges === true}
                                    onClick={ () => this.printDocument() }></button>
                        </div>
                        <div className="center-container">
                            <div className="center-container__buttons">
                                <div className="wr-clear-button">
                                    <button
                                        className={ activeClearPage ? 'icon-v17-clear-page-selected' : 'icon-v17-clear-page' }
                                        //className={ btnClasses.clear.class }
                                        //id="btn-clear"
                                        onMouseEnter={(e) => this.onMouseEnter(e, 'clear-page')}
                                        onMouseLeave={ () => this.onMouseLeave() }
                                        title="Clear page (clear all redaction boxes and annotations on page)"
                                        disabled={(clearPageDisabled === false ? true : btnClasses.clear.disabled) || lockSmartViewChanges === true} onClick={this._clearCopy}/>
                                    <button className={ activeClearSelected ? 'icon-v17-clear-selected-selected' : 'icon-v17-clear-selected' }
                                        //id="btn-eraser"
                                            title="Delete selection (delete selected redaction box)"
                                            onMouseEnter={(e) => this.onMouseEnter(e, 'clear-selected')}
                                            onMouseLeave={ () => this.onMouseLeave() }
                                            onClick={this.removeObj}
                                            disabled={this.state.removeSelectedBtnDisabled || statusSaveCanvasDeleteSection === true || lockSmartViewChanges === true}/>

                                </div>
                                <div className="wr-undo-redo-button">
                                    <button className={ activeUndo ? 'icon-v17-undo-selected' : 'icon-v17-undo' }
                                        //className={ btnClasses.undo.class } id="btn-undo"
                                            onMouseEnter={(e) => this.onMouseEnter(e, 'select-undo')}
                                            onMouseLeave={ () => this.onMouseLeave() }
                                            title="Undo (step back)"
                                            disabled={ btnClasses.undo.disabled || lockSmartViewChanges === true } onClick={ this._undo }/>

                                    <button className={ activeRedo ? 'icon-v17-redo-selected' : 'icon-v17-redo' }
                                        //className="btn-action mr-2" id="btn-redo"
                                            onMouseEnter={(e) => this.onMouseEnter(e, 'select-redo')}
                                            disabled={lockSmartViewChanges === true}
                                            onMouseLeave={ () => this.onMouseLeave() }
                                            title="Redo (step forward)"
                                            onClick={ this._redo }/>
                                </div>
                                <div className="wr-select-button">
                                    <button className={this.state.renderSelectTool ? 'icon-v17-move-selected' : activeSelectTool ? 'icon-v17-move-selected' : 'icon-v17-move' }
                                        //className={ btnClasses.select.class }
                                        //id={ this.state.renderSelectTool ? 'btn-select-active' : 'btn-select' }
                                            onMouseEnter={(e) => this.onMouseEnter(e, 'select-tool')}
                                            onMouseLeave={ () => this.onMouseLeave() }
                                            title="Selection Tool (selection tool)"
                                            disabled={lockSmartViewChanges === true}
                                            onClick={ (e) => this.chooseMethodRedacting('selectTool') }/>

                                    <button className={this.state.renderSelectDraw ? 'icon-v17-draw-redaction-selected' : activeSelectDraw ? 'icon-v17-draw-redaction-selected' : 'icon-v17-draw-redaction'}
                                        //className={ btnClasses.draw.class }
                                        //id={ this.state.renderSelectDraw ? 'btn-draw-active' : 'btn-draw' }
                                            onMouseEnter={(e) => this.onMouseEnter(e, 'select-draw')}
                                            onMouseLeave={ () => this.onMouseLeave() }
                                            disabled={lockSmartViewChanges === true}
                                            title="Draw (draw redaction box)"
                                            onClick={ (e) => this.chooseMethodRedacting('draw') }/>
                                    <button
                                        //className={isOcr ? 'icon-v17-text-selection icon-v17-text-selection-disabled' : this.state.renderSelectText ? 'icon-v17-text-selection-selected' : activeSelectText ? 'icon-v17-text-selection-selected' : 'icon-v17-text-selection' }
                                        className={this.state.renderSelectText ? 'icon-v17-text-selection-selected' : activeSelectText ? 'icon-v17-text-selection-selected' : 'icon-v17-text-selection' }
                                        //className={ btnClasses.undo.class }
                                        onMouseEnter={(e) => this.onMouseEnter(e, 'select-text')}
                                        onMouseLeave={ () => this.onMouseLeave() }
                                        disabled={lockSmartViewChanges === true}
                                        onClick={(e) => this.chooseMethodRedacting('selectText') }
                                        //id={ this.state.renderSelectText ? 'btn-text-select-active' : 'btn-text-select' }
                                        title="Select Text (select text)"
                                    />
                                </div>
                                <div className="wr-search-button">
                                    <button
                                        //className={ `${ isOcr ? 'icon-v17-word-search icon-v17-word-search-disabled' : renderSearch ? 'icon-v17-word-search-selected' : activeSearchWord ? 'icon-v17-word-search-selected' : 'icon-v17-word-search' }` }
                                        className={ `${ renderSearch ? 'icon-v17-word-search-selected' : activeSearchWord ? 'icon-v17-word-search-selected' : 'icon-v17-word-search' }` }
                                        //id={ this.state.renderSearch ? 'btn-search-active' : 'btn-search' }
                                        onMouseEnter={(e) => this.onMouseEnter(e, 'search-word')}
                                        onMouseLeave={ () => this.onMouseLeave() }
                                        disabled={lockSmartViewChanges === true}
                                        title="Search (search for words or sentences)"
                                        onClick={ (e) => this.chooseMethodRedacting('search') }/>
                                    { renderSearch ? <Icon type="up" className="search-arrow"/> : '' }
                                    <button className={ isOcr ? 'icon-v17-annotate-1 icon-v17-annotate-1-disabled' : `${ this.state.viewAnnotationsRedaction ? 'icon-v17-annotate-selected' : activeAnnotation ? 'icon-v17-annotate-selected' : 'icon-v17-annotate-1'}` }
                                        //className={ btnClasses.undo.class }
                                        //id={ this.state.viewAnnotationsRedaction ? 'btn-message-active' : 'btn-message' }
                                            title="Annotation (add annotation to redaction box)"
                                            onMouseEnter={(e) => this.onMouseEnter(e, 'annotation')}
                                            onMouseLeave={ () => this.onMouseLeave() }
                                            disabled={lockSmartViewChanges === true}
                                            onClick={ isOcr ? (e) => this.disabledAnswerToogle('disabled') : (e) => this.chooseMethodRedacting('annotation') }/>
                                </div>
                                <div className='wr-switch-redact'>
                                    <button className={ activePreview ? 'icon-v17-preview-selected-1' : this.state.redactingMode ? 'icon-v17-preview' :
                                        activePreview ? 'icon-v17-preview-selected-1' : 'icon-v17-preview-selected-1' }
                                            onMouseEnter={(e) => this.onMouseEnter(e, 'preview')}
                                            onMouseLeave={ () => this.onMouseLeave() }
                                            disabled={lockSmartViewChanges === true}
                                            onClick={this.props.isOcr ? (e) => this.onUpdateAnswerToogleOcr(e, !this.state.redactingMode) :
                                                (e) => this.onUpdateAnswerToogle(e, !this.state.redactingMode) }>

                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="content-option-smartview">
                            <div className="content-status-file">

                                <Select
                                    disabled={ lockSmartViewChanges === true }
                                    value={ <span className="content-value-status">
                  { selectTag.icon && selectTag.icon.includes('v211') ?
                      <span style={{backgroundColor: selectTag.color}} className={selectTag.icon}></span> :
                      selectTag.color && selectTag.color.includes('#') ?
                          <span style={{backgroundColor: selectTag.color}} className={'icon-color'}></span> :
                          <span><Icon type={selectTag.icon} /></span>
                  }
                                        <span>{ Object.keys(selectTag).length === 0 ? 'Select' : selectTag.tagName === '' ? 'Select' : selectTag.tagName }</span></span> }
                                    //placeholder="File Status"
                                    dropdownClassName="file-status-dropdown"
                                    dropdownMenuStyle={{overflowY:'auto', overflowX:'hidden',}}
                                    showArrow={ false }
                                    className={ valueStatusFile === '' || valueStatusFile === null ? 'custom-status-holder' : 'custom-status-select' }
                                    onChange={ (e) => this.updateValueStatus(e) }
                                    onDropdownVisibleChange={(e) => this.onDropdownVisibleChange(e)}
                                >
                                    {
                                        customerStatus && customerStatus.length > 0 && customerStatus.map((item, index) => {
                                            return (
                                                <Option className="item-options-status-new" key={index} value={item.tagName}>
                                                    { item.icon && item.icon.includes('v211') ?
                                                        <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                                              className={item.icon ? item.icon : 'icon-color'}></span> :
                                                        item.color ?
                                                            <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                                                  className={item.icon ? item.icon : 'icon-color'}></span> :
                                                            <span><Icon type={item.icon} /></span>
                                                    }
                                                    <span className={'item-tagName'}>{item.tagName.length > 17 ? item.tagName.substring(0,15) + '...' : item.tagName}</span>
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                    { showCloseButton && !checkStartRedaction ?
                        <div className="close-icon">
                            {/*<Icon title="Close redaction mode" type="close"/>*/}
                            <span className="icon-v17-close-black" onClick={ this.closeRedactingMixed }></span>
                        </div> : <div className="close-icon"/>
                    }
                    <div id="page-pdf-redacting" className={ landscape ? 'redacting__page-landscape' : 'redacting__page-portrait redacting-block' }
                         style={ { overflow: documentNoteVisible ? 'hidden' : 'auto' } }>
                        { printLoading || finalizeLoading ?
                            <Spin className="print-loading" spinning={ printLoading || finalizeLoading }
                                  tip={ printLoading ? `Prepare for downloading ${ totalPages } pages, please wait up to
                      ${ this.calculatePrintingTime(totalPages) } ${ totalPages > 60 ? 'minutes' : 'seconds' }` :
                                      `Prepare for finalizing ${ totalPages } pages, please wait up to
                      ${ this.calculatePrintingTime(totalPages) } ${ totalPages > 60 ? 'minutes' : 'seconds' }` }/> :
                            '' }
                        { fileWarnings && fileWarnings.length > 0 &&
                        <div className="contain-warnings-report">
                <span className="contain-text">
                <span className="icon-v23-warning-1"></span>
                <span className="content-text-warning">One or more errors have been detected in the original PDF file. This may cause issues with alignment of highlights and redactions.</span>
                </span>
                            <span className="link-text-warning" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001260647', '_blank') }>Learn more</span>
                        </div>
                        }
                        {
                            lockSmartViewChanges &&
                            <div className="contain-warnings-report">
                <span className="contain-text">
                <span className="icon-v23-warning-1"></span>
                <span className="content-text-warning">This document is already opened by another user, changes are disabled.</span>
                </span>
                            </div>
                        }
                        <div className={ landscape ? 'redacted-page-landscape' : 'redacted-page-portrait' }>
                            <div className="redacted-container" style={ { width: width, height: height } }>
                                <div className="redacted-section">
                                    { disableClickOnPage ? <div className="disabled-clicked-page"/> : '' }
                                    { documentNoteVisible ?
                                        <DocumentNote closeDocumentNote={ this.closeDocumentNote }
                                                      documentId={ this.props.documentId }
                                                      isSmartBoxApplication={ this.props.isSmartBoxApplication }
                                                      addNotifyDocumentNote={ this.addNotifyDocumentNote }
                                                      documentNoteGet={ documentNoteGet }
                                        />
                                        :
                                        '' }
                                    <div id="pdf-page" style={ { width: width, display: documentNoteVisible ? 'none' : 'block' } }>
                                        { !isDocumentReady &&
                                        <div className="content-spinning-render-pages"><Spin tip="Loading"></Spin></div>
                                        }
                                        <Spin className="custom-spin spin-over-pages" spinning={ loadingBeforeMark && !isOcr } tip={loadingBeforeMark && !isOcr ? 'Please wait while annotations are being marked on document!' : ''}></Spin>
                                        { totalPages ?
                                            this.renderPages(totalPages, canvasData, forPageNumber, tool, pdfBytes, isDocumentLoaded, divPrint, height, width, scale, viewAnnotationsRedaction, renderSelectDraw, renderSelectText, drawByHandText, clearSketch, isOcr, statusHideAllHighlightingMixed,
                                                canvasDataHighlightingMixed, statusSaveSmartviewMixed2) :
                                            '' }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { this.updateRenderAnnotationsList(children, canvasModalText) }
                </div>
                {/*</Spin>*/}
                { annotateSelectedModalVisible &&
                <AnnotateSelectedRedaction itemsSelected={itemsSelected} documentId={idDoc} numberOfAnnotateSelected={numberOfAnnotateSelected} collapseAnnotateSelectModal={this.collapseAnnotateSelectModal} updateCanvasText={this.updateCanvasText}/>
                }
            </React.Fragment>
        )
    }
}

export default RedactionToolCopy
