import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import './FormsWorkflowAdmin.scss'
import {Button, Empty, Input, List, Select} from "antd";
import HeaderForm from './componentsFormAdmin/HeaderFormAdmin'
import FullNameForm from './componentsFormAdmin/FullNameFormAdmin'
import AddressForm from './componentsFormAdmin/AddressFormAdmin'
import PhoneForm from './componentsFormAdmin/PhoneFormAdmin'
import EmailForm from './componentsFormAdmin/EmailFormAdmin'
import PurposeProcessForm from './componentsFormAdmin/PurposeProcessFormAdmin'
import SubjectVerificationForm from './componentsFormAdmin/SubjectVerificationFormAdmin'
import DropdownCategoriesForm from './componentsFormAdmin/DropdownCategoriesFormAdmin'
import FileUploadForm from './componentsFormAdmin/FileUploadFormAdmin'
import SignatureForm from './componentsFormAdmin/SignatureFormAdmin'
import ParagraphForm from './componentsFormAdmin/ParagraphFormAdmin'
import MultipleChoiceIndividualsForm from './componentsFormAdmin/MultipleChoiceIndividualsFormAdmin'
import MultipleChoicePersonalDataForm from './componentsFormAdmin/MultipleChoicePersonalDataFormAdmin'
import MultipleChoiceRecipientsForm from './componentsFormAdmin/MultipleChoiceRecipientsFormAdmin'
import DropdownForm from './componentsFormAdmin/DropdownFormAdmin'
import ShortTextForm from './componentsFormAdmin/ShortTextFormAdmin'
import LongTextForm from './componentsFormAdmin/LongTextFormAdmin'
import StaticTextForm from './componentsFormAdmin/StaticTextFormAdmin'
import {customConfirm} from "../../../utils/AssetRegister/CustomConfirm";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {
    apiCreateDefaultFormWorkflow,
    apiCreateDuplicateFormWorkflow,
    apiCreateNewDuplicateWorkflowTemplate,
    apiDeleteFormWorkflow, apiEditDefaultFormWorkflow,
    apiFormBuilderCreateFormWorkflow, apiFormBuilderEditFormWorkflow, apiGetDefaultFormListWorkflow,
    apiGetFormBuilderListWorkflow, apiGetFormWorkflow
} from "../../../api/SmartBox";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
const { Option } = Select;
class FormBuilderUiAdmin extends React.Component {
    state = {
        valueForm: '',
        listFormElements: [
            { id: 'Header', name: 'Header'},
            { id: 'Short text', name: 'Short text'},
            { id: 'Long text', name: 'Long text'},
            // { id: 'Static Text', name: 'Static Text'},
            // { id: 'Paragraph', name: 'Paragraph'},
            { id: 'Dropdown', name: 'Dropdown'},
            { id: 'Single Choice', name: 'Single Choice'},
            { id: 'Multiple Choice', name: 'Multiple Choice'},
            // { id: 'Multiple Choice Personal Data', name: 'Multiple Choice Personal Data'},
            // { id: 'Multiple Choice Recipients', name: 'Multiple Choice Recipients'},
            { id: 'Numbers-Full', name: 'Name (First and Last)'},
            { id: 'Email', name: 'Email'},
            { id: 'Addresses', name: 'Addresses'},
            { id: 'Phone', name: 'Phone'},
            { id: 'Date', name: 'File upload'},
            { id: 'Signature', name: 'Signature'},
        ],
        listFormComponents: [
            // { id: 'header', key: 0, name: 'header'},
            // { id: 'fullName', key: 1, name: 'fullName'},
            // { id: 'address', key: 2, name: 'address'},
            // { id: 'email', key: 3, name: 'email'},
            // { id: 'phone', key: 4, name: 'phone'},
            // { id: 'purposeProcess', key: 5, name: 'purposeProcess'},
            // { id: 'radio', key: 6, name: 'radio'},
            // { id: 'upload', key: 7, name: 'upload'},
            // { id: 'dropdown', key: 8, name: 'dropdown', titleCategories: 'Categories of individuals'},
            // { id: 'dropdown', key: 9, name: 'dropdown', titleCategories: 'Categories of personal data'},
            // { id: 'dropdown', key: 10, name: 'dropdown', titleCategories: 'Categories of recipients'},
            // { id: 'signature', key: 11, name: 'signature', titleCategories: 'Signature'},
            // { id: 'paragraph', key: 12, name: 'paragraph', titleCategories: 'Paragraph'},
        ],
        actionProvidedDraggable: {},
        keyComp: 0,
        listForms: [],
        selectForm: null,
        selectFormName: '',
        hoverDelete: false,
        hoverCopy: false,
        listFormsIds: [],
        formId: null,
        responseGetForm: {},
        checkStartSaved: false,
        hoverBtnAdd:false,
        isBtnSaveChangeDisabled:true,
        // isBtnSaveNewFormDisabled:true,
        responseSaveEmail: false,
        responseRequestForm: false,
        defaultTemplate: false,
        loadingButtonSaveForm: false,
        onHoverBack:false,
    }

    componentDidMount() {
        apiGetFormBuilderListWorkflow().then((response) => {
            this.setState({
                listForms: response.data
            })
        })

        const { selectFormTemplate } = this.props
        this.onSelectForm(selectFormTemplate.formId);
    }
    onSelectForm = (e) => {
        const { listFormComponents } = this.state
        const position = []
        const listForm = []
        this.setState({
            selectForm: e,
            isBtnSaveChangeDisabled:true,
        })
        this.setState({
            listFormComponents: [],
            keyComp: 0
        }, () => {
            apiGetFormWorkflow(e).then((response) => {
                let formSection = {}
                this.setState({
                    responseGetForm: response.data,
                    defaultTemplate: response.data.defaultTemplate,
                })
                if (response.data && response.data.sections && response.data.sections.length > 0) {
                    response.data.sections.forEach((section) => {
                        position.push(section.sectionPosition)
                        listForm.push({
                            id: section.sectionType,
                            key: section.sectionPosition,
                            name: section.sectionType,
                            queryForSearch: section.queryForSearch,
                            mandatory: section.mandatory,
                            options: section.options,
                            sectionId: section.sectionId,
                            header: section.header,
                            subheader: section.subheader,
                            sectionVariable: section.sectionVariable,
                            formSection: {},
                        })
                    })
                }
                const uniqueListForm = listForm && listForm && listForm.length > 0 && listForm.sort((listA, listB) => (listA.sectionPosition - listB.sectionPosition))
                this.setState({
                    keyComp: position && position.length && response.data.sections && response.data.sections.length && position.includes(response.data.sections.length) ? response.data.sections.length : response.data.sections !== null ? response.data.sections.length : 1,
                    valueForm: response.data.formName,
                    selectFormName: response.data.formName,
                    formId: response.data.formId,
                    listFormComponents: uniqueListForm
                })
            })
            // this.setState({
            //     listFormComponents: uniqueListForm
            // })
        })
    }
    onMouseEnterActions = (e, status) => {
        switch (status) {
            case 'back':
                this.setState({
                    onHoverBack: true
                })
                break
        }
    }
    onMouseLeaveActions = () => {
        this.setState({
            onHoverBack: false
        })
    }
    onCLickCancelForm = (e) => {
        const { formId, selectForm, responseGetForm } = this.state
        const { selectFormTemplate } = this.props
        if (selectFormTemplate && selectFormTemplate.formId) {
            this.setState({
                listFormComponents: [],
                keyComp: 0,
                valueForm: '',
                selectFormName: '',
                formId: null,
                selectForm: null,
                responseSaveEmail: false
            }, () => {
                this.onSelectForm(selectFormTemplate.formId)
            })
        } else {
            this.setState({
                valueForm: '',
                selectFormName: '',
                listFormComponents: [],
                responseSaveEmail: false
            })
        }
        // if (formId) {
        //     const position = []
        //     const listForm = []
        //     this.setState({
        //         listFormComponents: [],
        //         keyComp: 0,
        //         valueForm: '',
        //         selectFormName: '',
        //         formId: null,
        //         selectForm: null,
        //         responseSaveEmail: false
        //     }, () => {
        //         // if (responseGetForm && responseGetForm.sections && responseGetForm.sections.length > 0) {
        //         //     responseGetForm.sections.forEach((section) => {
        //         //         position.push(section.sectionPosition)
        //         //         listForm.push({
        //         //             id: section.sectionType,
        //         //             key: section.sectionPosition,
        //         //             name: section.sectionType,
        //         //             queryForSearch: section.queryForSearch,
        //         //             mandatory: section.mandatory,
        //         //             options: section.options,
        //         //             sectionId: section.sectionId
        //         //         })
        //         //     })
        //         // }
        //         // const uniqueListForm = listForm && listForm && listForm.length > 0 && listForm.sort((listA, listB) => (listA.sectionPosition - listB.sectionPosition))
        //         // this.setState({
        //         //     keyComp: position && position.length && responseGetForm.sections && responseGetForm.sections.length && position.includes(responseGetForm.sections.length) ? responseGetForm.sections.length : responseGetForm.sections !== null ? responseGetForm.sections.length : 1,
        //         //     valueForm: responseGetForm.formName,
        //         //     selectFormName: responseGetForm.formName,
        //         //     formId: responseGetForm.formId,
        //         //     listFormComponents: uniqueListForm
        //         // })
        //     })
        // } else {
        //     this.setState({
        //         valueForm: '',
        //         selectFormName: '',
        //         listFormComponents: [],
        //         responseSaveEmail: false
        //     })
        // }
    }
    onCLickSaveForm = (e) => {
        e.preventDefault()
        const { valueForm, listFormComponents } = this.state
        const { clientId } = this.props
        const sectionForm = []
        this.setState({
            checkStartSaved: true,
            responseRequestForm: true,
            loadingButtonSaveForm: true,
        }, () => {
            listFormComponents && listFormComponents.length > 0 && listFormComponents.forEach((list) => {
                const optionList = []
                list.formSection.options && list.formSection.options.length > 0 && list.formSection.options.forEach((option) => {
                    optionList.push({
                        optionText: option.optionText
                    })
                })
                sectionForm.push({
                    sectionType: list.id,
                    queryForSearch: list.formSection.queryForSearch,
                    mandatory: list.formSection.mandatoryCheck,
                    sectionPosition: list.key,
                    options: optionList,
                    header: list.header,
                    subheader: list.subheader ? list.subheader : null,
                    sectionVariable: list.sectionVariable ? list.sectionVariable : null,
                })

            })
            const createFormWorkflowDTO = {
                formName: valueForm,
                sections: sectionForm
            }
            apiCreateDefaultFormWorkflow(createFormWorkflowDTO).then((response) => {
                this.setState({
                    checkStartSaved: false,
                    responseSaveEmail: true,
                    responseRequestForm: false,
                    loadingButtonSaveForm: false,
                    formId: response.data.formId
                    // isBtnSaveNewFormDisabled:true,
                })
                if(response.data.messageTextResponseDTO.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.messageTextResponseDTO.message, 'Success')
                } else if(response.data.messageTextResponseDTO.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.messageTextResponseDTO.message, 'Info')
                }
                // if (response.data === 'A form with this name already exists!') {
                //     CustomNotificationManager.info('A form with this name already exists!')
                // }
                //  if (response.data === 'A variable with this name already exists!') {
                //      CustomNotificationManager.info('A variable with this name already exists!')
                //  }
                //  if (response.data === 'Form saved successfully!') {
                //      CustomNotificationManager.success('Form saved successfully!')
                //  }
                apiGetDefaultFormListWorkflow().then((response) => {
                    this.setState({
                        listForms: response.data
                    })
                })
            })
        })
    }
    onCLickEditForm = (e) => {
        e.preventDefault()
        const { valueForm, listFormComponents, formId } = this.state
        const { clientId } = this.props
        const sectionForm = []
        this.setState({
            checkStartSaved: true,
            responseRequestForm: true,
            loadingButtonSaveForm: true,
        }, () => {
            listFormComponents && listFormComponents.length > 0 && listFormComponents.forEach((list) => {
                const optionList = []
                list.options && list.options.length > 0 && list.options.forEach((option) => {
                    optionList.push({
                        optionText: option.optionText
                    })
                })
                sectionForm.push({
                    sectionId: list.sectionId ? list.sectionId : null,
                    sectionType: list.id,
                    queryForSearch: list.queryForSearch === undefined ? list.formSection.queryForSearch : list.queryForSearch ,
                    mandatory: list.mandatory === undefined ? list.formSection.mandatoryCheck : list.mandatory,
                    sectionPosition: list.key,
                    options: optionList,
                    header: list.header,
                    subheader: list.subheader ? list.subheader : null,
                    sectionVariable: list.sectionVariable ? list.sectionVariable : null,
                })

            })
            const createFormWorkflowDTO = {
                formId: formId,
                formName: valueForm,
                sections: sectionForm
            }
            apiEditDefaultFormWorkflow(createFormWorkflowDTO).then((response) => {
                this.setState({
                    checkStartSaved: false,
                    isBtnSaveChangeDisabled:true,
                    responseRequestForm: false,
                    loadingButtonSaveForm: false,
                })
                if (response.data === 'A variable with this name already exists!') {
                    CustomNotificationManager.info('A variable with this name already exists!')
                }
                if (response.data === 'Form saved successfully!') {
                    CustomNotificationManager.success('Form saved successfully!')
                }
                apiGetDefaultFormListWorkflow().then((response) => {
                    this.setState({
                        listForms: response.data
                    })
                })
            })
        })
    }
    onChangeInputForm = (e) => {
        this.setState({
            valueForm: e.target.value,
            isBtnSaveChangeDisabled:false,
            // isBtnSaveNewFormDisabled:false,
        })
    }
    onClickListElement = (e, item) => {
        let { listFormComponents, keyComp } = this.state

        if(listFormComponents == false)
            listFormComponents = [];
        switch(item.id) {
            case 'Header':
                listFormComponents.push({id: 'header', key:keyComp, name: 'header'})
                break
            case 'Short text':
                listFormComponents.push({id: 'short-text', key: keyComp, name: 'short-text'})
                break
            case 'Long text':
                listFormComponents.push({id: 'long-text', key: keyComp, name: 'long-text'})
                break
            // case 'Static Text':
            //     listFormComponents.push({id: 'static-text', key: keyComp, name: 'static-text'})
            //     break
            case 'Dropdown':
                listFormComponents.push({id: 'dropdown-select', key: keyComp, name: 'dropdown-select'})
                break
            case 'Single Choice':
                listFormComponents.push({ id: 'radio', key: keyComp, name: 'radio' })
                break
            case 'Multiple Choice':
                listFormComponents.push({ id: 'multiple-choice', key: keyComp, name: 'multiple-choice' })
                break
            // case 'Multiple Choice Personal Data':
            //     listFormComponents.push({ id: 'multiple-choice-personal-data', key: keyComp, name: 'multiple-choice-personal-data' })
            //     break
            // case 'Multiple Choice Recipients':
            //     listFormComponents.push({ id: 'multiple-choice-recipients', key: keyComp, name: 'multiple-choice-recipients' })
            //     break
            case 'Email':
                listFormComponents.push({ id: 'email', key: keyComp, name: 'email' })
                break
            case 'Addresses':
                listFormComponents.push({ id: 'address', key: keyComp, name: 'address' })
                break
            case 'Phone':
                listFormComponents.push({ id: 'phone', key: keyComp, name: 'phone' })
                break
            case 'Date':
                listFormComponents.push({ id: 'upload', key: keyComp, name: 'upload'})
                break
            case 'Numbers-Full':
                listFormComponents.push({ id: 'fullName', key: keyComp, name: 'fullName'})
                break
            // case 'Paragraph':
            //     listFormComponents.push({id: 'paragraph', key: keyComp, name: 'paragraph'})
            //     break
            case 'Signature':
                listFormComponents.push({id: 'signature', key: keyComp, name: 'signature'})
                break
            default: break
        }
        this.setState({
            listFormComponents,
            keyComp: keyComp + 1,
            isBtnSaveChangeDisabled:false,
        })
    }
    onGenerateComponentsForm = (component, index, snapshot, onStateProvidedDraggable, onLeaveProvidedDraggable, onChangeForm, checkStartSaved, defaultTemplate) => {
        switch(component.name) {
            case 'header':
                return <HeaderForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                   onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                   onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'fullName':
                return <FullNameForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                     onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                     onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'address':
                return <AddressForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                    onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                    onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'email':
                return <EmailForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                  onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                  onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'phone':
                return <PhoneForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                  onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                  onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'purposeProcess':
                return <PurposeProcessForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                           onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                           onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'radio':
                return <SubjectVerificationForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                                onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                                onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'dropdown':
                return <DropdownCategoriesForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                               onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                               onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'upload':
                return <FileUploadForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                       onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                       onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'signature':
                return <SignatureForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                      onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                      onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            // case 'paragraph':
            //     return <ParagraphForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
            //                           onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
            //                           onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved }/>
            //     break
            case 'multiple-choice':
                return <MultipleChoiceIndividualsForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                                      onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                                      onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            // case 'multiple-choice-personal-data':
            //     return <MultipleChoicePersonalDataForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
            //                                           onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
            //                                           onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved }/>
            //     break
            // case 'multiple-choice-recipients':
            //     return <MultipleChoiceRecipientsForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
            //                                            onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
            //                                            onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved }/>
            //     break
            case 'dropdown-select':
                return <DropdownForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                     onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                     onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'short-text':
                return <ShortTextForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                      onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                      onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved }  snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            case 'long-text':
                return <LongTextForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
                                     onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
                                     onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved } snapshot={snapshot} defaultTemplate={defaultTemplate}/>
                break
            // case 'static-text':
            //     return <StaticTextForm component={component} showConfirmDeleteFormElement={ this.showConfirmDeleteFormElement }
            //                          onStateProvidedDraggable={onStateProvidedDraggable} onLeaveProvidedDraggable={onLeaveProvidedDraggable}
            //                          onChangeForm={ onChangeForm } checkStartSaved={ checkStartSaved }/>
            //     break
            default: break
        }
    }
    showConfirmDeleteFormElement = (e, component) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete form element?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className:'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onActionDeleteComponent(e, component)
            }
        })
    }
    onActionDeleteComponent = (e, component) => {
        const { listFormComponents } = this.state
        if (listFormComponents && listFormComponents.length > 0) {
            let filtersForm = []
            filtersForm = listFormComponents.filter((elem) => elem.key !== component.key)

            this.setState({
                listFormComponents: filtersForm,
                isBtnSaveChangeDisabled:false,
            })
        }
    }
    handleDropComponent = (droppedItem) => {
        if (!droppedItem.destination) return
        const updatedList = [...this.state.listFormComponents];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        updatedList && updatedList.length > 0 && updatedList.forEach((item, index) => {
            item.key = index
        })
        this.setState({
            listFormComponents: updatedList,
            isBtnSaveChangeDisabled:false,
        })
    }
    onProvidedDraggable = (provided) => {
        return {...provided.draggableProps}
    }
    onStateProvidedDraggable = (state) => {
        this.setState({
            actionProvidedDraggable: state
        })
    }
    onLeaveProvidedDraggable = (state) => {
        this.setState({
            actionProvidedDraggable: {}
        })
    }
    onChangeForm = (form, component) => {
        const { listFormComponents } = this.state
        listFormComponents && listFormComponents.length > 0 &&  listFormComponents.forEach((list) => {
            if (list.key === component.key) {
                list.formSection = form
                list.options = form.options
                list.mandatory = form.mandatoryCheck
                list.queryForSearch = form.queryForSearch
                list.header = form.header
                list.subheader = form.subheader
                list.sectionVariable = form.sectionVariable
            }
        })
        this.setState({
            listFormComponents,
            isBtnSaveChangeDisabled:false,
            // isBtnSaveNewFormDisabled:false,
        })
    }
    showConfirmDeleteForm = (e) => {
        const { valueForm, selectForm, defaultTemplate } = this.state
        if (selectForm !== null && defaultTemplate !== true) {
            customConfirm({
                title: <span className="icon-v14-delete"></span>,
                content: `Delete form ${valueForm}?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal modal-delete-connector',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.onDeleteForm(e)
                }
            })
        }
    }
    onDeleteForm = (e) => {
        const { selectForm } = this.state
        e.preventDefault()
        e.stopPropagation()
        if (selectForm !== null) {
            apiDeleteFormWorkflow(selectForm).then((response) => {
                this.setState({
                    listFormComponents: [],
                    valueForm: '',
                    selectForm: null,
                    selectFormName: '',
                    formId: '',
                    responseSaveEmail: false
                })
                apiGetFormBuilderListWorkflow().then((response) => {
                    this.setState({
                        listForms: response.data,
                    })
                })
            })
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }

    onHoverActionCopy = () => {
        this.setState({
            hoverCopy: true
        })
    }
    onLeaveActionCopy = () => {
        this.setState({
            hoverCopy: false
        })
    }
    onHoverBtnAdd = () => {
        this.setState({
            hoverBtnAdd: true
        })
    }
    onLeaveBtnAdd = () => {
        this.setState({
            hoverBtnAdd: false
        })
    }
    onActionCopy = () => {
        const { selectForm } = this.state
        const { clientId } = this.props
        const formIds = []
        formIds.push(selectForm)
        const createDuplicateFormWorkflowDTO = {
            clientId: clientId,
            formIds: formIds
        }
        apiCreateDuplicateFormWorkflow(createDuplicateFormWorkflowDTO).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            apiGetFormBuilderListWorkflow().then((response) => {
                this.setState({
                    listForms: response.data
                })
            })
        })
    }

    onClickBackWorkflow = () => {
        this.props.onBackFormBuilderUi()
    }

    render() {
        const { onHoverBack,valueForm, listFormElements, listFormComponents, actionProvidedDraggable, listForms, selectForm, selectFormName, hoverDelete, hoverBtnAdd, formId,
            checkStartSaved, responseSaveEmail, responseRequestForm, defaultTemplate, hoverCopy, loadingButtonSaveForm} = this.state
        const { selectFormTemplate } = this.props
        const uniqueListForm = listFormComponents && listFormComponents.length > 0 ? listFormComponents.sort((listA, listB) => (listA.key - listB.key)) : []
        return (
            <div className="forms-workflow">
                <div className="forms-workflow__page">
                    <header className="header-forms-workflow">
                        <span className={ onHoverBack ? 'icon-v21-back-active' : "icon-v17-back-1" }
                              onClick={ () => this.onClickBackWorkflow()}
                              onMouseEnter={(e) => this.onMouseEnterActions(e, 'back')}
                              onMouseLeave={() => this.onMouseLeaveActions()}></span>
                        <span className="name-forms-workflow">{selectFormTemplate.formName}</span>

                        <div className="header-form">
                            <div className="action-header-forms">
                                <Button className="button-cancel-template" onClick={(e) => this.onCLickCancelForm(e)}>Cancel</Button>
                                {
                                    (formId || responseSaveEmail) ?
                                        <Button className="button-save-template" onClick={(e) => this.onCLickEditForm(e)}
                                                disabled={ responseRequestForm === true || this.state.isBtnSaveChangeDisabled || listFormComponents.length === 0 || valueForm === ''}
                                                loading={loadingButtonSaveForm}>Save</Button> :
                                        <Button className="button-save-template" onClick={(e) => this.onCLickSaveForm(e)}
                                                disabled={ responseRequestForm === true || listFormComponents.length === 0 || valueForm === ''}
                                                loading={loadingButtonSaveForm}>Save</Button>
                                }
                            </div>
                        </div>

                    </header>
                    <div className="page-form-content">
                        <div className="form-elements-content">
                            <div><b className="form-elements">Form elements</b></div>
                            <List
                                bordered
                                dataSource={listFormElements}
                                className="myListBordered"
                                renderItem={item => (
                                    <List.Item onClick={(e) => this.onClickListElement(e, item)}>{item.name}
                                    </List.Item>
                                )}
                            />
                        </div>
                        <div className="form-builder-content">
                            <DragDropContext onDragEnd={this.handleDropComponent}>
                                <Droppable droppableId="list-container">
                                    {(provided) => (
                                        <div
                                            className="list-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >

                                            {
                                                listFormComponents && listFormComponents.length > 0 ?
                                                    listFormComponents.map((component, index) => {
                                                        return (
                                                            <Draggable key={component.key} draggableId={`${component.key}`} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        id={index}
                                                                        className="item-container"
                                                                        ref={provided.innerRef}
                                                                        {...provided.dragHandleProps}
                                                                        {...this.onProvidedDraggable(provided)}
                                                                    >
                                                                        {/*{...actionProvidedDraggable.key === component.key && {...this.onProvidedDraggable(provided)}}*/}
                                                                        {
                                                                            this.onGenerateComponentsForm(component, index, snapshot ,this.onStateProvidedDraggable, this.onLeaveProvidedDraggable,
                                                                                this.onChangeForm, checkStartSaved, defaultTemplate)
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }) :
                                                    (
                                                        < Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    )

                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {/*<HeaderForm />*/}
                            {/*<FullNameForm />*/}









                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default connect(mapStateToProps)(FormBuilderUiAdmin)