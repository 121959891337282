import React from "react";
import './ApiConnector.scss';
import {Table, Modal, Select, Button, Input,Form, Checkbox} from "antd";
import {customConfirm} from "../../utils/AssetRegister/CustomConfirm";
import {
    apiExternalApiCopyToken,
    apiExternalApiCreateToken,
    apiExternalApiDeleteToken, apiExternalApiEditToken,
    getExternalApiAvaiableToken,
    getExternalApiTokenList, getExternalApiTokenListId
} from "../../api/SmartBox";
import {connect} from "react-redux";
import CustomNotificationManager from "../Common/CustomNotificationManager";
import moment from 'moment-timezone'
const { Option } = Select;
const { Column } = Table;

class ApiConnector extends React.Component {
    state = {
       // dateTableApiConnector: [
       //     {id: 0, tokenName: 'Preprod for H', key: '23213bnbxzcw2d', dateExpires: '21/05/2024'},
       //     {id: 1, tokenName: 'Preprod for N', key: 'fgtt54f-3432-324324f', dateExpires: '11/05/2024'},
       //     {id: 2, tokenName: 'Preprod for M', key: 'dgjjjjj-3432-324324f', dateExpires: '11/05/2024'},
       //     {id: 3, tokenName: 'Preprod for S', key: 'aaaaa-3432-324324f', dateExpires: 'expired'},
       // ],
        dateTableApiConnector: [],
        hoverAdd: false,
        hoverDelete: {},
        hoverEdit: {},
        modalVisible: false,
        // listTokenAccess: [
        //     {id: 0, name: 'Send files to smartbox'},
        //     {id: 1, name: 'List Boxes'},
        //     {id: 2, name: 'Create box'},
        //     {id: 3, name: 'Query status of box'},
        //     {id: 4, name: 'List Box content'},
        //     {id: 5, name: 'Query status of document'},
        //     {id: 6, name: 'Download original file'},
        //     {id: 7, name: 'Download audit copy of file'},
        //     {id: 8, name: 'Trigger bulk redaction'},
        //     {id: 9, name: 'Trigger de-threading'},
        //     {id: 10, name: 'Trigger deduplication'},
        //     {id: 11, name: 'Trigger near-deduplication'},
        // ],
        listTokenAccess: [],
        copyValueKey: '',
        formToken: {
           nameInputToken: '',
            selectExpiresValue: '',
            selectListAccess: []
        },
        statusModal: '',
        selectToken: {},
        disabledAddConnector: false,
        pageNumber: 1,
        pageRows: 10,
        checkedSelectAll: false,
        listKeys: []
   }
    componentDidMount() {
      this.onGetAvaiableToken()
      this.onGetExternalApiTokenList()
    }

    onGetExternalApiTokenList = () => {
        const { pageNumber, pageRows} = this.state
        getExternalApiTokenList(pageNumber, pageRows).then((response) => {
          this.setState({
              dateTableApiConnector: response.data.tokenDTOS,
              totalToken: response.data.total
          })
        })
    }
     onGetAvaiableToken = () => {
         getExternalApiAvaiableToken().then((response) => {
             this.setState({
                 listTokenAccess: response.data
             })
         })
     }

    onHoverAddButton = () => {
        this.setState({
            hoverAdd: true
        })
    }
    onLeaveAddButton = () => {
        this.setState({
            hoverAdd: false
        })
    }
    onHoverDeleteButton = (e, item) => {
        this.setState({
            hoverDelete: item
        })
    }
    onLeaveDeleteButton = () => {
        this.setState({
            hoverDelete: {}
        })
    }
    onHoverEditButton = (e, item) => {
        this.setState({
            hoverEdit: item
        })
    }
    onLeaveEditButton = () => {
        this.setState({
            hoverEdit: {}
        })
    }
    onEditToken = (e, record) => {
        this.onGetTokenAPILis(record)
    }
    onGetTokenAPILis = (record) => {
        const { listTokenAccess } = this.state
        let { selectListAccess } = this.state.formToken
        const keysAllKeys = []
        getExternalApiTokenListId(record.tokenId).then((response) => {
            listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
                response.data && response.data.length > 0 && response.data.forEach((item2) => {
                    if (item.apicode === item2.apiCode) {
                       item.checked = true
                        selectListAccess.push(item)
                    }
                })
            })
            if (listTokenAccess && listTokenAccess.length > 0 && response.data && response.data.length > 0 && listTokenAccess.length === response.data.length) {
                this.setState({
                    checkedSelectAll: true
                })
            }
            listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
                if (item.checked) {
                    keysAllKeys.push(item.apicode)
                }
            })
            this.setState({
                modalVisible: true,
                statusModal: 'edit',
                selectToken: record,
                formToken: {
                    nameInputToken: record.tokenName,
                    selectListAccess: selectListAccess,
                    selectExpiresValue: record.tokenActiveDays,
                    tokenId: record.tokenId,
                },
                listTokenAccess,
                listKeys: keysAllKeys
            })
        })
    }
    showConfirmDeleteAccessToken = (e, record) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete token?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className:'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onRemoveAccessToken(e, record)
            }
        })
    }
    onRemoveAccessToken = (e, record) => {
      let { dateTableApiConnector } = this.state
        // dateTableApiConnector = dateTableApiConnector.filter((list) => list.tokenName !== record.tokenName)
        // this.setState({
        //     dateTableApiConnector
        // })
        const tokenIds = []
        tokenIds.push(record.tokenId)
        apiExternalApiDeleteToken(tokenIds).then((response) => {
            if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.onGetExternalApiTokenList()
        })
    }
    displayAddNewConnectorModal = (e, status) => {
       this.setState({
           modalVisible: true,
           statusModal: 'create'
       })
    }
    onCancelModalVisible = () => {
        const { listTokenAccess } = this.state
        listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
                item.checked = false
        })
        this.setState({
            modalVisible: false,
            checkedSelectAll: false,
            formToken: { nameInputToken: '',
                selectExpiresValue: '',
                selectListAccess: [] },
            listTokenAccess
        })
    }
    onCopyKey = (e, record) => {
    const tokenId = record.tokenId
    const tokenKey = record.tokenKey
        const dtoCopy = {
            tokenId: tokenId
        }

        if (record.expirationDate !== 'expired' && record.tokenCopied !== true) {
            apiExternalApiCopyToken(dtoCopy).then((response) => {
                if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                navigator.clipboard.writeText(tokenKey)
                this.onGetExternalApiTokenList()
            })
            this.setState({
                copyValueKey: record.tokenId
            })
        }
    }
    onRowClassName = (record, index) => {
        if (record.expirationDate === 'expired') {
            return 'row-class-expired'
        }
    }
    onInputTokenName = (e) => {
        this.setState({
            formToken: {...this.state.formToken, nameInputToken: e.target.value}
        })
    }
    onSelectExpires = (e) => {
        this.setState({
            formToken: {...this.state.formToken, selectExpiresValue: e}
        })
    }
    onChangeTokenAccess = (e, list) => {
        const { listTokenAccess } = this.state
        e.stopPropagation()
        const keysAllKeys = []
        let { selectListAccess } = this.state.formToken
        if(e.target.checked) {
            selectListAccess.push(list.name)
            listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
               if (list.apiname === item.apiname) {
                   item.checked = true
               }
            })
        } else {
            listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
                if (list.apiname === item.apiname) {
                    item.checked = false
                }
            })
            selectListAccess = selectListAccess.filter(item => item !== list.name)
        }
        this.setState({
            formToken: {...this.state.formToken, selectListAccess: selectListAccess},
            listTokenAccess
        }, () => {
            listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
                if (item.checked) {
                    keysAllKeys.push(item.apicode)
                }
            })
            this.setState({
                listKeys: keysAllKeys
            })
            if (listTokenAccess.length === keysAllKeys.length) {
              this.setState({
                  checkedSelectAll: true
              })
            } else {
                this.setState({
                    checkedSelectAll: false
                })
            }
        })
    }
    onAddAccessToken = () => {
      const { formToken, listTokenAccess, dateTableApiConnector, statusModal } = this.state
        const tokenAPIList = []
        listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
            if (item.checked) {
                tokenAPIList.push({
                    apiCode: item.apicode,
                    allowAccess: item.checked,
                })
            }

        })
        if (statusModal === 'create') {
            const createExternalTokenDTO = {
                tokenName: formToken.nameInputToken,
                clientId: this.props.clientId,
                tokenActiveDays: formToken.selectExpiresValue === 'Never' ? 9999 : formToken.selectExpiresValue,
                tokenAPIList: tokenAPIList
            }
            this.setState({
                disabledAddConnector: true
            }, () => {
                apiExternalApiCreateToken(createExternalTokenDTO).then((response) => {
                    this.onGetExternalApiTokenList()
                    if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
                        item.checked = false
                    })
                    this.setState({
                        modalVisible: false,
                        disabledAddConnector: false,
                        formToken: {
                            nameInputToken: '',
                            selectExpiresValue: '',
                            selectListAccess: []
                        },
                        listTokenAccess
                    })
                })
            })
        } else if (statusModal === 'edit') {
            const editExternalTokenDTO = {
                tokenName: formToken.nameInputToken,
                tokenId: formToken.tokenId,
                clientId: this.props.clientId,
                tokenActiveDays: formToken.selectExpiresValue === 'Never' ? 9999 : formToken.selectExpiresValue,
                tokenAPIList: tokenAPIList,
            }
            this.setState({
                disabledAddConnector: true
            }, () => {
                apiExternalApiEditToken(editExternalTokenDTO).then((response) => {
                    this.onGetExternalApiTokenList()
                    if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                        listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
                            item.checked = false
                        })
                        this.setState({
                            formToken: { nameInputToken: '',
                                selectExpiresValue: '',
                                selectListAccess: [] },
                            listTokenAccess
                        })
                    } else if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    this.setState({
                        modalVisible: false,
                        disabledAddConnector: false,
                    })
                })
            })
        }
    }

    onPageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page
        }, () => {
            this.onGetExternalApiTokenList()
        })
    }
    selectAllConnectors = (e) => {
        const { listTokenAccess, listKeys } = this.state
        let listAllKeys = []
        listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.forEach((item) => {
            if (e.target.checked) {
                item.checked = true
                listAllKeys.push(item.apicode)
            } else {
                item.checked = false
                listAllKeys = []
            }
        })
        this.setState({
            listTokenAccess,
            checkedSelectAll: e.target.checked,
            listKeys: listAllKeys
        })
    }
    onTimezoneConvert = (time) => {
        const localTimeZone = moment.tz.guess()
        const localDate = moment.utc(time).tz(localTimeZone)
        return `${ moment(localDate).format('YYYY-MM-DD HH:mm') }`
    }
    render(){
        const { dateTableApiConnector, hoverAdd, hoverDelete, modalVisible, listTokenAccess, copyValueKey, formToken, hoverEdit, statusModal, disabledAddConnector,
            pageRows, totalToken, checkedSelectAll, listKeys } = this.state
        return (
            <div className="api-connector-page">
                <div className="api-connector-page__content">
                    <div className="api-connector-header">
                        <span className="title-header">Access tokens</span>
                    </div>
                    <div className="content-api-connector-page">
                        <div className="content-api-connector-table">
                            <div className="btn-container">
                                <button className={hoverAdd ? "icon-v17-add-active":"icon-v17-add"}
                                        onMouseEnter={(e) => this.onHoverAddButton(e)}
                                        onMouseLeave={() => this.onLeaveAddButton()}
                                        onClick={(e) => this.displayAddNewConnectorModal(e, 'create')}>
                                </button>
                            </div>
                            <div className="table-api-connector">
                                <Table
                                    //pagination={this.state.pagination}
                                    dataSource={ dateTableApiConnector }
                                    className="ant-table-filter-dropdown-source"
                                    rowClassName={(record, rowIndex) => this.onRowClassName(record, rowIndex)}
                                    rowKey = { (record) => { return `rowKey${ record.tokenId }` }}
                                    pagination={{
                                        onChange: this.onPageChange,
                                        pageSize: pageRows,
                                        total: totalToken
                                    }}
                                >
                                    <Column className="column-api"
                                            title = 'Token name'
                                            dataIndex="tokenName" key="tokenName"
                                            sorter = {(a, b) => console.log()}
                                            defaultSortOrder = {['descend']}
                                            render={(text, record) => {
                                                return (
                                                    <span className="text-error-report text-provider">{ text }</span>
                                                )
                                            }} />
                                    <Column className="column-api"
                                            title = 'Key'
                                            dataIndex="tokenKey" key="tokenKey"
                                            render={(text, record) => {
                                                return (
                                                    <span><span className="text-error-report text-provider">
                                                        { text.length > 30 ? `${ text.substring(0, 30) }...`  : text }</span>
                                                        <span className="icon-v211-copy"
                                                        onClick={(e) => this.onCopyKey(e, record)}></span>
                                                    </span>
                                                )
                                            }} />
                                    <Column className="column-error-report"
                                            title = 'Expires'
                                            dataIndex="expirationLocalDateTime" key="expirationLocalDateTime"
                                            render={(text, record) => {
                                                return (
                                                    <span id="copyTextClip" className="text-error-report text-provider">{ text ? this.onTimezoneConvert(text) : '' }</span>
                                                )
                                            }} />
                                    <Column className="column-error-report"
                                            title = ''
                                            dataIndex="tokenId" key="tokenId2"
                                            render={(text, record) => {
                                                return (
                                                    <button className={ hoverEdit.tokenId === text ? "icon-v17-edit-active" : "icon-v17-edit"}
                                                            onMouseEnter={(e) => this.onHoverEditButton(e, record, 'edit')}
                                                            onMouseLeave={() => this.onLeaveEditButton()}
                                                            disabled={record.expirationDate === 'expired'}
                                                            onClick={(e) => this.onEditToken(e, record)}>
                                                    </button>
                                                )
                                            }} />
                                    <Column className="column-error-report"
                                            title = ''
                                            dataIndex="tokenId" key="tokenId"
                                            render={(text, record) => {
                                                return (
                                                    <button className={ hoverDelete.tokenId === text ? "icon-v17-delete-active" : "icon-v17-delete"}
                                                            onMouseEnter={(e) => this.onHoverDeleteButton(e, record, 'delete')}
                                                            onMouseLeave={() => this.onLeaveDeleteButton()}
                                                            onClick={(e) => this.showConfirmDeleteAccessToken(e, record)}>
                                                    </button>
                                                )
                                            }} />
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="api-connector-modal" title={<div className="header-connector-modal"><span>{statusModal === 'edit' ? 'Edit token' : 'New token'}</span></div>} visible={ modalVisible} footer={null} closable={ false }>
                    <div className="content-api-connector-modal">

                        <div className="api-connector-modal-text-field">
                            <div className="title-text-field">Token name</div>
                            <Input className="input-token-name"
                                   value={ formToken.nameInputToken }
                            onChange={(e) => this.onInputTokenName(e)}></Input>
                        </div>
                        <div className="api-connector-modal-text-field">
                            <div className="title-text-field">Expires in</div>
                            <Select
                                className="select-expires"
                                getPopupContainer={trigger => trigger.parentNode}
                                dropdownClassName={'select-expiration-period'}
                                onChange={(e) => this.onSelectExpires(e)}
                                value={ formToken.selectExpiresValue }>
                                <Option key={1} value={30}>{'30 days'}</Option>
                                <Option key={2} value={60}>{'60 days'}</Option>
                                <Option key={3} value={90}>{'90 days'}</Option>
                                <Option key={4} value={180}>{'180 days (default)'}</Option>
                                <Option key={5} value={9999}>{'Never'}</Option>
                            </Select>
                        </div>
                        <div className="api-connector-modal-text-field">
                            <div className="title-text-field">Allow token access to:</div>
                            <div className={listKeys && listKeys.length > 0 && listKeys.length < listTokenAccess.length ? "selected-all-connector selected-children-list" : 'selected-all-connector' }>
                                <Checkbox checked={checkedSelectAll} onChange={(e) => this.selectAllConnectors(e)}>Select all</Checkbox>
                            </div>
                            <div className="content-allow-token">
                            { listTokenAccess && listTokenAccess.length > 0 && listTokenAccess.map((list) => {
                                return (
                                    <Checkbox
                                        key={list.apiname}
                                        checked={list.checked}
                                        onChange={(e) => this.onChangeTokenAccess(e, list)}>{list.apiname}</Checkbox>
                                )
                            })

                            }
                            </div>
                        </div>
                        <div className="api-connector-modal-buttons">
                            <Button onClick={ () => this.onCancelModalVisible() }>Cancel</Button>
                            <Button type="primary" onClick={() => this.onAddAccessToken()}
                            disabled={ disabledAddConnector === true }>{statusModal === 'edit' ? 'Edit' : 'Add' }</Button>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    client: state.subscriptionReducer.subscription.subscription.client,
})
export default connect(mapStateToProps, {
})(ApiConnector)