export const ANSWER_HIDE_RESPONSE = 'ANSWER_HIDE_RESPONSE'
export const ANSWER_SHOW_RESPONSE = 'ANSWER_SHOW_RESPONSE'
export const ANSWER_UPDATE_VALUE_FOR_CHECKBOX = 'ANSWER_UPDATE_VALUE_FOR_CHECKBOX'
export const ANSWER_UPDATE_VALUE_FOR_OTHER = 'ANSWER_UPDATE_VALUE_FOR_OTHER'
export const ANSWER_UPDATE_VALUE_FOR_RADIO = 'ANSWER_UPDATE_VALUE_FOR_RADIO'
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS'
export const FETCH_USER_INFO = 'FETCH_USER_INFO'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const MANAGE_GROUPS_SHOW_LOADING = 'MANAGE_GROUPS_SHOW_LOADING'
export const QUESTIONS_CALCULATE_PROGRESS = 'QUESTIONS_CALCULATE_PROGRESS'
export const QUESTIONS_SET_ASSESSMENT_ID = 'QUESTIONS_SET_ASSESSMENT_ID'
export const QUESTIONS_UPDATE_QUESTION = 'QUESTIONS_UPDATE_QUESTION'
export const RECEIVE_ACTIVE_QUESTION = 'RECEIVE_ACTIVE_QUESTION'
export const RECEIVED_ASSESSMENT = 'RECEIVED_ASSESSMENT'
export const RECEIVED_ASSESSMENT_ERROR = 'RECEIVED_ASSESSMENT_ERROR'
export const RECEIVED_ASSIGNED_USER = 'RECEIVED_ASSIGNED_USER'
export const RECEIVED_PROCESS = 'RECEIVED_PROCESS'
export const RECEIVE_MANAGE_GROUPS_LIST = 'RECEIVE_MANAGE_GROUPS_LIST'
export const RECEIVE_SUBSCRIPTION_INFO = 'RECEIVE_SUBSCRIPTION_INFO'
export const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS'
export const RECEIVE_USER_INFO = 'RECEIVE_USER_INFO'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const SET_TO_LOADING_QUESTIONS_SCREEN = 'SET_TO_LOADING_QUESTIONS_SCREEN'
export const SET_TO_LOADING_PIA_RESULT_SCREEN = 'SET_TO_LOADING_PIA_RESULT_SCREEN'
export const SET_CURRENT_USER_AS_ASSIGNED = 'SET_CURRENT_USER_AS_ASSIGNED'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'
export const UNDO_SKIPPED_QUESTIONS = 'UNDO_SKIPPED_QUESTIONS'
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const GOD_ADMIN_MODE = 'GOD_ADMIN_MODE'
export const SAR_VISIBLE = 'SAR_VISIBLE'
export const SMART_BOX_VISIBLE = 'SMART_BOX_VISIBLE'
export const DOC_LOCKER_VISIBLE = 'DOC_LOCKER_VISIBLE'
export const ASSET_REGISTER_VISIBLE = 'ASSET_REGISTER_VISIBLE'
export const CHANGE_FORM = 'CHANGE_FORM'
export const LOAD_SAR_FOR_USER = 'LOAD_SAR_FOR_USER'
export const LOAD = 'LOAD'
export const SET_HEADER_VISIBILITY = 'SET_HEADER_VISIBILITY'
export const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY'
export const SET_FOOTER_VISIBILITY = 'SET_FOOTER_VISIBILITY'
export const FETCHING_USER_SUBSCRIPTION_DATA_COMPLETE = 'FETCHING_USER_SUBSCRIPTION_DATA_COMPLETE'
export const SET_AI_ANNOTATIONS_STATUS = 'SET_AI_ANNOTATIONS_STATUS'
export const SET_SAR_PATH_AI_READY = 'SET_SAR_PATH_AI_READY'
export const SET_SMART_BOX_UPLOADING_OBJECT = 'SET_SMART_BOX_UPLOADING_OBJECT'
export const FETCH_FOLDER_CONTENT = 'FETCH_FOLDER_CONTENT'
export const SET_SMART_BOX_SEARCHED_TEXT = 'SET_SMART_BOX_SEARCHED_TEXT'
export const SET_SMART_BOX_INTERVAL_FOR_AI_PAGES = 'SET_SMART_BOX_INTERVAL_FOR_AI_PAGES'
export const SET_SMART_BOX_INTERVAL_FOR_PDF_PAGES = 'SET_SMART_BOX_INTERVAL_FOR_PDF_PAGES'
export const REMOVE_SMART_BOX_INTERVAL_FOR_AI_PAGES = 'REMOVE_SMART_BOX_INTERVAL_FOR_AI_PAGES'
export const REMOVE_SMART_BOX_INTERVAL_FOR_PDF_PAGES = 'REMOVE_SMART_BOX_INTERVAL_FOR_PDF_PAGES'
export const DPIA_VISIBLE = 'DPIA_VISIBLE'
export const WORKFLOW_VISIBLE = 'WORKFLOW_VISIBLE'
export const DATA_MAPPING_VISIBLE = 'DATA_MAPPING_VISIBLE'
export const ADDITIONAL_ENVIRONMENTS = 'ADDITIONAL_ENVIRONMENTS'
export const SET_SMART_BOX_VIEW_MODE = 'SET_SMART_BOX_VIEW_MODE'
export const SET_SMART_BOX_SCROLL_POSITION_OBJECT = 'SET_SMART_BOX_SCROLL_POSITION_OBJECT'
export const SET_SMART_BOX_INTERVAL_FOR_BULK_REDACTION = 'SET_SMART_BOX_INTERVAL_FOR_BULK_REDACTION'
export const REMOVE_SMART_BOX_INTERVAL_FOR_BULK_REDACTION = 'REMOVE_SMART_BOX_INTERVAL_FOR_BULK_REDACTION'
export const SET_SMART_BOX_EXPORT_DATA_VISIBILITY = 'SET_SMART_BOX_EXPORT_DATA_VISIBILITY'
export const SET_SMART_BOX_FETCH_TREE_DATA = 'SET_SMART_BOX_FETCH_TREE_DATA'
export const SET_AXIOS_HEADERS_COPY = 'SET_AXIOS_HEADERS_COPY'
export const ADD_INTERVAL_TO_APPLICATION = 'ADD_INTERVAL_TO_APPLICATION'
export const REMOVE_ALL_INTERVAL_TO_APPLICATION = 'REMOVE_ALL_INTERVAL_TO_APPLICATION'
export const SET_SMART_BOX_PROJECTS = 'SET_SMART_BOX_PROJECTS'
export const SET_SMART_BOX_INTERVAL_FOR_BULK_FINALISE = 'SET_SMART_BOX_INTERVAL_FOR_BULK_FINALISE'
export const REMOVE_SMART_BOX_INTERVAL_FOR_BULK_FINALISE = 'REMOVE_SMART_BOX_INTERVAL_FOR_BULK_FINALISE'
export const SET_SMART_BOX_TREE = 'SET_SMART_BOX_TREE'
export const SET_SMART_BOX_CURRENT_FOLDER = 'SET_SMART_BOX_CURRENT_FOLDER'
export const SET_SMART_BOX_LOAD_TREE = 'SET_SMART_BOX_LOAD_TREE'
export const SET_SMART_BOX_NEW_NODE = 'SET_SMART_BOX_NEW_NODE'
export const SET_SMART_BOX_FOLDER_CLOSED = 'SET_SMART_BOX_FOLDER_CLOSED'
export const SET_SMART_BOX_MOVED_FILES = 'SET_SMART_BOX_MOVED_FILES'
export const SET_SMART_BOX_SELECTED_FILES = 'SET_SMART_BOX_SELECTED_FILES'
export const SET_SMART_BOX_DELETED_FOLDER = 'SET_SMART_BOX_DELETED_FOLDER'
export const SET_SMART_BOX_FILES_TABLE = 'SET_SMART_BOX_FILES_TABLE'
export const SET_SMART_BOX_FETCH_FILES = 'SET_SMART_BOX_FETCH_FILES'
export const SET_SMART_BOX_BASE_DATA_TREE = 'SET_SMART_BOX_BASE_DATA_TREE'
export const SET_SMART_BOX_TOGGLE_FILE_PREVIEW = 'SET_SMART_BOX_TOGGLE_FILE_PREVIEW'
export const SET_SMART_BOX_VISIBLE_DIRECTORY_NOTE = 'SET_SMART_BOX_VISIBLE_DIRECTORY_NOTE'
export const SET_SMART_BOX_DOCUMENT_SELECTED_ID = 'SET_SMART_BOX_DOCUMENT_SELECTED_ID'
export const SET_SMART_BOX_RENAMED_FOLDER = 'SET_SMART_BOX_RENAMED_FOLDER'
export const SET_SMART_BOX_ANALYSIS_PERCENT = 'SET_SMART_BOX_ANALYSIS_PERCENT'
export const SET_SMART_BOX_PARENT_FOLDER_EMPTY = 'SET_SMART_BOX_PARENT_FOLDER_EMPTY'
export const SET_SMART_BOX_PARENT_FOLDER_ERRORS = 'SET_SMART_BOX_PARENT_FOLDER_ERRORS'
export const SET_SMART_BOX_PARENT_FOLDER_NAME = 'SET_SMART_BOX_PARENT_FOLDER_NAME'
export const SET_SMART_BOX_FOLDERS_CHECK_ERRORS = 'SET_SMART_BOX_FOLDERS_CHECK_ERRORS'
export const SET_SMART_BOX_DOCUMENT_SELECTED_DUPLICATE = 'SET_SMART_BOX_DOCUMENT_SELECTED_DUPLICATE'
export const SET_SMART_BOX_MOVED_FOLDERS = 'SET_SMART_BOX_MOVED_FOLDERS'
export const SET_SMART_BOX_OLD_PARENT_OF_MOVED_FOLDERS = 'SET_SMART_BOX_OLD_PARENT_OF_MOVED_FOLDERS'
export const SET_SMART_BOX_NEW_PARENT_OF_MOVED_FOLDERS = 'SET_SMART_BOX_NEW_PARENT_OF_MOVED_FOLDERS'
export const SET_SMART_BOX_SECURITY_CONFIGURATION = 'SET_SMART_BOX_SECURITY_CONFIGURATION'
export const SET_SMART_BOX_RESPONSE_DELETE_DUPLICATES = 'SET_SMART_BOX_RESPONSE_DELETE_DUPLICATES'
export const SET_SMART_BOX_PROGRESS_CLEAN_DELETE_DUPLICATES = 'SET_SMART_BOX_PROGRESS_CLEAN_DELETE_DUPLICATES'
export const SET_SMART_BOX_CHECK_FIRST_UPLOAD_FILE = 'SET_SMART_BOX_CHECK_FIRST_UPLOAD_FILE'
export const SET_SMART_BOX_NOTIFICATION_BUTTON_FIRST_UPLOAD = 'SET_SMART_BOX_NOTIFICATION_BUTTON_FIRST_UPLOAD'
export const SET_SMART_BOX_PAGE_LIMIT = 'SET_SMART_BOX_PAGE_LIMIT'
export const SET_LIST_TERMS_PAGE_LIMIT = 'SET_LIST_TERMS_PAGE_LIMIT'
export const SET_OVERVIEW_PAGE_LIMIT = 'SET_OVERVIEW_PAGE_LIMIT'
export const SET_BUSINESS_TERMS_PAGE_LIMIT = 'SET_BUSINESS_TERMS_PAGE_LIMIT'
export const SET_SAR_PAGE_LIMIT = 'SET_SAR_PAGE_LIMIT'
export const SET_SMART_BOX_DATA_READY = 'SET_SMART_BOX_DATA_READY'
export const SET_SMART_BOX_TREE_CURRENT_DEPTH = 'SET_SMART_BOX_TREE_CURRENT_DEPTH'
export const SET_SMART_BOX_HOME_CLICKED = 'SET_SMART_BOX_HOME_CLICKED'
export const SET_SECOND_LEVEL_NAVIGATION = 'SET_SECOND_LEVEL_NAVIGATION'
export const SET_THIRD_LEVEL_NAVIGATION = 'SET_THIRD_LEVEL_NAVIGATION'
export const SET_SMART_BOX_CURRENT_Sort_Option = 'SET_SMART_BOX_CURRENT_Sort_Option'
export const SET_SMART_BOX_FOLDER_HAS_BULK = 'SET_SMART_BOX_FOLDER_HAS_BULK'
export const SET_SMART_BOX_ERROR_LOADING_THUMBNAILS = 'SET_SMART_BOX_ERROR_LOADING_THUMBNAILS'
export const SET_SORT_CLOSE_SMART = 'SET_SORT_CLOSE_SMART'
export const SET_RESPONSE_PROGRESS_BAR_RENAME = 'SET_RESPONSE_PROGRESS_BAR_RENAME'
export const SET_SMART_BOX_PARENT_STATISTICS_READY = 'SET_SMART_BOX_PARENT_STATISTICS_READY'
export const SET_SMART_FILES_ERROR_NOT_BE_CONVERTED = 'SET_SMART_FILES_ERROR_NOT_BE_CONVERTED'
export const SET_SMART_BOX_UPLOAD_FAILED = 'SET_SMART_BOX_UPLOAD_FAILED'
export const SET_SMART_BOX_GENERATE_UNIQUE_ID = 'SET_SMART_BOX_GENERATE_UNIQUE_ID'
export const SET_SMART_NOT_SAR_TRACKER = 'SET_SMART_NOT_SAR_TRACKER'
export const SET_SMART_NOT_FOLDER_REDACTION_COMPLETE = 'SET_SMART_NOT_FOLDER_REDACTION_COMPLETE'
export const SET_SMART_ADD_NOTE = 'SET_SMART_ADD_NOTE'
export const SET_SMART_STATUS_CHANGE = 'SET_SMART_STATUS_CHANGE'
export const SET_SMART_PER_PAGE_DUPLICATE = 'SET_SMART_PER_PAGE_DUPLICATE'
export const SET_SMART_NOT_UPLOAD_COMPLETE = 'SET_SMART_NOT_UPLOAD_COMPLETE'
export const SET_SMART_NOT_GIVE_ACCESS_BOX = 'SET_SMART_NOT_GIVE_ACCESS_BOX'
export const SET_SMART_NOT_ANALYSIS_COMPLETE = 'SET_SMART_NOT_ANALYSIS_COMPLETE'
export const SET_SMART_NOT_ACCESS_PERMISSION = 'SET_SMART_NOT_ACCESS_PERMISSION'
export const SET_SMART_BOX_FOLDER_COUNT_TREE = 'SET_SMART_BOX_FOLDER_COUNT_TREE'
export const SET_SMART_FOLDER_COUNT_TREE = 'SET_SMART_FOLDER_COUNT_TREE'
export const SET_SMART_BOX_FILTER_REFRESH_OPENED_BOX = 'SET_SMART_BOX_FILTER_REFRESH_OPENED_BOX'
export const SET_SMART_REPROCESSING_FILE_DELETE = 'SET_SMART_REPROCESSING_FILE_DELETE'
export const SET_SMART_BOX_BASE_LIST = 'SET_SMART_BOX_BASE_LIST'
export const SET_SMART_BOX_SORT_SEARCH = 'SET_SMART_BOX_SORT_SEARCH'
export const SET_SMART_BOX_CHECK_STATUS_DEDUPLICATION = 'SET_SMART_BOX_CHECK_STATUS_DEDUPLICATION'
export const SET_SMART_BOX_SHOW_UPLOAD_NAVIGATION = 'SET_SMART_BOX_SHOW_UPLOAD_NAVIGATION'
export const SET_SMART_BOX_CURRENT_Filter_Option = 'SET_SMART_BOX_CURRENT_Filter_Option'
export const SET_SMART_BOX_CURRENT_Breadcrumb_Urls_Paths = 'SET_SMART_BOX_CURRENT_Breadcrumb_Urls_Paths'
export const SET_SMART_BOX_NEW_BOX = 'SET_SMART_BOX_NEW_BOX'
export const SET_SMART_BOX_CHECK_BOX_REDACTION_STATUS = 'SET_SMART_BOX_CHECK_BOX_REDACTION_STATUS'
export const SET_COLLECT_DATA_MAPPING = 'SET_COLLECT_DATA_MAPPING'
export const SET_ADMIN_MODIFY_CLIENT = 'SET_ADMIN_MODIFY_CLIENT'
export const SET_SMART_BOX_CURRENT_FOLDER_NAME = 'SET_SMART_BOX_CURRENT_FOLDER_NAME'
export const SET_SMART_BOX_SOURCE_DROP = 'SET_SMART_BOX_SOURCE_DROP'
export const SET_LEFT_NAVIGATION_TREE_SHOW = 'SET_LEFT_NAVIGATION_TREE_SHOW'
export const SET_SMART_BOX_CURRENT_NEW_FILTERS = 'SET_SMART_BOX_CURRENT_NEW_FILTERS'
export const BUSINESS_PROCESSES_VISIBLE = 'BUSINESS_PROCESSES_VISIBLE'
export const SOURCE_MAPPING_VISIBLE = 'SOURCE_MAPPING_VISIBLE'
export const CONNECTORS_VISIBLE = 'CONNECTORS_VISIBLE'
export const SET_SMART_BOX_CURRENT_NEW_FILTERS_SORT = 'SET_SMART_BOX_CURRENT_NEW_FILTERS_SORT'
export const SET_SMART_BOX_CLICK_OUTSIDE_FILTER = 'SET_SMART_BOX_CLICK_OUTSIDE_FILTER'
export const SET_REDACTION_NON_SELECTED_WORDS = 'SET_REDACTION_NON_SELECTED_WORDS'
export const SET_STATUS_CLEAR_ALL_FILTERS_SMARTBOX = 'SET_STATUS_CLEAR_ALL_FILTERS_SMARTBOX'
export const SET_SMART_BOX_SELECTED_KEYS = 'SET_SMART_BOX_SELECTED_KEYS'
export const SET_BOX_LIST_VIEW_SELECTED_KEYS = 'SET_BOX_LIST_VIEW_SELECTED_KEYS'
export const SET_PROGRESS_EVENT_UPLOAD = 'SET_PROGRESS_EVENT_UPLOAD'
export const SET_ACTIVE_DELETE_FILE = 'SET_ACTIVE_DELETE_FILE'
export const SET_DUPLICATE_CARD_INFO = 'SET_DUPLICATE_CARD_INFO'
export const SET_GET_VARIABLES_EMAILS_WORKFLOW = 'SET_GET_VARIABLES_EMAILS_WORKFLOW'
export const SET_SMART_BOX_EXISTS_FILES_WARNINGS_NOTIFICATION = 'SET_SMART_BOX_EXISTS_FILES_WARNINGS_NOTIFICATION'
export const SET_IMAGE_PROFILE_SETTINGS = 'SET_IMAGE_PROFILE_SETTINGS'
export const SET_SMART_BOX_PREVIOUS_CURRENT_PAGE = 'SET_SMART_BOX_PREVIOUS_CURRENT_PAGE'
export const SET_BOX_WORKFLOW_DASHBOARD = 'SET_BOX_WORKFLOW_DASHBOARD'
export const SET_VISIBLE_SUBHEADER_STEPS = 'SET_VISIBLE_SUBHEADER_STEPS'
