import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import moment from 'moment'
import './SubjectsList.scss'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../../../Common/CustomNotificationManager'
import { apiFetchByFilter, apiSarArchiveSteps, apiSarDeleteRequest } from '../../../../../api/Sar'
import {Dropdown, DatePicker, Menu, Table, Tooltip, Input, Icon, Select} from 'antd'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { DropdownButton } from 'react-bootstrap'
import Dropdown2 from 'react-bootstrap/Dropdown';
import imgBoxEmpty from '../../../../../assets/box/box_empty_20x20.png'
import imgBoxEmptyNeg from '../../../../../assets/box/box_empty_negative_20x20.png'
import imgBoxProcessing from '../../../../../assets/box/box_processing_20x20.png'
import imgBox from '../../../../../assets/box/box_20x20.png'
import { customConfirm } from '../../../../../utils/AssetRegister/CustomConfirm'
import withRouter from '../../../../../private-routers/withRouter'
import NewTicketSettingsModal from "./NewTicketSettingsModal";
import {
  apiMarkTicketWorkflowAsCompleted,
  apiSetTicketWorkflowStatus,
  getTicketWorkflowAndHeaderDetails
} from "../../../../../api/SmartBox";
import {setVisibleSubheaderSteps} from "../../../../../actions/smartBoxActions";
const { Option } = Select;
export class SubjectsList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showLearnMessage: true,
      accordion: {},
      history: {},
      isSending: false,
      redirectToArchiveSar: false,
      expandedRowKeys: [],
      dropdownActive: null,
      columns: [],
      columnSubjectValue: {},
      columnEmailValue: {},
      columnCreatedValue: {},
      columnOwnerValue: {},
      columnFoundValue: {},
      columnDaysLeftValue: {},
      columnStepValue: {},
      searchInputSubject: '',
      searchInputEmail: '',
      searchInputOwner: '',
      datePickerBeginCreated: null,
      datePickerEndCreated: null,
      selectedSar: {},
      selectedClickSarRow: {},
      listOptionStatus:['Open', 'Archive', 'Complete'],
      listOptionStatusNo:['Open', 'Complete'],
      displayFormCreateTicket:false,
      selectStatusValue:'Open',
      dataSourceTicket: [],
      selectColumn: {},
    }
    //this.navigateToDpiaDashboard = this.navigateToDpiaDashboard.bind(this)
  }

  componentDidMount() {
    this.props.setVisibleSubheaderSteps(false)
    this.setState({
      dataSourceTicket: this.props.dataSourceTicket
    })
    this.initColumns()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.dataSourceTicket) {
      this.setState({
        dataSourceTicket: this.props.dataSourceTicket
      })
    }
    if (prevProps !== this.props && this.props.dataSourceTicket && this.props.dataSourceTicket.length > 0) {
      this.initColumns()
    }
  }

  filterHasColumn = (column) => {
    let response = -1
    const { filterCriteria } = { ...this.props }
    if (filterCriteria && filterCriteria.length) {
      filterCriteria.forEach((item, index) => {
        if (item.column === column) {
          response = index
        }
      })
    }
    return response
  }

  handleClickFilterMenu = ({ item, key, domEvent }, column) => {
    const { searchInputSubject, searchInputEmail, searchInputOwner } = { ...this.state }
    let { dropdownActive } = this.state
    const newFilterCriteria = this.props.filterCriteria
    let { sortCriteria } = { ...this.props }

    if (column === 'created' && key === 'selected') {
      return
    }

    if (key === 'asc' || key === 'desc') {
      sortCriteria = {}
      sortCriteria = { column: column, order: key }
    } else {
      switch (column) {
        case 'subject': {
          const index = this.filterHasColumn(column)
          if (searchInputSubject.trim()) {
            if (index > -1) {
              newFilterCriteria[index] = { column: column, searchTerm: searchInputSubject }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: searchInputSubject })
            }
          } else if (index > -1) {
            newFilterCriteria.splice(index, 1)
          }
          break
        }
        case 'email': {
          const index = this.filterHasColumn(column)
          if (searchInputEmail.trim()) {
            if (index > -1) {
              newFilterCriteria[index] = { column: column, searchTerm: searchInputEmail }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: searchInputEmail })
            }
          } else if (index > -1) {
            newFilterCriteria.splice(index, 1)
          }
          break
        }
        case 'created': {
          break
        }
        case 'owner': {
          const index = this.filterHasColumn(column)
          if (searchInputOwner.trim()) {
            if (index > -1) {
              newFilterCriteria[index] = { column: column, searchTerm: searchInputOwner }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: searchInputOwner })
            }
          } else if (index > -1) {
            newFilterCriteria.splice(index, 1)
          }
          break
        }
        case 'step': {
          if (key) {
            const index = this.filterHasColumn(column)
            if (index > -1) {
              if (newFilterCriteria[index].searchTerm === key) {
                newFilterCriteria.splice(index, 1)
              } else {
                newFilterCriteria[index] = { column: column, searchTerm: key }
              }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: key })
            }
          }
          break
        }
        default: {
          if (searchInputSubject) {
            const index = this.filterHasColumn(column)
            if (index > -1) {
              newFilterCriteria[index] = { column: column, searchTerm: searchInputSubject }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: searchInputSubject })
            }
          }
        }
      }
    }

    if (!['search', 'select'].includes(key)) {
      dropdownActive = false
    }

    this.props.setFilterAndSort(sortCriteria, newFilterCriteria)
    this.setState({
      dropdownActive: dropdownActive,
    }, () => {
      this.initColumns()
    })
  }

  selectDate = (e, field) => {
    const { filterCriteria } = this.props
    const { columnCreatedValue } = this.state
    let { datePickerBeginCreated, datePickerEndCreated } = this.state
    if (! columnCreatedValue.range) {
      columnCreatedValue.range = [null, null]
    }
    if (field === 'start' && e) {
      columnCreatedValue.range[0] = e.unix()
      datePickerBeginCreated = e

      const newValue = `${ datePickerBeginCreated ? datePickerBeginCreated.unix() : '' },${ datePickerEndCreated ? datePickerEndCreated.unix() : '' }`
      const index = this.filterHasColumn('created')
      if (index > -1) {
        filterCriteria[index] = { column: 'created', searchTerm: newValue }
      } else {
        filterCriteria.push({ column: 'created', searchTerm: newValue })
      }
      this.setState({ datePickerBeginCreated, columnCreatedValue }, () => {
        this.props.setFilter(filterCriteria)
        if (datePickerBeginCreated && datePickerEndCreated) {
          this.setState({ dropdownActive: null }, () => {
            this.initColumns()
          })
        }
      })
    } else if (e) {
      columnCreatedValue.range[1] = e.unix()
      datePickerEndCreated = e

      const newValue = `${ datePickerBeginCreated ? datePickerBeginCreated.unix() : '' },${ datePickerEndCreated ? datePickerEndCreated.unix() : '' }`
      const index = this.filterHasColumn('created')
      if (index > -1) {
        filterCriteria[index] = { column: 'created', searchTerm: newValue }
      } else {
        filterCriteria.push({ column: 'created', searchTerm: newValue })
      }
      this.setState({ datePickerEndCreated, columnCreatedValue }, () => {
        this.props.setFilter(filterCriteria)
        if (datePickerBeginCreated && datePickerEndCreated) {
          this.setState({ dropdownActive: null }, () => {
            this.initColumns()
          })
        }
      })
    } else if (field === 'start') {
      datePickerBeginCreated = null
      const newValue = `${ datePickerBeginCreated ? datePickerBeginCreated.unix() : '' },${ datePickerEndCreated ? datePickerEndCreated.unix() : '' }`
      const index = this.filterHasColumn('created')
      if (!datePickerBeginCreated && !datePickerEndCreated && index > -1) {
        filterCriteria.splice(index, 1)
      } else {
        filterCriteria[index] = { column: 'created', searchTerm: newValue }
      }
      this.setState({ datePickerBeginCreated, dropdownActive: null }, () => {
        this.props.setFilter(filterCriteria)
        this.initColumns()
      })
    } else {
      datePickerEndCreated = null
      const newValue = `${ datePickerBeginCreated ? datePickerBeginCreated.unix() : '' },${ datePickerEndCreated ? datePickerEndCreated.unix() : '' }`
      const index = this.filterHasColumn('created')
      if (!datePickerBeginCreated && !datePickerEndCreated && index > -1) {
        filterCriteria.splice(index, 1)
      } else {
        filterCriteria[index] = { column: 'created', searchTerm: newValue }
      }
      this.setState({ datePickerEndCreated, dropdownActive: null }, () => {
        this.props.setFilter(filterCriteria)
        this.initColumns()
      })
    }
  }

  openDropdown = (columnSelected) => {
    if (this.state.dropdownActive === columnSelected) {
      this.setState({ dropdownActive: null }, () => this.initColumns())
    } else {
      this.setState({ dropdownActive: columnSelected }, () => this.initColumns())
    }
  }
  displayTicketCreationForm = (status) => {
    this.setState({
      displayFormCreateTicket:status
    })
  }
  openStepsTicketModal = (text, record) => {
    this.props.onEditFirstStepModalTickets(record)
    this.props.setVisibleSubheaderSteps(true)
  }
  openNewTicketModal = (text, record) => {
    this.props.openNewEditModalTicket('edit', record)
    // this.props.onEditFirstStepModalTickets(record)
    this.props.setVisibleSubheaderSteps(true)
  }

  onChangeSelect = (e, record) => {
    const { dataSourceTicket } = this.state
    if (e === 'Complete') {
      this.showCompleteTicketModal(e, record)
    } else {
      dataSourceTicket && dataSourceTicket.length > 0 && dataSourceTicket.forEach((item) => {
        if (record.ticketId === item.ticketId) {
          item.ticketStatus = e
        }
      })
      this.setState({
        selectStatusValue: e,
        dataSourceTicket
      }, () => {
        const setTicketWorkflowStatusDTO = {
          ticketId: record.ticketId,
          ticketStatus: e,
        }
        this.setState({
          dataSourceTicket
        })
        apiSetTicketWorkflowStatus(setTicketWorkflowStatusDTO).then((response) => {
          if (response.data.title && response.data.title.toLowerCase() === 'success') {
            CustomNotificationManager.success(response.data.message, 'Success')
            // if (e === 'Archive') {
            //   const path = `/sar/workflow/workflow-archive`
            //   this.props.navigate(path)
            // }
          } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
            CustomNotificationManager.info(response.data.message, 'Info')
          }
          this.props.onGetTicketWorkflowListDashboard()
        })
      })
    }
  }

  showCompleteTicketModal= (e, record) => {
    customConfirm({
      title: <span className="icon-v22-radio-button-tick"></span>,
      //content: `Complete workflow?`,
      content: <span className="content-end-ticket"><span className="header-end-ticket">End ticket?</span>
        <span className="subheader-end-ticket">Ending the ticket can not be undone.</span></span>,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal modal-send-email-step-workflow modal-internal-email-notification',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
      confirmAction: () => {
        this.callApiMarkTicketWorkflowAsCompleted(e, record)
      }
    })
  }


  callApiMarkTicketWorkflowAsCompleted = (e, record) => {
    const { dataSourceTicket } = this.state
    dataSourceTicket && dataSourceTicket.length > 0 && dataSourceTicket.forEach((item) => {
      if (record.ticketId === item.ticketId) {
        item.ticketStatus = e,
        item.ticketCompletionDate = moment().format('YYYY.MM.DD HH:mm')
      }
    })
    const MarkTicketAsCompletedDTO = {
      ticketId: record.ticketId,
    }
    this.setState({
      selectOptionStatus: e,
      dataSourceTicket
    }, () => {
      //this.props.onGetTicketWorkflowListDashboard()
    })
    apiMarkTicketWorkflowAsCompleted(MarkTicketAsCompletedDTO).then(response => {
      if (response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
      } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
      }
      //this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
      // getTicketWorkflowAndHeaderDetails(selectHeaderTickets.ticketId).then(response => {
      // })
    })

  }

  initColumns = () => {
    const { selectColumn } = this.state
    const columns = [
      {
        key: 'ticketId',
        //title: this.renderColumnTitle('Ticket ID', 'ticketId'),
        className: "column-tickets",
        title: <span className="content-header-column"><span>Ticket ID</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ this.state.selectColumn.column === 'ticketId' && this.state.selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'ticketId', 'asc')}></span>
                                                     <span className={ this.state.selectColumn.column === 'ticketId' && this.state.selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'ticketId', 'desc')}></span>
                                                 </span>
                                        </span>,
        dataIndex: 'ticketId',
        align: 'center',
        width: "17%",
        render: (text, record, index) => {
          return <div className="text-container text-container--center">
            {/*{ moment(text).format('DD.MM.YYYY HH:mm') }*/}

            {/*<span*/}
            {/*    className={'tiketId'}*/}
            {/*    onClick={() => this.openNewTicketModal()}>*/}
            {/*  { moment(text).format('DD MM YY ').replace(/\s/g, "") }*/}
            {/*</span>*/}
            {/*<span className={'ticketName'}>Josephine Gallagher, record request</span>*/}
            <span className={'ticket-id-name-container'}>
              <span onClick={() => this.openNewTicketModal(text, record)} className="ticket-id">{(record.ticketIdClient)}</span>
              <span onClick={() => this.openStepsTicketModal(text, record)} className="ticket-name">{record.ticketName}</span></span>
          </div>
        }
      },
      {
        key: 'ticketOwnerName',
        //title: this.renderColumnTitle('Owner', 'owner'),
        className: "column-tickets",
        title: <span className="content-header-column"><span>Owner</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ this.state.selectColumn.column === 'owner' && this.state.selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'owner', 'asc')}></span>
                                                     <span className={ this.state.selectColumn.column === 'owner' && this.state.selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'owner', 'desc')}></span>
                                                 </span>
                                        </span>,
        dataIndex: 'ticketOwnerName',
        //filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
        filters: this.props.ownerListFilter,
        width: "17%",
        //onFilter: (value, record) => console.log(),
        render: (text, record, index) => {
          return <div className="text-container text-container--center">
            {/*{ text }*/}
            <span className={'text-container-data ellipsis'}
                  onClick={() => this.openStepsTicketModal(text, record)}>{text}</span>
          </div>
        }
      },
      {
        key: 'ticketWorkflowName',
        //title: this.renderColumnTitle('Workflow', 'workflow'),
        className: "column-tickets",
        title: <span className="content-header-column"><span>Workflow</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ this.state.selectColumn.column === 'workflow' && this.state.selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'workflow', 'asc')}></span>
                                                     <span className={ this.state.selectColumn.column === 'workflow' && this.state.selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'workflow', 'desc')}></span>
                                                 </span>
                                        </span>,
        dataIndex: 'ticketWorkflowName',
        filters: this.props.workflowNames,
        width: "17%",
        //onFilter: (value, record) => console.log(),
        render: (text, record, index) => {
          return <div className="text-container text-container--center">
            {/*{ text }*/}
            <span className={'text-container-data ellipsis'}
                  onClick={() => this.openStepsTicketModal(text, record)}>{text}</span>
          </div>
        }
      },
      {
        key: 'ticketReceivedDate',
        //title: this.renderColumnTitle('Received', 'received'),
        className: "column-tickets",
        title: <span className="content-header-column"><span>Opened</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ this.state.selectColumn.column === 'received' && this.state.selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'received', 'asc')}></span>
                                                     <span className={ this.state.selectColumn.column === 'received' && this.state.selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'received', 'desc')}></span>
                                                 </span>
                                        </span>,
        dataIndex: 'ticketReceivedDate',
        align: 'center',
        width: "13%",
        render: (text, record, index) => {
          return <div className="text-container text-container--center">
            {/*{ moment(text).format('DD.MM.YYYY HH:mm') }*/}
            <span className={'text-container-data'}
                  onClick={() => this.openStepsTicketModal(text, record)}> {text ? moment(text).format('DD.MM.YYYY') : ''}</span>
          </div>
        }
      },
      {
        key: 'ticketTimeFrame',
        //title: this.renderColumnTitle('Time frame', 'daysLeft'),
        className: "column-tickets",
        title: <span className="content-header-column"><span>Time frame</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ this.state.selectColumn.column === 'timeFrame' && this.state.selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'timeFrame', 'asc')}></span>
                                                     <span className={ this.state.selectColumn.column === 'timeFrame' && this.state.selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'timeFrame', 'desc')}></span>
                                                 </span>
                                        </span>,
        dataIndex: 'ticketTimeFrame',
        align: 'center',
        filters: this.props.timeframes,
       // onFilter: (value, record) => console.log(),
        //onFilter: (value, record) => this.onSorterColumnTickets(value, record),
        //onFilter: (value, record) => record.ticketTimeFrame.indexOf(value) === 0,
        sortDirections: ['descend', 'ascend'],
        width: "12%",
        render: (text, record, index) => {
          return <div className="text-container text-container--center text-container--days"
                      onClick={() => this.openStepsTicketModal(text, record)}>
            {/*{ record.sarCompleted === true && record.step === 'COMPLETE' ? '-' : text ? this.getDaysLeft(text) : '-' }*/}
            {/*<span className={'process-time'}>Day 1</span><span className='process-time-days'>{`of ${text}`}</span>*/}
            {/*<span className="content-get-time-frame">{this.getTimeFrame(text, record)}</span>*/}

            {this.getTimeFrame(text, record)}
          </div>
        }
      },
      {
        key: 'box',
        title: 'Box',
        dataIndex: 'box',
        filters: this.props.boxStatusList,
        className: 'column-box',
        width: "8%",
        //onFilter: (value, record) => this.onSorterColumnTickets(value, record),
        render: (text, record, index) => {
          return <div className="text-container text-container--center text-container--center-update">
            {/*<Tooltip title = {` ${ record.boxId ? record.boxName : 'Unselected' }`} placement="bottom">*/}
              {/*<span*/}
              {/*  className= {`icon-v10-smart-folder-medium-2 ${ record.boxId ? 'icon-blue' : 'icon-grey' }`}*/}
              {/*  onClick = { () => this.gotToSmartbox(record.boxId) }*/}
              {/*></span>*/}
              <span onClick = {record.boxStatus && record.boxStatus === 'DISABLED' ?
                  () => this.gotToSmartboxDisabled() : () => this.gotToSmartbox(record.ticketBoxId) } className={record.boxStatus === 'DISABLED' ? 'box-icon box-icon-empty' : 'box-icon'}>
                <img  src={record.boxStatus === 'DISABLED' ? imgBoxEmpty :
                    record.boxStatus === 'EMPTY' ? imgBoxEmpty :
                        record.boxStatus === 'GRAY' ? imgBoxProcessing :
                            record.boxStatus === 'TURQUOISE' ? imgBox : imgBoxEmpty}/></span>
            {/*</Tooltip>*/}
          </div>
        }
      },
      {
        key: 'ticketDonePercent',
        //title: this.renderColumnTitle('Done', 'done'),
        className: "column-tickets",
        title: <span className="content-header-column"><span>Progress</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ this.state.selectColumn.column === 'done' && this.state.selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'done', 'asc')}></span>
                                                     <span className={ this.state.selectColumn.column === 'done' && this.state.selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumnTickets(e, 'done', 'desc')}></span>
                                                 </span>
                                        </span>,
        dataIndex: 'ticketDonePercent',
        width: "11%",
        render: (text, record, index) => {
          return <div className="text-container text-container--center text-container--center-update">
            {/*{ text }*/}
            <span className={'estimation-of-workflow'}
                  onClick={() => this.openStepsTicketModal(text, record)}>{text} %</span>
          </div>
        }
      },
      {
        key: 'ticketStatus',
        title:'Status',
        dataIndex: 'ticketStatus',
        width: "8%",
        render: (text, record, index) => {
          return <div className="text-container text-container--center text-container--center-update">
            {/*{ text }*/}
            {record.ticketStatus === 'Complete' ?
              <Select
                  defaultValue={text}
                  onChange={(e) => this.onChangeSelect(e, record)}
                  value={text}
                  dropdownClassName={'select-per-page-tickets select-status select-status-dashboard-option'}>
                {this.state.listOptionStatus && this.state.listOptionStatus.length > 0 && this.state.listOptionStatus.map((item, index) => {
                  return (<Option className="options-select-connector" value={item} key={index}>
                    {item === 'Archive' ? 'Archive' : item === 'Complete' ? 'Complete' : item}
                  </Option>)
                })}
              </Select>
                :
                <Select
                    defaultValue={text}
                    onChange={(e) => this.onChangeSelect(e, record)}
                    value={text}
                    dropdownClassName={'select-per-page-tickets select-status'}>
                  {this.state.listOptionStatusNo && this.state.listOptionStatusNo.length > 0 && this.state.listOptionStatusNo.map((item, index) => {
                    return (<Option className="options-select-connector" value={item} key={index}>
                      {item === 'Archive' ? 'Archive' : item === 'Complete' ? 'Complete' : item}
                    </Option>)
                  })}
                </Select>
            }
          </div>
        }
      },
      {
        key: 'ticketName',
        title: '',
        dataIndex: 'ticketName',
        render: (text, record, index) => {
          return <div className="text-container text-container--center">
            {/*{ text }*/}
            <span onClick={() => this.openStepsTicketModal(text, record)} className={'icon-v14-arrow-bold'}></span>
          </div>
        }
      },
    ]
    this.setState({ columns })
  }
  onSorterColumnTickets = (e, column, sorter) => {
    const selectCol = {
      column: column,
      sorter: sorter
    }
    this.setState({
      selectColumn: selectCol,
    }, () => {
      this.props.onGetSorterColumnTickets(column, sorter)
      this.initColumns()
    })
  }
  gotToSmartbox = (boxId) => {
    if (boxId) {
      this.props.navigate(`/smart-box/folder/${ boxId }`)
      //window.open(`${window.location.origin}/smart-box/folder/${boxId}`)
    }
  }
  gotToSmartboxDisabled = (boxId) => {
    return true
  }
  handleDeleteSarAction = (record, action, e) => {
    customConfirm({
      title: 'Do you want to delete request?',
      content: `${ record.subject }`,
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
      confirmAction: () => {

      }
    })
  }
  onFilterSearchChange = (e, key) => {
    switch (key) {
      case 'subject':
        this.setState({ searchInputSubject: e.target.value }); break
      case 'email':
        this.setState({ searchInputEmail: e.target.value }); break
      default:
        this.setState({ searchInputOwner: e.target.value })
    }
    this.setState({ dropdownActive: false })
  }

  disabledDateStart = (current) => {
    const { datePickerEndCreated } = this.state
    if (datePickerEndCreated) {
      return current > datePickerEndCreated
    }
    return current && current > moment()
  }

  disabledDateEnd = (current) => {
    const { datePickerBeginCreated } = this.state
    if (datePickerBeginCreated) {
      return current <= datePickerBeginCreated
    }
    return null
  }

  getClassForTableFilters = (key) => {
    const { sortCriteria, filterCriteria } = this.props
    let titleColorClass = ''
    if (Object.keys(sortCriteria).length) {
      if (sortCriteria.column === key) {
        titleColorClass = 'black-title'
      }
    }
    if (filterCriteria && filterCriteria.length) {
      filterCriteria.forEach((item) => {
        if (item.column === key) {
          titleColorClass = 'black-title'
        }
      })
    }
    return titleColorClass
  }

  getSelectedItems = (key) => {
    const { sortCriteria } = this.props
    let selectedItems = ''
    if (Object.keys(sortCriteria).length) {
      if (sortCriteria.column === key) {
        selectedItems = Object.values(sortCriteria)
      }
    }
    return selectedItems
  }

  getSelectedStepItem = () => {
    const { filterCriteria } = this.props
    let selectedItem = ''
    if (filterCriteria && filterCriteria.length) {
      filterCriteria.forEach((item) => {
        if (item.column === 'step') {
          selectedItem = item.searchTerm
        }
      })
    }
    return selectedItem
  }

  renderColumnTitle = (title, key) => {
    const { dropdownActive } = this.state
    const { Search } = Input
    const { SubMenu } = Menu
    const titleColorClass = this.getClassForTableFilters(key)
    let selectedItems = this.getSelectedItems(key)
    const selectedStepItem = this.getSelectedStepItem()
    if (selectedItems) {
      if (selectedStepItem) {
        selectedItems.push(selectedStepItem)
      }
    } else if (selectedStepItem) {
      selectedItems = [selectedStepItem]
    }
    const columnTitleMenu = (
      <Menu
        className="sar-dashboard-filter-menu"
        onClick={ (e) => this.handleClickFilterMenu(e, key) }
        mode="vertical"
        selectedKeys={ selectedItems }
      >
        {['subject', 'email', 'owner', 'step'].includes(key) &&
          <Menu.Item key= "asc">Sort A-Z</Menu.Item>
        }
        {['subject', 'email', 'owner', 'step'].includes(key) &&
          <Menu.Item key= "desc">Sort Z-A</Menu.Item>
        }
        { key === 'step' &&
          <SubMenu
            key="sub1"
            title='Filter by status'
            popupClassName="steps-filters-popup-position"
          >
            <Menu.Item key="0">Action Notification</Menu.Item>
            <Menu.Item key="1">Request</Menu.Item>
            <Menu.Item key="2">Acknowledge</Menu.Item>
            <Menu.Item key="3">ID Check</Menu.Item>
            <Menu.Item key="4">Rights</Menu.Item>
            <Menu.Item key="5">Data</Menu.Item>
            <Menu.Item key="6">Send</Menu.Item>
            <Menu.Item key="8">Complete</Menu.Item>
          </SubMenu>
        }
        {['found', 'daysLeft'].includes(key) &&
          <Menu.Item key= "desc">Sort High - Low</Menu.Item>
        }
        {['found', 'daysLeft'].includes(key) &&
          <Menu.Item key= "asc">Sort Low - High</Menu.Item>
        }
        { key === 'created' &&
          <Menu.Item key= "desc">Sort Latest - Oldest</Menu.Item>
        }
        { key === 'created' &&
          <Menu.Item key= "asc">Sort Oldest - Latest</Menu.Item>
        }
        { key === 'created' &&
          <Menu.Item key= "select" className={`date-picker-input-wrapper date-picker-input-wrapper_${ key }`}>
            <DatePicker
              className="date-picker"
              disabledDate={ this.disabledDateStart }
              dropdownClassName="date-picker-selector date-picker-selector--left"
              placeholder="Date"
              format='DD.MM.YY'
              showToday = {false}
              onChange={ (e) => this.selectDate(e, 'start')}
              suffixIcon={<span className="icon-calendar-b" />}
            />
            <span className="date-picker-to"> to </span>
            <DatePicker
              className="date-picker"
              disabledDate={ this.disabledDateEnd }
              dropdownClassName="date-picker-selector date-picker-selector--right"
              placeholder="Date"
              format='DD.MM.YY'
              showToday = {false}
              onChange={ (e) => this.selectDate(e, 'end')}
              suffixIcon={ <span className="icon-calendar-b" /> }
            />
          </Menu.Item>
        }
        {['subject', 'email', 'owner'].includes(key) &&
          <Menu.Item key="search" className={`search-input-wrapper search-input-wrapper_${ key }`}>
            <Search placeholder="input search text" onChange={ (e) => this.onFilterSearchChange(e, key) }/>
          </Menu.Item>
        }
      </Menu>
    )
    return <Dropdown overlay={columnTitleMenu} visible={ dropdownActive === key } trigger={['click']}>
      <span className="content-header-column"
          //className={`ant-dropdown-linke + ${ titleColorClass }`}
          //onClick={ () => this.openDropdown(key)}
      ><span className={'ant-table-column-title'}> { title }</span>
        <span className="content-buttons-sorter">
          <span className={ "icon-v16-arrow-bold rotate-arrow" }></span>
          <span className={ "icon-v16-arrow-bold" }></span>
        </span>
        {/*<span className="icon-v10-arrow"></span>*/}
      </span>
    </Dropdown>
  }

  getTimeFrame = (text, record) => {
    const timeCurrent = moment().format('YYYY.MM.DD 00:00');
    const ticketTimeFrame = text
    const ticketTimeFrameOneThird = ticketTimeFrame/3
    const ticketTimeFrameTwoThird = ticketTimeFrameOneThird * 2
    if (record.ticketReceivedDate) {
      const date1 = moment(record.ticketReceivedDate,'YYYY.MM.DD 00:00' ).toDate()
      const date2 = new Date(timeCurrent)


      const diffTime = Math.abs(date2 - date1);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      diffDays = diffDays + 1
      if (record.ticketStatus === 'Complete' && record.ticketReceivedDate  && record.ticketCompletionDate) {
        const startDate = moment(record.ticketReceivedDate,'YYYY.MM.DD 00:00' ).toDate()
        const endDate = moment(record.ticketCompletionDate,'YYYY.MM.DD 00:00' ).toDate()
        const differenceTime = endDate.getTime() - startDate.getTime()
        const differenceDays = differenceTime / (1000 * 3600 * 24)
        const openingDate = parseInt(differenceDays+1)
         return <span className={'process-time'}><span
            className='process-time-days'>{`${openingDate} days`}</span></span>
      } else if (text === 99999) {
        if (record.boxStatus === 'TURQUOISE') {
          return <span className={'process-time-disclosed'}><span className="process-time-days">Disclosed</span></span>
        } else
        return <span className={'process-time'}><span
            className='process-time-days'>{`None`}</span></span>
      } else {
        if (record.boxStatus === 'DISABLED') {
          //return <span className={'process-time'}>-</span>
          if (diffDays < ticketTimeFrameOneThird) {
            return <span className={'process-time'}>{`Day ${diffDays}`}<span
                className='process-time-days'>{` of ${text}`}</span></span>
          }
          if (diffDays < ticketTimeFrameTwoThird) {
            return <span className={'process-time-yellow'}>{`Day ${diffDays}`}<span
                className='process-time-days'>{` of ${text}`}</span></span>
          }
          if (diffDays <= ticketTimeFrame) {
            return <span className={'process-time-orange'}>{`Day ${diffDays}`}<span
                className='process-time-days'>{` of ${text}`}</span></span>
          }

          if (diffDays >= ticketTimeFrame) {
            return <span className={'process-time-black'}>{`Day ${diffDays}`}<span
                className='process-time-days-black'>{` of ${text}`}</span></span>
          }
          return <span className={'process-time'}>Day 1<span className='process-time-days'>{` of ${text}`}</span></span>
        }
        if (record.boxStatus === 'TURQUOISE') {
          return <span className={'process-time-disclosed'}><span className="process-time-days">Disclosed</span></span>
        }
        if (diffDays < ticketTimeFrameOneThird) {
          return <span className={'process-time'}>{`Day ${diffDays}`}<span
              className='process-time-days'>{` of ${text}`}</span></span>
        }
        if (diffDays < ticketTimeFrameTwoThird) {
          return <span className={'process-time-yellow'}>{`Day ${diffDays}`}<span
              className='process-time-days'>{` of ${text}`}</span></span>
        }
        if (diffDays <= ticketTimeFrame) {
          return <span className={'process-time-orange'}>{`Day ${diffDays}`}<span
              className='process-time-days'>{` of ${text}`}</span></span>
        }

        if (diffDays >= ticketTimeFrame) {
          return <span className={'process-time-black'}>{`Day ${diffDays}`}<span
              className='process-time-days-black'>{` of ${text}`}</span></span>
        }
        return <span className={'process-time'}>Day 1<span className='process-time-days'>{` of ${text}`}</span></span>
      }
    }
  }

  getDaysLeft(time) {

    if (time && time[0] >= 7) {
      return (
        <span>{ `${ time[0] }d ${ time[1] }h`}</span>
      )
    } else if (time && time[0] < 0) {
      return (
        <span className="time-span-color">{ `${ time[0] }d ${ -time[1] }h`}</span>
      )
    } else if (time && time[0] >= 0 && time[0] < 7 && time[1] >= 0) {
      return (
        <span className="time-span-color-yellow">{ `${ time[0] }d ${ time[1] }h`}</span>
      )
    } else if (time && time[0] === 0 && time[1] < 0) {
      return (
        <span className="time-span-color">{ `-${ time[0] }d ${ -time[1] }h`}</span>
      )
    }
  }
  archiveStepClicked(e, id) {
    e.preventDefault()
    swal.fire({
      text: 'This step will be archived so that your reports will still be accessible.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes, archive it!'
    }).then((result) => {
      if (result.value) {
        this.confirmedActionToArchive(id)
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  confirmedActionToArchive(id) {
    this.setState({
      isSending: true
    })
    // apiSarArchiveSteps(id)
    //   .then(() => {
    //     CustomNotificationManager.success('Archived successfully', 'Success')
    //     this.setState({
    //       isSending: false,
    //       redirectToArchiveSar: true
    //     })
    //   }).catch((error) => {
    //   if (error?.response?.status === 504) {
    //     CustomNotificationManager.cleanBuffer('warning', '')
    //   }else{
    //     console.log(error)
    //   }
    // })
  }

  renderContentAccordion(obj) {
    let details = 'Without details'
    if (typeof obj.details !== undefined) {
      if (obj.details !== null && obj.details.trim() !== '') {
        details = obj.details
      }
    }
    return (
      <React.Fragment>
        <div className="subject-list-info">
          <div className="detailsAccordion">
            <p>{ details }</p>
          </div>
          <div className="emailAccordion">
            <p>email: <span className="font-weight-bold">{ obj.email }</span></p>
          </div>
        </div>
      </React.Fragment>
    )
  }
  onClickRowSelected = (e, record) => {
    this.props.onSelectSarRow(record)
    this.setState({
      selectedClickSarRow: record
    })
  }
  onMouseEnterRow = (e, record) => {
    this.setState({
      selectedSar: record
    })
  }
  onMouseLeaveRow = (e) => {
    this.setState({
      selectedSar: {}
    })
  }
  rowClassSelected = (record) => {
    const { selectedClickSarRow } = this.state
    if (record.id === selectedClickSarRow.id) {
      return 'row-selected-table'
    }
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, () => {
      this.props.onGetFiltersColumnTickets(filters, extra)
    })
  }

  render() {
    const { redirectToArchiveSar, expandedRowKeys, columns, displayFormCreateTicket, dataSourceTicket} = this.state
    const { isLoading, sars, checkSelectAllSar, loadingGetListDashboard } = this.props

    return (
      <div>
        { redirectToArchiveSar &&
          <Navigate to="/sar/archive" />
        }
        { isLoading &&
          <div className="dpiaDashboard-loading">

          </div>
        }
        <div className ="styleAccordion">
          <Table
            columns={ columns }
            rowKey = { (record) => { return `rowKey${ record.ticketId }` }}
            //expandedRowRender={ (record) => this.renderContentAccordion(record) }
            dataSource={ dataSourceTicket}
            loading={loadingGetListDashboard}
            pagination={ false }
            expandedRowKeys={ expandedRowKeys }
            className="sar-dashboard-table sar-dashboard-table-new"
            // rowClassName={checkSelectAllSar ? 'row-selected-table' : (record) => this.rowClassSelected(record) }
            onRow={(record, rowIndex) => {
              return {
                // onClick: (e) => this.onClickRowSelected(e, record),
                onMouseEnter: (e) => this.onMouseEnterRow(e, record),
                onMouseLeave: (e) => this.onMouseLeaveRow(e),
                // onDoubleClick: (event) => {
                //   this.props.navigate(`/sar/tracker/${ record.id }`)
                // }
              }
            }}
            onChange={this.handleTableChange}

          />
        </div>
        {
          displayFormCreateTicket &&
              <NewTicketSettingsModal displayTicketCreationForm={this.displayTicketCreationForm}/>
        }
      </div>

    )
  }
}
SubjectsList = withRouter(SubjectsList)
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
})

export default compose(connect(mapStateToProps, {
  setVisibleSubheaderSteps
}))(SubjectsList)