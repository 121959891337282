import React from 'react'
import {Checkbox, Popover, Collapse, Modal} from 'antd'
import {
  apiSmartBoxSaveRegularExpression,
  apiEditRegularExpression,
  getSmartBoxRegexList,
  saveRegularExpressionsColor,
  selectRegularExpressions,
  deleteRegularExpressions,
  getSelectedRegex, getSmartBoxAllRegexList, savedRegularExpressions,
} from '../../../../../api/SmartBox'
import { connect } from 'react-redux';
import { setSmartBoxFetchTreeData } from '../../../../../actions/smartBoxActions';
import {CompactPicker} from "react-color";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import './RegularExpressions.scss'
import cookie from "react-cookies";
import withRouter from '../../../../../private-routers/withRouter';

class RegularExpressions extends React.Component {
  state = {
    visibleRegularExpressions: false,
    visibleExpressionBuilder: false,
    unit: '',
    unitModal: '',
    activeIndex: -1,
    caseSensitive: false,
    regex: '',
    title: '',
    description: '',
    regexModal: '',
    titleModal: '',
    descriptionModal: '',
    regexList: [],
    regexOpen:[],
    regexActive: null,
    color: 'rgba(77, 100, 113, 1)',
    colorPickerVisible: false,
    editModalVisible: false,
    selectedList: [],
    selectAllChecked: false,
    saveStep: 0,
    optionsEnabled: true,
    expandKeysRegex: [],
    activeOpenRegEx: false,
    activeEdit:false,
  }
  
  ua = window.navigator.userAgent.toLowerCase();
  isIE = !!this.ua.match(/msie|trident\/7|edge/);
  isWinPhone = this.ua.indexOf('windows phone') !== -1;
  isIOS = !this.isWinPhone && !!this.ua.match(/ipad|iphone|ipod/);

  componentDidMount() {
    if (this.props.folder.id) {
      this.fetchRegularExpressions()
    }
    //this.fetchRegularExpressions()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.folder.id !== this.props.folder.id && this.props.folder.id) {
      this.fetchRegularExpressions()
    }
    if (prevProps.folder.id !== this.props.folder.id) {
      const openLoadRegEx = cookie.load('sendOpenRegEx')
      if (openLoadRegEx === 'openIsTrue') {
        this.props.navigate('/smart-box/regular-expressions/settings')
      }
      cookie.remove('sendOpenRegEx', { path: '/' })
    }
  }fetchRegularExpressions = () => {
    getSmartBoxAllRegexList().then((res) => {
      if (res.status === 200) {
        let regexList = []
        for (const value of Object.values(res.data)) {
          delete value.folderId
          delete value.id
          regexList.push(value)
        }
        regexList.sort((a,b) => a.title.localeCompare(b.title) )
        regexList && regexList.length > 0 && regexList.forEach((item, index) => {
          item.id = index
        })
        this.setState({ regexList }, () => {
          this.fetchData()
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }
  fetchData = () => {
    let { optionsEnabled, saveStep, selectAllChecked, regexList } = this.state
    const { folderIdOne, folderIdTwo } = this.props
    const folderIdApi = this.props.folder.id ? this.props.folder.id : folderIdOne ? folderIdOne : folderIdTwo ? folderIdTwo : this.props.folder.id
    getSmartBoxRegexList(folderIdApi).then((res) => {
      if (res.status === 200) {
        if (res.data.regexList) {
          const listLength = res.data.regexList.length
          const newRegEx =  regexList.concat(res.data.regexList)
          const seen = new Set()

          const filteredArr = newRegEx && newRegEx.length > 0 && newRegEx.filter(el => {
            const duplicate = seen.has(el.regex);
            seen.add(el.regex)
            return !duplicate
          })
          filteredArr && filteredArr.length > 0 && filteredArr.forEach((item, index) => {
            item.id = index.toString()
          })
          this.setState({ regexList: filteredArr }, () => {
            getSelectedRegex(this.props.folder.id).then((result) => {
              if (result.status === 200) {
                const selectedKeys = []
                filteredArr && filteredArr.length > 0 && filteredArr.forEach((reg) => {
                   if (result.data && result.data.length) {
                     result.data.forEach((result) => {
                       if (reg.regex === result) {
                         selectedKeys.push(reg.id)
                       }
                     })
                   }
                })
                if (result.data.length) {
                  optionsEnabled= false
                  saveStep= 2
                  if (result.data.length === listLength) {
                    selectAllChecked = true
                  }
                }
                // let uniqueSelectedKeys = []
                //uniqueSelectedKeys = selectedKeys && selectedKeys.length > 0 && selectedKeys.filter((a, i) => selectedKeys.findIndex((s) => a === s) === i)
                this.setState({
                  selectedList: selectedKeys,
                  optionsEnabled,
                  saveStep,
                  selectAllChecked
                })
              }
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          })
        }
        if (res.data.color) {
          this.setState({ color: this.replaceAlpha(res.data.color) })
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  
  onClickVisibleRegular = () => {
    this.setState({
      visibleRegularExpressions: !this.state.visibleRegularExpressions
    })
  }
  
  onClickVisibleBuilder = () => {
    this.setState({
      visibleExpressionBuilder: !this.state.visibleExpressionBuilder
    })
  }

  replaceAlpha = (color) => {
    const temp = color.split(',')
    if (temp.length === 4) {
      temp[3] = " 1)"
      color = temp.join()
    }
    return color
  }
  
  applyHighlights(text, regExp) {
    const str = regExp.toString()
    const lastSlash = str.lastIndexOf('/')
    let restoredRegex = ''
    let isRegexValid
    try {
      restoredRegex = new RegExp(str.slice(1, lastSlash), str.slice(lastSlash + 1))
      isRegexValid = true
    } catch (e) {
      isRegexValid = false
    }
  
    text = text
      .replace(/\n$/g, '\n\n')
      .replace(restoredRegex, '<mark>$&</mark>')
    
    if (this.isIE) {
      // IE wraps whitespace differently in a div vs textarea, this fixes it
      text = text.replace(/ /g, ' <wbr>')
    }
    
    return { highlightedText: text, isRegexValid }
  }
  
  handleScroll = () => {
    document.getElementById('backdrop').scrollTop = document.getElementById('text-area-2').scrollTop

    document.getElementById('backdrop').scrollLeft = document.getElementById('text-area-2').scrollLeft
  }
  
  onChange = (e, type) => {
    switch (type) {
      case 'regex-input':
        this.setState({
          regex: e.target.value
        })
        break
      case 'regex-input-modal':
        this.setState({
          regexModal: e.target.value
        })
        break
      case 'regex-name':
        this.setState({
          title: e.target.value
        })
        break
      case 'regex-name-modal':
        this.setState({
          titleModal: e.target.value
        })
        break
      case 'regex-description':
        this.setState({
          description: e.target.value
        })
        break
      case 'regex-description-modal':
        this.setState({
          descriptionModal: e.target.value
        })
        break
      case 'textarea':
        this.setState({
          unit: e.target.value
        }, () => {
        })
        break
      case 'textarea-modal':
        this.setState({
          unitModal: e.target.value
        }, () => {
        })
        break
      default:
        break
    }
  }

  convertRgbaToString = ({ r, g, b, a }) => {
    return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
  }

  openColorPickerVisible = (e) => {
    this.stop(e)
    this.setState({ colorPickerVisible: true })
  }

  closeAndSaveColorPicker = (color, e) => {
    this.stop(e)
    const convertedColor = this.convertRgbaToString(color.rgb)
    this.setState({ color: convertedColor, colorPickerVisible: false },
        () => {
          saveRegularExpressionsColor({color: convertedColor, folderPath: this.props.folder.path }).then((result) => {
            CustomNotificationManager.success('The colour was changed successfully.', 'Success')
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        })
  }

  saveRegularExpression = () => {
    const { regex, title, description } = this.state
    const data = {
      regex,
      title,
      description,
      folderPath: this.props.folder.path
    }
    if (title && description && regex) {
      apiSmartBoxSaveRegularExpression(data).then(() => {
        this.setState({
          description: '',
          title: '',
          regex: '',
          unit: '',
          visibleRegularExpressions: true
        }, () => {
          this.props.setSmartBoxFetchTreeData(true)
          this.fetchData()
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }

  stop = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  toggleAccordeon = (key) => {
    const { regexList }= this.state
    let  { regexActive }= this.state
    let regexOpen = key
    if (key.length) {
      regexOpen= key[key.length-1]
      if (regexList && regexList.length) {
        regexList.map( (item) => {
          if (item.id === regexOpen) {
            regexActive = item
          }
          return true
        })
      }
    }
    this.setState({ regexOpen, regexActive, expandKeysRegex: key })
  }
  savedSelectRegEx = () => {
    const { selectedList, regexList } = this.state
    let listSaveRegEx = []
    regexList && regexList.length > 0 && regexList.forEach((regEx) => {
      if (selectedList && selectedList.length > 0) {
        selectedList.forEach((select) => {
          if (regEx.id === select) {
            listSaveRegEx.push({
              title: regEx.title,
              description: regEx.description,
              regex: regEx.regex,
              id: regEx.id
            })
          }
        })

      } else {
        listSaveRegEx = []
      }
    })
    const saveRegexFolderDTO = {
      folderId: this.props.folder.id,
      folderAddRegexInputDTO: listSaveRegEx

    }
    savedRegularExpressions(saveRegexFolderDTO).then(() => {

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onExpandContentCollapse = (e) => {
    const { expandKeysRegex } = this.state
    if (expandKeysRegex && expandKeysRegex.length > 0) {
      this.setState({
        activeOpenRegEx: true
      })
    } else {
      this.setState({
        activeOpenRegEx: true
      })
    }
  }
  selectItem = (e, id) => {
    const { selectedList, regexList } = this.state
    let { selectAllChecked, saveStep } = this.state
    //this.stop(e)
    //e.preventDefault()
    //e.stopPropagation()
    // if (activeOpenRegEx === true) {
    //   this.setState({
    //     activeOpenRegEx: false
    //   })
    // } else {
    //   this.setState({
    //     activeOpenRegEx: false
    //   })
    // }
    if(e.target.checked) {
      selectedList.push(id)
    } else {
      let selectedKey = -1
      if (selectedList.includes(id)) {
        selectedList.map((item, key) => {
          if (item === id) {
            selectedKey = key
          }
          return true
        })
      }
      if (selectedKey >= 0) {
        selectedList.splice(selectedKey, 1)
      }
    }
    // set select all ckecked or unchecked
    if (regexList.length === selectedList.length) {
      selectAllChecked = true
    } else if (!e.target.checked) {
      selectAllChecked = false
    } else {
      if (!selectedList.length) {
        selectAllChecked = false
      }
    }
    // change step 0 to 1 when a radio button is checked
    if (selectedList.length && !saveStep) {
      saveStep = 1
    } else if (!selectedList.length) {
      saveStep = 0
    }
    // const uniqueSelectedList = selectedList && selectedList.length > 0 && selectedList.filter((a, i) => selectedList.findIndex((s) => a === s) === i)
    this.setState({ selectedList, selectAllChecked, saveStep, expandKeysRegex: [] }, () => {
      this.savedSelectRegEx()
    })
    this.props.onGetStatusRunAnalytics(false)
  }

  toggleEditModal = (e, visible, id=null) => {
    const { regexList }= this.state
    let { regexActive }= this.state
    e.stopPropagation()
    e.preventDefault()
    let editModalVisible= !this.state.editModalVisible
    if (visible) {
      editModalVisible= visible
      if (regexList && regexList.length) {
        regexList.map( (item) => {
          if (item.id === id) {
            regexActive = item
            this.setState({titleModal: item.title, descriptionModal: item.description, regexModal: item.regex})
          }
          return true
        })
      }
    }
    this.setState({ editModalVisible, regexActive })
  }

  submitEditForm = (e) => {
    const { regexList, regexActive, titleModal, descriptionModal, regexModal } = this.state
    const data = {
      id: regexActive.id,
      title: titleModal ? titleModal : regexActive.title,
      description: descriptionModal ? descriptionModal : regexActive.description,
      regex: regexModal ? regexModal : regexActive.regex,
      folderPath: this.props.folder.path
    }

    apiEditRegularExpression(data).then((response) => {
      if (response.status === 200) {
        let selectedKey = -1
        regexList.map( (item, key) => {
          if (item.id === regexActive.id) {
            selectedKey = key
          }
          return true
        })
        if (selectedKey >= 0) {
          regexList[selectedKey].title = titleModal ? titleModal : regexActive.title
          regexList[selectedKey].description = descriptionModal ? descriptionModal : regexActive.description
          regexList[selectedKey].regex = regexModal ? regexModal : regexActive.regex
        }
        this.setState({ editModalVisible: false, regexList })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  removeRegularExpressions = (e) => {
    const { selectedList, regexList } = this.state
    const newRegexList= []
    this.stop(e)
    if (selectedList.length) {
      if (regexList && regexList.length) {
        regexList.map( (item) => {
          if (! selectedList.includes(item.id)) {
            newRegexList.push(item)
          }
          return true
        })
      }
      this.setState({ regexList: newRegexList })
      const data = {ids: selectedList, folderPath: this.props.folder.path}
      deleteRegularExpressions(data).then((response) => {
        CustomNotificationManager.success('Selected regular expressions were deleted.', 'Success')
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }

  selectAllItems = (e) => {
    const { regexList } = this.state
    let { selectedList, selectAllChecked, saveStep } = this.state
    if(e.target.checked) {
      if (regexList && regexList.length) {
        regexList.forEach( (regex) => {
          selectedList.push(regex.id)
        })
        selectAllChecked = true
      }
      saveStep= 1
    } else {
      selectedList = []
      selectAllChecked = false
      saveStep= 0
    }
    this.setState({ selectedList, selectAllChecked, saveStep }, () => {
      this.savedSelectRegEx()
    })
    this.props.onGetStatusRunAnalytics(false)
  }

  selectRegularExpression = () => {
    const { selectedList, saveStep } = this.state
    const { folder } = this.props
    if (saveStep === 1) {
      const data = {ids: selectedList, folderPath: folder.path}
      selectRegularExpressions(data).then((response) => {
        CustomNotificationManager.success('Selected regular expressions were sent.', 'Success')
        if (selectedList.length) {
          this.setState({ saveStep: 2, optionsEnabled: false })
        } else {
          this.setState({ saveStep: 0, optionsEnabled: true })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else if (saveStep === 2) {
      this.setState({ saveStep: 1, optionsEnabled: true })

    }

  }

  renderEditModal = () => {
    const { editModalVisible, regexActive, titleModal, descriptionModal, regexModal } = this.state
    let submitCondition = false
    if (regexActive) {
      submitCondition = (titleModal || regexActive.title) && (descriptionModal || regexActive.description) && (regexModal || regexActive.regex)
    }
    return (
        <Modal className="import-terms-modal" width={700} title={ false } visible={ editModalVisible } onCancel={ (e) => this.toggleEditModal(e,false) } closable={ true } footer={ null } >
          <div className="import-terms-section">
            <h1 className="import-terms-title">Edit Regular Expression</h1>
            <br/>
            <div className="edit_form">
              <div className="insert-regular-expression">
                <label className="edit_form__label" htmlFor="edit_modal_name">Regex name:</label>
                <input className="input-insert-expression" id="edit_modal_name" onChange={ (e) => this.onChange(e, 'regex-name-modal') } value={titleModal} />
              </div>
              <div className="insert-regular-expression">
                <label className="edit_form__label" htmlFor="edit_modal_description">Regex description:</label>
                <input className="input-insert-expression" id="edit_modal_description" onChange={ (e) => this.onChange(e, 'regex-description-modal') } value={descriptionModal}/>
              </div>
              <div className="insert-regular-expression">
                <label className="edit_form__label" htmlFor="edit_modal_regex">Your regular expression:</label>
                <input className="input-insert-expression" id="edit_modal_regex" onChange={ (e) => this.onChange(e, 'regex-input-modal') } value={regexModal}/>
              </div>
              <div className="button-container">
                <button className={`button-green ${!submitCondition?'btn-disabled':''}`} onClick={(e)=>this.submitEditForm(e)} disabled={!submitCondition} >Update</button>
              </div>
            </div>
          </div>
        </Modal>
    )
  }

  renderEditIcon = (id, disabled) => {
    return (
      <span className={ disabled ? 'disabled regular-expression-edit' : 'icon-edit-b regular-expression-edit' } onClick={ (e) => disabled ? undefined : this.toggleEditModal(e, true, id) } />
    )
  }

  renderPopOver = () => {
    const { color, colorPickerVisible } = this.state
    const renderColorPicker = (
        <React.Fragment>
          <div className="business-term-color-picker">
            <CompactPicker
                className="color-picker-a"
                colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                  'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                  'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                  'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                  'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                  'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                  'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                disableAlpha={true}
                color={color}
                onChange={(color, e) => this.closeAndSaveColorPicker(color, e)}
            />
          </div>
        </React.Fragment>
    )
    return (
      <Popover content={renderColorPicker} trigger="click" visible={ colorPickerVisible } >
        <span className="regular-selected-color"  style={{backgroundColor: color}} onClick={ (e) => this.openColorPickerVisible(e) } />
      </Popover>
    )
  }
  thisActive = (isActive) => {
    return true
  }
  openInRegEx= (e) => {
    e.preventDefault()
    window.open(window.location.href)
    cookie.save('sendOpenRegEx', 'openIsTrue', { path: '/', maxAge: 60*60 })
  }
  onExpandRegEx = (e) => {
    const { expandKeysRegex } = this.state
    if (expandKeysRegex && expandKeysRegex.length > 0) {
      this.setState({
        activeOpenRegEx: true
      })
    } else {
      this.setState({
        activeOpenRegEx: true
      })
    }
  }

  onMouseEnter = (e) => {
    this.setState({
          activeEdit:true
        })
  }
  onMouseLeave = () => {
    this.setState({
      activeEdit:false
    })
  }

  render() {
    const { regexList, selectedList, selectAllChecked, expandKeysRegex, activeOpenRegEx,activeEdit } = this.state
    let { disabled, smartBoxFilesTable, disabledRegularExpressions } = this.props
    const { Panel } = Collapse
    //const allowAdd = title && description && regex
    const uniqueSelectedKeys = selectedList && selectedList.length > 0 && selectedList.filter((a, i) => selectedList.findIndex((s) => a === s) === i)
    if(typeof disabled === 'undefined') {
      disabled = false
    }
    // save button
    // let saveBtnTitle = 'Confirm Selection'
    // let saveBtnClass = 'btn-grey'
    // if (saveStep === 1) {
    //   saveBtnClass = 'btn-green-fill'
    // } else if (saveStep === 2) {
    //   saveBtnTitle = 'Update Selection'
    //   saveBtnClass = 'btn-green'
    // }
    return (
      <div className="access-expressions">
        <div className="access-expressions__contain-regular-expressions">
          {/*<Collapse
            defaultActiveKey={['list']}
            bordered={ false }
            expandIconPosition="right"
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} /> }
          >
            <Panel header="Your Regular Expressions" key="list" extra={ this.renderPopOver() }>*/}

              {/*<div className="access-expressions__content-inputs">*/}
              {/*  <div className="insert-regular-expression">*/}
              {/*    { disabled ?*/}
              {/*      <input readOnly className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-name') }*/}
              {/*             placeholder="Name" value={ title }/>*/}
              {/*      : <input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-name') }*/}
              {/*               placeholder="Name" value={ title }/>*/}
              {/*    }*/}
              {/*  </div>*/}
              {/*  <div className="insert-regular-expression">*/}
              {/*    { disabled ?*/}
              {/*      <input readOnly className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-description') }*/}
              {/*             placeholder="Description" value={ description }/>*/}
              {/*      :*/}
              {/*      <input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-description') }*/}
              {/*             placeholder="Description" value={ description }/>*/}
              {/*    }*/}
              {/*  </div>*/}
              {/*  <div className="insert-regular-expression">*/}
              {/*    { disabled ?*/}
              {/*      <input readOnly className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-input') }*/}
              {/*             placeholder="/ insert regular expression" value={ regex }/>*/}
              {/*      :*/}
              {/*      <input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-input') }*/}
              {/*             placeholder="/ insert regular expression" value={ regex }/>*/}
              {/*    }*/}
              {/*    <div className="add-regular-expression" onClick={ disabled ? undefined : this.saveRegularExpression }>*/}
              {/*      <button className="icon-add-b" disabled={ !allowAdd || disabled } style={{cursor: allowAdd ? 'pointer': 'not-allowed'}}/>*/}
              {/*      /!*<span className="text-add" style={{cursor: allowAdd ? 'pointer': 'not-allowed'}}>Add Regular Expression</span>*!/*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="access-expressions__content-radio">
                <div className={ activeOpenRegEx ? 'regexp-radio-check regexp-radio-check-update': 'regexp-radio-check regexp-radio-check-update regexp-close-collapse' }>
                  { regexList && regexList.length > 0 &&
                    <div className={ (uniqueSelectedKeys && uniqueSelectedKeys.length > 0 && regexList.length === uniqueSelectedKeys.length) ? 'select-all select-all-active' : regexList.length !== uniqueSelectedKeys.length && selectedList && selectedList.length > 0 ? "select-all select-children" : 'select-all' }>
                      <Checkbox
                        className="select-all__label"
                        checked={selectAllChecked}
                        //disabled={ (this.props.smartBoxParentStatisticsReady === 'false' && smartBoxFilesTable.length > 0) || disabled }
                        disabled={ disabledRegularExpressions === false }
                        onChange={(e) => this.selectAllItems(e)}
                        //disabled={!optionsEnabled || disabled}
                      >
                        {'Select'} all
                      </Checkbox>
                      <button className={ activeEdit ? "icon-v17-edit-active" : "icon-v17-edit select-all__delete"} disabled={ disabled }
                              //onClick={(e) => this.removeRegularExpressions(e)}
                          onClick={(e) => this.openInRegEx(e)}
                              onMouseEnter={(e)=> this.onMouseEnter(e)}
                              onMouseLeave={(e)=> this.onMouseLeave(e)}
                      />
                    </div>
                  }
                  { regexList && regexList.length > 0 && regexList.map((regex, key) =>
                    <div className="collapse-reg-ex-single" key={ key }>
                      <Checkbox
                          className= "radio-style"
                          checked= { selectedList.includes(regex.id) }
                          key= { regex.id }
                          //disabled={ (this.props.smartBoxParentStatisticsReady === 'false' && smartBoxFilesTable.length > 0) || disabled }
                          disabled={ disabledRegularExpressions === false }
                          //onClick= { e => this.stop(e)}
                          onChange={ (e)=> this.selectItem(e, regex.id) }
                          //disabled = { !optionsEnabled || disabled }
                      >
                      </Checkbox>
                  <Collapse
                    bordered={ false }
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => this.thisActive(isActive) }
                    onChange={ (key)=>this.toggleAccordeon(key) }
                  >
                    {/*{*/}
                    {/*  regexList && regexList.length > 0 && regexList.map((regex, key) =>*/}
                        <Panel
                          //extra={ this.renderEditIcon(regex.id, disabled) }
                          showArrow={true}
                          key={regex.id}
                          header={
                            <div className="collapse-content-update">
                            {/*<Checkbox*/}
                            {/*  className= "radio-style"*/}
                            {/*  checked= { selectedList.includes(regex.id) }*/}
                            {/*  key= { regex.id }*/}
                            {/*  disabled={ (this.props.smartBoxParentStatisticsReady === 'false' && smartBoxFilesTable.length > 0) || disabled }*/}
                            {/*  onClick= { e => this.stop(e)}*/}
                            {/*  onChange={ (e)=> this.selectItem(e, regex.id) }*/}
                            {/*  //disabled = { !optionsEnabled || disabled }*/}
                            {/*>*/}
                            {/*</Checkbox>*/}
                              <span onClick={(e) => this.onExpandContentCollapse(e)} className="content-collapse-reg-ex">
                                  {`${ regex.title }: `}<span
                                  className="text-radio">{ regex.description }<span onClick={ (e) => this.onExpandRegEx(e) }>{ expandKeysRegex && expandKeysRegex.length >0 && expandKeysRegex.includes(regex.id) ? <span className="icon-v16-arrow-medium"></span> : <span className="icon-v16-arrow-medium rotate-arrow-medium"></span>}</span></span>
                              </span>
                            </div>
                          }
                        >
                          <div className='panel-container'>
                            { (activeOpenRegEx) &&
                            <React.Fragment>
                              <div className='panel-container__left'>
                                <p><span className="font-weight-bold">Name:</span> {regex.title}</p>
                                <p><span className="font-weight-bold">Description:</span> {regex.description}</p>
                              </div>
                              <div className='panel-container__right'>
                                <p><span className="font-weight-bold">Regular expression:</span> {regex.regex}</p>
                              </div>
                            </React.Fragment>
                            }
                          </div>
                        </Panel>
                    {/*  )*/}
                    {/*}*/}
                  </Collapse>
                    </div>
                  )
                  }
                </div>
              </div>
          {/*</Panel>
            <Panel header="Regular Expression Builder" key="add">*/}

          {/*</Panel>*/}
          {/*  { regexList && regexList.length > 0 &&*/}
          {/*  <div className="scan-now-container">*/}
          {/*    <button className={`button-green ${ saveBtnClass }`} disabled={!saveStep || disabled } onClick={ () => this.selectRegularExpression() } >*/}
          {/*      { saveBtnTitle }*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*  }*/}
          {/*</Collapse>*/}
        </div>
        { this.renderEditModal() }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  smartBoxParentStatisticsReady: state.smartBox.smartBoxParentStatisticsReady,
})
RegularExpressions = withRouter(RegularExpressions)
export default connect(mapStateToProps, { setSmartBoxFetchTreeData })(RegularExpressions)