import React from 'react'
import {
  apiSmartBoxGetNewQueryStatus,
  apiSmartBoxSaveNewQueryStatus,
  getSmartBoxScanAnnotations,
  apiSendKeysHelper,
  apiSmartBoxSaveNewQueryStatusOcr,
  apiSmartBoxUpdateRedactionNumber,
  saveKeysRedactedBusiness,
  apiSmartBoxCheckAllCanvasPoints,
  apiSmartBoxSaveXeroxRedactionSmartView
} from '../../../api/SmartBox'
import { getAnnotationColorsByClientId, getScanAnnotations, updateAnnotationColor } from '../../../api/Sar'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { Tree, notification, Icon, Spin } from 'antd'
import PropTypes from 'prop-types'
import '../Tracker/Tracker.scss'
import './AiAnnotationsSidebar.scss'
import { connect } from 'react-redux'
//import ReviewRedact from './ReviewRedact'
import {
  generateHtmlForSecurityScanResult,
  renderTreeNodes,
  markWordsOnDocumentForCategory,
  timerCheckCanvas,
  markWordsOnDocumentForCategoryCopy
} from '../../../utils/SmartBox/SmartBoxMethods'
import swal from 'sweetalert2'
import { categories } from '../../../utils/AITagsNew'

let timerCheckMarkReady

class StandardTab extends React.Component {

  state = {
    docAnnotations: [],
    docAnnotationsColors: [],
    rowSelectedCategory: '',
    displayColorPicker: [],
    lastSelectedColor: '',
    defaultActiveKeys: [ 'names', 'locations', 'numbers', 'time', 'addresses', 'identifiers', 'organisations' ],
    categoriesColors: {
      names: '',
      locations: '',
      numbers: '',
      time: '',
      addresses: '',
      identifiers: '',
      organisations: '',
    },
    namesSection: '',
    locationsSection: '',
    numbersSection: '',
    timeSection: '',
    addressesSection: '',
    identifiersSection: '',
    organisationsSection: '',
    categoriesVisible: {
      names: true,
      locations: true,
      numbers: true,
      time: true,
      addresses: true,
      organisations: true,
      categories: true,
      identifiers: true
    },
    checkList: [],
    treeDataAnnotations: [ {
      title: 'Select All',
      key: 'select-all',
      identifier: 'top-parent',
      children: []
    } ],
    treeDataAnnotationsOcr: [ {
      title: 'Select All',
      key: 'select-all',
      identifier: 'top-parent',
      children: []
    } ],
    treeDataAnnotationsXerox: [ {
      title: 'Select All',
      key: 'select-all',
      identifier: 'top-parent',
      children: []
    } ],
    initCheckedKeys: [],
    checkedKeys: [],
    unCheckedKeys: [],
    selectedKeys: [],
    expandedKeys: [ 'select-all', 'names', 'locations', 'numbers', 'time', 'addresses', 'identifiers', 'organisations' ],
    autoExpandParent: true,
    redactedKeys: [],
    docAnnotationsNewQuery: [],
    docAnnotationCategories: [],
    checkedChildrenNew: [],
    docAnnotationsChildrenKeys: [],
    selectedChildrenBold: [],
    disableCheckedKeys: '',
    checkStatusChildren: [],
    sendStatusOnClearAll: false,
    selectClassDisabled: '',
    selectedPgNrBoldStandard: [],
    docAnnotationsOCR: [],
    isOcr: false,
    checkedKeysOcr: [],
    redactedDisplayHover: {},
    disabledCheckBoxTree: false,
    checkedKeysNew: [],
    allTermsChildren: [],
    showVisibleCategory: {},
    statusSaveQuery: false,
    keysSavedOcr: [],
    classListBusiness: [],
    itemSelectForRedact: {},
    setIntervalCheckPdfCanvas: 0,
    responseCheckPdfCanvas: '',
    docAnnotationsXerox: [],
    xeroxUpload: false,
    keysSavedXerox: [],
    showHideCategoryItems: []
  }
  
  componentDidMount() {
    if (this.props.isDocumentReady) {
      //this.fetchData(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
      //this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
      //this.checkNode()
      this.setState({
        isOcr: this.props.isOcr,
        xeroxUpload: this.props.xeroxUpload
      })
      if (this.props.isOcr) {
        this.onCheckKeysSavedOcr()
      }
      if (this.props.xeroxUpload) {
        this.onCheckKeysSavedXerox()
      }
    }
    // this.setState({
    //   checkedKeys: this.props.alreadyRedactedKeys.standard
    // })
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkForDocAnnotationCategories()
    if (prevProps.isDocumentReady !== this.props.isDocumentReady) {
      //this.fetchData(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
      //this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
      //this.checkNode()
      this.setState({
        isOcr: this.props.isOcr,
        xeroxUpload: this.props.xeroxUpload,
      })
    }
    if (Object.keys(prevProps.docAnnotationsStandard).length !== Object.keys(this.props.docAnnotationsStandard).length && Object.keys(this.props.docAnnotationsStandard).length > 0 && !this.props.statusNextDocumentStandard) {
      this.props.onGetStatusNextDocumentStandard(false)
      this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
      //this.checkNode()
    }
    if (prevProps.expandedKeysAll.length !== this.props.expandedKeysAll.length && this.props.expandedKeysAll.length > 0) {
      this.setState({
        expandedKeys: [...this.state.expandedKeys, ...this.props.expandedKeysAll ]
      })
      //this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
    }
    if (prevProps.isNextDocumentId !== this.props.isNextDocumentId) {
        const { treeDataAnnotations } = this.state
        // treeDataAnnotations[0].children = []
        // this.setState({
        //   docAnnotations: [],
        // })
        const folderPath = `${this.props.isNextDocumentId.folderPath}${this.props.isNextDocumentId.path}`
        //setTimeout(() => {
          //this.fetchDataNextDocument(folderPath, this.props.clientId, this.props.userId, this.props.isNextDocumentId.id, this.props.allPagesRendered && !prevProps.allPagesRendered)
          //this.fetchDataNextDocumentUpdate(folderPath, this.props.clientId, this.props.userId, this.props.isNextDocumentId.id, this.props.allPagesRendered && !prevProps.allPagesRendered)
          //this.checkNode()
        ///}, 3000);
        this.setState({
          isOcr: this.props.isOcr,
          xeroxUpload: this.props.xeroxUpload,
        })
    }
    if (prevProps.updateRedactAllStatus !== this.props.updateRedactAllStatus) {
      this.setState({
        checkedKeys: this.props.checkedKeysAllStandard
      })
    }
    if (prevProps !== this.props && this.props.isOcr && this.props.alreadyRedactedKeys && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0) {
      const listKeysOcr = []
      this.props.alreadyRedactedKeys.standard.forEach((keys) => {
        const keysNew = keys.substr(0, keys.indexOf('-'))
        const str2 = keysNew.toUpperCase()
        listKeysOcr.push(str2)
      })
      this.setState({
        keysSavedOcr: listKeysOcr,
      })
    }
    if (prevProps.docAnnotationCategoriesStandard  !== this.props.docAnnotationCategoriesStandard ) {
      this.setState({
        docAnnotationCategories: this.props.docAnnotationCategoriesStandard
      })
    }
    if (prevProps !== this.props && this.props.getAllChildrenStandardUpdate.length > 0 && this.props.statusNextDocumentStandard) {
      this.props.onGetStatusNextDocumentStandard(false)
      this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
      //this.checkNode()
    }

    if (prevProps.statusHideAllHighlighting !== this.props.statusHideAllHighlighting) {
      const { treeDataAnnotations, categoriesVisible } = this.state
      if (!this.props.statusHideAllHighlighting) {
        treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((item2) => {
          const aiAnnotations = document.querySelectorAll(`.${item2.key}`)
          Object.keys(categoriesVisible) && Object.keys(categoriesVisible).length > 0 && Object.keys(categoriesVisible).forEach((cat) => {
            categoriesVisible[cat] = this.props.statusHideAllHighlighting
          })
          this.setState({
            // categoriesVisible: {
            //   ...this.state.categoriesVisible, [item2.key]: this.props.statusHideAllHighlighting
            // }
          }, () => {
            for (const aiAnnotate of aiAnnotations) {
              aiAnnotate.style.background = 'transparent'
            }
          })
        })
      } else {
        treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((item2) => {
          const aiAnnotations = document.querySelectorAll(`.${item2.key}`)
          Object.keys(categoriesVisible) && Object.keys(categoriesVisible).length > 0 && Object.keys(categoriesVisible).forEach((cat) => {
            categoriesVisible[cat] = this.props.statusHideAllHighlighting
          })
          this.setState({
            // categoriesVisible: {
            //   ...this.state.categoriesVisible, [item2.key]: this.props.statusHideAllHighlighting
            // }
          }, () => {
            for (const aiAnnotate of aiAnnotations) {
             aiAnnotate.style.background = this.state.categoriesColors[item2.key]
            }
          })
        })
      }
    }
  }
  onCheckKeysSavedOcr = () => {
    const listKeys = []
    this.props.alreadyRedactedKeys && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 && this.props.alreadyRedactedKeys.standard.forEach((keys) => {
      const keysNew = keys.substr(0, keys.indexOf('-'))
      //const str2 = keysNew.charAt(0).toUpperCase() + keysNew.slice(1)
      const str2 = keysNew.toUpperCase()
      listKeys.push(str2)
    })
    this.setState({
      keysSavedOcr: listKeys,
    })
  }

  onCheckKeysSavedXerox = () => {
    const listKeys = []
    this.props.alreadyRedactedKeys && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 && this.props.alreadyRedactedKeys.standard.forEach((keys) => {
      const keysNew = keys.substr(0, keys.indexOf('-'))
      //const str2 = keysNew.charAt(0).toUpperCase() + keysNew.slice(1)
      const str2 = keysNew.toUpperCase()
      listKeys.push(str2)
    })
    this.setState({
      keysSavedXerox: listKeys,
    })
  }
  fetchDataNextDocumentUpdate = async(docId, clientId, userId, docId2, allPagesRendered) => {
    const { docAnnotationsStandard, docAnnotationsColorsStandard, categoriesColorsStandard, expandedKeysAll } = this.props
    let { expandedKeys } = this.state
    let { docAnnotationCategories, treeDataAnnotations } = { ...this.state }
    // treeDataAnnotations[0].children = []
    // docAnnotationCategories = []
    // this.setState({
    //   docAnnotationCategories,
    //   treeDataAnnotations,
    // })
    this.setState({
      docAnnotations: docAnnotationsStandard,
      docAnnotationsOCR: docAnnotationsStandard,
      docAnnotationsXerox: docAnnotationsStandard,
      docAnnotationsColors: docAnnotationsColorsStandard,
      categoriesColors: categoriesColorsStandard,
      //expandedKeys
    }, () => {
      this.props.onCheckAiAnnotations(true)
      this.renderCategoriesSectionsNextDocumentUpdate()
    })
  }
  fetchDataNextDocument = async(docId, clientId, userId, docId2, allPagesRendered) => {
    let response1
    if (this.props.isSmartBoxApplication) {
      response1 = await getSmartBoxScanAnnotations(docId)
    } else {
      response1 = await getScanAnnotations(docId)
    }
    const response2 = await getAnnotationColorsByClientId(clientId, userId)
    this.props.onGetStatusResponseQuery(response1.status)
    //this.props.onDocAnnotationsOcr(response1.data)
    this.setState({
      docAnnotations: response1.data,
      docAnnotationsOCR: response1.data,
      docAnnotationsXerox: response1.data,
      docAnnotationsColors: response2.data,
      categoriesColors: {
        names: response2.data.filter((annColor) => annColor.category === 'names')[0].color,
        locations: response2.data.filter((annColor) => annColor.category === 'locations')[0].color,
        numbers: response2.data.filter((annColor) => annColor.category === 'numbers')[0].color,
        time: response2.data.filter((annColor) => annColor.category === 'time')[0].color,
        addresses: response2.data.filter((annColor) => annColor.category === 'addresses')[0].color,
        identifiers: response2.data.filter((annColor) => annColor.category === 'identifiers')[0].color,
        organisations: response2.data.filter((annColor) => annColor.category === 'organisations')[0].color
      }
    }, () => {
      if (!Object.keys(response1.data).length) {
        //CustomNotificationManager.error('No AI annotations available for this document, yet.', 'No annotations available', '')
        // notification.info({
        //   message: `No Ai results found`,
        //   className: 'no-ai-results-found',
        //   duration: 3
        // })
        this.props.hideLoadingBeforeMark()
      } else if (allPagesRendered || Object.keys(response1.data).length) {
        setTimeout(() => {
          this.renderCategoriesSectionsNextDocument()
        }, 3000);
      }
    })
  }

  renderCategoriesSectionsNextDocumentUpdate = () => {
    let { categoriesColors, treeDataAnnotations, docAnnotations, docAnnotationCategories, treeDataAnnotationsOcr, expandedKeys,
      // checkedKeys
    } = this.state
    const { landscape } = this.props
    const { docAnnotationsStandard, docAnnotationsColorsStandard, categoriesColorsStandard, treeDataAnnotationsStandard, expandedKeysAll } = this.props
    timerCheckMarkReady = setInterval(() => {
      if (global.itemsToBeMarked === global.markedTimes) {
        clearInterval(timerCheckMarkReady)
        this.props.hideLoadingBeforeMark()
        this.setState({
          statusSaveQuery: true
        }, () => {
          this.fetchDataNewQueryStatus(this.props.idDocument, docAnnotationCategories)
        })
        if (this.props.isSmartBoxApplication && this.props.statusNextDocumentStandard) {
          this.saveKeysForClearAll(this.props.idDocument, treeDataAnnotationsStandard)
        }
        const treeData = treeDataAnnotationsStandard
        this.props.setTreeDataAnnotations(treeDataAnnotationsStandard)
        this.props.allChildrenTree(treeDataAnnotationsStandard)
        this.props.treeDataFilter(treeData)
        // if (treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
        //   checkedKeys = this.props.alreadyRedactedKeys.standard
        // }
        //expandedKeys = [...expandedKeys, ...expandedKeysAll ],
        this.setState({
          treeDataAnnotations: treeDataAnnotationsStandard,
          docAnnotationCategories,
          treeDataAnnotationsOcr: treeDataAnnotationsStandard,
          //expandedKeys,
          autoExpandParent: false
        }, () => {
          if (this.props.isOcr) {
            this.checkDocAnnotationCategoryOcr()
          }
        })
        global.itemsToBeMarked = 0
        global.markedTimes = 0
      }
    }, 100)
  }
  renderCategoriesSectionsNextDocument = () => {
    const { treeDataAnnotations, docAnnotations, treeDataAnnotationsOcr } = this.state
    let { expandedKeys, categoriesColors, docAnnotationCategories } = { ...this.state }
    const { landscape } = this.props
    treeDataAnnotations[0].children = []
    //categoriesColors = {}
    docAnnotationCategories = []
    for (const categoryColor of Object.keys(categoriesColors)) {
      markWordsOnDocumentForCategory(categoryColor, categoriesColors[categoryColor], docAnnotations, landscape, categories)
    }
    timerCheckMarkReady = setInterval(() => {
      if (global.itemsToBeMarked === global.markedTimes) {
        clearInterval(timerCheckMarkReady)
        this.props.hideLoadingBeforeMark()
        for (const categoryColor of Object.keys(categoriesColors)) {
          const { result, expandedSubCategoryParent } = generateHtmlForSecurityScanResult(categoryColor, categoriesColors[categoryColor], docAnnotations, landscape, categories, docAnnotationCategories)
          expandedKeys = [ ...expandedKeys, ...expandedSubCategoryParent ]
          treeDataAnnotations[0].children.push(result)
          treeDataAnnotationsOcr[0].children.push(result)
        }
        this.setState({
          statusSaveQuery: true
        }, () => {
          this.fetchDataNewQueryStatus(this.props.idDocument, docAnnotationCategories)
        })
        if (this.props.isSmartBoxApplication) {
          this.saveKeysForClearAll(this.props.idDocument, treeDataAnnotations)
        }
        this.props.checkedKeysObjectRefsStandard(this.state.checkedKeysObjectRefs)
        this.props.setTreeDataAnnotations(treeDataAnnotations)
        this.setState({
          treeDataAnnotations,
          docAnnotationCategories,
          treeDataAnnotationsOcr,
          expandedKeys: [ ...expandedKeys, ...[ 'names', 'locations', 'numbers', 'time', 'addresses', 'identifiers', 'organisations' ] ],
          autoExpandParent: false
        }, () => {
          if (this.props.isOcr) {
            this.checkDocAnnotationCategoryOcr()
          }
        })
        global.itemsToBeMarked = 0
        global.markedTimes = 0
      }
    }, 100)
  }
  fetchDataNextDocumentStandard = () => {
    // this.fetchData(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
    // this.checkNode()
    // this.setState({
    //   isOcr: this.props.isOcr
    // })
  }
  fetchDataNewQueryStatus = (docId, docAnnotationCategories) => {
    const newDocAnnotationCategories = this.changeDataAnnotationCategory(docAnnotationCategories)
    const data = {
      idDocument: docId,
      orderCategory: newDocAnnotationCategories
    }
    if (this.props.isSmartBoxApplication) {
      apiSmartBoxGetNewQueryStatus(data).then((response) => {

        const annotateChecked = []
        this.props.setQueryStatus(docAnnotationCategories)
        this.setState({
          statusSaveQuery: false
        })
        Object.keys(response.data).map((item) => {
          if (response.data[item].length) {
            response.data[item].map((value) => {
              if (value.status) {
                annotateChecked.push(value.key)
              }
              return true
            })
          }
          return true
        })
        // this.props.setNumberOfAnnotateSelected(annotateChecked)
        const data = []
        for (const key in response.data) {
          data.push({ key: key, name: key, children: response.data[key] })
        }
        this.checkNode()
        this.setState({
          docAnnotationsNewQuery: data
        }, () => {
          this.checkNode()
          if (this.props.isOcr) {
            this.checkNodeOcr()
          }

        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }

  }
  checkNodeOcr = () => {
    const { docAnnotationsNewQuery } = this.state
    const keysStatusOcr = []
    docAnnotationsNewQuery && docAnnotationsNewQuery.length > 0 && docAnnotationsNewQuery.forEach((newKey) => {
      newKey && newKey.children && newKey.children.length > 0 && newKey.children.forEach((children) => {
        if (children.status === 1) {
          keysStatusOcr.push(children.text)
        }
      })
    })
    this.setState({
      checkedKeys: keysStatusOcr,
      checkedKeysNew: keysStatusOcr
    })
  }

  renderCategoriesSectionsUpdate = () => {
    let { categoriesColors, treeDataAnnotations, docAnnotations, docAnnotationCategories, treeDataAnnotationsOcr, expandedKeys,
       checkedKeys
    } = this.state
    const { landscape } = this.props
    const { docAnnotationsStandard, docAnnotationsColorsStandard, categoriesColorsStandard, treeDataAnnotationsStandard, expandedKeysAll, docAnnotationCategoriesStandard } = this.props
    timerCheckMarkReady = setInterval(() => {
      if (global.itemsToBeMarked === global.markedTimes) {
        clearInterval(timerCheckMarkReady)
        this.props.hideLoadingBeforeMark()
        this.setState({
          statusSaveQuery: true
        }, () => {
          //this.fetchDataNewQueryStatus(this.props.idDocument, docAnnotationCategories)
        })
        if (this.props.isSmartBoxApplication) {
          this.saveKeysForClearAll(this.props.idDocument, treeDataAnnotationsStandard)
        }
        const treeData = treeDataAnnotationsStandard
        this.props.setTreeDataAnnotations(treeDataAnnotationsStandard)
        this.props.allChildrenTree(treeDataAnnotationsStandard)
        this.props.treeDataFilter(treeData)
        // if (treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
        //   checkedKeys = this.props.alreadyRedactedKeys.standard
        // }
        //expandedKeys = [...expandedKeys, ...expandedKeysAll ],
        const checkedKeysUpdateMount = []
        if (this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0) {
        if (treeDataAnnotationsStandard[0].children && treeDataAnnotationsStandard[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
          treeDataAnnotationsStandard[0].children.forEach((children1) => {
            children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
              children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                children3 && children3.children && children3.children.length > 0 && children3.children.forEach((children4) => {
                  if ((this.props.alreadyRedactedKeys.standard.includes(`${children4.name.toLowerCase()}-${children4.index}`)) ||
                      this.props.alreadyRedactedKeys.standard.includes(`${children4.name.toLowerCase()}-all`)) {
                    checkedKeysUpdateMount.push(children4.key)
                  }
                })
              })
            })
          })
        }
          checkedKeys = checkedKeysUpdateMount
        }
        this.setState({
          treeDataAnnotations: treeDataAnnotationsStandard,
          docAnnotationCategories,
          treeDataAnnotationsOcr: treeDataAnnotationsStandard,
          treeDataAnnotationsXerox: treeDataAnnotationsStandard,
          //expandedKeys,
          autoExpandParent: false,
          checkedKeys
          //checkedKeys: ['JamieaBrown-7', 'jamieabrownagravicusacom-3']
        }, () => {
          //this.fetchDataNewQueryStatus(this.props.idDocument, docAnnotationCategories)
          if (this.props.isOcr) {
            this.checkDocAnnotationCategoryOcrUpdate()
          } else
            if (this.props.xeroxUpload) {
              this.checkDocAnnotationCategoryXeroxUpdate()
            }
          this.checkNode()
          this.onColourAnnotations()
        })
        global.itemsToBeMarked = 0
        global.markedTimes = 0
      }
    }, 100)
  }
  fetchDataUpdate = () => {
    const { docAnnotationsStandard, docAnnotationsColorsStandard, categoriesColorsStandard, expandedKeysAll } = this.props
    let { expandedKeys } = this.state
    expandedKeys = [...expandedKeys, ...expandedKeysAll ],
    this.setState({
      docAnnotations: docAnnotationsStandard,
      docAnnotationsOCR: docAnnotationsStandard,
      docAnnotationsXerox: docAnnotationsStandard,
      docAnnotationsColors: docAnnotationsColorsStandard,
      categoriesColors: categoriesColorsStandard,
      expandedKeys
      //expandedKeys
    }, () => {
      this.props.onCheckAiAnnotations(true)
      this.renderCategoriesSectionsUpdate()
    })
  }
  fetchData = async(docId, clientId, userId, docId2, allPagesRendered) => {
    let response1
    if (this.props.isSmartBoxApplication) {
      response1 = await getSmartBoxScanAnnotations(docId)
      //response1.data.doc_annotate_organization_company = ["Gravicus", "GVC", "Skype", "com"]
    } else {
      response1 = await getScanAnnotations(docId)
    }
    const response2 = await getAnnotationColorsByClientId(clientId, userId)
    this.props.onGetStatusResponseQuery(response1.status)
    if (Object.keys(response1.data).length === 0 && Object.keys(this.props.docAnnotationsRegEx).length === 0 && Object.keys(this.props.docAnnotationsList).length === 0) {
      //CustomNotificationManager.error('No AI annotations available for this document, yet.', 'No annotations available')
      notification.info({
        message: `No Ai results found`,
        className: 'no-ai-results-found',
        duration: 3
      })
    }
    //this.props.onDocAnnotationsOcr(response1.data)
    this.setState({
      docAnnotations: response1.data,
      //docAnnotations: {"doc_annotate_location_city":["PETERBOROUGH","PHILADELPHIA"],"doc_annotate_address_phone":["07.561.2046"],"doc_annotate_person":["Will Smith"],"doc_annotate_identifier":["SMITH707237W99RG"],"doc_annotate_date":["07.11.2016","07.11.2046"],"doc_annotate_address_complete":["2046","2046 8. 125 CLARENCE ROAD","8. 125 CLARENCE ROAD"],"doc_annotate_location_country":["UK","USA"],"doc_annotate_address_postcode":["PE1 2LE"]},
      //docAnnotations: {"doc_annotate_address_phone":['02836 394013', 'much', "blechen's", "vardy", "rooney's", "mr wayne"]},
      //docAnnotations: {"doc_annotate_address_phone":['06/06/2023,16:38', '06/06/2023']},
      // docAnnotations: {"doc_annotate_address_url":["http://www.linkedin.com/in/harrisongowers", "http://www.gravicus.com", "http://www.linkedin.com", "www.gravicus.com"],
      // "doc_annotate_organization_company": ["Linkedin"]},
      docAnnotationsOCR: response1.data,
      docAnnotationsXerox: response1.data,
      //docAnnotationsOCR: {"doc_annotate_location_city":["PETERBOROUGH","PHILADELPHIA"],"doc_annotate_address_phone":["07.561.2046"],"doc_annotate_person":["Will Smith"],"doc_annotate_identifier":["SMITH707237W99RG"],"doc_annotate_date":["07.11.2016","07.11.2046"],"doc_annotate_address_complete":["2046","2046 8. 125 CLARENCE ROAD","8. 125 CLARENCE ROAD"],"doc_annotate_location_country":["UK","USA"],"doc_annotate_address_postcode":["PE1 2LE"]},
      docAnnotationsColors: response2.data,
      categoriesColors: {
        names: response2.data.filter((annColor) => annColor.category === 'names')[0].color,
        locations: response2.data.filter((annColor) => annColor.category === 'locations')[0].color,
        numbers: response2.data.filter((annColor) => annColor.category === 'numbers')[0].color,
        time: response2.data.filter((annColor) => annColor.category === 'time')[0].color,
        addresses: response2.data.filter((annColor) => annColor.category === 'addresses')[0].color,
        identifiers: response2.data.filter((annColor) => annColor.category === 'identifiers')[0].color,
        organisations: response2.data.filter((annColor) => annColor.category === 'organisations')[0].color
      }
    }, () => {
      if (Object.keys(response1.data).length === 0 && this.props.treeRegEx.length === 0 && this.props.treeBusiness.length === 0) {
        //CustomNotificationManager.error('No AI annotations available for this document, yet.', 'No annotations available')
        // notification.info({
        //   message: `No Ai results found`,
        //   className: 'no-ai-results-found',
        //   duration: 3
        // })
        this.props.hideLoadingBeforeMark()
        this.props.onCheckAiAnnotations(false)
      } else if (allPagesRendered || Object.keys(response1.data).length) {
        // setTimeout(() => {
        //   this.renderCategoriesSections()
        // }, 5000)
        //this.renderCategoriesSections()
        this.renderCategoriesSectionsCopy()
        this.props.onCheckAiAnnotations(true)
      }
    })
  }
  onColourAnnotations = () => {
    const { treeDataAnnotations, categoriesVisible } = this.state
    const { activeKey } = this.props

    treeDataAnnotations && treeDataAnnotations[0].children.map((keyColour) => {
      const aiAnnotations = document.querySelectorAll(`.${ keyColour.key }`)
      for (const aiAnnotate of aiAnnotations) {
        if (activeKey === 'standardTab' && categoriesVisible[keyColour.key]) {
          aiAnnotate.style.background = keyColour.backgroundColor
          aiAnnotate.style.borderColor = keyColour && keyColour.backgroundColor ? keyColour.backgroundColor.replace('0.3', '1') : ''
        } else {
          aiAnnotate.style.background = 'transparent'
          aiAnnotate.style.borderColor = 'initial'
        }
      }
      return true
    })
  }

  checkDocAnnotationCategoryOcrUpdate = () => {
    const { docAnnotationsOCR, treeDataAnnotationsOcr } = this.state
    const { treeDataAnnotationsStandard, docAnnotationCategoriesStandard, docAnnotationsStandardOcr } = this.props
    treeDataAnnotationsStandard && treeDataAnnotationsStandard[0].children && treeDataAnnotationsStandard[0].children.length > 0 && treeDataAnnotationsStandard[0].children.forEach((children1) => {
      children1.children.length > 0 && children1.children.forEach((children2) => {
        if (Object.keys(docAnnotationsStandardOcr) && Object.keys(docAnnotationsStandardOcr).length) {
          Object.keys(docAnnotationsStandardOcr).forEach((category) => {
            let title = category && category.replace('doc_annotate_', '')
            title = title && title.replace('_', ' ')
            if (children2.key === title) {
              docAnnotationsStandardOcr[category] && docAnnotationsStandardOcr[category].length > 0 && docAnnotationsStandardOcr[category].forEach((nameParent, index) => {
                const parentAllCategory = {
                  backgroundColor: children2.backgroundColor,
                  children: [],
                  key: `${ nameParent }`,
                  name: nameParent,
                  index: index,
                  title: nameParent,
                  pageNumber: '0',
                  isOcr: this.props.isOcr,
                  xeroxUpload: this.props.xeroxUpload,
                }
                children2.children.push(parentAllCategory)
              })
            }
          })
        }
        else
        if (Object.keys(docAnnotationsOCR) && Object.keys(docAnnotationsOCR).length) {
          Object.keys(docAnnotationsOCR).forEach((category) => {
            let title = category && category.replace('doc_annotate_', '')
            title = title && title.replace('_', ' ')
            if (children2.key === title) {
              docAnnotationsOCR[category] && docAnnotationsOCR[category].length > 0 && docAnnotationsOCR[category].forEach((nameParent, index) => {
                const parentAllCategory = {
                  backgroundColor: children2.backgroundColor,
                  children: [],
                  key: `${ nameParent }`,
                  name: nameParent,
                  index: index,
                  title: nameParent,
                  pageNumber: '0',
                  isOcr: this.props.isOcr,
                  xeroxUpload: this.props.xeroxUpload,
                }
                children2.children.push(parentAllCategory)
              })
            }
          })
        }
      })

    })
    this.setState({
      treeDataAnnotations: treeDataAnnotationsOcr
    })
  }

  checkDocAnnotationCategoryXeroxUpdate = () => {
    const { docAnnotationsXerox, treeDataAnnotationsXerox } = this.state
    const { treeDataAnnotationsStandard, docAnnotationCategoriesStandard } = this.props
    treeDataAnnotationsStandard && treeDataAnnotationsStandard[0].children && treeDataAnnotationsStandard[0].children.length > 0 && treeDataAnnotationsStandard[0].children.forEach((children1) => {
      children1.children.length > 0 && children1.children.forEach((children2) => {
        if (Object.keys(docAnnotationsXerox) && Object.keys(docAnnotationsXerox).length) {
          Object.keys(docAnnotationsXerox).forEach((category) => {
            let title = category && category.replace('doc_annotate_', '')
            title = title && title.replace('_', ' ')
            if (children2.key === title) {
              docAnnotationsXerox[category] && docAnnotationsXerox[category].length > 0 && docAnnotationsXerox[category].forEach((nameParent, index) => {
                const parentAllCategory = {
                  backgroundColor: children2.backgroundColor,
                  children: [],
                  key: `${ nameParent }`,
                  name: nameParent,
                  index: index,
                  title: nameParent,
                  pageNumber: '0',
                  isOcr: this.props.isOcr,
                  xeroxUpload: this.props.xeroxUpload,
                }
                children2.children.push(parentAllCategory)
              })
            }
          })
        }
      })

    })
    this.setState({
      treeDataAnnotations: treeDataAnnotationsXerox
    })
  }


  checkDocAnnotationCategoryOcr = () => {
    const { docAnnotationsOCR, treeDataAnnotationsOcr } = this.state
    treeDataAnnotationsOcr && treeDataAnnotationsOcr[0].children && treeDataAnnotationsOcr[0].children.length > 0 && treeDataAnnotationsOcr[0].children.forEach((children1) => {
      children1.children.length > 0 && children1.children.forEach((children2) => {
        if (docAnnotationsOCR && Object.keys(docAnnotationsOCR).length) {
          Object.keys(docAnnotationsOCR).forEach((category) => {
            let title = category && category.replace('doc_annotate_', '')
            title = title && title.replace('_', ' ')
            if (children2.key === title) {
              docAnnotationsOCR[category] && docAnnotationsOCR[category].length > 0 && docAnnotationsOCR[category].forEach((nameParent, index) => {
                const parentAllCategory = {
                  backgroundColor: children2.backgroundColor,
                  children: [],
                  key: `${ nameParent }`,
                  name: nameParent,
                  index: index,
                  title: nameParent,
                  pageNumber: '0',
                  isOcr: this.props.isOcr,
                  xeroxUpload: this.props.xeroxUpload,
                }
                children2.children.push(parentAllCategory)
              })
            }
          })
        }
      })
      
    })
    this.setState({
      treeDataAnnotations: treeDataAnnotationsOcr
    })
  }
  changeDataAnnotationCategory = (docAnnotationCategories) => {
    const { treeDataAnnotations } = this.state
    const newDocAnnotationCategories = []
    if (treeDataAnnotations && treeDataAnnotations.length) {
      const tree = treeDataAnnotations[0]
      if (tree.children && tree.children.length) {
        tree.children.forEach((parent) => {
          if (parent.children && parent.children.length) {
            parent.children.forEach((children) => {
              const temp = `doc_annotate_${ children.key.split(' ').join('_') }`
              if (docAnnotationCategories.includes(temp)) {
                if (children.children.length) {
                  let numberNumberOfChildren = 0
                  children.children.forEach((nephew) => {
                    numberNumberOfChildren += nephew.children.length
                  })
                  newDocAnnotationCategories.push({ 'name': temp, 'children': numberNumberOfChildren })
                } else {
                  newDocAnnotationCategories.push({ 'name': temp, 'children': children.children.length })
                }
              }
            })
          }
        })
      }
    }
    return newDocAnnotationCategories
  }

  checkForDocAnnotationCategories = () => {
    const { docAnnotations } = this.state
    const docAnnotationCategories = []
    const notAnnotationCategories = ['doc_annotated', 'doc_annotate_address', 'doc_annotate_keyword']
    if (docAnnotations && Object.keys(docAnnotations).length) {
      Object.keys(docAnnotations).map((category) => {
        if (!notAnnotationCategories.includes(category)) {
          docAnnotationCategories.push(category)
        }
        return true
      })
      if (!this.state.docAnnotationCategories.length) {
        this.setState({ docAnnotationCategories })
      }
    }
  }
  saveKeysForClearAll = (idDocument, treeData) => {
    const data = {
      id: idDocument,
      treeData: treeData
    }
    apiSendKeysHelper(data).then(() => {

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }
  renderCategoriesSectionsCopy = () => {
    let { categoriesColors, treeDataAnnotations, docAnnotations, docAnnotationCategories, treeDataAnnotationsOcr, expandedKeys,
      // checkedKeys
    } = this.state
    const { landscape } = this.props
    for (const categoryColor of Object.keys(categoriesColors)) {
      //markWordsOnDocumentForCategoryCopy(categoryColor, categoriesColors[categoryColor], docAnnotations, landscape, categories)
    }
    markWordsOnDocumentForCategoryCopy(categoriesColors, docAnnotations, landscape, categories)
    timerCheckMarkReady = setInterval(() => {
      if (global.itemsToBeMarked === global.markedTimes) {
        clearInterval(timerCheckMarkReady)
        this.props.hideLoadingBeforeMark()
        for (const categoryColor of Object.keys(categoriesColors)) {
          const { result, expandedSubCategoryParent } = generateHtmlForSecurityScanResult(categoryColor, categoriesColors[categoryColor], docAnnotations, landscape, categories, docAnnotationCategories)
          expandedKeys = [ ...expandedKeys, ...expandedSubCategoryParent ]
          treeDataAnnotations[0].children.push(result)
          treeDataAnnotationsOcr[0].children.push(result)
        }
        this.setState({
          statusSaveQuery: true
        }, () => {
          this.fetchDataNewQueryStatus(this.props.idDocument, docAnnotationCategories)
        })
        if (this.props.isSmartBoxApplication) {
          this.saveKeysForClearAll(this.props.idDocument, treeDataAnnotations)
        }
        const treeData = treeDataAnnotations
        this.props.setTreeDataAnnotations(treeDataAnnotations)
        this.props.allChildrenTree(treeDataAnnotations)
        this.props.treeDataFilter(treeData)
        // if (treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
        //   checkedKeys = this.props.alreadyRedactedKeys.standard
        // }
        this.setState({
          treeDataAnnotations,
          docAnnotationCategories,
          treeDataAnnotationsOcr,
          expandedKeys: [ ...expandedKeys, ...[ 'names', 'locations', 'numbers', 'time', 'addresses', 'identifiers', 'organisations' ] ],
          autoExpandParent: false
        }, () => {
          if (this.props.isOcr) {
            this.checkDocAnnotationCategoryOcr()
          }
        })
        global.itemsToBeMarked = 0
        global.markedTimes = 0
      }
    }, 100)
  }
  renderCategoriesSections = () => {
    let { categoriesColors, treeDataAnnotations, docAnnotations, docAnnotationCategories, treeDataAnnotationsOcr, expandedKeys,
      // checkedKeys
    } = this.state
    const { landscape } = this.props
    for (const categoryColor of Object.keys(categoriesColors)) {
      markWordsOnDocumentForCategory(categoryColor, categoriesColors[categoryColor], docAnnotations, landscape, categories)
    }
    timerCheckMarkReady = setInterval(() => {
      if (global.itemsToBeMarked === global.markedTimes) {
        clearInterval(timerCheckMarkReady)
        this.props.hideLoadingBeforeMark()
        for (const categoryColor of Object.keys(categoriesColors)) {
          const { result, expandedSubCategoryParent } = generateHtmlForSecurityScanResult(categoryColor, categoriesColors[categoryColor], docAnnotations, landscape, categories, docAnnotationCategories)
          expandedKeys = [ ...expandedKeys, ...expandedSubCategoryParent ]
          treeDataAnnotations[0].children.push(result)
          treeDataAnnotationsOcr[0].children.push(result)
        }
      this.setState({
        statusSaveQuery: true
      }, () => {
        this.fetchDataNewQueryStatus(this.props.idDocument, docAnnotationCategories)
      })
        if (this.props.isSmartBoxApplication) {
          this.saveKeysForClearAll(this.props.idDocument, treeDataAnnotations)
        }
        const treeData = treeDataAnnotations
        this.props.setTreeDataAnnotations(treeDataAnnotations)
        this.props.allChildrenTree(treeDataAnnotations)
        this.props.treeDataFilter(treeData)
        // if (treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
        //   checkedKeys = this.props.alreadyRedactedKeys.standard
        // }
        this.setState({
          treeDataAnnotations,
          docAnnotationCategories,
          treeDataAnnotationsOcr,
          expandedKeys: [ ...expandedKeys, ...[ 'names', 'locations', 'numbers', 'time', 'addresses', 'identifiers', 'organisations' ] ],
          autoExpandParent: false
        }, () => {
          if (this.props.isOcr) {
            this.checkDocAnnotationCategoryOcr()
          }
        })
        global.itemsToBeMarked = 0
        global.markedTimes = 0
      }
    }, 100)
  }
  showHideCategory = (e, category, state, item) => {
      e.stopPropagation()
    const { treeDataAnnotations } = this.state
    const showHideCategoryItemsUpdate = []
    if (this.props.isOcr) {
      this.props.showHideCategoryOcr(e, category, state, item)
    }
    const aiAnnotations = document.querySelectorAll(`.${ category }`)
    this.setState({
      categoriesVisible: {
        ...this.state.categoriesVisible, [category]: state
      }
    }, () => {
      treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((item2) => {
        if (!this.state.categoriesVisible[item2.title]) {
          item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
            item3 && item3.children && item3.children.length > 0 && item3.children.forEach((item4) => {
              item4 && item4.children && item4.children.length > 0 && item4.children.forEach((item5) => {
                showHideCategoryItemsUpdate.push(item5.key)
              })
            })
          })
        }
      })
      this.setState({
        showHideCategoryItems: showHideCategoryItemsUpdate
      })
      if (!this.state.categoriesVisible[category]) {
        for (const aiAnnotate of aiAnnotations) {
          aiAnnotate.style.background = 'transparent'
        }
      } else {
        this.props.onChangeStatusIconHideAllHighlighting()
        for (const aiAnnotate of aiAnnotations) {
          aiAnnotate.style.background = this.state.categoriesColors[category]
        }
      }
    })
  }

  onCategoryRenderVisible = () => {
    const { categoriesVisible } = this.state
    Object.keys(categoriesVisible) && Object.keys(categoriesVisible).length > 1 && Object.keys(categoriesVisible).forEach((category) => {
      const aiAnnotations = document.querySelectorAll(`.${ category }`)
      if (categoriesVisible[category] === false) {
        for (const aiAnnotate of aiAnnotations) {
          aiAnnotate.style.background = 'transparent'
        }
      } else {
        for (const aiAnnotate of aiAnnotations) {
          aiAnnotate.style.background = this.state.categoriesColors[category]
        }
      }
    })
  }

  expandHideCategory = (e, key, action) => {
    e.stopPropagation()
    let { expandedKeys } = { ...this.state }
    const expandKeysStandard = []
    expandKeysStandard.push(key)
    if (action === 'add') {
      expandedKeys.push(key)
      this.setState({
        expandedKeys: this.state.expandedKeys.concat(expandKeysStandard)
      })
    } else {
      //expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
      this.setState({
        expandedKeys: expandedKeys.filter((eKey) => eKey !== key)
      })
    }
    this.setState({
      //expandedKeys,
      autoExpandParent: false,
    })
  }
  
  toggleColorPicker = (key, option, event) => {
    if (option) {
      const newVal = this.state.displayColorPicker
      newVal[key] = !Boolean(this.state.displayColorPicker[key])
      this.setState({
        displayColorPicker: newVal,
        rowSelectedCategory: key
      })
    } else {
      const newVal = this.state.displayColorPicker
      newVal[key] = false
      this.setState({
        displayColorPicker: newVal,
        lastSelectedColor: ''
      })
    }
    if (event) event.stopPropagation()
  }
  
  changeColorPicker = (color, e) => {
    e.stopPropagation()
    this.setState({ lastSelectedColor: color.rgb })
  };
  
  closeAndSaveColorPicker = (record, key, e) => {
    e.stopPropagation()
    const { rowSelectedCategory, docAnnotationsColors, lastSelectedColor } = this.state
    const newVal = docAnnotationsColors
    for (let i = 0; i < newVal.length; i++) {
      if (newVal[i].id === record.id) {
        newVal[i].color = lastSelectedColor
      }
    }
    const newValue = this.state.displayColorPicker
    newValue[rowSelectedCategory] = false
    record.color.a = 0.3
    record.color = this.convertRgbaToString(record.color)
    this.saveColor(record, newVal, key, newValue)
  }
  
  convertRgbaToString = ({ r, g, b, a }) => {
    return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
  }

  onChangeTreeDataColour = (newVal) => {
    const { treeDataAnnotations } = this.state
    newVal && newVal.map((newValue) => {
      treeDataAnnotations && treeDataAnnotations[0].children.map((treeData) => {
        if (newValue.category === treeData.key) {
          treeData.backgroundColor = newValue.color
        }
        return true
      })
      return true
    })
    this.setState({
      treeDataAnnotations
    })
  }

  saveColor(record, newVal, key, newValue) {
    record.clientId = this.props.clientId
    record.defaultColor = 0
    record.userId = this.props.userId
    if (newVal) {
      this.onChangeTreeDataColour(newVal)
    }

    updateAnnotationColor(record).then((response) => {
      if (response.status === 200) {
        CustomNotificationManager.success(`You successfully changed the color for the ${ record.category } category`, 'Color changed')
        this.setState(
          {
            data: newVal,
            lastSelectedColor: '',
            displayColorPicker: newValue,
            categoriesColors: {
              ...this.state.categoriesColors, [key]: record.color
            }
          }, () => {
            const aiAnnotations = document.querySelectorAll(`.${ key }`)
            for (const aiAnnotate of aiAnnotations) {
              aiAnnotate.style.background = this.state.categoriesColors[key]
            }
          }
        )
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }

  extractLeafKeys = (node) => {
    const { unCheckedKeys } = this.state
    if (node.children && node.children.length) {
      node.children.forEach((item) => {
        this.extractLeafKeys(item.props)
      })
    } else {
      if (!unCheckedKeys.includes(node['data-key'])) {
        unCheckedKeys.push(node['data-key'])
      }
      this.setState({ unCheckedKeys })
    }
  }

  onCheck = (checkedKeys, { checkedNodes, checked, node }) => {
    const { docAnnotationsChildrenKeys, unCheckedKeys, sendStatusOnClearAll, docAnnotationsOCR } = this.state
    if (this.props.isSmartBoxApplication) {
      this.props.checkOnClearAll(sendStatusOnClearAll)
    }
    if (!checked) {
      this.extractLeafKeys(node.props)
    } else if (unCheckedKeys.length) {
      unCheckedKeys.forEach((item, index) => {
        if (checkedKeys.includes(item)) {
          unCheckedKeys.splice(index, 1)
        }
      })
    }
    //this.extractLeafKeys(node.props)
    let newChildren = []
    const newFinalChildren = []

    newChildren = docAnnotationsChildrenKeys.filter((redChild) => checkedKeys.map((red) => red).includes(redChild.key))
    newChildren && newChildren.map((newChildren2) => {
      newFinalChildren.push(`${ newChildren2.key } => ${ newChildren2.name } => ${ newChildren2.pageNumber }`)
      return true
    })

    if (checkedKeys && checkedKeys.length) {
      this.props.setNumberOfAnnotateSelected(checkedKeys)
    }
    const listSavedName = []
    if (docAnnotationsOCR && Object.keys(docAnnotationsOCR).length) {
      Object.keys(docAnnotationsOCR).forEach((category) => {
        docAnnotationsOCR[category] && docAnnotationsOCR[category].length > 0 && docAnnotationsOCR[category].forEach((nameParent, index) => {
          checkedKeys && checkedKeys.length > 0 && checkedKeys.forEach((keyName) => {
            if (keyName === nameParent) {
              listSavedName.push(keyName)
            }
          })
        })
      })
    }
    const checkNodes = checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord)
    this.props.onCheckedKeysObjectRefs(checkNodes)
    this.setState({
      checkedKeys,
      checkedKeysOcr: listSavedName,
      unCheckedKeys,
      newFinalChildren: newFinalChildren,
      selectClassDisabled: '',
      checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord)
    })
  };
  
  findElementByClassAndAddMarkPulse(selectedKeys, node) {
    let { expandedKeys } = { ...this.state }
    const { categoriesColors, treeDataAnnotations } = this.state
    const checkNodeKeyStandard = []
    const newCategoryColour = []
    const treeChildrenCategory = []
    categoriesColors && Object.keys(categoriesColors).forEach((key) => {
      newCategoryColour.push({
        key: key,
        nameCategory: key,
        colorCategory: categoriesColors[key]
      })
    })
    treeDataAnnotations && treeDataAnnotations[0].children.forEach((child) => {
      child && child.children.forEach((child2) => {
        child2.backgroundColor = child.backgroundColor
        child2 && child2.children.forEach((child3) => {
          child3.backgroundColor = child2.backgroundColor
          treeChildrenCategory.push(child3)
        })
      })
    })
    if (node && node.props.dataRef.key) {
      checkNodeKeyStandard.push(node.props.dataRef.key)
      this.setState({
        selectedChildrenBold: checkNodeKeyStandard,
        selectedPgNrBoldStandard: checkNodeKeyStandard
      })
    }
    if (node.props.dataRef.key.includes('found-parent-all') || node.props.dataRef.identifier === 'category-parent') {
      if (!expandedKeys.includes(node.props.dataRef.key)) {
        expandedKeys.push(node.props['data-key'])
        this.setState({
          expandedKeys,
          autoExpandParent: false
        })
      } else {
        expandedKeys = expandedKeys.filter((eKey) => eKey !== node.props['data-key'])
        this.setState({
          expandedKeys,
          autoExpandParent: false
        })
      }
    } else {
      this.setState({
        selectedWord: selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : ''
      }, () => {
        if (this.state.selectedWord) {
          const { pageNumber, name } = node.props.dataRef
          let { backgroundColor } = node.props.dataRef
          treeChildrenCategory && treeChildrenCategory.length > 0 && treeChildrenCategory.forEach((children) => {
            if (children.name === name) {
              backgroundColor = children.backgroundColor
            }
          })
          const oldElement = document.querySelector('#matched-word--current')
          if (oldElement) {
            oldElement.removeAttribute('id')
          }
          if (+pageNumber !== +this.props.pageNumber) {
            this.props.changePage(+pageNumber)
          }
          const element = document.getElementsByClassName(this.state.selectedWord)
          if (element && element.length > 0) {
            element[0].id = 'matched-word--current'
            element[0].style.borderColor = backgroundColor.replace('0.3', '1')
            element[0].scrollIntoView(false)
          }
        } else {
          //const element = document.querySelector('#matched-word--current')
          //element.removeAttribute('id')
        }
      })
    }
  }

  onSaveNewQueryStatus = () => {
    const { newFinalChildren, checkStatusChildren } = this.state
    let updateStatus = false
    if (checkStatusChildren && checkStatusChildren.length > 0) {
      updateStatus = true
    } else {
      updateStatus = false
    }
    const data = {
      idDocument: this.props.idDocument,
      keyWordNonOcr: newFinalChildren,
      keyWordOcr: [],
      updateRedactions: updateStatus
    }
    if (this.props.isSmartBoxApplication) {
      apiSmartBoxSaveNewQueryStatus(data).then((response) => {
        this.setState({
          statusSaveQuery: false
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  onUpdateRedactionNumber = (classList) => {
    const data = {
      doc_id: this.props.idDocument,
      box_number_words_redacted: classList.length
    }
    apiSmartBoxUpdateRedactionNumber(data).then((response) => {

    })
  }
  drawMatchedWordsOcr = (classList) => {
    const { checkedKeys, checkedKeysNew, treeDataAnnotations } = this.state
    const checkedKeysUpdate = []
    const checkedKeysUpdateTwo = []
    if (treeDataAnnotations && treeDataAnnotations.length > 0 ) {
      treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((child) => {
        child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
          child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
            if (checkedKeys.includes(child3.key)) {
              checkedKeysUpdate.push(child3.key)
            }
            checkedKeys && checkedKeys.length > 0 && checkedKeys.forEach((check) => {
              if (child3.key === check) {
                checkedKeysUpdateTwo.push(child3.key)
              }
            })
          })
        })
      })
    }
    const checkedKeysOcr = []
    if (classList && classList.children && classList.children.length > 0) {
      classList.children.forEach((child) => {
        child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
          checkedKeysOcr.push(child2.key)
        })
      })

    } else if (classList && classList.children && classList.children.length === 0) {
      checkedKeysOcr.push(classList.key)
    }
    //const uniqueCheckedKeysUpdate = [...new Set(checkedKeysUpdate)]
    const textMessageRedact = checkedKeysOcr && checkedKeysOcr.length === 1 ? `Redact ${ checkedKeysOcr.length } selected?` : `Redact all ${ checkedKeysOcr.length } selected?`
    swal.fire({
      text: 'Redact all selected?',
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#3DD8DB',
      confirmButtonText: 'REDACT',
      customClass: {
        container: 'redact-swall-wr'
      }
    }).then((result) => {
      if (result.value) {
        this.setState({
          checkedKeys,
          checkedKeysNew: checkedKeys
        })
      } else {
        this.setState({
          checkedKeys: checkedKeysNew
        })
      }
      if (result.value) {
        // this.props.toggleLoadingProcess(true, 'redact')
        this.onSaveNewQueryStatusOCR(checkedKeysOcr)
        this.setState({
          disabledCheckBoxTree: false
        })
      }
    })
  }
  onSaveNewQueryStatusOCR = (checkedKeysOcr) => {
    const { checkedKeys, newFinalChildren, treeDataAnnotations } = this.state
    const { docAnnotationsOCR } = this.state
    const listSavedName = []
    const newAllStandardChildrenOcr = []
    if (docAnnotationsOCR && Object.keys(docAnnotationsOCR).length) {
      Object.keys(docAnnotationsOCR).forEach((category) => {
        // let title = category && category.replace('doc_annotate_', '')
        // title = title && title.replace('_', ' ')
        docAnnotationsOCR[category] && docAnnotationsOCR[category].length > 0 && docAnnotationsOCR[category].forEach((nameParent, index) => {
          checkedKeysOcr && checkedKeysOcr.length > 0 && checkedKeysOcr.forEach((keyName) => {
            if (keyName === nameParent) {
              listSavedName.push(keyName)
            }
          })
        })
      })
    }

    treeDataAnnotations && treeDataAnnotations.length > 0 && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((child) => {
      child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
        child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
          if (checkedKeysOcr.includes(child3.key)) {
            newAllStandardChildrenOcr.push({
              category: child.key,
              subcategory: child2.key,
              word: child3.key,
            })
          }
        })
      })
    })

    const data = {
      idDocument: this.props.idDocument,
      //keyWordOcr: listSavedName,
      keyWordOcr: newAllStandardChildrenOcr,
      keyWordNonOcr: newFinalChildren
    }
    apiSmartBoxSaveNewQueryStatusOcr(data).then((response) => {
      if (response.status === 200) {
        //this.props.fetchSmartBoxDataCanvasOcr()
        this.onCheckAllCanvasPoints()
      }

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }

  drawMatchedWordsXerox = (classList) => {
    const { checkedKeys, checkedKeysNew, treeDataAnnotations } = this.state
    const checkedKeysUpdate = []
    const checkedKeysUpdateTwo = []
    if (treeDataAnnotations && treeDataAnnotations.length > 0 ) {
      treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((child) => {
        child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
          child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
            if (checkedKeys.includes(child3.key)) {
              checkedKeysUpdate.push(child3.key)
            }
            checkedKeys && checkedKeys.length > 0 && checkedKeys.forEach((check) => {
              if (child3.key === check) {
                checkedKeysUpdateTwo.push(child3.key)
              }
            })
          })
        })
      })
    }
    const checkedKeysOcr = []
    if (classList && classList.children && classList.children.length > 0) {
      classList.children.forEach((child) => {
        child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
          checkedKeysOcr.push(child2.key)
        })
      })

    } else if (classList && classList.children && classList.children.length === 0) {
      checkedKeysOcr.push(classList.key)
    }
    //const uniqueCheckedKeysUpdate = [...new Set(checkedKeysUpdate)]
    const textMessageRedact = checkedKeysOcr && checkedKeysOcr.length === 1 ? `Redact ${ checkedKeysOcr.length } selected?` : `Redact all ${ checkedKeysOcr.length } selected?`
    swal.fire({
      text: 'Redact all selected?',
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#3DD8DB',
      confirmButtonText: 'REDACT',
      customClass: {
        container: 'redact-swall-wr'
      }
    }).then((result) => {
      if (result.value) {
        this.setState({
          checkedKeys,
          checkedKeysNew: checkedKeys
        })
      } else {
        this.setState({
          checkedKeys: checkedKeysNew
        })
      }
      if (result.value) {
        // this.props.toggleLoadingProcess(true, 'redact')
        this.onSaveNewQueryStatusXerox(checkedKeysOcr)
        this.setState({
          disabledCheckBoxTree: false
        })
      }
    })
  }
  onSaveNewQueryStatusXerox = (checkedKeysOcr) => {
    const { checkedKeys, newFinalChildren } = this.state
    const { docAnnotationsOCR } = this.state
    const listSavedName = []
    if (docAnnotationsOCR && Object.keys(docAnnotationsOCR).length) {
      Object.keys(docAnnotationsOCR).forEach((category) => {
        // let title = category && category.replace('doc_annotate_', '')
        // title = title && title.replace('_', ' ')
        docAnnotationsOCR[category] && docAnnotationsOCR[category].length > 0 && docAnnotationsOCR[category].forEach((nameParent, index) => {
          checkedKeysOcr && checkedKeysOcr.length > 0 && checkedKeysOcr.forEach((keyName) => {
            if (keyName === nameParent) {
              listSavedName.push(keyName)
            }
          })
        })
      })
    }

    const data = {
      docId: this.props.idDocument,
      wordsToRedact: listSavedName,
      clientId: this.props.clientId
    }
    apiSmartBoxSaveXeroxRedactionSmartView(data).then((response) => {
      if (response.status === 200) {
        //this.props.fetchSmartBoxDataCanvasOcr()
        //this.onCheckAllCanvasPoints()
        this.props.onGetCanvasPointsXeroxRedaction(response.data)
      }

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onCheckAllCanvasPoints = () => {
    apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
      this.setState({
        responseCheckPdfCanvas: response.data
      })
      if(response.data === 'Processing') {
        this.setIntervalCheckAllCanvasPoints()
      } else {
        this.props.fetchSmartBoxDataCanvasOcr()
      }
    })
  }
  setIntervalCheckAllCanvasPoints = () => {
    const { responseCheckPdfCanvas} = this.state
    let { setIntervalCheckPdfCanvas} = this.state
    if (setIntervalCheckPdfCanvas === 0) {
      if (responseCheckPdfCanvas === 'Processing') {
        setIntervalCheckPdfCanvas = setInterval(this.callBackCheckAllCanvasPoints, 5000)
        this.setState({
          setIntervalCheckPdfCanvas
        })
      } else {
        clearInterval(setIntervalCheckPdfCanvas)
      }
    }
  }

  callBackCheckAllCanvasPoints = () => {
    const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
    if(responseCheckPdfCanvas === 'Processing') {
      apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
        this.setState({
          responseCheckPdfCanvas: response.data
        })
        if (response.data === 'Finished') {
          this.props.fetchSmartBoxDataCanvasOcr()
        }
      })
    }
  }
  drawMatchedWords = (classList) => {
    const { unCheckedKeys, checkedKeys, checkedKeysNew, itemSelectForRedact } = this.state
    const { treeDataAnnotations } = this.state
    const { activeKey, listCategories } = this.props
    const allWordsRedacted = []
    treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((item2) => {
      item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
        item3 && item3.children && item3.children.length > 0 && item3.children.forEach((item4) => {
          item4 && item4.children && item4.children.length > 0 && item4.children.forEach((item5) => {
            if (classList.includes(item5.key)) {
              item5.categoryName = item2.key
              item5.subcategoryName = item3.key
              allWordsRedacted.push(item5)
            }
          })
        })
      })
    })
    listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
      elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
        allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
          if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
            item.categoryColor = elem2.subcategoryColorWithAlpha
          }
        })
      })
    })
    let numberRedact = []
    if (itemSelectForRedact && itemSelectForRedact.key.includes('found-parent-all')) {
      numberRedact = itemSelectForRedact.children
    } else if (itemSelectForRedact.identifier !== 'category-parent' && !itemSelectForRedact.key.includes('found-parent-all') && itemSelectForRedact.children.length === 0) {
      numberRedact.push(itemSelectForRedact)
    } else {
      numberRedact = classList
    }
    const textMessageRedact = unCheckedKeys && unCheckedKeys.length === 1 ? `Remove ${ unCheckedKeys.length } selected redaction?` :
        unCheckedKeys && unCheckedKeys.length > 0 ? `Remove ${ unCheckedKeys.length } selected redactions?` : numberRedact && numberRedact.length === 1 ? `Redact ${ numberRedact.length } selected?` : `Redact all ${ numberRedact.length } selected?`
    swal.fire({
      text: 'Redact all selected?',
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#3DD8DB',
      confirmButtonText: unCheckedKeys && unCheckedKeys.length > 0 ? 'Unredact' : 'REDACT',
      customClass: {
        container: 'redact-swall-wr'
      }
    }).then((result) => {
      if (result.value) {
        this.props.getStatusElementRedaction('elementStandard')
        this.setState({
          checkedKeys,
          checkedKeysNew: checkedKeys
        })
      } else {
        this.setState({
          checkedKeys: checkedKeysNew
        })
      }
      if (result.value) {
        // this.props.toggleLoadingProcess(true, 'redact')
        if (unCheckedKeys.length === 0) {
          this.onSaveNewQueryStatus()
        }
        if (unCheckedKeys.length === 0) {
          this.onUpdateRedactionNumber(classList)
        }
        this.setState({
          disabledCheckBoxTree: false
        })
        const existingRedactedKeys = new Set([ ...this.state.redactedKeys, ...classList ])
        const newUnCheckedKeys = []
        if (unCheckedKeys && unCheckedKeys.length) {
          unCheckedKeys.forEach((item, index) => {
            if (existingRedactedKeys.has(item)) {
              existingRedactedKeys.delete(item)
            }
            if (!classList.includes(item)) {
              newUnCheckedKeys.push(item)
            }
          })
        }
        this.setState({
          redactedKeys: existingRedactedKeys
        }, () => {
          this.props.drawAllMatchedWordsByClassList(classList, newUnCheckedKeys, 'standard', allWordsRedacted)
          //this.props.drawAllMatchedWordsByClassList(classListBusiness, [], 'dictionaries')
          // if (newUnCheckedKeys && newUnCheckedKeys.length > 0) {
          //   this.onSaveKeysBusiness()
          //   this.onDrawRegExAndDict()
          // }
          this.setState({
            unCheckedKeys: []
          })
          if (activeKey === 'standardTab') {
            this.setState({
              disableCheckedKeys: false
            })
            this.props.onCheckStatusSelectAgainRedacted(false)
          }
        })
      } else if (activeKey === 'standardTab') {
        this.setState({
          disableCheckedKeys: true
        })
        this.setState({
          disabledCheckBoxTree: false
        })
        this.props.onCheckStatusSelectAgainRedacted(true)
      } else {
        this.setState({
          disabledCheckBoxTree: false
        })
      }
    })
  }
  onDrawRegExAndDict = () => {
    const { treeBusiness } = this.props
    const selectAllItemsBusiness = []
    treeBusiness && treeBusiness.length > 0 && treeBusiness[0].children && treeBusiness[0].children.length > 0 && treeBusiness[0].children.forEach((child) => {
      child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
        child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
          selectAllItemsBusiness.push(child3)
        })
      })
    })
    const sortedByPageItemsBusiness = selectAllItemsBusiness.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
    this.onRedactDict(sortedByPageItemsBusiness.map((item) => item.key))
  }
  onRedactDict = (classListBusiness) => {
    const { treeBusiness } = this.props
    if (treeBusiness && treeBusiness.length > 0) {
      this.setState({
        classListBusiness: classListBusiness
      })
      this.props.drawAllMatchedWordsByClassList(classListBusiness, [], 'dictionaries')
    }
  }
  onSaveKeysBusiness = () => {
    const { treeBusiness } = this.props
    const selectedKeys = []
    treeBusiness && treeBusiness[0].children.forEach((item) => {
      item && item.children.forEach((item2) => {
        item2 && item2.children.forEach((item3) => {
          selectedKeys.push(`${ item3.key } => ${ item3.name } => ${ item3.pageNumber }`)
        })
      })
    })
    const data = {
      id: this.props.idDocument,
      listKeys: selectedKeys
    }
    saveKeysRedactedBusiness(data).then(() => {

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }
  componentWillUnmount() {
    global.finalItems = {}
    global.items = {}
    global.markedTimes = 0
    global.itemsToBeMarked = 0
    global.arEl = []
    global.arOptions = []

    clearInterval(timerCheckMarkReady)
    clearInterval(timerCheckCanvas)
  }

  checkNode = () => {
    const { docAnnotationsNewQuery, treeDataAnnotations } = this.state
    const { treeDataAnnotationsStandard } = this.props
    let { checkedKeys } = this.state
    const checkedChildren = []
    const docAnnotationsChildren = []
     treeDataAnnotationsStandard[0].children.length > 0 && treeDataAnnotationsStandard[0].children.forEach((item) => {
      item && item.children.length > 0 && item.children.forEach((item2) => {
        item2 && item2.children.forEach((item3) => {
          item3 && item3.children.forEach((item4) => {
            docAnnotationsChildren.push(item4)
          })
        })
      })
    })
    docAnnotationsChildren && docAnnotationsChildren.map((items) => {
      docAnnotationsNewQuery && docAnnotationsNewQuery.map((chil) => {
        chil && chil.children.map((item) => {
          if (item.status === 1 && items.key === item.key) {
            checkedChildren.push(items.key)
          }
          return true
        })
        return true
      })
      return true
    })

    treeDataAnnotations && treeDataAnnotations[0].children.map((item) => {
      item && item.children.map((item2) => {
        item2 && item2.children.map((item3) => {
          item3 && item3.children.map((item4) => {
            docAnnotationsNewQuery && docAnnotationsNewQuery.map((chil) => {
              chil && chil.children.map((item5) => {
                if (item4.key === item5.key) {
                    item4.status = item5.status
                    item3.parent = item.title
                    item4.parent = item.title
                }
                return true
              })
              return true
            })
            return true
          })
          return true
        })
        return true
      })
      return true
    })
    checkedKeys = this.updateCheckedList(checkedKeys.concat(checkedChildren))
    this.props.statusCheckedChildrenRedact(checkedKeys)
    this.setState({
      checkedKeys,
      checkedKeysNew: checkedKeys,
      docAnnotationsChildrenKeys: docAnnotationsChildren,
      checkStatusChildren: checkedChildren,
      treeDataAnnotations
    }, () => this.checkStatusParent())
  }
  checkStatusParent = () => {
    const { treeDataAnnotations } = this.state
    treeDataAnnotations && treeDataAnnotations[0].children.forEach((item) => {
      item && item.children.forEach((item2) => {
        item2 && item2.children.forEach((item3) => {
          item3 && item3.children.forEach((item4) => {
            // if (item.title === item4.parent) {
              if (item4.status === 1) {
                item.redactAllChild = true
              }
              else {
                item.redactAllChild = false
              }
            // }
          })
        })
      })
    })
    this.setState({
      treeDataAnnotations
    })
  }
  updateCheckedList = (checkedList) => {
    const { extraCheckedKeys } = this.state
    if (extraCheckedKeys && extraCheckedKeys.length && checkedList.length) {
      extraCheckedKeys.forEach((item) => {
        if (!checkedList.includes(item)) {
          checkedList.push(item)
        }
      })
    }
    return checkedList
  }

  onClickDisableCheckedKeys = (status) => {
    this.setState({
      disableCheckedKeys: status
    })
  }

  onCheckDisabled = () => {
    CustomNotificationManager.info('Select update redactions first', 'Aborted')
    this.setState({
      selectClassDisabled: 'disabled-standard-tree'
    })
    return null
  }
  onDisplayDifferenceColourOnActiveTab = () => {
    const { treeDataAnnotations, categoriesVisible } = this.state
    const { activeKey } = this.props

    treeDataAnnotations && treeDataAnnotations[0].children.map((keyColour) => {
      const aiAnnotations = document.querySelectorAll(`.${ keyColour.key }`)
      for (const aiAnnotate of aiAnnotations) {
        if (activeKey === 'standardTab' && categoriesVisible[keyColour.key]) {
          aiAnnotate.style.background = keyColour.backgroundColor
          aiAnnotate.style.borderColor = keyColour && keyColour.backgroundColor ? keyColour.backgroundColor.replace('0.3', '1') : ''
        } else {
          aiAnnotate.style.background = 'transparent'
          aiAnnotate.style.borderColor = 'initial'
        }
      }
      return true
    })
  }

  displayCheckKeysNonDelete() {
    const { newListKeysStandard } = this.props
    const { treeDataAnnotations } = this.state
    const newkeys = []
    if (newListKeysStandard) {
      treeDataAnnotations && treeDataAnnotations[0].children.map((item) => {
        item && item.children.map((item2) => {
          item2 && item2.children.map((item3) => {
            item3 && item3.children.map((item4) => {
              newListKeysStandard && newListKeysStandard.map((newKey) => {
                if (item4.key.toLowerCase() === newKey) {
                  newkeys.push(item4.key)
                }
                return true
              })
              return true
            })
            return true
          })
          return true
        })
        return true
      })
      return newkeys
    } 
    return []
    
  }
   onSelectOcr = () => {
     return true
   }
  onMouseEnter = (e) => {
  }
  onMouseEnterItemsTree = (e, item) => {
    this.setState({
      redactedDisplayHover: item,
      showVisibleCategory: item
    })
  }
  findElementByClassChildren(className) {
    if (!this.props.isOcr || !this.props.xeroxUpload) {
      const element = document.getElementsByClassName(className.key)
      const oldElement = document.querySelector('#matched-word--current')
      if (className) {
        if (oldElement) {
          oldElement.removeAttribute('id')
        }
        if (+className.pageNumber !== +this.props.pageNumber) {
          this.props.changePage(+className.pageNumber)
        }
        if (element && element.length > 0) {
          element[0].id = 'matched-word--current'
          element[0].style.borderColor = className.backgroundColor.replace('0.3', '1')
          element[0].scrollIntoView(false)
        }
      } else {
        const element = document.querySelector('#matched-word--current')
        element.removeAttribute('id')
      }
    }
  }
  onSelectFindElementTree = (e, item) => {
    e.stopPropagation()
    const { treeDataAnnotations, showHideCategoryItems } = this.state
    if (!showHideCategoryItems.includes(item.key) && !this.props.isOcr && !this.props.xeroxUpload) {
      let checkedKeysRef = []
      if (item && item.children.length > 0) {
        item.children.forEach((item) => {
          checkedKeysRef.push(item)
        })
      } else {
        checkedKeysRef.push(item)
      }
      if (item && item.children && item.children.length > 0) {
        this.props.onCheckedKeysObjectRefs(checkedKeysRef)
      } else {
        this.findElementByClassChildren(item)
      }
      this.setState({
        selectedChildrenBold: item.key
      })
    }
  }
  findElementByClass(className) {
    const element = document.getElementsByClassName(className)
    if (element && element.length > 0) {
      element[0].removeAttribute('id')
    }
  }
  onRedactAiTag = () => {
    const { checkedKeysObjectRefs, treeDataAnnotations } = this.state
     //if (checkedKeysObjectRefs.length > 0) {

      const sortedByPageItems = checkedKeysObjectRefs.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
      this.drawMatchedWords(sortedByPageItems.map((item) => item.key))
    //}
  }
  onCheckCategoryDisabled = (e) => {
    e.stopPropagation()
    return "true"
  }
  onCheckSelectedTree = (e, item) => {
    const { lockSmartViewChanges } = this.props
    //e.stopPropagation()
    if (!lockSmartViewChanges) {
      const { isOcr } = this.state
      this.setState({
        itemSelectForRedact: item
      })
      if (isOcr) {
        this.setState({
          disabledCheckBoxTree: true
        }, () => {
          this.drawMatchedWordsOcr(item)
        })
      } if (this.props.xeroxUpload) {
        this.drawMatchedWordsXerox(item)
      } if (!isOcr && !this.props.xeroxUpload) {
        //this.onRedactAiTag()
        this.setState({
          disabledCheckBoxTree: true
        }, () => {
          this.onRedactAiTag()
        })
      }
    }
  }
  onCheckSelectedTreeNon = () => {
    return false
  }
   render() {
     const { 
       docAnnotations, 
       treeDataAnnotations, 
       expandedKeys, 
       autoExpandParent, 
       categoriesColors, 
       docAnnotationsColors, 
       categoriesVisible, 
       displayColorPicker,
       redactedKeys,
       selectedChildrenBold,
       selectClassDisabled,
       isOcr, redactedDisplayHover, showVisibleCategory, keysSavedOcr, xeroxUpload, keysSavedXerox } = this.state
     let { checkedKeys } = this.state
     const { verificationOnClickClearAll, statusFilterReview, alreadyRedactedKeys, fetchResponseLoadingStandard, docAnnotationsXerox, docAnnotationsStandardOcr,
       lockSmartViewChanges } = this.props
     this.onDisplayDifferenceColourOnActiveTab()
     if (verificationOnClickClearAll && this.props.isSmartBoxApplication) {
       checkedKeys = this.displayCheckKeysNonDelete()
     }
     return (
         <React.Fragment>
           {treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 &&
               <div>
                 <div className="find-asset-container ai-tags-scroll update-find-asset-container">
                   <Tree
                       checkable
                       expandedKeys={expandedKeys}
                       autoExpandParent={this.state.autoExpandParent}
                       defaultExpandAll={true}
                       //onCheck={ disableCheckedKeys === '' && checkStatusChildren && checkStatusChildren.length === 0 ? this.onCheck : disableCheckedKeys ? this.onCheck : disableCheckedKeys === false ? this.onCheckDisabled : this.onCheckDisabled }
                       //onCheck={ disabledCheckBoxTree ? this.onCheck : this.onCheckSelectedTreeNon }
                       //onCheck={ disabledCheckBoxTree ? this.onCheck : this.onCheckSelectedTreeNon }
                       onCheck={this.onCheck}
                       checkedKeys={checkedKeys}
                       //defaultExpandedKeys={ expandedKeys }
                       //selectable={ false }
                       className={selectClassDisabled}
                       onSelect={this.props.isOcr ? this.onSelectOcr : this.props.xeroxUpload ? this.onSelectOcr : (selectedKeys, {node}) => this.findElementByClassAndAddMarkPulse(selectedKeys, node)}
                       switcherIcon={<Icon type="down"/>}
                       //icon={<span className="icon-v17-redaction"/>}
                       //onMouseEnter={(e) => this.onMouseEnter(e)}
                       //showIcon={<span className="icon-v17-redaction"/>}
                   >
                     {renderTreeNodes(treeDataAnnotations, categoriesColors, expandedKeys,
                         this.expandHideCategory, docAnnotationsColors,
                         categoriesVisible, this.toggleColorPicker, this.showHideCategory, this.changeColorPicker,
                         this.closeAndSaveColorPicker, displayColorPicker, redactedKeys,
                         this.props.collapseAnnotateSelectModal, checkedKeys, selectedChildrenBold, isOcr, this.onMouseEnterItemsTree, redactedDisplayHover,
                         this.onSelectFindElementTree, this.onCheckCategoryDisabled, this.onCheckSelectedTree, showVisibleCategory, statusFilterReview,
                         keysSavedOcr, alreadyRedactedKeys, xeroxUpload, keysSavedXerox, lockSmartViewChanges)}
                   </Tree>
                 </div>
                 {/*<ReviewRedact isDocumentReady={ isDocumentReady }*/}
                 {/*  checkedKeysObjectRefs={ checkedKeysObjectRefs }*/}
                 {/*  changePage={ changePage }*/}
                 {/*  pageNumber={ pageNumber }*/}
                 {/*  drawAllMatchedWordsByClassList={ this.drawMatchedWords }*/}
                 {/*  isSmartBoxApplication={ this.props.isSmartBoxApplication }*/}
                 {/*  toggleLoadingProcess={ this.props.toggleLoadingProcess }*/}
                 {/*  activeKey={ activeKey }*/}
                 {/*  checkedKeys={ checkedKeys }*/}
                 {/*  onClickDisableCheckedKeys={ this.onClickDisableCheckedKeys }*/}
                 {/*  disableCheckedKeys={ disableCheckedKeys }*/}
                 {/*  checkStatusChildren={ checkStatusChildren }*/}
                 {/*  onCheckStatusSelectAgainRedacted={ this.props.onCheckStatusSelectAgainRedacted }*/}
                 {/*  onSendStatusOnClearAll = { this.props.onSendStatusOnClearAll }*/}
                 {/*  statusKeysEmptyOnClearAll = { statusKeysEmptyOnClearAll }*/}
                 {/*  onSaveNewQueryStatusOCR = { this.onSaveNewQueryStatusOCR }*/}
                 {/*  isOcr = { this.props.isOcr }*/}
                 {/*  checkedKeysOcr = { checkedKeysOcr }*/}
               </div>
               // : fetchResponseLoadingStandard === false ?
               //     <div></div> :
               // <div className="content-spinning-standard"> <Spin tip="Loading"></Spin></div>
           }
         </React.Fragment>
     )

   }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  userId: state.userReducer.user.id
})

StandardTab.propTypes = {
  clientId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isDocumentReady: PropTypes.bool.isRequired,
  collapseAnnotateSelectModal: PropTypes.func,
  setNumberOfAnnotateSelected: PropTypes.func,
  hideLoadingBeforeMark: PropTypes.func,
  setQueryStatus: PropTypes.func,
  setTreeDataAnnotations: PropTypes.func,
  toggleLoadingProcess: PropTypes.func
}

export default connect(mapStateToProps)(StandardTab)