import React from "react";
import './FormsWorkflow.scss'
import {connect} from "react-redux";
import {Button, Checkbox, Empty, Input, Modal} from "antd";
import {
    apiCreateDuplicateFormWorkflow,
    apiDeleteFormWorkflow, apiFormBuilderCreateFormWorkflow,
    apiFormBuilderEditFormWorkflow,
    apiFormBuilderEditFormWorkflowSimple,
    apiGetFormBuilderListWorkflow
} from "../../../../api/SmartBox";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import FormBuilderUi from "./FormBuilderUi";

class TemplateWorkflow extends React.Component {
    state = {
        listFormTemplate: [],
        selectedForm: null,
        visibleModal: false,
        formModal: {
            name: '',
        },
        modalAction: 'create',
        hoverEditBtn: false,
        hoverCopyBtn: false,
        hoverDeleteBtn:false,
        hoverAddBtn:false,
        visibleFormBuilderUi: false,
        selectFormTemplate: {},
        hoverOpenFormUi: {},
        disableAddFormTemplate: false,
        selectedFormList: []
    }
    componentDidMount() {
        this.onGetFormTemplate()
    }

    onGetFormTemplate = () => {
        apiGetFormBuilderListWorkflow().then((response) => {
            this.setState({
                listFormTemplate: response.data
            })
        })
    }
    onActionEdit = () => {
        const { selectedForm, listFormTemplate, selectedFormList } = this.state
        if(selectedFormList.length === 0) return
        const activeList = listFormTemplate.find(item => item.formId === selectedFormList[0])
        listFormTemplate && listFormTemplate.length > 0 && listFormTemplate.forEach((item) => {
            if (activeList !== null && activeList.formId === item.formId) {
                this.setState({
                    formModal: {
                        name: item.formName,
                        id: item.formId
                    }
                })
            }
        })
        this.setState({
            modalAction: 'edit',
            visibleModal: true,
        })
    }

    onActionCopy = () => {
        const { selectedForm, listFormTemplate, selectedFormList } = this.state
        let selectFormId = null
        listFormTemplate && listFormTemplate.length > 0 && listFormTemplate.forEach((item) => {
            if (selectedForm !== null  && selectedForm.formId === item.formId) {
                selectFormId = item.formId
            }
        })

        let formIdsSelected = []
        if(selectedForm !== null){
            formIdsSelected.push(selectedForm.formId)
        }
         if (selectedFormList && selectedFormList.length > 0) {
             const createDuplicateFormWorkflowDTO = {
                 formIds: selectedFormList,
                 clientId: this.props.clientId,
             }

             apiCreateDuplicateFormWorkflow(createDuplicateFormWorkflowDTO).then((response) => {
                 if (response.data.messageTextResponseDTO.title && response.data.messageTextResponseDTO.title.toLowerCase() === 'success') {
                     CustomNotificationManager.success(response.data.messageTextResponseDTO.message, 'Success')
                 } else if (response.data.messageTextResponseDTO.title && response.data.messageTextResponseDTO.title.toLowerCase() === 'info') {
                     CustomNotificationManager.info(response.data.messageTextResponseDTO.message, 'Info')
                 }
                 this.onGetFormTemplate()
             })
         }
    }


    showConfirmDeleteForm = (e) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete form?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onDeleteFormTemplate(e)
            }
        })
    }
    onDeleteFormTemplate = () => {
        const { selectedForm, selectedFormList } = this.state
        let formIdsSelected = []
        if (selectedFormList && selectedFormList.length > 0) {
            // if (selectedForm !== null) {
            //     formIdsSelected.push(selectedForm.formId)
            // }
            // we call apiDeleteFormWorkflow for each id in selectedLists
            apiDeleteFormWorkflow(selectedFormList).then((response) => {

                if (response.data === 'Success!') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response.data === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.setState({
                    selectedForm: null,
                    selectedFormList: []
                }, () => {
                    this.onGetFormTemplate()
                })
            })
        }

    }
    onClickVisibleModal = () => {
        this.setState({
            visibleModal: true,
            formModal: {
                name: '',
            },
            modalAction: 'create'
        })
    }
    addOrRemoveSelectedLists = (e,item) => {
        e.stopPropagation()
        let { selectedForm, selectedFormList } = this.state
        if(e.target.checked) {
            selectedForm = item;
            selectedFormList.push(item.formId)
        }else{
            selectedForm = null;
            selectedFormList = selectedFormList.filter(item2 => item2 !== item.formId)
        }
        this.setState({selectedForm, selectedFormList})

    }
    handleCancel = () => {
        this.setState({
            visibleModal: false,
            formModal: {...this.state.formModal, name: '', id: null }
        })
    }
    updateValue = (e, field) => {
        if (field === 'name') {
            this.setState({
                formModal: {...this.state.formModal, name: e.target.value }
            })
        }
    }
    onCreateFormTemplate = () => {
        const { formModal } = this.state
        const createFormWorkflowDTO = {
            clientId: this.props.clientId,
            formName: formModal.name
        }
        this.setState({
            disableAddFormTemplate: true
        }, () => {
            apiFormBuilderCreateFormWorkflow(createFormWorkflowDTO).then((response) => {
                if (response.status === 200) {
                    if (response.data.messageTextResponseDTO.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.messageTextResponseDTO.message, 'Success')
                    } else if (response.data.messageTextResponseDTO.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.messageTextResponseDTO.message, 'Info')
                    }
                    this.setState({
                        visibleModal: false,
                        formModal: {...this.state.formModal, name: '', id: null},
                        disableAddFormTemplate: false
                    })
                    this.onGetFormTemplate()
                }
            })
        })
    }
    onEditFormTemplate = () => {
        const { selectedLists, formModal } = this.state
        const editFormWorkflowDTO  = {
            clientId: this.props.clientId,
            formName: formModal.name ? formModal.name : null,
            formId: formModal.id ? formModal.id : null,
        }
        this.setState({
            disableAddFormTemplate: true
        }, () => {
            apiFormBuilderEditFormWorkflowSimple(editFormWorkflowDTO).then((response) => {
                if (response.status === 200) {
                    if (response.data === 'Form saved successfully!') {
                        CustomNotificationManager.success(response.data, 'Success')
                    }
                    else if (response.data === 'A form with this name already exists!') {
                        CustomNotificationManager.info(response.data, 'Info')
                    }
                    else if (response.data === 'info') {
                        CustomNotificationManager.info(response.data, 'Info')
                    }
                    this.setState({
                        visibleModal: false,
                        disableAddFormTemplate: false,
                        selectedForm: null,
                        formModal: {...this.state.formModal, name: '', id: null},
                        // modalAction: 'create',
                    })
                    this.onGetFormTemplate()
                }
            })
        })
    }
    onHoverAction = (e, status, item) => {        
        switch (status) {
            case 'edit':
                this.setState({
                    hoverEditBtn: true
                })
                break
            case 'delete':
                this.setState({
                    hoverDeleteBtn: true
                })
                break
            case 'add' :
                this.setState({
                    hoverAddBtn: true
                })
                break
            case 'copy':
                this.setState({
                    hoverCopyBtn: true
                })
                break
            case 'open' :
                this.setState({
                    hoverOpenFormUi: item
                })
                break
            default: break
        }
    }
    onLeaveAction = () => {
        this.setState({
            hoverEditBtn:false,
            hoverDeleteBtn:false,
            hoverAddBtn:false,
            hoverCopyBtn:false,
            hoverOpenFormUi: {},
        })
    }
    onOpenWorkflowBuilderUi = (e, template) => {
        this.setState({
            visibleFormBuilderUi: true,
            selectFormTemplate: template
        })
    }
    onBackFormBuilderUi = (e, template) => {
        this.setState({
            visibleFormBuilderUi: false,
            selectFormTemplate: {}
        })
    }
    render() {
        const { listFormTemplate, selectedForm, visibleModal, formModal, modalAction, hoverEditBtn, hoverDeleteBtn, hoverAddBtn,
            visibleFormBuilderUi, selectFormTemplate, hoverOpenFormUi, disableAddFormTemplate, hoverCopyBtn, selectedFormList } = this.state

        return (
            <div className="workflow-template-page workflow-template-page-new2">
                { visibleFormBuilderUi ?

                    <FormBuilderUi onBackFormBuilderUi={ this.onBackFormBuilderUi } selectFormTemplate={ selectFormTemplate }/> :

                    <div className="workflow-template-page__content workflow-template-page-new__content">
                        <div className="workflow-template-header">
                            <span className="title-header">Form templates</span>
                        </div>
                        <div className="content-workflow-template-page">
                            <div className="content-buttons-actions">
                                <button className={ hoverCopyBtn ? "icon-v17-copy-active action-edit margin-twenty-pixels" : "icon-v17-copy action-edit margin-twenty-pixels"}
                                        onClick={(e) => this.onActionCopy(e)}
                                        disabled={ selectedFormList.length === 0 }
                                        onMouseEnter={(e)=> this.onHoverAction(e, 'copy')}
                                        onMouseLeave={() => this.onLeaveAction()}>

                                </button>
                                <button onClick={ (e) => this.onActionEdit(e) }
                                        className={ hoverEditBtn ? "icon-v17-edit-active action-edit margin-twenty-pixels" : "icon-v17-edit action-edit margin-twenty-pixels"}
                                        //disabled={ selectedForm === null   || (selectedForm.defaultTemplate === true && selectedForm.duplicatedTemplate === false)}
                                        disabled={ selectedFormList.length === 0 || selectedFormList.length > 1}
                                        onMouseEnter={(e)=> this.onHoverAction(e, 'edit')}
                                        onMouseLeave={() => this.onLeaveAction()}>
                                </button>
                                <button
                                    //disabled={ selectedForm === null   || (selectedForm.defaultTemplate === true && selectedForm.duplicatedTemplate === false)}
                                       disabled={ selectedFormList.length === 0 }
                                        onClick={(e) => this.showConfirmDeleteForm(e)}
                                        className={ hoverDeleteBtn ? "icon-v17-delete-active action-delete margin-twenty-pixels": "icon-v17-delete action-delete margin-twenty-pixels"}
                                        onMouseEnter={(e) => this.onHoverAction(e,'delete')}
                                        onMouseLeave={() => this.onLeaveAction()}>
                                </button>
                                <button onClick={(e) => this.onClickVisibleModal(e)}
                                        className={ hoverAddBtn ? "icon-v17-add-active" : "icon-v17-add"}
                                        onMouseEnter={(e) => this.onHoverAction(e, 'add')}
                                        onMouseLeave={() => this.onLeaveAction()}>
                                </button>
                            </div>
                            <div className="content-table-workflow-template">
                                { listFormTemplate && listFormTemplate.length > 0 ?
                                    <div className="list-process">
                                        { listFormTemplate.map((item, index) => {
                                            return (
                                                <div key={index} className="row-list-process heightForty">
                                        <span key={index} className="content-name">
                                            <Checkbox
                                                //checked={selectedForm !== null && selectedForm.formId === item.formId ? true : false}
                                                checked={selectedFormList.includes(item.formId) ? true : false}
                                                onChange={(e) => this.addOrRemoveSelectedLists(e, item)}></Checkbox>
                                            <span onClick={(e) => this.onOpenWorkflowBuilderUi(e, item)}
                                                  onMouseEnter={(e) => this.onHoverAction(e,'open', item)}
                                                  onMouseLeave={() => this.onLeaveAction()}
                                                  className={hoverOpenFormUi && hoverOpenFormUi.formName === item.formName ? 'name-first name-first-active' : "name-first" }>{item.formName}</span>
                                            {/*<span className="name-last">{item.workflowName}</span>*/}
                                        </span>
                                                    <i onClick={(e) => this.onOpenWorkflowBuilderUi(e, item)} className="icon-v14-arrow-bold"
                                                       onMouseEnter={(e) => this.onHoverAction(e,'open', item)}
                                                       onMouseLeave={() => this.onLeaveAction()}></i>
                                                </div>
                                            )
                                        })

                                        }
                                    </div>
                                    :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </div>
                        </div>
                    </div>

                }
                <Modal className="new-workflow-modal new-form-modal" title={ <span className="content-header-modal"><span>
                    { modalAction === 'create' ? 'Add form template' : 'Edit form template' }</span></span> }
                       visible={visibleModal}
                       footer={ null } closable={ false }>
                    <div className="new-process-section">
                        <form className="new-process-form">
                            <div className="process-modal-line">
                                <Input value={formModal.name ? formModal.name : ''}
                                       onChange={(e) => this.updateValue(e, 'name')}
                                       className="modalInput"
                                       placeholder={'Name'}
                                />
                            </div>
                            <div className="content-buttons-modal">
                                <Button className="action-cancel" onClick={(e) => this.handleCancel(e)}>Cancel</Button>
                                { modalAction === 'create' ?
                                    <Button type="primary" className="action-save"
                                            onClick={(e) => this.onCreateFormTemplate(e)}
                                            disabled={formModal.name === '' || disableAddFormTemplate === true}>Create</Button> :
                                    <Button type="primary" className="action-save"
                                            onClick={(e) => this.onEditFormTemplate(e)}
                                            disabled={formModal.name === '' || disableAddFormTemplate === true}>Edit</Button>
                                }
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default connect(mapStateToProps, {
})(TemplateWorkflow)