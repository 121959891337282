import React from 'react'
import PropTypes from "prop-types";
import './RedactionColour.scss'
import {Button, Checkbox, Empty, Input, Modal} from "antd";
import {
    apiRedactionColourProfileCreate,
    apiRedactionColourProfileDelete,
    apiRedactionColourProfileEdit,
    apiRedactionColourProfileGet,
    apiWorkflowTemplateSimpleCreate,
    apiWorkflowTemplateSimpleEdit
} from "../../../../../api/SmartBox";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import {customConfirm} from "../../../../../utils/AssetRegister/CustomConfirm";
import RedactionColourProfiles from "./RedactionColourProfiles"

class RedactionColour extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hoverEditBtn: false,
            hoverDeleteBtn: false,
            hoverAddBtn: false,
            listRedactionColour: [],
            selectedRedaction: null,
            selectedRedactionList: [],
            selectedRedactionColour: {},
            hoverOpenWorkflowUi: {},
            modalAction: 'create',
            formModal: {
                name: '',
            },
            disableAddWorkflowTemplate: false,
            visibleRedactionColourSettings: false
        }
    }

    componentDidMount() {
      this.onGetRedactionColour()
    }
    onGetRedactionColour = () => {
        apiRedactionColourProfileGet().then((response) => {
            this.setState({
                listRedactionColour: response.data.redactionColorProfileItemDTOS
            })
        })
    }
    onHoverAction = (e, status, item) => {
        switch (status) {
            case 'edit':
                this.setState({
                    hoverEditBtn: true
                })
                break
            case 'delete':
                this.setState({
                    hoverDeleteBtn: true
                })
                break
            case 'add' :
                this.setState({
                    hoverAddBtn: true
                })
                break
            case 'open' :
                this.setState({
                    hoverOpenWorkflowUi: item
                })
                break
            default: break
        }
    }
    onLeaveAction = () => {
        this.setState({
            hoverEditBtn:false,
            hoverDeleteBtn:false,
            hoverAddBtn:false,
            hoverOpenWorkflowUi: {}
        })
    }
    onActionEdit = () => {
        const { selectedRedaction, listRedactionColour, selectedRedactionList } = this.state
        if(selectedRedactionList.length === 0) return

        const activeList = listRedactionColour.find(item => item.redactionColorProfileId === selectedRedactionList[0])
        listRedactionColour && listRedactionColour.length > 0 && listRedactionColour.forEach((item) => {
            if (activeList !== null && activeList.redactionColorProfileId === item.redactionColorProfileId) {
                this.setState({
                    formModal: {
                        name: item.redactionColorProfileName,
                        id: item.redactionColorProfileId
                    }
                })
            }
        })
        this.setState({
            modalAction: 'edit',
            visibleModal: true,
        })
    }
    showConfirmDeleteWorkflow = (e) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete redaction colour?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onRedactionColourProfileDelete(e)
            }
        })
    }
    onRedactionColourProfileDelete = () => {
        const { selectedRedaction, selectedRedactionList, listRedactionColour } = this.state
        if(selectedRedactionList && selectedRedactionList.length > 0) {
            let redactionColorProfileIds = []
            listRedactionColour && listRedactionColour.length > 0 && listRedactionColour.forEach((elem) => {
                if (selectedRedactionList.includes(elem.redactionColorProfileId)) {
                    redactionColorProfileIds.push(elem.redactionColorProfileId)
                }
            })
            //redactionColorProfileIds.push(selectedRedaction.redactionColorProfileId)
            const deleteRedactionColorProfileDTO = {
                redactionColorProfileIds: selectedRedactionList
            }
            apiRedactionColourProfileDelete(deleteRedactionColorProfileDTO).then((response) => {
                if(response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.setState({
                    selectedRedaction: null,
                    selectedRedactionList: []
                }, () => {
                    this.onGetRedactionColour()
                })
            })
        }
    }
    onClickVisibleModal = () => {
       this.setState({
           visibleModal: true,
           formModal: {
               name: '',
           },
           modalAction: 'create'
       })
    }
    updateValue = (e, field) => {
        if (field === 'name') {
            this.setState({
                formModal: {...this.state.formModal, name: e.target.value }
            })
        }
    }
    handleCancel = () => {
        this.setState({
            visibleModal: false,
            formModal: {...this.state.formModal, name: '', id: null }
        })
    }
    onCreateRedactionColour = () => {
        const { formModal } = this.state
        const createRedactionColorProfileDTO = {
            redactionColorProfileName: formModal.name
        }
        this.setState({
            disableAddWorkflowTemplate: true
        }, () => {
            apiRedactionColourProfileCreate(createRedactionColorProfileDTO).then((response) => {
                if (response.status === 200) {
                    if (response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if (response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    this.setState({
                        visibleModal: false,
                        formModal: {...this.state.formModal, name: '', id: null},
                        disableAddWorkflowTemplate: false
                    })
                    this.onGetRedactionColour()
                }
            })
        })
    }
    onEditRedactionColour = () => {
        const { selectedRedaction, formModal } = this.state
        const createRedactionColorProfileDTO = {
            redactionColorProfileName: formModal.name,
            redactionColorProfileId: formModal.id,
        }
        this.setState({
            disableAddWorkflowTemplate: true
        }, () => {
            apiRedactionColourProfileEdit(createRedactionColorProfileDTO).then((response) => {
                if (response.status === 200) {
                    if (response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if (response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    this.setState({
                        visibleModal: false,
                        formModal: {...this.state.formModal, name: '', id: null},
                        disableAddWorkflowTemplate: false,
                        selectedRedaction: null
                    })
                    this.onGetRedactionColour()
                }
            })
        })
    }
    addOrRemoveSelectedLists = (e, item) => {
        e.stopPropagation()
        let { selectedRedaction, selectedRedactionList } = this.state
        if(e.target.checked) {
            selectedRedaction= item;
            selectedRedactionList.push(item.redactionColorProfileId)
        }else{
            selectedRedaction = null;
            selectedRedactionList = selectedRedactionList.filter(item2 => item2 !== item.redactionColorProfileId)
        }
        if (selectedRedactionList && selectedRedactionList.length > 0) {
            selectedRedactionList =  selectedRedactionList.filter((a, i) => selectedRedactionList.findIndex((s) => a === s) === i)
        } else {
            selectedRedactionList = []
        }
        this.setState({selectedRedaction, selectedRedactionList})

    }
    onOpenRedactionColourSettings = (e, item) => {
       this.setState({
           selectedRedactionColour: item,
           visibleRedactionColourSettings: true,
       })
    }
    onOpenRedactionColourSettingsDefault = () => {
        return true
    }
    onBackRedactionColour = () => {
       this.setState({
           visibleRedactionColourSettings: false,
           selectedRedactionColour: {}
       }, () => {
           this.onGetRedactionColour()
       })
    }
    render() {
        const { hoverEditBtn, hoverDeleteBtn, hoverAddBtn, visibleModal, listRedactionColour, selectedRedactionColour, hoverOpenWorkflowUi,
            modalAction, formModal, disableAddWorkflowTemplate, selectedRedaction, visibleRedactionColourSettings, selectedRedactionList} = this.state
        return (
            <div className='redaction-colour'>
                { visibleRedactionColourSettings ?
                    <RedactionColourProfiles selectedRedactionColour={selectedRedactionColour} onBackRedactionColour={this.onBackRedactionColour}/>
                    :
                    <div className='redaction-colour-page'>
                        <div className="redaction-colour-page__content">
                            <div className="redaction-colour-header">
                                <span className="title-header">Redaction colour profiles</span>
                            </div>
                            <div className="content-redaction-colour-page">
                                <div className="content-buttons-actions">
                                    <button onClick={(e) => this.onActionEdit(e)}
                                            disabled={ selectedRedactionList.length === 0 || selectedRedactionList.length > 1}
                                            className={hoverEditBtn ? "icon-v17-edit-active action-edit" : "icon-v17-edit action-edit"}
                                            onMouseEnter={(e) => this.onHoverAction(e, 'edit')}
                                            onMouseLeave={() => this.onLeaveAction()}>
                                    </button>
                                    <button
                                        onClick={(e) => this.showConfirmDeleteWorkflow(e)}
                                        disabled={selectedRedactionList.length === 0}
                                        className={hoverDeleteBtn ? "icon-v17-delete-active action-delete" : "icon-v17-delete action-delete"}
                                        onMouseEnter={(e) => this.onHoverAction(e, 'delete')}
                                        onMouseLeave={() => this.onLeaveAction()}>
                                    </button>
                                    <button onClick={(e) => this.onClickVisibleModal(e)}
                                            className={hoverAddBtn ? "icon-v17-add-active" : "icon-v17-add"}
                                            onMouseEnter={(e) => this.onHoverAction(e, 'add')}
                                            onMouseLeave={() => this.onLeaveAction()}>
                                    </button>
                                </div>
                                <div className="content-table-redaction-colour">
                                    {listRedactionColour && listRedactionColour.length > 0 ?
                                        <div className="list-process">
                                            {listRedactionColour.map((item, index) => {
                                                return (
                                                    <div key={index} className="row-list-process">
                                        <span key={index} className="content-name">
                                            { !item.defaultProfile &&
                                                <Checkbox
                                                    //checked={selectedRedaction !== null && selectedRedaction.redactionColorProfileName === item.redactionColorProfileName ? true : false}
                                                    //checked={selectedRedactionList.includes(item.redactionColorProfileId) ? true : false}
                                                    onChange={(e) => this.addOrRemoveSelectedLists(e, item)}></Checkbox>
                                            }
                                            <span
                                                onClick={item.defaultProfile ? () => this.onOpenRedactionColourSettingsDefault() : (e) => this.onOpenRedactionColourSettings(e, item)}
                                                onMouseEnter={(e) => this.onHoverAction(e, 'open', item)}
                                                onMouseLeave={() => this.onLeaveAction()}
                                                className={!item.defaultProfile && hoverOpenWorkflowUi && hoverOpenWorkflowUi.redactionColorProfileName === item.redactionColorProfileName ? 'name-first name-first-active' : "name-first"}>{item.redactionColorProfileName}</span>
                                            {/*<span className="name-last">{item.workflowName}</span>*/}
                                        </span>
                                                        { !item.defaultProfile &&
                                                        <i
                                                            // onClick={(e) => this.onOpenWorkflowBuilderUi(e, item)}
                                                            className="icon-v14-arrow-bold"
                                                            onMouseEnter={(e) => this.onHoverAction(e, 'open', item)}
                                                            onMouseLeave={() => this.onLeaveAction()}></i>
                                                        }
                                                    </div>
                                                )
                                            })

                                            }
                                        </div>
                                        :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Modal className="new-workflow-modal new-redaction-colour-modal" title={ <span className="content-header-modal"><span>
                    { modalAction === 'create' ? 'Create colour profile' : 'Edit colour profile' }</span></span> }
                       visible={visibleModal}
                       footer={ null } closable={ false }>
                    <div className="new-process-section">
                        <form className="new-process-form">
                            <div className="process-modal-line">
                                <Input value={formModal.name ? formModal.name : ''}
                                       onChange={(e) => this.updateValue(e, 'name')}
                                       placeholder={'Name'}
                                />
                            </div>
                            <div className="content-buttons-modal">
                                <Button className="action-cancel" onClick={(e) => this.handleCancel(e)}>Cancel</Button>
                                { modalAction === 'create' ?
                                    <Button type="primary" className="action-save"
                                            onClick={(e) => this.onCreateRedactionColour(e)}
                                            disabled={formModal.name === '' || disableAddWorkflowTemplate === true}>Create</Button> :
                                    <Button type="primary" className="action-save"
                                            onClick={(e) => this.onEditRedactionColour(e)}
                                            disabled={formModal.name === '' || disableAddWorkflowTemplate === true}>Edit</Button>
                                }
                            </div>
                        </form>
                    </div>
                </Modal>

            </div>
        )
    }
}

RedactionColour.propTypes = {

}

export default RedactionColour