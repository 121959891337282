import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../DisclosureWorkflow.scss'
import moment from "moment";
import {
    downloadExportDeliveredPageDisclosure, downloadExportDeliveredPageDisclosurePost,
    downloadExportLoadedFilesDisclosure,
    getDisclosureDeliveredPageData
} from "../../../../../../api/SmartBox";
export class DeliveredDisclosure extends Component {
    state = {
       deliveredPage: {},
        ticketEncodedId: null
    }
    componentDidMount() {
       this.onDisclosureDeliveredPageData()
    }

    onDisclosureDeliveredPageData = () => {
        const { selectHeaderTickets, selectEditTickets, stepHeaderActive } = this.props
        const ticketId = selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId
        const workflowComponentId = stepHeaderActive.stepComponentWorkflowId
        getDisclosureDeliveredPageData(ticketId, workflowComponentId).then((response) => {
            this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
            this.props.onGetDisclosureStepsDetails()
            this.setState({
                deliveredPage: response.data,
                ticketEncodedId: response.data.ticketEncodedId,
            })
        })
    }

    onTimezoneConvert = (time) => {
        const localTimeZone = moment.tz.guess()
        const localDate = moment.utc(time).tz(localTimeZone)
        return `${ moment(localDate).format('YYYY.MM.DD, HH:mm') }`
    }

    onDownloadExportDeliveredPageDisclosure(e) {
        const { selectHeaderTickets, selectEditTickets } = this.props
        const ticketId = selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId
        const DTO = {
            ticketId: ticketId,
            browserData : moment().format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T'),
        }

        downloadExportDeliveredPageDisclosurePost(DTO).then(response => {

            if(response.data){
                // BE endpoint sends a readable stream of bytes
                const byteArray = s2ab(atob(response.data));
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }),
                );
                a.download = `Disclosure Workflow -  Deliverable record.xlsx`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }

            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }
        })

    }
    render() {
        const { deliveredPage, ticketEncodedId } = this.state
        return (
            <div className="delivered-disclosure">
                <div className="delivered-disclosure-header">
                    <span className="delivered-disclosure-name">Deliverable record</span>
                    {/*<span className="icon-v22-download-1"></span>*/}
                    { ticketEncodedId ?
                        <a
                            //href={downloadExportDeliveredPageDisclosure(ticketEncodedId)}
                           onClick={(e) => this.onDownloadExportDeliveredPageDisclosure(e)}
                           className='icon-v22-download-1'
                        ></a> :
                        <a
                           className='icon-v22-download-1 disabled-download-1'
                        ></a>
                    }
                </div>
                <div className="delivered-disclosure-content">

                   <div className="delivered-disclosure-contain">
                       <span className="record-header">Requester</span>
                       <div className="record-header-contain">
                           <span className="delivered-record-example">
                             <span className="title-record">Name:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.requestorName ? deliveredPage.requestorName : '' }</span>
                           </span>
                           <span className="delivered-record-example">
                             <span className="title-record">Email:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.requestorEmail ? deliveredPage.requestorEmail : '' }</span>
                           </span>
                           <span className="delivered-record-example">
                             <span className="title-record">Request details:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.requestorDetails ? deliveredPage.requestorDetails : '' }</span>
                           </span>
                       </div>
                   </div>

                    <div className="delivered-disclosure-contain">
                        <span className="record-header">Processor</span>
                        <div className="record-header-contain">
                           <span className="delivered-record-example">
                             <span className="title-record">Name:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.processorName ? deliveredPage.processorName : '' }</span>
                           </span>
                            <span className="delivered-record-example">
                             <span className="title-record">Email:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.processorEmail ? deliveredPage.processorEmail : '' }</span>
                           </span>
                        </div>
                    </div>

                    <div className="delivered-disclosure-contain">
                        <span className="record-header">Request process</span>
                        <div className="record-header-contain">
                           <span className="delivered-record-example">
                             <span className="title-record">Request received</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.requestReceivedDate ? this.onTimezoneConvert(deliveredPage.requestReceivedDate) : '' }</span>
                           </span>
                            { deliveredPage.stepsCompletion && deliveredPage.stepsCompletion.length > 0 && deliveredPage.stepsCompletion.map((elem) => {
                                return (
                                            <span className="delivered-record-example">
                                     <span className="title-record">{elem.title}</span>
                                     <span className="description-record">{ elem.date ? this.onTimezoneConvert(elem.date) : '' }</span>
                                   </span>
                                )
                            })

                            }
                        </div>
                    </div>

                    <div className="delivered-disclosure-contain">
                        <span className="record-header">Data delivered</span>
                        <div className="record-header-contain">
                           <span className="delivered-record-example">
                             <span className="title-record">Record of Processing Activity</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.ropadelivered ? deliveredPage.ropadelivered : '' }</span>
                           </span>
                            <span className="delivered-record-example">
                             <span className="title-record">{`Box data: ${ deliveredPage && deliveredPage.boxNameExported ? deliveredPage.boxNameExported : '' }`}</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.boxNameExported ? 'Yes' : '' }</span>
                           </span>
                            <span className="delivered-record-example">
                             <span className="title-record">Files</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.selectedFiles ? deliveredPage.selectedFiles : '' }</span>
                           </span>
                            <span className="delivered-record-example">
                             <span className="title-record">Types</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.filesType ? deliveredPage.filesType : '' }</span>
                           </span>
                            <span className="delivered-record-example">
                             <span className="title-record">Format</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.filesFormat ? deliveredPage.filesFormat : '' }</span>
                           </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


DeliveredDisclosure.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(DeliveredDisclosure)