import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import cookie from 'react-cookies'
import PropTypes from 'prop-types'
import CustomNotificationManager from '../Common/CustomNotificationManager'
import {
  apiUserLogin,
  apiResetWordListDownload,
  apiResetMfaUserLogin,
  apiGetUserLogin,
  apiWarmupTrigger, apiWarmupTriggerGetConnectList
} from '../../api/User'

import * as messageActions from '../../actions/messageActions'
import * as userActions from '../../actions/userActions'
import FormHeader from '../Common/FormHeader'
//import FormSignUpAction from '../Common/FormSignUpAction'
import Loading from '../Common/Loading'
import Message from '../Common/Message'
import { emailRegex, getHomeApplicationUrlPath } from '../../utils'
import '../../style/common.scss'
import PrivacyPolicy from '../Common/PrivacyPolicy'
import TermsOfService from '../Common/TermsOfService'
import './Login.css'
import './LoginUpdate.scss'
import { Spin, Tooltip } from 'antd'
import Smartbox_Logo_neg from '../../assets/Smartbox_Logo_neg.svg'
import FileDownload from 'js-file-download'
import withRouter from '../../private-routers/withRouter'

export class Login extends Component {
  constructor() {
    super()

    this.state = {
      email: '',
      isSending: false,
      password: '',
      isOpenedPrivacyPolicy: false,
      isOpenedTerms: false,
      hoverOnLogin: false,
      imageScan: '',
      valueDigitPasscode: '',
      secretCode: '',
      loadingAuthentication: false,
      loadingAuthenticationFailed: false,
      statusAuthenticationFinish: false,
      wordsCodes: [],
      userLoginOutputDTO: {},
      statusResetCode: false,
      valueResetPasscode: '',
      onHoverDownload: false,
      checkTryAgain: true,
      responseAccountIsLocked: {},
      timeAccountLocked: 30,
      companyLogo: ''
    }
  }

  componentWillUnmount() {
    this.props.hideMessage()
  }
  componentDidMount() {
    this.setState({
      hoverOnLogin: true
    })
    if (this.props.location.search) {
      this.doGetLogin(this.props.location.search)
    }
  }

  doGetLogin(search) {
    apiGetUserLogin(search).then((response) => {
        this.setState({
          companyLogo: response.data.companyLogo
        })
      const root = document.documentElement;
      response.data.colorsDTOS && response.data.colorsDTOS && response.data.colorsDTOS.length > 0 && response.data.colorsDTOS.forEach((brand) => {
        if (brand.color) {
          switch (brand.colorCode) {
            case 'background':
              root.style.setProperty('--navigationBackground', brand.color);
              break
            case 'background-hover':
              root.style.setProperty('--navigationBackgroundHover', brand.color);
              break
            case 'nav-body-font':
              root.style.setProperty('--navigationBodyFont', brand.color);
              break
            case 'nav-body-font-hover':
              root.style.setProperty('--navigationBodyFontHover', brand.color);
              break
            case 'sub-background':
              root.style.setProperty('--subNavigationBackground', brand.color);
              break
            case 'sub-background-hover':
              root.style.setProperty('--subNavigationBackgroundHover', brand.color);
              break
            case 'sub-nav-body-font':
              root.style.setProperty('--subNavigationBodyFont', brand.color);
              break
            case 'sub-nav-body-font-hover':
              root.style.setProperty('--subNavigationBodyFontHover', brand.color);
              break
            case 'sub-nav-body-font-active' :
              root.style.setProperty('--subNavigationBodyFontActive', brand.color);
              break
            case 'general-icon-font-hover' :
              root.style.setProperty('--generalIconFontHover', brand.color);
              break
            case 'general-icon-font-active' :
              root.style.setProperty('--generalIconFontActive', brand.color);
              break
            case 'general-button' :
              root.style.setProperty('--generalButton', brand.color);
              break
            case 'general-button-hover' :
              root.style.setProperty('--generalButtonHover', brand.color);
              break
            case 'general-button-active' :
              root.style.setProperty('--generalButtonActive', brand.color);
              break
            case 'general-button-font' :
              root.style.setProperty('--generalButtonFont', brand.color);
              break
            default:
              break;
          }
        }
      })
    })
  }
  handleSubmit(e) {
    e.preventDefault()
    if (!this.isFormValid()) return

    const data = {
      username: this.state.email,
      password: this.state.password
    }
    this.doLogin(data)
  }

  isFormValid() {
    const { email } = this.state

    // Validates if email has a valid format.
    if (!emailRegex.test(email.trim())) {
      CustomNotificationManager.error('Your email does not have a valid format.', 'Error')      
      return false
    }

    return true
  }

  doLogin(data) {
    this.setState({
      isSending: true,
    })
    this.props.hideMessage()
    apiUserLogin(data)
      .then((response) => {
        //this.onWarmupTrigger()
        const messageNotVerified = `Email not verified for user  email: ${ this.state.email }`
        if (response.data && Object.keys(response.data) && Object.keys(response.data).length > 0 && response.data.accountIsLocked) {
          // this.setState({
          //   responseAccountIsLocked: response.data,
          //   timeAccountLocked: response.data.accountLockedFor + 1
          // })

           this.setState({
             responseAccountIsLocked: response.data,
             timeAccountLocked: response.data.accountLockedFor + 1
           })
        }

        if (response.data.title && response.data.title.toLowerCase() === 'success') {
          CustomNotificationManager.success(response.data.message, 'Success')
        }

        if(response.data.title && response.data.title.toLowerCase() === 'info') {
          if (response.data.message === 'Waiting for the verification code!') {
            this.setState({
              imageScan: response.data.message,
            })
          } else {
            CustomNotificationManager.info(response.data.message, 'Info')
            this.setState({
              isSending: false,
            })
          }
        } else {
          this.setState({
            imageScan: response.data.qrCode,
            secretCode: response.data.secret,
          })
          // this.setState({
          //   loadingAuthentication: true,
          //   loadingAuthenticationFailed: false,
          // })
          const idToken = response.data.result.id_token
          const accessToken = response.data.result.access_token
          this.props.userLogin(idToken, accessToken)
          if (response.data.ospreyUser.user.user_metadata.roles['application'] !== 'god_admin' && response.data.ospreyUser.subscription.client.homeApplication) {
            let urlPath = getHomeApplicationUrlPath(response.data.ospreyUser.subscription.client)
            this.props.navigate(urlPath)
          }
          if(response.data.ospreyUser.user.user_metadata.roles['application'] === 'god_admin'){
            this.props.navigate('/admin/client-data')
          }
        }


        // if (response.data === 'Waiting for the verification code!') {
        //   this.setState({
        //     imageScan: response.data,
        //   })
        // } else if (response.data === 'Invalid e-mail address or password!') {
        //   CustomNotificationManager.info(`${ response.data }`)
        // } else if (response.data === 'Tenant is inactive') {
        //   CustomNotificationManager.info(`${ response.data }`)
        // } else if (response.data === 'Tenant does not exist') {
        //   CustomNotificationManager.info(`${ response.data }`)
        // } else if (response.data === 'Access denied!') {
        //   CustomNotificationManager.info(`${ response.data }`)
        // } else if (response.data === 'Request to reset password. The reset password link was sent to your email') {
        //   CustomNotificationManager.info(`${ response.data }`) }
        //   else if (response.data === 'An error occurs when trying to generate the QR Code!') {
        //   CustomNotificationManager.info(`${ response.data }`) }
        //   else if (response.data === messageNotVerified) {
        //   CustomNotificationManager.info(`${ response.data }`) }
        //     else if (response.data === 'Invalid code! Please try again!') {
        //   CustomNotificationManager.info(`${ response.data }`) }
        //   else {
        //   this.setState({
        //     imageScan: response.data.qrCode,
        //     secretCode: response.data.secret,
        //   })
        // }

      })
      .catch((error) => {
          if (error?.response?.data) {
            if (error.response.data.error && error.response.data.error.includes('The user is inactive')) {
              CustomNotificationManager.error('This account is inactive.', 'Inactive account')
            }
          }else if (error?.response?.status === 502) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        
        this.setState({
          isSending: false
        })
      })
  }

  onWarmupTrigger = () => {
    apiWarmupTrigger().then((response) => {
      if (response.data.triggerWarmUp) {
          this.onWarmupTriggerGetConnectList()
      }
    }).catch((error) => {
      console.log(error, 'error')
      this.onWarmupTriggerGetConnectList()
    })
  }

  onWarmupTriggerGetConnectList = () => {
    apiWarmupTriggerGetConnectList().then((response) => {

    }).catch((error) => {

    })
  }

  updateValue(field, e) {
    this.setState({
      [field]: e.target.value,
      hoverOnLogin: false
    })
  }
  updateDigitPasscode(e) {
    this.setState({
      valueDigitPasscode: e.target.value
    })
  }
  updateDigitPasscodePressEnter(e) {
    if (e.key === 'Enter') {
      this.doLoginAuthentication()
    }
  }
  doLoginAuthentication = () => {
    const { imageScan } = this.state
    let data = {}
    if (imageScan === 'Waiting for the verification code!') {
      data = {
        username: this.state.email,
        password: this.state.password,
        code: this.state.valueDigitPasscode
      }
    } else {
      data = {
        username: this.state.email,
        password: this.state.password,
        code: this.state.valueDigitPasscode,
        secret: this.state.secretCode
      }
    }
    this.setState({
      isSending: true,
      //loadingAuthentication: true
    })
    this.props.hideMessage()
    apiUserLogin(data)
      .then((response) => {
        //this.onWarmupTrigger()
        const messageNotVerified = `Email not verified for user  email: ${ this.state.email }`
        if (response.data.title && response.data.title.toLowerCase() === 'success') {
          CustomNotificationManager.success(response.data.message, 'Success')
        }
        if(response.data.title && response.data.title.toLowerCase() === 'info') {
          if (response.data.message === 'Waiting for the verification code!') {
            this.setState({
              imageScan: response.data.message,
            })
          } else {
            CustomNotificationManager.info(response.data.message, 'Info')
          }
        } else {
          this.setState({
            imageScan: response.data,
            wordsCodes: response.data.words,
            userLoginOutputDTO: response.data.userLoginOutputDTO,
          })
          this.setState({
            loadingAuthentication: true
          }, () => {
            if (!response.data.words) {
              this.loginActiveAuthentication(response)
            }
          })
        }

        // if (response.data === 'Invalid e-mail address or password!') {
        //   CustomNotificationManager.info(`${ response.data }`)
        // } else if (response.data === 'Tenant is inactive') {
        //   CustomNotificationManager.info(`${ response.data }`)
        // } else if (response.data === 'Tenant does not exist') {
        //   CustomNotificationManager.info(`${ response.data }`)
        // } else if (response.data === 'Access denied!') {
        //   CustomNotificationManager.info(`${ response.data }`)
        // } else if (response.data === 'Request to reset password. The reset password link was sent to your email') {
        //   CustomNotificationManager.info(`${ response.data }`) }
        // else if (response.data === 'An error occurs when trying to generate the QR Code!') {
        //   CustomNotificationManager.info(`${ response.data }`) }
        // else if (response.data === messageNotVerified) {
        //   CustomNotificationManager.info(`${ response.data }`) }
        // else if (response.data === 'Invalid code! Please try again!') {
        //   CustomNotificationManager.info(`${ response.data }`) }
        // else {
        //   this.setState({
        //     imageScan: response.data,
        //     wordsCodes: response.data.words,
        //     userLoginOutputDTO: response.data.userLoginOutputDTO,
        //   })
        //   this.setState({
        //     loadingAuthentication: true
        //   }, () => {
        //     if (!response.data.words) {
        //       this.loginActiveAuthentication(response)
        //     }
        //   })
        // }

        // setTimeout(function() {
        //   this.setState({
        //     //loadingAuthentication: false
        //   }, () => {
        //     this.setState({
        //       imageScan: response.data,
        //       wordsCodes: response.data.words
        //     })
        //     if (!response.data.words) {
        //       this.loginActiveAuthentication(response)
        //       const idToken = response.data.result.id_token
        //       const accessToken = response.data.result.access_token
        //       this.props.userLogin(idToken, accessToken)
        //       if (response.data.ospreyUser.user.user_metadata.roles['application'] !== 'god_admin' && response.data.ospreyUser.subscription.client.homeApplication) {
         //      getHomeApplicationUrlPath(response.data.ospreyUser.subscription.client)
        //       }
        //     }
        //   })
        // }.bind(this), 2000)
      })
      .catch((error) => {
          if (error?.response?.data) {
            if (error?.response?.data?.error && error?.response?.data?.error.includes('The user is inactive')) {
              CustomNotificationManager.error('This account is inactive.', 'Inactive account')
            }
          }
          if (error?.response?.status === 502) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        
        setTimeout(function() {
          this.setState({
            loadingAuthenticationFailed: true
          })
        }.bind(this), 2000)
        this.setState({
          isSending: false
        })
      })
  }
  loginActiveAuthentication = (response) => {
    const { imageScan } = this.state
    this.setState({
      isSending: true,
      loadingAuthentication: true
    })
    setTimeout(function() {
      this.setState({
        loadingAuthentication: false
      }, () => {
        this.setState({
          imageScan: response.data,
        })
        if (!response.data.title && !response.data.words) {
          const idToken = response.data.result.id_token
          const accessToken = response.data.result.access_token
          this.props.userLogin(idToken, accessToken)
          if (response.data.ospreyUser.user.user_metadata.roles['application'] !== 'god_admin' && response.data.ospreyUser.subscription.client.homeApplication) {
            //redirectToHomeApplication(response.data.ospreyUser.subscription.client)
            const urlPath = getHomeApplicationUrlPath(response.data.ospreyUser.subscription.client)
            this.props.navigate(urlPath)
          }
          if(response.data.ospreyUser.user.user_metadata.roles['application'] === 'god_admin'){
            this.props.navigate('/admin/client-data')
          }
        }
      })
    }.bind(this), 2000)
  }


  imageScan = (response) => {
    this.setState({
      isSending: true,
      loadingAuthentication: true
    })
    setTimeout(function() {
      this.setState({
        loadingAuthentication: false
      }, () => {
        this.setState({
          imageScan: response.data,
        })
        if (!response.data.words) {
          const idToken = response.data.result.id_token
          const accessToken = response.data.result.access_token
          this.props.userLogin(idToken, accessToken)
          if (response.data.ospreyUser.user.user_metadata.roles['application'] !== 'god_admin' && response.data.ospreyUser.subscription.client.homeApplication) {
            const urlPath = getHomeApplicationUrlPath(response.data.ospreyUser.subscription.client)
            this.props.navigate(urlPath)
          }
          if (response.data.ospreyUser.user.user_metadata.roles['application'] === 'god_admin'){
            this.props.navigate('/admin/client-data')
          }
        }
      })
    }.bind(this), 2000)
  }
  onResetCodes = () => {
    this.setState({
      statusResetCode: true
    })
  }
  onResetCodesActive = () => {
    const resetMfaUserLogin = {
      email: this.state.email,
      word: this.state.valueResetPasscode
    }
    apiResetMfaUserLogin(resetMfaUserLogin).then((response) => {

      if (response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
        this.setState({
          statusResetCode: false,
          loadingAuthentication: false,
          imageScan: ''
        })
      } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
        this.setState({
          statusResetCode: true
        })
      }

      // if (response.data === 'Code Invalid. Please try again!!') {
      //   this.setState({
      //     statusResetCode: true
      //   })
      //   CustomNotificationManager.info('Code Invalid. Please try again!!', 'Cancelled')
      // } else if (response.data === 'Your MFA Account reset successfully! Please login to configure it!') {
      //   CustomNotificationManager.success('Your MFA Account reset successfully! Please login to configure it!', 'Success')
      //   this.setState({
      //     statusResetCode: false,
      //     loadingAuthentication: false,
      //     imageScan: ''
      //   })
      // }

    })
  }
  resetDigitPasscode(e) {
    this.setState({
      valueResetPasscode: e.target.value
    })
  }
  doAuthenticationFinish = () => {
    const { userLoginOutputDTO } = this.state
    this.setState({
      statusAuthenticationFinish: false,
      loadingAuthentication: true
    })
    setTimeout(function() {
      this.setState({
        loadingAuthentication: false
      }, () => {
        const idToken = userLoginOutputDTO.result.id_token
        const accessToken = userLoginOutputDTO.result.access_token
        this.props.userLogin(idToken, accessToken)
        if (userLoginOutputDTO.ospreyUser.user.user_metadata.roles['application'] !== 'god_admin' && userLoginOutputDTO.ospreyUser.subscription.client.homeApplication) {
          const urlPath = getHomeApplicationUrlPath(userLoginOutputDTO.ospreyUser.subscription.client)
          this.props.navigate(urlPath)
        }
        if (userLoginOutputDTO.ospreyUser.user.user_metadata.roles['application'] === 'god_admin'){
          this.props.navigate('/admin/client-data')
        }
      })
    }.bind(this), 2000)
  }
  onTryAgain = () => {
    this.setState({
      loadingAuthenticationFailed: false,
      loadingAuthentication: false
    })
  }
  showPrivacyPolicy = (e) => {
    this.setState({
      isOpenedPrivacyPolicy: true
    })
    e.preventDefault()
  }

  hidePrivacyPolicy = () => {
    this.setState({
      isOpenedPrivacyPolicy: false
    })
  }
  showTermsOfService = (e) => {
    this.setState({
      isOpenedTerms: true
    })
    e.preventDefault()
  }
  hideTermsOfService = (e) => {
    this.setState({
      isOpenedTerms: false
    })
  }
  onMouseEnterOnPanel(e, status) {
    if (status === 'download') {
      this.setState({
        onHoverDownload: true
      })
    }
  }
  onMouseLeaveOnPanel() {
    this.setState({
      onHoverDownload: false
    })
  }
  onDownloadCode = () => {
    const { wordsCodes, email } = this.state
    const resetWordListDownloadDTO = {
      email: email,
      words: wordsCodes
    }
    apiResetWordListDownload(resetWordListDownloadDTO).then((response) => {
      FileDownload(response.data, 'Reset_MFA_Words.pdf')
    })
  }
  render() {
    const { email, password, isSending, isOpenedPrivacyPolicy, isOpenedTerms, hoverOnLogin, imageScan, valueDigitPasscode, loadingAuthentication, loadingAuthenticationFailed,
      wordsCodes, statusResetCode, onHoverDownload, responseAccountIsLocked, timeAccountLocked, companyLogo } = this.state
    const { isLogged, messageArea } = this.props
    if (cookie.load('logout_reason')) {
      messageArea.visible = true
      messageArea.status = 'error'
      messageArea.text = cookie.load('logout_reason')
      cookie.remove('logout_reason', { path: '/' })
    }
    return (
      <div className="loginPage-page">
        {/* {
          isLogged &&
          <Navigate to="/smart-box/management" />
        } */}
        { (!loadingAuthentication && imageScan === '') || (!loadingAuthentication && Object.keys(responseAccountIsLocked) && Object.keys(responseAccountIsLocked).length > 0 && responseAccountIsLocked.accountIsLocked) ?
          <div className="new-content-login-page">
            { !isLogged &&
            <div className="loginPage-header">
              <img className="logo-osprey-header" src={ Smartbox_Logo_neg } alt="Osprey"/>
            </div>
            }
            { !isLogged &&
            <div className={ Object.keys(responseAccountIsLocked) && Object.keys(responseAccountIsLocked).length > 0 && responseAccountIsLocked.accountIsLocked ? "loginPage-box loginPage-boxTry" : 'loginPage-box'}>
              <FormHeader
                title=""
                subtitle=""
                companyLogo={companyLogo}/>

              <form className="loginPage-form">
                { messageArea.visible &&
                <div className="loginPage-message">
                  <Message
                    hideMessageAction={ this.props.hideMessage }
                    status={ messageArea.status }
                    text={ messageArea.text }/>
                </div>
                }


                <div className="loginPage-container">
                  { Object.keys(responseAccountIsLocked) && Object.keys(responseAccountIsLocked).length > 0 && responseAccountIsLocked.accountIsLocked &&
                    <div className="content-unsuccessful-account">
                      <span className="icon-v18-process-error account-process-error"></span>
                      <span className="text-unsuccessful-account">{ `You have tried to login too many times.
                    Please try again in ${ timeAccountLocked } minutes or check your emails for more information.` }</span>
                    </div>
                  }
                  <div className="loginPage-formGroup">
                    <label htmlFor="email">Email address</label>
                    <input
                      id="email"
                      type="text"
                      onChange={ this.updateValue.bind(this, 'email') }/>
                  </div>

                  <div className="loginPage-formGroup">
                    <label htmlFor="password">Password</label>
                    <input
                      id="password"
                      type="password"
                      onChange={ this.updateValue.bind(this, 'password') }/>
                  </div>
                </div>

                <div className="loginPage-footer">
                  { !isSending &&
                  <div className="loginPage-footerForm">
                    {/*<Link*/ }
                    {/*  id="password-recovery-link"*/ }
                    {/*  to="/osp/password-recovery">Forgot password?</Link>*/ }

                    <input
                      disabled={ hoverOnLogin === true ? false : email === '' || password === '' }
                      id="submit"
                      type="submit"
                      value="Log In"
                      className="input-login input-login-update"
                      onClick={ this.handleSubmit.bind(this) }/>
                  </div>
                  }

                  { isSending &&
                  <div className="loginPage-loading">
                    <Loading/>
                  </div>
                  }
                </div>
                <div className="loginPage-footerForm forget-password-footer">
                  <Link
                    id="password-recovery-link"
                    to="/osp/password-recovery">Forgot password?</Link>
                </div>
              </form>

              {/*<FormSignUpAction />*/ }
              <div className="display-privacy-policy">
                <button className="open-privacy"
                  onClick={ () => window.open(/*'https://smartbox.ai/wp-content/uploads/2021/02/Privacy-Notice-2021.pdf'*/ 'https://smartbox.ai/privacy-notice/', '_blank') }>Privacy
                  Notice
                </button>
                <button className="sign-up-link term-of-service-login"
                  onClick={ () => window.open( /*'https://smartbox.ai/wp-content/uploads/2021/03/Smartbox.ai-TCs-24.03.21.pdf'*/ 'https://smartbox.ai/terms-conditions/', '_blank') }>Terms
                  of Service
                </button>
              </div>
              { isOpenedPrivacyPolicy &&
              <PrivacyPolicy hidePrivacyPolicy={ this.hidePrivacyPolicy }/>
              }
              {
                isOpenedTerms &&
                <TermsOfService hideTermsOfService={ this.hideTermsOfService }/>
              }
            </div>
            }
          </div>

          : !loadingAuthentication && imageScan !== 'Waiting for the verification code!' ?

            <div className="new-content-login-page content-authentication-page">
              <div className="loginPage-header">
                <img className="logo-osprey-header" src={ Smartbox_Logo_neg } alt="Osprey" />
              </div>
              <div className="authentication-page">
                <div className="authentication-header">
                  <span>Authentication</span>
                </div>
                <div className="content-app">
                  <div className="content-app-text-scan">
                    <span className="text-app">1. Open your authentication app.</span>
                    <Tooltip overlayClassName="information-authentication-app" title={ <div>
                      <div className="header-authentication-info">
                        <div className="authentication-info"><span className="icon-v12-question"></span><span className="text-header">Authentication App (TOTP)</span></div>
                        <div><span className="text-close">X</span></div>
                      </div>
                      <div className="content-authentication-info">
                        <span><span className="text-information">To enable app-based two factor authentication (2FA) using an authentication app like</span>
                          <span className="text-link" onClick={ () => window.open('https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en', '_blank') }> Google Autenticator </span><span className="text-information">or</span>
                          <span className="text-link" onClick={ () => window.open('https://authy.com/download/', '_blank') }> Authy </span>
                          <span className="text-information">, click enable ans scan the QR code with the app on your phone.</span></span>
                      </div>
                      <div>
                        <span className="text-link-footer" onClick={ () => window.open('https://en.wikipedia.org/wiki/Time-based_One-Time_Password', '_blank') }> How does TOTP work? </span>
                      </div>
                    </div> }>
                      <span className="icon-v12-question image-help"></span>
                    </Tooltip>
                  </div>
                  <div className="content-app-QRscan">
                    <span className="text-app">2. Scan this QR Code with the app.</span>
                  </div>
                  <div className="content-image-scan">
                    <img src={ imageScan } alt={ imageScan } className="image-scan-display"/>
                  </div>
                  <div className="content-enter-passcode">
                    <span className="text-app">3. Enter the 6-digit passcode from the in </span>
                    <span className="text-app text-app2">authentication app</span>
                  </div>
                  <div>
                    <span className="text-app text-app-digit">6-digit passcode</span>
                    <input autoFocus={ true } className="input-authentication" onKeyPress={ (e) => this.updateDigitPasscodePressEnter(e) }
                      onChange={ (e) => this.updateDigitPasscode(e) }/>
                  </div>
                  <div className="loginPage-footer new-loginPage-footer">
                    <input
                      id="submit"
                      type="submit"
                      value="Authenticate"
                      className="input-login"
                      disabled={ valueDigitPasscode === '' }
                      onClick={ this.doLoginAuthentication }/>
                  </div>
                </div>
              </div>
            </div>

            : !loadingAuthentication ?
              <div className="new-content-login-page">
                <div className="loginPage-header">
                  <img className="logo-osprey-header" src={ Smartbox_Logo_neg } alt="Osprey"/>
                </div>
                { !statusResetCode ?
                  <div className="authentication-page authentication-page-active">
                    <FormHeader
                      title=""
                      subtitle=""/>
                    <div className="email-address-authentication">
                      <span className="text-app">Email address</span>
                      <span className="text-app-email">{ email }</span>
                    </div>
                    <div>
                      <div className="content-tooltip-login-info">
                      <span className="text-app text-app-digit">6-digit passcode</span>
                        <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                          <span className="text-information">Enter the 6-digit code displayed in your authenticator app.</span>
                          <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001264677', '_blank') }>More info.</span>
                        </div>}>
                          <span className="icon-v14-question"></span>
                        </Tooltip>
                      </div>
                      <input autoFocus={ true } className="input-authentication" onKeyPress={ (e) => this.updateDigitPasscodePressEnter(e) }
                      onChange={ (e) => this.updateDigitPasscode(e) }/>

                    </div>
                    <div className="loginPage-footer new-loginPage-footer new-login-reset">
                      <input
                        id="submit"
                        type="submit"
                        value="Login"
                        className="input-login"
                        disabled={ valueDigitPasscode === '' }
                        onClick={ this.doLoginAuthentication }/>
                      <input
                        id="submit"
                        type="submit"
                        value="Recover"
                        className="input-login input-login-reset"
                        onClick={ this.onResetCodes }/>
                    </div>
                  </div> :
                  <div className="authentication-page reset-code-page-active">
                    <FormHeader
                      title=""
                      subtitle=""/>
                    <div>
                      {/*<span className="text-app text-app-digit">Reset passcode</span>*/}
                      <div className="content-tooltip-login-info">
                      <span className="text-app text-app-digit">Enter recovery code</span>
                        <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                          <span className="text-information">Enter one of your recovery codes received during MFA setup.</span>
                          <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001264677', '_blank') }>More info.</span>
                        </div>}>
                          <span className="icon-v14-question"></span>
                        </Tooltip>
                      </div>
                      <input className="input-authentication"
                        onChange={(e) => this.resetDigitPasscode(e) }/>
                    </div>
                    <div className="loginPage-footer new-loginPage-footer">
                      <input
                        id="submit"
                        type="submit"
                        value="Reset"
                        className="input-login"
                        onClick={ this.onResetCodesActive }/>
                    </div>
                  </div>
                }
              </div>

              : wordsCodes && wordsCodes.length > 0 ?

                <div className="new-content-login-page content-authentication-page">
                  <div className="loginPage-header">
                    <img className="logo-osprey-header" src={ Smartbox_Logo_neg } alt="Osprey" />
                  </div>
                  <div className="authentication-page">
                    <div className="authentication-header-successful">
                      <span className="authentication-header-title">Authentication</span>
                      <span><span className="icon-v14-radio-button-tick radio-button-successful"></span><span className="successful-header">Successful</span></span>
                    </div>
                    <div className="content-app">
                      <div className="content-text-codes">
                        <p>If you are unable to access your virtual
                          MFA device, you can log into your account
                          using one of these recovery codes. Each
                          code can only be used once.
                          Please copy, download or print these
                          codes and store them in a safe place.
                          These recovery codes replace any
                          previously generated codes.</p>
                      </div>
                      <div className="content-codes-list-app">
                        <ul className="columns-codes" data-columns="2">
                          { wordsCodes && wordsCodes.length > 0 && wordsCodes.map((code, index) => {
                            return (
                              <li key={ index }>{code}</li>
                            )
                          })
                          }
                        </ul>
                      </div>
                      <div className="content-functionality-codes-app">
                        <span><span className="icon-v14-copy icon-functionality"></span><span>Copy</span></span>
                        <span style={{ cursor: 'pointer' }} onClick={ (e) => this.onDownloadCode(e) }
                          onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'download') }
                          onMouseLeave={ () => this.onMouseLeaveOnPanel() }>
                          <span className={ onHoverDownload ? 'icon-v14-download-active icon-functionality' : 'icon-v14-download icon-functionality' }></span><span>Download</span></span>
                        <span><span className="icon-v14-print icon-functionality"></span><span>Print</span></span>
                      </div>
                    </div>
                    <div className="loginPage-footer new-loginPage-footer">
                      <input
                        id="submit"
                        type="submit"
                        value="Finish"
                        className="input-login"
                        onClick={ this.doAuthenticationFinish }/>
                    </div>
                  </div>
                </div>
                :
                <div className="new-content-login-page">
                  <div className="loginPage-header">
                    <img className="logo-osprey-header" src={ Smartbox_Logo_neg } alt="Osprey" />
                  </div>
                  <div className={ loadingAuthenticationFailed ? 'authentication-page-loading authentication-page-loading-failed' : 'authentication-page-loading' }>
                    <div className="authentication-header authentication-header22">
                      <span>Authentication</span>
                    </div>
                    <div className="content-loading-authentication">
                      { loadingAuthentication && !loadingAuthenticationFailed ?
                        <div className="example-loading">
                          <Spin size="large" spinning={ loadingAuthentication }/>
                        </div> : !loadingAuthentication && !loadingAuthenticationFailed ?
                          <div className='content-success'>
                            <span className="icon-v12-radio-button-tick success-loading"></span>
                            <span className="success-loading">Successful</span>
                          </div> :
                          <div>
                            <div className="failed-authentication">
                              <span className="icon-v12-remove"></span>
                              <span>Failed</span>
                            </div>
                            <div className="loginPage-footer new-loginPage-footer">
                              <input
                                id="submit"
                                type="submit"
                                value="Try again"
                                className="input-login"
                                onClick={ this.onTryAgain }/>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  isLogged: state.userReducer.isLogged,
  messageArea: state.messageAreaReducer.messageArea
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(messageActions.hideMessage()),
  showMessage: (status, text) => dispatch(messageActions.showMessage({ status, text })),
  userLogin: (idToken, accessToken) => dispatch(userActions.userLogin(idToken, accessToken)),
  userLogout: () => dispatch(userActions.userLogout())
})

Login.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired,
  userLogout: PropTypes.func.isRequired
}

Login = withRouter(Login)
export default compose(
  connect(stateMap, dispatchMap)
)(Login)