import React from 'react'
import './ReportDashboard.scss'
import { Progress, Card, Tooltip } from 'antd'

import PropTypes from 'prop-types'
import {
    apiBubbleChartStatisticsReport, apiBubblePerDay, apiGetClutterCardInfo, apiGetDuplicatesCardInfo,
    apiGetTotalSizeBox,
    apiGetUserBoxLogReportInfo, apiPieChartDataReport
} from "../../../../../api/SmartBox";
import moment from "moment";
//import ReactMinimalPieChart from 'react-minimal-pie-chart'
import DataSet from "@antv/data-set";
// import G2 from "@antv/g2";
import  { Chart as Chart2 }  from '@antv/g2'
import { Chart } from "react-google-charts"
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import cookie from "react-cookies";
import withRouter from "../../../../../private-routers/withRouter";
import {HeaderNavigationBox} from "../../../../Common/HeaderNavigationBox";
import {connect} from "react-redux";
import {
    setGetDuplicateCardInfo,
    setSmartBoxProgressCleanDeleteDuplicates,
    setSmartBoxResponseDeleteDuplicates,
    setSmartBoxSelectedDuplicateFile, setSmartReprocessingFileDelete
} from "../../../../../actions/smartBoxActions";

class ReportDashboard extends React.Component {
        state = {
          checkExpandFilesType: false,
            docsSizeBoxMB: 0,
            nrOfFilesWithFailuresBox: 0,
            fileTypes: [],
            statusApproved: 0,
            statusRedacted: 0,
            statusSent: 0,
            nrOfFilesBox: 0,
            listUsersReport: [],
            selectedBaseItem: '',
            basicLevelData: [
                { name: 'PII', type:'PII', found: 33.33333, categoryGrandParent: 'grandParent', key:'ar', oldName: 'Analytic Results', level: 1, value: 90 },
                { name: 'Reg EX', type:'Reg EX', found: 33.33333, categoryGrandParent: 'grandParent', key:'re', oldName: 'Regular expression', level: 1, value: 5 },
                { name: 'Bus Terms', type:'Bus Terms', found: 33.33333, categoryGrandParent: 'grandParent', key:'mt', oldName: 'Business Terms', level: 1, value: 5 },
            ],
            dataPieChart: [
                ["Category", "Found"],
                ["Work", 11],
                ["Eat", 2],
                ["Commute", 2],
                ["Watch TV", 2],
                ["Sleep", 7],
            ],
            dataPieChart2: [],
            dataBubbleChart: [],
            dataReportPiaChart: {},
            treePiaChartNew: [],
            newPiaChart: [],
            bubbleChart: [],
            monthsYear: ['Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22'],
            numberDuplicatesFiles: 0,
            dateCreatedBubble: '',
            getDateBubble: '',
            onActivePerDay: false,
            numberClutterFiles: 0,
            setIntervalDuplicatesCardInfo: 0,
            responseDuplicatesCardInfo: '',
            requestIdRandom: null,
            tagInfo: []
        }

  componentDidMount() {
      this.setState({
          requestIdRandom: Math.floor(Math.random() * 100000) + 5
      }, () => {
          this.onApiGetRedactionServiceUrl()
          this.onGetUserBoxLogReportInfo()
          this.getBubbleChartStatisticsReport()
          this.getPieChartDataReport()
          this.onGetDuplicatesCardInfo()
          this.onGetClutterCardInfo()
          this.getDateCreated()
      })
            //this.extractPisChart()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.treeForSettings !== this.props.treeForSettings && this.props.treeForSettings) {
            //this.extractPisChart()
            const piaChart1 = []
            const piaChartHeader = ['category', 'found']
            //let piaChartTreeNew = []
            piaChart1.push(piaChartHeader)
            this.props.treeForSettings && this.props.treeForSettings.length > 0 && this.props.treeForSettings.forEach((tree) => {
                piaChart1.push([tree.nameCategory, tree.found])
            })
            this.setState({
                dataPieChart2: piaChart1
            })
        }
        if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === 'ReportDashboard') {
            this.setState({
                requestIdRandom: Math.floor(Math.random() * 100000) + 5
            }, () => {
                this.onApiGetRedactionServiceUrl()
                this.onGetUserBoxLogReportInfo()
                this.getBubbleChartStatisticsReport()
                this.getPieChartDataReport()
                this.onGetDuplicatesCardInfo()
                this.onGetClutterCardInfo()
                this.getDateCreated()
            })
        }
    }
    getDateCreated = () => {
        const { folderParent, smartBoxBaseDataTree } = this.props
        let parentName = ''
        let dateBox = ''
        //let piaChartDashboard = {}
        if (folderParent && typeof folderParent.relativePath !== 'undefined') {
            parentName = folderParent.relativePath.split('/')[1]
        }
        smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
            if (parentName === list.name) {
                dateBox = list.creationDate
            }
        })
        this.setState({
            dateCreatedBubble: dateBox ? dateBox : folderParent.creationDate
        })
    }
    getPieChartDataReport = () => {
            //const { treePiaChartNew } = this.state
            const { folderParent, smartBoxBaseDataTree } = this.props
        let parentName = ''
        let idBox = ''
        let piaChartDashboard = {}
        if (folderParent && typeof folderParent.relativePath !== 'undefined') {
            parentName = folderParent.relativePath.split('/')[1]
        }
        smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
            if (parentName === list.name) {
                idBox = list.id
            }
        })
        apiPieChartDataReport(idBox ? idBox : this.props.folder.id).then((response) => {
            const pieList = []
            piaChartDashboard = {
                percentDictionary: response.data.percentDictionary,
                percentPII: response.data.percentPII,
                percentRegex: response.data.percentRegex,
            }
            // response.data && Object.keys(response.data) && Object.keys(response.data).forEach((pie) => {
            //     if (response.data && response.data.percentDictionary === 0 && response.data.percentPII === 0 && response.data.percentRegex === 0 )
            //     {
            //         pieList.push({
            //             nameCategory: pie === 'percentPII' ? 'PII' : pie === 'percentDictionary' ? 'Dictionary' : pie === 'percentRegex' ? 'Regular Expressions' : '',
            //             percentCategory: 33.33,
            //             name: pie,
            //             color: pie === 'percentPII' ? '#00d4d8' : pie === 'percentDictionary' ? '#01E493' : pie === 'percentRegex' ? '#E4C801' : '',
            //         })
            //     } else {
            //         pieList.push({
            //             nameCategory: pie === 'percentPII' ? 'PII' : pie === 'percentDictionary' ? 'Dictionary' : pie === 'percentRegex' ? 'Regular Expressions' : '',
            //             percentCategory: response.data[pie],
            //             name: pie,
            //             color: pie === 'percentPII' ? '#00d4d8' : pie === 'percentDictionary' ? '#01E493' : pie === 'percentRegex' ? '#E4C801' : '',
            //         })
            //     }
            // })
            piaChartDashboard && Object.keys(piaChartDashboard) && Object.keys(piaChartDashboard).forEach((pie) => {
                if (piaChartDashboard && piaChartDashboard.percentDictionary === 0 && piaChartDashboard.percentPII === 0 && piaChartDashboard.percentRegex === 0 )
                {
                    pieList.push({
                        nameCategory: pie === 'percentPII' ? 'NaN' : pie === 'percentDictionary' ? 'NaN' : pie === 'percentRegex' ? 'NaN' : '',
                        percentCategory: 33.33,
                        name: pie,
                        color: pie === 'percentPII' ? '#00d4d8' : pie === 'percentDictionary' ? '#01E493' : pie === 'percentRegex' ? '#E4C801' : '',
                    })
                } else {
                    pieList.push({
                        nameCategory: pie === 'percentPII' ? 'PII' : pie === 'percentDictionary' ? 'Dictionary' : pie === 'percentRegex' ? 'Regular Expressions' : '',
                        percentCategory: response.data[pie],
                        name: pie,
                        color: pie === 'percentPII' ? '#00d4d8' : pie === 'percentDictionary' ? '#01E493' : pie === 'percentRegex' ? '#E4C801' : '',
                    })
                }
            })
            this.setState({
                dataReportPiaChart: response.data,
                treePiaChartNew: pieList,
            }, () => this.updateFormatListPia())
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    updateFormatListPia = () => {
            const { treePiaChartNew } = this.state
        const piaChart = []
        const piaChartHeader = ['category', 'found']
        piaChart.push(piaChartHeader)
        treePiaChartNew && treePiaChartNew.length > 0 && treePiaChartNew.forEach((tree) => {
            piaChart.push([tree.nameCategory, tree.percentCategory])
        })
        this.setState({
            newPiaChart: piaChart
        })
    }
  onApiGetRedactionServiceUrl = () => {
      const { folderParent, smartBoxBaseDataTree } = this.props
      let parentName = ''
      let pathBox = ''
      if (folderParent && typeof folderParent.relativePath !== 'undefined') {
          parentName = folderParent.relativePath.split('/')[1]
      }
      smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
          if (parentName === list.name) {
              pathBox = list.path
          }
      })
      const leftTreeReportDTO = {
          boxPath: pathBox ? pathBox : this.props.folder.path
      }
      apiGetTotalSizeBox(leftTreeReportDTO).then((response) => {
         const fileTypesKeys = []
         response.data && Object.keys(response.data.fileTypes) && Object.keys(response.data.fileTypes).length > 0 && Object.keys(response.data.fileTypes).forEach((file, key) => {
             fileTypesKeys.push({
                 nameFile: file,
                 sizeFile: response.data.fileTypes[file]
             })
         })
         this.setState({
             docsSizeBoxMB: response.data.docsSizeBoxMB,
             nrOfFilesWithFailuresBox: response.data.nrOfFilesWithFailuresBox,
             nrOfFilesBox: response.data.nrOfFilesBox,
             tagInfo: response.data.tagInfo,
             fileTypes: fileTypesKeys,
             statusApproved: response.data.fileStatus && response.data.fileStatus.approved ? response.data.fileStatus.approved : 0,
             statusRedacted: response.data.fileStatus && response.data.fileStatus.redacted ? response.data.fileStatus.redacted : 0,
             statusSent: response.data.fileStatus && response.data.fileStatus.sent ? response.data.fileStatus.sent : 0,
         })

     }).catch((error) => {
        if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
     })
   }

   getBubbleChartStatisticsReport = () => {
            //const { dataBubbleChart } = this.state
       const { folderParent, smartBoxBaseDataTree } = this.props
       let parentName = ''
       let idBox = ''
       if (folderParent && typeof folderParent.relativePath !== 'undefined') {
           parentName = folderParent.relativePath.split('/')[1]
       }
       smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
           if (parentName === list.name) {
               idBox = list.id
           }
       })
       const bubbleChartOne = []
       const bubbleChartHeader = ['Title', 'Column', 'Row', 'Data Type', 'Files' ]
       bubbleChartOne.push(bubbleChartHeader)
       //const index = 1
       apiBubbleChartStatisticsReport(idBox ? idBox : this.props.folder.id).then((response) => {
           this.setState({
               getDateBubble: response.data.boxCreationDate
           })
           if (response.data && response.data.boxLogEntities && response.data.boxLogEntities.length > 0) {
               response.data.boxLogEntities.forEach((item, i) => {
                   if (item.uploaded_files && item.uploaded_files !== 0) {
                       bubbleChartOne.push(['', i + 1, 1, 'Uploaded', item.uploaded_files === null ? 0 : item.uploaded_files])
                   }
               })
               response.data.boxLogEntities.forEach((item, i) => {
                   if (item.deleted_files && item.deleted_files !== 0) {
                       bubbleChartOne.push(['', i + 1, 2, 'Deleted', item.deleted_files === null ? 0 : item.deleted_files])
                   }
               })
               response.data.boxLogEntities.forEach((item, i) => {
                   if (item.redacted_files && item.redacted_files !== 0) {
                       bubbleChartOne.push(['', i + 1, 3, 'Bulk redacted', item.redacted_files === null ? 0 : item.redacted_files])
                   }
                   //bubbleChartOne.push(['', 0, 0, '', 0])
               })
           } else {
               bubbleChartOne.push(['no date', 0, 0, 'no date', 0])
           }
           this.setState({
               bubbleChart: response.data.boxLogEntities
           })
       }).catch((error) => {
        if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
       })
       this.setState({
           dataBubbleChart: bubbleChartOne
       })

       // const bubbleChartOne = []
       // const bubbleChartHeader = ['logDate', 'Data redacted', 'Data deleted', 'Data uploaded']
       // bubbleChartOne.push(bubbleChartHeader)
       // apiBubbleChartStatisticsReport(this.props.folder.id).then((response) => {
       //     if (response.data && response.data.boxLogEntities && response.data.boxLogEntities.length > 0) {
       //         response.data.boxLogEntities.forEach((item) => {
       //             bubbleChartOne.push([item.logDate, item.redacted_files === null ? 0 : item.redacted_files, item.deleted_files === null ? 0 : item.deleted_files, item.uploaded_files === null ? 0 : item.uploaded_files])
       //         })
       //     } else {
       //         bubbleChartOne.push(['no date', 0, 0, 0])
       //     }
       // })
       // this.setState({
       //     dataBubbleChart: bubbleChartOne
       // })
   }
    onGetUserBoxLogReportInfo = () => {
        const { folderParent, smartBoxBaseDataTree } = this.props
        let parentName = ''
        let idBox = ''
        if (folderParent && typeof folderParent.relativePath !== 'undefined') {
            parentName = folderParent.relativePath.split('/')[1]
        }
        
        smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
            if (parentName === list.name) {
                idBox = list.id
            }
        })

        apiGetUserBoxLogReportInfo(idBox ? idBox : this.props.folder.id).then((response) => {
            this.setState({
                listUsersReport: response.data.boxLogs
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    onGetDuplicatesCardInfo = () => {
            const { setIntervalDuplicatesCardInfo, requestIdRandom } = this.state
        const { folderParent, smartBoxBaseDataTree } = this.props
        let parentName = ''
        let idBox = ''
        if (folderParent && typeof folderParent.relativePath !== 'undefined') {
            parentName = folderParent.relativePath.split('/')[1]
        }
        smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
            if (parentName === list.name) {
                idBox = list.id
            }
        })
        const boxReportDuplicateRequestDTO = {
            boxId: idBox ? idBox : this.props.folder.id,
            //requestId: requestIdRandom ? requestIdRandom : null
        }
        apiGetDuplicatesCardInfo(boxReportDuplicateRequestDTO).then((response) => {
            cookie.save('checkGetDuplicatesCardInfo', 'getCardInfo', {path: '/'})
            this.props.setGetDuplicateCardInfo(response.data)
            this.setState({
                numberDuplicatesFiles: response.data,
                responseDuplicatesCardInfo: response.data,
            })
            if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
                this.setIntervalGetDuplicatesCardInfo()
            } else {
                clearInterval(setIntervalDuplicatesCardInfo)
                this.setState({setIntervalDuplicatesCardInfo})
            }
            // if(response.data === 'processing') {
            //     this.setIntervalGetDuplicatesCardInfo()
            // } else {
            //     clearInterval(setIntervalDuplicatesCardInfo)
            //     this.setState({setIntervalDuplicatesCardInfo})
            // }
        }).catch((error) => {
            if (error && error.response && error.response.status && error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              } else{
                console.log(error)
              }
        })
    }

    setIntervalGetDuplicatesCardInfo = () => {
        let { setIntervalDuplicatesCardInfo } = this.state
        const { responseDuplicatesCardInfo } = this.state
        if (setIntervalDuplicatesCardInfo === 0) {
            if (responseDuplicatesCardInfo.title && responseDuplicatesCardInfo.title === 'Info') {
                setIntervalDuplicatesCardInfo = setInterval(this.callBackGetDuplicatesCardInfo, 5000)
                this.setState({
                    setIntervalDuplicatesCardInfo
                })
            } else {
                clearInterval(setIntervalDuplicatesCardInfo)
            }
        }
    }

    callBackGetDuplicatesCardInfo = () => {
        const { folderParent, smartBoxBaseDataTree } = this.props
        const { responseDuplicatesCardInfo, requestIdRandom } = this.state
        let parentName = ''
        let idBox = ''
        if (folderParent && typeof folderParent.relativePath !== 'undefined') {
            parentName = folderParent.relativePath.split('/')[1]
        }
        smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
            if (parentName === list.name) {
                idBox = list.id
            }
        })
        const boxReportDuplicateRequestDTO  = {
            boxId: idBox ? idBox : this.props.folder.id,
            //requestId: requestIdRandom ? requestIdRandom : null
        }
        const itemCardInfo = cookie.load('checkGetDuplicatesCardInfo', { path: '/' })
        if (responseDuplicatesCardInfo.title && responseDuplicatesCardInfo.title === 'Info' && itemCardInfo === 'getCardInfo') {
            let responseCard = 0
            apiGetDuplicatesCardInfo(boxReportDuplicateRequestDTO).then((response) => {
                this.props.setGetDuplicateCardInfo(response.data)
                if (!responseDuplicatesCardInfo.title) {
                    cookie.remove('checkGetDuplicatesCardInfo', { path: '/' })
                }
                this.setState({
                    numberDuplicatesFiles: response.data,
                    responseDuplicatesCardInfo: response.data,
                })

            }).catch((error) => {
                if (error && error.response && error.response.status && error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                    console.log(error)
                }
            })
        }
    }

    onGetClutterCardInfo = () => {
        const { folderParent, smartBoxBaseDataTree } = this.props
        let parentName = ''
        let idBox = ''
        let idBoxRefresh = ''
        if (folderParent && typeof folderParent.relativePath !== 'undefined') {
            parentName = folderParent.relativePath.split('/')[1]
        }
        
        smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
            if (parentName === list.name) {
                idBox = list.id
            }
        })
        apiGetClutterCardInfo(idBox ? idBox : this.props.folder.id).then((response) => {
            this.setState({
                numberClutterFiles: response.data
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
   onClickExpandFilesTypes = () => {
      this.setState({
          checkExpandFilesType: !this.state.checkExpandFilesType
      })
    }
    onContentTitleFilesTypes = () => {
        const { checkExpandFilesType, fileTypes } = this.state
            return (
                <div>
                    <span className="content-title-types">
                    <span>File types</span>
                        <span onClick={(e) => this.onClickExpandFilesTypes(e)}
                              className={ checkExpandFilesType ? 'icon-v20-shrink' : "icon-v20-expand" }></span>
                    </span>
                    { checkExpandFilesType &&
                        <div className="content-files-type">
                            { fileTypes && fileTypes.length > 0 && fileTypes.map((file, index) => {
                                return (
                                    <span key={index} className="files-type">
                                    <span>{ file.nameFile }</span>
                                     <span>{ file.sizeFile }</span>
                                     </span>
                                )
                            })
                            }
                        </div>
                    }
                </div>
            )
    }
    formatPercent = (percent, successPercent) => {
        if (percent === 100) {
            return '100 %'
        } else {
            return `${percent} %`
        }
    }
   extractPisChart = () => {
            const { basicLevelData } = this.state
       const chart = new Chart2.Chart({
           container: 'data-insights-chart-new',
           forceFit: true,
           height: 800,
           padding: 'auto',
           width: 1000
       })
       const ds3 = new DataSet()
       const dv3 = ds3.createView()
       dv3.source(basicLevelData).transform({
           type: 'percent',
           field: 'value',
           dimension: 'name',
           as: 'percent'
       })
       const outterView3 = chart.view();
       outterView3.source(dv3);
       outterView3.coord('theta', {
           innerRadius: 0.05 / 0.3,
           radius: 0.3
       })
       outterView3.intervalStack().position('percent').color('type', [ '#00d4d8', '#E4C801', '#01E493' ])
           .style({
               stroke: 'white',
               lineWidth: 7
           })
           .label('name*nrChildren', (name, nrChildren) =>{
               return {
                   content: name,
                   style: {
                       textAlign: 'center',
                       fontSize: 19,
                       shadowBlur: 2,
                       shadowColor: 'rgba(0, 0, 0, .45)',
                       fill: '#f00',
                       fontWeight: 600,
                       color: '#f00'
                   },
                   offset: -5
               }
           })
           .opacity(1);
       chart.render()
   }
   // labelDisplayPia = (data) => {
   //     //let percentage = 0
   //     data.color = '#000000'
   //     let percentPii = {}
   //     let percentDict = {}
   //     let percentReg = {}
   //     data.data && data.data.length > 0 && data.data.forEach((da) => {
   //         if (da.title === 'Analytic Results') {
   //             data.title = 'pii'
   //             percentPii = {
   //               title: 'pii',
   //                 percentage: da.percentage
   //             }
   //         } else if (da.title === 'Regular expression') {
   //             data.title1 = 'reg'
   //             percentReg = {
   //                 title: 'reg',
   //                 percentage: da.percentage
   //             }
   //         } else if (da.title === 'Business Terms') {
   //             data.title2 = 'dict'
   //             percentDict = {
   //                 title: 'dict',
   //                 percentage: da.percentage
   //             }
   //         }
   //     })
   //     if (data.data[0].title === 'Analytic Results') {
   //         return `${Math.round(data.data[0].percentage)} %`
   //     } else if (data.data[1].title === 'Regular expression') {
   //         return `${Math.round(data.data[1].percentage)} %`
   //     } else if (data.data[2].title === 'Business Terms') {
   //         return `${Math.round(data.data[2].percentage)} %`
   //     }
   //     //return percentage
   // }
   onBubbleLinePerDay = (e, month) => {
            const { onActivePerDay } = this.state
       this.setState({
           onActivePerDay: !this.state.onActivePerDay
       })
       if (onActivePerDay) {
           this.getBubbleChartStatisticsReport()
       } else {
           this.getBubblePerDay(month)
       }
   }

   getBubblePerDay = (month) => {
       const bubbleChartOne = []
       const bubbleChartHeader = ['Title', 'Column', 'Row', 'Data Type', 'Bubble Value' ]
       bubbleChartOne.push(bubbleChartHeader)
        const dataDay = {
            boxId: month.boxId,
            month: month.logDate,
        }
       apiBubblePerDay(dataDay).then((response) => {
           this.setState({
               getDateBubble: response.data.boxCreationDate
           })
           if (response.data && response.data.boxLogEntities && response.data.boxLogEntities.length > 0) {
               response.data.boxLogEntities.forEach((item, i) => {
                   if (item.uploaded_files && item.uploaded_files !== 0) {
                       bubbleChartOne.push(['', i + 1, 1, 'Uploaded', item.uploaded_files === null ? 0 : item.uploaded_files])
                   }
               })
               response.data.boxLogEntities.forEach((item, i) => {
                   if (item.deleted_files && item.deleted_files !== 0) {
                       bubbleChartOne.push(['', i + 1, 2, 'Deleted', item.deleted_files === null ? 0 : item.deleted_files])
                   }
               })
               response.data.boxLogEntities.forEach((item, i) => {
                   if (item.redacted_files && item.redacted_files !== 0) {
                       bubbleChartOne.push(['', i + 1, 3, 'Bulk redacted', item.redacted_files === null ? 0 : item.redacted_files])
                   }
                   //bubbleChartOne.push(['', 0, 0, '', 0])
               })
           } else {
               bubbleChartOne.push(['no date', 0, 0, 'no date', 0])
           }
           this.setState({
               bubbleChart: response.data.boxLogEntities
           })
       }).catch((error) => {
        if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
       })
       this.setState({
           dataBubbleChart: bubbleChartOne
       })
   }
    onTimezoneConvert = (time) => {
        const localTimeZone = moment.tz.guess()
        const localDate = moment.utc(time).tz(localTimeZone)
        return `${ moment(localDate).format('DD:MM:YYYY') } at ${ moment(localDate).format('HH:mm:ss') }`
    }
    render() {
            const { docsSizeBoxMB, nrOfFilesWithFailuresBox, fileTypes, statusApproved, statusRedacted, statusSent, nrOfFilesBox,
                listUsersReport, dataBubbleChart, newPiaChart, bubbleChart, numberDuplicatesFiles, dataReportPiaChart,
                dateCreatedBubble, getDateBubble, onActivePerDay, numberClutterFiles, tagInfo } = this.state
        const { treeForSettings } = this.props
        treeForSettings && treeForSettings.length > 0 && treeForSettings.forEach((tree) => {
            tree.value = tree.found
            if (tree.title === 'Analytic Results') {
                tree.color = '#00d4d8'
                tree.nameCategory = 'PII'
            } else if (tree.title === 'Regular expression') {
                tree.color = '#E4C801'
                tree.nameCategory = 'Regular Expressions'
            } else if (tree.title === 'Business Terms') {
                tree.color = '#01E493'
                tree.nameCategory = 'Dictionary'
            }
        })
        const options = {
            slices: [{color: '#01E493'}, {color: '#00d4d8'}, {color: '#E4C801'}],
            legend: {position: 'left', textStyle: {color: '#382E34', fontSize: 14, fontWeight: 400, fontFamily: 'ROBOTO', letterSpacing: '0.5px', lineHeight: '20px'}},
            chartArea: {width:'100%', height:'75%'},
            pieHole: 0.5,
            pieSliceTextStyle: {
                color: 'black',
            },
            tooltip: {textStyle: {color: '#382E34', fontSize: 12, fontWeight: 400, fontFamily: 'ROBOTO', letterSpacing: '0.5px', lineHeight: '20px'}},
            pieSliceText: ( dataReportPiaChart.percentDictionary === 0 && dataReportPiaChart.percentPII === 0 && dataReportPiaChart.percentRegex === 0 ) ? 'none' :
                Object.keys(dataReportPiaChart) && Object.keys(dataReportPiaChart).length > 0 ? '' : 'label',
        };
            // const tableBubble = [
            //     ['Year', 'Data redacted', 'Data deleted', 'Data uploaded'],
            //     ['Jan 21',  167,      120, 100],
            //     ['Feb 21',  136,      130, 120],
            //     ['Mar 21',  184,      50, 100],
            //     [ 'Apr 21',  278,      230, 110],
            //     ['May 21',  200,      210, 190],
            //     ['Jun 21',  170,      100, 100],
            //     ['Jul 21',  477,      80, 100],
            //     ['Sep 21',  477,      80, 100],
            //     ['Oct 21',  477,      80, 110],
            //     ['Nov 21',  477,      80, 140],
            // ]
        const optionsBubble = {
            //slices: [{color: '#00d4d8'}, {color: '#E4C801'}, {color: '#01E493'}],
            //title: 'Activity timeline',
            colors: ["#A6ACB2", "#EDA41E", "#665360"],

            //tooltip:{trigger: 'none'},
            vAxis: {
                viewWindow: {
                    max: 4,
                    min: 0,
                },
                gridlines: {
                    count: 4,
                    color : 'rgba(110,120,130, 0.15)',
                },
                textPosition: 'none'
            },
            hAxis: {
                viewWindow: {
                    max: bubbleChart.length + 1,
                    min: 0,
                },
                gridlines: {
                    count: bubbleChart.length + 1,
                    color : 'rgba(110,120,130, 0.15)',
                },
                textPosition: 'none'
            },
            'width': 1000,
            'height':500,
            bubble: {
                textStyle: {
                    auraColor: 'none'
                }
            },
            tooltip: {textStyle: {color: '#382e34', fontFamily: 'ROBOTO', fontName: 'Roboto'}},
            legend: "none"

        };
        bubbleChart && bubbleChart.length > 0 && bubbleChart.forEach((month) => {
            if (!onActivePerDay) {
                const substringDateComplete = month.logDateComplete ? month.logDateComplete.substring(month.logDateComplete.length - 2, month.logDateComplete.length) : ''
                const indexOfDate = month.logDate ? month.logDate.indexOf(" ", 0) : ''
                const substringLogDate = month.logDate ? month.logDate.substring(0, indexOfDate) : ''
                month.dateCreatedBubble = `${ substringLogDate } ${ substringDateComplete }`
            } else {
                const substringDateComplete = month.logDateComplete ? month.logDateComplete.substring(month.logDateComplete.length - 2, month.logDateComplete.length) : ''
                const indexOfDate = month.logDate ? month.logDate.indexOf(" ", 0) : ''
                const substringLogDate = month.logDate ? month.logDate.substring(0, indexOfDate) : ''
                month.dateCreatedDay = `${ substringDateComplete } ${ substringLogDate }`
            }
        })

        let percentRedacted = 0
        let percentApproved = 0
        let percentSent = 0
        if (!Number.isNaN(statusRedacted*100/nrOfFilesBox)) {
            percentRedacted = Number(statusRedacted*100/nrOfFilesBox).toFixed(1)
        }
        if (!Number.isNaN(statusApproved*100/nrOfFilesBox)) {
            percentApproved = Number(statusApproved*100/nrOfFilesBox).toFixed(1)
        }
        if (!Number.isNaN(statusSent*100/nrOfFilesBox)) {
            percentSent = Number(statusSent*100/nrOfFilesBox).toFixed(1)
        }
        return (
           <div className="report-dashboard">
               <div className="report-dashboard__header">
                   <span className="title-header">Dashboard</span>
                   <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                        <span className="text-information">The Dashboard provides a visual overview of your box file reports.</span>
                        <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174226-dashboard', '_blank') }>More info.</span>
                        </div>}>
                        <span className="icon-v14-question"></span>
                    </Tooltip>
               </div>

               <div className="report-dashboard__content-dashboard">
                   {/*//11111*/}
                   {/*222222*/}
                   <div className="content-ai-analysis">
                       <div className="first-ai-analysis">
                           <div className="content-cards">
                               <div>
                                   <Card
                                       title="Total data size"
                                   >
                                       <p>{ `${ docsSizeBoxMB } MB` }</p>
                                   </Card>
                               </div>
                               <div>
                                   <Card
                                       title={ this.onContentTitleFilesTypes() }
                                       // extra={ <span onClick={(e) => this.onClickExpandFilesTypes(e)}
                                       //               className={ checkExpandFilesType ? 'icon-v20-shrink' : "icon-v20-expand" }></span> }
                                   >
                                       <p>{fileTypes.length}</p>
                                   </Card>
                               </div>
                               <div>
                                   <Card
                                       title="File errors"
                                   >
                                       <p>{ nrOfFilesWithFailuresBox }</p>
                                   </Card>
                               </div>
                               <div>
                                   <Card
                                       title="Duplicates"
                                   >
                                       <p>{ this.props.responseSetCardInfoDuplicates.title && this.props.responseSetCardInfoDuplicates.title === 'Info' ? 'Processing data' : this.props.responseSetCardInfoDuplicates}</p>
                                   </Card>
                               </div>
                               <div>
                                   <Card
                                       title="Clutter"
                                   >
                                       <p>{ numberClutterFiles ? numberClutterFiles : 0 }</p>
                                   </Card>
                               </div>
                           </div>
                           <div className="ai-analysis">
                               <span className="title-ai-analysis">AI analysis</span>
                               <div className="content-ai-analysis">
                                   <div id="data-insights-chart-new" className="content-pia-chart">
                                       <Chart
                                           chartType="PieChart"
                                           data={ newPiaChart }
                                           options={options}
                                           width={"500px"}
                                           height={"300px"}
                                       />
                                   </div>
                               </div>

                           </div>
                           {/*333333*/}
                           <div className="content-work-progress">

                               <div className="work-progress-content">
                                   <span className="title-work">Tagged documents</span>
                                   <div className="progress-contain">
                                       { tagInfo && tagInfo.length > 0 && tagInfo.map((tag) => {
                                           return (
                                               <div className="progress-all" key={`rowKey${ tag.tagName }`}>
                                                   <span className="title-progress">{ tag.tagName }</span>
                                                   <Progress
                                                       //percent={ Number(percentRedacted) }
                                                       percent={ Number(Number(tag.tagDocumentsNr*100/nrOfFilesBox).toFixed(1)) }
                                                       format={this.formatPercent}
                                                       title= { `${tag.tagDocumentsNr} of ${nrOfFilesBox} files` }
                                                   />
                                               </div>
                                           )
                                       })

                                       }
                                       {/*<div className="progress-all">*/}
                                       {/*  <span className="title-progress">Redacted</span>*/}
                                       {/*    <Progress*/}
                                       {/*        percent={ Number(percentRedacted) }*/}
                                       {/*        format={this.formatPercent}*/}
                                       {/*        title= { `${statusRedacted} of ${nrOfFilesBox} files` }*/}
                                       {/*    />*/}
                                       {/*</div>*/}
                                       {/*<div className="progress-all">*/}
                                       {/*    <span className="title-progress">Approved</span>*/}
                                       {/*    <Progress*/}
                                       {/*        percent={ Number(percentApproved) }*/}
                                       {/*        format={this.formatPercent}*/}
                                       {/*        title= { `${statusApproved} of ${nrOfFilesBox} files` }*/}
                                       {/*    />*/}
                                       {/*</div>*/}
                                       {/*<div className="progress-all">*/}
                                       {/*    <span className="title-progress">Sent</span>*/}
                                       {/*    <Progress*/}
                                       {/*        percent={ Number(percentSent) }*/}
                                       {/*        format={this.formatPercent}*/}
                                       {/*        title= { `${statusSent} of ${nrOfFilesBox} files` }*/}
                                       {/*    />*/}
                                       {/*</div>*/}
                                   </div>
                               </div>

                               <div className="content-latest-member">
                                 <span className="title-member">Latest member interactions</span>
                                   <div className="latest-member-interactions">
                                       { listUsersReport && listUsersReport.length > 0 && listUsersReport.map((list, index) => {
                                         return (
                                             <div key={ index } className="member-interactions">
                                                 <img className="picture-member" src={list.picture_base64} alt={list.picture_base64}/>
                                                 <span className="member-list">
                                               <span className="name-member">{ list.userName }</span>
                                                      <span className="name-message">{ list.message ? list.message : '' }</span>
                                               <span className="time-member">{ list.date ? this.onTimezoneConvert(list.date) : '' }</span>
                                           </span>
                                             </div>
                                         )
                                       })
                                       }
                                   </div>
                               </div>
                           </div>

                       </div>
                       {/*<div className="report-dashboard__content-chart-bubble">*/}
                       {/*    <span className="activity-content"><span className="name-activity">Activity timeline</span>*/}
                       {/*        <span className="info-date">{getDateBubble ? `Box created: ${ moment(getDateBubble).format('DD-MM-YYYY') } at ${ moment(getDateBubble).format('HH:mm:ss') }` :*/}
                       {/*            `Box created: ${ moment(dateCreatedBubble).format('DD-MM-YYYY') } at ${ moment(dateCreatedBubble).format('HH:mm:ss') }` }</span></span>*/}
                       {/*    <div className="v-axis-bubble">*/}
                       {/*        <span className="title-v-axis">Bulk redacted</span>*/}
                       {/*        <span className="title-v-axis">Deleted</span>*/}
                       {/*        <span className="title-v-axis">Uploaded</span>*/}
                       {/*    </div>*/}
                       {/*    <div className="chart-bubble">*/}
                       {/*        <Chart*/}
                       {/*            chartType="BubbleChart"*/}
                       {/*            data={ dataBubbleChart }*/}
                       {/*            options={ optionsBubble }*/}
                       {/*            width={"1200px"}*/}
                       {/*            height={"500px"}*/}
                       {/*        />*/}
                       {/*    </div>*/}
                       {/*    <div className="h-axis-bubble">*/}
                       {/*        { bubbleChart && bubbleChart.length > 0 && bubbleChart.map((month, index) => {*/}
                       {/*            return (*/}
                       {/*                <span key={ index } onClick={(e) => this.onBubbleLinePerDay(e, month)}*/}
                       {/*                      className={onActivePerDay ? "title-h-axis title-h-axis-active": 'title-h-axis' }>{ !onActivePerDay ? month.logDate.replace('20', '') : month.dateCreatedDay }</span>*/}
                       {/*            )*/}
                       {/*        })*/}
                       {/*        }*/}
                       {/*    </div>*/}
                       {/*</div>*/}
                   </div>
               </div>

           </div>
        )
    }
}
ReportDashboard = withRouter(ReportDashboard)
ReportDashboard.propTypes = {
    folder: PropTypes.object.isRequired,
    treeForSettings: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => ({
    responseSetCardInfoDuplicates: state.smartBox.responseSetCardInfoDuplicates,
})

export default connect(mapStateToProps, {
    setGetDuplicateCardInfo
})(ReportDashboard)