import React from 'react'
import './NearDeduplication.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Select, Tooltip, Table, Checkbox, Dropdown, Menu, Icon, Spin } from 'antd'
import {
    apiGetBoxNearStatus,
    apiKeepNewestFile,
    getListDuplicateFileBox,
} from "../../../../api/SmartBox";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import cookie from "react-cookies";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
const { Option } = Select
const { Column } = Table
class NearTable extends React.Component {
    state = {
        pageLimitDisplay: 20,
        totalRecords: 0,
        filesPerPageLimit: 20,
        currentPage: 1,
        isPrevDisabled: true,
        isNextDisabled: false,
        inputPage: '',
        tableNear: [],
        selectedRowKeys: [],
        filesSelect: [],
        filesSelectRowsGlobal: [],
        filesSelectRows: [],
        nonSelectedRows: [],
        nonSelectedRowsIds: [],
        nonSelectedWords: [],
        selectAllChecked: false,
        checkSelectAll: false,
        onHoverForth: false,
        onHoverStart: false,
        onHoverBack: false,
        onHoverEnd: false,
        typeNewestFile: 'Keep newest file',
        checkStatusNear: '',
        setIntervalNear: 0,
        loadingKeepOptionNear: false,
        setIntervalListDuplicateFileBox: 0,
        answerListDuplicateFileBox: '',
        requestIdRandom: null,
        excludedHashes: [],
        selectHashes: [],
        selectAll: false
    }
    componentDidMount() {
        this.setState({
            requestIdRandom: Math.floor(Math.random() * 100000) + 5
        }, () => {
            this.onGetNearStatus()
        })
        cookie.remove('checkListNearFileBox', { path: '/' })
        cookie.remove('checkListDuplicateFileBox', { path: '/' })
        cookie.remove('checkListClutter', { path: '/' })
        cookie.remove('checkDeduplicationStatus', { path: '/' })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === 'near') {
            cookie.remove('checkListDuplicateFileBox', { path: '/' })
            cookie.remove('checkListClutter', { path: '/' })
            cookie.remove('checkDeduplicationStatus', { path: '/' })
            this.setState({
                requestIdRandom: Math.floor(Math.random() * 100000) + 5
            }, () => {
                this.onGetNearStatus()
            })
        }
        if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab !== 'near') {
             this.setState({
                 selectedRowKeys: [],
                 filesSelect: [],
                 filesSelectRowsGlobal: [],
                 filesSelectRows: [],
                 nonSelectedRows: [],
                 nonSelectedRowsIds: [],
                 nonSelectedWords: [],
                 checkSelectAll: false,
                 selectAllChecked: false,
             })
        }
    }
    onGetNearStatus = () => {
        const { setIntervalNear } = this.state
        const { folder } = this.props
        apiGetBoxNearStatus(folder.id).then((response) => {
            cookie.save('checkNearStatus', 'getNear', {path: '/'})
            if (response.status === 200) {
                this.setState({
                    checkStatusNear: response.data,
                    loadingKeepOptionNear: false
                })
                if (response.data === 'In Progress') {
                    this.setIntervalNearStatus()
                } else if (response.data === 'Finished') {
                    clearInterval(setIntervalNear)
                    this.setState({setIntervalNear})
                    this.onGetNearDuplicateList()
                }
            }
        })
    }
    setIntervalNearStatus = () => {
        const {  checkStatusNear } = this.state
        let { setIntervalNear } = this.state
        if (setIntervalNear === 0) {
            if (checkStatusNear === 'In Progress') {
                setIntervalNear = setInterval(this.callBackNearStatus, 1000)
                this.setState({
                    setIntervalNear
                })
            } else {
                clearInterval(setIntervalNear)
            }
        }
    }
    callBackNearStatus = () => {
        const { setIntervalNear, checkStatusNear } = this.state
        const { folder } = this.props
        const itemNear = cookie.load('checkNearStatus', { path: '/' })
        if (checkStatusNear === 'In Progress' && itemNear === 'getNear') {
            apiGetBoxNearStatus(folder.id).then((response) => {
                if (response.data === 'Finished') {
                    this.onGetNearDuplicateList()
                    clearInterval(setIntervalNear)
                    this.setState({ setIntervalNear })
                }  else {
                    this.setState({ setIntervalNear })
                }
                this.setState({
                    checkStatusNear: response.data
                })
            })
        } else if (checkStatusNear === 'In Progress') {
            clearInterval(setIntervalNear)
            this.setState({ setIntervalNear })
        }
    }
    onGetNearDuplicateList = () => {
        const { currentPage, filesPerPageLimit, requestIdRandom, setIntervalListDuplicateFileBox} = this.state
        const { folder } = this.props
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        const requestDuplicateNearDTO = {
            clientId: this.props.clientId,
            folderPath: rootPathParent,
            pageNr: currentPage,
            rows: filesPerPageLimit,
            //selectAll: false,
            //boxId: folder.id,
            deduplicationType: 'near-duplication',
            requestId: requestIdRandom ? requestIdRandom : null
        }

        getListDuplicateFileBox(requestDuplicateNearDTO).then((response) => {
            if (response.status === 200) {
                cookie.save('checkListNearFileBox', rootPathParent, {path: '/'})
                this.setState({
                    answerListDuplicateFileBox: response.data
                })
                response.data.dups && response.data.dups.length > 0 && response.data.dups.forEach((item, index) => {
                    item.key = index
                })
             if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                 this.setIntervalGetListDuplicateFileBox()
                }

                this.setState({
                    tableNear: response.data.dups ? response.data.dups: [],
                    totalRecords: response.data.totalRows ? response.data.totalRows : 0,
                }, () => {
                    this.updatePreviewNextPageNumber()
                })
                if (response.data.dups && response.data.dups.length > 0) {
                    this.setState({
                        requestIdRandom: Math.floor(Math.random() * 100000) + 5
                    })
                    clearInterval(setIntervalListDuplicateFileBox)
                    this.setState({setIntervalListDuplicateFileBox})
                }
                // if (response.data === 'processing') {
                //     //this.setIntervalGetListDuplicateFileBox()
                // } else {
                //     this.setState({
                //         tableNear: response.data.dups,
                //         totalRecords: response.data.totalRows,
                //     }, () => {
                //         this.updatePreviewNextPageNumber()
                //     })
                // }
            }
            })
    }

    setIntervalGetListDuplicateFileBox = () => {
        let { setIntervalListDuplicateFileBox } = this.state
        const { answerListDuplicateFileBox } = this.state
        if (setIntervalListDuplicateFileBox === 0) {
            if (answerListDuplicateFileBox.title && answerListDuplicateFileBox.title === 'Info') {
                setIntervalListDuplicateFileBox = setInterval(this.callBackGetListDuplicateFileBox, 5000)
                this.setState({
                    setIntervalListDuplicateFileBox
                })
            } else {
                clearInterval(setIntervalListDuplicateFileBox)
            }
        }
    }

    callBackGetListDuplicateFileBox = () => {
        const { currentPage, filesPerPageLimit, answerListDuplicateFileBox, requestIdRandom } = this.state
        const { folder } = this.props
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        const requestDuplicateNearDTO = {
            clientId: this.props.clientId,
            folderPath: rootPathParent,
            pageNr: currentPage,
            rows: filesPerPageLimit,
            //selectAll: false,
            //boxId: folder.id,
            deduplicationType: 'near-duplication',
            requestId: requestIdRandom ? requestIdRandom : null
        }
        const itemNear = cookie.load('checkListNearFileBox', { path: '/' })
        if (answerListDuplicateFileBox.title && answerListDuplicateFileBox.title === 'Info' && (itemNear && itemNear === rootPathParent)) {
            getListDuplicateFileBox(requestDuplicateNearDTO).then((response) => {
                response.data.dups && response.data.dups.length > 0 && response.data.dups.forEach((item, index) => {
                    item.key = index
                })
                if (response.data.dups && response.data.dups.length > 0) {
                    this.setState({
                        requestIdRandom: Math.floor(Math.random() * 100000) + 5
                    })
                    cookie.remove('checkListNearFileBox', { path: '/' })
                }
                if (response.data.dups && response.data.dups.length === 0) {
                    cookie.remove('checkListNearFileBox', { path: '/' })
                }
                this.setState({
                    answerListDuplicateFileBox: response.data
                })
                this.setState({
                    tableNear: response.data.dups ? response.data.dups : [],
                    totalRecords: response.data.totalRows ? response.data.totalRows : 0,
                    filesSelectRowsGlobal: [],
                    selectedRowKeys: [],
                    filesSelectRows: [],
                    nonSelectedRows: [],
                    nonSelectedRowsIds: [],
                    selectHashes: [],
                    excludedHashes: [],
                    selectAll: false,
                }, () => {
                    this.updatePreviewNextPageNumber()
                })
            })
        }
    }
    onCheckKeysSelectedRows = () => {
        const { tableNear, selectAll, selectHashes, excludedHashes } = this.state
        const updateKeys = []
        if (selectAll) {
            tableNear && tableNear.length > 0 && tableNear.forEach((list, index) => {
                if (!excludedHashes.includes(list.hash)) {
                    updateKeys.push(index)
                }
            })
        } else {
            tableNear && tableNear.length > 0 && tableNear.forEach((list, index) => {
                if (selectHashes.includes(list.hash)) {
                    updateKeys.push(index)
                }
            })
        }
        const uniqueUpdateKeys = [...new Set(updateKeys)]
        this.setState({
            selectedRowKeys: uniqueUpdateKeys
        })
    }

    onCheckSelectedRows = () => {
        const { tableNear, filesSelectRows, checkSelectAll, nonSelectedRows, filesSelectRowsGlobal } = this.state
        const updateKeys = []
        if (checkSelectAll) {
            tableNear && tableNear.length > 0 && tableNear.forEach((list, index) => {
                filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((list2, index2) => {
                    if (nonSelectedRows && nonSelectedRows.length > 0) {
                        if (!nonSelectedRows.includes(list.hash)) {
                            updateKeys.push(index)
                        }
                    } else {
                        updateKeys.push(index)
                    }
                })
            })
        } else {
            tableNear && tableNear.length > 0 && tableNear.forEach((list, index) => {
                filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((list2, index2) => {
                    if (list.hash === list2.hash) {
                        updateKeys.push(index)
                    }
                })
            })
        }
        const uniqueUpdateKeys = [...new Set(updateKeys)]
        this.setState({
            selectedRowKeys: uniqueUpdateKeys
        })
    }
    onSelectDeduplicateNear = (selectedRowKeys, filesSelect, e) => {

        const { tableNear, selectAll } = this.state

        const nonSelected = []
        const nonSelectedIds = []
        const nameFilesSelected = []
        const selectHashesNew = []
        let selectedHashesGlobal = []
        const nonSelectedHash = []
        let nonSelectedHashGlobal = []

        filesSelect && filesSelect.length > 0 && filesSelect.forEach((error) => {
            nameFilesSelected.push(error.hash)
        })

        if (!selectAll) {
            filesSelect && filesSelect.length > 0 && filesSelect.forEach((error) => {
                selectHashesNew.push(error.hash)
            })
            selectedHashesGlobal = this.state.selectHashes.concat(selectHashesNew)
            tableNear && tableNear.length > 0 && tableNear.forEach((error) => {
                if (!nameFilesSelected.includes(error.hash)) {
                    selectedHashesGlobal = selectedHashesGlobal.filter((el) => el !== error.hash)
                }
            })
        } else if (selectAll) {
            tableNear && tableNear.length > 0 && tableNear.forEach((error) => {
                if (!nameFilesSelected.includes(!error.hash)) {
                    nonSelectedHash.push(error.hash)
                }
            })
            nonSelectedHashGlobal = this.state.excludedHashes.concat(nonSelectedHash)
            nameFilesSelected && nameFilesSelected.length > 0 && nameFilesSelected.forEach((error) => {
                if (nonSelectedHashGlobal.includes(error)) {
                    nonSelectedHashGlobal = nonSelectedHashGlobal.filter((el) => el !== error)
                }
            })
        }

        let filesSelectedGlobal = this.state.filesSelectRowsGlobal.concat(filesSelect)
        let filesSelected = this.state.filesSelectRows.concat(filesSelect)
        tableNear && tableNear.length > 0 && tableNear.forEach((error) => {
            if(!nameFilesSelected.includes(error.hash)) {
                nonSelected.push(error.hash)
                nonSelectedIds.push(error.hash)
            }
        })
        this.state.filesSelectRowsGlobal && this.state.filesSelectRowsGlobal.length > 0 && this.state.filesSelectRowsGlobal.forEach((error) => {
            if(nonSelected.includes(error.hash)) {
                filesSelectedGlobal = this.state.filesSelectRowsGlobal.filter((el) => el.hash !== error.hash)
                this.setState({
                    filesSelectRowsGlobal: filesSelectedGlobal
                })
            }
        })

        const otherFileSelected = []
        this.state.filesSelectRows && this.state.filesSelectRows.length > 0 && this.state.filesSelectRows.forEach((error) => {
            if(nonSelected.includes(error.hash)) {
                filesSelected = this.state.filesSelectRows.filter((el) => el.hash !== error.hash)
            }
        })

        const uniqueFilesSelectedGlobal = filesSelectedGlobal && filesSelectedGlobal.length > 0 && filesSelectedGlobal.filter((a, i) => filesSelectedGlobal.findIndex((s) => a.hash === s.hash) === i)
        const uniqueFilesSelected = filesSelected && filesSelected.length > 0 && filesSelected.filter((a, i) => filesSelected.findIndex((s) => a.hash === s.hash) === i)

        const uniqueSelectedHashesGlobal = selectedHashesGlobal && selectedHashesGlobal.length > 0 && selectedHashesGlobal.filter((a, i) => selectedHashesGlobal.findIndex((s) => a === s) === i)
        const uniqueNonSelectedHashGlobal = nonSelectedHashGlobal && nonSelectedHashGlobal.length > 0 && nonSelectedHashGlobal.filter((a, i) => nonSelectedHashGlobal.findIndex((s) => a === s) === i)

        this.setState({
            nonSelectedRows: [...new Set(this.state.nonSelectedRows.concat(nonSelected))],
            nonSelectedRowsIds: [...new Set(this.state.nonSelectedRowsIds.concat(nonSelectedIds))],
        })
        this.setState({
            //selectedRowKeys: selectedRowKeys,
            filesSelectRows: uniqueFilesSelected === false ? [] : uniqueFilesSelected,
            filesSelectRowsGlobal: uniqueFilesSelectedGlobal === false ? [] : uniqueFilesSelectedGlobal,
            selectHashes: uniqueSelectedHashesGlobal === false ? [] : uniqueSelectedHashesGlobal,
            excludedHashes: uniqueNonSelectedHashGlobal === false ? [] : uniqueNonSelectedHashGlobal
        })

        this.setState({
            selectedRowKeys: selectedRowKeys,
            filesSelect: filesSelect })
    }
    changeFilesPerPageLimit = (e) => {
        this.setState({ filesPerPageLimit: e }, () => {
            this.onGetNearDuplicateList()
        })
    }
    handleMovePage = (e, direction) => {
        const { isPrevDisabled, isNextDisabled, totalRecords, filesPerPageLimit } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / filesPerPageLimit) ? Math.ceil(totalRecords / filesPerPageLimit) : this.state.currentPage + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(Math.ceil(totalRecords/filesPerPageLimit))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPage = (newPage) => {
        const { totalRecords, filesPerPageLimit } = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPage: newPage, inputPage: newPage }, () => {
            this.onGetNearDuplicateList()
        })
    }
    updatePreviewNextPageNumber = () => {
        const { totalRecords, currentPage } = this.state
        const totalPages = Math.ceil(totalRecords / this.state.filesPerPageLimit)
        const isPrevDisabled = currentPage > 1 ? false : true
        const isNextDisabled = currentPage < totalPages ? false : true
        this.setState({ isPrevDisabled, isNextDisabled, currentPage }, () => {
            //this.onCheckSelectedRows()
            this.onCheckKeysSelectedRows()
        })
    }
    handleInputPageChange = (e) => this.setState({ inputPage: e.target.value })
    handleInputPageEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlur()
        }
    }
    handleInputPageBlur = () => {
        const { inputPage } = this.state
        if (inputPage) {
            const paginationEnd = Math.ceil(this.state.totalRecords / this.state.filesPerPageLimit)
            if (inputPage > 0 && inputPage <= paginationEnd) {
                this.gotoPage(inputPage)
            } else {
                this.gotoPage(1)
                this.setState({ inputPage: 1 })
            }
        }
    }
    autoSelectedAll = (e) => {
        const { tableNear } = this.state
        const selectAllFiles = []
        const selectedAllKeys = []
        if (e.target.checked) {
            this.setState({
                selectAll: true,
                selectHashes: [],
                excludedHashes: [],
            })
        } else {
            this.setState({
                selectAll: false,
                excludedHashes: [],
            })
        }
        if (e.target.checked) {
            tableNear && tableNear.length > 0 && tableNear.forEach((item, index) => {
                item.key = index
                selectedAllKeys.push(index)
                selectAllFiles.push(item)
            })
            this.setState({
                selectedRowKeys: selectedAllKeys,
                checkSelectAll: true,
                filesSelect: selectAllFiles })
        } else {
            this.setState({
                selectedRowKeys: [],
                checkSelectAll: false,
                filesSelect: [] })
        }
      this.setState({
          selectAllChecked: e.target.checked
      })
    }
    onSelectedRowDuplicates = (e, record) => {
        this.props.selectFileNearBox(record)
        this.props.onVisibleNearList(true)
    }
    handleNewestFile = (e) => {
        e.preventDefault()
        const { typeNewestFile } = this.state
        customConfirm({
            title: <span className="icon-v21-clean"></span>,
            content: `Only keep the  ${ typeNewestFile === 'Keep newest file' ? 'newest' : 'biggest' } file(s)?`,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-duplicate confirm-remove-duplicates confirm-near',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.setState({
                    loadingKeepOptionNear: true
                }, () => {
                    this.onClickKeepNewestFile()
                })
            }
        })
    }
    onClickKeepNewestFile = () => {
        const { checkSelectAll, filesSelect, filesPerPageLimit, currentPage, typeNewestFile, filesSelectRowsGlobal,
            selectAll, selectHashes, excludedHashes} = this.state
        const { folder, clientId } = this.props
        const hashes = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((file) => {
            hashes.push(file.hash)
        })
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        let autoDedupingRequestDTO = {}
        if (selectAll) {
            autoDedupingRequestDTO = {
                clientId: clientId,
                folderPath: rootPathParent,
                pageNr: currentPage,
                rows: filesPerPageLimit,
                selectAll: true,
                hashes: null,
                excludedHashes: excludedHashes,
                boxId: folder.id,
                deduplicationType: typeNewestFile.toLowerCase()
            }
        }  else {
            autoDedupingRequestDTO = {
                clientId: clientId,
                folderPath: rootPathParent,
                hashes: selectHashes,
                excludedHashes: null,
                boxId: folder.id,
                deduplicationType: typeNewestFile.toLowerCase()
            }
        }
        apiKeepNewestFile(autoDedupingRequestDTO).then(() => {
            this.setState({
                filesSelect: [],
                selectedRowKeys: [],
            })
            this.onGetNearStatus()
        })
    }
    onClickMenu = (e) => {
        this.setState({
            typeNewestFile: e.key
        })
    }
    menuNewest = () => {
        return  <Menu onClick={this.onClickMenu}>
            <Menu.Item id="Keep newest file" key="Keep newest file">Keep newest file</Menu.Item>
            <Menu.Item id="Keep biggest file" key="Keep biggest file">Keep biggest file</Menu.Item>
        </Menu>;
    }
    onMouseEnterOnPanel(e, status) {
        switch (status) {
            case 'forth' :
                this.setState({
                    onHoverForth: true
                })
                break
            case 'start' :
                this.setState({
                    onHoverStart: true
                })
                break
            case 'back' :
                this.setState({
                    onHoverBack: true
                })
                break
            case 'end' :
                this.setState({
                    onHoverEnd: true
                })
                break
            default: break
        }
    }
    onMouseLeaveOnPanel(e, status) {
        this.setState({
            onHoverForth: false,
            onHoverStart: false,
            onHoverBack: false,
            onHoverEnd: false
        })

    }
    render() {
        const { totalRecords, filesPerPageLimit, currentPage, isPrevDisabled, isNextDisabled, inputPage, tableNear, selectedRowKeys, selectAllChecked,
            checkSelectAll, onHoverForth, onHoverStart, onHoverBack, onHoverEnd, typeNewestFile, filesSelect, checkStatusNear, loadingKeepOptionNear,
            answerListDuplicateFileBox, selectAll, excludedHashes, selectHashes } = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectDeduplicateNear,
        }
        const antIcon = <Icon type="loading" style={{ fontSize: 15 }} spin />;
        return (
            <div className="near-table">
                <div className="near-table__content">
                    <div className="content-actions-deduplicate-near">
                        <div className="button-auto-go">
                            {/*<Button onClick={(e) => this.handleNewestFile(e)} className="button-blue-go"*/}
                            {/*>*/}
                            {/*    Keep newest file*/}
                            {/*</Button>*/}
                            <Dropdown.Button
                                icon={<span className="icon-v21-arrow-bold"></span>}
                                className="button-dropdown-newest"
                                // loading={loadings[1]}
                                overlay={this.menuNewest}
                                disabled={ (answerListDuplicateFileBox.title && answerListDuplicateFileBox.title === 'Info') || filesSelect.length === 0}
                                //dropdownClassName="dropdown-newest-class"
                                onClick={(e) => this.handleNewestFile(e)}
                            >
                                { <span><Spin spinning={loadingKeepOptionNear} indicator={antIcon} /><span>{typeNewestFile}</span></span> }
                            </Dropdown.Button>
                        </div>
                    </div>
                  <div className="near-table-header">
                      <div className="content-select-all-near">
                          <div>
                              <div className={ checkSelectAll ? 'checkbox-near' : !checkSelectAll && selectedRowKeys.length > 0 ? 'select-non-checkbox' : 'checkbox-near' }>
                                  <Checkbox checked={ selectAllChecked }
                                            className="select-deduplicate-checkbox"
                                            onChange={(e) => this.autoSelectedAll(e)}>

                                  </Checkbox>
                              </div>
                          </div>
                          <div className="select-all-near">
                              <span>Select all</span>
                          </div>
                      </div>

                      <div className="buttons-control-pagination">
                          <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                           <span className={ tableNear && tableNear.length === 0 ? 'icon-v21-start-1 disabled-pagination' : isPrevDisabled ? 'icon-v21-start-1 disabled-back' : onHoverStart ? 'icon-v21-start-active' : 'icon-v21-start-1' }
                                 onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'start') }
                                 onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                                 onClick={ (e) => this.handleMovePage(e, 'first')}/>
                          </Tooltip>
                          <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                            <span className={tableNear && tableNear.length === 0 ? 'icon-v21-back-1 disabled-pagination' : isPrevDisabled ? 'icon-v21-back-1 disabled-back' : onHoverBack ? 'icon-v21-back-active' : 'icon-v21-back-1' }
                                  onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'back') }
                                  onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                                  onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                          </Tooltip>
                          { totalPages === 0 &&
                          <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
                          }
                          { totalPages === 1 &&
                          <span className='buttons-control-pagination__text'>{currentPage} of {totalPages} </span>
                          }
                          { totalPages > 1 &&
                          <span className='buttons-control-pagination__text'>
                <input
                    type="number"
                    className="insert-page"
                    placeholder= { currentPage }
                    value= { inputPage }
                    min="1"
                    max={totalPages}
                    onChange={ (e) => this.handleInputPageChange(e) }
                    onBlur={ () => this.handleInputPageBlur() }
                    onKeyDown={ (e) => this.handleInputPageEnter(e) }
                />
                <span>of {totalPages}</span>
              </span>
                          }
                          <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                            <span className={ tableNear && tableNear.length === 0 ? 'icon-v21-forth-1 disabled-pagination' : isNextDisabled ? 'icon-v21-forth-1 disabled-next' : onHoverForth ? 'icon-v21-forth-active' : 'icon-v21-forth-1' }
                                  onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'forth') }
                                  onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                           onClick={ (e) => this.handleMovePage(e, 'next')}/>
                          </Tooltip>
                          <Tooltip title="last" className={ isNextDisabled ? 'disabled' : '' }>
                      <span className={ tableNear && tableNear.length === 0 ? 'icon-v21-end-1 disabled-pagination' : isNextDisabled ? 'icon-v21-end-1 disabled-next' : onHoverEnd ? 'icon-v21-end-active' : 'icon-v21-end-1' }
                            onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'end') }
                            onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'last')}/>
                          </Tooltip>
                      </div>

                      <div className={ tableNear.length === 0 ? "per-page-near disabled" : "per-page-near"}>
                          <Select dropdownClassName="select-per-page-near"
                                  value={`Show`+ ' ' + `${filesPerPageLimit}` }
                                  onChange={ (e) => this.changeFilesPerPageLimit(e) }
                                  disabled={ tableNear.length === 0}>
                              <Option value="20">20</Option>
                              <Option value="50">50</Option>
                              <Option value="100">100</Option>
                          </Select>
                      </div>

                  </div>

                    <div className="table-near-content">
                        <Table
                            dataSource={ tableNear }
                            className="table-near"
                            pagination={ false }
                            showHeader={ false }
                            rowSelection={rowSelection}
                            loading={ checkStatusNear === 'In Progress'}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (e) => this.onSelectedRowDuplicates(e, record)
                                }
                            }}>
                            <Column className="column-name-duplicate" title="Name" dataIndex="fileName" key="fileName" render={(text, record) => {
                                return (
                                    <span key={ record.key } className="name-duplicate-content">
                                       <span className="content-number-duplicates"><span className="number-duplicates">{ record.numberOfDuplicates }</span></span>
                                    <span className="text-style">{text}</span>
                                 </span>
                                )
                            }}/>
                            <Column className="column-arrow-duplicate" title="Name" dataIndex="hash" key="hash" render={(text, record) => {
                                return (
                                    <span key={ record.key } className="arrow-duplicate-content">
                                       <span className="text-style icon-v16-arrow-medium rotate-arrow-medium"></span>
                                 </span>
                                )
                            }}/>
                        </Table>
                    </div>
                </div>
            </div>
        )

    }
}

NearTable.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default compose(
    connect(mapStateToProps),
)(NearTable)