import React from 'react'
import { SketchField } from '../../Common/CustomSketchField'
import { Page } from 'react-pdf'
//import { Page } from 'react-pdf/dist/esm/entry.webpack'

const fabric = require('fabric').fabric

const Tools = {
  Circle: 'circle',
  Line: 'line',
  Pencil: 'pencil',
  Rectangle: 'rectangle',
  Select: 'select',
  Pan: 'pan'
}

export class CanvasPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pageNumber: 1,
      fillColor: 'transparent',
      width: null,
      height: null,
      canUndo: false,
      canRedo: false,
      divPrint: 'div-to-print-portrait',
      canvasData: [],
      tool: Tools.Select,
      history: [],
      modal: false,
      isRendered: 0,
      removeSelectedBtnDisabled: false,
      totalPages: null,
      alreadyRedactedElements: []
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.forPageNumber === nextProps.pageNumber) {
      this.props.setSketch(this._sketch)
    }
    if (nextProps.tool !== this.props.tool) {
      this.setState({
        tool: nextProps.tool
      })
    }
    if(nextProps.clearSketch !== this.props.clearSketch && nextProps.clearSketch && this._sketch) {
      const existingObjects = this._sketch._fc._objects
      const newObjects = []
      for(let i=0; i<existingObjects.length; i++) {
        newObjects.push(existingObjects[i])
      }
      this._sketch.clear()
      for(let i=0; i<newObjects.length; i++) {
        if(newObjects[i].annotation === this.props.drawByHandText
          || (typeof newObjects[i].type !== 'undefined' && newObjects[i].type === 3) ) {
          const relatedObj = newObjects[i]
          const rect = new fabric.Rect({
            left: relatedObj.left,
            top: relatedObj.top,
            width: relatedObj.width,
            height: relatedObj.height,
            fill: 'rgba(0, 0, 0, 0.4)' ,
            angle: 0,
            padding: 0,
            selectable: true,
            text: relatedObj.text ? relatedObj.text : '',
            annotation: relatedObj.annotation
          })
          this._sketch._fc.add(rect)
          this._sketch._fc.renderAll()
        }
      }
    }
    this.setState({
      pageNumber: nextProps.pageNumber,
      canvasData: nextProps.canvasData,
      totalPages: nextProps.totalPages,
      divPrint: nextProps.divPrint,
      width: nextProps.width,
      height: nextProps.height
    }, () => this.loadPoints)
  }

  componentDidMount() {
    const canvas = (this._fc = new fabric.Canvas(
      this._canvas /*, {
         }*/
    ))
    if (this.props.forPageNumber === this.props.pageNumber) {
      this.props.setSketch(this._sketch)
    }
    const that = this
    if (this.props.pageNumber === this.props.totalPages) {
      if (this.props.totalPages <= 10) {
        setTimeout(function() {
          that.props.loadingFinished()
        }, Math.round((this.props.totalPages * 0.25) * 1000))
      } else if (this.props.totalPages > 10 && this.props.totalPages <= 30) {
        setTimeout(function() {
          that.props.loadingFinished()
        }, Math.round((this.props.totalPages * 0.35) * 1000))
      } else if (this.props.totalPages > 30 && this.props.totalPages <= 60) {
        setTimeout(function() {
          that.props.loadingFinished()
        }, Math.round((this.props.totalPages * 0.45) * 1000))
      } else if (this.props.totalPages > 60 && this.props.totalPages <= 100) {
        setTimeout(function() {
          that.props.loadingFinished()
        }, Math.round((this.props.totalPages * 0.60) * 1000))
      } else if (this.props.totalPages > 100) {
        setTimeout(function() {
          that.props.loadingFinished()
        }, Math.round((this.props.totalPages * 0.75) * 1000))
      }
    }

    this.setState({
      canvas,
      canvasData: this.props.canvasData,
      pageNumber: this.props.pageNumber,
      totalPages: this.props.totalPages,
      loading: this.props.loading,
      width: this.props.width,
      height: this.props.height
    }, () => {
      this.loadPoints()
    })
  }

  loadPoints = () => {
    const { alreadyRedactedElements } = this.state
    const { isOcr } = this.props
    if (isOcr) {
      if (this.state.canvasData) {
        if (this._sketch) {
          this._sketch.clearTemporary()
        }
        if (this.state.canvasData) {
          const elements = this.state.canvasData
          const elemstsJson = []
          elements.forEach((item) => {
            elemstsJson.push(JSON.parse(item))
          })
          // fill rectangle and annotation
          if (elemstsJson && elemstsJson.length > 0) {
            elemstsJson.forEach((point) => {
              if (this._sketch && point && point.text !== null) {
                if (!alreadyRedactedElements.includes(point.text + point.x1 + point.y1)) {
                  //point.color = 'rgba(74, 74, 74, 100)'
                  if (point.color && point.color !== 'rgba(74, 74, 74, 100)') {
                    this._sketch.fillPointsOcr(point, point.color)
                  } else {
                    this._sketch.fillPointsOcr(point, 'rgba(0,0,0,0.3)')
                  }
                  alreadyRedactedElements.push(point.text + point.x1 + point.y1)
                }
              }
            })
          }
        }
      }
    } else {
      if (this.state.canvasData) {
        if (this._sketch) {
          this._sketch.clearTemporary()
        }
        if (this.state.canvasData) {
          const elements = this.state.canvasData
          const elemstsJson = []
          elements.forEach((item) => {
            if (JSON.parse(item)) {
              elemstsJson.push(JSON.parse(item))
            }
          })
          // fill rectangle and annotation
          elemstsJson.forEach((point) => {
            if (this._sketch && point) {
              if (!alreadyRedactedElements.includes(point.text + point.x1 + point.y1)) {
                if (point.color) {
                  this._sketch.fillPoints(point, point.color)
                } else {
                  this._sketch.fillPoints(point, 'rgba(0,0,0,0.3)')
                }
                if (point.text) {
                  alreadyRedactedElements.push(point.text + point.x1 + point.y1)
                }
              }
            }
          })
        }
      }
    }
    this.setState({ alreadyRedactedElements }, () => {
      if (this.props.setAlreadyRedactedList) {
        this.props.setAlreadyRedactedList()
      }
    })
  }

  fill = (color, documentId, page, alpha, canvasData) => {
    if (this.props.isOcr) {
      this._sketch.fillRectanglesOcr(color,documentId, page, alpha, canvasData)
    } else {
      this._sketch.fillRectangles(color,documentId, page, alpha, canvasData)
    }
  }

  render() {
    const { pageNumber } = this.state
    const { scale } = this.props
    return (
      <div id={this.state.divPrint} data-pageid={pageNumber} className={pageNumber === 1 ? 'show-pdf-page' : ''}>
        <React.Fragment>
          <Page pageNumber={pageNumber}
                key={`page_${pageNumber}`}
            scale={scale}
            renderAnnotationLayer={true}
            renderTextLayer={true}
            fontName={'ubuntu'}
            onRenderSuccess={ () => this.props.handleRenderPage(pageNumber) }
            options={{
              disableFontFace: true,
              fontTypes: true,
              styles: { fontFamily: 'roboto' }
            }}
          />
          <SketchField
            ref={(c) => (this._sketch = c)}
            className={`canvas-area canvas-page-${ pageNumber }`}
            width={this.state.width}
            height={this.state.height}
            tool={this.state.tool}
            lineColor='black'
            fillColor={this.state.fillColor}
            background='transparent'
            lineWidth={2}
            pageNumber={pageNumber}
            selectTool={this.props.selectTool}
            selectRectangle={this.props.selectRectangle}
            showAnnotationsList={this.props.showAnnotationsList}
            hideAnnotationsList={ this.props.hideAnnotationsList }
            isModalShow={this.props.isModalShow}
            selectObject={this.props.selectObject}
            renderSelectDraw={ this.props.renderSelectDraw}
            renderSelectText={ this.props.renderSelectText}
            drawByHandText={ this.props.drawByHandText }
            onObjectAdded={(obj) => { this.props.addObjectToCanvas(obj)}}
            onEndPoints = { this.props.onEndPoints }
            isOcr = { this.props.isOcr }
            renderCommentsText = { this.props.renderCommentsText }
          />
        </React.Fragment>
      </div>
    )
  }
}

export default CanvasPage