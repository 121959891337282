import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import imgBoxEmpty from '../../../../../assets/box/box_empty_20x20.png'
import imgBoxEmptyNeg from '../../../../../assets/box/box_empty_negative_20x20.png'
import imgBoxProcessing from '../../../../../assets/box/box_processing_20x20.png'
import imgBox from '../../../../../assets/box/box_20x20.png'
import imgBoxNegative from '../../../../../assets/box/box_negative_20x20.png'
import {Select, Tooltip} from "antd";
import moment from "moment";
import {apiSetTicketWorkflowStatus, apiMarkTicketWorkflowAsCompleted} from "../../../../../api/SmartBox";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import cookie from "react-cookies";
import withRouter from "../../../../../private-routers/withRouter";
import {HeaderNavigationBox} from "../../../../Common/HeaderNavigationBox";
import { customConfirm } from '../../../../../utils/AssetRegister/CustomConfirm'
import {setVisibleSubheaderSteps} from "../../../../../actions/smartBoxActions";
const { Option } = Select;
import ModalNewEditTicket from "./ModalNewEditTicket";
export class HeaderTicket extends Component {

    state = {
      hoverRequestDetail: false,
        hoverIconDate: false,
        listOptionStatus:['Open', 'Archive', 'Complete'],
        listOptionStatusNo:['Open', 'Complete'],
        selectOptionStatus: 'Open'
    }
     componentDidMount() {
        if (this.props.selectHeaderTickets && this.props.selectHeaderTickets.ticketStatus) {
            this.setState({
                selectOptionStatus: this.props.selectHeaderTickets.ticketStatus
            })
        }
     }

     componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectHeaderTickets && prevProps.selectHeaderTickets.ticketStatus !== this.props.selectHeaderTickets.ticketStatus) {
            this.setState({
                selectOptionStatus: this.props.selectHeaderTickets.ticketStatus
            })
        }
     }

    onMouseEnter = (e, status) => {

        switch (status) {
            case 'request':
                this.setState({
                    hoverRequestDetail: true
                })
                break
            case 'date':
                this.setState({
                    hoverIconDate: true
                })
                break
            default: break
        }
    }

    onMouseLeave = () => {
      this.setState({
          hoverRequestDetail: false,
          hoverIconDate: false,
      })
    }
    onChangeSelect = (e) => {
        const { dataSourceTicket } = this.state
        if (e === 'Complete') {
            this.showCompleteTicketModal(e)
        } else {
            this.setState({
                selectOptionStatus: e,
            }, () => {
                const setTicketWorkflowStatusDTO = {
                    ticketId: this.props.selectHeaderTickets.ticketId,
                    ticketStatus: e,
                }
                apiSetTicketWorkflowStatus(setTicketWorkflowStatusDTO).then((response) => {
                    if (response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                        if (e === 'Archive') {
                            const path = `/sar/workflow/workflow-archive`
                            this.props.navigate(path)
                        }
                    } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    if (response.data.title && response.data.title.toLowerCase() === 'success' && e === 'Archive') {
                        this.props.onCloseNewTickets(false)
                    }
                    // this.setState({
                    //     selectOptionStatus: 'Open'
                    // })
                    this.props.onGetTicketWorkflowListDashboard()
                })
            })
        }
    }
    showCompleteTicketModal= (e) => {
        customConfirm({
            title: <span className="icon-v22-radio-button-tick"></span>,
            //content: `Complete workflow?`,
            content: `End ticket?`,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-send-email-step-workflow modal-internal-email-notification',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.callApiMarkTicketWorkflowAsCompleted(e)
            }
        })
    }


    callApiMarkTicketWorkflowAsCompleted = (e) => {
        const { selectHeaderTickets} = this.props
        const MarkTicketAsCompletedDTO = {
            ticketId: selectHeaderTickets.ticketId,
        }
        this.setState({
            selectOptionStatus: e,
        })
        apiMarkTicketWorkflowAsCompleted(MarkTicketAsCompletedDTO).then(response => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
            // getTicketWorkflowAndHeaderDetails(selectHeaderTickets.ticketId).then(response => {
            // })
        })

    }
    onModalHeader = () => {
        //this.props.onEditModalTickets(this.props.selectHeaderTickets)
        //this.props.onOpenEditTickets(this.props.selectHeaderTickets, this.props.activeOpenEditTickets)
        this.props.openNewEditModalTicket('edit', this.props.selectHeaderTickets)
    }
    onCloseHeaderTickets = () => {
        cookie.remove('checkCollectorPageUploadHistoryData', { path: '/' })
        cookie.remove('openTicketArchive', { path: '/' })
        cookie.remove('emailWorkflowTicketTrackingSteps', { path: '/' })
        cookie.remove('emailDisclosureEmailTrackingSteps', { path: '/' })
      this.props.onCloseNewTickets(false)
      this.props.setVisibleSubheaderSteps(false)
    }
    routeGoToBox = () => {
       const { selectTicketTableDashboard, selectHeaderTickets, dataSourceTicket } = this.props
        let basePathBox = ''
        let ticketBoxId = ''
        dataSourceTicket && dataSourceTicket.length > 0 && dataSourceTicket.forEach((elem) => {
            if (selectTicketTableDashboard && selectTicketTableDashboard.ticketId && selectTicketTableDashboard.ticketId === elem.ticketId) {
                ticketBoxId = elem.ticketBoxId
            }
        })
        if (selectTicketTableDashboard && selectTicketTableDashboard.ticketBoxId) {
            basePathBox = selectTicketTableDashboard.ticketBoxId
        } else if (ticketBoxId) {
            basePathBox = ticketBoxId
        }
        if (basePathBox) {
            const path = `/smart-box/folder/${basePathBox}`
            this.props.navigate(path)
        }
    }

    routeGoToBoxDisabled = () => {
        return true
    }
    render() {
      const { hoverRequestDetail, hoverIconDate, listOptionStatus, selectOptionStatus } = this.state
        const { selectHeaderTickets, selectEditTickets, activeOpenEditTickets, selectTicketTableDashboard,
            visibleModalNewEditTicket } = this.props

        let nameHeaderTicket = ''
        let fullNameHeaderTicket = ''
        if (selectEditTickets && selectEditTickets.ticketId && selectEditTickets.ticketName) {
            nameHeaderTicket = `${selectEditTickets.ticketIdClient} ${selectEditTickets.ticketName}`
        }
        if (selectEditTickets && selectEditTickets.ticketId && selectEditTickets.ticketRequestorName) {
            fullNameHeaderTicket = `${selectEditTickets.ticketIdClient} ${selectEditTickets.ticketRequestorName}`
        }
        let diffDays = 0
        if (selectEditTickets) {
            const timeCurrent = moment().format('YYYY.MM.DD 00:00');
            const ticketTimeFrame = selectEditTickets.ticketTimeFrame
            const date1 =  moment(selectEditTickets.creationDate,'YYYY.MM.DD 00:00' ).toDate();
            const date2 = new Date(timeCurrent);
            const diffTime = Math.abs(date2 - date1);
            diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
        let openingDate = 0
        if (selectHeaderTickets.ticketStatus === 'Complete' && selectHeaderTickets.creationDate  && selectHeaderTickets.ticketCompletionDate) {
           const startDate =  moment(selectHeaderTickets.creationDate,'YYYY.MM.DD 00:00' ).toDate()

           const endDate =  moment(selectHeaderTickets.ticketCompletionDate,'YYYY.MM.DD' ).toDate()
            const differenceTime = endDate.getTime() - startDate.getTime()
            const differenceDays = differenceTime / (1000 * 3600 * 24)
            openingDate = parseInt(differenceDays)
        } else if ( selectHeaderTickets?.ticketTimeFrame === 99999 && selectHeaderTickets?.creationDate ) {
            // const [year, month, day] = selectEditTickets.creationDate.split('.')
            // openingDate = `${day}.${month}.${year}`
            openingDate = moment(selectEditTickets.creationDate).format('YYYY.MM.DD')

        }
        return (
            <div className={this.props.visibleSubheaderSteps ? "header-ticket-page header-ticket-page-visible" : "header-ticket-page" }>
                <div className="content-header-ticket-page">
                <div className="content-header-ticket-id">
                   <span className="ticket-id">{nameHeaderTicket}</span>
                    <span className={activeOpenEditTickets ? 'icon-v22-request-detail-active' : hoverRequestDetail ? 'icon-v22-request-detail-active' : "icon-v22-request-detail" }
                    onMouseEnter={(e) => this.onMouseEnter(e, 'request')}
                          onClick={(e) => this.onModalHeader(e)}
                    onMouseLeave={() => this.onMouseLeave()}></span>

                    <Tooltip
                        title="Go to Box">
                        <span className="ticket-box-header">
                            <img onClick={selectTicketTableDashboard && selectTicketTableDashboard.boxStatus === 'DISABLED' ?
                                (e) => this.routeGoToBoxDisabled(e) : (e) => this.routeGoToBox(e)} src={imgBoxNegative}/>
                            {/*<img  src={selectTicketTableDashboard && selectTicketTableDashboard.boxStatus && selectTicketTableDashboard.boxStatus === 'DISABLED' ? imgBoxEmptyNeg :*/}
                            {/*    selectTicketTableDashboard && selectTicketTableDashboard.boxStatus && selectTicketTableDashboard.boxStatus === 'EMPTY' ? imgBoxEmpty :*/}
                            {/*        selectTicketTableDashboard && selectTicketTableDashboard.boxStatus &&  selectTicketTableDashboard.boxStatus === 'GRAY' ? imgBoxProcessing :*/}
                            {/*            selectTicketTableDashboard &&  selectTicketTableDashboard.boxStatus &&  selectTicketTableDashboard.boxStatus === 'TURQUOISE' ? imgBox : imgBoxEmpty}/>*/}
                       </span>
                    </Tooltip>

                    <span></span>
                </div>

                    <div className="header-navigation-content">

                        <span className="ticket-owner">
                            {`Processor: ${selectHeaderTickets.ownerName ? selectHeaderTickets.ownerName : ''}`}
                        </span>
                        <span className='date-request'>
                            <span
                                //className={ hoverIconDate ? 'icon-v211-date-active':'icon-v211-date'}
                                className="icon-v211-date"
                                  onMouseEnter={(e)=>this.onMouseEnter(e, 'date')}
                                  onMouseLeave={() => this.onMouseLeave()}
                            ></span>
                            { selectHeaderTickets.ticketTimeFrame && selectHeaderTickets.ticketTimeFrame !== 99999 ?
                                <span className='content-body-data'>{`Day ${ diffDays + 1}`}{` of ${selectHeaderTickets.ticketTimeFrame ? selectHeaderTickets.ticketTimeFrame : 0 }`}</span> :
                                <span className='content-body-data'>{selectHeaderTickets.ticketStatus === 'Complete' && selectHeaderTickets.creationDate  && selectHeaderTickets.ticketCompletionDate ?
                                    `${openingDate} days` : openingDate}</span>
                            }
                        </span>
                        { selectHeaderTickets.ticketStatus === 'Complete' ?
                            <span className="ticket-owner">
                           Status:
                             <Select
                                 defaultValue={selectOptionStatus}
                                 onChange={(e) => this.onChangeSelect(e)}
                                 value={selectOptionStatus}
                                 className="select-option-status-ticket-owner"
                                 disabled={selectOptionStatus === "Archive"}
                                 dropdownClassName={'select-per-page-tickets select-status header-select-status'}>
                                  {this.state.listOptionStatus && this.state.listOptionStatus.length > 0 && this.state.listOptionStatus.map((item, index) => {
                                      return (<Option className="options-select-connector" value={item} key={index}>
                                          {item === 'Archive' ? 'Archive' : item === 'Complete' ? 'Complete' : item}
                                      </Option>)
                                  })}
                                </Select>
                        </span> :
                            <span className="ticket-owner">
                           Status:
                             <Select
                                 defaultValue={selectOptionStatus}
                                 onChange={(e) => this.onChangeSelect(e)}
                                 disabled={selectOptionStatus === "Archive"}
                                 value={selectOptionStatus}
                                 dropdownClassName={'select-per-page-tickets select-status header-select-status'}>
                                  {this.state.listOptionStatusNo && this.state.listOptionStatusNo.length > 0 && this.state.listOptionStatusNo.map((item, index) => {
                                      return (<Option className="options-select-connector" value={item} key={index}>
                                          {item === 'Archive' ? 'Archive' : item === 'Complete' ? 'Complete' : item}
                                      </Option>)
                                  })}
                                </Select>
                        </span>
                        }

                        <span onClick={(e) => this.onCloseHeaderTickets(e)} className="icon-v22-close-black"></span>
                    </div>
                </div>
                { visibleModalNewEditTicket &&
                <ModalNewEditTicket status="add"
                                    statusUpdate="inside"
                                    onCloseNewTickets={this.props.onCloseNewTickets}
                                    onCloseNewTicketsModal={this.props.onCloseNewTicketsModal}
                                    onGetTicketWorkflowListDashboard={this.props.onGetTicketWorkflowListDashboard}
                                    statusModalNewTickets={this.props.statusModalNewTickets}
                                    selectEditTickets={this.props.selectEditTickets}
                                    checkedRequestorDetails={this.props.checkedRequestorDetails}
                                    onOpenStepWorkflow={this.props.onOpenStepWorkflow}
                                    statusModalNewTicketsUpdate={this.props.statusModalNewTicketsUpdate}
                                    selectEditTicketsUpdate={ this.props.selectEditTicketsUpdate }
                                    onEditFirstStepModalTickets={ this.props.onEditFirstStepModalTickets }
                />
                }

            </div>
        )
    }
}

HeaderTicket = withRouter(HeaderTicket)
HeaderTicket.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    visibleSubheaderSteps: state.smartBox.visibleSubheaderSteps,
})
export default compose(
    connect(mapStateToProps, {setVisibleSubheaderSteps}),
)(HeaderTicket)