import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../FormEmployeeWorkflow.scss'
import {Checkbox, Col, Icon, Input, Modal, Radio, Select, Spin, Steps, Upload} from "antd";
import {generateBlobFromBase64} from "../../../../../../utils/GenerateBlob";
import PDFViewer from "../../../../../../utils/PDFViewer";
import PDFJSBackend from "../../../../../../utils/backends/PDFJs";
import CustomNotificationManager from "../../../../../Common/CustomNotificationManager";
import Loading from "../../../../../Common/Loading";
import swal from "sweetalert2";
import FormData from "form-data";
import axios from "axios";
import {ConcurrencyManager} from "axios-concurrency";
import _ from "lodash";
import {apiDeleteFormSectionsFileData} from "../../../../../../api/SmartBox";
const { Option } = Select;
const { Step } = Steps;
const { Dragger } = Upload
const isLocalhost = () => {
    return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

export class FileUploadTemplateForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            fileListUpdate: [],
            loadingUpload: false
        }
        this.windowDocument = null
    }

    componentDidMount() {
        const { component } = this.props
        if (component.fileSectionDTOS && component.fileSectionDTOS.length > 0) {
            const thumbnails = []
            component.fileSectionDTOS.forEach((item, index) => {
                item.size = item.fileSize
                item.thumbUrl = item.fileThumbnail
                item.newThumnbailUpdate = item.fileThumbnail
                item.uid = item.fileUID
                item.name = item.fileName
                item.type = item.fileType
                item.id = item.id
                item.doc = item.file
                thumbnails.push(item)
            })
            this.setState({
                fileList: thumbnails
            })
        } else {
            this.setState({
                fileList: []
            })
        }
        this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
        this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
            this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
            this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
        }
        if (prevProps !== this.props) {
            const { component } = this.props
            if (component.fileSectionDTOS && component.fileSectionDTOS.length > 0) {
                const thumbnails = []
                component.fileSectionDTOS.forEach((item, index) => {
                    item.size = item.fileSize
                    item.thumbUrl = item.fileThumbnail
                    item.newThumnbailUpdate = item.newThumnbailUpdate
                    item.uid = item.fileUID
                    item.name = item.fileName
                    item.type = item.fileType
                    item.id = item.id
                    item.doc = item.file
                    thumbnails.push(item)
                })
                this.setState({
                    fileList: thumbnails
                })
            } else {
                this.setState({
                    fileList: []
                })
            }
        }
    }
    onChangeUpload = (file) => {
        const { component } = this.props
        const fileUpload = []
        fileUpload.push(file.file)
        let fileUpload2 = {}
        fileUpload2 = file.file
        const listUid = []
        file.fileList && file.fileList.length > 0 && file.fileList.forEach((list) => {
            listUid.push(list.uid)
            if (fileUpload2.uid === list.uid) {
                fileUpload2.fileThumbnail = list.thumbUrl
            }
        })
        this.setState({
            //fileList: [...this.state.fileList, file.file ]
            fileList: file.fileList
        }, () => {
            this.props.onChangeNameAndDetails(file, 'file_upload', component)
        })
    }
    handleFileChangeTemplate(file) {
        const { component, formId, activeStepProps, selectHeaderTickets } = this.props
        const filesInfo = {}
        // filesInfo.push({
        //     file: file,
        //     fileThumbnail: file.fileThumbnail,
        //     fileName: file.name,
        //     fileType: file.type,
        //     fileSize: file.size,
        //     fileUID: file.uid,
        // })
        const saveFormFileDataTicketWorkflowDTO = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : null,
            workflowComponentFormSelectedId: formId,
            workflowComponentId: activeStepProps.stepComponentWorkflowId,
            formSectionId: component.sectionId,
            filesInfo: filesInfo,
            file: file,
        }
        this.uploadTemplateFormFiles(saveFormFileDataTicketWorkflowDTO).then((response) => {
            const listResponse = []
            listResponse.push(response.data)
            if (response.data) {
                this.setState({
                    fileListUpdate: listResponse,
                    loadingUpload: false
                })
            }
            this.props.onStartSaveFormSectionsData(false)
            if (component.sectionTicketFormDataId) {
                this.props.onGetTicketFormComponentFromWorkflow()
            } else {
                this.props.onGetTicketFormComponentFromWorkflow()
            }
        })
    }
    uploadTemplateFormFiles = (saveFormFileDataTicketWorkflowDTO) => {
            const obj = new FormData()
        let objData = {}
        objData = {
            ticketId: saveFormFileDataTicketWorkflowDTO.ticketId,
            workflowComponentFormSelectedId: saveFormFileDataTicketWorkflowDTO.workflowComponentFormSelectedId,
            workflowComponentId: saveFormFileDataTicketWorkflowDTO.workflowComponentId,
            formSectionId: saveFormFileDataTicketWorkflowDTO.formSectionId,
            file: saveFormFileDataTicketWorkflowDTO.file,
            fileName: saveFormFileDataTicketWorkflowDTO.file.name,
            fileType: saveFormFileDataTicketWorkflowDTO.file.type,
            fileSize: saveFormFileDataTicketWorkflowDTO.file.size,
            fileUID: saveFormFileDataTicketWorkflowDTO.file.uid,
        }
            // obj.append('ticketId', saveFormFileDataTicketWorkflowDTO.ticketId)
            // obj.append('workflowComponentFormSelectedId', saveFormFileDataTicketWorkflowDTO.workflowComponentFormSelectedId)
            // obj.append('workflowComponentId', saveFormFileDataTicketWorkflowDTO.workflowComponentId)
            // obj.append('formSectionId', saveFormFileDataTicketWorkflowDTO.formSectionId)
            // obj.append('file', saveFormFileDataTicketWorkflowDTO.file)
            // //obj.append('fileThumbnail', saveFormFileDataTicketWorkflowDTO.file.thumbUrl)
            // obj.append('fileName', saveFormFileDataTicketWorkflowDTO.file.name)
            // obj.append('fileType', saveFormFileDataTicketWorkflowDTO.file.type)
            // obj.append('fileSize', saveFormFileDataTicketWorkflowDTO.file.size)
            // obj.append('fileUID', saveFormFileDataTicketWorkflowDTO.file.uid)
        const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
        const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
            return this.axiosClone({
                    method: 'post',
                    url: `${host}/osprey-0.1.0/v1/workflow-builder/save-form-sections-file-data-ticket-workflow`,
                data: objData,
                body: body,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${boundary}`
                },
                    //onUploadProgress: config
                }
            ).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                    console.log(error)
                }
            })
    }
    handlePreview = (file) => {
            this.setState({
                previewVisible: true,
                loading: true
            }, () => {
                if (file) {
                    switch (file.type) {
                        case 'image/png':
                        case 'image/jpg':
                        case 'image/jpeg':
                            //const imageUrl = `data:${file.type};base64, ${file.thumbnailBytes}`
                            const imageUrl = file.thumbUrl
                            this.setState({
                                renderingBlock: <img alt="example" style={{width: '100%'}} src={imageUrl}/>,
                                loading: false
                            })
                            break
                        case 'application/pdf':
                            if (file.doc) {
                                this.windowDocument = generateBlobFromBase64(file.doc)
                                this.setState({
                                    renderingBlock: <PDFViewer backend={PDFJSBackend} src={this.windowDocument} toolbar={0}/>,
                                    loading: false
                                })
                            } else if (file.newThumnbailUpdate) {
                                const codeThumbnail = file.newThumnbailUpdate.substr(8, file.newThumnbailUpdate.length)
                                this.windowDocument = generateBlobFromBase64(codeThumbnail)
                                this.setState({
                                    renderingBlock: <PDFViewer backend={PDFJSBackend} src={this.windowDocument} toolbar={0}/>,
                                    loading: false
                                })
                            }
                            break
                        default:
                            break
                    }
                }
            })

    };
    customUploadDraft = (file, file2) => {
        const { component, activeStepProps } = this.props
        const maxSizeFile = file.size / 1024 / 1024 < 5
        const listUid = []
        const listUid2 = []
        const listUid3 = {}
        file2 && file2.length > 0 && file2.forEach((list) => {
            listUid.push(list.uid)
            listUid2.push({
                uid: list.uid,
                name: list.name,
                lastModified: list.lastModified,
                type: list.type,
                size: list.size,
            })
        })
        // this.setState({
        //     fileSelect: file,
        //     fileList: file,
        //     filesSelectUid: listUid
        // })
        this.setState({
            loadingUpload: true
        }, () => {
            if (component.sectionTicketFormDataId) {
                this.props.onChangeNameAndDetails(file, 'file_upload', component)
                this.props.onStartSaveFormSectionsData(false)
                if (maxSizeFile) {
                    this.handleFileChangeTemplate(file)
                    return false
                } else {
                    CustomNotificationManager.error('File must smaller than 5MB!', 'Error')
                }
            } else {
                this.props.onChangeNameAndDetails(file, 'file_upload', component)
                this.props.onStartSaveFormSectionsData(true)
                this.setState({
                    filesSelected: file
                }, () => {
                    if (maxSizeFile) {
                        this.handleFileChangeTemplate(file)
                        return false
                    } else {
                        CustomNotificationManager.error('File must smaller than 5MB!', 'Error')
                    }
                })
            }
        })
        return false
    };
    handleCancel = () => {
        this.setState({ previewVisible: false })
    }
    onRemoveFileClicked = (file) => {
        swal.fire({
            text: 'Do you want to delete this file?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#D33D33',
            confirmButtonText: 'Yes, remove file'
        }).then((result) => {
            if (result.value) {
                //this.onRemoveFile(file)
                this.onRemoveFileUpdate(file)
            } else {
                CustomNotificationManager.info('Action cancelled', 'Aborted')
            }
        })
    }

    onRemoveFileUpdate = (file) => {
        const { fileList } = this.state
        const { component, selectHeaderTickets } = this.props
        const ticketId =  selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : null
        let fileListRemoved = []
        if (fileList && fileList.length > 0) {
            fileListRemoved = fileList.filter((list) => list.uid !== file.uid)
        }
        apiDeleteFormSectionsFileData(file.id, ticketId).then((response) => {
            this.props.onChangeNameAndDetails(file, 'file_upload', component)
            this.props.onGetTicketFormComponentFromWorkflow()
        })
        // this.setState({
        //     fileList: fileListRemoved
        // })
    }
    render() {
        const {fileList, fileSelect, previewVisible, renderingBlock, loading, fileListUpdate, loadingUpload } = this.state
        const { formerEmployee, component, stepTicketActive } = this.props
        const props = {
            name: 'file',
            multiple: true,
            //action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            onChange(info) {
                const { status } = info.file;
                if (status !== 'uploading') {
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        }
        //const uniqueVerificationVisited = fileList && fileList.length > 0 && fileList.filter((a, i) => fileList.findIndex((s) => a.uid === s.uid) === i)
        //console.log('fileList 33333', fileList)
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'File upload'}</span>
                <span className="input-content input-content-upload">
                    <Upload
                              fileList={ fileList }
                              accept={ '.pdf, .png, .jpeg, .jpg' }
                              listType="picture-card"
                              multiple={ true }
                              disabled={stepTicketActive.blockFormEdit === true}
                              //onChange={this.onChangeUpload}
                              beforeUpload={ this.customUploadDraft }
                              onPreview={ this.handlePreview }
                              onRemove={ this.onRemoveFileClicked }
                              //disabled={true}
                    >  { loadingUpload ?
                        <span>
                            <Icon type={'loading'} />
                             <div className="ant-upload-text">Upload</div>
                        </span>
                        :
                        <span>
                            <p className="ant-upload-drag-icon">
                                <Icon type="cloud-upload"/>
                            </p>
                            <p className="ant-upload-text">Browse Files</p>
                            <p className="ant-upload-hint">
                              Drag and drop files here
                            </p>
                        </span>
                    }
                        </Upload>
                     <Modal visible={ previewVisible } footer={ null } onCancel={ this.handleCancel }
                            className="pdf-modal">
                      <div className="custom-modal-body">
                        { loading ? <Spin spinning={ loading } indicator={ <div className="checkout-loading"><Loading/>
                        </div> }/> : renderingBlock }
                      </div>
                    </Modal>

                </span>
            </div>
        )
    }
}
FileUploadTemplateForm.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,
})
export default compose(
    connect(mapStateToProps),
)(FileUploadTemplateForm)