import React from 'react'
import {Button, Input, Modal, Select, Tabs, Tooltip} from 'antd'
import { Link, Navigate } from 'react-router-dom'
import './SmartBoxFolderSettings.scss'
import {
  apiRedactionColourProfileGet, apiSetBoxRedactionColorProfile,
  apiSmartBoxRunAnalytic, getFolderFilesNumber, getPiiCategories,
  getRenameFile,
  getSmartBoxFileById,
  getSmartBoxFilesFilter
} from '../../../../api/SmartBox'
import Analytics from './FolderSettings/Analytics'
import RegularExpressions from './FolderSettings/RegularExpressions'
import { generateFileForLink } from '../../../../utils/SmartBox/SmartBoxMethods'
//import ManualTerms from './FolderRedaction/ManualTerms'
import BusinessTermList from './FolderRedaction/BusinessTermList'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import imgFolderSmallOpenParentSmall from '../../../../assets/smart-folder-open-paret-small.svg'
import SmartBoxSettingsMenu from './SmartBoxSettingsMenu'
// import LeftFoldersNavigation from '../../../Common/LeftFoldersNavigation/LeftFoldersNavigation'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  setSmartBoxFilesTable,
  // setSmartBoxBaseDataTree,
  setSmartBoxToggleFilePreview,
  setSmartBoxSelectedDocuments,
  setSmartBoxDirectoryNote,
  setSmartBoxAnalysisPercent,
  setSmartBoxSecurityConfiguration,
} from '../../../../actions/smartBoxActions'
import DirectoryNote from '../DirectoryNote'
import HeaderNavigationBox from '../../../Common/HeaderNavigationBox'
import Access from './FolderSettings/Access'
import withRouter from '../../../../private-routers/withRouter'
const { Option } = Select
class SmartBoxFolderAnalysisSettings extends React.Component {
  state = {
    folder: {},
    paths: '',
    smartBoxMenuOpen: false,
    renameFolderModalVisible: false,
    newFolderName: '',
    newFolderNameValid: { success: true, reason: 'Invalid name' },
    stateButtonAnalytics: false,
    runAnalyticsUpdate: '',
    searchText: '',
    activeTab: '1',
    statusRunAiAnalytics: false,
    resultKeysSavedS: [],
    _mounted: false,
    numberOfFiles: 0,
    loadingRunAISearch: false,
    disabledRegularExpressions: true,
    valueSelectProfile: null,
    listRedactionColour: [],
  }
  
  componentDidMount() {
    if(this.props.smartBoxSecurityConfiguration === 'security-settings'){
      this.setState({activeTab: '2'})
      this.props.setSmartBoxSecurityConfiguration('')
    }
    document.getElementsByClassName('commonShell-main')[0].classList.add('box-settings-page')
    document.addEventListener('mousedown', this.handleClickOutside, false)
    this.fetchFolderInfo()
    this.getPiiCategoriesSaved()
    this.fetchFiles()
    this.onGetRedactionColour()

    // if (this.props.smartBoxAnalysisPercent >= 0 && this.props.smartBoxAnalysisPercent < 100) {
    //   CustomNotificationManager.info('Analysis process is not completed, therefore all options of Box settings are locked for changes!',)
    // }
    this.setState({_mounted:true})
  }
  onGetRedactionColour = () => {
    apiRedactionColourProfileGet().then((response) => {
      this.setState({
        listRedactionColour: response.data.redactionColorProfileItemDTOS
      })
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.smartBoxSelectedDocumentId && this.props.smartBoxSelectedDocumentId.action === 'analysis-settings') {      
      this.setState({activeTab: '1'})
    }
    if(prevState.activeTab !== this.state.activeTab && this.state.activeTab === '1'){
        this.fetchFolderInfo()
        this.fetchFiles()
        this.getPiiCategoriesSaved()
    }
    if (prevProps !== this.props && this.props.smartBoxSecurityConfiguration === 'security-settings'){
      this.setState({activeTab: '2'})
      this.props.setSmartBoxSecurityConfiguration('')
    }
    
    if (prevProps !== this.props) {
      document.getElementsByClassName('commonShell-main')[0].classList.add('box-settings-page')
      document.addEventListener('mousedown', this.handleClickOutside, false)
    }
  }

  getPiiCategoriesSaved = () => {
    //this.props.params.id
    getPiiCategories(this.props.params.id).then((response) => {
      this.setState({
        stateGetPiiCategoriesS: response.data.state,
        resultKeysSavedS: response.data.result
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
    document.getElementsByClassName('commonShell-main')[0].classList.remove('box-settings-page')
    this.setState({_mounted:false})
  }

  fetchFiles = () => {
    const { searchText } = this.state
    const initFilter = {
      docFilename: searchText,
      docFolderPath: null
    }
    // getSmartBoxFilesFilter(initFilter).then((response) => {
    //   this.props.setSmartBoxBaseDataTree(response.data)
    // }).catch((error) => {
    //   if (error.response.status === 504) {
    //     CustomNotificationManager.cleanBuffer('warning', '')
    //   }
    // })
  }
  fetchFolderInfo = () => {
    this.setState({
      folderId: this.props.params.id
    }, () => {
      getSmartBoxFileById(this.state.folderId).then((response) => {
        if (response.status === 200) {
          if (!response.data.path && !response.data.smartDocument) {
            CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
            const path = '/smart-box'
            this.props.navigate(path)
          }
          const folderData = response.data.smartDocument
          this.setState({
            folder: folderData,
            paths: response.data.path,
            //runAnalyticsUpdate: response.data.smartDocument.widht.runUpdateAnalytics,
            disabledRegularExpressions: response.data.regExSelectActive,
            valueSelectProfile: response.data.boxRedactionColorProfileId,
          }, () => {
            const ids = []
            ids.push(this.props.params.id)
            const folderCountFilesInput = {
              ids: ids
            }
            getFolderFilesNumber(folderCountFilesInput).then((response) => {
              this.setState({
                numberOfFiles: response.data[0].numberOfFiles
              })
            })
          })
          if (folderData.relativePath.substr(1).indexOf('/') !== -1) {
            this.props.setSmartBoxAnalysisPercent(100)
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 500 && error?.response?.data?.detail === 'You don\'t have access to this folder') {
          CustomNotificationManager.error('You don\'t have access to this folder', 'Error')
          const path = '/smart-box'
          this.props.navigate(path)
        }else if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })

    })
  }

  generateSmartBoxMenu = (activePage= '') => {
    const { folder, paths, smartBoxMenuOpen } = this.state
    const shortBreadcrumbLimit = 4
    let folderDepth = 0
    const path = folder.relativePath
    if (path) {
      folderDepth = path.split('/').length
      folderDepth = folderDepth - 1
      const fileId = (Object.keys(paths).length) ? paths[Object.keys(paths)[0]] : (folder.id ? folder.id : '')
      const pathArray = path.split('/')
      let breadCrumb = []
      let requestPath = ''
      let j = 0
      for (let i = 0; i < pathArray.length; i++) {
        if (pathArray[i]) {
          requestPath = `${ requestPath }/${ pathArray[i] }`
          const fileIdForLink = generateFileForLink(folder.id, paths, requestPath)

          if (i < shortBreadcrumbLimit + 1) {
            breadCrumb = [...breadCrumb, [<span key={j++}>&nbsp;&nbsp;&nbsp;</span>,
              <span key={j++} className="arrow">&rsaquo;</span>, <span key={j++}>&nbsp;&nbsp;&nbsp;</span>]]
          }

          if (path === requestPath) {
            breadCrumb.push(<img key={j++} src={imgFolderSmallOpenParentSmall} alt={path}/>)
          }
          if (i < shortBreadcrumbLimit || i === folderDepth) {
            breadCrumb.push(<span key={j++}>&nbsp;&nbsp;</span>)
            breadCrumb.push(<Link key={j++} to={{ pathname: `/smart-box/folder/${ fileIdForLink }` }}>{pathArray[i]}</Link>)
          } else if (i === shortBreadcrumbLimit && i < folderDepth) {
            breadCrumb.push(<span key={j++}>...&nbsp;&nbsp;&nbsp;&nbsp;</span>)
          }
          if (path === requestPath && !folder.depth) {
            breadCrumb = [...breadCrumb, [<span key={j++} className="arrow">&rsaquo;</span>,
              <span key={j++}>&nbsp;&nbsp;&nbsp;</span>,
              <span ref={ (node) => this.node = node } key={j++}>
                <SmartBoxSettingsMenu
                  folder={folder}
                  fileId={fileId}
                  toggleFilePreview={() => this.toggleFilePreview(true, folder)}
                  activePage={ activePage }
                  smartBoxMenuOpen={smartBoxMenuOpen}
                  fetchFiles = { this.fetchFolderInfo }
                  toggleLoadingOpenedFolder = { this.toggleLoadingOpenedFolder }
                  toggleSmartBoxMenu = { this.toggleSmartBoxMenu }
                  toggleRenameFolderModal = { this.toggleRenameFolderModal }
                >
                </SmartBoxSettingsMenu></span>]]
          }
        }
      }
      return breadCrumb
    }
    return ''
  }

  toggleLoadingOpenedFolder = (open) => this.setState({ loadingOpenedFolder: open })

  toggleRenameFolderModal = (open) => this.setState({ renameFolderModalVisible: open })

  handleRenameFolder = () => {
    const { folder, newFolderName, newFolderNameValid } = this.state
    const { renameFolderModalVisible } = this.state
    const nameRgex = /^[A-Za-z\d_\s]+$/
    if (newFolderName) {
      newFolderNameValid.success= true
      if (!nameRgex.test(newFolderName) || folder.name === newFolderName) {
        newFolderNameValid.success = false
        if (!nameRgex.test(newFolderName)) {
          newFolderNameValid.reason = 'Invalid name. Please do not use the following characters: /\\?*;:[]\'"|,.<>'
        }
        if (folder.name === newFolderName) {
          newFolderNameValid.reason = 'It\'s old name. Please insert a new one.'
        }
      }
      if (newFolderNameValid.success) {
        const newRenameFolder = {
          id: folder.id,
          name: newFolderName
        }
        getRenameFile(newRenameFolder).then((response) => {
          if (response.status === 200) {
            folder.name= response.data
            const pos = folder.relativePath.lastIndexOf('/')
            const newRelativePathTemp = `${ folder.relativePath.substr(0, pos < 0 ? folder.relativePath.length : pos) }/${ response.data }`
            folder.relativePath = newRelativePathTemp
            this.setState({
              renameFolderModalVisible: false,
              newFolderName: '',
              folder
            }, () => { this.generateSmartBoxMenu('') })
          }
        }).catch((error) => {
          const errorMessage = 'Folder id does not exist'
            if (error?.response?.status === 500) {
              if (typeof error.response?.data?.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
                CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
                this.props.navigate('/smart-box')
              }
            }else if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
        })
      } else {
        this.setState({ newFolderName: '', renameFolderModalVisible })
      }

    }

  }

  handleChangeFolderName = (e) => this.setState({ newFolderName: e.target.value })

  handleClickOutside = (e) => {
    const { smartBoxMenuOpen } = this.state
    if (this.node) {
      if (this.node.contains(e.target)) {
        if (!smartBoxMenuOpen) {
          this.toggleSmartBoxMenu(true)
        }
        if (smartBoxMenuOpen && typeof e.target.className === 'string' && (e.target.className.includes('ant-menu-submenu-title') || e.target.className.includes('ant-menu-submenu-arrow'))) {
          this.toggleSmartBoxMenu(false)
        }
      } else if (smartBoxMenuOpen) {
        this.toggleSmartBoxMenu(false)
      }
    }
  }

  toggleSmartBoxMenu = (open) => {
    const { smartBoxMenuOpen } = this.state
    if (smartBoxMenuOpen) {
      this.setState({ smartBoxMenuOpen: open })
    }
    this.setState({ smartBoxMenuOpen: open })
  }

  runUpdateAnalytics = (e) => {
    const { folder } = this.state
    e.stopPropagation()
    this.setState({
      loadingRunAISearch: true
    }, () => {
      apiSmartBoxRunAnalytic(folder.id).then((response) => {
        CustomNotificationManager.success('Analytics process started.')
        this.setState({
          statusRunAiAnalytics: true,
          loadingRunAISearch: false
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
    this.setState({
      stateButtonAnalytics: true,
      statusRunAiAnalytics: true
    })
  }

  renderRenameFolderModal = () => {
    const { renameFolderModalVisible, folder, newFolderNameValid } = this.state
    return (
      <Modal
        className="rename-folder"
        title="Rename folder"
        visible={ renameFolderModalVisible }
        onOk={ () => this.handleRenameFolder() }
        onCancel={ () => this.toggleRenameFolderModal(false) }
      >
        <p className="subtitle">Add new name for <b>{folder.name}</b></p>
        <Input type="text" placeholder="Rename folder" onChange={ (e) => this.handleChangeFolderName(e) } onPressEnter={ (e) => this.handleRenameFolder() }/>
        {!newFolderNameValid.success &&
        <p className="error">{newFolderNameValid.reason}</p>
        }
      </Modal>
    )
  }
  visibleDirectoryNote = (open) => {
    this.props.setSmartBoxDirectoryNote(open)
  }
  toggleNoteVisible = (open) => {
    this.setState({ documentNoteVisible: open })
  }
  onChange = (activeKey) => {
    this.setState({ activeTab: activeKey.toString() })
  }

  onGetStatusRunAnalytics = (status) => {
    this.setState({
      statusRunAiAnalytics: status
    })
  }
  onSelectResetProfiles = (e) => {
    this.setState({
      valueSelectProfile: e,
    }, () => {
      this.onSetBoxRedactionColorProfile()
    })
  }
  onSetBoxRedactionColorProfile = () => {
    const { valueSelectProfile } = this.state
    const setBoxRedactionColorProfileDTO = {
      redactionProfileId: valueSelectProfile,
      boxId: this.props.params.id
    }
    apiSetBoxRedactionColorProfile(setBoxRedactionColorProfileDTO).then((response) => {
      if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
      } else if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
      }
    })
  }
  render() {
    const { folder, activeTab, statusRunAiAnalytics, stateGetPiiCategoriesS, resultKeysSavedS, numberOfFiles, loadingRunAISearch,
      disabledRegularExpressions, valueSelectProfile, listRedactionColour } = this.state
    const { smartBoxBaseDataTree } = this.props
    const { TabPane } = Tabs
    let disabled = false
    //const disabled = this.props.smartBoxAnalysisPercent >= 0 && this.props.smartBoxAnalysisPercent < 100
    let basePath = ''
    let folderIdOne = ''
    let folderIdTwo = ''
    let folderPathNew = ''
    let countChildrenBox = 0
    if (this.props.smartBoxBaseDataTree && this.props.smartBoxBaseDataTree.length > 0) {
      basePath = this.props.smartBoxBaseDataTree[0].folderPath ? this.props.smartBoxBaseDataTree[0].folderPath : ''
      folderPathNew = this.props.smartBoxBaseDataTree[0].path ? this.props.smartBoxBaseDataTree[0].path : ''
      folderIdOne = this.props.smartBoxBaseDataTree[0].id ? this.props.smartBoxBaseDataTree[0].id : ''
    }
    this.props.smartBoxFolderCountTree && this.props.smartBoxFolderCountTree.length > 0 && this.props.smartBoxFolderCountTree.forEach((file) => {
      if (folder.path === file.path || folderPathNew === file.path) {
        countChildrenBox = file.numberOfFiles
      }
    })
    if (folder && folder.statisticsReady === 'false' && countChildrenBox !== 0 && this.props.smartBoxAnalysisPercent >= 0 && this.props.smartBoxAnalysisPercent < 100) {
      disabled = true
    } else {
      disabled = false
    }
    if (!folder) {
      const path = '/smart-box'
      return <Navigate to={ path } />
    }
    return (


      <React.Fragment>
        { this.props.smartBoxVisibleAddNote === true &&
        <DirectoryNote closeDocumentNote={(e) => this.visibleDirectoryNote(e, false)} documentId={ this.props.smartBoxSelectedDocumentId.documentId } user={ this.props.user }/>
        }
        <div className="smart-box-folder-settings_container smart-box-folder-settings_new-container-analysis">
          <div className="smart-box-folder-settings_header smart-box-folder-settings_header2 mt-5">
            {/*<div className="smart-box-folder-opened__breadcrumb">*/}
            {/*  <span className="smart-box-folder-opened__breadcrumb--bold">*/}
            {/*    <Link to="/smart-box/management">Smartbox.ai Home</Link>*/}
            {/*    { this.generateSmartBoxMenu(2) }*/}
            {/*  </span>*/}
            {/*</div>*/}

            <HeaderNavigationBox
              folderParent={ folder }
              filesTable={ this.props.smartBoxBaseDataTree }
              clientId={ this.props.clientId }
              basePath={ folder.name }
              boxIdBasePath={ folder.id }
              activeItem = { 'analysis-settings' }
            />
          </div>
          <div className="smart-box-folder-settings_content smart-box-folder-settings_content-analysis">
            <div className="file-settings-tab">
              {/*<div className="content-button-analytics">*/}
              {/*  <Button onClick={ this.runUpdateAnalytics }>{ runAnalyticsUpdate ? 'UPDATE ANALYTICS' : stateButtonAnalytics ? 'UPDATE ANALYTICS' : 'RUN ANALYTICS' }</Button>*/}
              {/*</div>*/}
              <div className="parent-tabs">
                <Tabs activeKey={ activeTab } onChange={ this.onChange } type="card" className="child-tabs ant-tabs-top-content tabs-analysis-settings">
                  <TabPane tab="AI configuration" key="1">
                    <div className="top-label">AI configuration
                      <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                        <span className="text-information-header">AI Search</span>
                        <div>
                          <span className="text-information">Select categories and terms to include in your search.</span>
                          <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174215', '_blank') }> More info. </span>
                        </div>
                      </div>}>
                        <span className="icon-v14-question"></span>
                      </Tooltip></div>
                    {/*<div className="top-text">Setup AI conditions for your search and data. How to change your AI colours*/}
                    {/* </div>*/}
                    <div className={ disabled ? 'no-action' : 'ai-configuration-content'} >
                    {/*  <div className="colour-profile-settings">
                        <label className="colour-profile-label left-side">Colour profile</label>
                        <div className="colour-profile-settings-select right-side">
                          <Select className="select-profiles-colours"
                                  placeholder="Select redaction color"
                                  value={ valueSelectProfile ? valueSelectProfile : 'Select redaction color' }
                                  onChange={(e) => this.onSelectResetProfiles(e)}
                          >
                            { listRedactionColour && listRedactionColour.length > 0 && listRedactionColour.map((elem, index) => {
                              return (
                                  <Option key={index} value={elem.redactionColorProfileId}>{elem.redactionColorProfileName}</Option>
                              )
                            })

                            }
                          </Select>
                        </div>
                      </div>*/}
                      <label className="left-side">PII</label>
                      <div className="right-side">
                        <Analytics folderId = { folder.id }
                          folder={ folder }
                          idFolder={ this.props.params.id }
                          smartBoxSelectedDocumentId={ this.props.smartBoxSelectedDocumentId }
                          disabled={ disabled }
                          clientId={ this.props.clientId }
                          userId={ this.props.userId }
                          onGetStatusRunAnalytics={ this.onGetStatusRunAnalytics }
                                   stateGetPiiCategoriesS={ stateGetPiiCategoriesS }
                                   resultKeysSavedS={ resultKeysSavedS }
                        />
                      </div>
                      <label className="left-side">Dictionaries</label>
                      <div className="right-side">
                        <BusinessTermList folder={ folder } disabled={ disabled } onGetStatusRunAnalytics={ this.onGetStatusRunAnalytics } />
                      </div>
                      <label className="left-side">Regular expressions</label>
                      <div className="right-side">

                        <RegularExpressions  folder={ folder } disabled={ disabled } onGetStatusRunAnalytics={ this.onGetStatusRunAnalytics }
                          smartBoxFilesTable={ this.props.smartBoxFilesTable }
                                            folderIdOne = { folderIdOne }
                                            folderIdTwo = { folderIdTwo }
                                             disabledRegularExpressions = { disabledRegularExpressions }
                        />
                      </div>
                      {/*<label className="left-side">Box terms</label>*/}
                      {/*<div className="right-side">*/}
                      {/*  <ManualTerms folder={ folder } disabled={ disabled } />*/}
                      {/*</div>*/}
                    </div>
                  </TabPane>
                  <TabPane tab="Security" key="2">
                    <div className="top-label">General</div>
                    <div className={ disabled ? 'no-action' : ''}>
                  {activeTab === '2' && this.state._mounted && this.props.smartBoxBaseDataTree &&
                    <Access folder={ folder } folderId={ folder.id } disabled={ disabled } idFolder = {this.props.params.id}/>}
                    </div>
                  </TabPane>
                  { activeTab === '2' ?
                    <TabPane key="3" disabled tab={<div className="content-new-run-analytics">
                    </div>}>
                    </TabPane> :
                    <TabPane key="3" disabled tab={<div className="content-new-run-analytics"><Button
                      disabled={this.state.folder === null || this.state.folder.statisticsReady === null ||this.state.folder.statisticsReady === 'false'  || statusRunAiAnalytics === true }
                      loading={ loadingRunAISearch }
                      onClick={this.runUpdateAnalytics}>
                      {/*{runAnalyticsUpdate ? 'Update analytics' : stateButtonAnalytics ? 'Update analytics' : 'Run AI search'}*/}
                      { 'Run AI search' }
                    </Button>
                    </div>}>
                    </TabPane>
                  }
                </Tabs>
              </div>
            </div>
          </div>
          { this.renderRenameFolderModal() }
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxFilesTable: state.smartBox.smartBoxFilesTable,
  smartBoxBaseDataTree: state.smartBox.smartBoxBaseDataTree,
  user: state.userReducer.user,
  userId: state.userReducer.user.id,
  smartBoxSelectedDocumentId: state.smartBox.smartBoxSelectedDocumentId,
  smartBoxVisibleAddNote: state.smartBox.smartBoxVisibleAddNote,
  smartBoxAnalysisPercent: state.smartBox.smartBoxAnalysisPercent,
  smartBoxSecurityConfiguration: state.smartBox.smartBoxSecurityConfiguration,
  smartBoxParentStatisticsReady: state.smartBox.smartBoxParentStatisticsReady,
  smartBoxParentFolderEmpty: state.smartBox.smartBoxParentFolderEmpty,
  smartBoxFolderCountTree: state.smartBox.smartBoxFolderCountTree,
})
SmartBoxFolderAnalysisSettings = withRouter(SmartBoxFolderAnalysisSettings)
export default compose(
  connect(mapStateToProps, {
    setSmartBoxFilesTable,
    // setSmartBoxBaseDataTree,
    setSmartBoxToggleFilePreview,
    setSmartBoxSelectedDocuments,
    setSmartBoxDirectoryNote,
    setSmartBoxAnalysisPercent,
    setSmartBoxSecurityConfiguration,
  }),
)(SmartBoxFolderAnalysisSettings)
