import React from 'react'
import Card from 'antd/es/card'
import './UploadProgressWindow.scss'
import FileUploadProgress from './FileUploadProgress'
import FileProgressDataMapping from './FileProgressDataMapping'
import { connect } from 'react-redux'
import {
  setFetchFolderContent,
  setSmartBoxNotUploadComplete,
  setSmartBoxUploadFailed,
  setSmartBoxShowUploadNavigation
} from '../../../actions/smartBoxActions'
import worker from './workers/app.worker.js'
import WebWorker from './workers/WebWorker'
import { ConcurrencyManager } from 'axios-concurrency'
import axios from 'axios'
import _ from 'lodash'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import moment from "moment";
import {apiCancelMultipleUpload, cancelFilesUpload} from "../../../api/SmartBox";
import {FormData} from "formdata-node"

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

class UploadProgressWindow extends React.Component {
  
  MAX_CONCURRENT_REQUESTS = 2;
  
  state = {
    filesUploading: [],
    showUploadProgress: false,
    progressStatus: [],
    totalBatchOfFiles: [],
    totalBatchOfFilesNames: [],
    path: null,
    fetchingCount: 0,
    responseErrorZip: false,
    estimatedStatusEvent: false,
    responseStatusMultipleUpload: null,
  }
  // upload using axios clone that is restricted two MAX_CONCURRENT_REQUESTS
   uploadDirectories = (data, config, data2) => {
     let filesUpdate = []
     let filesUpdate3 = {}
     const uniqueId = Math.floor(Math.random() * 10000000000000000)
     data2 && data2.files && data2.files.length > 0 && data2.files.forEach((item) => {
       if (item.fileObject) {
         filesUpdate.push(item.fileObject)
       } else {
         filesUpdate = data2.files
       }
     })
     data2.files2 = filesUpdate
     filesUpdate3 = {
       files: filesUpdate,
       uploadId: uniqueId,
       fileInfoList: data2.fileInfoList,
       path: data2.path,
     }

     const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
     const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
     // const formD = new FormData()
     // formD.set('dragos', data2.files)
     //return axios({
     return this.axiosClone({
       method: 'post',
       url: `${ host }/osprey-0.1.0/v1/smart-box/multiple-upload`,
           //data: formD,
           data: filesUpdate3,
           body: body,
           headers: {
             'Content-Type': `multipart/form-data; boundary=${boundary}`
             //'Content-Type': `multipart/form-data`
             //'Content-Type': `application/x-www-form-urlencoded`
           },
         //data: JSON.stringify(data),
         //   headers: {
         //     'Authorization': `Bearer ${ idToken }`,
         //     'Content-type': 'application/json'
         //   },
       onUploadProgress: config
     }
     ).catch((res) => {
       if (res) {
         this.setState({
           responseStatusMultipleUpload: res.response.status
         })
         if (res.response.status !== 200 || res.response.status === 500) {
           const uploadFailed = []
           const indexBox = this.props.smartBoxUploadingObject.path && this.props.smartBoxUploadingObject.path.indexOf('/')
           let nameBoxFailed = ''
           if (indexBox === -1) {
             nameBoxFailed = this.props.smartBoxUploadingObject.path
           } else {
              nameBoxFailed = this.props.smartBoxUploadingObject.path && this.props.smartBoxUploadingObject.path.substring(0, indexBox)
           }
           const timeCurrent = `${ moment().format('MM-DD-YYYY') } at ${ moment().format('HH:mm:ss') }`
           uploadFailed.push({
             descriptionActionEvent: 'Upload failed',
             nameActionEvent: nameBoxFailed,
             status: 'upload failed',
             timesEvent: timeCurrent
           })
           const uploadFailedFinally = uploadFailed.concat(this.props.smartBoxUploadFailed)
           const uniqueUploadFinally = uploadFailedFinally && uploadFailedFinally.length > 0 && uploadFailedFinally.filter((a, i) => uploadFailedFinally.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
           this.props.setSmartBoxUploadFailed(uniqueUploadFinally)
         }
         this.setState({
           responseErrorZip: true
         })
         if (res.response.status === 504) {
           CustomNotificationManager.cleanBuffer('warning', '')
         }
       }
     })
   }
  
   constructor(props) {
     super(props)
     this.onUnload = this.onUnload.bind(this)
   }
  
   componentDidMount() {
     this.worker = new WebWorker(worker)
     this.props.setSmartBoxShowUploadNavigation(false)
     this.worker.addEventListener('message', (e) => {
       const listArray = []
       const listObjects = []
       let batchOfFilesTotal = []
       if (e.data.totalBatchOfFiles && e.data.totalBatchOfFiles.length > 0) {
         e.data.totalBatchOfFiles.forEach((file) => {
           if (Array.isArray(file)) {
             file && file.length > 0 && file.forEach((file2) => {
               listArray.push(file2)
             })
           } else {
             listObjects.push(file)
           }
         })
       }
       if (listArray && listArray.length > 0 && listObjects.length === 0) {
         batchOfFilesTotal = listArray
       } else if (listObjects && listObjects.length > 0 && listArray.length === 0) {
         batchOfFilesTotal = listObjects
       } else if (listObjects && listObjects.length > 0 && listArray && listArray.length > 0) {
         batchOfFilesTotal = listObjects.concat(listArray)
       }
       let batchOfFilesTotal2 = []
       batchOfFilesTotal2 = new Array(batchOfFilesTotal)
       this.setState({
         //totalBatchOfFiles: e.data.totalBatchOfFiles,
         totalBatchOfFiles: batchOfFilesTotal2,
         totalBatchOfFilesNames: e.data.totalBatchOfFilesNames
       })
     })
     
     //add logic for second axios
     this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
     this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
   }
  
   componentDidUpdate(prevProps, prevState, snapshot) {
     if (prevProps.smartBoxUploadingObject !== this.props.smartBoxUploadingObject) {
       this.handleUpload(this.props.smartBoxUploadingObject)
       window.addEventListener('beforeunload', this.onUnload, false)
       this.props.setFetchFolderContent(false)
       this.props.setSmartBoxShowUploadNavigation(false)
     }
     if (prevProps.smartBoxHomeClicked !== this.props.smartBoxHomeClicked && this.props.smartBoxHomeClicked === true) {
       this.setState({
         showUploadProgress: false
       })
       this.props.setSmartBoxShowUploadNavigation(true)
     }
     if (prevProps.smartBoxShowUploadNavigation !== this.props.smartBoxShowUploadNavigation && this.props.smartBoxShowUploadNavigation === true) {
       this.setState({
         showUploadProgress: false
       })
       this.props.setSmartBoxShowUploadNavigation(false)
     }
     if (prevProps.smartBoxShowUploadNavigation !== this.props.smartBoxShowUploadNavigation && this.props.smartBoxShowUploadNavigation === false) {
       this.setState({
         showUploadProgress: false
       })
       this.props.setSmartBoxShowUploadNavigation(false)
     }
     if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
       this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
       this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
     }
   }
  
  onUnload = (ev) => {
    if (this.state.currentLocation) {
      ev.preventDefault()
      return ev.returnValue = 'Are you sure you want to close?'
    }
  }
  
  handleUpload({ fileList, searchText, path }) {
    const { totalBatchOfFiles  } = this.state
    this.setState({
      totalBatchOfFiles: [],
      totalBatchOfFilesNames: [],
    }, () => {
      this.worker.postMessage(fileList)
      this.setState({
        showUploadProgress: true,
        searchText,
        path,
        currentLocation: window.location.pathname
      })
    })
  }
  
  closeWindowUpload() {
    if (this.state.fetchingCount !== 0) {
      /*customConfirm({
        confirmAction: () => {
          this.setState({
            totalBatchOfFiles: [],
            totalBatchOfFilesNames: [],
            showUploadProgress: false,
            path: null,
            fetchingCount: 0
          }, () => {
            this.props.setFetchFolderContent(false)
          })
        },
        title: 'Are you sure ?',
        content: 'Data will be lost',
        confirmButtonText: 'YES',
        cancelButtonText: 'CANCEL',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button'
      })*/
    } else if (this.state.responseStatusMultipleUpload === 415) {
      // Upload failed notifications for Unsupported Media Type error
      const uploadFailed = []
      const indexBox = this.props.smartBoxUploadingObject.path && this.props.smartBoxUploadingObject.path.indexOf('/')
      let nameBoxFailed = ''
      if (indexBox === -1) {
        nameBoxFailed = this.props.smartBoxUploadingObject.path
      } else {
        nameBoxFailed = this.props.smartBoxUploadingObject.path && this.props.smartBoxUploadingObject.path.substring(0, indexBox)
      }
      const timeCurrent = `${ moment().format('MM-DD-YYYY') } at ${ moment().format('HH:mm:ss') }`
      uploadFailed.push({
        descriptionActionEvent: 'Upload failed',
        nameActionEvent: nameBoxFailed,
        status: 'upload failed',
        timesEvent: timeCurrent
      })
      const uploadFailedFinally = uploadFailed.concat(this.props.smartBoxUploadFailed)
      const uniqueUploadFinally = uploadFailedFinally && uploadFailedFinally.length > 0 && uploadFailedFinally.filter((a, i) => uploadFailedFinally.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
      this.props.setSmartBoxUploadFailed(uniqueUploadFinally)
    } else {
      const notAddNote = {
        nameActionEvent: `Upload of ${ this.state.totalBatchOfFilesNames } complete`,
        descriptionActionEvent: `${ this.props.smartBoxUploadingObject.path }`,
        timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
        status: 'box-notification'
      }
      this.props.setSmartBoxNotUploadComplete(notAddNote)
      this.props.setSmartBoxShowUploadNavigation(true)
      this.setState({
        totalBatchOfFiles: [],
        totalBatchOfFilesNames: [],
        showUploadProgress: false,
        path: null,
        fetchingCount: 0
      }, () => {
        this.props.setFetchFolderContent(false)
      })
    }
  }
  onCancelUpload = () => {
    const CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    source.cancel('Operation canceled');
    source = CancelToken.source();
    this.setState({ uploadCanceled: true });
    if (this.props.smartBoxUploadUniqueId && this.props.smartBoxUploadUniqueId.length > 0) {
      const uploadId = this.props.smartBoxUploadUniqueId[0].uniqueId
      cancelFilesUpload(uploadId).then((response) => {
          if (response.status === 200) {
            this.setFetchingCount()
          }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
    this.props.setSmartBoxShowUploadNavigation(true)
    this.setState({
      totalBatchOfFiles: [],
      totalBatchOfFilesNames: [],
      showUploadProgress: false,
      path: null,
      fetchingCount: 0
    }, () => {
      this.props.setFetchFolderContent(false)
    })
  }
  handleCancelUpload = (e) => {
    e.preventDefault()
    customConfirm({
      title: <span className="icon-v18-remove"></span>,
      content: 'Cancel upload ?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal confirm-cancel-upload',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
      confirmAction: () => {
        this.onCancelUpload()
      }
    })
  }
  setFetchingCount = () => {
    this.setState({
      fetchingCount: this.state.fetchingCount + 1
    }, () => {
      if (this.state.fetchingCount === this.state.totalBatchOfFiles.length) {
        setTimeout(() => this.closeWindowUpload(), 3000)
        this.manager.detach()
        this.setState({
          responseErrorZip: false
        })
        if (window.location.pathname === this.state.currentLocation) {
          this.setState({
            fetchingCount: 0
          }, () => { setTimeout(() => this.props.setFetchFolderContent(true), 2000)
            this.setState({
              showUploadProgress: false
            })
            this.props.setSmartBoxShowUploadNavigation(true)
          })}
        window.removeEventListener('beforeunload', this.onUnload, false)
      }
    })
  }
  percentCompletedStatus = (percentCompleted) => {
    if (percentCompleted === 99) {
      setTimeout(() => {
        this.setState({
          showUploadProgress: false,
        })
        this.setFetchingCount()
        this.props.setFetchFolderContent(true)
      }, 4000)
    }
  }
  onCancelMultipleUpload = () => {
    if (this.props.collectDataMapping.sendDataMapping && this.props.collectDataMapping.sendDataMapping.length > 0) {
      apiCancelMultipleUpload(this.props.collectDataMapping.sendDataMapping).then(() => {

      })
    }
  }
  onProgressEventEstimated(event) {
   return event
  }
  render() {
    const { showUploadProgress, totalBatchOfFiles, totalBatchOfFilesNames, path, responseErrorZip, estimatedStatusEvent } = this.state
    if (totalBatchOfFilesNames && totalBatchOfFilesNames.length) {
      totalBatchOfFilesNames.forEach((item, index) => {
        if (item && item.length) {
          item.forEach((file, i) => {
            const extension = file.split('.').pop().toLowerCase()
            const newFileName = `${ file.substr(0, file.lastIndexOf('.')) }.${ extension }`
            totalBatchOfFilesNames[index][i] = newFileName
          })
        }
      })
    }
    if (totalBatchOfFiles && totalBatchOfFiles.length) {

      totalBatchOfFiles.forEach((item, index) => {
        if (item && item.length) {
          item.forEach((file, i) => {
            const extension = file.name.split('.').pop().toLowerCase()
            const newFileName = `${ file.name.substr(0, file.name.lastIndexOf('.')) }.${ extension }`
            if (Object.keys(totalBatchOfFiles[index][i]).length) {
              totalBatchOfFiles[index][i].name = newFileName
              if (file.fullPath) {
                const fullPath = `${ file.fullPath.substr(0, file.fullPath.lastIndexOf('.')) }.${ extension }`
                totalBatchOfFiles[index][i]['fullPath'] = fullPath
              }
              if (file.webkitRelativePath) {
                const webkitRelativePath = `${ file.webkitRelativePath.substr(0, file.webkitRelativePath.lastIndexOf('.')) }.${ extension }`
                totalBatchOfFiles[index][i]['webkitRelativePath'] = webkitRelativePath
              }
            }
          })
        }
      })
    }
    return (
        <React.Fragment>
          { this.props.collectDataMapping.checkStartProgress &&
          <div className="progress-upload-files progress-mapping-files">
            <Card
                //title="Uploading files"
                  extra={<span style={{cursor: 'pointer'}}
                               onClick={(e) => this.onCancelMultipleUpload(e)}
                               className="icon-v14-close-black"></span>}>
              { this.props.collectDataMapping.responseDataMapping && this.props.collectDataMapping.responseDataMapping.length > 0 &&
              this.props.collectDataMapping.responseDataMapping.map((file, index) =>
                  <FileProgressDataMapping key={index}
                                      showUploadProgress={true}
                                      files={file}
                  />)
              }
            </Card>
          </div>
          }
          {/*{ (this.props.setProgressEventUpload !== 0 || this.props.setProgressEventUpload === undefined) &&*/}
          { showUploadProgress && !this.props.smartBoxShowUploadNavigation &&
          <div className="progress-upload-files">
            <Card title="Uploading files"
                  extra={<span style={{cursor: 'pointer'}} onClick={(e) => this.handleCancelUpload(e)}
                               className="icon-v14-close-black"></span>}>
              {totalBatchOfFiles &&
              totalBatchOfFiles.map((file, index) =>
                  <FileUploadProgress key={index}
                                      showUploadProgress={true}
                                      files={file}
                                      upload={this.uploadDirectories}
                                      fileNames={totalBatchOfFilesNames[index]}
                                      path={path}
                                      setFetchingCount={this.setFetchingCount}
                                      smartBoxShowUploadNavigation={this.props.smartBoxShowUploadNavigation}
                                      responseErrorZip={responseErrorZip}
                                      percentCompletedStatus={this.percentCompletedStatus}
                                      onProgressEventEstimated={this.onProgressEventEstimated}
                  />)}
            </Card>
          </div>
          }
        </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  smartBoxUploadingObject: state.smartBox.smartBoxUploadingObject,
  smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,
  smartBoxUploadFailed: state.smartBox.smartBoxUploadFailed,
  smartBoxUploadUniqueId: state.smartBox.smartBoxUploadUniqueId,
  smartBoxHomeClicked: state.smartBox.smartBoxHomeClicked,
  fetchFolderContent: state.smartBox.fetchFolderContent,
  smartBoxShowUploadNavigation: state.smartBox.smartBoxShowUploadNavigation,
  collectDataMapping: state.smartBox.collectDataMapping,
  setProgressEventUpload: state.smartBox.setProgressEventUpload,
})

export default connect(mapStateToProps, { setFetchFolderContent, setSmartBoxUploadFailed, setSmartBoxNotUploadComplete, setSmartBoxShowUploadNavigation })(UploadProgressWindow)