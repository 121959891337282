import React from "react";
import {connect} from "react-redux";
import './BusinessProcessPage.scss';
import { Input, Checkbox} from "antd";
const { TextArea } = Input
class HeaderProcessing extends React.Component {
    state = {
        headerForm: {
            valueInput: '',
            header: 'Header',
        },
        hoverDelete: false,
        editHeader: false,
        characterCount:0,
        hoverEditBtn:false,
    }
    componentDidMount() {
        if (this.props.component && this.props.component.header) {
            this.setState({
                headerForm: {...this.state.headerForm, valueInput: this.props.component.valueInput, header: this.props.component.header,
                    key: this.props.component.key, id: this.props.component.id }
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    onChangeInput = (e, key) => {
        switch (key) {
            case 'text':
                this.setState({
                    headerForm: { ...this.state.headerForm, valueInput: e.target.value },
                    characterCount:e.target.value.length,
                }, () => {
                    this.props.onChangeForm(this.state.headerForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditBtn = () => {
        this.setState({
            hoverEditBtn: true
        })
    }
    onLeaveEditBtn = () => {
        this.setState({
            hoverEditBtn: false
        })
    }
    onClickEditHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                editHeader: true
            })
        }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                headerForm: { ...this.state.headerForm, header: e.target.value },
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.headerForm, this.props.component)
            })
        }
    }

    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    render() {
        const { headerForm, hoverDelete, editHeader, characterCount, hoverEditBtn} = this.state
        const { component } = this.props
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form-header dragging " : "component-form-header"}>
                <div className="component-delete">
                    <span onClick={ (e) => this.props.showConfirmDeleteElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></span>
                </div>
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-content">
                              { editHeader ?
                                  <div className="contain-header">
                                      <Input defaultValue={headerForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.confirmEditHeader()}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                                  :
                                  <div className="contain-header">
                                      <span className="header-title header-title-update">{headerForm.header}</span>
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                              }
                          </span>
                    </div>
                    <span className="input-activities-content">
                            <Input className="header-form-name"
                                      onChange={(e) => this.onChangeInput(e, 'text')}

                                      value={ headerForm.valueInput }/>
                        </span>
                </div>
                <div className="component-move-header">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(HeaderProcessing)