import React from 'react'
import './DataMappingModal.scss'
import {Button, Modal, Select, DatePicker, Input, Spin, Icon, Tooltip, Progress, notification} from 'antd'
import {connect} from "react-redux";
import {
    apiCancelUpload,
    apiCountMailsFromUsExchange,
    apiDownloadFilesDriveInitialiseUpload, apiDownloadFilesFromSharePointInitialiseUpload,
    apiExtractEmailsAndInitialiseUpload,
    apiGetCheckCountMailsFromMsExchange,
    apiGetConnectorInfo,
    apiGetConnectorList, apiGetSharePointSiteFolderList, apiGetSharePointSiteList,
    apiPostConnectorInfo,
    getSmartBoxFilesFilter
} from "../../api/SmartBox";
import CustomNotificationManager from "./CustomNotificationManager";
import imgFolderSmallParent from "../../assets/smart-folder-medium-parent.svg";
import {setCollectDataMapping, setFetchFolderContent} from "../../actions/smartBoxActions";
import cookie from "react-cookies";
const { Option } = Select;


class DataMappingModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hoverAddBtn: false,
            hoverRemoveBtn: false,
            valueSelectConnector:'',
            valueSelectSiteListSharePointConnector:'',
            valueSelectSiteListFolderSharePointConnector:'',
            listOptionsConnector:['Microsoft 365 Exchange'],
            disabledSearchButton:true,
            loadingDataCollection:false,
            inputListNumber:[0],
            valueInputSearchFor: { 0: '' },
            selectedDateValue:[],
            dataTableConnect: [],
            dataSelectSiteListSharePoint: [],
            dataSelectSiteFolderListSharePoint: [],
            listBoxConnect: [],
            objectSelectConnect: {},
            valueClientId: '',
            valueUserId: '',
            valueSecretValue: '',
            valueTenantId: '',
            valueUserName: '',
            valuePassword: '',
            valueFolderName: '',
            valueSelectedBox: '',
            dateSelect: [],
            importDataCollection: false,
            responseExchange: null,
            percentConnectInfo: 0,
            displayProgressConnectInfo: false,
            setIntervalConnect: 0,
            responseConnectInfo: [],
            setIntervalImport: 0,
            responseImport: {},
            visibleProgressImport: false,
            uploadIdGenerate: '',
            statusUploadConnectorInfo: false,
            setIntervalCheckEmailExchange: 0,
            responseIntervalCheckEmailExchange: [],
            queryId: '',
            dateTime: [],
            statusBack: false,
            loadingActionSearch: false,
            loadingActionCancel: false,
            folderId: null
        }
    }
    componentDidMount() {
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        this.setState({
            uploadIdGenerate: result
        })
        this.onGetConnectorList()
        this.fetchFilesBox()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.valueSelectConnector !== this.state.valueSelectConnector
            || prevState.selectedDateValue !== this.state.selectedDateValue){
            if(this.state.valueSelectConnector !== 'Select' && this.state.selectedDateValue.length !== 0){
                this.setState({disabledSearchButton: false})
            }else {
                this.setState({disabledSearchButton: true})
            }
        }
    }
    onGetConnectorList = () => {
        const getConnectorsRequestDTO = {
            clientId: this.props.clientId,
            pageNumber: 1,
            pageRows: 99999,
        }
        apiGetConnectorList(getConnectorsRequestDTO).then((response) => {
            this.setState({
                dataTableConnect: response.data.connectorList ? response.data.connectorList : []
            })
        })
    }

    onGetSharePointSiteList = (data) => {
        apiGetSharePointSiteList(data).then((response) => {
            this.setState({
                dataSelectSiteListSharePoint : response.data && response.data.sharePointSiteDTOList ? response.data.sharePointSiteDTOList : []
            })
        })
    }

    onGetSharePointSiteFolderList = (data) => {
        apiGetSharePointSiteFolderList(data).then((response) => {
            this.setState({
                dataSelectSiteFolderListSharePoint : response.data && response.data.sharePointSiteListItemDTOS ? response.data.sharePointSiteListItemDTOS : []
            })
        })
    }


    fetchFilesBox = () => {
        const initFilter = {
            docFilename: '',
            docFolderPath: null
        }
        getSmartBoxFilesFilter(initFilter).then((res) => {
            this.setState({
                listBoxConnect: res.data
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    handleCancelModal = () => {
        this.setState({
            loadingActionCancel: true
        }, () => {
            this.setState({
                loadingActionCancel: false
            })
            this.props.statusCloseModalDataMapping(false)
        })
    }
    handleBackModal = () => {
        this.setState({
            importDataCollection: false,
            loadingDataCollection: false,
            statusBack: true,
        })
    }
    onHoverAddBtn = () => {
        this.setState({
            hoverAddBtn: true
        })
    }
    onLeaveAddBtn = () => {
        this.setState({
            hoverAddBtn: false
        })
    }
    onHoverRemoveBtn = () => {
        this.setState({
            hoverRemoveBtn: true
        })
    }
    onLeaveRemoveBtn = () => {
        this.setState({
            hoverRemoveBtn: false
        })
    }
    onChangeSelectConnector = (e, item) => {
        const { dataTableConnect } = this.state
        let listSelect = {}
        dataTableConnect && dataTableConnect.length > 0 && dataTableConnect.forEach((list) => {
            if (list.connectorId === e) {
                listSelect = list
            }
        })
        this.setState({
            valueSelectConnector: e,
            objectSelectConnect: listSelect
        })

    if( listSelect.connectorProvider === "Microsoft 365 SharePoint") {

            const getSharePointSitesDTO = {
                connectorId: listSelect.connectorId
            }
            this.onGetSharePointSiteList(getSharePointSitesDTO);
        }

    }

    onChangeSelectSiteSharePointConnector = (e, item) => {

        this.setState({
            valueSelectSiteListSharePointConnector: e
        })

            const getSharePointSiteFolders = {
                connectorId: this.state.objectSelectConnect.connectorId,
                siteId: e
            }
            this.onGetSharePointSiteFolderList(getSharePointSiteFolders);




    }

    onChangeSelectSiteFolderSharePointConnector = (e, item) => {
      const { valueSelectSiteListFolderSharePointConnector, dataSelectSiteFolderListSharePoint } = this.state
        let folderId = null
        dataSelectSiteFolderListSharePoint && dataSelectSiteFolderListSharePoint.length > 0 && dataSelectSiteFolderListSharePoint.forEach((item) => {
             if (e === item.folderName) {
                 folderId = item.folderId
             }
        })
        this.setState({
            valueSelectSiteListFolderSharePointConnector: e,
            folderId: folderId
        })


    }

    onChangeInputSearchFor = (e, ind) => {
        let { value } =  e.target
        let copyState = this.state.valueInputSearchFor
        copyState[ind] = value
        this.setState({ valueInputSearchFor: copyState })
    }
    handleCancelEdit = () => {
        this.setState({
            valueSelectConnector: '',
            valueSelectSiteListSharePointConnector: '',
            valueSelectSiteListFolderSharePointConnector: '',
            valueInputSearchFor:'',
        })
    }
    addNewInputSearchFor = (arg) => {
        if(arg === 'INCREMENT'){
            this.setState({inputListNumber: [...this.state.inputListNumber, 0]})
        }else{
            let removedLast = [...this.state.inputListNumber]
            if(removedLast.length !==1 ){
                removedLast.pop()
                this.setState({inputListNumber: removedLast})
            }
        }
    }

    handleOnClickSearch = (e) => {
        const { objectSelectConnect, valueClientId, valueUserId, valueSecretValue, valueTenantId, valueUserName, valuePassword,
             valueInputSearchFor, dateSelect, valueFolderName, uploadIdGenerate, valueSelectSiteListSharePointConnector, folderId,
            valueSelectSiteListFolderSharePointConnector} = this.state
        const { boxNameBase, folderParent, smartBoxParentFolderName } = this.props
        e.preventDefault()
        e.stopPropagation()
        const valueInputsSearch = []
        const nameBoxPAth = boxNameBase ? boxNameBase : folderParent ? folderParent.name : smartBoxParentFolderName
        Object.keys(valueInputSearchFor) && Object.keys(valueInputSearchFor).length > 0 && Object.keys(valueInputSearchFor).forEach((value) => {
            valueInputsSearch.push(valueInputSearchFor[value])
        })
        // this.setState({
        //     loadingDataCollection:true,
        // })
        let connectorsUploadDTO = {}
        if (objectSelectConnect.connectionType === "Username & Password Credentials") {
            connectorsUploadDTO = {
                boxPath: nameBoxPAth,
                clientIdMs: valueClientId,
                usernameMs: valueUserName,
                passwordMs: valuePassword,
                connectionType: objectSelectConnect.connectionType,
                connectorId: objectSelectConnect.connectorId,
                searchItemList: valueInputsSearch.includes('') ? [] : valueInputsSearch,
                dataRangeStart: dateSelect[0],
                dataRangeEnd: dateSelect[1],
            }
        } else {
            connectorsUploadDTO = {
                boxPath: nameBoxPAth,
                clientIdMs: valueClientId,
                userIdMs: valueUserId,
                secretValueMs: valueSecretValue,
                tenantIdMs: valueTenantId,
                connectionType: objectSelectConnect.connectionType,
                connectorId: objectSelectConnect.connectorId,
                searchItemList: valueInputsSearch.includes('') ? [] : valueInputsSearch,
                dataRangeStart: dateSelect[0],
                dataRangeEnd: dateSelect[1],
                folderName: valueFolderName,
            }
        }
        let connectorsUpload = {}
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        if (objectSelectConnect.connectorProvider === 'Microsoft 365 SharePoint') {
            connectorsUpload = {
                boxPath: nameBoxPAth,
                uploadId: uploadIdGenerate,
                connectorId: objectSelectConnect.connectorId,
                folderName: valueSelectSiteListFolderSharePointConnector,
                folderId: folderId,
                siteId: valueSelectSiteListSharePointConnector,
            }
        } else if (objectSelectConnect.connectorProvider === "Microsoft 365 Exchange") {
            connectorsUpload = {
                boxPath: nameBoxPAth,
                //folderName: valueFolderName,
                connectorId: objectSelectConnect.connectorId,
                dataRangeStart: dateSelect[0],
                dataRangeEnd: dateSelect[1],
                searchItemList: valueInputsSearch.includes('') ? [] : valueInputsSearch,
                uploadId: uploadIdGenerate
            }
        } else {
            connectorsUpload = {
                boxPath: nameBoxPAth,
                folderName: valueFolderName,
                connectorId: objectSelectConnect.connectorId,
                uploadId: uploadIdGenerate
            }
        }
        this.setState({
            loadingActionSearch: true
        }, () => {
            if (objectSelectConnect.connectorProvider === 'Microsoft 365 SharePoint') {
                apiDownloadFilesFromSharePointInitialiseUpload(connectorsUpload).then((response) => {
                    if (response.status === 200) {
                        if (response.data.title && response.data.title.toLowerCase() === 'success') {
                            this.setState({
                                //importDataCollection: true,
                                responseExchange: response.data.message,
                                loadingActionSearch: false
                            }, () => {
                                notification.info({
                                    message: response.data.message,
                                    duration: 6
                                })
                                this.onGetConnectInfo()
                                this.props.statusCloseModalDataMapping(false)
                            })
                        } else  if (response.data.title && response.data.title.toLowerCase() === 'info') {
                            this.setState({
                                //importDataCollection: true,
                                responseExchange: response.data.message,
                                loadingActionSearch: false
                            }, () => {
                                notification.info({
                                    message: response.data.message,
                                    duration: 6
                                })
                                //this.onGetConnectInfo()
                                this.props.statusCloseModalDataMapping(false)
                            })
                        }
                    }
                })
            } else if (objectSelectConnect.connectorProvider === 'Microsoft 365 Exchange') {
                apiCountMailsFromUsExchange(connectorsUpload).then((response) => {
                    this.setState({
                        queryId: response.data,
                        loadingDataCollection:true,
                        loadingActionSearch: false
                    })
                    this.onCheckCountMailsFromMsExchange(response.data)
                    // if (response.status === 200) {
                    //     if (response.data.errorMessage === null && response.data.nrOfEmails !== null) {
                    //         this.setState({
                    //             importDataCollection: true,
                    //             responseExchange: response.data
                    //         })
                    //     } else if ( response.data.errorMessage && response.data.errorMessage.includes('Syntax error: character')) {
                    //             notification.info({
                    //             message: `${ response.data.errorMessage }  Please try again.`,
                    //             duration: 6
                    //         })
                    //     }
                    //     else {
                    //             notification.info({
                    //                 message: `${ response.data.errorMessage } Please reconnect!`,
                    //                 duration: 6
                    //             })
                    //     }
                    // }
                })
            } else {
                apiDownloadFilesDriveInitialiseUpload(connectorsUpload).then((response) => {
                    if (response.status === 200) {
                        if (response.data.title && response.data.title.toLowerCase() === 'success') {
                            this.setState({
                                //importDataCollection: true,
                                responseExchange: response.data.message,
                                loadingActionSearch: false
                            }, () => {
                                notification.info({
                                    message: response.data.message,
                                    duration: 6
                                })
                                this.onGetConnectInfo()
                                this.props.statusCloseModalDataMapping(false)
                            })
                        } else  if (response.data.title && response.data.title.toLowerCase() === 'info') {
                            this.setState({
                                //importDataCollection: true,
                                responseExchange: response.data.message,
                                loadingActionSearch: false
                            }, () => {
                                notification.info({
                                    message: response.data.message,
                                    duration: 6
                                })
                                //this.onGetConnectInfo()
                                this.props.statusCloseModalDataMapping(false)
                            })
                        }
                    }
                })
            }
        })

    }
    onCheckCountMailsFromMsExchange = (queryId) => {
        const { setIntervalCheckEmailExchange, responseIntervalCheckEmailExchange} = this.state
        apiGetCheckCountMailsFromMsExchange(queryId).then((response) => {
            if (response.status === 200) {
                this.setState({
                    responseIntervalCheckEmailExchange: response.data,
                })
                if (Array.isArray(response.data) && response.data.length > 0) {
                    this.setIntervalCountMailsFromMsExchange()
                }
                 else
                if (response.data.errorMessage === null && response.data.nrOfEmails !== null) {
                    clearInterval(setIntervalCheckEmailExchange)
                    this.setState({setIntervalCheckEmailExchange})
                    this.setState({
                        importDataCollection: true,
                        responseExchange: response.data
                    })
                } else if ( response.data.errorMessage && response.data.errorMessage.includes('Syntax error: character')) {
                    clearInterval(setIntervalCheckEmailExchange)
                    this.setState({setIntervalCheckEmailExchange})
                    notification.info({
                        message: `${ response.data.errorMessage }  Please try again.`,
                        duration: 6
                    })
                }
                else {
                    clearInterval(setIntervalCheckEmailExchange)
                    this.setState({setIntervalCheckEmailExchange,loadingDataCollection:false})
                    notification.info({
                        message: `${ response.data.errorMessage } Please reconnect!`,
                        duration: 6
                    })
                }
            }
        })
    }

    setIntervalCountMailsFromMsExchange = (response) => {
      let { setIntervalCheckEmailExchange } = this.state
      const { statusBack } = this.state
        if (setIntervalCheckEmailExchange === 0 || statusBack === true) {
            setIntervalCheckEmailExchange = setInterval(this.callBackCountMailsFromMsExchange, 3000)
            this.setState({
                setIntervalCheckEmailExchange,
                statusBack: false
            })
        } else {
            clearInterval(setIntervalCheckEmailExchange)
            this.setState({setIntervalCheckEmailExchange})
        }
    }

    callBackCountMailsFromMsExchange = () => {
        const { queryId, responseIntervalCheckEmailExchange, setIntervalCheckEmailExchange } = this.state
        if (responseIntervalCheckEmailExchange.length !== 0) {
            apiGetCheckCountMailsFromMsExchange(queryId).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        responseIntervalCheckEmailExchange: response.data,
                    })
                    if (response.data.errorMessage === null && response.data.nrOfEmails !== null) {
                        clearInterval(setIntervalCheckEmailExchange)
                        this.setState({
                            loadingDataCollection:false,
                            importDataCollection: true,
                            responseExchange: response.data,
                            setIntervalCheckEmailExchange
                        })
                    } else if (response.data.errorMessage && response.data.errorMessage.includes('Syntax error: character')) {
                        clearInterval(setIntervalCheckEmailExchange)
                        this.setState({setIntervalCheckEmailExchange})
                        notification.info({
                            message: `${response.data.errorMessage}  Please try again.`,
                            duration: 6
                        })
                    } else if (!Array.isArray(responseIntervalCheckEmailExchange)) {
                        clearInterval(setIntervalCheckEmailExchange)
                        this.setState({setIntervalCheckEmailExchange})
                        notification.info({
                            message: `${response.data.errorMessage ? response.data.errorMessage : responseIntervalCheckEmailExchange.errorMessage} Please reconnect!`,
                            duration: 6
                        })
                    }
                }
            })
        }
    }

    onGetConnectInfo = (connectorIdUpdate) => {
        const { objectSelectConnect, statusUploadConnectorInfo } = this.state
        const { setIntervalConnect, uploadIdGenerate } = this.state
        const { collectDataMapping } = this.props
        const connectorId = objectSelectConnect.connectorId ? objectSelectConnect.connectorId : connectorIdUpdate
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        cookie.remove('mappingData', { path: '/' })
        let listIdGenerate = []
        listIdGenerate.push(uploadIdGenerate)
        //listIdGenerate = this.props.collectDataMapping.sendDataMapping.concat(listIdGenerate)
        listIdGenerate = listIdGenerate
        const uniqueListIdGenerate = listIdGenerate && listIdGenerate.length > 0 && listIdGenerate.filter((a, i) => listIdGenerate.findIndex((s) => a === s) === i)
        let collect = {}
        let statusUploadConnectorInfoUpdate = false
        const mappingData = cookie.load('mappingData', { path: '/' })
        // this.props.setCollectDataMapping(collect)
        const getConnectorInfoDTO = {
            uploadIDs: uniqueListIdGenerate,
            sendEmptyList: false,
        }
        apiPostConnectorInfo(getConnectorInfoDTO).then((response) => {
            let responseConnect = []
            responseConnect = response.data
            this.setState({
                responseConnectInfo: response.data
            }, () => {
                setTimeout(() => {
                    this.props.statusCloseModalDataMapping(false)
                }, 2000)
            })
            if (response.data && response.data.length > 0) {
                cookie.save('mappingData', response.data[0].connectorStatus , { path: './' })
            } else {
                if (response.data && response.data.length > 0) {
                    cookie.save('mappingData', 'dddddddd' , { path: './' })
                }
            }
            const listConnectorStatus = []
            response.data && response.data.length > 0 && response.data.forEach((item) => {
                listConnectorStatus.push(item.connectorStatus)
            })
            const responseApi = []
            if (response.data && response.data.length > 0) {
                collect = {
                    sendDataMapping: uniqueListIdGenerate,
                    responseDataMapping: response.data,
                    checkStartProgress: true
                }
                if (response.data[0].connectorStatus === 'FILES EXTRACTION COMPLETED') {
                    this.setState({
                        statusUploadConnectorInfo: true
                    })
                    statusUploadConnectorInfoUpdate = true
                }
            } else {
                collect = {
                    sendDataMapping: uniqueListIdGenerate,
                    responseDataMapping: response.data,
                    checkStartProgress: false
                }
            }
            this.props.setCollectDataMapping(collect)

            if (response.data && response.data.length > 0) {
                this.props.setCollectDataMapping(collect)
                this.setIntervalConnectInfo(response.data)
            } else {
                clearInterval(setIntervalConnect)
                this.setState({setIntervalConnect})
            }
        })
    }

    setIntervalConnectInfo = (response) => {
        let { setIntervalConnect } = this.state
        const mappingData = cookie.load('mappingData', { path: '/' })
        if (setIntervalConnect === 0) {
            if (mappingData && mappingData.length > 2) {
                setIntervalConnect = setInterval(this.callBackCheckConnectInfo, 3000)
                this.setState({
                    setIntervalConnect
                })
            } else {
                clearInterval(setIntervalConnect)
            }
        }
    }

    callBackCheckConnectInfo = () => {
        const { responseConnectInfo, setIntervalConnect, connectorIdUpdate, objectSelectConnect, uploadIdGenerate, statusUploadConnectorInfo } = this.state
        const connectorId = objectSelectConnect.connectorId ? objectSelectConnect.connectorId : connectorIdUpdate
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        //this.props.collectDataMapping && this.props.collectDataMapping.checkStartProgress !== null
        const mappingData = cookie.load('mappingData', { path: '/' })
        if (mappingData && mappingData.length > 2 && mappingData !== 'stop') {
            let listIdGenerate = []
            listIdGenerate.push(uploadIdGenerate)
            if (this.props.collectDataMapping && this.props.collectDataMapping.sendDataMapping && this.props.collectDataMapping.sendDataMapping.length > 0) {
                //listIdGenerate = this.props.collectDataMapping.sendDataMapping.concat(listIdGenerate)
                listIdGenerate = listIdGenerate
            }
            const uniqueListIdGenerate = listIdGenerate && listIdGenerate.length > 0 && listIdGenerate.filter((a, i) => listIdGenerate.findIndex((s) => a === s) === i)
            let collect = {}
            //this.props.setCollectDataMapping(collect)
            let statusUploadConnectorInfoUpdate = false
            const getConnectorInfoDTO = {
                uploadIDs: uniqueListIdGenerate,
                sendEmptyList: mappingData === 'FILES EXTRACTION COMPLETED' ? true : false,
            }
            if (mappingData === 'FILES EXTRACTION COMPLETED') {
                this.props.setFetchFolderContent(true)
            } else {
                this.props.setFetchFolderContent(false)
            }
            apiPostConnectorInfo(getConnectorInfoDTO).then((response) => {
                let responseConnect = []
                responseConnect = response.data
                if (response.data && response.data.length > 0) {
                    cookie.save('mappingData', response.data[0].connectorStatus , { path: './' })
                } else if (mappingData === 'FILES EXTRACTION COMPLETED') {
                    cookie.save('mappingData', 'stop' , { path: './' })
                    this.props.setFetchFolderContent(true)
                } else {
                    this.props.setFetchFolderContent(false)
                    if (response.data && response.data.length > 0) {
                        cookie.save('mappingData', 'dddddd' , { path: './' })
                    }
                }
                const responseApi = []
                this.setState({
                    responseConnectInfo: response.data
                })
                if (response.data && response.data.length > 0) {
                    collect = {
                        sendDataMapping: uniqueListIdGenerate,
                        responseDataMapping: response.data,
                        checkStartProgress: true
                    }
                    if (response.data[0].connectorStatus === 'FILES EXTRACTION COMPLETED') {
                        this.setState({
                            statusUploadConnectorInfo: true
                        })
                        statusUploadConnectorInfoUpdate = true
                    }
                    this.props.setCollectDataMapping(collect)
                    this.setState({setIntervalConnect})
                } else {
                    collect = {
                        sendDataMapping: uniqueListIdGenerate,
                        responseDataMapping: response.data,
                        checkStartProgress: false
                    }
                    //this.props.statusCloseModalDataMapping(false)
                    clearInterval(setIntervalConnect)
                    this.setState({setIntervalConnect})
                    this.props.setCollectDataMapping(collect)
                }
            })
        } else if (mappingData.length === 2) {
            clearInterval(setIntervalConnect)
            this.setState({setIntervalConnect})
        }
    }
    handleOnClickImportFinally = () => {
        const { objectSelectConnect, valueInputSearchFor, dateSelect, valueFolderName, setIntervalImport, responseImport, uploadIdGenerate } = this.state
        const { boxNameBase, folderParent, smartBoxParentFolderName } = this.props
        const valueInputsSearch = []
        const nameBoxPAth = boxNameBase ? boxNameBase : folderParent ? folderParent.name : smartBoxParentFolderName
        Object.keys(valueInputSearchFor) && Object.keys(valueInputSearchFor).length > 0 && Object.keys(valueInputSearchFor).forEach((value) => {
            valueInputsSearch.push(valueInputSearchFor[value])
        })
        let connectorsUpload = {}
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        if (objectSelectConnect.connectorProvider === "Microsoft 365 Exchange") {
            connectorsUpload = {
                boxPath: nameBoxPAth,
                connectorId: objectSelectConnect.connectorId,
                dataRangeStart: dateSelect[0],
                dataRangeEnd: dateSelect[1],
                searchItemList: valueInputsSearch.includes('') ? [] : valueInputsSearch,
                uploadId: uploadIdGenerate
            }
        } else {
            connectorsUpload = {
                boxPath: nameBoxPAth,
                folderName: valueFolderName,
                connectorId: objectSelectConnect.connectorId,
                uploadId: uploadIdGenerate
            }
        }
        if (objectSelectConnect.connectorProvider === 'Microsoft 365 Exchange') {
            apiExtractEmailsAndInitialiseUpload(connectorsUpload).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        responseImport: response.data,
                        //visibleProgressImport: true
                    }, () => {
                        this.props.statusCloseModalDataMapping(false)
                    })
                    this.onGetConnectInfo()
                    //this.setIntervalImportFinally(response.data)
                }
            })
        }
    }
    setIntervalImportFinally = (response) => {
        let { setIntervalImport } = this.state
        if (response) {
            setIntervalImport = setInterval(this.callBackCheckImportFinally, 3000)
        } else {
            clearInterval(setIntervalImport)
            this.setState({setIntervalImport: 0})
        }
    }
    callBackCheckImportFinally = () => {
        const { objectSelectConnect, valueInputSearchFor, dateSelect, valueFolderName, setIntervalImport, responseImport, uploadIdGenerate } = this.state
        const { boxNameBase, folderParent, smartBoxParentFolderName } = this.props
        const valueInputsSearch = []
        const nameBoxPAth = boxNameBase ? boxNameBase : folderParent ? folderParent.name : smartBoxParentFolderName
        Object.keys(valueInputSearchFor) && Object.keys(valueInputSearchFor).length > 0 && Object.keys(valueInputSearchFor).forEach((value) => {
            valueInputsSearch.push(valueInputSearchFor[value])
        })
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        let connectorsUpload = {}
        if (objectSelectConnect.connectorProvider === "Microsoft 365 Exchange") {
            connectorsUpload = {
                boxPath: nameBoxPAth,
                connectorId: objectSelectConnect.connectorId,
                dataRangeStart: dateSelect[0],
                dataRangeEnd: dateSelect[1],
                searchItemList: valueInputsSearch.includes('') ? [] : valueInputsSearch,
                uploadId: uploadIdGenerate
            }
        } else {
            connectorsUpload = {
                boxPath: nameBoxPAth,
                folderName: valueFolderName,
                connectorId: objectSelectConnect.connectorId,
                uploadId: uploadIdGenerate
            }
        }
        if (objectSelectConnect.connectorProvider === 'Microsoft 365 Exchange') {
            apiExtractEmailsAndInitialiseUpload(connectorsUpload).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        responseImport: response.data
                    })
                }
            })
        }
    }
    handleOnClickImport = () => {
        const { objectSelectConnect, valueSelectedBox, valueClientId, valueUserId, valueSecretValue, valueTenantId, valueUserName, valuePassword,
            valueInputSearchFor, dateSelect, responseConnectInfo } = this.state
        const valueInputsSearch = []
        Object.keys(valueInputSearchFor) && Object.keys(valueInputSearchFor).length > 0 && Object.keys(valueInputSearchFor).forEach((value) => {
            valueInputsSearch.push(valueInputSearchFor[value])
        })
        let connectorsUploadDTO = {}
        if (objectSelectConnect.connectionType === "Username & Password Credentials") {
            connectorsUploadDTO = {
                boxPath: valueSelectedBox,
                clientIdMs: valueClientId,
                usernameMs: valueUserName,
                passwordMs: valuePassword,
                connectionType: objectSelectConnect.connectionType,
                connectorId: objectSelectConnect.connectorId,
                searchItemList: valueInputsSearch,
                dataRangeStart: dateSelect[0],
                dataRangeEnd: dateSelect[1],
            }
        } else {
            connectorsUploadDTO = {
                boxPath: valueSelectedBox,
                clientIdMs: valueClientId,
                userIdMs: valueUserId,
                secretValueMs: valueSecretValue,
                tenantIdMs: valueTenantId,
                connectionType: objectSelectConnect.connectionType,
                connectorId: objectSelectConnect.connectorId,
                searchItemList: valueInputsSearch,
                dataRangeStart: dateSelect[0],
                dataRangeEnd: dateSelect[1],
            }
        }
        const uploadId = responseConnectInfo.uploadId
        apiCancelUpload(uploadId).then(() => {
            this.setState({
                importDataCollection: false,
            })
            this.props.statusCloseModalDataMapping(false)
        })
    }
    selectDate = (date,dateString) => {
        this.setState({
            //selectedDateValue: this.state.selectedDateValue.push(...dateString),
            dateSelect: dateString,
            dateTime: date,
        })
    }
    handleChangeConnect(event, field) {
        switch (field) {
            case 'field_clientId':
                this.setState({ valueClientId: event.target.value })
                break
            case 'field_userId':
                this.setState({ valueUserId: event.target.value })
                break
            case 'field_secretValue':
                this.setState({ valueSecretValue: event.target.value })
                break
            case 'field_tenantId':
                this.setState({ valueTenantId: event.target.value })
                break
            case 'field_userName':
                this.setState({ valueUserName: event.target.value })
                break
            case 'field_password':
                this.setState({ valuePassword: event.target.value })
                break
            case 'field_folderName':
                this.setState({ valueFolderName: event.target.value })
                break
            default:
                break
        }
    }
    chooseSelectBox(e) {
        this.setState({
            valueSelectedBox: e
        })
    }
    render () {
        const {hoverAddBtn,hoverRemoveBtn,valueSelectConnector, loadingDataCollection, dataTableConnect, dataSelectSiteListSharePoint, dataSelectSiteFolderListSharePoint,
            valueSelectSiteListSharePointConnector, valueSelectSiteListFolderSharePointConnector, objectSelectConnect, valueUserId, valueSecretValue, valueTenantId,
            importDataCollection, responseExchange, valueFolderName, responseConnectInfo, valueInputSearchFor, dateSelect, visibleProgressImport, responseImport,
            uploadIdGenerate, statusUploadConnectorInfo, dateTime, loadingActionSearch, loadingActionCancel } = this.state
        const { RangePicker } = DatePicker;
        let disabledSearch = true
        const valueInputsSearch = []
        Object.keys(valueInputSearchFor) && Object.keys(valueInputSearchFor).length > 0 && Object.keys(valueInputSearchFor).forEach((value) => {
            valueInputsSearch.push(valueInputSearchFor[value])
        })
        if (Object.keys(objectSelectConnect) && Object.keys(objectSelectConnect).length > 0 && objectSelectConnect.connectorProvider === "Microsoft 365 SharePoint") {
            if (!valueSelectSiteListFolderSharePointConnector && !valueSelectSiteListSharePointConnector) {
                disabledSearch = true
            } else if (valueSelectSiteListFolderSharePointConnector && valueSelectSiteListSharePointConnector) {
                disabledSearch = false
            }
        } else if (Object.keys(objectSelectConnect) && Object.keys(objectSelectConnect).length > 0 && objectSelectConnect.connectorProvider === "Microsoft 365 Exchange") {
            if (valueInputsSearch.length === 0 && valueInputsSearch.includes('') && dateSelect.length === 0) {
                disabledSearch = true
            } else if (valueInputsSearch && !valueInputsSearch.includes('') && dateSelect.length > 0) {
                disabledSearch = false
            }
        } else if (Object.keys(objectSelectConnect) && Object.keys(objectSelectConnect).length > 0 && objectSelectConnect.connectorProvider === "Microsoft 365 Onedrive") {
            if (valueFolderName === '') {
                disabledSearch = true
            } else if (valueFolderName) {
                disabledSearch = false
            }
        }
        if (loadingDataCollection === true) {
            disabledSearch = true
        }
        let progressConnect = 0
        if (Object.keys(responseConnectInfo) && Object.keys(responseConnectInfo).length > 0) {
            progressConnect = ((responseConnectInfo.nrOfFilesExtracted / responseConnectInfo.totalFilesNumber)*100).toFixed(2)
        }
        let progressImport = 0
        if (Object.keys(responseImport) && Object.keys(responseImport).length > 0) {
            progressImport = ((responseImport.nrOfFilesExtracted / responseImport.totalFilesNumber)*100).toFixed(2)
        }
        this.state.dataTableConnect = dataTableConnect && dataTableConnect.length > 0 ? dataTableConnect.sort(function(a,b) {
            return a.connectorName.toLowerCase().localeCompare(b.connectorName.toLowerCase()) || b.connectorProvider - a.connectorProvider}) : ''
        if (responseExchange) {
        }
        return (
            <Modal className={ importDataCollection ? 'data-mapping-modal data-mapping-modal-progress' : 'data-mapping-modal' }
                   title={ <span className="content-header-data-mapping">
                       <span>{ loadingDataCollection ? 'Searching' : importDataCollection ? 'Data import' : 'Data search'}</span>
                       { importDataCollection ? <span className="icon-v14-close-black" onClick={() => this.handleCancelModal()}></span> : <span></span> }
                   </span>}
                   visible={true}
                   footer={null}
                   closable={ false }
                   style={loadingDataCollection ? {height:'350px'} : {height:'406px'}}
            >
                { loadingDataCollection ?
                    <div className="modal-content-on-search">
                      <div className="spin-wrapper">
                        <Spin className="data-mapping-modal-spin"/>
                     </div>
                    </div> :
                    importDataCollection ?
                        <div className="modal-content-on-search">
                            {
                                objectSelectConnect.connectorProvider === 'Microsoft 365 Exchange' ?
                                <div className='body-modal-on-search body-progress-import-finally'>
                                    <span className='title-upload'>{ `Import ${responseExchange && responseExchange.nrOfEmails ? responseExchange.nrOfEmails : '0'} files from data source` }</span>
                                </div> :
                                <div className='body-modal-on-search progress-connect-info'>
                                </div>
                            }

                        </div>
                    :
                    <div className='data-mapping-modal-body'>
                        <div className='data-mapping-modal-content'>
                            <div className='title-select'>Connector</div>
                            <Select className='select-data-mapping-connector'
                                    placeholder="Select"
                                    value={valueSelectConnector ? valueSelectConnector : 'Select'}
                                    onChange={(e) => this.onChangeSelectConnector(e)}>
                                {dataTableConnect && dataTableConnect.length > 0 && dataTableConnect.map((item, index) => {
                                    return (<Option className="options-select-connector"
                                                    value={item.connectorId}
                                                    key={index}
                                                    disabled={item.active === false}
                                    >
                                        {`${item.connectorProvider} - ${item.connectorName} - ${item.connectionStatus}`}
                                    </Option>)
                                })}
                            </Select>
                        </div>
                        { Object.keys(objectSelectConnect) && Object.keys(objectSelectConnect).length > 0 && objectSelectConnect.connectorProvider === "Microsoft 365 Onedrive" &&
                        <div className='data-mapping-modal-content'>
                            <div className='title-select'>Folder Name</div>
                            <Input className="input-connection-owner"
                                   value={valueFolderName}
                                   onChange={(e) => this.handleChangeConnect(e, 'field_folderName')}></Input>
                        </div>
                        }

                        { Object.keys(objectSelectConnect) && Object.keys(objectSelectConnect).length > 0 && objectSelectConnect.connectorProvider === "Microsoft 365 SharePoint" &&
                        <div className='data-mapping-modal-content'>
                            <div className='title-select'>Site</div>
                            <Select className='select-data-mapping-connector'
                                    placeholder="Select Site"
                                    disabled={dataSelectSiteListSharePoint == null || dataSelectSiteListSharePoint.length === 0}
                                    value={valueSelectSiteListSharePointConnector ? valueSelectSiteListSharePointConnector : 'Select SharePoint Site'}
                                    onChange={(e) => this.onChangeSelectSiteSharePointConnector(e)}>
                                {dataSelectSiteListSharePoint && dataSelectSiteListSharePoint.length > 0 && dataSelectSiteListSharePoint.map((item, index) => {
                                    return (<Option className="options-select-connector"
                                                    value={item.siteId}
                                                    key={index}
                                                    disabled={item.active === false}
                                    >
                                        {`${item.siteName} - ${item.siteDescription}`}
                                    </Option>)
                                })}
                            </Select>


                            <div className='title-select'>Folder</div>
                            <Select className='select-data-mapping-connector'
                                    placeholder="Select Folder"
                                    disabled={dataSelectSiteFolderListSharePoint == null || dataSelectSiteFolderListSharePoint.length === 0}
                                    value={valueSelectSiteListFolderSharePointConnector ? valueSelectSiteListFolderSharePointConnector : 'Select Folder'}
                                    onChange={(e) => this.onChangeSelectSiteFolderSharePointConnector(e)}>
                                {dataSelectSiteFolderListSharePoint && dataSelectSiteFolderListSharePoint.length > 0 && dataSelectSiteFolderListSharePoint.map((item, index) => {
                                    return (<Option className="options-select-connector"
                                                    value={item.folderName}
                                                    key={index}
                                                    disabled={item.active === false}
                                    >
                                        {`${item.folderName}`}
                                    </Option>)
                                })}
                            </Select>



                        </div>
                        }

                        {Object.keys(objectSelectConnect) && Object.keys(objectSelectConnect).length > 0 && objectSelectConnect.connectorProvider === 'Microsoft 365 Exchange' &&
                        <div className='data-mapping-modal-content data-mapping-update'>
                            <div className='title-select'>Date range</div>
                            <RangePicker onChange={this.selectDate}
                                         className='date-picker'
                                         defaultValue={dateTime ? dateTime : []}
                                         dropdownClassName="date-picker-up"/>

                        </div>
                        }
                        {Object.keys(objectSelectConnect) && Object.keys(objectSelectConnect).length > 0 && objectSelectConnect.connectorProvider === 'Microsoft 365 Exchange' &&
                        <div className='data-mapping-modal-content'>
                            <div className='title-select'>Search for</div>
                            {this.state.inputListNumber.map((item, index) => {
                                return (
                                    <Input key={index}
                                           className='data-mapping-input'
                                           placeholder="Key word"
                                           onChange={(e) => this.onChangeInputSearchFor(e, index)}
                                           value={this.state.valueInputSearchFor[index] ? this.state.valueInputSearchFor[index] : ''}/>
                                )
                            })
                            }
                        </div>
                        }
                        {Object.keys(objectSelectConnect) && Object.keys(objectSelectConnect).length > 0 && objectSelectConnect.connectorProvider === 'Microsoft 365 Exchange' &&
                        <div className="btn-wrapper">
                            <div className="btn-container">
                                <button className={hoverRemoveBtn ? "icon-v16-minus-active" : "icon-v16-minus"}
                                        onMouseEnter={() => this.onHoverRemoveBtn()}
                                        onMouseLeave={() => this.onLeaveRemoveBtn()}
                                        onClick={() => this.addNewInputSearchFor('DECREMENT')}
                                        disabled={this.state.inputListNumber.length === 1}/>
                                <button className={hoverAddBtn ? "icon-v16-add-active" : " icon-v16-add"}
                                        onMouseEnter={() => this.onHoverAddBtn()}
                                        onMouseLeave={() => this.onLeaveAddBtn()}
                                        onClick={() => this.addNewInputSearchFor('INCREMENT')}/>
                            </div>
                        </div>
                        }
                    </div>

                }


                { importDataCollection ?
                    <div className="footer-dataMapping-modal">
                        { objectSelectConnect.connectorProvider === 'Microsoft 365 Exchange' && !visibleProgressImport ?
                            <React.Fragment>
                                <Button className="action-cancel-search"
                                        onClick={() => this.handleBackModal()}>Back</Button>
                                <Button className="action-button-search"
                                        disabled={ responseExchange && responseExchange.nrOfEmails === 0 }
                                        onClick={() => this.handleOnClickImportFinally()}>Import</Button>
                            </React.Fragment> :
                            <React.Fragment>
                                {/*<Button className="action-cancel-search"*/}
                                {/*        onClick={() => this.handleCancelModal()}>Close</Button>*/}
                                {/*<Button type="primary" className="action-search"*/}
                                {/*        disabled={responseConnectInfo.uploadId === ''}*/}
                                {/*        onClick={() => this.handleOnClickImport()}>Cancel Import</Button>*/}
                            </React.Fragment>
                        }
                    </div> :
                    <div className="footer-dataMapping-modal">
                        <Button className="action-cancel-search"
                                loading={ loadingActionCancel }
                                onClick={() => this.handleCancelModal()}>Cancel</Button>
                        <Button className="action-button-search"
                                disabled={ disabledSearch === true }
                                loading={loadingActionSearch}
                                onClick={(e) => this.handleOnClickSearch(e)}>Search</Button>
                    </div>
                }

            </Modal>
        )
    }
}
const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    collectDataMapping: state.smartBox.collectDataMapping,
})
export default connect(mapStateToProps, { setCollectDataMapping, setFetchFolderContent
})(DataMappingModal)