import React from 'react'
import PropTypes from 'prop-types'
import {notification, Select, Spin} from 'antd'
import StandardTab from './StandardTab'
import ManualTab from './ManualTab'
import swal from "sweetalert2";
import {
    apiSmartBoxCheckAllCanvasPoints,
    apiSmartBoxSaveNewQueryStatus,
    apiSmartBoxSaveNewQueryStatusOcr, apiSmartBoxSaveXeroxRedactionSmartView,
    apiSmartBoxUpdateRedactionNumber, getBusinessCategoryColor, getRegexCategoryColor,
    getSmartBoxScanAnnotations,
    newGetBusinessTermWords, newGetRegexWords,
    saveKeysRedactedBusiness,
    saveKeysRedactedOnRegEx,
    getRectanglesCoordsOcrPost, apiRedactionColourProfileGetCategories
} from "../../../api/SmartBox";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import {getAnnotationColorsByClientId, getScanAnnotations} from "../../../api/Sar";
import {connect} from "react-redux";
const { Option } = Select
import {
    generateHtmlForSecurityScanResult,
    renderTreeNodes,
    markWordsOnDocumentForCategory,
    timerCheckCanvas,
    markWordsOnDocumentForCategoryCopy, markWordsOnDocumentForCategoryALL, generateHtmlForSecurityScanResultALL
} from '../../../utils/SmartBox/SmartBoxMethods'
import {categories} from "../../../utils/AITagsNew";
let timerCheckMarkReady
class AiAnnotationsSidebar extends React.Component {
  state = {
    activeKey: 'standardTab',
      checkedKeysObjectRefs: [],
      checkedKeysObjectRefsList: [],
      checkedKeysObjectRefsBoxTerms: [],
      checkedKeysObjectRefsRegEx: [],
      treeStandard: [],
      updateRedactAllStatus: false,
      checkedKeysAllStandard: [],
      finalChildrenStatus: [],
      checkAiStandard: true,
      statusFilterReview: 'review-all',
      treeDataStandardOption: [],
      checkedKeysRedacted: [],
      treeBusiness: [],
      checkedKeysAllBusiness: [],
      treeRegEx: [],
      checkedKeysAllRegEx: [],
      docAnnotationsRegEx: {},
      docAnnotationsList: {},
      docAnnotationsOcr: {},
      docAnnotationsXerox: {},
      finalChildrenStandard: [],
      visibleOptionsReviewAll: false,
      responseStatusQuery: 0,
      docAnnotationsStandard: [],
      docAnnotationsColorsStandard: [],
      docAnnotationCategoriesStandard: [],
      categoriesColorsStandard: {
          names: '',
          locations: '',
          numbers: '',
          time: '',
          addresses: '',
          identifiers: '',
          organisations: '',
      },
      treeDataAnnotationsStandard: [ {
          title: 'Select All',
          key: 'select-all',
          identifier: 'top-parent',
          children: []
      } ],
      docAnnotationsBusiness: {},
      docAnnotationsBusinessOcr: {},
      docAnnotationsBusinessXerox: {},
      categoryColoursBusiness: {},
      treeDataAnnotationsBusiness: [ {
          title: 'Select All',
          key: 'select-all',
          identifier: 'top-parent',
          children: []
      } ],
      docAnnotationsRegularExp: {},
      docAnnotationsRegularExpOcr: {},
      docAnnotationsRegularXerox: {},
      categoryColoursRegularExp: {},
      treeDataAnnotationsRegEx: [ {
          title: 'Select All',
          key: 'select-all',
          identifier: 'top-parent',
          children: []
      } ],
      treeDataAll: [ {
          title: 'Select All',
          key: 'select-all',
          identifier: 'top-parent',
          children: []
      } ],
      expandedKeysAll: [],
      getAllChildrenRegExUpdate: [],
      getAllChildrenRegExUpdateParent: [],
      regExListUpdate: [],
      regExListVersionUpdate: {},
      dictionaryListUpdate: [],
      getAllChildrenBusinessUpdate: [],
      getAllChildrenBusinessParent: [],
      getAllChildrenStandardUpdate: [],
      fetchResponseLoadingStandard: false,
      setIntervalCheckPdfCanvas: 0,
      responseCheckPdfCanvas: '',
      listAllDocAnnotationOcr: ['Dictionary', 'RegEx', 'doc_annotate_address_email', 'doc_annotate_address_url', 'doc_annotate_address_phone', 'doc_annotate_address_postcode',
          'doc_annotate_address_complete', 'doc_annotate_address_ip', 'doc_annotate_person_female', 'doc_annotate_person_male', 'doc_annotate_person', 'doc_annotate_identifier',
          'doc_annotate_password', 'doc_annotate_location_country', 'doc_annotate_location_city', 'doc_annotate_location_region', 'doc_annotate_location_airport',
          'doc_annotate_location_country_abbrev', 'doc_annotate_location_county', 'doc_annotate_location_province', 'doc_annotate_location_other', 'doc_annotate_location_pre',
          'doc_annotate_money', 'doc_annotate_percent', 'doc_annotate_ssn', 'doc_annotate_creditcard', 'doc_annotate_organization_company', 'doc_annotate_organization_department',
          'doc_annotate_organization_government', 'doc_annotate_organization_newspaper', 'doc_annotate_organization_university', 'doc_annotate_organization_team', 'doc_annotate_date'],
      statusHideAllHighlighting: true,
      statusIconHideAllHighlighting: true,
      listCategories: []
  }
    componentWillUnmount() {
        global.finalItems = {}
        global.items = {}
        global.markedTimes = 0
        global.itemsToBeMarked = 0
        global.arEl = []
        global.arOptions = []

        clearInterval(timerCheckMarkReady)
        clearInterval(timerCheckCanvas)
    }
  componentDidMount() {
      //this.fetchDataAllCategories(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
      this.setState({
          checkedKeysObjectRefs: [],
          checkedKeysObjectRefsList: [],
          checkedKeysObjectRefsBoxTerms: [],
          checkedKeysObjectRefsRegEx: [],
      }, () => {
          //this.fetchDataAllCategories(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
      })
      if (this.props.isDocumentReady) {
          //this.fetchDataAllCategories(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
      }
      this.onRedactionColourProfileGetCategories()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.onSelectResetProfilesStatus !== this.props.onSelectResetProfilesStatus && this.props.onSelectResetProfilesStatus === 'start') {
          this.onRedactionColourProfileGetCategories()
      }
          if (prevProps.isDocumentReady !== this.props.isDocumentReady && Object.keys(this.props.isNextDocumentId).length === 0) {
          if (this.props.isOcr) {
              this.fetchDataAllCategoriesOcr(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
          } else {
              this.fetchDataAllCategories(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
          }
      }
      if (prevProps.isDocumentReady !== this.props.isDocumentReady && Object.keys(prevProps.isNextDocumentId).length > 0) {
          if (this.props.isOcr) {
              this.fetchNextPreviousAllCategoriesOcr(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
          } else {
              this.fetchNextPreviousAllCategories(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
          }

      }
      if (prevProps !== this.props && this.props.startRedactXerox) {
          if (this.props.isOcr) {
              this.fetchDataAllCategoriesOcr(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
          } else {
              this.fetchDataAllCategories(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
          }
      }
  }

    onRedactionColourProfileGetCategories = () => {
        const { boxRedactionColorProfileId } = this.props
        const getRedactionColorProfileCategoriesRequestDTO = {
            defaultColorProfile: null,
            redactionColorProfileId: boxRedactionColorProfileId,
        }
        apiRedactionColourProfileGetCategories(getRedactionColorProfileCategoriesRequestDTO).then((response) => {
            this.props.onSelectResetProfilesStatusUpdate()
            this.setState({
                listCategories: response.data.categoryResponseDTOList,
            })
        })

    }
    fetchDataAllCategories = async (docId, clientId, userId, docId2, allPagesRendered) => {
        let responseStandard1
        let responseStandard2
        let responseBusiness1
        let responseBusiness2
        let responseRegEx1
        let responseRegEx2
        if (this.props.isSmartBoxApplication) {
            responseStandard1 = await getSmartBoxScanAnnotations(docId)
            responseBusiness1 = await newGetBusinessTermWords(docId2)
            responseRegEx1 = await newGetRegexWords(docId2)

             responseStandard2 = await getAnnotationColorsByClientId(clientId, userId)
             responseBusiness2 = await getBusinessCategoryColor(docId2)
            responseRegEx2 = await getRegexCategoryColor(docId2)
            this.props.onDisabledNextDocument(false)
            this.props.onDisabledBackDocument(false)
        }

        this.onGetStatusResponseQuery(responseStandard1.status)
        this.onDocAnnotationsOcr(responseStandard1.data)
        if (responseStandard1.data && Object.keys(responseStandard1.data).length === 0 && this.props.docAnnotationsRegEx && this.props.docAnnotationsList && Object.keys(this.props.docAnnotationsRegEx).length === 0 && Object.keys(this.props.docAnnotationsList).length === 0) {
            //CustomNotificationManager.error('No AI annotations available for this document, yet.', 'No annotations available')
            notification.info({
                message: `No Ai results found`,
                className: 'no-ai-results-found',
                duration: 3
            })
        }
       let statusResponseStandard = null
        if (responseStandard1.status === 200) {
            statusResponseStandard = true
        } else {
            statusResponseStandard = false
        }
        // if (responseStandard1.data && Object.keys(responseStandard1.data).length === 0) {
        //     statusResponseStandard = false
        // } else {
        //     statusResponseStandard = true
        // }
        //const categoryColoursBusinessDefault = responseBusiness1.data
        // Object.keys(categoryColoursBusinessDefault) &&  Object.keys(categoryColoursBusinessDefault).length > 0 &&  Object.keys(categoryColoursBusinessDefault).forEach((item) => {
        //     categoryColoursBusinessDefault[item] = "rgba(192, 125, 81, 0.3)"
        // })
        this.setState({
            docAnnotationsStandard: responseStandard1.data,
            //docAnnotationsStandard: [],
            //docAnnotationsStandard:  { doc_annotate_location_country :['AUSTRALIAN']},
            docAnnotationsOCR: responseStandard1.data,
            docAnnotationsXerox: responseStandard1.data,
            //docAnnotationsOCR: { doc_annotate_location_country :['AUSTRALIAN']},
            //docAnnotationsOCR: [],

            docAnnotationsColorsStandard: responseStandard2.data,
            //docAnnotationsColorsStandard: [],
            categoriesColorsStandard: {
                names: responseStandard2.data.filter((annColor) => annColor.category === 'names')[0].color,
                locations: responseStandard2.data.filter((annColor) => annColor.category === 'locations')[0].color,
                numbers: responseStandard2.data.filter((annColor) => annColor.category === 'numbers')[0].color,
                time: responseStandard2.data.filter((annColor) => annColor.category === 'time')[0].color,
                addresses: responseStandard2.data.filter((annColor) => annColor.category === 'addresses')[0].color,
                identifiers: responseStandard2.data.filter((annColor) => annColor.category === 'identifiers')[0].color,
                organisations: responseStandard2.data.filter((annColor) => annColor.category === 'organisations')[0].color
            },
            docAnnotationsBusiness: responseBusiness1.data,
            docAnnotationsBusinessOcr: responseBusiness1.data,
            docAnnotationsBusinessXerox: responseBusiness1.data,

            // docAnnotationsBusiness: { Harrison3 :['thorax', 'dikes', 'larvae', 'melanotum', 'damselfly'],
            //     NHSdemo :['rob', 'dor']},
            // docAnnotationsBusinessOcr: { silviu :['06/06/2023,16:38', '06/06/202316:38', 'ddd'],
            //     dragos :['aa', 'bb']},

            //categoryColoursBusiness: responseBusiness2.data,
            //categoryColoursBusiness: Object.keys(responseBusiness2.data).length === 0 ? categoryColoursBusinessDefault : responseBusiness2.data,
            categoryColoursBusiness: responseBusiness2.data,

            //categoryColoursBusiness: { d1: "rgba(192, 125, 81, 0.3)" },
            docAnnotationsRegularExp: responseRegEx1.data,
            docAnnotationsRegularExpOcr: responseRegEx1.data,
            docAnnotationsRegularXerox: responseRegEx1.data,

             // docAnnotationsRegularExp:  { doc_annotate_1: ["passport"]},
             // docAnnotationsRegularExpOcr:  { doc_annotate_1: ["passport"]},
            categoryColoursRegularExp: responseRegEx2.data,
            //categoryColoursRegularExp: { doc_annotate_medical: "rgba(219,16,16,0.3)" },

            fetchResponseLoadingStandard: statusResponseStandard
        }, () => {
            if (responseStandard1.status === 200 && responseBusiness1.status === 200 && responseRegEx1.status === 200) {
                this.renderAllCategoriesSections()
            }
        // if (allPagesRendered || Object.keys(responseStandard1.data).length) {
        //     this.renderAllCategoriesSections()
        //     }
        })
    }
    renderAllCategoriesSections = () => {
      const { docAnnotationsStandard, treeDataAnnotationsStandard, treeDataAll, treeDataAnnotationsBusiness, treeDataAnnotationsRegEx } = this.state
        let { categoriesColorsStandard, docAnnotationsColorsStandard, docAnnotationCategoriesStandard,
            docAnnotationsBusiness, categoryColoursBusiness, newBusinessUpdate,
            docAnnotationsRegularExp, categoryColoursRegularExp, expandedKeysAll, docAnnotationsRegularExpOcr} = { ...this.state }
        const { landscape } = this.props
        let categoriesColoursALL = {}
        //Standard
        const categoriesColorsStandardArray = []
        const docAnnotationsStandardArray = []
        const categoriesArray = []
        //Business
        const categoryColoursBusinessArray = []
        const docAnnotationsBusinessArray = []
        const categoriesBusinessArray = []
        //RegularExp
        const categoryColoursRegularExpArray = []
        const docAnnotationsRegularExpArray = []
        const categoriesRegularExpArray = []

         //All categories
        let categoryColoursArrayAll = []
        let docAnnotationsArrayAll = []
        let categoriesArrayAll = []

        categoriesColoursALL = {...categoryColoursBusiness, ...categoriesColorsStandard}
        //actions array Standard
        for (const categoryColor of Object.keys(categoriesColorsStandard)) {
            categoriesColorsStandardArray.push({
                name: categoryColor,
                colour: categoriesColorsStandard[categoryColor],
                categoryName: 'standard'
            })
        }
        for (const category of Object.keys(docAnnotationsStandard)) {
            docAnnotationsStandardArray.push({
                name: category,
                list: docAnnotationsStandard[category]
            })
        }
        for (const category of Object.keys(categories)) {
            categoriesArray.push({
                name: category,
                list: categories[category],
                categoryName: 'standard'
            })
        }

        //actions array Business
        for (const categoryColor of Object.keys(categoryColoursBusiness)) {
            categoryColoursBusinessArray.push({
                name: categoryColor,
                colour: categoryColoursBusiness[categoryColor],
                categoryName: 'business'
            })
        }
        for (const category of Object.keys(docAnnotationsBusiness)) {
            docAnnotationsBusinessArray.push({
                name: `doc_annotate_${category}`,
                list: docAnnotationsBusiness[category]
            })
        }

        for (const categoryColor of Object.keys(categoryColoursBusiness)) {
            categoriesBusinessArray.push({
                name: categoryColor,
                list: [{
                    tagName: categoryColor.charAt(0).toUpperCase() + categoryColor.slice(1),
                    tag: `doc_annotate_${categoryColor}`,
                }],
                categoryName: 'business'
            })
        }

        //actions array Regular Expressions
        for (const categoryColor of Object.keys(categoryColoursRegularExp)) {
            categoryColoursRegularExpArray.push({
                name: categoryColor,
                colour: categoryColoursRegularExp[categoryColor],
                categoryName: 'regular'
            })
        }
        for (const category of Object.keys(docAnnotationsRegularExp)) {
            docAnnotationsRegularExpArray.push({
                //name: `doc_annotate_${category}`,
                name: category.includes('doc_annotate_') ? category : `doc_annotate_${category}`,
                list: docAnnotationsRegularExp[category]
            })
        }

        for (const categoryColor of Object.keys(categoryColoursRegularExp)) {
            categoriesRegularExpArray.push({
                name: categoryColor,
                list: [{
                    tagName: categoryColor.charAt(0).toUpperCase() + categoryColor.slice(1),
                    tag: categoryColor.includes('doc_annotate_') ? categoryColor : `doc_annotate_${categoryColor}`,
                    //tag: categoryColor,
                }],
                categoryName: 'regular'
            })
        }
        categoryColoursArrayAll = categoriesColorsStandardArray.concat(categoryColoursBusinessArray, categoryColoursRegularExpArray)
        docAnnotationsArrayAll = docAnnotationsStandardArray.concat(docAnnotationsBusinessArray, docAnnotationsRegularExpArray)
        categoriesArrayAll = categoriesArray.concat(categoriesBusinessArray, categoriesRegularExpArray)
        markWordsOnDocumentForCategoryALL(categoryColoursArrayAll, docAnnotationsArrayAll, landscape, categoriesArrayAll)
        docAnnotationCategoriesStandard = []
        timerCheckMarkReady = setInterval(() => {
            if (global.itemsToBeMarked === global.markedTimes) {
                clearInterval(timerCheckMarkReady)
                this.props.hideLoadingBeforeMark()
                for (const categoryColor of categoryColoursArrayAll) {
                    const { result, expandedSubCategoryParent } = generateHtmlForSecurityScanResultALL(categoryColor.name, categoryColor.colour, docAnnotationsArrayAll, landscape, categoriesArrayAll, docAnnotationCategoriesStandard, categoryColor.categoryName,
                        this.props.isOcr, this.props.xeroxUpload)
                    this.props.setQueryStatus(docAnnotationCategoriesStandard)
                    expandedKeysAll = [...expandedKeysAll, ...expandedSubCategoryParent]
                    treeDataAll[0].children.push(result)
                    switch (result.categoryName) {
                        case 'standard':
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
                                    item3 && item3.children && item3.children.length > 0 && item3.children.forEach((item3, index3) => {
                                        const keyName = item3.name.replace(/\W/g, 'a')
                                        item3.indexChild = index3
                                        item3.key3 = `${keyName}-${index3}`
                                    })
                                })

                            })
                            treeDataAnnotationsStandard[0].children.push(result)
                            this.setState({
                                docAnnotationCategoriesStandard: docAnnotationCategoriesStandard
                            })
                            this.onGetStandardUpdate(treeDataAnnotationsStandard)
                            break
                        case 'business':
                            result && result.children && result.children.length > 0 && result.children.forEach((item) => {
                                result.children = item.children
                            })
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                item2.identifier = 'children-parent'

                            })
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                item2.backgroundColor = result.backgroundColor
                                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3, index3) => {
                                    const keyName = item3.name.replace(/\W/g, 'a')
                                    item3.indexChild = index3
                                    item3.key3 = `${keyName}-${index3}`
                                    item3.backgroundColor = result.backgroundColor

                                })

                            })
                            treeDataAnnotationsBusiness[0].children.push(result)
                            break
                        case 'regular':
                            result && result.children && result.children.length > 0 && result.children.forEach((item) => {
                                result.children = item.children
                            })
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                item2.identifier = 'children-parent'

                            })
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                item2.backgroundColor = result.backgroundColor
                                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3, index3) => {
                                    const keyName = item3.name.replace(/\W/g, 'a')
                                    item3.indexChild = index3
                                    item3.key3 = `${keyName}-${index3}`
                                    item3.backgroundColor = result.backgroundColor

                                })

                            })
                            treeDataAnnotationsRegEx[0].children.push(result)
                            this.allChildrenTreeRegEx(treeDataAnnotationsRegEx)
                            break
                        default: break
                    }
                    //expandedKeysAll = categoryColoursArrayAll
                    //expandedKeysAll = [ ...expandedKeysAll, ...categoryColoursArrayAll ]
                }
                if (this.props.isOcr) {
                    this.onGetRegExOcrUpdate(treeDataAnnotationsRegEx, docAnnotationsRegularExp)
                } else if (this.props.xeroxUpload) {
                    this.onGetRegExXeroxUpdate(treeDataAnnotationsRegEx, docAnnotationsRegularExp)
                } else {
                    this.onGetRegExUpdate(treeDataAnnotationsRegEx)
                }
                this.onGetRegExVersionUpdate(docAnnotationsRegularExp)
                if (this.props.isOcr) {
                    this.onGetDictionaryOcrUpdate(treeDataAnnotationsBusiness, docAnnotationsBusiness)
                } else if (this.props.xeroxUpload) {
                    this.onGetDictionaryXeroxUpdate(treeDataAnnotationsBusiness, docAnnotationsBusiness)
                } else {
                    this.onGetDictionaryUpdate(treeDataAnnotationsBusiness)
                }
            this.setState({
                treeDataAll,
                treeDataAnnotationsStandard,
                treeDataAnnotationsBusiness,
                treeDataAnnotationsRegEx,
                expandedKeysAll
            })
                global.itemsToBeMarked = 0
                global.markedTimes = 0
            }
        }, 100)
    }

    fetchDataAllCategoriesOcr = async (docId, clientId, userId, docId2, allPagesRendered) => {
        const { listAllDocAnnotationOcr } = this.state
        let responseStandard1
        let responseStandard2
        let responseBusiness1
        let responseBusiness2
        let responseRegEx1
        let responseRegEx2
        if (this.props.isSmartBoxApplication) {
            const getColoredRectanglesDTO = {
                docId: this.props.idDocument,
                categories: listAllDocAnnotationOcr,
                //solrIp: '18.157.167.245'
            }
            responseStandard1 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
            //responseStandard1 = await getSmartBoxScanAnnotations(docId)
            responseBusiness1 = await newGetBusinessTermWords(docId2)
            responseRegEx1 = await newGetRegexWords(docId2)

            responseStandard2 = await getAnnotationColorsByClientId(clientId, userId)
            responseBusiness2 = await getBusinessCategoryColor(docId2)
            responseRegEx2 = await getRegexCategoryColor(docId2)
            this.props.onDisabledNextDocument(false)
            this.props.onDisabledBackDocument(false)
        }

        this.onGetStatusResponseQuery(responseStandard1.status)
        this.onDocAnnotationsOcr(responseStandard1.data.wordsPII)
        if (responseStandard1.data.wordsPII && Object.keys(responseStandard1.data.wordsPII).length === 0 && this.props.docAnnotationsRegEx && this.props.docAnnotationsList && Object.keys(this.props.docAnnotationsRegEx).length === 0 && Object.keys(this.props.docAnnotationsList).length === 0) {
            notification.info({
                message: `No Ai results found`,
                className: 'no-ai-results-found',
                duration: 3
            })
        }
        let statusResponseStandard = null
        if (responseStandard1.status === 200) {
            statusResponseStandard = true
        } else {
            statusResponseStandard = false
        }
        this.setState({
            docAnnotationsStandard: responseStandard1.data.wordsPII,
            docAnnotationsOCR: responseStandard1.data.wordsPII,
            docAnnotationsXerox: responseStandard1.data.wordsPII,

            docAnnotationsColorsStandard: responseStandard2.data,
            categoriesColorsStandard: {
                names: responseStandard2.data.filter((annColor) => annColor.category === 'names')[0].color,
                locations: responseStandard2.data.filter((annColor) => annColor.category === 'locations')[0].color,
                numbers: responseStandard2.data.filter((annColor) => annColor.category === 'numbers')[0].color,
                time: responseStandard2.data.filter((annColor) => annColor.category === 'time')[0].color,
                addresses: responseStandard2.data.filter((annColor) => annColor.category === 'addresses')[0].color,
                identifiers: responseStandard2.data.filter((annColor) => annColor.category === 'identifiers')[0].color,
                organisations: responseStandard2.data.filter((annColor) => annColor.category === 'organisations')[0].color
            },
            docAnnotationsBusiness: responseBusiness1.data,
            docAnnotationsBusinessOcr: responseBusiness1.data,
            docAnnotationsBusinessXerox: responseBusiness1.data,

            categoryColoursBusiness: responseBusiness2.data,

            docAnnotationsRegularExp: responseRegEx1.data,
            docAnnotationsRegularExpOcr: responseRegEx1.data,
            docAnnotationsRegularXerox: responseRegEx1.data,

            categoryColoursRegularExp: responseRegEx2.data,

            fetchResponseLoadingStandard: statusResponseStandard
        }, () => {
            if (responseStandard1.status === 200 && responseBusiness1.status === 200 && responseRegEx1.status === 200) {
                this.renderAllCategoriesSections()
            }
        })
    }

    fetchNextPreviousAllCategories = async (docId, clientId, userId, docId2, allPagesRendered) => {
      let { treeDataAnnotationsStandard, treeDataAnnotationsBusiness, treeDataAnnotationsRegEx, treeDataAll, checkedKeysObjectRefs, checkedKeysObjectRefsList, checkedKeysObjectRefsBoxTerms,
          checkedKeysObjectRefsRegEx, docAnnotationsStandard, docAnnotationsColorsStandard, expandedKeysAll, docAnnotationsBusiness, categoryColoursBusiness,
          docAnnotationsRegularExp, categoryColoursRegularExp, docAnnotationsBusinessOcr, docAnnotationsRegularExpOcr} = this.state
        treeDataAnnotationsStandard[0].children = []
        treeDataAnnotationsBusiness[0].children = []
        treeDataAnnotationsRegEx[0].children = []
        treeDataAll[0].children = []
        checkedKeysObjectRefs = []
        checkedKeysObjectRefsList = []
        checkedKeysObjectRefsBoxTerms = []
        checkedKeysObjectRefsRegEx = []
        docAnnotationsStandard = []
        docAnnotationsColorsStandard = []
        expandedKeysAll = []
        docAnnotationsBusiness = {}
        docAnnotationsBusinessOcr = {}
        categoryColoursBusiness = {}
        docAnnotationsRegularExp = {}
        docAnnotationsRegularExpOcr = {}
        categoryColoursRegularExp = {}
        let responseStandard1
        let responseStandard2
        let responseBusiness1
        let responseBusiness2
        let responseRegEx1
        let responseRegEx2
        if (this.props.isSmartBoxApplication) {
            responseStandard1 = await getSmartBoxScanAnnotations(docId)
            responseBusiness1 = await newGetBusinessTermWords(docId2)
            responseRegEx1 = await newGetRegexWords(docId2)

            responseStandard2 = await getAnnotationColorsByClientId(clientId, userId)
            responseBusiness2 = await getBusinessCategoryColor(docId2)
            responseRegEx2 = await getRegexCategoryColor(docId2)
            this.props.onDisabledNextDocument(false)
            this.props.onDisabledBackDocument(false)
        }
        this.onGetStatusResponseQuery(responseStandard1.status)
        this.onDocAnnotationsOcr(responseStandard1.data)
        if (responseStandard1.data && Object.keys(responseStandard1.data).length === 0 && this.props.docAnnotationsRegEx && Object.keys(this.props.docAnnotationsRegEx).length === 0 && this.props.docAnnotationsList && Object.keys(this.props.docAnnotationsList).length === 0) {
            //CustomNotificationManager.error('No AI annotations available for this document, yet.', 'No annotations available')
            notification.info({
                message: `No Ai results found`,
                className: 'no-ai-results-found',
                duration: 3
            })
        }

        let statusResponseStandard = null
        if (responseStandard1.status === 200) {
            statusResponseStandard = true
        } else {
            statusResponseStandard = false
        }
        // if (responseStandard1.data && Object.keys(responseStandard1.data).length === 0) {
        //     statusResponseStandard = false
        // } else {
        //     statusResponseStandard = true
        // }

        this.setState({
            docAnnotationsStandard: responseStandard1.data,
            //docAnnotationsOCR: responseStandard1.data,
            docAnnotationsColorsStandard: responseStandard2.data,
            categoriesColorsStandard: {
                names: responseStandard2.data.filter((annColor) => annColor.category === 'names')[0].color,
                locations: responseStandard2.data.filter((annColor) => annColor.category === 'locations')[0].color,
                numbers: responseStandard2.data.filter((annColor) => annColor.category === 'numbers')[0].color,
                time: responseStandard2.data.filter((annColor) => annColor.category === 'time')[0].color,
                addresses: responseStandard2.data.filter((annColor) => annColor.category === 'addresses')[0].color,
                identifiers: responseStandard2.data.filter((annColor) => annColor.category === 'identifiers')[0].color,
                organisations: responseStandard2.data.filter((annColor) => annColor.category === 'organisations')[0].color
            },
            docAnnotationsBusiness: responseBusiness1.data,
            docAnnotationsBusinessOcr: responseBusiness1.data,
            //docAnnotationsBusiness: { silviu :['06/06/2023,16:38', '06/06/202316:38', 'ddd']},
            categoryColoursBusiness: responseBusiness2.data,
            //categoryColoursBusiness: { silviu: "rgba(192, 125, 81, 0.3)" },

            docAnnotationsRegularExp: responseRegEx1.data,
            docAnnotationsRegularExpOcr: responseRegEx1.data,
            //docAnnotationsRegularExp:  { dragos :['dra', 'yahoo']},
            categoryColoursRegularExp: responseRegEx2.data,
            //categoryColoursRegularExp: { dragos: "rgba(255, 255, 255, 0.3)" },

            fetchResponseLoadingStandard: statusResponseStandard
        }, () => {
            this.renderAllCategoriesSections()
            // if (allPagesRendered || Object.keys(responseStandard1.data).length) {
            //     this.renderAllCategoriesSections()
            //     }
        })
    }

    fetchNextPreviousAllCategoriesOcr = async (docId, clientId, userId, docId2, allPagesRendered) => {
        let { treeDataAnnotationsStandard, treeDataAnnotationsBusiness, treeDataAnnotationsRegEx, treeDataAll, checkedKeysObjectRefs, checkedKeysObjectRefsList, checkedKeysObjectRefsBoxTerms,
            checkedKeysObjectRefsRegEx, docAnnotationsStandard, docAnnotationsColorsStandard, expandedKeysAll, docAnnotationsBusiness, categoryColoursBusiness,
            docAnnotationsRegularExp, categoryColoursRegularExp, docAnnotationsBusinessOcr, docAnnotationsRegularExpOcr} = this.state
        const { listAllDocAnnotationOcr } = this.state
        treeDataAnnotationsStandard[0].children = []
        treeDataAnnotationsBusiness[0].children = []
        treeDataAnnotationsRegEx[0].children = []
        treeDataAll[0].children = []
        checkedKeysObjectRefs = []
        checkedKeysObjectRefsList = []
        checkedKeysObjectRefsBoxTerms = []
        checkedKeysObjectRefsRegEx = []
        docAnnotationsStandard = []
        docAnnotationsColorsStandard = []
        expandedKeysAll = []
        docAnnotationsBusiness = {}
        docAnnotationsBusinessOcr = {}
        categoryColoursBusiness = {}
        docAnnotationsRegularExp = {}
        docAnnotationsRegularExpOcr = {}
        categoryColoursRegularExp = {}
        let responseStandard1
        let responseStandard2
        let responseBusiness1
        let responseBusiness2
        let responseRegEx1
        let responseRegEx2
        if (this.props.isSmartBoxApplication) {
            const getColoredRectanglesDTO = {
                docId: this.props.idDocument,
                categories: listAllDocAnnotationOcr,
                //solrIp: '18.157.167.245'
            }
            responseStandard1 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
            //responseStandard1 = await getSmartBoxScanAnnotations(docId)
            responseBusiness1 = await newGetBusinessTermWords(docId2)
            responseRegEx1 = await newGetRegexWords(docId2)

            responseStandard2 = await getAnnotationColorsByClientId(clientId, userId)
            responseBusiness2 = await getBusinessCategoryColor(docId2)
            responseRegEx2 = await getRegexCategoryColor(docId2)
            this.props.onDisabledNextDocument(false)
            this.props.onDisabledBackDocument(false)
        }
        this.onGetStatusResponseQuery(responseStandard1.status)
        this.onDocAnnotationsOcr(responseStandard1.data.wordsPII)
        if (responseStandard1.data.wordsPII && Object.keys(responseStandard1.data.wordsPII).length === 0 && this.props.docAnnotationsRegEx && Object.keys(this.props.docAnnotationsRegEx).length === 0 && this.props.docAnnotationsList && Object.keys(this.props.docAnnotationsList).length === 0) {
            //CustomNotificationManager.error('No AI annotations available for this document, yet.', 'No annotations available')
            notification.info({
                message: `No Ai results found`,
                className: 'no-ai-results-found',
                duration: 3
            })
        }

        let statusResponseStandard = null
        if (responseStandard1.status === 200) {
            statusResponseStandard = true
        } else {
            statusResponseStandard = false
        }

        this.setState({
            docAnnotationsStandard: responseStandard1.data.wordsPII,
            docAnnotationsColorsStandard: responseStandard2.data,
            categoriesColorsStandard: {
                names: responseStandard2.data.filter((annColor) => annColor.category === 'names')[0].color,
                locations: responseStandard2.data.filter((annColor) => annColor.category === 'locations')[0].color,
                numbers: responseStandard2.data.filter((annColor) => annColor.category === 'numbers')[0].color,
                time: responseStandard2.data.filter((annColor) => annColor.category === 'time')[0].color,
                addresses: responseStandard2.data.filter((annColor) => annColor.category === 'addresses')[0].color,
                identifiers: responseStandard2.data.filter((annColor) => annColor.category === 'identifiers')[0].color,
                organisations: responseStandard2.data.filter((annColor) => annColor.category === 'organisations')[0].color
            },
            docAnnotationsBusiness: responseBusiness1.data,
            docAnnotationsBusinessOcr: responseBusiness1.data,
            categoryColoursBusiness: responseBusiness2.data,

            docAnnotationsRegularExp: responseRegEx1.data,
            docAnnotationsRegularExpOcr: responseRegEx1.data,
            categoryColoursRegularExp: responseRegEx2.data,

            fetchResponseLoadingStandard: statusResponseStandard
        }, () => {
            this.renderAllCategoriesSections()
        })
    }

    onGetRegExUpdate = (regList) => {
      const childrenReg = []
      const childrenRegParent = []
        regList && regList.length > 0 && regList[0].children && regList[0].children.length > 0 && regList[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                childrenRegParent.push(child2)
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    childrenReg.push(child3)
                })
            })
        })
        this.setState({
            getAllChildrenRegExUpdate: childrenReg,
            getAllChildrenRegExUpdateParent: childrenRegParent,
            regExListUpdate: regList
        })
    }
    onGetRegExOcrUpdate = (regList, docAnnotationsRegularExp) => {
        const childrenReg = []
        regList && regList[0].children && regList[0].children.length > 0 && regList[0].children.forEach((children1) => {
            if (docAnnotationsRegularExp && Object.keys(docAnnotationsRegularExp).length) {
                Object.keys(docAnnotationsRegularExp).forEach((category) => {
                    let title = category && category.replace('doc_annotate_', '')
                    title = title && title.replace('_', ' ')
                    if (children1.key === title) {
                        docAnnotationsRegularExp[category] && docAnnotationsRegularExp[category].length > 0 && docAnnotationsRegularExp[category].forEach((nameParent, index) => {
                            const parentAllCategory = {
                                backgroundColor: children1.backgroundColor,
                                children: [],
                                key: `${ nameParent }`,
                                identifier: 'children',
                                name: nameParent,
                                index: index,
                                title: nameParent,
                                pageNumber: '1',
                                isOcr: this.props.isOcr
                            }
                            childrenReg.push(parentAllCategory)
                        })
                    }
                })
            }

        })
        this.setState({
            getAllChildrenRegExUpdate: childrenReg,
            regExListUpdate: regList
        })
    }

    onGetRegExXeroxUpdate = (regList, docAnnotationsRegularExp) => {
        const childrenReg = []
        regList && regList[0].children && regList[0].children.length > 0 && regList[0].children.forEach((children1) => {
            if (docAnnotationsRegularExp && Object.keys(docAnnotationsRegularExp).length) {
                Object.keys(docAnnotationsRegularExp).forEach((category) => {
                    let title = category && category.replace('doc_annotate_', '')
                    title = title && title.replace('_', ' ')
                    if (children1.key === title) {
                        docAnnotationsRegularExp[category] && docAnnotationsRegularExp[category].length > 0 && docAnnotationsRegularExp[category].forEach((nameParent, index) => {
                            const parentAllCategory = {
                                backgroundColor: children1.backgroundColor,
                                children: [],
                                key: `${ nameParent }`,
                                identifier: 'children',
                                name: nameParent,
                                index: index,
                                title: nameParent,
                                pageNumber: '1',
                                isOcr: this.props.isOcr
                            }
                            childrenReg.push(parentAllCategory)
                        })
                    }
                })
            }

        })
        this.setState({
            getAllChildrenRegExUpdate: childrenReg,
            regExListUpdate: regList
        })
    }

    onGetRegExVersionUpdate = (regExListVersionUpdate) => {
      this.setState({
          regExListVersionUpdate: regExListVersionUpdate
      })
    }

    onGetDictionaryUpdate = (dictList) => {
        const childrenBusiness = []
        const childrenBusinessParent = []
        dictList && dictList[0].children && dictList[0].children.length > 0 && dictList[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                childrenBusinessParent.push(child2)
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    childrenBusiness.push(child3)
                })
            })
        })
        this.setState({
            dictionaryListUpdate: dictList,
            getAllChildrenBusinessUpdate: childrenBusiness,
            getAllChildrenBusinessParent: childrenBusinessParent,
        })
    }
    onGetStandardUpdate = (standardList) => {
        const childrenStandard = []
        standardList && standardList[0].children && standardList[0].children.length > 0 && standardList[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    child3 && child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                        childrenStandard.push(child4)
                    })
                })
            })
        })
        this.setState({
            getAllChildrenStandardUpdate: childrenStandard
        })
    }
    onGetDictionaryOcrUpdate = (dictList, docAnnotationsBusiness) => {
        const childrenBusiness = []
        let newBusinessOcr2 = []
         newBusinessOcr2 = dictList
        if (this.props.isOcr) {
            newBusinessOcr2 && newBusinessOcr2[0].children && newBusinessOcr2[0].children.length > 0 && newBusinessOcr2[0].children.forEach((children1) => {
                if (docAnnotationsBusiness && Object.keys(docAnnotationsBusiness).length) {
                    Object.keys(docAnnotationsBusiness).forEach((category) => {
                        let title = category && category.replace('doc_annotate_', '')
                        title = title && title.replace('_', ' ')
                        if (children1.key === title) {
                            docAnnotationsBusiness[category] && docAnnotationsBusiness[category].length > 0 && docAnnotationsBusiness[category].forEach((nameParent, index) => {
                                const parentAllCategory = {
                                    backgroundColor: children1.backgroundColor,
                                    children: [],
                                    key: `${nameParent}`,
                                    name: nameParent,
                                    index: index,
                                    title: nameParent,
                                    pageNumber: '1',
                                    isOcr: this.props.isOcr
                                }
                                childrenBusiness.push(parentAllCategory)
                            })
                        }
                    })
                }
            })
            this.setState({
                dictionaryListUpdate: dictList,
                getAllChildrenBusinessUpdate: childrenBusiness
            })
        }
    }

    onGetDictionaryXeroxUpdate = (dictList, docAnnotationsBusiness) => {
        const childrenBusiness = []
        let newBusinessOcr2 = []
        newBusinessOcr2 = dictList
        if (this.props.xeroxUpload) {
            newBusinessOcr2 && newBusinessOcr2[0].children && newBusinessOcr2[0].children.length > 0 && newBusinessOcr2[0].children.forEach((children1) => {
                if (docAnnotationsBusiness && Object.keys(docAnnotationsBusiness).length) {
                    Object.keys(docAnnotationsBusiness).forEach((category) => {
                        let title = category && category.replace('doc_annotate_', '')
                        title = title && title.replace('_', ' ')
                        if (children1.key === title) {
                            docAnnotationsBusiness[category] && docAnnotationsBusiness[category].length > 0 && docAnnotationsBusiness[category].forEach((nameParent, index) => {
                                const parentAllCategory = {
                                    backgroundColor: children1.backgroundColor,
                                    children: [],
                                    key: `${nameParent}`,
                                    name: nameParent,
                                    index: index,
                                    title: nameParent,
                                    pageNumber: '1',
                                    isOcr: this.props.isOcr
                                }
                                childrenBusiness.push(parentAllCategory)
                            })
                        }
                    })
                }
            })
            this.setState({
                dictionaryListUpdate: dictList,
                getAllChildrenBusinessUpdate: childrenBusiness
            })
        }
    }


    onSelectTab = (activeKey) => {
   this.setState({
     activeKey: activeKey
   })
 }
     onGetStatusResponseQuery = (status) => {
        this.setState({
            responseStatusQuery: status
        })
     }

 onCheckedKeysObjectRefs = (checkedKeysObject) => {
     const { checkedKeysObjectRefsList, checkedKeysObjectRefsBoxTerms, checkedKeysObjectRefsRegEx } = this.state
     this.setState({
         //checkedKeysObjectRefs: checkedKeysObject
         checkedKeysObjectRefs: checkedKeysObject ? checkedKeysObject.concat(checkedKeysObjectRefsList, checkedKeysObjectRefsBoxTerms, checkedKeysObjectRefsRegEx) : ''
     })

 }
    onCheckedKeysObjectRefsList = (checkedKeysObject) => {
        let { checkedKeysObjectRefs, checkedKeysObjectRefsList } = this.state
        checkedKeysObjectRefs = checkedKeysObjectRefs && checkedKeysObjectRefs.filter((redChild) => !checkedKeysObjectRefsList.map((red) => red.key).includes(redChild.key))
        this.setState({
            checkedKeysObjectRefsList: checkedKeysObject,
            checkedKeysObjectRefs: checkedKeysObjectRefs.concat(checkedKeysObject)
        })
    }
    onCheckedKeysObjectRefsBoxTerms = (checkedKeysObject) => {
      let { checkedKeysObjectRefs, checkedKeysObjectRefsBoxTerms } = this.state
        checkedKeysObjectRefs = checkedKeysObjectRefs && checkedKeysObjectRefs.filter((redChild) => !checkedKeysObjectRefsBoxTerms.map((red) => red.key).includes(redChild.key))
        this.setState({
            checkedKeysObjectRefsBoxTerms: checkedKeysObject,
            checkedKeysObjectRefs: checkedKeysObjectRefs.concat(checkedKeysObject)
        })
    }
    onCheckedKeysObjectRefsRegEx = (checkedKeysObject) => {
        let { checkedKeysObjectRefs, checkedKeysObjectRefsRegEx } = this.state
        checkedKeysObjectRefs = checkedKeysObjectRefs && checkedKeysObjectRefs.filter((redChild) => !checkedKeysObjectRefsRegEx.map((red) => red.key).includes(redChild.key))
        this.setState({
            checkedKeysObjectRefsRegEx: checkedKeysObject,
            checkedKeysObjectRefs: checkedKeysObjectRefs.concat(checkedKeysObject)
        })
    }

    allChildrenTree = (tree) => {
        this.setState({
            treeStandard: tree
        })
    }
    allChildrenTreeBusiness = (tree) => {
        this.setState({
            treeBusiness: tree
        })
    }
    allChildrenTreeRegEx = (tree) => {
        this.setState({
            treeRegEx: tree
        })
    }
    onDocAnnotationsRegEx = (doc) => {
      this.setState({
          docAnnotationsRegEx: doc
      }, () => {
          this.setState({
              docAnnotationsRegEx: doc
          })
      })
    }
    onDocAnnotationsList = (doc) => {
        this.setState({
            docAnnotationsList: doc
        }, () => {
            this.setState({
                docAnnotationsList: doc
            })
        })
    }
    onDocAnnotationsOcr = (doc) => {
        this.setState({
            docAnnotationsOcr: doc
        })
    }
    onCheckAiAnnotations = (status) => {
      this.setState({
          checkAiStandard: status
      })
    }
    onRedactAllCategories = (e) => {
      const { treeStandard, treeBusiness, treeRegEx, listCategories } = this.state
        const selectAllItems = []
        const selectAllItemsBusiness = []
        const selectAllItemsRegEx = []
        let allWordsRedacted = []
        // if (checkAiStandard) {
            treeStandard && treeStandard.length > 0 && treeStandard[0].children && treeStandard[0].children.length > 0 && treeStandard[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        child3 && child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                            child4.categoryName = child.key
                            child4.subcategoryName = child2.key
                            selectAllItems.push(child4)
                        })
                    })
                })
            })
        treeBusiness && treeBusiness.length > 0 && treeBusiness[0].children && treeBusiness[0].children.length > 0 && treeBusiness[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    child3.categoryName = 'Dictionaries'
                    child3.subcategoryName = child.key
                    selectAllItemsBusiness.push(child3)
                })
            })
        })
        treeRegEx && treeRegEx.length > 0 && treeRegEx[0].children && treeRegEx[0].children.length > 0 && treeRegEx[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    child3.categoryName = 'Regular Expressions'
                    child3.subcategoryName = child.key
                    selectAllItemsRegEx.push(child3)
                })
            })
        })

        allWordsRedacted = selectAllItems.concat(selectAllItemsBusiness, selectAllItemsRegEx)
            if (selectAllItems && selectAllItems.length > 0) {
                this.setState({
                    finalChildrenStatus: selectAllItems,
                    finalChildrenStandard: selectAllItems,

                })
                listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
                   elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                       allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                            if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                                item.categoryColor = elem2.subcategoryColorWithAlpha
                            }
                       })
                   })
                })
                this.onRedactAiAllTag(allWordsRedacted, selectAllItems, selectAllItemsBusiness, selectAllItemsRegEx)
            }
        // }
    }
    onRedactAllOcr = (e) => {
      const { docAnnotationsOcr, docAnnotationsBusinessOcr, docAnnotationsRegularExpOcr } = this.state
        const listSavedNameOcr = []
        const listSavedNameRegExOcr = []
        const listSavedNameBusinessOcr = []
        let listSavedNameALlOcr = []
        if (docAnnotationsOcr && Object.keys(docAnnotationsOcr).length) {
            Object.keys(docAnnotationsOcr).forEach((category) => {
                //let title = category && category.replace('doc_annotate_', '')
                //title = title && title.replace('_', ' ')
                docAnnotationsOcr[category] && docAnnotationsOcr[category].length > 0 && docAnnotationsOcr[category].forEach((nameParent, index) => {
                            listSavedNameOcr.push(nameParent)
                })
            })
        }
        if (docAnnotationsRegularExpOcr && Object.keys(docAnnotationsRegularExpOcr).length) {
            Object.keys(docAnnotationsRegularExpOcr).forEach((category) => {
                docAnnotationsRegularExpOcr[category] && docAnnotationsRegularExpOcr[category].length > 0 && docAnnotationsRegularExpOcr[category].forEach((nameParent, index) => {
                    listSavedNameRegExOcr.push(nameParent)
                })
            })
        }
        if (docAnnotationsBusinessOcr && Object.keys(docAnnotationsBusinessOcr).length) {
            Object.keys(docAnnotationsBusinessOcr).forEach((category) => {
                docAnnotationsBusinessOcr[category] && docAnnotationsBusinessOcr[category].length > 0 && docAnnotationsBusinessOcr[category].forEach((nameParent, index) => {
                    listSavedNameBusinessOcr.push(nameParent)
                })
            })
        }
        listSavedNameALlOcr = listSavedNameOcr.concat(listSavedNameRegExOcr, listSavedNameBusinessOcr)
        this.drawMatchedAllWordsOcr(listSavedNameALlOcr)
    }
    drawMatchedAllWordsOcr = (classList) => {
        const textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                this.onSavedOcr(classList)
            }
        })
    }
    onSavedOcr = (classList) => {
      const { finalChildrenStatus, finalChildrenStandard, treeStandard, treeBusiness, treeRegEx, treeDataAll } = this.state
        const newFinalChildren = []
        const newFinalChildrenTwo = []
        const ocrList = []
        const newAllChildrenOcr = []
        const newAllStandardChildrenOcr = []
        const newAllDictionaryChildrenOcr = []
        const newAllRegExChildrenOcr = []
        treeDataAll && treeDataAll.length > 0 && treeDataAll[0].children && treeDataAll[0].children.length > 0 && treeDataAll[0].children.forEach((child) => {
            // newAllChildrenOcr.push({
            //     category: child.categoryName === 'regular' ? 'Regular Expressions' : child.categoryName === 'business' ? 'Dictionary' : child.key
            // })
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                if (child.categoryName === 'regular' || child.categoryName === 'business') {
                    newAllChildrenOcr.push({
                        category: child.categoryName === 'regular' ? 'Regular Expressions' : child.categoryName === 'business' ? 'Dictionary' : '',
                        subcategory: child.key,
                        word: child2.key,
                    })
                }
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    if (child.categoryName !== 'regular' || child.categoryName !== 'business') {
                        newAllChildrenOcr.push({
                            category: child.key,
                            subcategory: child2.key,
                            word: child3.key,
                        })
                    }
                })
            })
        })
        finalChildrenStatus && finalChildrenStatus.length > 0 && finalChildrenStatus.forEach((newChildren2) => {
            newFinalChildren.push(`${ newChildren2.key } => ${ newChildren2.name } => ${ newChildren2.pageNumber }`)
        })
        finalChildrenStandard && finalChildrenStandard.length > 0 && finalChildrenStandard.forEach((newChildren2) => {
            newFinalChildrenTwo.push(`${ newChildren2.key } => ${ newChildren2.name } => ${ newChildren2.pageNumber }`)
        })
        classList && classList.length > 0 && classList.forEach((list) => {
            ocrList.push(`${ list }-1 => ${ list } => 1`)
        })
        const data = {
            idDocument: this.props.idDocument,
            //keyWordOcr: classList,
            keyWordOcr: newAllChildrenOcr,
            keyWordNonOcr: newFinalChildrenTwo,
            //solrIp: '18.157.167.245'
        }
        apiSmartBoxSaveNewQueryStatusOcr(data).then((response) => {
            if (response.status === 200) {
                //this.props.fetchSmartBoxDataCanvasOcr()
                this.onCheckAllCanvasPoints()
            }

        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }


    onRedactAllXerox = (e) => {
        const { docAnnotationsOcr, docAnnotationsBusinessOcr, docAnnotationsRegularExpOcr } = this.state
        const listSavedNameOcr = []
        const listSavedNameRegExOcr = []
        const listSavedNameBusinessOcr = []
        let listSavedNameALlOcr = []
        if (docAnnotationsOcr && Object.keys(docAnnotationsOcr).length) {
            Object.keys(docAnnotationsOcr).forEach((category) => {
                //let title = category && category.replace('doc_annotate_', '')
                //title = title && title.replace('_', ' ')
                docAnnotationsOcr[category] && docAnnotationsOcr[category].length > 0 && docAnnotationsOcr[category].forEach((nameParent, index) => {
                    listSavedNameOcr.push(nameParent)
                })
            })
        }
        if (docAnnotationsRegularExpOcr && Object.keys(docAnnotationsRegularExpOcr).length) {
            Object.keys(docAnnotationsRegularExpOcr).forEach((category) => {
                docAnnotationsRegularExpOcr[category] && docAnnotationsRegularExpOcr[category].length > 0 && docAnnotationsRegularExpOcr[category].forEach((nameParent, index) => {
                    listSavedNameRegExOcr.push(nameParent)
                })
            })
        }
        if (docAnnotationsBusinessOcr && Object.keys(docAnnotationsBusinessOcr).length) {
            Object.keys(docAnnotationsBusinessOcr).forEach((category) => {
                docAnnotationsBusinessOcr[category] && docAnnotationsBusinessOcr[category].length > 0 && docAnnotationsBusinessOcr[category].forEach((nameParent, index) => {
                    listSavedNameBusinessOcr.push(nameParent)
                })
            })
        }
        listSavedNameALlOcr = listSavedNameOcr.concat(listSavedNameRegExOcr, listSavedNameBusinessOcr)
        this.drawMatchedAllWordsXerox(listSavedNameALlOcr)
    }
    drawMatchedAllWordsXerox = (classList) => {
        const textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                this.onSavedXerox(classList)
            }
        })
    }

    onSavedXerox = (classList) => {
        const { finalChildrenStatus, finalChildrenStandard } = this.state
        const newFinalChildren = []
        const newFinalChildrenTwo = []
        const ocrList = []
        finalChildrenStatus && finalChildrenStatus.length > 0 && finalChildrenStatus.map((newChildren2) => {
            newFinalChildren.push(`${ newChildren2.key } => ${ newChildren2.name } => ${ newChildren2.pageNumber }`)
            return true
        })
        finalChildrenStandard && finalChildrenStandard.length > 0 && finalChildrenStandard.map((newChildren2) => {
            newFinalChildrenTwo.push(`${ newChildren2.key } => ${ newChildren2.name } => ${ newChildren2.pageNumber }`)
            return true
        })
        classList && classList.length > 0 && classList.forEach((list) => {
            ocrList.push(`${ list }-1 => ${ list } => 1`)
        })
        const data = {
            docId: this.props.idDocument,
            clientId: this.props.clientId,
            wordsToRedact: classList,
        }
        apiSmartBoxSaveXeroxRedactionSmartView(data).then((response) => {
            if (response.status === 200) {
                //this.props.fetchSmartBoxDataCanvasOcr()
                //this.onCheckAllCanvasPoints()
                //this.onCheckAllCanvasPointsXerox()
                this.props.onGetCanvasPointsXeroxRedaction(response.data)
            }

        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }

    onCheckAllCanvasPointsXerox = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        //this.props.fetchSmartBoxDataCanvasOcr()

        // apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
        //     this.setState({
        //         responseCheckPdfCanvas: response.data
        //     })
        //     if(response.data === 'Processing') {
        //         this.setIntervalCheckAllCanvasPoints()
        //     } else {
        //         this.props.fetchSmartBoxDataCanvasOcr()
        //     }
        // })
    }

    onCheckAllCanvasPoints = () => {
      const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
            this.setState({
                responseCheckPdfCanvas: response.data
            })
            if(response.data === 'Processing') {
              this.setIntervalCheckAllCanvasPoints()
            } else {
                this.props.fetchSmartBoxDataCanvasOcr()
            }
        })
    }

    setIntervalCheckAllCanvasPoints = () => {
        const { responseCheckPdfCanvas} = this.state
        let { setIntervalCheckPdfCanvas} = this.state
        if (setIntervalCheckPdfCanvas === 0) {
            if (responseCheckPdfCanvas === 'Processing') {
                setIntervalCheckPdfCanvas = setInterval(this.callBackCheckAllCanvasPoints, 5000)
                this.setState({
                    setIntervalCheckPdfCanvas
                })
            } else {
                clearInterval(setIntervalCheckPdfCanvas)
            }
        }
    }

    callBackCheckAllCanvasPoints = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        if(responseCheckPdfCanvas === 'Processing') {
            apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
                this.setState({
                    responseCheckPdfCanvas: response.data
                })
                if (response.data === 'Finished') {
                    this.props.fetchSmartBoxDataCanvasOcr()
                }
            })
        }
    }
    onUpdateRedactionNumber = (classList) => {
        const data = {
            doc_id: this.props.idDocument,
            box_number_words_redacted: classList.length
        }
        apiSmartBoxUpdateRedactionNumber(data).then((response) => {

        })
    }
    onSaveUpdateQueryStatusStandard = () => {
        const { finalChildrenStatus } = this.state
        let updateStatus = false
        const newFinalChildren = []
        const checkStatusChildren = []
        finalChildrenStatus && finalChildrenStatus.length > 0 && finalChildrenStatus.map((newChildren2) => {
            newFinalChildren.push(`${ newChildren2.key } => ${ newChildren2.name } => ${ newChildren2.pageNumber }`)
            checkStatusChildren.push(newChildren2.key)
            return true
        })
        if (checkStatusChildren && checkStatusChildren.length > 0) {
            updateStatus = true
        } else {
            updateStatus = false
        }
        const data = {
            idDocument: this.props.idDocument,
            keyWordNonOcr: newFinalChildren,
            keyWordOcr: [],
            updateRedactions: updateStatus
        }
        if (this.props.isSmartBoxApplication && !this.props.statusSaveQuery) {
            apiSmartBoxSaveNewQueryStatus(data).then((response) => {
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        }
    }

    onSaveKeysBusiness = () => {
        const { treeBusiness } = this.state
        const selectedKeys = []
        treeBusiness && treeBusiness[0].children.forEach((item) => {
            item && item.children.forEach((item2) => {
                item2 && item2.children.forEach((item3) => {
                            selectedKeys.push(`${ item3.key } => ${ item3.name } => ${ item3.pageNumber }`)
                })
            })
        })
        const data = {
            id: this.props.idDocument,
            listKeys: selectedKeys
        }
        saveKeysRedactedBusiness(data).then(() => {

        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }

    onSaveKeysRedacted = () => {
        const { treeRegEx } = this.state
        const selectedKeys = []
        treeRegEx && treeRegEx[0].children.forEach((item) => {
            item && item.children.forEach((item2) => {
                item2 && item2.children.forEach((item3) => {
                            selectedKeys.push(`${ item3.key } => ${ item3.name } => ${ item3.pageNumber }`)
                })
            })
        })
        const data = {
            id: this.props.idDocument,
            listKeys: selectedKeys
        }
        saveKeysRedactedOnRegEx(data).then(() => {

        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    onRedactAiAllTag = (checkedKeysObjectRefs, selectAllItems, selectAllItemsBusiness, selectAllItemsRegEx) => {
        if (checkedKeysObjectRefs && checkedKeysObjectRefs.length > 0) {
            const sortedByPageItems = checkedKeysObjectRefs.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            const sortedByPageItemsStandard = selectAllItems.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            const sortedByPageItemsBusiness = selectAllItemsBusiness.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            const sortedByPageItemsRegEx = selectAllItemsRegEx.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            this.drawMatchedAllWords(sortedByPageItems.map((item) => item.key), sortedByPageItemsStandard.map((item) => item.key),
                sortedByPageItemsBusiness.map((item) => item.key), sortedByPageItemsRegEx.map((item) => item.key), checkedKeysObjectRefs)
        }
    }
    drawMatchedAllWords = (classList, classListStandard, classListBusiness, classListRegEx, checkedKeysObjectRefs) => {
      const { treeBusiness, treeStandard, treeRegEx } = this.state
      const { alreadyRedactedKeys, statusRedactAll } = this.props
        const unCheckedKeys = []
        let extractAllRedactedKeys = []
        if (alreadyRedactedKeys && Object.keys(alreadyRedactedKeys) && Object.keys(alreadyRedactedKeys).length > 0) {
            extractAllRedactedKeys = alreadyRedactedKeys.standard.concat(alreadyRedactedKeys.business, alreadyRedactedKeys.regex)
        }

        classList && classList.length > 0 && classList.forEach((item) => {
            if(!extractAllRedactedKeys.includes(item)) {
            }
        })
        // const uniqueExtractAllRedactedKeys = [...new Set(extractAllRedactedKeys)]
        const textMessageRedact = statusRedactAll === "true" ? `Remove all ${ classList.length } selected?` :
            classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
        let classListDictRegEx = []
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: statusRedactAll === "true"  ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                if (statusRedactAll !== 'true')
                {
                    this.onSaveUpdateQueryStatusStandard()
                    this.onSaveKeysBusiness()
                    this.onSaveKeysRedacted()
                    this.onUpdateRedactionNumber(classList)
                }
                this.setState({
                    redactedKeys: []
                }, () => {
                    let classAllNameList = []
                    classAllNameList = classListStandard.concat(classListBusiness, classListRegEx)
                    this.props.drawRedactAllMatchedWords(classAllNameList, classListStandard, classListBusiness, classListRegEx, extractAllRedactedKeys, 'standard', checkedKeysObjectRefs)
                    // if (treeStandard && treeStandard.length > 0) {
                    //     this.props.drawAllMatchedWordsByClassList(classListStandard, [], 'standard')
                    // }

                    // if (treeBusiness && treeBusiness.length > 0) {
                    //     this.props.drawAllMatchedWordsByClassList(classListBusiness, [], 'dictionaries')
                    // }
                    // if (treeRegEx && treeRegEx.length > 0) {
                    //     this.props.drawAllMatchedWordsByClassList(classListRegEx, [], 'regex')
                    // }

                    // if ((treeRegEx && treeRegEx.length > 0) && (treeBusiness && treeBusiness.length > 0)) {
                    //     classListDictRegEx = classListRegEx.concat(classListBusiness)
                    //     this.props.drawAllMatchedWordsByClassList(classListDictRegEx, [], 'regex')
                    // } else if ((treeRegEx && treeRegEx.length > 0) && (treeBusiness.length === 0)) {
                    //     classListDictRegEx = classListRegEx
                    //     this.props.drawAllMatchedWordsByClassList(classListDictRegEx, [], 'regex')
                    // } else if ((treeRegEx.length === 0) && (treeBusiness && treeBusiness.length > 0)) {
                    //     classListDictRegEx = classListBusiness
                    //     this.props.drawAllMatchedWordsByClassList(classListDictRegEx, [], 'regex')
                    // }
                    if (statusRedactAll === "true") {
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllStandard: [],
                            checkedKeysAllBusiness: [],
                            checkedKeysAllRegEx: [],
                        })
                    } else {
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllStandard: classListStandard,
                            checkedKeysAllBusiness: classListBusiness,
                            checkedKeysAllRegEx: classListRegEx,
                        })
                    }
                })
            }
        })
    }
    onDropdownVisibleReview = (e) => {
      this.setState({
          visibleOptionsReviewAll: e
      })
    }
    filterOptionsReview = (e) => {
      const { treeDataStandardOption, checkedKeysRedacted } = this.state
        this.setState({
            statusFilterReview: e
        })
        //const treeDataFilter = treeDataStandardOption[0]
         if (e === 'review-redacted' && checkedKeysRedacted && checkedKeysRedacted.length > 0) {
            treeDataStandardOption && treeDataStandardOption[0].children && treeDataStandardOption[0].children.length > 0 && treeDataStandardOption[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                            if (child4.status === 1) {
                                //child3.status = 1
                                child4.status = 1
                            } else {
                                //child3.status = 1
                                child4.status = 0
                            }
                            if (child3.children.includes(child4.status === 1)) {
                                //child3.status = 1
                            }
                        })
                    })
                })
            })

             treeDataStandardOption && treeDataStandardOption[0].children && treeDataStandardOption[0].children.length > 0 && treeDataStandardOption[0].children.forEach((child) => {
                 child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                     child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                         if (child3.children.filter(e => e.status === 1).length > 0) {
                             child3.status = 1
                             child.status = 1
                             child2.status = 1
                             treeDataStandardOption[0].status = 1
                         } else {
                             child3.status = 0
                             child.status = 0
                             child2.status = 0
                             treeDataStandardOption[0].status = 0
                         }
                     })
                 })
             })
         }
    }
    treeDataFilter = (tree) => {
        this.setState({
            treeDataStandardOption: tree
        })
    }
    statusCheckedChildrenRedact = (checked) => {
       this.setState({
           checkedKeysRedacted: checked
       })
    }

    onBlockRedactAllCategories = () => {
      return true
    }
   onActionHideAllHighlighting = () => {
      const { treeDataAnnotationsStandard, statusHideAllHighlighting, statusIconHideAllHighlighting } = this.state
       if (!statusHideAllHighlighting) {
           this.setState({
               statusHideAllHighlighting: !this.state.statusHideAllHighlighting,
               statusIconHideAllHighlighting: true,
           })
       } else {
           this.setState({
               statusHideAllHighlighting: !this.state.statusHideAllHighlighting,
               statusIconHideAllHighlighting: !this.state.statusIconHideAllHighlighting,
           })
       }
   }
   onChangeStatusIconHideAllHighlighting = () => {
      this.setState({
          statusIconHideAllHighlighting: true
      })
   }

 render() {
   const { activeKey, updateRedactAllStatus, checkedKeysAllStandard, statusFilterReview, checkedKeysAllBusiness, checkedKeysAllRegEx,
       treeRegEx, treeBusiness, docAnnotationsRegEx, docAnnotationsList, visibleOptionsReviewAll, responseStatusQuery,
       categoriesColorsStandard, docAnnotationsStandard, docAnnotationsColorsStandard, docAnnotationCategoriesStandard, treeDataAnnotationsStandard,
       expandedKeysAll, docAnnotationsBusiness, categoryColoursBusiness, treeDataAnnotationsBusiness,
       docAnnotationsRegularExp, categoryColoursRegularExp, treeDataAnnotationsRegEx, getAllChildrenRegExUpdate, regExListUpdate, regExListVersionUpdate,
       dictionaryListUpdate, getAllChildrenBusinessUpdate, docAnnotationsOcr, docAnnotationsBusinessOcr, docAnnotationsRegularExpOcr,
       getAllChildrenStandardUpdate, getAllChildrenBusinessParent, getAllChildrenRegExUpdateParent, fetchResponseLoadingStandard,
       docAnnotationsXerox, docAnnotationsBusinessXerox, docAnnotationsRegularXerox, statusHideAllHighlighting, statusIconHideAllHighlighting,
       listCategories } = this.state
     const { lockSmartViewChanges } = this.props
   return (
       <React.Fragment>
           { fetchResponseLoadingStandard === false &&
           <div className="content-spinning-standard-ai"> <Spin tip="Loading"></Spin></div> }
           { this.props.isDocumentReady && <div className="find-asset-container">
               {/*<div className="title">SEARCH TERMS</div>*/}
               <div className="header-action-ai-results">
                   <div className="content-option-ai">
                       <span className="option-ai-contain">
                         <span onClick={lockSmartViewChanges ? (e) => this.onBlockRedactAllCategories(e) : this.props.isOcr ? (e) => this.onRedactAllOcr(e) :
                             this.props.xeroxUpload ? (e) => this.onRedactAllXerox(e) :
                             (e) => this.onRedactAllCategories(e)}
                               className={ lockSmartViewChanges ? 'icon-v17-redaction-hover icon-v17-redaction-hover-block' : "icon-v17-redaction-hover" }
                         ></span>
                           {!this.props.isOcr &&
                           <span
                               className={statusIconHideAllHighlighting ? "icon-v22-visible-active" : 'icon-v22-unvisible-active'}
                               onClick={(e) => this.onActionHideAllHighlighting(e)}></span>
                           }
                           </span>
                       <Select
                           placeholder="show-all"
                           className='custom-option-review'
                           value={<span
                               className="content-options-review"><span>{statusFilterReview === 'review-all' ? 'show all' : statusFilterReview === 'review-redacted' ? 'redacted' :
                               statusFilterReview === 'review-annotated' ? 'annotated' : 'show all'}</span>
                         <span
                             className={visibleOptionsReviewAll ? "icon-v19-arrow-medium icon-v19-arrow-medium-rotate" : 'icon-v19-arrow-medium'}></span></span>}
                           dropdownClassName="custom-option-review-ai"
                           showArrow={false}
                           onChange={(e) => this.filterOptionsReview(e)}
                           onDropdownVisibleChange={(e) => this.onDropdownVisibleReview(e)}
                       >
                           <Option className="item-options-review" key={1} value="review-all">
                               show all
                           </Option>
                           <Option className="item-options-review" key={2} value="review-redacted">
                               redacted
                           </Option>
                           <Option className="item-options-review" key={3} value="review-annotated">
                               annotated
                           </Option>
                       </Select>
                   </div>
                   {/*<ReviewPrevNextAiTag*/}
                   {/*    checkedKeysObjectRefs={ checkedKeysObjectRefs }*/}
                   {/*    pageNumber={ this.props.pageNumber }*/}
                   {/*    changePage={ this.props.changePage }*/}
                   {/*    isDocumentReady={ this.props.isDocumentReady }/>*/}
               </div>
               <div>
                   <StandardTab
                       isDocumentReady={this.props.isDocumentReady}
                       isSmartBoxApplication={this.props.isSmartBoxApplication}
                       documentId={this.props.documentId}
                       idDocument={this.props.idDocument}
                       landscape={this.props.landscape}
                       changePage={this.props.changePage}
                       pageNumber={this.props.pageNumber}
                       drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}
                       collapseAnnotateSelectModal={this.props.collapseAnnotateSelectModal}
                       setNumberOfAnnotateSelected={this.props.setNumberOfAnnotateSelected}
                       hideLoadingBeforeMark={this.props.hideLoadingBeforeMark}
                       allPagesRendered={this.props.allPagesRendered}
                       setQueryStatus={this.props.setQueryStatus}
                       setTreeDataAnnotations={this.props.setTreeDataAnnotations}
                       newListKeys={this.props.newListKeys}
                       smartBoxDocumentPath={this.props.smartBoxDocumentPath}
                       toggleLoadingProcess={this.props.toggleLoadingProcess}
                       newListKeysStandard={this.props.newListKeysStandard}
                       verificationOnClickClearAll={this.props.verificationOnClickClearAll}
                       checkOnClearAll={this.props.checkOnClearAll}
                       activeKey={activeKey}
                       onCheckStatusSelectAgainRedacted={this.props.onCheckStatusSelectAgainRedacted}
                       onSelectTab={this.onSelectTab}
                       statusKeysEmptyOnClearAll={this.props.statusKeysEmptyOnClearAll}
                       onSendStatusOnClearAll={this.props.onSendStatusOnClearAll}
                       isOcr={this.props.isOcr}
                       fetchSmartBoxDataCanvasOcr={this.props.fetchSmartBoxDataCanvasOcr}
                       onCheckedKeysObjectRefs={this.onCheckedKeysObjectRefs}
                       isNextDocument={this.props.isNextDocument}
                       isNextDocumentId={this.props.isNextDocumentId}
                       disabledNextDocument={this.props.disabledNextDocument}
                       nextDocumentLength={this.props.nextDocumentLength}
                       filesTable={this.props.filesTable}
                       allChildrenTree={this.allChildrenTree}
                       updateRedactAllStatus={updateRedactAllStatus}
                       checkedKeysAllStandard={checkedKeysAllStandard}
                       onCheckAiAnnotations={this.onCheckAiAnnotations}
                       treeDataFilter={this.treeDataFilter}
                       statusCheckedChildrenRedact={this.statusCheckedChildrenRedact}
                       statusFilterReview={statusFilterReview}
                       checkedKeysObjectRefsStandard={this.props.checkedKeysObjectRefsStandard}
                       statusSaveQuery={this.props.statusSaveQuery}
                       alreadyRedactedKeys={this.props.alreadyRedactedKeys}
                       treeRegEx={treeRegEx}
                       treeBusiness={treeBusiness}
                       docAnnotationsRegEx={docAnnotationsRegEx}
                       docAnnotationsList={docAnnotationsList}
                       onDocAnnotationsOcr={this.onDocAnnotationsOcr}
                       getStatusElementRedaction={this.props.getStatusElementRedaction}
                       onGetStatusResponseQuery={this.onGetStatusResponseQuery}
                       categoriesColorsStandard={categoriesColorsStandard}
                       docAnnotationsStandard={docAnnotationsStandard}
                       docAnnotationsColorsStandard={docAnnotationsColorsStandard}
                       docAnnotationCategoriesStandard={docAnnotationCategoriesStandard}
                       treeDataAnnotationsStandard={treeDataAnnotationsStandard}
                       expandedKeysAll={expandedKeysAll}
                       statusNextDocumentStandard={this.props.statusNextDocumentStandard}
                       onGetStatusNextDocumentStandard={this.props.onGetStatusNextDocumentStandard}
                       getAllChildrenStandardUpdate={getAllChildrenStandardUpdate}
                       fetchResponseLoadingStandard={fetchResponseLoadingStandard}
                       xeroxUpload={ this.props.xeroxUpload }
                       docAnnotationsXerox={docAnnotationsXerox}
                       onGetCanvasPointsXeroxRedaction={ this.props.onGetCanvasPointsXeroxRedaction }
                       showHideCategoryOcr={ this.props.showHideCategoryOcr }
                       docAnnotationsStandardOcr={ this.props.docAnnotationsStandardOcr }
                       lockSmartViewChanges={ this.props.lockSmartViewChanges }
                       statusHideAllHighlighting={ statusHideAllHighlighting }
                       onChangeStatusIconHideAllHighlighting={ this.onChangeStatusIconHideAllHighlighting }
                       listCategories={ listCategories }
                   />
                   <ManualTab isDocumentReady={this.props.isDocumentReady}
                              isSmartBoxApplication={this.props.isSmartBoxApplication}
                              documentId={this.props.documentId}
                              landscape={this.props.landscape}
                              changePage={this.props.changePage}
                              pageNumber={this.props.pageNumber}
                              drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}
                              hideLoadingBeforeMark={this.props.hideLoadingBeforeMark}
                              idDocument={this.props.idDocument}
                              highlightRectangle={this.props.highlightRectangle}
                              toggleLoadingProcess={this.props.toggleLoadingProcess}
                              activeKey={activeKey}
                              newListKeysManual={this.props.newListKeysManual}
                              verificationOnClickClearAllManual={this.props.verificationOnClickClearAllManual}
                              checkOnClearAllManual={this.props.checkOnClearAllManual}
                              newListKeysRegEx={this.props.newListKeysRegEx}
                              checkOnClearAllRegEx={this.props.checkOnClearAllRegEx}
                              verificationOnClickClearAllRegEx={this.props.verificationOnClickClearAllRegEx}
                              newListKeysBusiness={this.props.newListKeysBusiness}
                              checkOnClearAllBusiness={this.props.checkOnClearAllBusiness}
                              verificationOnClickClearAllBusiness={this.props.verificationOnClickClearAllBusiness}
                              onCheckedKeysObjectRefsList={this.onCheckedKeysObjectRefsList}
                              onCheckedKeysObjectRefsBoxTerms={this.onCheckedKeysObjectRefsBoxTerms}
                              onCheckedKeysObjectRefsRegEx={this.onCheckedKeysObjectRefsRegEx}
                              isNextDocumentId={this.props.isNextDocumentId}
                              allChildrenTreeBusiness={this.allChildrenTreeBusiness}
                              checkedKeysAllBusiness={checkedKeysAllBusiness}
                              updateRedactAllStatus={updateRedactAllStatus}
                              allChildrenTreeRegEx={this.allChildrenTreeRegEx}
                              checkedKeysAllRegEx={checkedKeysAllRegEx}
                              onDocAnnotationsRegEx={this.onDocAnnotationsRegEx}
                              onDocAnnotationsList={this.onDocAnnotationsList}
                              isOcr={this.props.isOcr}
                              fetchSmartBoxDataCanvasOcr={this.props.fetchSmartBoxDataCanvasOcr}
                              alreadyRedactedKeys={this.props.alreadyRedactedKeys}
                              treeRegEx={treeRegEx}
                              treeBusiness={treeBusiness}
                              setTreeDataRegEx={this.props.setTreeDataRegEx}
                              setTreeDataDict={this.props.setTreeDataDict}
                              getStatusElementRedaction={this.props.getStatusElementRedaction}
                              responseStatusQuery={responseStatusQuery}
                              docAnnotationsBusiness={docAnnotationsBusiness}
                              docAnnotationsBusinessOcr={docAnnotationsBusinessOcr}
                              categoryColoursBusiness={categoryColoursBusiness}
                              treeDataAnnotationsBusiness={treeDataAnnotationsBusiness}
                              docAnnotationsRegularExp={docAnnotationsRegularExp}
                              docAnnotationsRegularExpOcr={docAnnotationsRegularExpOcr}
                              categoryColoursRegularExp={categoryColoursRegularExp}
                              treeDataAnnotationsRegEx={treeDataAnnotationsRegEx}
                              getAllChildrenRegEx={getAllChildrenRegExUpdate}
                              getAllChildrenRegExUpdateParent={getAllChildrenRegExUpdateParent}
                              regExList={regExListUpdate}
                              regExListVersion={regExListVersionUpdate}
                              dictionaryList={dictionaryListUpdate}
                              getAllChildrenBusiness={getAllChildrenBusinessUpdate}
                              getAllChildrenBusinessParent={getAllChildrenBusinessParent}
                              statusNextDocumentBusiness={this.props.statusNextDocumentBusiness}
                              onGetStatusNextDocumentBusiness={this.props.onGetStatusNextDocumentBusiness}
                              statusNextDocumentRegEx={this.props.statusNextDocumentRegEx}
                              onGetStatusNextDocumentRegEx={this.props.onGetStatusNextDocumentRegEx}
                              docAnnotationsBusinessXerox={docAnnotationsBusinessXerox}
                              docAnnotationsRegularXerox={docAnnotationsRegularXerox}
                              xeroxUpload={ this.props.xeroxUpload }
                              onGetCanvasPointsXeroxRedaction={ this.props.onGetCanvasPointsXeroxRedaction }
                              showHideCategoryOcr={ this.props.showHideCategoryOcr }
                              lockSmartViewChanges={ this.props.lockSmartViewChanges }
                              statusHideAllHighlighting={ statusHideAllHighlighting }
                              onChangeStatusIconHideAllHighlighting={ this.onChangeStatusIconHideAllHighlighting }
                              listCategories={ listCategories }
                       //onGetDictionaryOcrUpdate={ this.onGetDictionaryOcrUpdate }
                   />
               </div>

               {/*<Tabs defaultActiveKey="1" onChange={this.onSelectTab}>*/}
               {/*  <TabPane tab="Standard" key="standardTab">*/}
               {/*    <StandardTab*/}
               {/*      isDocumentReady={ this.props.isDocumentReady }*/}
               {/*      isSmartBoxApplication={ this.props.isSmartBoxApplication }*/}
               {/*      documentId={ this.props.documentId }*/}
               {/*      idDocument={ this.props.idDocument }*/}
               {/*      landscape={ this.props.landscape }*/}
               {/*      changePage={ this.props.changePage }*/}
               {/*      pageNumber={ this.props.pageNumber }*/}
               {/*      drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}*/}
               {/*      collapseAnnotateSelectModal={this.props.collapseAnnotateSelectModal}*/}
               {/*      setNumberOfAnnotateSelected={this.props.setNumberOfAnnotateSelected}*/}
               {/*      hideLoadingBeforeMark={this.props.hideLoadingBeforeMark}*/}
               {/*      allPagesRendered={this.props.allPagesRendered}*/}
               {/*      setQueryStatus={this.props.setQueryStatus}*/}
               {/*      setTreeDataAnnotations = { this.props.setTreeDataAnnotations }*/}
               {/*      newListKeys = { this.props.newListKeys }*/}
               {/*      smartBoxDocumentPath = { this.props.smartBoxDocumentPath }*/}
               {/*      toggleLoadingProcess = { this.props.toggleLoadingProcess }*/}
               {/*      newListKeysStandard = { this.props.newListKeysStandard }*/}
               {/*      verificationOnClickClearAll = { this.props.verificationOnClickClearAll }*/}
               {/*      checkOnClearAll = { this.props.checkOnClearAll }*/}
               {/*      activeKey = { activeKey }*/}
               {/*      onCheckStatusSelectAgainRedacted= { this.props.onCheckStatusSelectAgainRedacted }*/}
               {/*      onSelectTab = { this.onSelectTab }*/}
               {/*      statusKeysEmptyOnClearAll = { this.props.statusKeysEmptyOnClearAll }*/}
               {/*      onSendStatusOnClearAll = { this.props.onSendStatusOnClearAll }*/}
               {/*      isOcr = { this.props.isOcr }*/}
               {/*      fetchSmartBoxDataCanvasOcr = { this.props.fetchSmartBoxDataCanvasOcr }*/}
               {/*    />*/}
               {/*  </TabPane>*/}
               {/*  <TabPane tab="Manual" key="manualTab">*/}
               {/*    <ManualTab isDocumentReady={ this.props.isDocumentReady }*/}
               {/*      isSmartBoxApplication={ this.props.isSmartBoxApplication }*/}
               {/*      documentId={ this.props.documentId }*/}
               {/*      landscape={ this.props.landscape }*/}
               {/*      changePage={ this.props.changePage }*/}
               {/*      pageNumber={ this.props.pageNumber }*/}
               {/*      drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList} */}
               {/*      hideLoadingBeforeMark={this.props.hideLoadingBeforeMark}*/}
               {/*      idDocument={ this.props.idDocument }*/}
               {/*      highlightRectangle={this.props.highlightRectangle }*/}
               {/*      toggleLoadingProcess = { this.props.toggleLoadingProcess }*/}
               {/*      activeKey = { activeKey }*/}
               {/*      newListKeysManual={ this.props.newListKeysManual }*/}
               {/*      verificationOnClickClearAllManual={ this.props.verificationOnClickClearAllManual }*/}
               {/*      checkOnClearAllManual={ this.props.checkOnClearAllManual }*/}
               {/*      newListKeysRegEx={ this.props.newListKeysRegEx }*/}
               {/*      checkOnClearAllRegEx={ this.props.checkOnClearAllRegEx }*/}
               {/*      verificationOnClickClearAllRegEx={ this.props.verificationOnClickClearAllRegEx }*/}
               {/*      newListKeysBusiness={ this.props.newListKeysBusiness }*/}
               {/*      checkOnClearAllBusiness={ this.props.checkOnClearAllBusiness }*/}
               {/*      verificationOnClickClearAllBusiness={ this.props.verificationOnClickClearAllBusiness }*/}
               {/*    />*/}
               {/*  </TabPane>*/}
               {/*</Tabs>*/}
           </div>
           }
       </React.Fragment>
   )
 }
}
const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    userId: state.userReducer.user.id
})
AiAnnotationsSidebar.propTypes = {
  isDocumentReady: PropTypes.bool.isRequired,
  collapseAnnotateSelectModal: PropTypes.func,
  setNumberOfAnnotateSelected: PropTypes.func,
  hideLoadingBeforeMark: PropTypes.func,
  setQueryStatus: PropTypes.func,
  setTreeDataAnnotations: PropTypes.func,
  toggleLoadingProcess: PropTypes.func,
    clientId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
}

//export default AiAnnotationsSidebar
export default connect(mapStateToProps)(AiAnnotationsSidebar)