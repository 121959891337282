import React from 'react'
import { connect } from 'react-redux'
import './SmartBoxFolderOpened.scss'
import './SmartBoxPage.scss'
import { Link } from 'react-router-dom'
import { Input, Spin, notification, Modal } from 'antd'
import {
  apiSmartBoxCheckAiFolderStatus,
  apiSmartBoxCheckPdfFolderStatus,
  apiSmartBoxPreviewSarDocByThumbnailId,
  createSmartDocument,
  getRenameFile,
  getSmartBoxFileById,
  getSmartBoxFilesPerPage,
  getSmartBoxFilesFilter,
  getSmartBoxFilesFilterForSearch,
  getThumbnailForFiles,
  setFileStatus,
  getProgressBarData,
  getFolderCountFiles,
  getCheckFilesDuplicate,
  getCheckFilesProprieties,
  apiSmartBoxFolderHasWarningFilesNotification,
  getCurrentFolderCountFiles,
  getBoxInfoFromSubFolderOrFile
} from '../../../api/SmartBox'
import { deleteFolderOrFile, generateFileForLink } from '../../../utils/SmartBox/SmartBoxMethods'
import moment from 'moment'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { setFooterVisibility, setHeaderVisibility, setSidebarVisibility } from '../../../actions/shellActions'
import { RedactionTool } from '../../Sar/Redacting/RedactionTool'
import { RedactionToolCopy } from '../../Sar/Redacting/RedactionToolCopy'
import { generateBlobFromBase64 } from '../../../utils/GenerateBlob'
import swal from 'sweetalert2'
import {
  removeSmartBoxIntervalForAiPages,
  setSmartBoxCurrentFolder,
  removeSmartBoxIntervalForPdfPages,
  setSmartBoxIntervalForAiPages,
  setSmartBoxIntervalForPdfPages,
  setSmartBoxSearchedWord,
  setSmartBoxUploadingObject,
  setSmartBoxViewMode,
  setSmartBoxNewNode,
  setSmartBoxMovedFiles,
  setSmartBoxSelectedFiles,
  setSmartBoxFilesTable,
  setSmartBoxFetchFiles,
  setSmartBoxAnalysisPercent,
  setSmartBoxParentFolderEmpty,
  setSmartBoxParentFolderErrors,
  setSmartBoxParentFolderName,
  setSmartBoxNotificationButtonFirstUpload,
  setSmartBoxDataReady,
  setSmartBoxFolderHasBulk,
  setSmartBoxErrorLoadingThumbnails,
  setSortSelectionCloseSmartview,
  setSmartFilesErrorNotBeConverted,
  setSmartBoxNotAnalysisComplete,
  setSmartFolderCountTree,
  setSmartBoxShowUploadNavigation,
  setSmartBoxClickOutsideFilter,
  setStatusClearAllFiltersSmartbox,
  setProgressEventUpload,
  setSmartBoxSelectedKeys,
  setActiveDeleteFile,
  setSmartBoxExistsFilesWarningsNotification,
  setSmartBoxPreviousCurrentPage

} from '../../../actions/smartBoxActions'
import WebWorker from './workers/WebWorker'
import worker from './workers/file-extensions.worker'
import DropMultipleFileDirectory from './DropMultipleFileDirectory'
import SmartBoxActionsHeader from './SmartBoxActionsHeader'
import SmartBoxFile from './SmartBoxFile'
import TreeStructureFolder from './TreeStructureFolder'
import MetadataPage from './MetadataPage'
import { addIntervalToApplication } from '../../../actions/applicationsActions'
import imgFolderSmallOpenParentSmall from '../../../assets/smart-folder-open-paret-small.svg'
import SmartBoxSettingsMenu from './Settings/SmartBoxSettingsMenu'
import WrapperNotification from './WrapperNotification'
import { SketchPicker } from 'react-color'
import cookie from "react-cookies";
import withRouter from '../../../private-routers/withRouter'

class SmartBoxFolderOpened extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      folder: '',
      folderId: props.location.pathname.replace('/smart-box/folder/', ''),
      childFiles: null,
      showDeleteButtons: {},
      isIntervalStartedForAi: false,
      isIntervalStartedForPdf: false,
      viewMode: 'grid',
      searchText: '',
      contentClass: '',
      sidebarClass: '',
      sidebarHeight: '',
      listKeysFiles: [],
      listKeysFilesDeleted: [],
      checkKeysFiles: false,
      selectedDoc: {},
      sidebarOpen: false,
      loadingOpenedFolder: true,
      visitedRedactionSmart: 'smart',
      isOcr: false,
      xeroxUpload: false,
      lockSmartViewChanges: false,
      disabledOnClicked: false,
      smartBoxMenuOpen: false,
      validNewFolderName: { success: true, reason: 'Invalid name' },
      smartBoxDocumentPath: '',
      nameFileOpenInRedaction: '',
      finalizedDocPath: false,
      renameFolderModalVisible: false,
      customerStatusModalVisible: '',
      newFolderName: '',
      newFolderNameValid: { success: true, reason: 'Invalid name !' },
      newCustomStatusNameValid: { success: true, reason: 'Invalid name !' },
      folderNewDepth: 0,
      processedFiles: [],
      convertedFiles: [],
      listSelectedNameFiles: [],
      listSelectedPathFiles: [],
      currentPathSelectedFiles: '',
      currentFolderId: '',
      checkStatusPaste: false,
      listSelectedNameId: [],
      totalPagesForPagination: 20,
      currentPage: 1,
      // amountPerPage: 20,
      paginationOptions: [20, 50, 100],
      listSelectedFiles: [],
      leftTreeData: [],
      documentNoteVisible: false,
      basePath: '',
      currentFolderPath: '',
      displayColorPicker: false,
      customStatusColor: '#cccccc',
      customStatusText: '',
      documentsFolderPath: '',
      newFolderClicked: false,
      totalFolderOnRoot: 0,
      statusCloseNavigationBar: false,
      boxIdBasePath: '',
      checkCloseSmartViewForPagination: false,
      foldersCountFiles: [],
      countFilesTimer: 0,
      activeFile: {},
      listSelectedNameIdCutPaste: [],
      foldersCheckErrors: [],
      filesCheckDuplicates: [],
      lastFileClicked: '',
      lastFileClickedAction: 'add',
      newDataFilters: {},
      newListFilesProprieties: [],
      countProprietiesTimer: 0,
      checkFilesProprieties: true,
      baseFolders: [],
      urlsFolderPaths: {},
      documentsStartNr: null,
      statusResponsePagination: false,
      closeRedactionStatus: false,
      updateSelectKeysSmartBox: false,
      statusPagination: null,
      statusFolderCountFiles: 200,
      statusThumbnailForFiles: null,
      statusProgressBarData: null,
      actionNextPreviousSmartbox: false,
      statusGetThumbnailForFiles: null,
      statusGetFolderCountForFiles: null,
      setIntervalCurrentFolderCountFilesAfterUpload: 0,
      totalFilesBeforeUpload: 0,
      responseFilesAfterUpload: 0,
      statusFilesAfterUpload: true,
      idParentBox: ''
    }
  }

  componentDidMount() {
    this.fetchFolderInfo()
    this.onGetBoxParentInfo()
    window.addEventListener('beforeunload', function (event) {
      event.stopImmediatePropagation();
    })
    window.scrollTo(0,0)
    this.props.setSmartBoxShowUploadNavigation(false)
    this.setState({
      currentLocation: this.props.location.pathname
    })
    this.worker = new WebWorker(worker)
    document.addEventListener('mousedown', this.handleClickOutsideFiles, false)
    this.worker.addEventListener('message', (e) => {
      const { acceptedFiles, rejectedFiles } = e.data
      this.props.setSmartBoxUploadingObject(acceptedFiles, this.state.folder.relativePath.substr(1))
      for (let i = 0; i < rejectedFiles.length; i++) {
        notification.warning({
          message: 'Not Uploaded',
          description: `File ${ rejectedFiles[i].name } with the extension ${ rejectedFiles[i].type } cannot be uploaded.`,
          className: 'nt-warning',
          duration: 1000000
        })
      }
    })
    document.addEventListener('mousedown', this.handleClickOutside, false)
    cookie.remove('checkDeduplicationStatus', { path: '/' })
    cookie.remove('checkCollectorPageUploadHistoryData', { path: '/' })
    cookie.remove('openTicketArchive', { path: '/' })
    cookie.remove('checkNearStatus', { path: '/' })
    cookie.remove('checkListDuplicateFileBox', { path: '/' })
    cookie.remove('checkListClutter', { path: '/' })
    cookie.remove('checkListNearFileBox', { path: '/' })
    cookie.remove('checkGetDuplicatesCardInfo', { path: '/' })
    cookie.remove('checkProgressBarBulkData', { path: '/' })
    cookie.remove('checkDownloadFilesError', { path: '/' })
    cookie.remove('checkErrorReportStatus', { path: '/' })
    cookie.remove('checkWarningsReportStatus', { path: '/' })
    cookie.remove('checkCurrentFolderCountFiles', { path: '/' })
    this.onRemoveEventDisableScroll()
    this.props.setProgressEventUpload(undefined)
    this.props.setActiveDeleteFile(false)
  }

  onGetBoxParentInfo = () => {
    this.setState({
      //folderId: this.props.params.id
    }, () => {
      getBoxInfoFromSubFolderOrFile(this.state.folderId).then((response) => {
        this.setState({
          idParentBox: response.data.boxId
        })
      })
    })
  }

  onRemoveEventDisableScroll = () => {
    let supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get:  supportsPassive = true
      }))
    } catch(e) {}

    const wheelOpt = supportsPassive ? { passive: false } : false
    const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
    window.removeEventListener('DOMMouseScroll', this.preventDefaultKeyDown, false)
    window.removeEventListener(wheelEvent, this.preventDefaultKeyDown, wheelOpt)
    window.removeEventListener('touchmove', this.preventDefaultKeyDown, wheelOpt)
    window.removeEventListener('keydown', this.preventDefaultEventScroll, false)
  }
  preventDefaultEventScroll = (e) => {
    this.preventDefaultKeyDown(e)
    return false
  }
  preventDefaultKeyDown = (e) => {
    e.preventDefault()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false)

    const { countFilesTimer, countProprietiesTimer } = this.state
    if (countFilesTimer > 0) {
      clearInterval(countFilesTimer)
    }
    if (countProprietiesTimer) {
      clearInterval(countProprietiesTimer)
    }
  }

  setActiveFile = (e, file) => {
    if (typeof file.id !== 'undefined') {
      const { listKeysFiles } = this.state

      if (!listKeysFiles.includes(file.id)) {
        this.addOrRemoveValue(file)
      }
    }
    this.setState({ activeFile: file })
  }

  handleClickOutside = (e) => {
    const { smartBoxMenuOpen } = this.state
    if (this.node) {
      if (this.node.contains(e.target)) {
        if (!smartBoxMenuOpen) {
          this.toggleSmartBoxMenu(true)
        }
        if (smartBoxMenuOpen && typeof e.target.className === 'string' && (e.target.className.includes('ant-menu-submenu-title') || e.target.className.includes('ant-menu-submenu-arrow'))) {
          this.toggleSmartBoxMenu(false)
        }
      } else if (smartBoxMenuOpen) {
        this.toggleSmartBoxMenu(false)
      }
    }
  }
  getTumbnails = () => {
    const { childFiles, statusGetThumbnailForFiles } = this.state
    const listOfFilesId = []
    const listOfThumbnails = []
    if (childFiles.length) {
      childFiles.forEach((file) => {
        if (file.type !== 'DIRECTORY' && file.isPdf !== 'false') {
          if (!listOfFilesId.includes(file.id)) {
            listOfFilesId.push(file.id)
          }
        }
      })

      if (listOfFilesId.length) {
        const parameters = {
          list: listOfFilesId,
          clientId: this.props.clientId
        }
        this.setState({
          statusGetThumbnailForFiles: false
        })
        if (statusGetThumbnailForFiles === null || statusGetThumbnailForFiles === true) {
          getThumbnailForFiles(parameters).then((response) => {
            if (response.status === 200) {
              if (Object.keys(response.data).length) {
                const formatedResponse = this.createFromatedResponseForThumbnails(response.data)
                if (Object.keys(formatedResponse).length) {
                  for (const [key, value] of Object.entries(formatedResponse)) {
                    listOfThumbnails.push({id: key, thumbnailSrc: value[0]})
                  }
                }
                this.setState({listOfThumbnails})
              }
              this.setState({
                statusThumbnailForFiles: response.status,
                statusGetThumbnailForFiles: true
              })
            }
          }).catch((error) => {
            if (error?.response?.status === 500) {
              const timeCurrent = `${moment().format('MM-DD-YYYY')} at ${moment().format('HH:mm:ss')}`
              this.props.setSmartBoxErrorLoadingThumbnails('errors-loading', 'Error loading thumbnails', '', timeCurrent)
            } else if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            } else {
              console.log(error)
            }
          })
        }
      }
    }
  }
  handleClickOutsideFiles = (e) => {
    if (typeof e.target.className === 'string' && (e.target.className.includes('smart-box-folder-opened_container__content__files') && e.target.className.includes('ant-spin-container') || e.target.className.includes('btn-upload'))) {
      this.props.setSmartBoxClickOutsideFilter(true)
      this.setState({
        listKeysFiles: [],
        listKeysFilesDeleted: [],
      })
    }
  }
  toggleSmartBoxMenu = (open) => {
    const { smartBoxMenuOpen } = this.state
    if (smartBoxMenuOpen) {
      this.setState({ smartBoxMenuOpen: open })
    }
    this.setState({ smartBoxMenuOpen: open })
  }

  saveLeftTree = (leftTreeData) => this.setState({ leftTreeData })

  toggleNoteVisible = (open) => {
    this.setState({ documentNoteVisible: open })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps !== this.props) && this.props.selectKeysSmartBox && this.props.selectKeysSmartBox.length === 1) {
      prevState.childFiles && prevState.childFiles.length > 0 && prevState.childFiles.forEach((item) => {
        if (this.props.selectKeysSmartBox.includes(item.id)) {
          this.setState({
            listKeysFiles: this.props.selectKeysSmartBox,
            updateSelectKeysSmartBox: true
          })
        } else {
          // this.setState({
          //   listKeysFiles: [],
          //   updateSelectKeysSmartBox: true
          // })
        }
      })
      this.props.setActiveDeleteFile(false)
    }
    if (prevProps.params.id !== this.props.params.id) {
      this.props.setSmartBoxMovedFiles([])
      this.props.setSmartBoxSelectedFiles([])
      if (prevState.countFilesTimer) {
        clearInterval(prevState.countFilesTimer)
      }
    if (prevState.countProprietiesTimer) {
      clearInterval(prevState.countProprietiesTimer)
    }
      this.setState({
        folder: '',
        folderId: this.props.params.id,
        childFiles: null,
        currentPage: 1,
        listSelectedFiles: [],
        listKeysFiles: [],
        listKeysFilesDeleted: [],
        listSelectedPathFiles: [],
        listSelectedNameFiles: [],
        listSelectedNameId: [],
        countFilesTimer: 0,
        loadingOpenedFolder: true,
        countProprietiesTimer: 0
      }, () => {
        if (!this.props.activeDeleteFile) {
          this.fetchFolderInfo()
          this.props.setActiveDeleteFile(false)
        } else {
          this.props.setActiveDeleteFile(false)
        }
      })
    }

    const diffMovedFiles = this.props.smartBoxMovedFiles.filter((x) => !prevProps.smartBoxMovedFiles.includes(x))
    if (diffMovedFiles.length > 0) {
      let { childFiles } = this.state

      childFiles = childFiles.filter((file) => !this.props.smartBoxMovedFiles.includes(file.id))
      this.setState({ childFiles, listSelectedFiles: [] })

      this.props.setSmartBoxMovedFiles([])
      this.props.setSmartBoxSelectedFiles([])
    }
    if (this.state.newFolderClicked && typeof this.newFolderInput !== 'undefined' && this.newFolderInput !== null) {
      this.newFolderInput.focus()
    }
    if (prevProps.smartBoxRenamedFolder.id !== this.props.smartBoxRenamedFolder.id || prevProps.smartBoxRenamedFolder.newName !== this.props.smartBoxRenamedFolder.newName) {
      this.changeFileName(this.props.smartBoxRenamedFolder.id, this.props.smartBoxRenamedFolder.newName)
      this.props.setActiveDeleteFile(false)
    }

    if ((prevProps.fetchFolderContent !== this.props.fetchFolderContent) && this.props.fetchFolderContent === true) {
      this.setState({ checkFilesProprieties : true }, () => {
        this.getCurrentFolderCountFilesAfterUpload()
        this.fetchFolderInfo(true)
        this.props.setActiveDeleteFile(false)
      })
    }
  }

  getCurrentFolderCountFilesAfterUpload = () => {
    const { folder, totalFilesBeforeUpload } = this.state
    const pathFiles = []
    if (folder) {
      pathFiles.push(folder.path)
    }
      const folderCountFilesDTO = {
        pathList: pathFiles
      }
    getCurrentFolderCountFiles(folderCountFilesDTO).then((response) => {
      if (response.data && response.data.length > 0) {
        cookie.save('checkCurrentFolderCountFiles', 'getUpload', {path: '/'})
        this.setState({
          responseFilesAfterUpload: response.data[0].numberOfFiles,
          statusFilesAfterUpload: true
        })
        if (totalFilesBeforeUpload < response.data[0].numberOfFiles) {
          cookie.remove('checkCurrentFolderCountFiles', { path: '/' })
          this.fetchFolderInfo(false)
          // this.setState({
          //   statusFilesAfterUpload: false
          // })
        }
      }
      this.getSetIntervalCurrentFolderCountFilesAfterUpload()
    })
  }

  getSetIntervalCurrentFolderCountFilesAfterUpload = () => {
    let { setIntervalCurrentFolderCountFilesAfterUpload } = this.state
    setIntervalCurrentFolderCountFilesAfterUpload = setInterval(this.onCallBackCurrentFolderCountFilesAfterUpload, 4000)
    this.setState({
      setIntervalCurrentFolderCountFilesAfterUpload
    })
  }

  onCallBackCurrentFolderCountFilesAfterUpload = () => {
    const { folder, totalFilesBeforeUpload, responseFilesAfterUpload, statusFilesAfterUpload } = this.state
    const pathFiles = []
    if (folder) {
      pathFiles.push(folder.path)
    }
    const folderCountFilesDTO = {
      pathList: pathFiles
    }
    const itemGetUpload = cookie.load('checkCurrentFolderCountFiles', { path: '/' })
    if (itemGetUpload === 'getUpload') {
      getCurrentFolderCountFiles(folderCountFilesDTO).then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({
            responseFilesAfterUpload: response.data[0].numberOfFiles
          })
        }
        if (totalFilesBeforeUpload < response.data[0].numberOfFiles) {
          cookie.remove('checkCurrentFolderCountFiles', { path: '/' })
          this.fetchFolderInfo(false)
          // this.setState({
          //   statusFilesAfterUpload: false
          // })
        }
      })
    }

  }

  createFromatedResponseForThumbnails = (data) => {
    const result = {}
    if (Object.keys(data).length) {
      for (const item of Object.entries(data)) {
        if (item.length > 1) {
          const temp = item[0].split(',')
          const documentId = temp[0]
          if (temp[1]) {
            const temporary = temp[1].split(':')
            const page = temporary[1].trim()
            const base64 = item[1]
            if (documentId in result) {
              result[documentId][page] = base64
            } else {
              result[documentId] = []
              result[documentId][page] = base64

            }
          }
        }
      }
    }
    return result
  }

  setSidebarHeight = (sidebarHeight) => this.setState({ sidebarHeight: sidebarHeight })

  toggleLoadingOpenedFolder = (open) => this.setState({ loadingOpenedFolder: open })

  getListFoldersCheckErrors = () => {
    // const { childFiles, countFilesTimer } = this.state
    //const files = childFiles.filter((item) => item.type === 'DIRECTORY')
    // if (files.length > 0) {
    //   getCheckFolderErrors(files).then((response) => {
    //     if (response.data.length) {
    //       this.setState({ foldersCheckErrors: response.data })
    //     }
    //   })
    // } else if (countFilesTimer > 0) {
    //   clearInterval(countFilesTimer)
    //   this.setState({ countFilesTimer: 0 })
    // }
  }
  getFilesDuplicates = (item) => {
    const { filesCheckDuplicates, childFiles } = this.state
    const filesDuplicatesPath = []
    let typeIcon = ''
    if (childFiles && childFiles.length > 0) {
      filesCheckDuplicates && filesCheckDuplicates.length > 0 && filesCheckDuplicates.forEach((file) => {
        filesDuplicatesPath.push(file.path)
      })
      //const matchDuplicate = filesDuplicatesPath && filesDuplicatesPath.length > 0 && filesDuplicatesPath.filter((el) => (el === item.folderPath + item.path) || (el === `${ item.folderPath + item.path }.${ item.type }`))
      filesCheckDuplicates && filesCheckDuplicates.length > 0 && filesCheckDuplicates.forEach((file) => {
        if ((file.path === item.folderPath + item.path) || (file.path === `${ item.folderPath + item.path }.${ item.type }`)) {
          typeIcon = file.iconType
        }
      })
      // if (matchDuplicate && matchDuplicate.length > 0) {
      //   return true
      // }
      return typeIcon
    }
  }
  getCheckFileProprietiesRequest = (afterUpload = false) => {
    const { childFiles, countProprietiesTimer, folder, currentPage, newDataFilters, checkFilesProprieties, documentsStartNr } = this.state
    const { smartBoxPageLimit } = this.props
    const files = childFiles.filter((item) => item.type !== 'DIRECTORY')
    if (Object.keys(newDataFilters).length) {

    }
    const checkFileProprietiesRequestDTO = {
      folderPath: `${ folder.relativePath }`.substr(1),
      pageNumber: currentPage,
      pageSize: smartBoxPageLimit,
      sort: this.props.smartBoxCurrentSortOption !== 'creation date' ? this.props.smartBoxCurrentSortOption : this.props.currentNewFiltersSort ? this.props.currentNewFiltersSort : Object.keys(newDataFilters).length ? newDataFilters.sort : null,
      folderTree: false,
      search: Object.keys(newDataFilters).length ? newDataFilters.search : null,
      filter: Object.keys(newDataFilters).length ? newDataFilters.filter : null,
      statusFilters: this.props.currentNewFilters.checkedStatus ? this.props.currentNewFilters.checkedStatus : [],
      tagFilters: this.props.currentNewFilters.checkedTag ? this.props.currentNewFilters.checkedTag : [],
      documentsStartNr: documentsStartNr,

    }
    if (files.length > 0 && checkFilesProprieties === true) {
      getCheckFilesProprieties(checkFileProprietiesRequestDTO).then((response) => {

         this.setState({
           newListFilesProprieties: response.data.checkFileProprietiesResposeDTOList,
           checkFilesProprieties: response.data.callAAPIagain
         }, () => {
          if(afterUpload && !this.state.checkFilesProprieties) {
            this.props.setSmartBoxDataReady('true', this.state.folder)
          }
         })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
      const folderHasAiApiDTO = {
        folder: `${ folder.path }/`
      }
      apiSmartBoxFolderHasWarningFilesNotification(folderHasAiApiDTO).then((response) => {
        if (response.data && Object.keys(response.data) && Object.keys(response.data).length > 0) {
          this.onGetShowExistsFilesWarningsNotification(response.data)
        }
      })
    } else if (countProprietiesTimer > 0) {
      clearInterval(countProprietiesTimer)
      this.setState({
        countProprietiesTimer: 0
      })
    }
  }

  getFilesProprieties = (item) => {
    const { childFiles, newListFilesProprieties } = this.state
    let fileProprieties = {}
    if (childFiles && childFiles.length > 0) {
      newListFilesProprieties && newListFilesProprieties.length > 0 && newListFilesProprieties.forEach((file) => {
        if (item.id === file.id) {
          fileProprieties = file
        }
      })
    }
    return fileProprieties
  }
  getListCheckDuplicate = () => {
    const { childFiles, countFilesTimer, folder } = this.state
    const files = childFiles.filter((item) => item.type !== 'DIRECTORY')
    const filesPath = []
    files && files.length > 0 && files.forEach((file) => {
      filesPath.push({
        path: file.folderPath + file.path,
        type: file.type
      })
    })
    const requestDuplicateIconDTO = {
      folderPath: folder.path,
      checkForDuplicateDTOS: filesPath
    }
    if (files.length > 0) {
      getCheckFilesDuplicate(requestDuplicateIconDTO).then((response) => {
        this.setState({
          filesCheckDuplicates: response.data
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else if (countFilesTimer > 0) {
      clearInterval(countFilesTimer)
      this.setState({ countFilesTimer: 0 })
    }
  }
  getFilesErrors = (item) => {
    const { foldersCheckErrors, childFiles } = this.state

    if (childFiles && childFiles.length > 0) {
      const match = foldersCheckErrors.filter((el) => el.path === item.path)
      if (match && match.length > 0) {
        return match[0].folderHasErrors
      }
    }
    return 0
  }

  onGetFilesCountOfFolders = (childFiles) => {
    const { foldersCountFiles, statusGetFolderCountForFiles } = this.state
    const files = childFiles && childFiles.length > 0 && childFiles.filter((item) => item.type === 'DIRECTORY')
    const pathFiles = []
    if (files && files.length > 0) {
      files.forEach((item) => {
        if (item.path) {
          pathFiles.push(item.path)
        }
      })
      const folderCountFilesDTO = {
        pathList: pathFiles
      }
      this.setState({
        statusGetFolderCountForFiles: false
      })
      if (document.visibilityState === 'visible' && (statusGetFolderCountForFiles === null || statusGetFolderCountForFiles === true)) {
        getFolderCountFiles(folderCountFilesDTO).then((res) => {
          if (res.status === 200) {
            this.setState({
              statusFolderCountFiles: res.status,
              statusGetFolderCountForFiles: true
            })
            if (res.data && res.data.length > 0) {
              this.props.setSmartFolderCountTree(res.data)
              this.setState({foldersCountFiles: res.data})
            }
            if (res.data.length) {
              files && files.length > 0 && files.forEach((file) => {
                res.data && res.data.length > 0 && res.data.forEach((el) => {
                  if (file.path === el.path) {
                    file.numberOfFiles = el.numberOfFiles
                  }
                })
              })
              let changed = false
              if (res.data.length !== foldersCountFiles.length) {
                changed = true
              } else {
                res.data.forEach((item) => {
                  const foundItem = foldersCountFiles.filter((file) => file.path === item.path)
                  if (foundItem.length > 0 && foundItem[0].numberOfFiles !== item.numberOfFiles) {
                    changed = true
                    return
                  }
                })
              }
              if (changed) {
                this.setState({foldersCountFiles: res.data})
              }
            }
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else if (error?.response?.status === 403) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else {
            console.log(error)
          }
        })
      }
    }
  }

  refreshFilesCountOfFolders = () => {
    const { childFiles, countFilesTimer, documentBytes } = this.state
    const { foldersCountFiles } = this.state
    const files = childFiles && childFiles.length > 0 && childFiles.filter((item) => item.type === 'DIRECTORY')
    const pathFiles = []
    if (files && files.length > 0) {
      files.forEach((item) => {
        if (item.path) {
          pathFiles.push(item.path)
        }
      })
      const folderCountFilesDTO = {
        pathList: pathFiles
      }
      if (document.visibilityState === 'visible') {
        if (!documentBytes) {

        } else {

        }
          getFolderCountFiles(folderCountFilesDTO).then((res) => {
            // this.setState({
            //   checkRedactionCount: false
            // })
            this.setState({
              statusFolderCountFiles: res.status
            })
            if (res.data && res.data.length > 0) {
              this.props.setSmartFolderCountTree(res.data)
              this.setState({foldersCountFiles: res.data})
            }
            if (res.data.length) {
              files && files.length > 0 && files.forEach((file) => {
                res.data && res.data.length > 0 && res.data.forEach((el) => {
                  if (file.path === el.path) {
                    file.numberOfFiles = el.numberOfFiles
                  }
                })
              })
              let changed = false
              if (res.data.length !== foldersCountFiles.length) {
                changed = true
              } else {
                res.data.forEach((item) => {
                  const foundItem = foldersCountFiles.filter((file) => file.path === item.path)
                  if (foundItem.length > 0 && foundItem[0].numberOfFiles !== item.numberOfFiles) {
                    changed = true
                    return
                  }
                })
              }
              if (changed) {
                this.setState({foldersCountFiles: res.data})
              }
            }
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            } else if (error?.response?.status === 403) {
              CustomNotificationManager.cleanBuffer('warning', '')
            } else {
              console.log(error)
            }
          })
      }
    } else if (countFilesTimer > 0) {
      clearInterval(countFilesTimer)
      this.setState({ countFilesTimer: 0 })
    }
  }
  retrieveFilesCountOfFolders = () => {
    const { childFiles, countFilesTimer, documentBytes } = this.state
    const { foldersCountFiles } = this.state
    const files = childFiles && childFiles.length > 0 && childFiles.filter((item) => item.type === 'DIRECTORY')
    const pathFiles = []
    if (files && files.length > 0) {
      files.forEach((item) => {
        if (item.path) {
          pathFiles.push(item.path)
        }
      })
      const folderCountFilesDTO = {
        pathList: pathFiles
      }
      if (document.visibilityState === 'visible') {
        if (!documentBytes) {

        } else {

        }
        if (!documentBytes) {
          getFolderCountFiles(folderCountFilesDTO).then((res) => {
            // this.setState({
            //   checkRedactionCount: false
            // })
            this.setState({
              statusFolderCountFiles: res.status
            })
            if (res.data && res.data.length > 0) {
              this.props.setSmartFolderCountTree(res.data)
              this.setState({foldersCountFiles: res.data})
            }
            if (res.data.length) {
              files && files.length > 0 && files.forEach((file) => {
                res.data && res.data.length > 0 && res.data.forEach((el) => {
                  if (file.path === el.path) {
                    file.numberOfFiles = el.numberOfFiles
                  }
                })
              })
              let changed = false
              if (res.data.length !== foldersCountFiles.length) {
                changed = true
              } else {
                res.data.forEach((item) => {
                  const foundItem = foldersCountFiles.filter((file) => file.path === item.path)
                  if (foundItem.length > 0 && foundItem[0].numberOfFiles !== item.numberOfFiles) {
                    changed = true
                    return
                  }
                })
              }
              if (changed) {
                this.setState({foldersCountFiles: res.data})
              }
            }
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            } else if (error?.response?.status === 403) {
              CustomNotificationManager.cleanBuffer('warning', '')
            } else {
              console.log(error)
            }
          })
        }
      }
    } else if (countFilesTimer > 0) {
      clearInterval(countFilesTimer)
      this.setState({ countFilesTimer: 0 })
    }
  }
  extractFilesErrorConverted = (files) => {
    const filesError = []
    let filesErrorFinally = []
    files && files.length > 0 && files.forEach((file) => {
      if (file.type !== 'DIRECTORY' && file.errorMessage && file.errorMessage !== 'No error' && file.errorMessage !== null) {
        filesError.push({
          descriptionActionEvent: file.errorMessage,
          nameActionEvent: file.name,
          status: file.errorMessage,
          timesEvent: moment().format('DD/MM/YYYY HH:mm:ss')
        })
      }
    })
    filesErrorFinally = filesError.concat(this.props.smartBoxFilesErrorConverted)
    const uniqueFiles = filesErrorFinally && filesErrorFinally.length > 0 && filesErrorFinally.filter((a, i) => filesErrorFinally.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
    this.props.setSmartFilesErrorNotBeConverted(uniqueFiles)
  }
  fetchFolderInfo = (afterUpload = false) => {
    const { currentPage, documentsStartNr, closeRedactionStatus, checkCloseSmartViewForPagination, responseFilesAfterUpload, totalFilesBeforeUpload } = this.state
    const { smartBoxPageLimit } = this.props
    getSmartBoxFileById(this.state.folderId).then((response) => {
      if (response.status === 200) {
        if (!response.data.path && !response.data.smartDocument) {
          CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
          const path = '/smart-box'
          this.props.navigate(path)
          return true
        }
        this.setState({urlsFolderPaths: response.data.path})
        const folderData = response.data.smartDocument
        if (Object.keys(response.data.path).length === 1) {
          this.props.setSmartBoxParentFolderEmpty(response.data.empty)
          this.props.setSmartBoxParentFolderErrors(response.data.hasProcessingErrors)
        }

        if (response.data.smartDocumentNewDTO) {
          folderData.depth = response.data.smartDocumentNewDTO.depth
        }

        // if (folderData.relativePath.substr(1).indexOf('/') !== -1) {
        //   this.props.setSmartBoxAnalysisPercent(100)
        // }
        let initFilter = {}
        //get data per page
        let viewFileError = {}
        viewFileError = cookie.load('errorReport')
        if (this.props.smartBoxFilterRefreshBox && this.props.smartBoxFilterRefreshBox.filter && response.data.smartDocument.path && this.props.smartBoxFilterRefreshBox.folderPath && this.props.smartBoxFilterRefreshBox.folderPath === response.data.smartDocument.path) {
          initFilter = {
            documentsFolderPath: `${response.data.smartDocument.relativePath}`.substr(1),
            pageNumber: viewFileError !== undefined && Object.keys(viewFileError) && Object.keys(viewFileError).length > 0 ? viewFileError.pageNr : currentPage,
            filter: this.props.smartBoxFilterRefreshBox.filter,
            resultsPerPage: smartBoxPageLimit,
            folderTree: false,
            sort: this.props.smartBoxCurrentSortOption !== 'creation date' ? this.props.smartBoxCurrentSortOption : this.props.currentNewFiltersSort,
            search: this.props.smartBoxFilterRefreshBox.search,
            documentsStartNr: documentsStartNr,
            statusFilters: this.props.currentNewFilters.checkedStatus ? this.props.currentNewFilters.checkedStatus : [],
            tagFilters: this.props.currentNewFilters.checkedTag ? this.props.currentNewFilters.checkedTag : [],
          }
        } else if (this.props.smartBoxFilterRefreshBox && this.props.smartBoxFilterRefreshBox.search && response.data.smartDocument.path && this.props.smartBoxFilterRefreshBox.folderPath && this.props.smartBoxFilterRefreshBox.folderPath === response.data.smartDocument.path) {
          initFilter = {
            documentsFolderPath: `${response.data.smartDocument.relativePath}`.substr(1),
            pageNumber: viewFileError !== undefined && Object.keys(viewFileError) && Object.keys(viewFileError).length > 0 ? viewFileError.pageNr : currentPage,
            filter: this.props.smartBoxFilterRefreshBox.filter,
            resultsPerPage: smartBoxPageLimit,
            folderTree: false,
            sort: this.props.smartBoxCurrentSortOption !== 'creation date' ? this.props.smartBoxCurrentSortOption : this.props.currentNewFiltersSort,
            search: this.props.smartBoxFilterRefreshBox.search,
            documentsStartNr: closeRedactionStatus ? null : this.props.smartBoxFilterRefreshBox.search ? null : documentsStartNr,
            statusFilters: this.props.currentNewFilters.checkedStatus ? this.props.currentNewFilters.checkedStatus : [],
            tagFilters: this.props.currentNewFilters.checkedTag ? this.props.currentNewFilters.checkedTag : [],
          }
        } else {
          initFilter = {
            documentsFolderPath: `${response.data.smartDocument.relativePath}`.substr(1),
            pageNumber: viewFileError !== undefined && Object.keys(viewFileError) && Object.keys(viewFileError).length > 0 ? viewFileError.pageNr :
                this.props.setPreviousCurrentPage && this.props.setPreviousCurrentPage.statusPrevious === 'previous' ? this.props.setPreviousCurrentPage.previousPage
                    : currentPage,
            resultsPerPage: smartBoxPageLimit,
            folderTree: false,
            sort: this.props.smartBoxCurrentSortOption !== 'creation date' ? this.props.smartBoxCurrentSortOption : this.props.currentNewFiltersSort,
            documentsStartNr: documentsStartNr,
            statusFilters: this.props.currentNewFilters.checkedStatus ? this.props.currentNewFilters.checkedStatus : [],
            tagFilters: this.props.currentNewFilters.checkedTag ? this.props.currentNewFilters.checkedTag : [],
          }
        }
        let basePath = Object.keys(response.data.path)[0]
        const posSecondSlash = basePath.indexOf('/', 1)
        basePath = posSecondSlash === -1 ? basePath.substr(1) : basePath.substr(1, posSecondSlash - 1)
        response.data && response.data.path && Object.keys(response.data.path).forEach((key, index) => {
          if (index === 0) {
            this.setState({
              boxIdBasePath: response.data.path[key]
            })
          }
        })

        this.props.setSmartBoxParentFolderName(basePath)
        if (this.props.setPreviousCurrentPage && this.props.setPreviousCurrentPage.statusPrevious === 'previous') {
          this.setState({
            currentPage: this.props.setPreviousCurrentPage.previousPage
          })
        }
        this.setState({
              loadingOpenedFolder: true,
          statusPagination: null,
              basePath: basePath,
              documentsFolderPath: `${response.data.smartDocument.relativePath}`.substr(1)
              //currentFolderPath: folderData.path
            }, () =>
                getSmartBoxFilesPerPage(initFilter).then((res2) => {
                  if (res2.status === 200) {
                    if (!afterUpload) {
                       this.setState({
                         totalFilesBeforeUpload: res2.data.files.length
                       })
                    } else if (res2.data.files && res2.data.files.length > 0 && res2.data.files.length === responseFilesAfterUpload) {
                      this.setState({
                        totalFilesBeforeUpload: res2.data.files.length,
                      })
                    }
                    if (res2.data.files && res2.data.files.length > 0 && res2.data.files.length < responseFilesAfterUpload) {
                      this.setState( {
                        statusFilesAfterUpload: false
                      })
                    }
                    this.extractFilesErrorConverted(res2.data.files)
                    if (this.props.setPreviousCurrentPage && this.props.setPreviousCurrentPage.statusPrevious === 'previous') {
                      this.props.setSmartBoxPreviousCurrentPage(currentPage, 'initial')
                      // this.setState({
                      //   currentPage: this.props.setPreviousCurrentPage.previousPage
                      // })
                    }
                    this.setState({
                      folder: folderData,
                      childFiles: res2.data.files,
                      paths: response.data.path,
                      loadingOpenedFolder: false,
                      //listKeysFiles: [],
                      checkKeysFiles: false,
                      totalPagesForPagination: res2.data.total,
                      totalFolderOnRoot: res2.data.totalFolders,
                      documentsStartNr: res2.data.documentsStartNr,
                      closeRedactionStatus: false,
                      statusPagination: res2.status
                    }, () => {
                      this.checkAiStatus()
                      this.getTumbnails()
                      this.updateProgressBarData()
                      this.onGetFilesSelectedErrorFile()
                      this.getCheckFileProprietiesRequest(afterUpload)
                      this.props.setSortSelectionCloseSmartview(false)

                      let {countFilesTimer, countProprietiesTimer} = this.state
                      // console.log(countFilesTimer)
                      this.getListCheckDuplicate()
                      if (countProprietiesTimer === 0) {
                        countProprietiesTimer = setInterval(() => {
                          this.getCheckFileProprietiesRequest(true)
                        }, 10000)
                        this.setState({
                          countProprietiesTimer
                        })
                      }
                      if (countFilesTimer === 0) {
                        this.retrieveFilesCountOfFolders()
                        this.getListFoldersCheckErrors()
                        countFilesTimer = setInterval(this.retrieveFilesCountOfFolders, 10000)
                        this.setState({countFilesTimer})
                      }
                    })
                    this.props.setSmartBoxFilesTable(res2.data.files)
                  }
                }).catch((error) => {
                  if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                  } else {
                    console.log(error)
                  }
                })
        )
      }
    }).catch((error) => {
      if (error?.response?.status === 500 && error?.response?.data?.detail === 'You don\'t have access to this folder') {
        CustomNotificationManager.error('You don\'t have access to this folder', 'Error')
        const path = '/smart-box'
        this.props.navigate(path)
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onGetFilesSelectedErrorFile = () => {
    let viewFileError = {}
    viewFileError = cookie.load('errorReport')
    const fileError = []
    if (viewFileError !== undefined && Object.keys(viewFileError) && Object.keys(viewFileError).length > 0) {
      fileError.push(viewFileError.documentId)
      this.setState({
        currentPage: viewFileError.pageNr,
        listKeysFiles: fileError,
        listKeysFilesDeleted: fileError
      }, () => {
        cookie.remove('errorReport', {path: '/'})
      })
    }
  }
  onGetShowExistsFilesWarningsNotification = (response) => {
    const { idParentBox } = this.state
    const filesWarnings = []
    if (response && Object.keys(response) && Object.keys(response).length > 0) {
      this.props.smartBoxBaseDataTree && this.props.smartBoxBaseDataTree.length > 0 && this.props.smartBoxBaseDataTree.forEach((elem) => {
           if (idParentBox && idParentBox === elem.id) {
             response.statisticsReady = elem.statisticsReady
           }
      })
      if (response.showWarning) {
        filesWarnings.push({
          nameActionEvent: response.boxName,
          descriptionActionEvent: `Warning, errors have been detected in one or more of the original PDF files.`,
          timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
          status: 'file-warning',
          nameBox: response.boxName,
          boxId: response.id,
          statisticsReady: response.statisticsReady,
        })
      }
    }
    // response && response.length > 0 && response.forEach((item) => {
    //   if (item.show_exists_files_with_warnings_notification === 'true') {
    //     filesWarnings.push({
    //       nameActionEvent: item.boxName,
    //       descriptionActionEvent: `Warning, errors have detected in one or more of the original PDF files`,
    //       timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
    //       status: 'file-warning',
    //       nameBox: item.boxName,
    //       boxId: item.id,
    //     })
    //   }
    // })
    this.props.setSmartBoxExistsFilesWarningsNotification(filesWarnings)
  }
  updateProgressBarData = () => {
    const { folder } = this.state
    if (folder && folder.path && folder.id) {
      const data = [{folderPath: folder.path, id: folder.id}]
       const progressBarDataDTO = {
         boxFolders: data
       }
      if (folder.relativePath.substr(1).indexOf('/') === -1) {
        if (document.visibilityState === 'visible') {
          getProgressBarData(progressBarDataDTO).then((res) => {
            //let percent = -1
            let percentUpdate = -1
            if (res.data && res.data.length > 0) {
              const data = res.data[0]
              //percent = (data.analysed + data.failedForAI) * 100 / data.nrOfDocuments
              //percent = parseInt(percent.toFixed(0))
              percentUpdate = (data.processed) * 100 / data.nrOfDocuments
              percentUpdate = parseInt(percentUpdate.toFixed(0))
            }
            this.props.setSmartBoxAnalysisPercent(percentUpdate)
            this.setState({
              statusProgressBarData: res.status
            })
            // if (res.data && res.data.length > 0) {
            //   this.onGetShowExistsFilesWarningsNotification(res.data)
            // }
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            } else {
              console.log(error)
            }
          })
        }
      }
    }
  }

  checkAiStatus = () => {
    const { folder, childFiles, currentLocation, isIntervalStartedForAi, isIntervalStartedForPdf, processedFiles, convertedFiles } = { ...this.state }
    const { intervalForAiPages, intervalForPdfPages } = { ...this.props }

    if (
      childFiles.filter((file) => file.aiFinished === 'true' || file.type === 'DIRECTORY' || file.pdfValidation === 'false').length !== childFiles.length
      && !isIntervalStartedForAi
      && !intervalForAiPages.includes(this.props.location.pathname
      )) {
      this.setState({
        isIntervalStartedForAi: true
      }, () => {
        this.props.setSmartBoxIntervalForAiPages(this.props.location.pathname)
        const aiReadyInterval = setInterval(() => {
          if (window.location.pathname.includes('login')) {
            clearInterval(aiReadyInterval)
          } else {
            //let addAnalysis = []
            // const analysisComplete = [{
            //   nameActionEvent: 'Analysis complete',
            //   descriptionActionEvent: `${ folder.name }`,
            //   timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
            //   status: 'box',
            //   documentName: folder.name
            // }]
            //addAnalysis = analysisComplete.concat(this.props.smartBoxNotAnalysisComplete)
            //const uniqueBoxAnalysis = addAnalysis && addAnalysis.length > 0 && addAnalysis.filter((a, i) => addAnalysis.findIndex((s) => a.documentName === s.documentName) === i)
            //this.props.setSmartBoxNotAnalysisComplete(uniqueBoxAnalysis)
            const folderHasAiApiDTO = {
              folder: `${ folder.path }/`
            }
            apiSmartBoxCheckAiFolderStatus(folderHasAiApiDTO).then((res) => {
              if (res.data) {
                // const messageText = <span>{ 'AI annotations are ready, go to  ' }<span
                //   style={ { textDecoration: 'underline', cursor: 'pointer' } }><span
                //     onClick={ () => this.props.navigate(`/smart-box/folder/${ folder.id }`) }>{ folder.name }</span></span> to continue redacting.</span>
                if (!processedFiles.includes(folder.id)) {
                  // notification.success({ message: messageText, duration: 5000 })
                  // const notifierTitle = 'AI annotations are ready'
                  // const notifierText= `AI annotations are ready, go to ${ folder.name } to continue redacting.`
                  // const notifierUrl = `/smart-box/folder/${ folder.id }`
                  // this.refWrapperNotification.showNotification(notifierTitle, notifierText, notifierUrl)
                  processedFiles.push(folder.id)
                  this.props.setSmartBoxDataReady('true', folder)
                  let addAnalysis = []
                  const analysisComplete = [{
                    nameActionEvent: 'Analysis complete',
                    descriptionActionEvent: `${ folder.name }`,
                    timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
                    status: 'box',
                    documentName: folder.name
                  }]
                  addAnalysis = analysisComplete.concat(this.props.smartBoxNotAnalysisComplete)
                  const uniqueBoxAnalysis = addAnalysis && addAnalysis.length > 0 && addAnalysis.filter((a, i) => addAnalysis.findIndex((s) => a.documentName === s.documentName) === i)
                  this.props.setSmartBoxNotAnalysisComplete(uniqueBoxAnalysis)
                }
                this.props.removeSmartBoxIntervalForAiPages(this.props.intervalForAiPages.filter((path) => path !== currentLocation))

                if (window.location.pathname === currentLocation) {
                  this.setState({
                    isIntervalStartedForAi: false
                  }, () => this.fetchFolderInfo())
                }

                clearInterval(aiReadyInterval)
              }
          if (res.data) {
            // const analysisComplete = [{
            //   nameActionEvent: 'Analysis complete',
            //   descriptionActionEvent: `${ folder.name }`,
            //   timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
            //   status: 'box',
            //   documentName: folder.name
            // }]
            //const addAnalysis = analysisComplete.concat(this.props.smartBoxNotAnalysisComplete)
            //const uniqueBoxAnalysis = addAnalysis && addAnalysis.length > 0 && addAnalysis.filter((a, i) => addAnalysis.findIndex((s) => a.documentName === s.documentName) === i)
            //this.props.setSmartBoxNotAnalysisComplete(uniqueBoxAnalysis)
          }
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
              clearInterval(aiReadyInterval)
            })

            apiSmartBoxFolderHasWarningFilesNotification(folderHasAiApiDTO).then((response) => {
              if (Object.keys(response.data) && Object.keys(response.data).length > 0) {
                this.onGetShowExistsFilesWarningsNotification(response.data)
              }
            })
          }
        }, 30000)

        this.props.addIntervalToApplication(aiReadyInterval)
      })
    }

    if (
      childFiles.filter((file) => file.isPdf === 'true' || file.type === 'DIRECTORY' || file.pdfValidation === 'false').length !== childFiles.length
      && !isIntervalStartedForPdf
      && !intervalForPdfPages.includes(this.props.location.pathname)) {
      this.setState({
        isIntervalStartedForPdf: true
      }, () => {
        this.props.setSmartBoxIntervalForPdfPages(this.props.location.pathname)
        const pdfReadyInterval = setInterval(() => {
          if (window.location.pathname.includes('login')) {
            clearInterval(pdfReadyInterval)
          } else {
            const folderIsConvertedApiDTO = {
              folder: `${ folder.path }/`
            }
            apiSmartBoxCheckPdfFolderStatus(folderIsConvertedApiDTO).then((res) => {
              if (res.data) {
                // const messageText = <span>{ 'Redaction mode is ready, go to ' }<span
                //   style={ { textDecoration: 'underline', cursor: 'pointer' } }><span
                //     onClick={ () => this.props.navigate(`/smart-box/folder/${ folder.id }`) }>{ folder.name }</span></span> to continue redacting.</span>
                if (!convertedFiles.includes(folder.id)) {
                  // notification.success({ message: messageText, duration: 5000 })
                  // const notifierTitle = 'Redaction mode is ready'
                  // const notifierText = `Redaction mode is ready, go to ${ folder.name } to continue redacting.`
                  // const notifierUrl = `/smart-box/folder/${ folder.id }`
                  // this.refWrapperNotification.showNotification(notifierTitle, notifierText, notifierUrl)
                  convertedFiles.push(folder.id)
                }
                this.props.removeSmartBoxIntervalForPdfPages(this.props.intervalForPdfPages.filter((path) => path !== currentLocation))

                if (window.location.pathname === currentLocation) {
                  this.setState({
                    isIntervalStartedForPdf: false
                  }, () => this.fetchFolderInfo())
                }

                clearInterval(pdfReadyInterval)
              }
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
              clearInterval(pdfReadyInterval)
            })
          }
        }, 10000)
        this.props.addIntervalToApplication(pdfReadyInterval)
      })
    }
  }

  adjustFilesTable(files) {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        files[i].key = files[i].id
        if (files[i].name.includes('.')) {
          //files[i].name = files[i].name.substring(0, files[i].name.lastIndexOf('.'))
          files[i].nameFileWithoutExtension = files[i].name.substring(0, files[i].name.lastIndexOf('.'))
        }
      }
      return files
    }
    return []
  }

  handleDeleteFile = (file) => {
    const { currentPage, totalPagesForPagination, folder, documentsStartNr } = this.state
    const { smartBoxPageLimit } = this.props
    const remainingFilesOnLastPage = totalPagesForPagination % smartBoxPageLimit - 1
    const parameters = {
      documentsFolderPath: folder.relativePath,
      pageNumber: currentPage,
      resultsPerPage: smartBoxPageLimit,
      sort: this.props.smartBoxCurrentSortOption,
      folderTree: false,
      documentsStartNr: documentsStartNr
    }
    getSmartBoxFilesPerPage(parameters).then((response) => {
      if (response.status === 200) {
        this.setState({ totalPagesForPagination: response.data.total,
          totalFolderOnRoot: response.data.totalFolders,
              documentsStartNr: response.data.documentsStartNr,
            },
        () => {
          if ((remainingFilesOnLastPage === 0) && (Math.ceil(response.data.total / smartBoxPageLimit) < currentPage)) {
            const data = {
              currentPage: Math.ceil(response.data.total / smartBoxPageLimit) ? Math.ceil(response.data.total / smartBoxPageLimit) : 1,
              pageLimit: smartBoxPageLimit,
            }
            this.onPageChanged(data)
          } else {
            const data = {
              currentPage: currentPage,
              pageLimit: smartBoxPageLimit,
            }
            this.onPageChanged(data)
          }
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
    this.setState({ childFiles: deleteFolderOrFile(file, this.state.childFiles) })
  }

  handlePreview = (file) => {
    this.setState({ loading: true }, () => {
      apiSmartBoxPreviewSarDocByThumbnailId(file.id).then((response) => {
        const { doc, id, totalPages, landscape, width, height, name, finalizedDocPath, systemPropertyFileStatus, doc_classification, visibleToDownload,
          systemPropertyFileColor, systemPropertyFileIcon, xeroxUpload, fileWarnings, lockSmartViewChanges, boxRedactionColorProfileId } = response.data
        const spliterPosition = name.indexOf('-') + 1
        if(doc === '') {
          CustomNotificationManager.error('Error occured while loading the file!', 'Error')
        } else {
          this.props.setHeaderVisibility(false)
          this.props.setSidebarVisibility(false)
          this.props.setFooterVisibility(false)
          //const isOcrNew = "true"
          this.setState({
            documentBytes: doc,
            documentId: id,
            totalPages: totalPages,
            landscape,
            width: width,
            height: height,
            currentFileId: file.id,
            initialName: name.substr(spliterPosition),
            smartBoxDocumentId: `${ file.folderPath + file.name }`,
            smartBoxDocumentPath: `${ file.folderPath + file.name }`,
            nameFileOpenInRedaction: `${ file.name }`,
            previewVisible: true,
            showCloseButton: false,
            loading: false,
            //isOcr: file.isOcr !== 'false',
            //isOcr: true,
            isOcr: false,
            //isOcr: isOcrNew !== 'false',
            finalizedDocPath,
            systemPropertyFileStatus,
            doc_classification,
            visibleToDownload,
            systemPropertyFileColor,
            systemPropertyFileIcon,
            fileWarnings,
            xeroxUpload: file.xeroxUpload === null ? false : file.xeroxUpload !== 'false',
            //xeroxUpload: false,
            lockSmartViewChanges,
            boxRedactionColorProfileId
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  };
  handlePreviewDocumentNext = (file) => {
    this.handlePreview(file)
  }

  handlePreviewClicked(file) {
    swal.fire({
      text: `This file has ${ file.totalPages } pages. Are you sure you want to open in Smartview ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.handlePreview(file)
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  closeRedacting = (obj) => {
    this.props.setHeaderVisibility(true)
    this.props.setSidebarVisibility(true)
    this.props.setFooterVisibility(true)
    this.props.setSmartBoxSelectedFiles(this.state.listSelectedFiles)
    if (this.state.listKeysFiles && this.state.listKeysFiles.length === 1) {
      this.setState({
        listKeysFilesDeleted: this.state.listKeysFiles
      })
    }
    this.setState({
      documentBytes: null,
      searchText: this.props.smartBoxSearchedText,
      checkCloseSmartViewForPagination: true,
      closeRedactionStatus: true,
      actionNextPreviousSmartbox: false,
    }, () => {
      window.URL.revokeObjectURL(obj)

      const helpBtn = document.querySelector('.icon-v12-nav-help')
      helpBtn.addEventListener('click', event => { window.FreshworksWidget('open') })
    })
    this.props.setSortSelectionCloseSmartview(true)
    this.props.setSmartBoxSelectedFiles(this.state.listSelectedFiles)
    if (this.state.viewMode !== 'grid') {
      this.fetchFolderInfo()
    }
  }
  closeRedactingNext = (obj) => {
    this.setState({
      documentBytes: null,
      searchText: this.props.smartBoxSearchedText,
      checkCloseSmartViewForPagination: true,
      closeRedactionStatus: true,
      actionNextPreviousSmartbox: true
    }, () => {
      window.URL.revokeObjectURL(obj)

    })
  }


  isUnique = (newFileName) => {
    const { childFiles } = this.state
    let response = true
    if (childFiles && childFiles.length) {
      childFiles.forEach((item) => {
        if (item.name === newFileName) {
          response = false
        }
      })
    }
    return response
  }

  handleNewFolderClick = () => this.setState({ newFolderClicked: true })

  handleNewFolderKeyPressed = (e) => {

    if (e.charCode !== 13) return

    const { validNewFolderName } = this.state
    e.stopPropagation()
    let { childFiles } = this.state
    const nextId = (new Date()).getTime()
    const newFolderName = e.target.value

    const newFolder = {
      'id': nextId,
      'key': nextId,
      'name': newFolderName,
      'type': 'DIRECTORY',
      'modified': moment().format('D.MM.YYYY hh:mm:ss'),
      'fromFolder': this.state.folder.relativePath.substr(1)
    }
    const nameRgex = /^[A-Za-z\d_\s]+$/
    if (nameRgex.test(newFolderName)) {
      validNewFolderName.success = true
      this.setState({
        disabledOnClicked: true,
        validNewFolderName
      })
      createSmartDocument(newFolder).then((res) => {
        if(res.data.title && res.data.title.toLowerCase() === 'success'){
          CustomNotificationManager.success(res.data.message, 'Success')
        }else if(res.data.title && res.data.title.toLowerCase() === 'info'){
          CustomNotificationManager.info(res.data.message, 'Info')
          this.setState({
            newFolderClicked: false,
            disabledOnClicked: false
          })
        } else {
          childFiles = [res.data, ...childFiles]
          this.setState({
            childFiles,
            newFolderClicked: false,
            disabledOnClicked: false
          }, () => {
            //this.fetchFolderInfo()
            setTimeout(() => {
              this.fetchFolderInfo()
            }, 200);
          })
          this.props.setSmartBoxNewNode({
            id: res.data.id,
            name: newFolderName,
            path: res.data.path,
            parentId: this.state.folderId,
          })
        }
        // if (res.data && res.data !== 'Directory exists!') {
        //   childFiles = [res.data, ...childFiles]
        //   this.setState({
        //     childFiles,
        //     newFolderClicked: false,
        //     disabledOnClicked: false
        //   }, () => {
        //     //this.fetchFolderInfo()
        //     setTimeout(() => {
        //       this.fetchFolderInfo()
        //     }, 200);
        //   })
        //   this.props.setSmartBoxNewNode({
        //     id: res.data.id,
        //     name: newFolderName,
        //     path: res.data.path,
        //     parentId: this.state.folderId,
        //   })
        // } if (res.data === 'Directory exists!') {
        //   CustomNotificationManager.info('Already exists', 'Cancelled')
        //   this.setState({
        //     newFolderClicked: false,
        //     disabledOnClicked: false
        //   })
        // }
      }).catch((error) => {
        if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
        }
        this.setState({
          newFolderClicked: false,
          disabledOnClicked: false
        }, () => {
          if (e.response.data.message) {
            if (e.response.data.message.includes('This name already exists')) {
              CustomNotificationManager.warning('This name already exists', 'Already exist')
            }
          }
        })
      })
    } else {
      if (!nameRgex.test(e.target.value)) {
        validNewFolderName.reason = 'Invalid name'
      }
      validNewFolderName.success = false
      this.setState({ validNewFolderName })
    }
  }

  removeNewFolderFormView = () => {
    this.setState({
      newFolderClicked: false,
    })
    if (this.state.childFiles && this.state.childFiles.length > 0) {
      this.setState({
        childFiles: this.state.childFiles.filter((file) => file.id !== 0)
      })
    }
  }

  setFolderFromChildren = (folderId) => {
    this.setState({ folderId }, () => {
      this.fetchFolderInfo()
    })
  }

  customUploadFolder = (e) => {
    const { newFolderClicked, childFiles, folder } = this.state
    let { folderNewDepth } = this.state
    const emptyFolder= newFolderClicked === false && (childFiles === null || childFiles.length === 0)
    folderNewDepth = this.onActiveFolderDepth()
    const newFileList = {}
    if (e.target.files) {
      if (emptyFolder && folderNewDepth === 1) {
        this.props.setSmartBoxNotificationButtonFirstUpload(true, folder.nameWithoutExtension)
      } else {
        this.props.setSmartBoxNotificationButtonFirstUpload(false, '')
      }
      this.props.setSmartBoxDataReady('false', folder )

      if (Object.keys(e.target.files).length) {
        Object.keys(e.target.files).forEach((item, index) => {
          const file = e.target.files[index]
          const extension = file.name.split('.').pop().toLowerCase()
          const newWebkitRelativePath = `/${ file.webkitRelativePath.substr(0, file.webkitRelativePath.lastIndexOf('.')) }.${ extension }`
          const blob = file.slice(0, file.size, file.type)
          blob.name = file.name
          const newFile = new File([blob], file.name, {
            type: file.type,
            webkitRelativePath: newWebkitRelativePath,
            lastModified: file.lastModified
          })
          newFileList[index] = newFile
        })
      }
      this.worker.postMessage(Object.values(newFileList))
    }
  }

  toggleFilePreview = (open, selectedDoc) => {
    const selectedFilesInfo = []
    selectedFilesInfo.push(selectedDoc.id)
    if (open) {
      this.setState({ sidebarClass: 'sidebar-class', contentClass: 'small-content', sidebarOpen: open, selectedDoc, listKeysFiles: selectedFilesInfo, listKeysFilesDeleted: selectedFilesInfo })
    } else {
      this.setState({ sidebarClass: '', contentClass: '', sidebarOpen: open, listKeysFiles: [], listKeysFilesDeleted: [] })
    }
  }

  fetchFiles = () => {
    const initFilter = {
      'docFilename': this.state.searchText.trim(),
      'docFolderPath': this.state.folder.relativePath.substr(1)
    }
    getSmartBoxFilesFilter(initFilter).then((res) => {
      this.setState({
        childFiles: res.data,
        baseFolders: res.data,
        basePath: res.data.length ? res.data[0].folderPath : ''
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  };

  fetchFilesForSearch = () => {
    const initFilter = {
      'docFilename': this.state.searchText.trim(),
      'docFolderPath': this.state.folder.relativePath.substr(1)
    }
    getSmartBoxFilesFilterForSearch(initFilter).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].root = true
      }
      this.setState({
        childFiles: res.data,
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  fetchFilesOnSearch = (e) => {
    if (e.key === 'Enter') {
      this.fetchFilesForSearch()
    }
  }

  handleSearchInputChange = (e) => {
    const searchedText = e.target.value

    this.setState({
      searchText: searchedText
    }, () => {
      this.props.setSmartBoxSearchedWord(this.state.searchText)
      if (searchedText === '') {
        this.fetchFiles()
      }
    })
  }

  handleChangeViewMode = () => {
    this.props.setSmartBoxViewMode(this.props.smartBoxViewMode === 'grid' ? 'list' : 'grid')
    this.toggleFilePreview(false, {})
  }

  onFilePressed = (file) => {
    if (file.isPdf === 'true') {
      file.totalPages > 20 ? this.handlePreviewClicked(file) : this.handlePreview(file)
    }
  }

  addOrRemoveValue = (file) => {

    let { listKeysFiles, listSelectedPathFiles, listSelectedNameFiles, listSelectedNameId, listSelectedFiles, listSelectedNameIdCutPaste, listKeysFilesDeleted, childFiles } = this.state
    const { folder } = this.state
    const fileId = file.id
    const folderPath = file.folderPath
    const nameFile = file.name
    const nameId = file.id
    const folderId = folder.id
    const type = file.type
    const idsAll = []
    childFiles && childFiles.length > 0 && childFiles.forEach((file2) => {
      idsAll.push(file2.id)
      // if (!listKeysFiles.includes(file2.id)) {
      //   listKeysFiles = []
      // }
    })
    let lastFileClickedAction
    if (listKeysFiles.includes(fileId)) {
      listKeysFiles = listKeysFiles ? listKeysFiles.filter((key) => key !== fileId) : ''
      listKeysFilesDeleted = listKeysFilesDeleted ? listKeysFilesDeleted.filter((key) => key !== fileId) : ''
      listSelectedPathFiles = listSelectedPathFiles ? listSelectedPathFiles.filter((key) => key !== folderPath) : ''
      listSelectedNameFiles = listSelectedNameFiles ? listSelectedNameFiles.filter((key) => key !== `${ nameFile }.${ type }`) : ''
      listSelectedNameId = listSelectedNameId ? listSelectedNameId.filter((key) => key !== nameId) : ''
      listSelectedNameIdCutPaste = listSelectedNameIdCutPaste ? listSelectedNameIdCutPaste.filter((key) => key !== nameId) : ''
      listSelectedFiles = listSelectedFiles ? listSelectedFiles.filter((key) => key.id !== nameId) : ''
      lastFileClickedAction = 'remove'
      this.props.setSmartBoxSelectedKeys([])
    } else {
      listKeysFiles.push(fileId)
      listKeysFilesDeleted.push(fileId)
      listSelectedPathFiles.push(folderPath)
      listSelectedNameFiles.push(`${ nameFile }.${ type }`)
      listSelectedNameIdCutPaste.push(nameId)
      listSelectedFiles.push(file)
      lastFileClickedAction = 'add'
    }
    //this trigger will trigger components
    const tempListKeysFiles = JSON.parse(JSON.stringify(listKeysFiles))
    const tempListKeysFiles2 = JSON.parse(JSON.stringify(listKeysFilesDeleted))

    this.setState({
      listKeysFiles: tempListKeysFiles,
      listKeysFilesDeleted: tempListKeysFiles2,
      listSelectedPathFiles,
      listSelectedNameFiles,
      currentPathSelectedFiles: folderPath,
      currentFolderId: folderId,
      listSelectedNameId,
      listSelectedFiles,
      listSelectedNameIdCutPaste,
      lastFileClicked: file.id,
      lastFileClickedAction
    }, () => {
      this.props.setSmartBoxSelectedFiles(this.state.listSelectedFiles)
    })
  }

  onSendStatusPaste = (status) => {
    this.setState({
      checkStatusPaste: status
    })
    if (!status) {
      this.setState({
        listSelectedNameFiles: [],
        listSelectedNameId: [],
        listSelectedNameIdCutPaste: []
      })
    }
  }

  onRefresh = () => {
    const { pageNumber, resultsPerPage } = this.state
    const data = {
      currentPage: pageNumber,
      pageLimit: resultsPerPage,
    }
    this.onPageChanged(data)
  }

  onPageChanged = (newData) => {
    const { folder, documentsStartNr } = this.state
    const { smartBoxPageLimit } = this.props
    if (Object.keys(newData).length) {
      this.setState({
        newDataFilters: newData
      })
      //this.props.smartBoxCurrentSortOption !== 'creation date' ? this.props.smartBoxCurrentSortOption
      const initFilter = {
        documentsFolderPath: `${ folder.relativePath }`.substr(1),
        pageNumber: this.props.statusClearAllFiltersSmart ? 1 : newData.currentPage,
        resultsPerPage: smartBoxPageLimit,
        sort: newData.sort !== 'creation date' ? newData.sort : this.props.currentNewFiltersSort,
        filter: newData.filter,
        statusFilters: newData.checkedStatus && newData.checkedStatus.length > 0 ? newData.checkedStatus : this.props.currentNewFilters.checkedStatus,
        tagFilters: newData.checkedTag && newData.checkedTag.length > 0 ? newData.checkedTag : this.props.currentNewFilters.checkedTag,
        search: newData.search ? newData.search : this.props.smartBoxFilterRefreshBox.search ? this.props.smartBoxFilterRefreshBox.search : null,
        folderTree: false,
        documentsStartNr: this.props.smartBoxFilterRefreshBox.search && newData.currentPage === 1 ? null : documentsStartNr,
      }
      this.setState({
        loadingOpenedFolder: true,
            statusPagination: null,
            statusThumbnailForFiles: null,
            //statusFolderCountFiles: null
      }, () =>
        getSmartBoxFilesPerPage(initFilter).then((response) => {
          if (response.status === 200) {
            this.setState({
              folder,
              childFiles: response.data.files,
              paths: folder.path,
              loadingOpenedFolder: false,
              listKeysFiles: [],
              listKeysFilesDeleted: [],
              checkKeysFiles: false,
              currentPage: newData.currentPage,
              // amountPerPage: newData.pageLimit,
              totalPagesForPagination: response.data.total,
              totalFolderOnRoot: response.data.totalFolders,
              documentsStartNr: response.data.documentsStartNr,
              statusResponsePagination: true,
              statusPagination: response.status
            }, () => {
              this.checkAiStatus()
              this.getTumbnails()
              this.updateProgressBarData()
              this.getCheckFileProprietiesRequest()
              this.props.setStatusClearAllFiltersSmartbox(false)
            })
            if (response.data.files && response.data.files.length > 0) {
              this.onGetFilesCountOfFolders(response.data.files)
            }
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      )
    }
  }

  //removeAllSelected = () => this.setState({ listKeysFiles: [] })

  /*onSelectAll = (e) => {
    const selectAllIdFiles = this.state.childFiles.map((file) => file.id)
    this.setState({
      listKeysFiles: e.target.checked ? this.state.childFiles.map((file) => file.id) : [],
      checkKeysFiles: !!e.target.checked,
      listSelectedNameId: selectAllIdFiles,
      listSelectedNameIdCutPaste: selectAllIdFiles,
      listSelectedPathFiles: e.target.checked ? this.state.childFiles.map((file) => file.folderPath) : [],
      listSelectedNameFiles: e.target.checked ? this.state.childFiles.map((file) => file.name + '.' + file.type) : [],
      listSelectedFiles: e.target.checked ? this.state.childFiles : []
    }, () => {
      this.props.setSmartBoxSelectedFiles(this.state.listKeysFiles.length === 0 ? [] : this.state.childFiles)
    })
  }*/

  onSelectAll = (selected) => {
    const selectAllIdFiles = this.state.childFiles.map((file) => file.id)
    this.setState({
      listKeysFiles: selected ? this.state.childFiles.map((file) => file.id) : [],
      listKeysFilesDeleted: selected ? this.state.childFiles.map((file) => file.id) : [],
      checkKeysFiles: !!selected,
      listSelectedNameId: selectAllIdFiles,
      listSelectedNameIdCutPaste: selectAllIdFiles,
      listSelectedPathFiles: selected ? this.state.childFiles.map((file) => file.folderPath) : [],
      listSelectedNameFiles: selected ? this.state.childFiles.map((file) => `${ file.name }.${ file.type }`) : [],
      listSelectedFiles: selected ? this.state.childFiles : []
    }, () => {
      this.props.setSmartBoxSelectedFiles(this.state.listKeysFiles.length === 0 ? [] : this.state.childFiles)
    })
  }

  handleRenameFolder = () => {
    const { folder, newFolderName, newFolderNameValid } = this.state
    const { renameFolderModalVisible } = this.state
    const nameRgex = /^[A-Za-z\d_\s]+$/
    if (newFolderName) {
      newFolderNameValid.success= true
      if (!nameRgex.test(newFolderName) || folder.name === newFolderName) {
        newFolderNameValid.success = false
        if (!nameRgex.test(newFolderName)) {
          newFolderNameValid.reason = 'Invalid name. Please do not use the following characters: /\\?*;:[]\'"|,.<>'
        }
        if (folder.name === newFolderName) {
          newFolderNameValid.reason = 'It\'s old name. Please insert a new one.'
        }
      }
      if (newFolderNameValid.success) {
        const newRenameFolder = {
          id: folder.id,
          name: newFolderName
        }
        getRenameFile(newRenameFolder).then((response) => {
          if (response.status === 200) {
            folder.name= response.data
            const pos = folder.relativePath.lastIndexOf('/')
            const newRelativePathTemp = `${ folder.relativePath.substr(0, pos < 0 ? folder.relativePath.length : pos) }/${ response.data }`
            folder.relativePath = newRelativePathTemp
            this.setState({
              renameFolderModalVisible: false,
              newFolderName: '',
              folder
            }, () => { this.generateSmartBoxMenu('')
              setTimeout(() => {
                this.fetchFolderInfo()
              }, 5000)
            })
          }
        }).catch((error) => {
          const errorMessage = 'Folder id does not exist'
          if (error?.response?.status === 500) {
            if (typeof error.response.data.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
              CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
              this.props.navigate('/smart-box')
            }
          }else if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      } else {
        this.setState({ newFolderName: '', renameFolderModalVisible })
      }

    }

  }
  onCallProgressRename = () => {

  }
  handleChangeFolderName = (e) => this.setState({ newFolderName: e.target.value })

  toggleRenameFolderModal = (open) => this.setState({ renameFolderModalVisible: open })

  toggleCustomerStatusModalVisible = (open) => this.setState({ customerStatusModalVisible: open })

  generateSmartBoxMenu = (activePage= '') => {
    const { folder, paths, smartBoxMenuOpen } = this.state
    const shortBreadcrumbLimit = 4
    let folderDepth = 0
    const path = folder.relativePath
    if (path) {
      folderDepth = path.split('/').length
      folderDepth = folderDepth - 1
      const fileId = (Object.keys(paths).length) ? paths[Object.keys(paths)[0]] : (folder.id ? folder.id : '')
      const pathArray = path.split('/')
      let breadCrumb = []
      let requestPath = ''
      let j = 0
      for (let i = 0; i < pathArray.length; i++) {
        if (pathArray[i]) {
          requestPath = `${ requestPath }/${ pathArray[i] }`
          const fileIdForLink = generateFileForLink(folder.id, paths, requestPath)

          if (i < shortBreadcrumbLimit + 1) {
            breadCrumb = [...breadCrumb, [<span key={j++}>&nbsp;&nbsp;&nbsp;</span>,
              <span key={j++} className="arrow">&rsaquo;</span>, <span key={j++}>&nbsp;&nbsp;&nbsp;</span>]]
          }

          if (path === requestPath) {
            breadCrumb.push(<img key={j++} src={imgFolderSmallOpenParentSmall} alt={path}/>)
          }
          if (i < shortBreadcrumbLimit || i === folderDepth) {
            breadCrumb.push(<span key={j++}>&nbsp;&nbsp;</span>)
            breadCrumb.push(<Link key={j++} to={{ pathname: `/smart-box/folder/${ fileIdForLink }` }}>{pathArray[i]}</Link>)
          } else if (i === shortBreadcrumbLimit && i < folderDepth) {
            breadCrumb.push(<span key={j++}>...&nbsp;&nbsp;&nbsp;&nbsp;</span>)
          }
          if (path === requestPath && !folder.depth) {
            breadCrumb = [...breadCrumb, [<span key={j++} className="arrow">&rsaquo;</span>,
              <span key={j++}>&nbsp;&nbsp;&nbsp;</span>,
              <span ref={ (node) => this.node = node } key={j++}>
                <SmartBoxSettingsMenu
                    folder={folder}
                    fileId={fileId}
                    toggleFilePreview={ () => this.toggleFilePreview(true, folder) }
                    activePage={ activePage }
                    smartBoxMenuOpen={ smartBoxMenuOpen }
                    fetchFiles = { this.fetchFiles }
                    toggleLoadingOpenedFolder = { this.toggleLoadingOpenedFolder }
                    toggleSmartBoxMenu = { this.toggleSmartBoxMenu }
                    toggleRenameFolderModal = { this.toggleRenameFolderModal }
                >
                </SmartBoxSettingsMenu>
              </span>]]
          }
        }
      }
      return breadCrumb
    }
    return ''
  }

  onGetSetSmartBoxCurrentFolder = (e, fileIdForLink) => {
    this.props.setSmartBoxCurrentFolder(fileIdForLink)
    this.props.setActiveDeleteFile(false)
  }
  generateBreadcrumb = () => {
    const { folder, urlsFolderPaths } = this.state
    let urlFolderObject = {}
    const shortBreadcrumbLimit = 5
    let folderDepth = 0
    let updateUrls = true
    const path = folder.relativePath
    if (path) {
      folderDepth = path.split('/').length
      folderDepth = folderDepth - 1
      // const fileId = (Object.keys(paths).length) ? paths[Object.keys(paths)[0]] : (folder.id ? folder.id : '')
      const pathArray = path.split('/')
      let breadCrumb = []
      let requestPath = ''
      let j = 0
      for (let i = 0; i < pathArray.length; i++) {
        if (pathArray[i]) {
          requestPath = `${ requestPath }/${ pathArray[i] }`
          const fileIdForLink = generateFileForLink(folder.id, urlsFolderPaths, requestPath)
          if (i < shortBreadcrumbLimit + 1) {
            if (i === 1) {
            } else {
              breadCrumb = [...breadCrumb,
                [<span key={j++} className="arrow">&rsaquo;</span>]]
            }
          }
          if (i < shortBreadcrumbLimit || i === folderDepth) {
            urlFolderObject[fileIdForLink] = pathArray[i]
            breadCrumb.push(<Link key={j++} to={{ pathname: `/smart-box/folder/${ fileIdForLink }` }}
                                  //onClick={() => {this.props.setSmartBoxCurrentFolder(fileIdForLink)}}
                                  onClick={(e) => this.onGetSetSmartBoxCurrentFolder(e, fileIdForLink)}
            >{pathArray[i]}</Link>)
          }
          if (i === shortBreadcrumbLimit && i < folderDepth) {
            updateUrls = false
            breadCrumb.push(<span key={j++}> . . .</span>,<span className='arrow' key={j++} >&rsaquo;</span>)
          }
        }
      }
      if(updateUrls && urlsFolderPaths && urlFolderObject && Object.keys(urlsFolderPaths).length !== Object.keys(urlFolderObject).length){
        this.setState({urlsFolderPaths : urlFolderObject})
      }
      return breadCrumb
    }
    return ''
  }

  onDoubleClickFilePressed = (file) => {
    if (file.isPdf === 'true') {
      file.totalPages > 20 ? this.handlePreviewClicked(file) : this.handlePreview(file)
    }
  }
  onDoubleClickSelectedFile = (file) => {
    const listKeysFilesSelected = []
    listKeysFilesSelected.push(file.id)
    this.setState({
      listKeysFiles: listKeysFilesSelected
    })
  }
  onActiveFolderDepth = () => {
    const { folder } = this.state
    let { folderNewDepth } = this.state
    const NewPath = folder.relativePath
    if (NewPath) {
      folderNewDepth = NewPath.split('/').length
      folderNewDepth = folderNewDepth - 1
    }
    return folderNewDepth
  }

  setRefWrapperNotification(callbacks) {
    this.refWrapperNotification = callbacks
  }

  setNewFiles = (childFiles) => {
    this.setState({ childFiles })
  }

  setNewFoldersCountFiles = (foldersCountFiles) => {
    this.setState({ foldersCountFiles: [...this.state.foldersCountFiles, ...foldersCountFiles] })
  }

  handleAddEditCustomerStatus = () => {
    const { customStatusColor, customStatusText, selectedDoc, newCustomStatusNameValid } = this.state
    if (customStatusText) {
      const parameters = {
        fileId: selectedDoc.id,
        fileStatus: customStatusText,
        color: customStatusColor
      }
      setFileStatus(parameters).then((response) => {
        if (response.status === 200) {
          newCustomStatusNameValid.success= true
          this.setState({ newCustomStatusNameValid }, () => {
            this.toggleCustomerStatusModalVisible('')
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else {
      newCustomStatusNameValid.success= false
      this.setState({ newCustomStatusNameValid })
    }
  }

  handleChangeComplete = (color, event) => {
    this.setState({ customStatusColor: color.hex, displayColorPicker: false })
  }

  togglePickerFromCustomStatusModal = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  closePickerFromCustomStatusModal = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChangeCustomStatus = (e) => {
    this.setState({ customStatusText: e.target.value })
  }

  renderAddEditCustomerStatusModal = (type = 'add') => {
    const { customerStatusModalVisible, selectedDoc, customStatusColor, newCustomStatusNameValid } = this.state
    return (
      <Modal
        className="modal-customer-status"
        title= { customerStatusModalVisible === 'add' ? 'Add new status' : 'Edit status'}
        visible={ customerStatusModalVisible !== '' }
        onOk={ () => this.handleAddEditCustomerStatus() }
        onCancel={ () => this.toggleCustomerStatusModalVisible('') }
      >
        <p className="subtitle">{ customerStatusModalVisible === 'add' ? 'Add a new' : 'Edit' } status for <b>{selectedDoc.name}</b></p>
        <Input type="text" onChange={ (e) => this.handleChangeCustomStatus(e) } onPressEnter={ (e) => this.handleRenameFolder() }/>
        <div className="pick-up-color-wrapper">
          <div className="input-color" onClick={ this.togglePickerFromCustomStatusModal }>
            <div className="input-color__selected" style={{ backgroundColor: customStatusColor }} />
          </div>
          { this.state.displayColorPicker &&
              <div className="popup-wrapper">
                <div className="popup-wrapper__cover" onClick={ this.closePickerFromCustomStatusModal }/>
                <SketchPicker onChangeComplete={ this.handleChangeComplete } />
              </div>
          }

        </div>
        {!newCustomStatusNameValid.success &&
            <p className="error">{newCustomStatusNameValid.reason}</p>
        }
      </Modal>
    )
  }

  renderRenameFolderModal = () => {
    const { renameFolderModalVisible, folder, newFolderNameValid } = this.state
    return (
      <Modal
        className="rename-folder"
        title="Rename folder"
        visible={ renameFolderModalVisible }
        onOk={ () => this.handleRenameFolder() }
        onCancel={ () => this.toggleRenameFolderModal(false) }
      >
        <p className="subtitle">Add new name for <b>{folder.name}</b></p>
        <Input type="text" placeholder="Rename folder" onChange={ (e) => this.handleChangeFolderName(e) } onPressEnter={ (e) => this.handleRenameFolder() }/>
        {!newFolderNameValid.success &&
          <p className="error">{newFolderNameValid.reason}</p>
        }
      </Modal>
    )
  }
  getThumbnailsTreeStructureFolder = (listOfThumbnails) => {
    if (listOfThumbnails && listOfThumbnails.length > 0) {
      this.setState({
        listOfThumbnails: listOfThumbnails
      })
    }
  }
  resetPageNumber = () => {
    this.setState({ currentPage: 1 })
  }
  changeFileName = (id, newFileName) => {
    const { childFiles } = this.state

    const index = childFiles && childFiles.length > 0 && childFiles.findIndex((file) => file.id === id)
    if (index !== -1) {

      const path = childFiles && childFiles.length > 0 && childFiles[index].path.split('/')
      path[path.length - 1] = newFileName

      childFiles[index].path = path.join('/')
      childFiles[index].name = newFileName
      childFiles[index].nameWithoutExtension = newFileName

      this.setState({ childFiles })
    }
  }
  onStatusCloseNavigationBar = (status) => {
    this.setState({
      statusCloseNavigationBar: status
    })
  }

  getFilesCount = (item) => {
    const { foldersCountFiles, childFiles } = this.state

    if (childFiles && childFiles.length > 0) {
      const match = foldersCountFiles.filter((el) => el.path === item.path)
      if (match && match.length > 0) {
        return match[0].numberOfFiles
      }
    }
    return 0
  }
  handlePreviousFolder = () => {
    let obj = this.state.urlsFolderPaths
    let sortedStrings = Object.keys(obj).sort((a,b) => a.length-b.length)
    this.props.navigate(`/smart-box/folder/${obj[sortedStrings[sortedStrings.length-2]]}`)
    this.props.setSmartBoxCurrentFolder(obj[sortedStrings[sortedStrings.length-2]])
    this.props.setSmartBoxPreviousCurrentPage(this.props.setPreviousCurrentPage.previousPage, 'previous')
  }

  getFilesCountListView = (item) => {
    const { foldersCountFiles, childFiles } = this.state
    childFiles && childFiles.length > 0 && childFiles.forEach((file) => {
      foldersCountFiles && foldersCountFiles.length > 0 && foldersCountFiles.forEach((el) => {
        if (file.path === el.path) {
          file.numberOfFiles = el.numberOfFiles
        }
      })
    })
    if(childFiles && childFiles.length > 0) {
      const match = foldersCountFiles.filter(el => el.path === item.path)
      if (match && match.length > 0) {
        return match[0].numberOfFiles
      }
    }
    return 0
  }

  onGetStatusTags = (defaultSelectedValue, listKeysFilesDeleted) => {
    let { childFiles } = this.state
    childFiles && childFiles.length > 0 && childFiles.forEach((item) => {
      if (listKeysFilesDeleted.includes(item.id)) {
        if (defaultSelectedValue.icon) {
          item.systemPropertyFileStatusIcon = defaultSelectedValue.icon
          item.systemPropertyFileStatus = defaultSelectedValue.tagName
          item.systemPropertyFileStatusColor = null
        } else if (defaultSelectedValue.color) {
          item.systemPropertyFileStatusColor = defaultSelectedValue.color
          item.systemPropertyFileStatusIcon = null
          item.systemPropertyFileStatus = defaultSelectedValue.tagName
        } else if (!defaultSelectedValue.icon && !defaultSelectedValue.color) {
          item.systemPropertyFileStatusColor = null
          item.systemPropertyFileStatusIcon = null
          item.systemPropertyFileStatus = defaultSelectedValue.tagName
        }
      }
    })
    this.setState({
      loadingOpenedFolder: true,
    }, () => {
     this.setState({
       loadingOpenedFolder: false,
       childFiles
     })
    })
  }

  onGetStatusTagsSmartView = (tagSelect, listIds) => {
    let { childFiles } = this.state
    childFiles && childFiles.length > 0 && childFiles.forEach((item) => {
      if (listIds.includes(item.id)) {
        if (tagSelect.icon) {
          item.systemPropertyFileStatusIcon = tagSelect.icon
          item.systemPropertyFileStatus = tagSelect.tagName
          item.systemPropertyFileStatusColor = null
        } else if (tagSelect.color) {
          item.systemPropertyFileStatusColor = tagSelect.color
          item.systemPropertyFileStatusIcon = null
          item.systemPropertyFileStatus = tagSelect.tagName
        } else if (!tagSelect.color && !tagSelect.icon) {
          item.systemPropertyFileStatusColor = null
          item.systemPropertyFileStatusIcon = null
          item.systemPropertyFileStatus = tagSelect.tagName
        }
      }
    })
    this.setState({
      loadingOpenedFolder: true,
    }, () => {
      this.setState({
        loadingOpenedFolder: false,
        childFiles
      })
    })
  }
  onDeleteAllFiles = (list) => {
    this.setState({
      listKeysFilesDeleted: list,
      listKeysFiles: list,
    })
  }
  render() {
    let { childFiles } = this.state
    const { isOcr, folder, documentBytes, documentId, totalPages, landscape, aspectRatio, height, width,
      initialName, smartBoxDocumentId, smartBoxDocumentPath, newFolderClicked, searchText, sidebarClass, contentClass,
      sidebarHeight, listKeysFiles, checkKeysFiles, selectedDoc, sidebarOpen, loadingOpenedFolder,
      visitedRedactionSmart, disabledOnClicked, validNewFolderName, finalizedDocPath, listSelectedNameFiles,
      currentFolderPath, listSelectedNameId, currentPathSelectedFiles, currentFolderId, checkStatusPaste,
      totalPagesForPagination, currentPage, listSelectedFiles, listOfThumbnails, systemPropertyFileStatus, basePath,
      totalFolderOnRoot, statusCloseNavigationBar, boxIdBasePath, checkCloseSmartViewForPagination, foldersCountFiles, activeFile,
      listSelectedNameIdCutPaste, lastFileClicked, lastFileClickedAction, filesCheckDuplicates, doc_classification, visibleToDownload
    ,baseFolders, statusResponsePagination, listKeysFilesDeleted, closeRedactionStatus, statusPagination, statusFolderCountFiles, statusThumbnailForFiles,
      statusProgressBarData, actionNextPreviousSmartbox,  systemPropertyFileColor,
      systemPropertyFileIcon, xeroxUpload, fileWarnings, lockSmartViewChanges, boxRedactionColorProfileId } = this.state
    const { redactingMode, smartBoxSearchedText, smartBoxViewMode, smartBoxPageLimit } = this.props
    let filesTable = this.adjustFilesTable(childFiles)
    let { folderNewDepth } = this.state
    folderNewDepth = this.onActiveFolderDepth()
    if (newFolderClicked) {

      const newFolderInput = (
        <Input placeholder="New folder name" onKeyPress={ this.handleNewFolderKeyPressed } style={{ width: '300px' }}
          onBlur={ this.removeNewFolderFormView }
          ref={ (input) => { this.newFolderInput = input } }/>)
      const emptyFile = [ {
        'id': 0,
        'key': 0,
        'name': newFolderInput,
        'type': 'DIRECTORY',
        'modified': moment().format('D.MM.YYYY'),
        'members': [ 'Only Me' ],
        'depth': 0
      } ]
      const emptyFileForList = [ {
        'id': 0,
        'key': 0,
        'name': 'New Folder',
        'type': 'DIRECTORY',
        'modified': moment().format('D.MM.YYYY'),
        'members': [ 'Only Me' ],
        'depth': 0
      } ]
      filesTable = [ ...emptyFile, ...filesTable ]
      if(childFiles === null) {
        childFiles = []
      }
      childFiles = [ ...emptyFileForList, ...childFiles ]
    }
    filesTable = filesTable.filter((file) => file.id !== this.props.smartBoxDeletedFolder)
    const notFolderClosed = this.props.smartBoxFolderClosed === '' || this.props.smartBoxFolderClosed !== this.state.folderId
    return (
      !redactingMode ?
        <React.Fragment>
          <WrapperNotification onMounted={ (callbacks) => this.setRefWrapperNotification(callbacks)}/>
          <div className={ `smart-box-folder-opened_container float-left ${ contentClass }` }>
            <div className="smart-box-folder-opened_container__row">
              <SmartBoxActionsHeader
                handleSearchInputChange={ this.handleSearchInputChange }
                searchText={ searchText }
                fetchFilesOnSearch={ this.fetchFilesOnSearch }
                fetchFiles={ this.fetchFolderInfo }
                handleNewFolderClick={ this.handleNewFolderClick }
                newFolderClicked={ newFolderClicked }
                customUploadFolder={ this.customUploadFolder }
                disableNewFolder={ false /*this.props.smartBoxViewMode === 'list'*/ }
                root={ false }
                totalRecords={ totalPagesForPagination }
                currentPage = { currentPage }
                pageLimit={ smartBoxPageLimit }
                onPageChanged={ this.onPageChanged }
                listKeysFiles = { listKeysFiles }
                listKeysFilesDeleted = { listKeysFilesDeleted }
                checkKeysFiles = { checkKeysFiles }
                onSelectAll = { this.onSelectAll }
                handleChangeViewMode = { this.handleChangeViewMode }
                toggleFilePreview = { this.toggleFilePreview }
                toggleNoteVisible = { this.toggleNoteVisible }
                filesTable = { filesTable }
                setFolderFromChildren={ this.setFolderFromChildren }
                currentFolderPath={ currentFolderPath }
                folderPath = { folder.path }
                totalFolderOnRoot = { totalFolderOnRoot }
                folderParent = { folder ? folder : '' }
                onStatusCloseNavigationBar={ this.onStatusCloseNavigationBar }
                statusCloseNavigationBar={ statusCloseNavigationBar }
                basePath={ basePath }
                boxIdBasePath={ boxIdBasePath }
                checkCloseSmartViewForPagination={ checkCloseSmartViewForPagination }
                loadingOpenedFolder={ loadingOpenedFolder }
                showLeftFoldersNav={ true }
                foldersCountFiles  = { foldersCountFiles }
                statusResponsePagination  = { statusResponsePagination }
                refreshFilesCountOfFolders  = { this.refreshFilesCountOfFolders }
                statusPagination  = { statusPagination }
                statusFolderCountFiles  = { statusFolderCountFiles }
                statusThumbnailForFiles  = { statusThumbnailForFiles }
                statusProgressBarData  = { statusProgressBarData }
                actionNextPreviousSmartbox  = { actionNextPreviousSmartbox }
                onGetStatusTags={ this.onGetStatusTags }
                onDeleteAllFiles={ this.onDeleteAllFiles }
              />
              <div className={ 
                smartBoxViewMode === 'grid' && filesTable.length === 0  
                ? `smart-box-folder-opened_container__content new-contain-folder-opened new-contain-folder-opened-empty ${!this.props.leftNavigationTreeShow}` 
                : smartBoxViewMode === 'grid' 
                && !statusCloseNavigationBar 
                ? `smart-box-folder-opened_container__content new-contain-folder-opened folder-opened-padding-${!this.props.leftNavigationTreeShow}`
                : smartBoxViewMode === 'grid' 
                && statusCloseNavigationBar 
                ? 'smart-box-folder-opened_container__content new-contain-folder-opened' 
                : `smart-box-folder-opened_container__content-${!this.props.leftNavigationTreeShow}`}>

                <Spin className={ filesTable.length === 0 ? " custom-spin custom-spin-folder-opened-empty": "custom-spin"} tip="Loading..." spinning={ loadingOpenedFolder && smartBoxViewMode === 'grid' } wrapperClassName={ filesTable.length === 0  && Object.keys(this.state.urlsFolderPaths).length < 2 ? 'spin-new-contain-folder-opened-empty': ""}>

                  <div className="smart-box-folder-opened_container__content__files new-content-file">
                    {/*BREADCRUMB*/}
                    <div className="smart-box-folder-opened__breadcrumb">
                      {Object.keys(this.state.urlsFolderPaths).length < 2 ?
                      <span className="smart-box-folder-opened__breadcrumb--bold">
                          {/*<span className='icon-v14-back-1 disabled'/>*/}
                        { this.generateBreadcrumb()}
                          </span>
                          :
                          <span className="smart-box-folder-opened__breadcrumb--bold">
                          <span className='icon-v14-back-1' onClick={this.handlePreviousFolder}/>
                            { this.generateBreadcrumb() }
                          </span>
                        }

                    </div>
                    {/*BREADCRUMB*/}
                    { notFolderClosed && smartBoxViewMode === 'grid' && filesTable.map((item, index) =>
                      <SmartBoxFile
                        key={ index }
                        file={ item }
                        disabledOnClicked = { disabledOnClicked }
                        handleDeleteFile={ this.handleDeleteFile }
                        isNewFolder={ item.id === 0 }
                        handleNewFolderKeyPressed={ this.handleNewFolderKeyPressed }
                        onFilePressed={ this.onFilePressed }
                        removeNewFolderFormView={ this.removeNewFolderFormView }
                        listKeysFiles={ listKeysFiles }
                        listKeysFilesDeleted={ listKeysFilesDeleted }
                        addOrRemoveValue={ this.addOrRemoveValue }
                        removeAllSelected={ this.removeAllSelected }
                        toggleFilePreview={ this.toggleFilePreview }
                        fetchFiles={ this.fetchFolderInfo }
                        user={this.props.user}
                        validNewFolderName = { validNewFolderName }
                        isUnique = { this.isUnique }
                        folderNewDepth = { folderNewDepth }
                        listSelectedNameFiles = { listSelectedNameFiles }
                        currentPathSelectedFiles = { currentPathSelectedFiles }
                        folder = { folder }
                        currentFolderId = { currentFolderId }
                        onSendStatusPaste = { this.onSendStatusPaste }
                        checkStatusPaste = { checkStatusPaste }
                        listSelectedNameId = { listSelectedNameId }
                        listSelectedNameIdCutPaste = { listSelectedNameIdCutPaste.length > 0 ? listSelectedNameIdCutPaste : [item.id] }
                        listOfThumbnails = { listOfThumbnails }
                        filesTable = { filesTable }
                        listSelectedFiles = { listSelectedFiles }
                        onDoubleClickFilePressed = { this.onDoubleClickFilePressed }
                        basePath={basePath}
                        changeFileName = { this.changeFileName }
                        totalRecords={ totalPagesForPagination }
                        pageLimit={ smartBoxPageLimit }
                        currentPage = { currentPage }
                        onPageChanged={ this.onPageChanged }
                        root={ false }
                        filesCount={ this.getFilesCount(item) }
                        setActiveFile = { this.setActiveFile }
                        activeFile = { activeFile }
                        filesErrors = { this.getFilesErrors(item) }
                        getFilesDuplicates = { this.getFilesDuplicates(item) }
                        boxIdBasePath={ boxIdBasePath }
                        filesCheckDuplicates={ filesCheckDuplicates }
                        getFilesProprieties={ this.getFilesProprieties(item) }
                        baseFoldersParent = { baseFolders }
                        onCallProgressRename={ this.onCallProgressRename }
                        onDoubleClickSelectedFile={ this.onDoubleClickSelectedFile }
                      />
                    ) }
                  </div>
                </Spin>
                {/*{smartBoxViewMode === 'list' &&*/}
                {/*  <TreeStructureFolder files={childFiles} toggleFilePreview={this.toggleFilePreview}></TreeStructureFolder>*/}
                {/*}*/}
                { smartBoxViewMode === 'list' &&
              <div className="smart-box-folder-opened_container__content list-view-content">
                <TreeStructureFolder
                  files={ childFiles }
                  toggleFilePreview={ this.toggleFilePreview }
                  toggleFileSelect={ this.addOrRemoveValue }
                  lastFileClicked={ lastFileClicked }
                  lastFileClickedAction={ lastFileClickedAction }
                  setSidebarHeight={ this.setSidebarHeight } handlePreview={ this.handlePreview }
                  handlePreviewClicked={ this.handlePreviewClicked }
                  setNewFiles={ this.setNewFiles }
                  onFilePressed={ this.onFilePressed }
                  fetchFiles={this.fetchFolderInfo}
                  folderNewDepth = { folderNewDepth }
                  listOfThumbnails = { listOfThumbnails }
                  hasDragBelow={true}
                  resetPageNumber={ this.resetPageNumber }
                  getThumbnailsTreeStructureFolder = { this.getThumbnailsTreeStructureFolder }
                  foldersCountFiles = { foldersCountFiles }
                  setNewFoldersCountFiles={ this.setNewFoldersCountFiles }
                  showArrowsFirstLevel={true}
                  root={false}
                  handleNewFolderKeyPressed={this.handleNewFolderKeyPressed}
                  validNewFolderName={validNewFolderName}
                  disabledOnClicked={disabledOnClicked}
                  removeNewFolderFormView={this.removeNewFolderFormView}
                  isUnique = { this.isUnique }
                  changeFileName = { this.changeFileName }
                  filesCheckDuplicates = { filesCheckDuplicates }
                  getFilesCountListView={ this.getFilesCountListView }
                />
              </div>
                }
                { (!loadingOpenedFolder || smartBoxViewMode === 'list') &&
                <DropMultipleFileDirectory folder={ this.state.folder }
                  emptyFolder={ newFolderClicked === false && (childFiles === null || childFiles.length === 0) }
                  filesTable={ filesTable }
                  childFiles={ childFiles }
                  folderNewDepth={ folderNewDepth }
                                           customUploadFolder={ this.customUploadFolder }/>
                }
              </div>
            </div>
            { this.renderRenameFolderModal() }
            { this.renderAddEditCustomerStatusModal() }
          </div>
          <div className={ `smart-box-page__action-sidebar float-left ${ sidebarClass }` }
            style={ { height: sidebarHeight } }>
            <MetadataPage
              documentId={ selectedDoc.id }
              documentPath = { selectedDoc.path }
              document = { selectedDoc }
              documentName = { selectedDoc.name }
              documentNameWithoutExtension = { selectedDoc.nameFileWithoutExtension }
              documentType={ selectedDoc.type }
              documentDepth={ selectedDoc.depth }
              sidebarOpen={ sidebarOpen }
              listOfThumbnails = { listOfThumbnails }
              toggleCustomerStatusModalVisible = { this.toggleCustomerStatusModalVisible }
              fetchFiles={this.fetchFolderInfo}
            />
            <div className="smart-box-page__action-sidebar__close" title="close">
              <svg width="16px" height="16px" onClick={ (e) => this.toggleFilePreview(false, {}) }>
                <polygon points="0,0,16,8 0,16" fill="#d8d8d8"/>
              </svg>
            </div>
          </div>
        </React.Fragment>
        :
        <div className="redacting-container col-md-12">
          { documentBytes &&
          <RedactionToolCopy
            pdfBytes={ generateBlobFromBase64(documentBytes) }
            documentId={ documentId }
            initialName={ initialName }
            totalPages={ totalPages }
            closeRedacting={ this.closeRedacting }
            landscape={ landscape }
            clientId={ this.props.clientId }
            aspectRatio={ aspectRatio } dbHeight={ height } dbWidth={ width }
            setHeaderVisibility={ this.props.setHeaderVisibility }
            setSidebarVisibility={ this.props.setSidebarVisibility }
            setFooterVisibility={ this.props.setFooterVisibility }
            isSmartBoxApplication={ true }
            smartBoxDocumentId={ smartBoxDocumentId }
            searchedValue={ smartBoxSearchedText }
            visitedRedactionSmart={ visitedRedactionSmart }
            fetchFiles={ this.fetchFolderInfo }
            isOcr={ isOcr }
            smartBoxDocumentPath={ smartBoxDocumentPath }
            finalizedDocPath = { finalizedDocPath }
            systemPropertyFileStatus = { systemPropertyFileStatus }
            doc_classification = { doc_classification }
            filesTable = { filesTable }
            handlePreviewDocumentNext = { this.handlePreviewDocumentNext }
            closeRedactingNext = { this.closeRedactingNext }
            visibleToDownload={ visibleToDownload }
            //checkRedactionCount={ checkRedactionCount }
            onGetStatusTagsSmartView={ this.onGetStatusTagsSmartView }
            systemPropertyFileStatusColor={ systemPropertyFileColor }
            systemPropertyFileStatusIcon={ systemPropertyFileIcon }
            xeroxUpload={ xeroxUpload }
            lockSmartViewChanges={ lockSmartViewChanges }
            client={ this.props.client }
            fileWarnings={ fileWarnings }
            boxRedactionColorProfileId={ boxRedactionColorProfileId }
            statusOpenRedactionTool="smartview"
          />
          }
        </div>
    )
  }
}


SmartBoxFolderOpened = withRouter(SmartBoxFolderOpened)

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  client: state.subscriptionReducer.subscription.subscription.client,
  user: state.userReducer.user,
  redactingMode: !state.shellReducer.sidebarVisible && !state.shellReducer.headerVisible && !state.shellReducer.footerVisible,
  fetchFolderContent: state.smartBox.fetchFolderContent,
  intervalForAiPages: state.smartBox.intervalForAiPages,
  intervalForPdfPages: state.smartBox.intervalForPdfPages,
  smartBoxSearchedText: state.smartBox.smartBoxSearchedText,
  smartBoxViewMode: state.smartBox.smartBoxViewMode,
  smartBoxFolderClosed: state.smartBox.smartBoxFolderClosed,
  smartBoxMovedFiles: state.smartBox.smartBoxMovedFiles,
  smartBoxDeletedFolder: state.smartBox.smartBoxDeletedFolder,
  smartBoxFilesTable: state.smartBox.smartBoxFilesTable,
  smartBoxRenamedFolder: state.smartBox.smartBoxRenamedFolder,
  smartBoxSelectedFiles: state.smartBox.smartBoxSelectedFiles,
  smartBoxPageLimit: state.smartBox.smartBoxPageLimit,
  smartBoxCurrentSortOption: state.smartBox.smartBoxCurrentSortOption,
  smartBoxFilesErrorConverted: state.smartBox.smartBoxFilesErrorConverted,
  smartBoxNotAnalysisComplete: state.smartBox.smartBoxNotAnalysisComplete,
  smartBoxFilterRefreshBox: state.smartBox.smartBoxFilterRefreshBox,
  leftNavigationTreeShow: state.smartBox.leftNavigationTreeShow,
  currentNewFilters: state.smartBox.currentNewFilters,
  currentNewFiltersSort: state.smartBox.currentNewFiltersSort,
  statusClearAllFiltersSmart: state.smartBox.statusClearAllFiltersSmart,
  selectKeysSmartBox: state.smartBox.selectKeysSmartBox,
  activeDeleteFile: state.smartBox.activeDeleteFile,
  smartBoxExistsFilesWarningsNotification: state.smartBox.smartBoxExistsFilesWarningsNotification,
  setPreviousCurrentPage: state.smartBox.setPreviousCurrentPage,
  smartBoxBaseDataTree: state.smartBox.smartBoxBaseDataTree,
})

export default connect(mapStateToProps, {
  setHeaderVisibility,
  setSidebarVisibility,
  setFooterVisibility,
  setSmartBoxUploadingObject,
  setSmartBoxIntervalForAiPages,
  setSmartBoxIntervalForPdfPages,
  removeSmartBoxIntervalForAiPages,
  removeSmartBoxIntervalForPdfPages,
  setSmartBoxSearchedWord,
  setSmartBoxViewMode,
  addIntervalToApplication,
  setSmartBoxNewNode,
  setSmartBoxMovedFiles,
  setSmartBoxSelectedFiles,
  setSmartBoxFilesTable,
  setSmartBoxFetchFiles,
  setSmartBoxAnalysisPercent,
  setSmartBoxParentFolderEmpty,
  setSmartBoxParentFolderErrors,
  setSmartBoxParentFolderName,
  setSmartBoxNotificationButtonFirstUpload,
  setSmartBoxDataReady,
  setSmartBoxFolderHasBulk,
  setSmartBoxErrorLoadingThumbnails,
  setSortSelectionCloseSmartview,
  setSmartFilesErrorNotBeConverted,
  setSmartBoxNotAnalysisComplete,
  setSmartFolderCountTree,
  setSmartBoxShowUploadNavigation,
  setSmartBoxCurrentFolder,
  setSmartBoxClickOutsideFilter,
  setStatusClearAllFiltersSmartbox,
  setProgressEventUpload,
  setSmartBoxSelectedKeys,
  setActiveDeleteFile,
  setSmartBoxExistsFilesWarningsNotification,
  setSmartBoxPreviousCurrentPage
  

})(SmartBoxFolderOpened)