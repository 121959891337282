import React from "react";
import {connect} from "react-redux";
import '../BuilderWorkflow.scss';
import {Input, Checkbox, Select} from "antd";
import {apiGetEmail} from "../../../../../api/Sar";
import {setGetVariablesEmailsWorkflow} from "../../../../../actions/smartBoxActions";
import {getTicketWorkflowAvailableUserList} from "../../../../../api/SmartBox";
const { Option } = Select
class WebFormEmailWorkflowBuilder extends React.Component {
    state = {
        emailBuilder: {
            selectEmail: '',
            selectForm: '',
            selectEmailVariables: '',
            emailTemplateIdNotification: '',
            variablesForm: [],
            variablesFormList: [],
            idComponentForm: [],
            workflowTemplateVariableList: [],
            sendWebformComplitionNotification: false
        },
        hoverDelete: false,
        listEmails: [],
        listEmailsGeneral: [],
        listSelectedEmail: {},
        listSelectedEmailGeneral: {},
        formVariables: [],
        idComponentForm: null,
        listForms: [],
        sendWebformComplitionNotification: false,
        listOptionsTicketOwner: []
    }
    componentDidMount() {
        this.onGetEmailsTemplate()
        this.onGetTicketWorkflowAvailableUserList()
        this.setState({
            listForms: this.props.listFormsWorkflow
        })
        // if ((this.props.formVariables2 && this.props.formVariables2.length > 0) || (this.props.component.formVariables && this.props.component.formVariables.length > 0)) {
        //     this.setState({
        //         emailBuilder: { ...this.state.emailBuilder, variablesFormList: this.props.formVariables2, idComponentForm: this.props.component.idComponentForm,
        //             workflowTemplateVariableList: this.props.component.workflowTemplateVariableList,
        //             selectForm: this.props.component.selectForm,
        //             idFormWebformEmail: this.props.component.idFormWebformEmail
        //         },
        //         formVariables: this.props.formVariables2 && this.props.formVariables2.length > 0 ? this.props.formVariables2 : this.props.component.formVariables,
        //         idComponentForm: this.props.component.idComponentForm,
        //     }, () => {
        //         this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
        //     })
        // } else
        // if (this.props.setVariablesEmailWorkflow && this.props.setVariablesEmailWorkflow.length > 0) {
        //     this.setState({
        //         emailBuilder: { ...this.state.emailBuilder, variablesFormList: this.props.setVariablesEmailWorkflow, idComponentForm: this.props.component.idComponentForm,
        //             workflowTemplateVariableList: this.props.component.workflowTemplateVariableList,
        //             selectForm: this.props.component.selectForm,
        //             idFormWebformEmail: this.props.component.idFormWebformEmail,
        //         },
        //         formVariables: this.props.setVariablesEmailWorkflow,
        //         idComponentForm: this.props.component.idComponentForm,
        //     }, () => {
        //         this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
        //     })
        // }
        // // this.setState({
        // //     listWebFormsWorkflow: this.props.listWebFormsWorkflow
        // // })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listFormsWorkflow && this.props.listFormsWorkflow && prevProps.listFormsWorkflow.length !== this.props.listFormsWorkflow.length) {
            this.setState({
                listForms: this.props.listFormsWorkflow
            })
        }
        // if (prevProps !== this.props && this.props.formVariables2 && this.props.formVariables2.length > 0) {
        //     this.setState({
        //         emailBuilder: { ...this.state.emailBuilder, variablesFormList: this.props.formVariables2, idComponentForm: this.props.component.idComponentForm,
        //             workflowTemplateVariableList: this.props.component.workflowTemplateVariableList,
        //             idFormWebformEmail: this.props.component.idFormWebformEmail,
        //             selectForm: this.props.component.selectForm,
        //         },
        //         formVariables: this.props.formVariables2 && this.props.formVariables2.length > 0 ? this.props.formVariables2 : this.props.component.formVariables,
        //         idComponentForm: this.props.component.idComponentForm,
        //     }, () => {
        //         this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
        //     })
        //     if (this.props.component.idComponentForm) {
        //         this.setState({
        //             idComponentForm: this.props.component.idComponentForm,
        //             //emailBuilder: { ...this.state.emailBuilder, idComponentForm: this.props.component.idComponentForm },
        //         })
        //     }
        // } else if (prevProps !== this.props && this.props.setVariablesEmailWorkflow && this.props.setVariablesEmailWorkflow.length > 0) {
        //     this.setState({
        //         formVariables: this.props.formVariables2 && this.props.formVariables2.length > 0 ? this.props.formVariables2 : this.props.component.formVariables,
        //         emailBuilder: { ...this.state.emailBuilder, variablesFormList: this.props.setVariablesEmailWorkflow, idComponentForm: this.props.component.idComponentForm,
        //             workflowTemplateVariableList: this.props.component.workflowTemplateVariableList,
        //             idFormWebformEmail: this.props.component.idFormWebformEmail,
        //             selectForm: this.props.component.selectForm,
        //         },
        //         idComponentForm: this.props.component.idComponentForm,
        //     }, () => {
        //         this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
        //     })
        //     // if (this.props.component.idComponentForm) {
        //     //     this.setState({
        //     //         idComponentForm: this.props.component.idComponentForm,
        //     //     })
        //     // }
        // }
        if (prevState.listSelectedEmail !== this.state.listSelectedEmail && Object.keys(prevState.listSelectedEmail).length > 0) {
            this.props.listWebFormsWorkflow && this.props.listWebFormsWorkflow.length > 0 && this.props.listWebFormsWorkflow.forEach((elem) => {
                this.state.listSelectedEmail.variables && this.state.listSelectedEmail.variables.length > 0 && this.state.listSelectedEmail.variables.forEach((elem2) => {
                    if (elem.formId && elem2.selectedVariable && elem.formId.toString() === elem2.selectedVariable.toString()) {
                        elem2.formName = elem.formName
                    }
                })
            })
            this.setState({
                listSelectedEmail: this.state.listSelectedEmail,
                //emailBuilder: this.props.component
            }, () => {
                this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
                this.props.onSelectIdWebFormGenerateListForms(this.props.listFormComponents, this.props.listWebFormsWorkflow, null)
            })
        }
    }
    onGetTicketWorkflowAvailableUserList = () => {
        getTicketWorkflowAvailableUserList().then((response) => {
            this.setState({
                listOptionsTicketOwner: response.data.ownersList,
            })
        })
    }
    onGetEmailsTemplate = () => {
        const { listSelectedEmail} = this.state
        apiGetEmail().then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((item) => {
                if (this.props.component.componentName && this.props.component.componentName === item.templateName) {
                    item.variables && item.variables.length > 0 && item.variables.forEach((variable) => {
                        variable.formVariable = null
                        this.props.component.workflowTemplateVariableList.forEach((variable3) => {
                            if (variable.id === variable3.email_template_variable_id ) {
                                variable.selectedVariable = variable3.form_variable

                            }
                        })
                    })
                    this.setState({
                        listSelectedEmail: {
                            variables: item.variables
                        }
                    }, () => {
                    })
                }
            })
            const listEmailsFilter = []
            const listEmailsGeneralFilter = []
            response.data && response.data.length > 0 && response.data.forEach((email) => {
                if (email && email.emailTemplateType === 'Webform emails') {
                    listEmailsFilter.push(email)
                }
                if (email && email.emailTemplateType === 'General emails') {
                    listEmailsGeneralFilter.push(email)
                }
            })
            this.setState({
                listEmails: listEmailsFilter,
                listEmailsGeneral: listEmailsGeneralFilter,
            }, () => {
                if (this.props.component.idComponent) {
                    this.onGetSelectEmails(this.props.component.idComponent)
                }
            })
        })
    }
    onGetSelectEmails = (e) => {
        const { listEmails } = this.state
        let componentName = ''
        let selectEmail = {}
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.emailTemplateId === e) {
                componentName = list.templateName
                selectEmail = list
            }
        })
        this.setState({
            emailBuilder: { ...this.state.emailBuilder, selectEmail: e, componentName: componentName, idComponent: e,
                workflowTemplateVariableList: this.props.component.workflowTemplateVariableList,
                selectForm: this.props.component.selectForm,
                idFormWebformEmail: this.props.component.idFormWebformEmail,
                idFormWebformEmailName: this.props.component.idFormWebformEmailName,
                sendWebformComplitionNotification: this.props.component.sendWebformComplitionNotification,
                userIdNotification: this.props.component.userIdNotification,
                emailTemplateIdNotification: this.props.component.emailTemplateIdNotification,
            },
            listSelectedEmail: selectEmail,
            formVariables: this.props.formVariables2 && this.props.formVariables2.length > 0 ? this.props.formVariables2 : this.props.component.formVariables,
            idComponentForm: this.props.component.idComponentForm,
        }, () => {
             this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            this.props.onSelectIdEmailTemplate(e)
        })
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onSelectEmails = (e) => {
        const { listEmails } = this.state
        const { listFormComponents } = this.props
        let componentName = ''
        let selectEmail = {}
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.emailTemplateId === e) {
                componentName = list.templateName
                selectEmail = list
            }
        })
        this.setState({
            emailBuilder: { ...this.state.emailBuilder, selectEmail: e, componentName: componentName, idComponent: e},
            listSelectedEmail: selectEmail
        }, () => {
            this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            this.props.onSelectIdEmailTemplate(e)
            //this.props.onSelectIdWebFormGenerateListForms(listFormComponents, listEmails)
        })
    }

    onSelectEmailsGeneral = (e) => {
        const { listEmails } = this.state
        const { listFormComponents } = this.props
        let componentName = ''
        let selectEmail = {}
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.emailTemplateId === e) {
                componentName = list.templateName
                selectEmail = list
            }
        })
        this.setState({
            emailBuilder: { ...this.state.emailBuilder, emailTemplateIdNotification: e, componentName: componentName },
            listSelectedEmailGeneral: selectEmail
        }, () => {
            this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            //this.props.onSelectIdEmailTemplate(e)
            //this.props.onSelectIdWebFormGenerateListForms(listFormComponents, listEmails)
        })
    }

    onSelectEmailVariables = (e, variable) => {
        const { listSelectedEmail, listForms } = this.state
        const { listWebFormsWorkflow } = this.props
        let idFormWebformEmailName = null
        listSelectedEmail.variables && listSelectedEmail.variables.length > 0 && listSelectedEmail.variables.map((item) => {
            if (item.variableEmail === variable.variableEmail) {
                item.formVariable = e
                item.selectedForm = e
                item.selectedVariable = e
                item.formName = null
            }
        })
        listForms && listForms.length > 0 && listForms.forEach((elem) => {
            if (elem.formId === e) {
                idFormWebformEmailName = elem.formName
            }
        })
        this.setState({
            listSelectedEmail,
            emailBuilder: { ...this.state.emailBuilder, variablesForm: listSelectedEmail, selectForm: e, idFormWebformEmail: e,
                idFormWebformEmailName: idFormWebformEmailName},
        }, () => {
            this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            this.props.onSelectIdWebFormGenerateListForms(this.props.listFormComponents, listWebFormsWorkflow, e)
        })
    }
    onChangeCheckboxCompletionNotification = (e, status) => {
       this.setState({
           sendWebformComplitionNotification: e.target.checked,
           emailBuilder: {...this.state.emailBuilder, sendWebformComplitionNotification: e.target.checked}
       }, () => {
           this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
       })
    }
    onChangeSelect = (e, arg) => {
        const { listOptionsWorkflow } = this.state
        switch (arg) {
            case 'ticketOwner':
                this.setState({
                    valueSelectTicketOwner: e,
                    emailBuilder: { ...this.state.emailBuilder, userIdNotification: e },
                }, () => {
                    this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
                    //this.props.onSelectIdWebFormGenerateListForms(this.props.listFormComponents, userIdNotification, e)
                })
                break
            default:
                break
        }
    }
    render() {
        const { listEmails, hoverDelete, emailBuilder, listSelectedEmail, formVariables, idComponentForm, listForms, listEmailsGeneral,
            sendWebformComplitionNotification, listOptionsTicketOwner } = this.state
        const { component, onStateProvidedDraggable, listWebFormsWorkflow, selectWorkflowTemplate} = this.props

        return (
            <div className="component-form">
                <div className="component-content component-content-web-form">
                    <div className="header-component">
                        <span className="header-title">Web Form</span>
                    </div>
                    <div className="select-content">
                          <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                                className={selectWorkflowTemplate.defaultTemplate === true ? 'icon-v19-delete icon-v19-delete-disabled' : hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                                onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                                onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <Select className="select-form"
                                value={ emailBuilder.selectEmail ? emailBuilder.selectEmail :
                                    component.selectEmail ? component.selectEmail : component.componentName ? component.componentName : 'Select' }
                                onChange={(e) => this.onSelectEmails(e)}
                                dropdownClassName={'select-form-dropdown'}
                                placeholder="Select">
                            { listEmails && listEmails.length > 0 && listEmails.map((item,index) => {
                                return  (<Option className="options-select-connector" value={ item.emailTemplateId } key={index}>
                                    { item.templateName }
                                </Option>)
                            })}
                        </Select>
                    </div>
                    <div className="content-variables-email content-variables-web-email">
                        { Object.keys(listSelectedEmail) && Object.keys(listSelectedEmail).length > 0 ?
                            <span className="content-active-variables-email">
                                <span className="variables-email">Variables:</span>
                                <span className="list-emails">
                                {
                                    listSelectedEmail.variables && listSelectedEmail.variables.length > 0 && listSelectedEmail.variables.map((item) => {
                                        return (
                                            <span className="content-select-email" key={item.variableEmail}>
                                                <span className="name-list">{ item.variableEmail ? item.variableEmail.replace('#', '$') : item.variableEmail }</span>
                                                {item.variableEmail && item.variableEmail.toLowerCase() === '#{webform}' &&
                                                    <div className="content-select-web-email">
                                                        <span className="header-title">Webform</span>
                                                        <div className="contain-web-email">
                                                    <Select className="select-form"
                                                            //value={item.formName ? item.formName : item.selectedVariable}
                                                            value={emailBuilder.idFormWebformEmailName ? emailBuilder.idFormWebformEmailName : component.idFormWebformEmailName ? component.idFormWebformEmailName : item.formName}
                                                            onChange={(e) => this.onSelectEmailVariables(e, item)}
                                                            dropdownClassName={'select-form-dropdown'}
                                                            placeholder="Select">
                                                        {listForms && listForms.length > 0 && listForms.map((item2, index) => {
                                                            return (<Option disabled={item2.disabledWebForm === true}
                                                                            className="options-select-connector"
                                                                            value={item2.formId} key={index}>
                                                                {item2.formName}
                                                            </Option>)
                                                        })}
                                                    </Select>
                                                            <div className="content-actions-completion-notification">
                                                                <Checkbox onChange={(e) => this.onChangeCheckboxCompletionNotification(e, 'notification')}
                                                                          checked={emailBuilder.sendWebformComplitionNotification}
                                                                >Send completion notification</Checkbox>
                                                                <span className="actions-select-completion-notification">

                                                                    <Select
                                                                        // showSearch
                                                                        // showArrow={false}
                                                                        // filterOption={false}
                                                                        value={ emailBuilder.userIdNotification ? emailBuilder.userIdNotification :
                                                                            component.userIdNotification ? component.userIdNotification : component.userIdNotification ? component.userIdNotification : 'Select' }
                                                                        placeholder="Select"
                                                                        className='select-form select-form-update'
                                                                        dropdownClassName={'select-form-dropdown'}
                                                                        onChange={(e) => this.onChangeSelect(e, 'ticketOwner')}
                                                                       // onSearch={ (e) => this.onSearchTicketOwner(e)}
                                                                    >
                                                                        { listOptionsTicketOwner && listOptionsTicketOwner.length > 0 && listOptionsTicketOwner.map((item, index) => {
                                                                            return (<Option className="options-select-connector" value={item.value} key={index}>
                                                                                {item.name ? `${item.name} | ${item.label}` : item.label}
                                                                            </Option>)
                                                                        })}
                                                                    </Select>

                                                                     <Select className="select-form select-form-update"
                                                                             value={ emailBuilder.emailTemplateIdNotification ? emailBuilder.emailTemplateIdNotification :
                                                                                 component.emailTemplateIdNotification ? component.emailTemplateIdNotification : component.emailTemplateIdNotification ? component.emailTemplateIdNotification : 'Select' }
                                                                             onChange={(e) => this.onSelectEmailsGeneral(e)}
                                                                             dropdownClassName={'select-form-dropdown'}
                                                                             placeholder="Select">
                                                                                                { listEmailsGeneral && listEmailsGeneral.length > 0 && listEmailsGeneral.map((item,index) => {
                                                                                                    return  (<Option className="options-select-connector" value={ item.emailTemplateId } key={index}>
                                                                                                        { item.templateName }
                                                                                                    </Option>)
                                                                                                })}
                                                                                            </Select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </span>
                                        )
                                    })
                                }
                                </span>

                            </span>
                            :
                            <span className="content-active-variables-email">
                            <span className="variables-email-default">Variables:</span>
                                <span className="list-emails">
                                                <span className="content-select-email">
                                                <span className="name-list">{'${webform}'}</span>
                                                    <div className="content-select-web-email">
                                                        <span className="header-title">Webform</span>
                                                        <div className="contain-web-email">
                                                            <Select className="select-form"
                                                                //value={item.formName ? item.formName : item.selectedVariable}
                                                                    //value={emailBuilder.idFormWebformEmailName ? emailBuilder.idFormWebformEmailName : component.idFormWebformEmailName ? component.idFormWebformEmailName : item.formName}
                                                                   // onChange={(e) => this.onSelectEmailVariables(e, item)}
                                                                    dropdownClassName={'select-form-dropdown'}
                                                                    disabled={true}
                                                                    placeholder="Select">
                                                                {listForms && listForms.length > 0 && listForms.map((item2, index) => {
                                                                    return (<Option disabled={item2.disabledWebForm === true}
                                                                                    className="options-select-connector"
                                                                                    value={item2.formId} key={index}>
                                                                        {item2.formName}
                                                                    </Option>)
                                                                })}
                                                            </Select>
                                                            <div className="content-actions-completion-notification">
                                                             <Checkbox onChange={(e) => this.onChangeCheckboxCompletionNotification(e, 'mandatory')}
                                                                       checked={emailBuilder.sendWebformComplitionNotification}
                                                                       >Send completion notification</Checkbox>
                                                                 <span className="actions-select-completion-notification">

                                                                     <Select
                                                                         // showSearch
                                                                         // showArrow={false}
                                                                         // filterOption={false}
                                                                         value={ emailBuilder.userIdNotification ? emailBuilder.userIdNotification :
                                                                             component.userIdNotification ? component.userIdNotification : component.userIdNotification ? component.userIdNotification : 'Select' }
                                                                         placeholder="Select"
                                                                         className='select-form select-form-update'
                                                                         dropdownClassName={'select-form-dropdown'}
                                                                         onChange={(e) => this.onChangeSelect(e, 'ticketOwner')}
                                                                        // onSearch={ (e) => this.onSearchTicketOwner(e)}
                                                                     >
                                                                        { listOptionsTicketOwner && listOptionsTicketOwner.length > 0 && listOptionsTicketOwner.map((item, index) => {
                                                                            return (<Option className="options-select-connector" value={item.value} key={index}>
                                                                                {item.name ? `${item.name} | ${item.label}` : item.label}
                                                                            </Option>)
                                                                        })}
                                                                    </Select>

                                                                     <Select className="select-form select-form-update"
                                                                             value={ emailBuilder.emailTemplateIdNotification ? emailBuilder.emailTemplateIdNotification :
                                                                                 component.emailTemplateIdNotification ? component.emailTemplateIdNotification : component.emailTemplateIdNotification ? component.emailTemplateIdNotification : 'Select' }
                                                                             onChange={(e) => this.onSelectEmailsGeneral(e)}
                                                                             dropdownClassName={'select-form-dropdown'}
                                                                             placeholder="Select">
                                                                                                { listEmailsGeneral && listEmailsGeneral.length > 0 && listEmailsGeneral.map((item,index) => {
                                                                                                    return  (<Option className="options-select-connector" value={ item.emailTemplateId } key={index}>
                                                                                                        { item.templateName }
                                                                                                    </Option>)
                                                                                                })}
                                                                                            </Select>
                                                                </span>
                                                            </div>
                                                            </div>
                                                    </div>
                                            </span>
                                </span>
                            </span>
                        }
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    setVariablesEmailWorkflow: state.smartBox.setVariablesEmailWorkflow,
})

export default connect(mapStateToProps, { setGetVariablesEmailsWorkflow })(WebFormEmailWorkflowBuilder)