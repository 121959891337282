import React, {useEffect, useState} from "react";
import "./NewNavigation.scss";
import { newNavigationObject } from "../../../NewNavigationObject";
import { useNavigate } from "react-router-dom";
import '../../../assets/fonts/neuro-fonts/smartbox_icons_v21.css'
import { useSelector, useDispatch } from 'react-redux'
import { setSmartBoxProjects, setSmartBoxHomeClicked } from '../../../actions/smartBoxActions'
import { connect } from "react-redux";

const NewNavigation = () => {
  const [menuNavigationObject, setMenuNavigationObject] = useState()
  const [showNavMenu, setShowNavMenu] = useState(false)
  const [currentPageTitle, setCurrentPageTitle] = useState(newNavigationObject[0].title)
  const [projects, setProjects] = useState([])
  const [excludeUrlFromDropdown, setExcludeUrlFromDropdown] = useState('/smart-box/management')
  const [currentPage, setCurrentPage] = useState(window.location.pathname)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const client = useSelector((state) => state.subscriptionReducer.subscription.subscription.client)
  const smartBoxProjects = useSelector((state) => state.smartBox.smartBoxProjects)
  const smartBoxHomeClicked = useSelector((state) => state.smartBox.smartBoxHomeClicked)
  const secondLevelNavigation = useSelector((state) => state.shellReducer.secondLevelNavigation)
  const user = useSelector((state) =>  state.userReducer.user)
  const userRoles = useSelector((state) => state.userReducer.user?.user_metadata.roles)
  const currentUserApplicationPermissions = useSelector((state) => new Map(
    [ [ 'isDpiaVisible', state.applications.dpiaVisible ],
      [ 'isSarVisible', state.applications.sarVisible ],
      [ 'isSmartBoxVisible', state.applications.smartBoxVisible ],
      [ 'isAssetRegisterVisible', state.applications.assetRegisterVisible ],
      [ 'isDocLockerVisible', state.applications.docLockerVisible ],
      [ 'isDataMappingAppVisible', state.applications.dataMappingAppVisible ],
      [ 'isWorkflowAppVisible', state.applications.workflowAppVisible ],
      [ 'isAdditionalEnvironmentsVisible', state.applications.additionalEnvironmentsVisible ],
    ])
  )
  useEffect(() => {
    renderNavigationMenu()
    // getProjects().then((res) => {
    //
    //   this.setState({
    //     projects: res.data
    //   }, () => {
    //     this.props.setSmartBoxProjects(this.state.projects)
    //
    //     for(let i=0; i<newNavigationObject.length; i++) {
    //       if(newNavigationObject[i].key === 'smart-box') {
    //         newNavigationObject[i].children = this.insertSmartBoxProjectsInSubmenu(newNavigationObject[i].children)
    //         break
    //       }
    //     }
    //   })
    // });
    if(location.pathname.includes('sar/workflow')) {
      setCurrentPageTitle('Workflows')
    }
    if(location.pathname.includes('source-mapping')) {
      setCurrentPageTitle('Data sources')
    }
    if(location.pathname.includes('connectors')) {
      setCurrentPageTitle('Data sources')
    }
    if(location.pathname.includes('data-mapping/business-process')) {
      setCurrentPageTitle('Data sources')
    }
    if(location.pathname.includes('sar/dashboard')) {
      setCurrentPageTitle('Request Tracker')
    }
    if(location.pathname.includes('sar/tracker-page')) {
      setCurrentPageTitle('Request Tracker')
    }
    if(location.pathname.includes('sar/archive')) {
      setCurrentPageTitle('Request Tracker')
    }
    if(location.pathname.includes('osp')) {
      setCurrentPageTitle('User management')
    }
    if(location.pathname.includes('dpia')) {
      setCurrentPageTitle('Assessments')
    }
    if(location.pathname.includes('smart-box/regular-expressions/settings')) {
      setCurrentPageTitle('Settings')
    }
    if(location.pathname.includes('smart-box/business-term-lists/settings')) {
      setCurrentPageTitle('Settings')
    }
    if(location.pathname.includes('/smart-box/redaction-colour/settings')) {
      setCurrentPageTitle('Settings')
    }
    if(location.pathname.includes('asset-register/management')) {
      setCurrentPageTitle('Asset Register')
    }

  }, [])

  // useEffect(() => {
  //   const { brandingProfileColors } = client
  //   if (showNavMenu) {
  //     brandingProfileColors && brandingProfileColors.colorsDTOS && brandingProfileColors.colorsDTOS.length > 0 && brandingProfileColors.colorsDTOS.forEach((brand) => {
  //       switch (brand.colorCode) {
  //         case 'background':
  //           if (brand.color) {
  //             document.getElementsByClassName('panel-new-nav')[0].style.backgroundColor = brand.color;
  //             if (document.getElementsByClassName('panel-new-nav')[0]) {
  //               const panelNavigation = document.getElementsByClassName('panel-new-nav')[0]
  //               panelNavigation.addEventListener('mouseout', function () {
  //                 panelNavigation.style.backgroundColor = brand.color
  //               })
  //             }
  //             for (let i = 0; i < document.getElementsByClassName('list-item-new-nav').length; i++) {
  //               const navButtons = document.getElementsByClassName('list-item-new-nav')[i]
  //               navButtons.addEventListener('mouseout', function() {
  //                 navButtons.style.backgroundColor = brand.color
  //               })
  //               navButtons.addEventListener('click', function() {
  //                 document.getElementsByClassName('navTitleDropdown')[0].style.backgroundColor = brand.color
  //               })
  //             }
  //           }
  //           break
  //         case 'background-hover':
  //           if (brand.color) {
  //             for (let i = 0; i < document.getElementsByClassName('list-item-new-nav').length; i++) {
  //               const navButtons = document.getElementsByClassName('list-item-new-nav')[i]
  //               navButtons.addEventListener('mouseover', function() {
  //                 navButtons.style.backgroundColor = brand.color
  //               })
  //             }
  //           }
  //           break
  //         case 'nav-body-font':
  //           if (brand.color) {
  //             for (let i = 0; i < document.getElementsByClassName('list-item-new-nav').length; i++) {
  //               document.getElementsByClassName('list-item-new-nav')[i].style.color = brand.color;
  //             }
  //             for (let i = 0; i < document.getElementsByClassName('list-item-new-nav').length; i++) {
  //               const navButtons = document.getElementsByClassName('list-item-new-nav')[i]
  //               navButtons.addEventListener('mouseout', function() {
  //                 navButtons.style.color = brand.color
  //               })
  //               navButtons.addEventListener('click', function() {
  //                 document.getElementsByClassName('navTitleDropdown')[0].style.color = brand.color
  //                 document.getElementsByClassName('navTitleDropdown')[0].style.borderColor = 'transparent'
  //
  //               })
  //             }
  //           }
  //           break
  //         case 'nav-body-font-hover':
  //           if (brand.color) {
  //             document.getElementsByClassName('panel-new-nav')[0].style.borderColor = brand.color;
  //             for (let i = 0; i < document.getElementsByClassName('new-nav-title').length; i++) {
  //               document.getElementsByClassName('new-nav-title')[i].style.color = brand.color;
  //             }
  //
  //             document.getElementsByClassName('section-items-new-nav')[0].style.color = brand.color;
  //             for (let i = 0; i < document.getElementsByClassName('title-header-nav').length; i++) {
  //               if (document.getElementsByClassName('title-header-nav')[i]) {
  //                 document.getElementsByClassName('title-header-nav')[i].style.color = brand.color;
  //               }
  //             }
  //
  //             document.getElementsByClassName('new-nav-title-wrapper')[0].style.color = brand.color;
  //
  //             for (let i = 0; i < document.getElementsByClassName('list-item-new-nav').length; i++) {
  //               const navButtons = document.getElementsByClassName('list-item-new-nav')[i]
  //               navButtons.addEventListener('mouseover', function() {
  //                 navButtons.style.color = brand.color
  //               })
  //             }
  //           }
  //           break
  //         default: break
  //       }
  //     })
  //   }
  // })
  useEffect(() => {
    setProjects(smartBoxProjects)
    for (let i=0; i<newNavigationObject.length; i++) {
      if (newNavigationObject[i].key === 'smart-box') {
        newNavigationObject[i].children = insertSmartBoxProjectsInSubmenu(newNavigationObject[i].children)
        break
      }
    }
  }, [smartBoxProjects.length])

  useEffect(() => {
    if(smartBoxHomeClicked) {
      let curPage = newNavigationObject.find(item => item.url === excludeUrlFromDropdown)
      setCurrentPageTitle('Smartbox.ai')
      setCurrentPage(curPage)
      setSmartBoxHomeClicked(false)
      dispatch(setSmartBoxHomeClicked(false))
    }
    if(smartBoxHomeClicked && currentPage.url !== excludeUrlFromDropdown) {
      let curPage = newNavigationObject.find(item => item.url === excludeUrlFromDropdown)
      setCurrentPage(curPage)
      dispatch(setSmartBoxHomeClicked(false))
      setSmartBoxHomeClicked(false)
    }
  }, [smartBoxHomeClicked])

  
  useEffect(() => {
    if (location.pathname.includes('smart-box')) {
      setCurrentPageTitle(newNavigationObject[0].title)
    }
    if(location.pathname.includes('settings')) {
      setCurrentPageTitle('Settings')
    }
    if (location.pathname.includes('osp/user/settings')) {
      setCurrentPageTitle(newNavigationObject[0].title)
    }

  },[location.pathname])

  const renderNavigationMenu = () => {
    let navObject = newNavigationObject.map((parent, index) => {
      if (user.inviteTenantPageActive !== true && parent.children && parent.children.length > 0) {
        parent.children = parent.children.filter((item) => (item.key !== 'home-invite-tenant' || item.title !== 'Create Tenant'))
      }
      if (parent.title === 'Data sources') {
        if (!client.businessProcessesVisible) {
          parent.children = parent.children.filter((item) => (item.key !== 'business-processes' || item.title !== 'Business processes'))
        }
        if (!client.sourceMappingVisible) {
          parent.children = parent.children.filter((item) => (item.key !== 'data-sources' || item.title !== 'Source mapping'))
        }
        if (!client.connectorsVisible) {
          parent.children = parent.children.filter((item) => (item.key !== 'connectors' || item.title !== 'Connectors'))
        }
      }
      if(parent.children && parent.children.length){
        return(
          permissionParent(parent) &&
            <div key={index} className="section-items-new-nav">
              <div className="new-nav-title-wrapper">
                <i className={parent.title === 'Workflows' ?  "icon-v211-workflow parent-title-header title-header-nav" : ''}/>
                <i className={parent.title === 'Request Tracker' ?  "icon-v211-request-tracker parent-title-header title-header-nav" : ''}/>
                <i className={parent.title === 'Data sources' ?  "icon-v211-share-1 parent-title-header title-header-nav" : ''}/>
                <i className={parent.title === 'User management' ?  "icon-v211-users-1 parent-title-header title-header-nav" : ''}/>
                <i className={parent.title === 'Assessments' ?  "icon-v211-users-1 parent-title-assessments title-header-nav" : ''}/>
                <i className={parent.title === 'Settings' ?  "icon-v211-gear parent-title-header title-header-nav" : ''}/>
                <i className={parent.title === 'Asset Register' ?  "icon-v211-request-tracker parent-title-request-tracker title-header-nav"  : ''}/>
                <span className='new-nav-title'>
                    {parent.title}
                </span>
              </div>
                {parent.children.map((child, ind) => {
                    return (
                        permissionChild(child) &&
                        <span className="list-item-new-nav"
                              key={ind}
                              disabled={child.title === 'Connectors' && userRoles.osprey === 'admin'}
                              onClick={() => {
                                setCurrentPageTitle(parent.title);
                                navigate(child.url)
                              }}>
                        {child.title}
                      </span>
                    )
                })}
            </div>
        )
      }
    })
    setMenuNavigationObject(navObject)
  }

  const permissionParent = (parent) => {
    if (parent.applicationpermission === 'home' && parent.title === 'Settings') {
      return true
    }
    if(parent.applicationpermission === 'isDataMappingAppVisible' && userRoles.osprey === 'user') {
      return false
    }
    if((userRoles.osprey === 'admin'  || userRoles.osprey === 'super_admin') && parent.title === 'User management') {
      return true
    }
    if(userRoles.osprey === 'user' && parent.title === 'User management') {
      return false
    }
    if(currentUserApplicationPermissions.get(parent.applicationpermission)) {
      return true
    }
    return false
  }

  const permissionChild = (child) => {
    if(child.permissions === 'all' && !child.applicationpermission){
      return true
    }
    if(child.permissions === 'all' && child.applicationpermission){
      let permissionResult = currentUserApplicationPermissions.get(child.applicationpermission)
      if(permissionResult == undefined || permissionResult === true){
        return true
      }else return false
      
    }
    for(let application of Object.keys(userRoles)) {
      for(let  permissionObj of child.permissions){
        if(application === permissionObj.application && userRoles[application] === permissionObj.permission){

          // if child has special field application permission then check if authorized
          if(child.applicationpermission){

            let permissionResult = currentUserApplicationPermissions.get(child.applicationpermission)
            if(permissionResult == undefined || permissionResult === true){
              return true
            }else return false

          }else{
            return true
          }
        }
      }
    }
    return false
  }


  const insertSmartBoxProjectsInSubmenu = (submenuArr) => {
    const { projects } = this.state
    submenuArr = submenuArr.filter((el) => el.key.indexOf('smart-box-project-') === -1)
    for (let i=projects.length-1; i>=0; i--) {
      submenuArr.splice(1, 0, 
        {
          key: `smart-box-project-${ i }`,
          title: projects[i].name,
          url: `/smart-box/project/${ projects[i].id }`,
          parentkey: 'smart-box',
          permissions: 'all'
        }
      )
    }
    return submenuArr
  }

  return (
    <div className="navTitleDropdown" onClick={() => {setShowNavMenu(prev => !prev)}}>
      {currentPageTitle}
      <i className="icon-v14-arrow-bold"/>
      {showNavMenu &&
        <div className="panel-new-nav" onMouseLeave={() => setShowNavMenu(false)}>
          <div className="mainBoxNewNav">
            <div className="sectionsNewNav">
              {menuNavigationObject}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    setSmartBoxHomeClicked: () => dispatch(setSmartBoxHomeClicked()),
    setSmartBoxProjects: () => dispatch(setSmartBoxProjects()),
  }
}
export default connect(mapDispatchToProps)(NewNavigation)