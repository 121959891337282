import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import './CullReport.scss'
import {Select, Tooltip, Table} from "antd";
import {getCullReportData} from "../../../../api/SmartBox";
import moment from "moment-timezone";
import cookie from "react-cookies";
const { Option } = Select
const { Column } = Table
class CullReportTable extends React.Component {
    state = {
        filesPerPageLimit: 20,
        totalRecords: 0,
        isPrevDisabled: true,
        isNextDisabled: true,
        inputPage: '',
        tableCullReport: [],
        onHoverForth: false,
        onHoverStart: false,
        onHoverBack: false,
        onHoverEnd: false,
        currentPage: 1,
        selectedRowKeys: [],
        filesSelect: [],
        statusOpenedRow: false
    }
    componentDidMount() {
        cookie.remove('checkGetDuplicatesCardInfo', { path: '/' })
        cookie.remove('checkDownloadFilesError', { path: '/' })
        this.getCullReportList()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === 'sweepReport') {
            cookie.remove('checkGetDuplicatesCardInfo', { path: '/' })
            cookie.remove('checkDownloadFilesError', { path: '/' })
            this.getCullReportList()
        }
    }
    getCullReportList = () => {
        const { filesPerPageLimit, currentPage } = this.state
        const { folder } = this.props
        // let parentFolderName = ''
        // if (typeof folder.relativePath !== 'undefined') {
        //     parentFolderName = folder.relativePath.split('/')[1]
        // }
        //const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        //const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        //const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        // const requestDuplicateFileBoxDTO = {
        //     clientId: this.props.clientId,
        //     folderPath: rootPathParent,
        //     pageNr: currentPage,
        //     rows: filesPerPageLimit,
        //     searchWord: null
        // }
        const requestCull = {
            boxId: folder.id,
            pageNr: currentPage,
            pageRows: filesPerPageLimit,
        }
        getCullReportData(requestCull).then((response) => {
            if (response.status === 200) {
                if (response.data && response.data.totalElements > 20 && response.data.cullReportEntityList.length === 20) {
                    this.setState({
                        isNextDisabled: false
                    })
                }
                response.data.cullReportEntityList && response.data.cullReportEntityList.length > 0 && response.data.cullReportEntityList.forEach((item, index) => {
                    item.key = index
                })
                this.setState({
                    tableCullReport: response.data.cullReportEntityList,
                    totalRecords: response.data.totalElements,
                })
            }
        })
    }
    changeFilesPerPageLimit = (e) => {
        this.setState({ filesPerPageLimit: e }, () => {
            this.getCullReportList()
        })
    }
    handleMovePage = (e, direction) => {
        const { isPrevDisabled, isNextDisabled, totalRecords, filesPerPageLimit } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / filesPerPageLimit) ? Math.ceil(totalRecords / filesPerPageLimit) : this.state.currentPage + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(Math.ceil(totalRecords/filesPerPageLimit))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPage = (newPage) => {
        const { totalRecords, filesPerPageLimit } = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPage: newPage }, () => {
            this.getCullReportList()
        })
    }
    onMouseEnterOnPanel(e, status) {
        switch (status) {
            case 'forth' :
                this.setState({
                    onHoverForth: true
                })
                break
            case 'start' :
                this.setState({
                    onHoverStart: true
                })
                break
            case 'back' :
                this.setState({
                    onHoverBack: true
                })
                break
            case 'end' :
                this.setState({
                    onHoverEnd: true
                })
                break
            default: break
        }
    }
    onMouseLeaveOnPanel(e, status) {
        this.setState({
            onHoverForth: false,
            onHoverStart: false,
            onHoverBack: false,
            onHoverEnd: false
        })

    }
    onSelectCull = (selectedRowKeys, filesSelect, e) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            filesSelect: filesSelect })
    }
    onSelectedRowCull = (e, record) => {
        this.setState({
            statusOpenedRow: true
        })
        this.props.onVisibleCullList(true)
        this.props.selectFileCullCategory(record)
    }
    onTimezoneConvert = (time) => {
        const localTimeZone = moment.tz.guess()
        const localDate = moment.utc(time).tz(localTimeZone)
        return `${ moment(localDate).format('DD.MM.YYYY HH:mm:ss').split(' ').join(' at ') }`
    }
    render() {
        const { filesPerPageLimit, isPrevDisabled, isNextDisabled, inputPage, tableCullReport, onHoverForth, onHoverStart, onHoverBack, onHoverEnd,
            totalRecords, currentPage } = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        // const rowSelection = {
        //     selectedRowKeys,
        //     onChange: this.onSelectCull,
        // }
        return (
            <div className="cull-report">
                <div className="cull-report-header">
                    <div className={ tableCullReport.length === 0 ? "per-page-cull disabled" : "per-page-cull"}>
                        <Select dropdownClassName="select-per-page-duplicate per-duplicate per-duplicate-update"
                                value={<span><span>{ `Show ${ filesPerPageLimit }` } </span><span className="icon-v19-arrow-medium"></span></span> }
                                onChange={ (e) => this.changeFilesPerPageLimit(e) }
                                disabled={tableCullReport.length === 0}>
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </div>
                    <div className="buttons-control-pagination-cull">
                        <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                <span className={ tableCullReport && tableCullReport.length === 0 ? 'icon-v14-start-1 disabled-pagination' : isPrevDisabled ? 'icon-v14-start-1 disabled-back' : onHoverStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'start') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'first')}/>
                        </Tooltip>
                        <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                <span className={ tableCullReport && tableCullReport.length === 0 ? 'icon-v14-back-1 disabled-pagination' : isPrevDisabled ? 'icon-v14-back-1 disabled-back' : onHoverBack ? 'icon-v21-back-active' : 'icon-v14-back-1' }
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'back') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                        </Tooltip>
                        { totalPages === 0 &&
                        <span className='buttons-control-pagination-cull__text disabled-text'>{1} of {1} </span>
                        }
                        { totalPages === 1 &&
                        <span className='buttons-control-pagination-cull__text disabled-text'>{currentPage} of {totalPages} </span>
                        }
                        { totalPages > 1 &&
                        <span className='buttons-control-pagination-cull__text'>
                <input
                    type="number"
                    className="insert-page"
                    placeholder= { currentPage }
                    value= { inputPage }
                    min="1"
                    max={totalPages}
                    onChange={ (e) => this.handleInputPageChange(e) }
                    onBlur={ () => this.handleInputPageBlur() }
                    onKeyDown={ (e) => this.handleInputPageEnter(e) }
                />
                <span>of {totalPages}</span>
              </span>
                        }
                        <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                <span className={ tableCullReport && tableCullReport.length === 0 ? 'icon-v14-forth-1 disabled-pagination' : isNextDisabled ? 'icon-v14-forth-1 disabled-next' : onHoverForth ? 'icon-v21-forth-active' : 'icon-v14-forth-1' }
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'forth') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'next')}/>
                        </Tooltip>
                        <Tooltip title="last" className={ isNextDisabled ? 'disabled' : '' }>
                <span className={tableCullReport && tableCullReport.length === 0 ? 'icon-v14-end-1 disabled-pagination' : isNextDisabled ? 'icon-v14-end-1 disabled-next' : onHoverEnd ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'end') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'last')}/>
                        </Tooltip>
                    </div>
                </div>

                <div className="table-cull-content">
                    <Table className="table-cull-files"
                           dataSource={ tableCullReport }
                           pagination={ false }
                           showHeader={ false }
                           //rowSelection={ rowSelection }
                           onRow={(record, rowIndex) => {
                               return {
                                   onClick: (e) => this.onSelectedRowCull(e, record)
                               }
                           }}
                    >
                        <Column className="column-name-cull" title="Name" dataIndex="reportDate" key="reportDate" render={(text, record) => {
                            return (
                                    <span className="text-style">{text ? this.onTimezoneConvert(text) : 'unknown'}</span>
                               )
                        }}/>
                        <Column className="column-name-cull column-size" title="Name" dataIndex="userFirstName" key="userFirstName" render={(text, record) => {
                            return (
                                <span className="text-style">{`by ${ record.userFirstName }`}</span>
                            )
                        }}/>
                        <Column className="column-name-cull column-size" title="Name" dataIndex="reportType" key="reportType" render={(text, record) => {
                            return (
                                <span className="text-style">{text === 'CULL' ? 'Quick cull' : (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace('_', ' ')}</span>
                            )
                        }}/>
                        <Column className="column-name-cull column-size" title="Name" dataIndex="deleted_files" key="deleted_files" render={(text, record) => {
                            return (
                                <span className="total-items">
                                    <span className="text-style">{`${text} items`}</span>
                                    <span className="text-style icon-v16-arrow-medium rotate-arrow-medium"></span>
                                </span>
                            )
                        }}/>
                    </Table>
                </div>
            </div>
        )

    }
}

CullReportTable.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default compose(
    connect(mapStateToProps),
)(CullReportTable)