export default {
  smartBoxUploadingObject: {},
  fetchFolderContent: false,
  smartBoxSearchedText: '',
  intervalForAiPages: [],
  intervalForPdfPages: [],
  intervalForBulkRedaction: [],
  smartBoxViewMode: 'grid',
  smartBoxScrollPositionObject: {
    expandedRowKeys: [],
    url: '',
    scrollPosition: 0
  },
  smartBoxExportDataVisible: false,
  smartBoxFetchTreeData: false,
  smartBoxAxiosHeaders: {},
  smartBoxProjects: [],
  smartBoxTree: [],
  smartBoxCurrentFolder: '',
  smartBoxLoadTree: false,
  smartBoxNewNode: null,
  smartBoxFolderClosed: '',
  smartBoxMovedFiles: [],
  smartBoxSelectedFiles: [],
  smartBoxDeletedFolder: [],
  smartBoxFilesTable: [],
  smartBoxFetchFiles: {},
  smartBoxBaseDataTree: [],
  smartBoxPreviewToggleFile: {},
  smartBoxVisibleAddNote: false,
  smartBoxSelectedDocumentId: {},
  smartBoxRenamedFolder: { id: '', newName: '' },
  smartBoxAnalysisPercent: 100,
  smartBoxParentFolderEmpty: false,
  smartBoxParentFolderErrors: false,
  smartBoxParentFolderName: '',
  smartBoxFoldersCheckErrors: [],
  smartBoxSelectedDocumentDuplicate: {},
  smartBoxMovedFolders: [],
  smartBoxOldParentOfMovedFolders: '',
  smartBoxNewParentOfMovedFolders: '',
  smartBoxSecurityConfiguration: '',
  smartBoxResponseDeleteDuplicates: {},
  smartBoxProgressCleanDeleteDuplicates: {},
  smartBoxCheckFirstUploadFile: {},
  smartBoxButtonUploadStatus: {},
  smartBoxPageLimit: 20,
  listTermsPageLimit: 10,
  businessTermsPageLimit: 10,
  overviewPageLimit: 10,
  smartBoxDataReady: {},
  smartBoxTreeCurrentDepth: 0,
  smartBoxHomeClicked: false,
  smartBoxCurrentSortOption: '',
  smartBoxFolderHasBulk: false,
  smartBoxErrorLoadingThumbnails: {},
  sortSelectionCloseSmartview: false,
  responseProgressBarRename: {},
  smartBoxParentStatisticsReady: '',
  smartBoxFilesErrorConverted: [],
  smartBoxUploadFailed: [],
  smartBoxUploadUniqueId: [],
  smartBoxNotSarTracker: [],
  smartBoxNotRedactComplete: [],
  smartBoxAddedNote: [],
  smartBoxStatusChange: [],
  smartBoxPerPageDuplicate: 20,
  smartBoxNotUploadComplete: [],
  smartBoxNotGiveAccessBox: [],
  smartBoxNotAnalysisComplete: [],
  smartBoxNotAccessPermission: [],
  smartBoxFolderCountTree: [],
  smartFolderCountTree: [],
  smartBoxFilterRefreshBox: {},
  smartReprocessingFileDelete: false,
  smartBoxBaseList: [],
  smartBoxSortSearch: { sort: "creation" },
  smartBoxCheckStatusDeduplication: false,
  smartBoxShowUploadNavigation: false,
  smartBoxCurrentFilterOption: null,
  smartBoxNewBox: {},
  smartBoxCheckBoxRedactionStatus: 0,
  collectDataMapping: {
    sendDataMapping: [],
    responseDataMapping: [],
    checkStartProgress: false
  },
  collectAdminClient: {},
  smartBoxCurrentFolderName: {},
  smartBoxSourceFile: {},
  leftNavigationTreeShow: false,
  currentNewFilters: {},
  currentNewFiltersSort: 'creation date',
  clickOutsideFiler: false,
  nonSelectedWords: [],
  statusClearAllFiltersSmart: false,
  selectKeysSmartBox: [],
  selectKeysListViewBox: [],
  setProgressEventUpload: undefined,
  activeDeleteFile: false,
  responseSetCardInfoDuplicates: '',
  setVariablesEmailWorkflow: [],
  smartBoxExistsFilesWarningsNotification: [],
  setImageProfile: null,
  setBoxRouteDashboardValue: 'dragos',
  setPreviousCurrentPage: {
    previousPage: 1,
    statusPrevious: 'initial'
  },
  visibleSubheaderSteps: false
}