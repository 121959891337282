import React from 'react'
import './NearDeduplication.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import Buttons from '../../../Common/Button'
import {Select, Table, Checkbox, Button, Modal} from 'antd'
import Icon from "antd/es/icon";
import {
    apiGetBoxNearStatus,
    apiListDeleteNear,
    apiPreviewFileDuplicate,
    getListDuplicateFileBoxSecond
} from "../../../../api/SmartBox";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {generateBlobFromBase64} from "../../../../utils/GenerateBlob";
import PDFViewer from "../../../../utils/PDFViewer";
import { handleByteConversion } from '../../../../utils'
import PDFJSBackend from "../../../../utils/backends/PDFJs";
import cookie from "react-cookies";
import moment from "moment-timezone";
const { Option } = Select
const { Column } = Table
class NearList extends React.Component {
    state = {
        filesPerPageLimit: 20,
        isPrevDisabled: true,
        isNextDisabled: true,
        currentPage: 1,
        totalRecords: 20,
        duplicateNearFiles: [],
        cursorMark: '',
        checkBoxSelectedFiles: [],
        tableDuplicatesOnFile: [],
        selectedFile: {},
        statusDeleteMouseEnter: false,
        deleteSelectableFile: {},
        selectColumnNear: {},
        renderingBlock: {},
        previewVisibleNear: false,
        activePageBack: false,
        selectedClickFileRow: {},
        selectDots: {},
        loadingKeepOptionNear: false,
        checkStatusNear: '',
        setIntervalNear: 0,
    }
    componentDidMount() {
        this.onGetDuplicateFileNear()
    }
    onGetDuplicateFileNear = () => {
        const { folder, selectBoxNear, hashFile } = this.props
        const { filesPerPageLimit, currentPage, cursorMark, selectColumnNear } = this.state
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        const requestDuplicateFileBoxSecondDTO = {
            clientId: this.props.clientId,
            hash: selectBoxNear && selectBoxNear.hash ? selectBoxNear.hash : hashFile ? hashFile : '',
            pageNr: currentPage,
            rows: filesPerPageLimit,
            folderPath: rootPathParent,
            cursorMark: currentPage === 1 ? '*' : cursorMark,
            searchWord: null,
            sortBy: selectColumnNear.column ? selectColumnNear.column : null,
            sortOrder: selectColumnNear.sorter ? selectColumnNear.sorter : null,
            deduplicationType: 'near-duplication'
        }
        getListDuplicateFileBoxSecond(requestDuplicateFileBoxSecondDTO).then((response) => {
            const duplicateFiles = []
            response.data.duplicateFileInfoSecondDTOList && response.data.duplicateFileInfoSecondDTOList.length > 0 && response.data.duplicateFileInfoSecondDTOList.forEach((file, index) => {
                file.selectable = false
                duplicateFiles.push(file)
                file.key = index
            })
            if (Object.keys(selectBoxNear) && selectBoxNear.numberOfDuplicates > 20 && response.data.duplicateFileInfoSecondDTOList.length === 20) {
                this.setState({
                    isNextDisabled: false
                })
            }
            this.setState({
                tableDuplicatesOnFile: duplicateFiles,
                duplicateNearFiles: response.data.duplicateFileInfoSecondDTOList,
                totalPages: response.data.totalRows,
                cursorMark: response.data.cursorMark,
                loadingKeepOptionNear: false
            })
        })
    }
    changeFilesPerPageLimit = (e) => {
        this.setState({ filesPerPageLimit: e }, () => {
            this.onGetDuplicateFileNear()
        })
    }
    gotoPage = (newPage) => {
        const { filesPerPageLimit, totalPages } = this.state
        const totalPagesRecord = Math.ceil(totalPages / filesPerPageLimit)
        const currentPage = Math.max(0, Math.min(newPage, totalPagesRecord))
        const isPrevDisabled = currentPage === 1
        const isNextDisabled = currentPage === totalPagesRecord
        this.setState({ currentPage, isPrevDisabled, isNextDisabled }, () => {
            this.onGetDuplicateFileNear()
        })
    }
    handleMoveLeft = (e) => {
        e.preventDefault()
        this.gotoPage(this.state.currentPage - 1)
    }
    handleMoveRight = (e) => {
        e.preventDefault()
        this.setState({
            checkNextDisabled: false
        })
        this.gotoPage(this.state.currentPage + 1)
    }
    onClickBackList = () => {
        this.props.onVisibleNearList(false)
    }
    onMouseEnterRow = (e, record) => {
        const { tableDuplicatesOnFile, checkBoxSelectedFiles, statusDeleteMouseEnter } = this.state
        const updateListDuplicate = []
        if (!statusDeleteMouseEnter) {
            tableDuplicatesOnFile && tableDuplicatesOnFile.length > 0 && tableDuplicatesOnFile.forEach((file) => {
                if (tableDuplicatesOnFile.length === checkBoxSelectedFiles.length) {
                    file.selectable = true
                } else
                if (file.path === record.path) {
                    file.selectable = true
                } else if (checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0) {
                    checkBoxSelectedFiles.forEach((duplicate) => {
                        duplicate.selectable = true
                        if (duplicate.selectable === false) {
                            file.selectable = true
                        } else if (duplicate.selectable === true && file.path === duplicate.path) {
                            file.selectable = true
                        } else
                        if (file.path === duplicate.path) {
                            file.selectable = true
                        }
                        else {
                            file.selectable = false
                        }
                    })
                } else {
                    file.selectable = false
                }
                updateListDuplicate.push(file)
            })
            this.setState({
                tableDuplicatesOnFile: updateListDuplicate,
                checkSelectedAll: false
            })
        }
        this.setState({
            selectedFile: record
        })
    }
    onMouseLeaveRow = (e, record) => {
        this.setState({
            selectedFile: {},
            deleteSelectableFile: {},
            statusDeleteMouseEnter: false
        })
    }
    onCheckBoxFiles = (e, file) => {
        file.selectable = true
        const { checkBoxSelectedFiles, duplicateNearFiles } = this.state
        checkBoxSelectedFiles.push(file)
        const uniqueFiles = [...new Set(checkBoxSelectedFiles.map((item) => item))]
        if (e.target.checked === false) {
            const unCheckedFile = checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0 && checkBoxSelectedFiles.filter((item2) => item2.path !== file.path)
            this.setState({
                checkBoxSelectedFiles: unCheckedFile
            })
        } else {
            const newTableList = []
            uniqueFiles && uniqueFiles.length > 0 && uniqueFiles.forEach((uniqF) => {
                uniqF.selectable = true
            })
            duplicateNearFiles && duplicateNearFiles.length && duplicateNearFiles.forEach((table) => {
                if (file.path === table.path) {
                    table.selectable = true
                }
                newTableList.push(table)
            })
            this.setState({
                checkBoxSelectedFiles: uniqueFiles,
                tableDuplicatesOnFile: newTableList
            })
        }

    }
    onMouseEnterRowDelete = (e) => {
        this.setState({
            statusDeleteMouseEnter: true
        })
    }
    onMouseLeaveRowDelete = (e) => {
        this.setState({
            statusDeleteMouseEnter: false
        })
    }
    handleDeleteSelectedFile = (e, file) => {
        this.setState({
            deleteSelectableFile: file
        })
        customConfirm({
            title: <span className="content-title-lock"><span className="icon-v21-lock"></span><span className="title-lock">{ file.fileName }</span></span>,
            content: `is the only file you want to keep?`,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-duplicate modal-delete-duplicate-selected confirm-near',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onDeleteNearFilesSelected(e, file)
            }
        })
    }
    onDeleteNearFilesSelected = (e, file) => {
        const { filesPerPageLimit, cursorMark, tableDuplicatesOnFile, selectColumnNear } = this.state
        const { folder, selectBoxNear } = this.props
        const selectFilesPath = []
        tableDuplicatesOnFile && tableDuplicatesOnFile.length > 0 && tableDuplicatesOnFile.forEach((fileName) => {
            if (fileName.originalPath !== file.originalPath) {
                selectFilesPath.push(fileName.originalPath)
            }
        })
        //this.props.setSmartBoxProgressCleanDeleteDuplicates(false, '')
        this.setState({
            statusCleanDuplicate: true
        })
        const requestDeleteDuplicateDTO = {
            folderPath: folder.path,
            hash: selectBoxNear.hash,
            pathList: selectFilesPath,
            rows: filesPerPageLimit,
            pageNr: this.state.currentPage,
            cursorMark: this.state.currentPage === 1 ? '*' : cursorMark,
            boxId: folder.id,
            sortBy: selectColumnNear.column ? selectColumnNear.column : null,
            sortOrder: selectColumnNear.sorter ? selectColumnNear.sorter : null,

        }
        this.setState({
            loadingKeepOptionNear: true
        }, () => {
            apiListDeleteNear(requestDeleteDuplicateDTO).then((response) => {
                if (response.status === 200) {
                    //this.props.setSmartBoxProgressCleanDeleteDuplicates(response.data, '')
                    this.onGetNearStatus()
                    //this.onGetDuplicateFileNear()
                    this.setState({
                        selectedRowKeys: [],
                        selectedRowsList: [],
                        statusCleanDuplicate: false
                    })
                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        })
    }
    handleSweepSelectedFiles = () => {
        const { checkBoxSelectedFiles } = this.state
        if ( checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0) {
            //const dd = checkBoxSelectedFiles.map((list) => { return list.fileName })
            customConfirm({
                title: <span className="content-titles-sweep"><span className="icon-v21-lock"></span><span className="title-sweep">{ checkBoxSelectedFiles.map((list) => { return `${list.fileName} ` }) }</span></span>,
                content: `is the only files you want to keep?`,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal modal-delete-duplicate modal-delete-duplicate-selected confirm-near',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.onSweepNearFilesSelected()
                }
            })
        }
    }
    onSweepNearFilesSelected = () => {
        const { filesPerPageLimit, cursorMark, checkBoxSelectedFiles, selectColumnNear } = this.state
        const { folder, selectBoxNear } = this.props
        const selectFilesPath = []
        checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0 && checkBoxSelectedFiles.forEach((file) => {
            selectFilesPath.push(file.originalPath)
        })
        this.setState({
            statusCleanDuplicate: true
        })
        const requestDeleteDuplicateDTO = {
            folderPath: folder.path,
            hash: selectBoxNear.hash,
            pathList: selectFilesPath,
            rows: filesPerPageLimit,
            pageNr: this.state.currentPage,
            cursorMark: this.state.currentPage === 1 ? '*' : cursorMark,
            boxId: folder.id,
            sortBy: selectColumnNear.column ? selectColumnNear.column : null,
            sortOrder: selectColumnNear.sorter ? selectColumnNear.sorter : null,

        }
        this.setState({
            loadingKeepOptionNear: true
        }, () => {
            apiListDeleteNear(requestDeleteDuplicateDTO).then((response) => {
                if (response.status === 200) {
                    this.onGetNearStatus()
                    //this.onGetDuplicateFileNear()
                    this.setState({
                        selectedRowKeys: [],
                        selectedRowsList: [],
                        statusCleanDuplicate: false
                    })
                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        })
    }

    onGetNearStatus = () => {
        const { setIntervalNear } = this.state
        const { folder } = this.props
        apiGetBoxNearStatus(folder.id).then((response) => {
            if (response.status === 200) {
                cookie.save('checkNearStatus', 'getNear', {path: '/'})
                this.setState({
                    checkStatusNear: response.data
                })
                if (response.data === 'In Progress') {
                    this.setIntervalNearStatus()
                } else if (response.data === 'Finished') {
                    clearInterval(setIntervalNear)
                    this.setState({setIntervalNear})
                    this.onGetDuplicateFileNear()
                }
            }
        })
    }
    setIntervalNearStatus = () => {
        const {  checkStatusNear } = this.state
        let { setIntervalNear } = this.state
        if (setIntervalNear === 0) {
            if (checkStatusNear === 'In Progress') {
                setIntervalNear = setInterval(this.callBackNearStatus, 1000)
                this.setState({
                    setIntervalNear
                })
            } else {
                clearInterval(setIntervalNear)
            }
        }
    }
    callBackNearStatus = () => {
        const { setIntervalNear, checkStatusNear } = this.state
        const { folder } = this.props
        const itemNear = cookie.load('checkNearStatus', { path: '/' })
        if (checkStatusNear === 'In Progress' && itemNear === 'getNear') {
            apiGetBoxNearStatus(folder.id).then((response) => {
                if (response.data === 'Finished') {
                    this.onGetDuplicateFileNear()
                    clearInterval(setIntervalNear)
                    this.setState({ setIntervalNear })
                }  else {
                    this.setState({ setIntervalNear })
                }
                this.setState({
                    checkStatusNear: response.data
                })
            })
        } else if (checkStatusNear === 'In Progress') {
            clearInterval(setIntervalNear)
            this.setState({ setIntervalNear })
        }
    }

    onSorterColumnsTenant = (e, column, sorter) => {
        const selectCol = {
            column: column,
            sorter: sorter
        }
        this.setState({
            selectColumnNear: selectCol
        }, () => {
            this.onGetDuplicateFileNear()
        })
    }
    onPreviewFileNear = (e, record) => {
        const path = record.path
        apiPreviewFileDuplicate(path).then((response) => {
            if (response.data && response.data.doc) {
                this.windowDocument = generateBlobFromBase64(response.data.doc)
                this.setState({
                    renderingBlock: <PDFViewer backend={ PDFJSBackend } src={ this.windowDocument } toolbar={ 0 }/>,
                    previewVisibleNear: true
                })
            } else {
                CustomNotificationManager.info('File no longer exists.', 'Aborted')
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    handleCancelModal = () => this.setState({ previewVisibleNear: false })
    onMouseEnterActive = (e, status) => {
        this.setState({
            activePageBack: true
        })
    }
    onMouseLeaveActive() {
        this.setState({
            activePageBack: false
        })
    }
    onClickRowSelected = (e, record, rowIndex) => {
        record.id = rowIndex
        this.setState({
            selectedClickFileRow: record
        })
    }
    onLeaveRowSelected = (e, record, rowIndex) => {
        this.setState({
            selectedClickFileRow: {}
        })
    }
    onMouseEnterDots = (e, record) => {
      this.setState({
          selectDots: record
      })
    }
    onMouseLeaveDots = (e, record) => {
        //selectDots: {}
    }
    onTimezoneConvert = (time) => {
        const localTimeZone = moment.tz.guess()
        const localDate = moment.utc(time).tz(localTimeZone)
        return `${ moment(localDate).format('DD.MM.YYYY HH:mm:ss').split(' ').join(' at ') }`
    }
    render() {
        const { filesPerPageLimit, isPrevDisabled, isNextDisabled, duplicateNearFiles, checkBoxSelectedFiles,
            deleteSelectableFile, selectColumnNear, selectedFile, renderingBlock, previewVisibleNear, activePageBack, selectedClickFileRow, selectDots,
            loadingKeepOptionNear } = this.state
        const { selectBoxNear } = this.props
        return (
            <div className="near-table">
                <div className="near-table__content">
                    <div className="near-table-header">
                        <div className="name-box-info">
                            <span onClick={ this.onClickBackList } className={ activePageBack ? 'icon-v21-back-active' : "icon-v21-back-1" }
                                  onMouseEnter={(e) => this.onMouseEnterActive(e, 'back-page')}
                                  onMouseLeave={(e) => this.onMouseLeaveActive(e)}></span>
                            <span className="content-count-file"><span className="name-count">{ Object.keys(selectBoxNear) ? selectBoxNear.numberOfDuplicates : ''}</span></span>
                            <span className="name-file">{ Object.keys(selectBoxNear) ? selectBoxNear.fileName : ''}</span>
                        </div>
                        <div className={duplicateNearFiles.length === 0 ? "per-page-near disabled" : "per-page-near"}>
                            <Select dropdownClassName="select-per-page-near select-per-page-near-update"
                                    value={`Show`+ ' ' + `${filesPerPageLimit}`}
                                    onChange={ (e) => this.changeFilesPerPageLimit(e) }
                                    disabled={duplicateNearFiles.length === 0}>
                                <Option value="20">20</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                        <div className="button-control-near">
                            <Buttons type="primary" theme="button-control-near button-control-near__left"
                                    onClick={ this.handleMoveLeft }
                                    disabled={ isPrevDisabled }
                            >
                                <Icon type="left"/>
                            </Buttons>
                            <Buttons type="primary" theme="button-control-near button-control-near__right"
                                    onClick={ this.handleMoveRight }
                                    disabled={ isNextDisabled }
                            >
                                <Icon type="right"/>
                            </Buttons>
                        </div>
                        <div className="sweep-info">
                            {/*<span className="icon-v14-question"></span>*/}
                            <Button className="button-blue-sweep"
                                    onClick={(e) => this.handleSweepSelectedFiles(e)}
                                   disabled={ checkBoxSelectedFiles.length === 0 }>
                                Sweep
                            </Button>
                        </div>
                    </div>

                    <div className="table-near-content">
                        <Table
                            dataSource={ duplicateNearFiles }
                            className="table-near table-near-list"
                            pagination={ false }
                            showHeader={ true }
                            loading={loadingKeepOptionNear}
                            onRow={(record, rowIndex) => {
                                return {
                                    onMouseEnter: (e) => this.onClickRowSelected(e, record, rowIndex),
                                    onMouseLeave: (e) => this.onLeaveRowSelected(e, record, rowIndex)
                                }
                            }}>
                            {/*<Column className="column-sweep-duplicate" dataIndex="sweep" key="sweep" render={(text, record) => {*/}
                            {/*    return (*/}
                            {/*        <span className="sweep-duplicate-content">*/}
                            {/*           <span>dd</span>*/}
                            {/*     </span>*/}
                            {/*    )*/}
                            {/*}}/>*/}
                            <Column className="column-name-duplicate" title="Name" dataIndex="fileName" key="fileName" render={(text, record, rowIndex) => {
                                return (
                                    <div className="duplicate-list-name update-list-name">
                                        <React.Fragment>
                                            { record.selectable && deleteSelectableFile && deleteSelectableFile.path !== record.path ?
                                                <Checkbox onChange={ (e) => this.onCheckBoxFiles(e, record) }></Checkbox> :
                                                <span className="icon-v21-clean"
                                                      onClick={ (e) => this.handleDeleteSelectedFile(e, record) }
                                                      onMouseEnter={(e) => this.onMouseEnterRowDelete(e, record)}
                                                      onMouseLeave={(e) => this.onMouseLeaveRowDelete(e)}></span>
                                            }
                                        </React.Fragment>
                                        <span className="update-column-near" onMouseEnter= { (e) => this.onMouseEnterRow(e, record, rowIndex) }
                                              onMouseLeave= { (e) => this.onMouseLeaveRow(e, record, rowIndex) }>{ text }</span>
                                    </div>
                                )
                            }}/>
                            <Column className="column-name-duplicate" title={
                                <span className="content-date">
                                    <span>Date</span>
                                      <span className="content-buttons-sorter">
                                      <span className={ selectColumnNear && selectColumnNear.column === 'date' && selectColumnNear.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                            onClick={(e) => this.onSorterColumnsTenant(e, 'date', 'asc')}></span>
                                     <span className={ selectColumnNear && selectColumnNear.column === 'date' && selectColumnNear.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                           onClick={(e) => this.onSorterColumnsTenant(e, 'date', 'desc')}></span>
                                 </span>
                                </span>
                            } dataIndex="date" key="date" render={(text, record, rowIndex) => {
                                return (
                                    <span className="update-column-near"  onMouseEnter= { (e) => this.onMouseEnterRow(e, record, rowIndex) }
                                          onMouseLeave= { (e) => this.onMouseLeaveRow(e, record, rowIndex) }>{ text ? this.onTimezoneConvert(text) : 'unknown' }</span>
                                )
                            }}/>
                            <Column className="column-name-duplicate" title={
                                <span className="content-date">
                                    <span>Size</span>
                                      <span className="content-buttons-sorter">
                                      <span className={ selectColumnNear && selectColumnNear.column === 'size' && selectColumnNear.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                            onClick={(e) => this.onSorterColumnsTenant(e, 'size', 'asc')}></span>
                                     <span className={ selectColumnNear && selectColumnNear.column === 'size' && selectColumnNear.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                           onClick={(e) => this.onSorterColumnsTenant(e, 'size', 'desc')}></span>
                                 </span>
                                </span>
                            }
                                    dataIndex="docSIze" key="docSIze" render={(text, record, rowIndex) => {
                                return (
                                    <span className="update-column-near"  onMouseEnter= { (e) => this.onMouseEnterRow(e, record, rowIndex) }
                                          onMouseLeave= { (e) => this.onMouseLeaveRow(e, record, rowIndex) }>{handleByteConversion(text)}</span>
                                )
                            }}/>
                            <Column className="column-sweep-duplicate" dataIndex="docId" key="docId" render={(text, record, rowIndex) => {
                                return (
                                    <div key={ record.key } className="actionsDotsCell action-drop-cell menu-duplicate"
                                         onMouseEnter= { (e) => this.onMouseEnterRow(e, record, rowIndex) }
                                         onMouseLeave= { (e) => this.onMouseLeaveRow(e, record, rowIndex) }>
                                        <DropdownButton
                                            className="dots-menu-sar"
                                            title={ ((selectedFile && selectedFile.path === record.path) || (selectedClickFileRow && selectedClickFileRow.path === record.path)) ?
                                                <div className="ellipsis-v2"
                                                     onMouseEnter= { (e) => this.onMouseEnterDots(e, record, rowIndex) }
                                                     onMouseLeave= { (e) => this.onMouseLeaveDots(e, record, rowIndex) }
                                                ><span
                                                    className={ selectDots && selectDots.path === record.path ? 'icon-v14-options-small-active' : ((selectedFile && selectedFile.path === record.path) || (selectedClickFileRow && selectedClickFileRow.path === record.path)) ? 'icon-v14-options-small' : 'icon-v14-options-small' }></span></div> : <div className="ellipsis-v3">,,,,</div> }
                                            nocaret="true"
                                            data-toggle="dropdown"
                                            id={ 'dropdown-1' }
                                            onClick={ (e) => {
                                                // e.stopPropagation()
                                            } }
                                            flip={false}
                                            bsstyle="default">
                                            <Dropdown.Item onClick={ (e) => this.onPreviewFileNear(e, record) } className="menu-left-item-directory"
                                            >
                                                <span>Preview</span>
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                )
                            }}/>
                        </Table>
                    </div>

                </div>
                <Modal visible={ previewVisibleNear } footer={ null } onCancel={ this.handleCancelModal } className="pdf-modal">
                    <div className="custom-modal-body">
                        { renderingBlock }
                    </div>
                </Modal>
            </div>
        )

    }
}

NearList.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default compose(
    connect(mapStateToProps),
)(NearList)