import React from 'react'
import {Button, Select, Modal, Table, Input, Checkbox, Popover, Icon} from 'antd'
import '../Admin.scss'
import './BrandingProfiles.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Link} from "react-router-dom";
import {CompactPicker} from "react-color";
import {apiEditNewBrandingProfile, apiSaveNewBrandingProfile} from "../../../api/SmartBox";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import ImageUploader from "react-images-upload";
import headerLogo from './../../../assets/Key visual 40x40.svg';
import new_Smartbox_Logo from '../../../assets/SB Logo Login 290x80.svg'
import FormData from "form-data";
import axios from "axios";
import {ConcurrencyManager} from "axios-concurrency";
import _ from "lodash";

const Option = Select.Option
const { Column } = Table
const isLocalhost = () => {
    return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
class BrandingProfilesCreate extends React.Component {
    constructor () {
        super()
    this.state = {
        listPrimaryColour: [
            {id: 'primary-colour', name: 'Primary colour', colour: '#382E34', changeColour: '#FAFAFA'},
            {id: 'primary-shade1', name: 'Shade I', colour: '#665360', changeColour: '#FAFAFA'},
            {id: 'primary-shade2', name: 'Shade II', colour: '#150010', changeColour: '#FAFAFA'},
        ],
        listSecondaryColour: [
            {id: 'secondary-colour', name: 'Secondary colour', colour: '#EDA41E', changeColour: '#FAFAFA'},
            {id: 'secondary-shade1', name: 'Shade I', colour: '#EEC457', changeColour: '#FAFAFA'},
            {id: 'secondary-shade2', name: 'Shade II', colour: '#C4812F', changeColour: '#FAFAFA'},
        ],
        listBrandingColour: [
            {colorCode: 'primary-colour', colorName: 'Primary colour', defaultColor: '#382E34', customColor: '#FAFAFA'},
            {colorCode: 'primary-shade1', colorName: 'Shade I', defaultColor: '#665360', customColor: '#FAFAFA'},
            {colorCode: 'primary-shade2', colorName: 'Shade II', defaultColor: '#150010', customColor: '#FAFAFA'},
            {colorCode: 'secondary-colour', colorName: 'Secondary colour', defaultColor: '#EDA41E', customColor: '#FAFAFA'},
            {colorCode: 'secondary-shade1', colorName: 'Shade I', defaultColor: '#EEC457', customColor: '#FAFAFA'},
            {colorCode: 'secondary-shade2', colorName: 'Shade II', defaultColor: '#C4812F', customColor: '#FAFAFA'},
        ],
        listBrandingNavigationColor: [
            {colorCode: 'background', colorName: 'Background', defaultColor: '#535d66', customColor:''},
            {colorCode: 'background-hover', colorName: 'Background hover', defaultColor: '#535d66', customColor:''},
            {colorCode: 'nav-body-font', colorName: 'Navigation body font', defaultColor: '#ffffff', customColor:''},
            {colorCode: 'nav-body-font-hover', colorName: 'Navigation body font hover', defaultColor: '#00d4d8', customColor:''},
            {colorCode: 'nav-home-icon', colorName: 'Home icon', imgDefault:""},
        ],
        listBrandingSubNavigationColor: [
            {colorCode: 'sub-background', colorName: 'Background', defaultColor: '#6e7882', customColor:''},
            {colorCode: 'sub-background-hover', colorName: 'Background hover', defaultColor: '#6e7882', customColor:''},
            {colorCode: 'sub-nav-body-font', colorName: 'Navigation body font', defaultColor: '#ffffff', customColor:''},
            {colorCode: 'sub-nav-body-font-hover', colorName: 'Navigation body font hover', defaultColor: '#00d4d8', customColor:''},
            {colorCode: 'sub-nav-body-font-active', colorName: 'Navigation body font active', defaultColor: '#00d4d8', customColor:''},
        ],
        listBrandingGeneralColor: [
            {colorCode: 'general-icon-font-hover', colorName: 'Icon & font hover', defaultColor: '#4096ff', customColor:''},
            {colorCode: 'general-icon-font-active', colorName: 'Icon & font active', defaultColor: '#0958d9', customColor:''},
            {colorCode: 'general-button', colorName: 'Button', defaultColor: '#1677ff', customColor:''},
            {colorCode: 'general-button-hover', colorName: 'Button hover', defaultColor: '#4096ff', customColor:''},
            {colorCode: 'general-button-active', colorName: 'Button active', defaultColor: '#0958d9', customColor:''},
            {colorCode: 'general-button-font', colorName: 'Button font', defaultColor: '#ffffff', customColor:''},
        ],
        color: '#fff',
        colorPickerVisible: {},
        selectedPrimaryColour: '',
        listCheckBoxPrimaryColour: [],
        colorSecondary: '#fff',
        colorPickerVisibleSecondary: {},
        selectedSecondaryColour: '',
        listCheckBoxSecondaryColour: [],
        valueInputProfile: '',
        colorNavHeader:'#fff',
        colorPickerVisibleNavHeader:{},
        colorPickerVisibleSubNavHeader:{},
        colorPickerVisibleGeneral:{},
        selectedNavHeaderColor:'',
        selectedSubNavHeaderColor:'',
        selectedGeneralColor:'',
        picture: '',
        showPicture: '',
        showPictureCompany: '',
        colorGeneral:'#fff',
    }
        this.state.pictureImg = []
        this.state.pictureImgCompany = []
        this.onDrop = this.onDrop.bind(this)
        this.onDropCompanyLogo = this.onDropCompanyLogo.bind(this)
        this.imageUpload = React.createRef()
    }

    onDrop(pictures, done) {
        const {listBrandingNavigationColor} = this.state
        if (this.imageUpload &&
            (Object.keys(pictures).length === 0 ||
                this.imageUpload.state?.notAcceptedFileSize?.length ||
                this.imageUpload.state?.notAcceptedFileType?.length
            ))
        {
            // buildImageErrorNotification(this.imageUpload)

            this.imageUpload.setState({
                notAcceptedFileType: [],
                notAcceptedFileSize: []
            })

        } else {
            this.setState({
                pictures: this.state.pictureImg.concat(pictures),
                showPicture: done
            })
            if (done.length > 1) {
                this.setState({
                    showPicture: done[done.length -1]
                })
            }
            if (done.length > 1) {
                if (listBrandingNavigationColor.length) {
                    listBrandingNavigationColor.map((item) => {
                        if (item.colorCode === 'nav-home-icon') {
                            item.imgDefault = done[done.length -1]
                        }
                    })
                }
                this.setState({
                    listBrandingNavigationColor
                })
            } else {
                if (listBrandingNavigationColor.length) {
                    listBrandingNavigationColor.map((item) => {
                        if (item.colorCode === 'nav-home-icon') {
                            item.imgDefault = done
                        }
                    })
                }
                this.setState({
                    listBrandingNavigationColor
                })
            }
        }
    }
    componentDidMount() {
        this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
        this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
        if(this.props.visibleProfileSettings && this.props.statusProfile === 'modify') {
            const listBrandingNavigationColor2 = []
            const listBrandingSubNavigationColor2 = []
            const listBrandingGeneralColor2 = []
            this.props.selectProfile.colorsDTOS && this.props.selectProfile.colorsDTOS.length > 0 && this.props.selectProfile.colorsDTOS.forEach((item) => {
                if (item.colorCode === 'background' || item.colorCode === 'background-hover' || item.colorCode === 'nav-body-font' || item.colorCode === 'nav-body-font-hover') {
                    item.colorName = item.colorCode === 'background' ? 'Background' : item.colorCode === 'background-hover' ? 'Background hover' :
                        item.colorCode === 'nav-body-font' ? 'Navigation body font' :
                            item.colorCode === 'nav-body-font-hover' ? 'Navigation body font hover' : ''
                    item.defaultColor = item.colorCode === 'background' ? '#535d66' : item.colorCode === 'background-hover' ? '#535d66' :
                        item.colorCode === 'nav-body-font' ? '#ffffff' :
                            item.colorCode === 'nav-body-font-hover' ? '#00d4d8' : ''
                    listBrandingNavigationColor2.push(item)
                }
            })
            listBrandingNavigationColor2.push({
                colorCode: 'nav-home-icon', colorName: 'Home icon', imgDefault:""
            })
            this.props.selectProfile.colorsDTOS && this.props.selectProfile.colorsDTOS.length > 0 && this.props.selectProfile.colorsDTOS.forEach((item) => {
                if (item.colorCode === 'sub-background' || item.colorCode === 'sub-background-hover' || item.colorCode === 'sub-nav-body-font' || item.colorCode === 'sub-nav-body-font-hover' || item.colorCode === 'sub-nav-body-font-active') {
                    item.colorName = item.colorCode === 'sub-background' ? 'Background' : item.colorCode === 'sub-background-hover' ? 'Background hover' :
                        item.colorCode === 'sub-nav-body-font' ? 'Navigation body font' :
                            item.colorCode === 'sub-nav-body-font-hover' ? 'Navigation body font hover' :
                                item.colorCode === 'sub-nav-body-font-active' ? 'Navigation body font active' : ''
                    item.defaultColor = item.colorCode === 'sub-background' ? '#6e7882' : item.colorCode === 'sub-background-hover' ? '#6e7882' :
                        item.colorCode === 'sub-nav-body-font' ? '#ffffff' :
                            item.colorCode === 'sub-nav-body-font-hover' ? '#00d4d8' :
                                item.colorCode === 'sub-nav-body-font-active' ? '#00d4d8' : ''
                    listBrandingSubNavigationColor2.push(item)
                }
            })

            this.props.selectProfile.colorsDTOS && this.props.selectProfile.colorsDTOS.length > 0 && this.props.selectProfile.colorsDTOS.forEach((item) => {
                if (item.colorCode === 'general-icon-font-hover' || item.colorCode === 'general-icon-font-active' || item.colorCode === 'general-button'
                    || item.colorCode === 'general-button-hover' || item.colorCode === 'general-button-active' || item.colorCode === 'general-button-font') {
                    item.colorName = item.colorCode === 'general-icon-font-hover' ? 'Icon & font hover' : item.colorCode === 'general-icon-font-active' ? 'Icon & font active' :
                        item.colorCode === 'general-button' ? 'Button' :
                            item.colorCode === 'general-button-hover' ? 'Button hover' :
                                item.colorCode === 'general-button-active' ? 'Button active' :
                                item.colorCode === 'general-button-font' ? 'Button font' : ''
                    item.defaultColor = item.colorCode === 'general-icon-font-hover' ? '#4096ff' : item.colorCode === 'general-icon-font-active' ? '#0958d9' :
                        item.colorCode === 'general-button' ? '#1677ff' :
                            item.colorCode === 'general-button-hover' ? '#4096ff' :
                                item.colorCode === 'general-button-active' ? '#0958d9' :
                                item.colorCode === 'general-button-font' ? '#ffffff' : ''
                    listBrandingGeneralColor2.push(item)
                }
            })
             if (listBrandingGeneralColor2.length === 0) {
                 listBrandingGeneralColor2.push( {colorCode: 'general-icon-font-hover', colorName: 'Icon & font hover', defaultColor: '#4096ff', customColor:''},
                     {colorCode: 'general-icon-font-active', colorName: 'Icon & font active', defaultColor: '#0958d9', customColor:''},
                     {colorCode: 'general-button', colorName: 'Button', defaultColor: '#1677ff', customColor:''},
                     {colorCode: 'general-button-hover', colorName: 'Button hover', defaultColor: '#4096ff', customColor:''},
                     {colorCode: 'general-button-active', colorName: 'Button active', defaultColor: '#0958d9', customColor:''},
                     {colorCode: 'general-button-font', colorName: 'Button font', defaultColor: '#ffffff', customColor:''})
             }
            const uniqueBrandingNavigationColor2 = listBrandingNavigationColor2 && listBrandingNavigationColor2.length > 0 && listBrandingNavigationColor2.filter((a, i) => listBrandingNavigationColor2.findIndex((s) => a.colorCode === s.colorCode) === i)
            this.setState({
                valueInputProfile: this.props.selectProfile.profileName,
                profileId: this.props.selectProfile.profileId,
                listBrandingColour: this.props.selectProfile.colorsDTOS,
                listBrandingNavigationColor: uniqueBrandingNavigationColor2,
                listBrandingSubNavigationColor: listBrandingSubNavigationColor2,
                listBrandingGeneralColor: listBrandingGeneralColor2,
                showPicture: this.props.selectProfile.homeIcon,
                showPictureCompany: this.props.selectProfile.companyLogo,
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
            this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
            this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
        }
    }
    convertRgbaToString = ({ r, g, b, a }) => {
        return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
    }
    closeAndSaveColorPicker = (color) => {
        const {listBrandingColour, selectedPrimaryColour} = this.state
        if (listBrandingColour.length) {
            listBrandingColour.map((item) => {
                if (item.colorCode === selectedPrimaryColour && item.colorCode.includes('primary')) {
                    item.customColor = color.hex
                }
            })

        }
        this.setState({
            listBrandingColour
        })
    }
    closeAndSaveColorPickerSecondary = (color) => {
        const {listBrandingColour, selectedSecondaryColour} = this.state
        if (listBrandingColour.length) {
            listBrandingColour.map((item) => {
                if (item.colorCode === selectedSecondaryColour && item.colorCode.includes('secondary')) {
                    item.customColor = color.hex
                }
            })

        }
        this.setState({
            listBrandingColour
        })
    }
    closeAndSaveColorPickerNavHeader = (color) => {
        const {listBrandingNavigationColor, selectedNavHeaderColor} = this.state
        if (listBrandingNavigationColor.length) {
            listBrandingNavigationColor.map((item) => {
                if (item.colorCode === selectedNavHeaderColor) {
                    item.color = color.hex
                }
            })
        }
        this.setState({
            listBrandingNavigationColor
        })
    }

    closeAndSaveColorPickerSubNavHeader = (color) => {
        const {listBrandingSubNavigationColor, selectedSubNavHeaderColor} = this.state
        if (listBrandingSubNavigationColor.length) {
            listBrandingSubNavigationColor.map((item) => {
                if (item.colorCode === selectedSubNavHeaderColor) {
                    item.color = color.hex
                }
            })
        }
        this.setState({
            listBrandingSubNavigationColor
        })
    }

    closeAndSaveColorPickerGeneral = (color) => {
        const {listBrandingGeneralColor, selectedGeneralColor} = this.state
        if (listBrandingGeneralColor.length) {
            listBrandingGeneralColor.map((item) => {
                if (item.colorCode === selectedGeneralColor) {
                    item.color = color.hex
                }
            })
        }
        this.setState({
            listBrandingGeneralColor
        })
    }

    toggleColorPickerVisible = (e, id, open) => {
       const { listBrandingColour } = this.state
        if (e) {
            e.stopPropagation()
        }
        const temp= {}
        if (listBrandingColour.length) {
            listBrandingColour.map((item) => {
                if (item.colorCode !== id && item.colorCode.includes('primary')) {
                    temp[item.colorCode]= false
                }
                return true
            })
        }
        temp[id] =  !this.state.colorPickerVisible[id]
        this.setState({
            colorPickerVisible: temp,
            selectedPrimaryColour: id,
        })
    }

    toggleColorPickerSecondaryVisible = (e, id, open) => {
        const { listBrandingColour } = this.state
        if (e) {
            e.stopPropagation()
        }
        const temp= {}
        if (listBrandingColour.length) {
            listBrandingColour.map((item) => {
                if (item.colorCode !== id && item.colorCode.includes('secondary')) {
                    temp[item.colorCode]= false
                }
                return true
            })
        }
        temp[id] =  !this.state.colorPickerVisibleSecondary[id]
        this.setState({
            colorPickerVisibleSecondary: temp,
            selectedSecondaryColour: id,
        })
    }
    toggleColorPickerNavHeaderVisible = (e, id, open) => {
        const { listBrandingNavigationColor } = this.state
        if (e) {
            e.stopPropagation()
        }
        const temp= {}
        if (listBrandingNavigationColor.length) {
            listBrandingNavigationColor.map((item) => {
                if (item.colorCode !== id) {
                    temp[item.colorCode]= false
                }
                return true
            })
        }
        temp[id] =  !this.state.colorPickerVisibleNavHeader[id]
        this.setState({
            colorPickerVisibleNavHeader: temp,
            selectedNavHeaderColor: id,
        })
    }

    toggleColorPickerSubNavHeaderVisible = (e, id, open) => {
        const { listBrandingSubNavigationColor } = this.state
        if (e) {
            e.stopPropagation()
        }
        const temp= {}
        if (listBrandingSubNavigationColor.length) {
            listBrandingSubNavigationColor.map((item) => {
                if (item.colorCode !== id) {
                    temp[item.colorCode]= false
                }
                return true
            })
        }
        temp[id] =  !this.state.colorPickerVisibleSubNavHeader[id]
        this.setState({
            colorPickerVisibleSubNavHeader: temp,
            selectedSubNavHeaderColor: id,
        })
    }

    toggleColorPickerGeneralVisible = (e, id, open) => {
        const { listBrandingGeneralColor } = this.state
        if (e) {
            e.stopPropagation()
        }
        const temp= {}
        if (listBrandingGeneralColor.length) {
            listBrandingGeneralColor.map((item) => {
                if (item.colorCode !== id) {
                    temp[item.colorCode]= false
                }
                return true
            })
        }
        temp[id] =  !this.state.colorPickerVisibleGeneral[id]
        this.setState({
            colorPickerVisibleGeneral: temp,
            selectedGeneralColor: id,
        })
    }

    onCheckBoxPrimaryColour = (e, listColour) => {
      const { listCheckBoxPrimaryColour, listBrandingColour } = this.state
        listCheckBoxPrimaryColour.push(listColour)
        const uniqueFiles = [...new Set(listCheckBoxPrimaryColour.map((item) => item))]
        if (e.target.checked === false) {
            listBrandingColour && listBrandingColour.length > 0 && listBrandingColour.forEach((item) => {
                if (listColour.colorCode === item.colorCode && item.colorCode.includes('primary')) {
                    item.activeCustomColor = false
                }
            })
            const unCheckedFile = listCheckBoxPrimaryColour && listCheckBoxPrimaryColour.length > 0 && listCheckBoxPrimaryColour.filter((item2) => item2.colorCode !== listColour.colorCode)
            this.setState({
                listCheckBoxPrimaryColour: unCheckedFile,
                listBrandingColour
            })
        } else {
            listBrandingColour && listBrandingColour.length > 0 && listBrandingColour.forEach((item) => {
                if (listColour.colorCode === item.colorCode && item.colorCode.includes('primary')) {
                    item.activeCustomColor = true
                }
            })
            this.setState({
                listCheckBoxPrimaryColour: uniqueFiles,
                listBrandingColour
            })
        }
    }

    onCheckBoxSecondaryColour = (e, listColour) => {
        const { listCheckBoxSecondaryColour, listBrandingColour } = this.state
        listCheckBoxSecondaryColour.push(listColour)
        const uniqueFiles = [...new Set(listCheckBoxSecondaryColour.map((item) => item))]
        if (e.target.checked === false) {
            listBrandingColour && listBrandingColour.length > 0 && listBrandingColour.forEach((item) => {
                if (listColour.colorCode === item.colorCode && item.colorCode.includes('secondary')) {
                    item.activeCustomColor = false
                }
            })
            const unCheckedFile = listCheckBoxSecondaryColour && listCheckBoxSecondaryColour.length > 0 && listCheckBoxSecondaryColour.filter((item2) => item2.colorCode !== listColour.colorCode)
            this.setState({
                listCheckBoxSecondaryColour: unCheckedFile,
                listBrandingColour
            })
        } else {
            listBrandingColour && listBrandingColour.length > 0 && listBrandingColour.forEach((item) => {
                if (listColour.colorCode === item.colorCode && item.colorCode.includes('secondary')) {
                    item.activeCustomColor = true
                }
            })
            this.setState({
                listCheckBoxSecondaryColour: uniqueFiles,
                listBrandingColour
            })
        }
    }
    onDiscardChanges = () => {
        this.props.onGetDiscardChange()
    }
    onInputProfile = (e) => {
       this.setState({
           valueInputProfile: e.target.value
       })
    }
    onCreateBrandingProfile = () => {
        const {listPrimaryColour, listSecondaryColour, valueInputProfile, listBrandingColour, profileId, listBrandingNavigationColor, listBrandingSubNavigationColor,
            listBrandingGeneralColor } = this.state
        const { statusProfile } = this.props
      this.props.onGetCreateBrandingProfile(listPrimaryColour, listSecondaryColour, valueInputProfile)

         if (statusProfile && statusProfile === 'create') {
             const colors = []
             // listBrandingColour && listBrandingColour.length > 0 && listBrandingColour.forEach((item) => {
             //     colors.push({
             //         colorCode: item.colorCode,
             //         colorName: item.colorName,
             //         defaultColor: item.defaultColor,
             //         customColor: item.customColor,
             //         activeCustomColor: item.activeCustomColor ? true : false,
             //     })
             // })
             listBrandingNavigationColor  && listBrandingNavigationColor.length > 0 && listBrandingNavigationColor.forEach((item) => {
                 if (item.colorCode !== 'nav-home-icon') {
                     colors.push({
                         colorCode: item.colorCode,
                         color: item.color ? item.color : item.defaultColor,
                         // colorName: item.colorName,
                         // defaultColor:  item.customColor ? item.customColor : item.defaultColor,
                         // customColor: null,
                         // activeCustomColor: true,
                     })
                 }
             })
             listBrandingSubNavigationColor  && listBrandingSubNavigationColor.length > 0 && listBrandingSubNavigationColor.forEach((item) => {
                     colors.push({
                         colorCode: item.colorCode,
                         color: item.color ? item.color : item.defaultColor,
                         // colorName: item.colorName,
                         // defaultColor:  item.customColor ? item.customColor : item.defaultColor,
                         // customColor: null,
                         // activeCustomColor: true,
                     })
             })
             listBrandingGeneralColor  && listBrandingGeneralColor.length > 0 && listBrandingGeneralColor.forEach((item) => {
                 colors.push({
                     colorCode: item.colorCode,
                     color: item.color ? item.color : item.defaultColor,
                 })
             })
             const saveBrandingProfileDTO = {
                 profileName: valueInputProfile ? valueInputProfile : null,
                 homeIconWidth: 40,
                 homeIconHeight: 40,
                 homeIcon: this.state.pictures ? this.state.pictures[0] : null,
                 colors: colors,
                 companyLogoWidth: 290,
                 companyLogoHeight: 80,
                 companyLogo : this.state.picturesCompany  ? this.state.picturesCompany[0] : null,
             }
             // apiSaveNewBrandingProfile(saveBrandingProfileDTO).then((response) => {
             //     if (response.data.title.toLowerCase() === 'success') {
             //         CustomNotificationManager.success(response.data.message, 'Success')
             //     } else if (response.data.title.toLowerCase() === 'info') {
             //         CustomNotificationManager.info(response.data.message, 'Info')
             //     }
             // })
             this.onGetSaveNewBrandingProfile(saveBrandingProfileDTO, colors).then((response) => {
                 if (response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                     CustomNotificationManager.success(response.data.message, 'Success')
                 } else if (response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                     CustomNotificationManager.info(response.data.message, 'Info')
                 }
             })
         } else if (statusProfile && statusProfile === 'modify') {
             const colors = []
        //      listBrandingColour && listBrandingColour.length > 0 && listBrandingColour.forEach((item) => {
        //          colors.push({
        //              colorCode: item.colorCode,
        //              colorName: item.colorName,
        //              defaultColor: item.defaultColor,
        //              customColor: item.customColor,
        //              activeCustomColor: item.activeCustomColor ? true : false,
        //          })
        //      })
             listBrandingNavigationColor  && listBrandingNavigationColor.length > 0 && listBrandingNavigationColor.forEach((item) => {
                 if (item.colorCode !== 'nav-home-icon') {
                     colors.push({
                         colorCode: item.colorCode,
                         color: item.color ? item.color : item.defaultColor,
                         // colorName: item.colorName,
                         // defaultColor:  item.customColor ? item.customColor : item.defaultColor,
                         // customColor: null,
                         // activeCustomColor: true,
                     })
                 }
             })
             listBrandingSubNavigationColor  && listBrandingSubNavigationColor.length > 0 && listBrandingSubNavigationColor.forEach((item) => {
                 colors.push({
                     colorCode: item.colorCode,
                     color: item.color ? item.color : item.defaultColor,
                     // colorName: item.colorName,
                     // defaultColor:  item.customColor ? item.customColor : item.defaultColor,
                     // customColor: null,
                     // activeCustomColor: true,
                 })
             })
             listBrandingGeneralColor  && listBrandingGeneralColor.length > 0 && listBrandingGeneralColor.forEach((item) => {
                 colors.push({
                     colorCode: item.colorCode,
                     color: item.color ? item.color : item.defaultColor,
                 })
             })
             const saveBrandingProfileDTO = {
                 profileName: valueInputProfile ? valueInputProfile : null,
                 profileId: profileId ? profileId : null,
                 homeIconWidth: 40,
                 homeIconHeight: 40,
                 colors: colors,
                 homeIcon: this.state.pictures ? this.state.pictures[0] : null,
                 companyLogoWidth: 290,
                 companyLogoHeight: 80,
                 companyLogo : this.state.picturesCompany  ? this.state.picturesCompany[0] : null,
             }
             this.onGetEditNewBrandingProfile(saveBrandingProfileDTO, colors).then((response) => {
                 if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                     CustomNotificationManager.success(response.data.message, 'Success')
                 } else if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                     CustomNotificationManager.info(response.data.message, 'Info')
                 }
             })
             // apiEditNewBrandingProfile(saveBrandingProfileDTO).then((response) => {
             //     if (response.data.title.toLowerCase() === 'success') {
             //         CustomNotificationManager.success(response.data.message, 'Success')
             //     } else if (response.data.title.toLowerCase() === 'info') {
             //         CustomNotificationManager.info(response.data.message, 'Info')
             //     }
             // })
         }
    }

    onGetSaveNewBrandingProfile = (data, colors) => {
        const obj = new FormData()
        let objData = {}
        const jsonString = JSON.stringify(colors)
        //const jsonString = JSON.stringify(Object.assign({}, colors))
        // obj.append('profileName', data.profileName)
        // obj.append('homeIconWidth', data.homeIconWidth)
        // obj.append('homeIconHeight', data.homeIconHeight)
        // obj.append('companyLogoWidth', data.companyLogoWidth)
        // obj.append('companyLogoHeight', data.companyLogoHeight)
        // if (data.homeIcon) {
        //     obj.append('homeIcon', data.homeIcon)
        // }
        // if (data.companyLogo) {
        //     obj.append('companyLogo', data.companyLogo)
        // }
        // obj.append('colorsJson', jsonString)
        if (data.homeIcon && !data.companyLogo) {
            objData = {
                profileName: data.profileName,
                homeIconWidth: data.homeIconWidth,
                homeIconHeight: data.homeIconHeight,
                companyLogoWidth: data.companyLogoWidth,
                companyLogoHeight: data.companyLogoHeight,
                colorsJson: jsonString,
                homeIcon: data.homeIcon,
            }
        } else if (!data.homeIcon && data.companyLogo) {
            objData = {
                profileName: data.profileName,
                homeIconWidth: data.homeIconWidth,
                homeIconHeight: data.homeIconHeight,
                companyLogoWidth: data.companyLogoWidth,
                companyLogoHeight: data.companyLogoHeight,
                colorsJson: jsonString,
                companyLogo: data.companyLogo,
            }
        } else if (data.homeIcon && data.companyLogo) {
            objData = {
                profileName: data.profileName,
                homeIconWidth: data.homeIconWidth,
                homeIconHeight: data.homeIconHeight,
                companyLogoWidth: data.companyLogoWidth,
                companyLogoHeight: data.companyLogoHeight,
                colorsJson: jsonString,
                companyLogo: data.companyLogo,
                homeIcon: data.homeIcon,
            }
        } else {
            objData = {
                profileName: data.profileName,
                homeIconWidth: data.homeIconWidth,
                homeIconHeight: data.homeIconHeight,
                companyLogoWidth: data.companyLogoWidth,
                companyLogoHeight: data.companyLogoHeight,
                colorsJson: jsonString,
            }
        }
        const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
        const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
        return this.axiosClone({
                method: 'post',
                url: `${ host }/osprey-0.1.0/v1/branding-profile/save-new-branding-profile`,
                data: objData,
                body: body,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${boundary}`
                },
            }
        ).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
            this.setState({
                isSending: false
            })
        })
    }

    onGetEditNewBrandingProfile = (data, colors) => {
        const obj = new FormData()
        let objData = {}
        const jsonString = JSON.stringify(colors)
        //const jsonString = JSON.stringify(Object.assign({}, colors))
        // obj.append('profileName', data.profileName)
        // obj.append('profileId', data.profileId)
        // obj.append('homeIconWidth', data.homeIconWidth)
        // obj.append('homeIconHeight', data.homeIconHeight)
        // obj.append('companyLogoWidth', data.companyLogoWidth)
        // obj.append('companyLogoHeight', data.companyLogoHeight)
        // if (data.homeIcon) {
        //     obj.append('homeIcon', data.homeIcon)
        // }
        // if (data.companyLogo) {
        //     obj.append('companyLogo', data.companyLogo)
        // }
        // obj.append('colorsJson', jsonString)

        if (data.homeIcon && !data.companyLogo) {
            objData = {
                profileName: data.profileName,
                profileId: data.profileId,
                homeIconWidth: data.homeIconWidth,
                homeIconHeight: data.homeIconHeight,
                companyLogoWidth: data.companyLogoWidth,
                companyLogoHeight: data.companyLogoHeight,
                colorsJson: jsonString,
                homeIcon: data.homeIcon,
            }
        } else if (!data.homeIcon && data.companyLogo) {
            objData = {
                profileName: data.profileName,
                profileId: data.profileId,
                homeIconWidth: data.homeIconWidth,
                homeIconHeight: data.homeIconHeight,
                companyLogoWidth: data.companyLogoWidth,
                companyLogoHeight: data.companyLogoHeight,
                colorsJson: jsonString,
                companyLogo: data.companyLogo,
            }
        } else if (data.homeIcon && data.companyLogo) {
            objData = {
                profileName: data.profileName,
                profileId: data.profileId,
                homeIconWidth: data.homeIconWidth,
                homeIconHeight: data.homeIconHeight,
                companyLogoWidth: data.companyLogoWidth,
                companyLogoHeight: data.companyLogoHeight,
                colorsJson: jsonString,
                companyLogo: data.companyLogo,
                homeIcon: data.homeIcon,
            }
        } else {
            objData = {
                profileName: data.profileName,
                profileId: data.profileId,
                homeIconWidth: data.homeIconWidth,
                homeIconHeight: data.homeIconHeight,
                companyLogoWidth: data.companyLogoWidth,
                companyLogoHeight: data.companyLogoHeight,
                colorsJson: jsonString,
            }
        }
        const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
        const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
        return this.axiosClone({
                method: 'post',
                url: `${ host }/osprey-0.1.0/v1/branding-profile/edit-branding-profile`,
                data: objData,
                body: body,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${boundary}`
                },
            }
        ).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
            this.setState({
                isSending: false
            })
        })
    }

    handleChangeHomeIcon = (event) => {
        this.setState({ picture: event.target.value })
    }
    onDropCompanyLogo(pictures, done) {
        if (this.imageUpload &&
            (Object.keys(pictures).length === 0 ||
                this.imageUpload.state?.notAcceptedFileSize?.length ||
                this.imageUpload.state?.notAcceptedFileType?.length
            ))
        {
            // buildImageErrorNotification(this.imageUpload)

            this.imageUpload.setState({
                notAcceptedFileType: [],
                notAcceptedFileSize: []
            })

        } else {
            this.setState({
                picturesCompany: this.state.pictureImgCompany.concat(pictures),
                showPictureCompany: done
            })
            if (done.length > 1) {
                this.setState({
                    showPictureCompany: done[done.length -1]
                })
            }
        }
    }

    render() {
        const { listPrimaryColour, listSecondaryColour, color, colorPickerVisible, listCheckBoxPrimaryColour, listCheckBoxSecondaryColour,
            colorPickerVisibleSecondary, colorSecondary, valueInputProfile, listBrandingColour, listBrandingNavigationColor, colorNavHeader,
            colorPickerVisibleNavHeader, showPicture, listBrandingSubNavigationColor, colorPickerVisibleSubNavHeader, listBrandingGeneralColor,
            colorGeneral, colorPickerVisibleGeneral, showPictureCompany, picturesCompany } = this.state
        const disabled = false
        const renderColorPicker = (
            <React.Fragment>
                <div className="business-term-color-picker">
                    <CompactPicker
                        className="color-picker-a"
                        colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                            'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                            'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                            'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                            'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                            'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                            'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                        disableAlpha={true}
                        color={color}
                        onChange={(color) => this.closeAndSaveColorPicker(color)}
                    />
                </div>
            </React.Fragment>
        )
        const renderSecondaryColorPicker = (
            <React.Fragment>
                <div className="business-term-color-picker">
                    <CompactPicker
                        className="color-picker-a"
                        colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                            'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                            'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                            'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                            'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                            'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                            'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                        disableAlpha={true}
                        color={colorSecondary}
                        onChange={(color) => this.closeAndSaveColorPickerSecondary(color)}
                    />
                </div>
            </React.Fragment>
        )
        const renderColorPickerNavHeader = (
            <React.Fragment>
                <div className="business-term-color-picker">
                    <CompactPicker
                        className="color-picker-a"
                        colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                            'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                            'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                            'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                            'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                            'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                            'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                        disableAlpha={true}
                        color={colorNavHeader}
                        onChange={(color) => this.closeAndSaveColorPickerNavHeader(color)}
                    />
                </div>
            </React.Fragment>
        )

        const renderColorPickerSubNavHeader = (
            <React.Fragment>
                <div className="business-term-color-picker">
                    <CompactPicker
                        className="color-picker-a"
                        colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                            'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                            'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                            'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                            'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                            'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                            'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                        disableAlpha={true}
                        color={colorNavHeader}
                        onChange={(color) => this.closeAndSaveColorPickerSubNavHeader(color)}
                    />
                </div>
            </React.Fragment>
        )

        const renderColorPickerGeneral = (
            <React.Fragment>
                <div className="business-term-color-picker">
                    <CompactPicker
                        className="color-picker-a"
                        colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                            'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                            'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                            'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                            'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                            'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                            'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                        disableAlpha={true}
                        color={colorGeneral}
                        onChange={(color) => this.closeAndSaveColorPickerGeneral(color)}
                    />
                </div>
            </React.Fragment>
        )
        return (
            <div className="branding-profiles-create">
                <div className="header-profile-settings">
                    <span>{this.props.statusProfile === 'create' ? 'Create colour profile' : 'Modify colour profile' }</span>
                </div>
                <div className="input-profile-settings">
                    <span className="name-input">Profile name:</span>
                    <Input className="input-profile" onChange={(e) => this.onInputProfile(e)}
                    value={valueInputProfile }/>
                </div>
                <div className="profile-settings-contain">
                   {/*<div className="primary-colour-contain">*/}
                       {/*{*/}
                       {/*    listBrandingColour && listBrandingColour.length > 0 && listBrandingColour.map((listColour) => {*/}
                       {/*        if (listColour.colorCode.includes('primary')) {*/}
                       {/*            return (*/}
                       {/*                <div className="primary-colour-list" key={listColour.colorCode}>*/}
                       {/*                    <span*/}
                       {/*                        className={listColour.colorName === 'Primary colour' ? "title-category-colour" : 'title-subcategory-colour'}>{`${listColour.colorName}:`}</span>*/}
                       {/*                    <div className="colours-contain">*/}
                       {/*                  <span className="default-colour-contain">*/}
                       {/*                      <span className="square-colour"*/}
                       {/*                            style={{backgroundColor: `${listColour.defaultColor}`}}></span>*/}
                       {/*                      <span className="name-colour">{listColour.defaultColor}</span>*/}
                       {/*                  </span>*/}
                       {/*                        <span className="space-colour"><Icon type="caret-right"/></span>*/}
                       {/*                        <span className="change-colour-contain">*/}
                       {/*                        <Checkbox*/}
                       {/*                            checked={listColour.activeCustomColor ? true : false}*/}
                       {/*                            onChange={(e) => this.onCheckBoxPrimaryColour(e, listColour)}></Checkbox>*/}
                       {/*                        <div className="content-change-colour">*/}
                       {/*                        <Popover content={renderColorPicker} trigger="click"*/}
                       {/*                                 visible={colorPickerVisible[listColour.colorCode]}>*/}
                       {/*                           <div*/}
                       {/*                               className={disabled ? 'icon-change-color disabled' : 'icon-change-color'}*/}
                       {/*                               key={`i-${listColour.colorCode}`}*/}
                       {/*                               style={{backgroundColor: listColour.customColor}}*/}
                       {/*                               onClick={(e) => disabled ? undefined : this.toggleColorPickerVisible(e, listColour.colorCode)}>*/}
                       {/*                           </div>*/}
                       {/*                         </Popover>*/}
                       {/*                              <span*/}
                       {/*                                  className="name-change-colour">{listColour.customColor}</span>*/}
                       {/*                        </div>*/}
                       {/*                    </span>*/}
                       {/*                    </div>*/}
                       {/*                </div>*/}
                       {/*            )*/}
                       {/*        }*/}
                       {/*    })*/}
                       {/*}*/}

                   {/*</div>*/}

                    {/*<div className="primary-colour-contain secondary-colour-contain">*/}
                    {/*    {*/}
                    {/*        listBrandingColour && listBrandingColour.length > 0 && listBrandingColour.map((listColour) => {*/}
                    {/*            if (listColour.colorCode.includes('secondary')) {*/}
                    {/*                return (*/}
                    {/*                    <div className="primary-colour-list" key={listColour.colorCode}>*/}
                    {/*                        <span*/}
                    {/*                            className={listColour.colorName === 'Primary colour' ? "title-category-colour" : 'title-subcategory-colour'}>{`${listColour.colorName}:`}</span>*/}
                    {/*                        <div className="colours-contain">*/}
                    {/*                     <span className="default-colour-contain">*/}
                    {/*                         <span className="square-colour"*/}
                    {/*                               style={{backgroundColor: `${listColour.defaultColor}`}}></span>*/}
                    {/*                         <span className="name-colour">{listColour.defaultColor}</span>*/}
                    {/*                     </span>*/}
                    {/*                            <span className="space-colour"><Icon type="caret-right"/></span>*/}
                    {/*                            <span className="change-colour-contain">*/}
                    {/*                           <Checkbox*/}
                    {/*                               checked={listColour.activeCustomColor ? true : false}*/}
                    {/*                               onChange={(e) => this.onCheckBoxSecondaryColour(e, listColour)}></Checkbox>*/}
                    {/*                           <div className="content-change-colour">*/}
                    {/*                           <Popover content={renderSecondaryColorPicker} trigger="click"*/}
                    {/*                                    visible={colorPickerVisibleSecondary[listColour.colorCode]}>*/}
                    {/*                              <div*/}
                    {/*                                  className={disabled ? 'icon-change-color disabled' : 'icon-change-color'}*/}
                    {/*                                  key={`i-${listColour.colorCode}`}*/}
                    {/*                                  style={{backgroundColor: listColour.customColor}}*/}
                    {/*                                  onClick={(e) => disabled ? undefined : this.toggleColorPickerSecondaryVisible(e, listColour.colorCode)}>*/}
                    {/*                              </div>*/}
                    {/*                            </Popover>*/}
                    {/*                                 <span*/}
                    {/*                                     className="name-change-colour">{listColour.customColor}</span>*/}
                    {/*                           </div>*/}
                    {/*                       </span>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        })*/}
                    {/*    }*/}

                    {/*</div>*/}

                    <div className='navigation-header-contain'>
                        <span className='navigation-header-title'>General</span>
                        {
                            listBrandingGeneralColor && listBrandingGeneralColor.length > 0 && listBrandingGeneralColor.map((listColour,index) => {
                                return (
                                    <div className="navigation-colour-list" key={listColour.colorCode}>
                                        <div className="navigation-colours-contain">
                                                <span className="change-colour-contain">
                                                     <span className='change-colour-nav-header'>{`${listColour.colorName}` + ':'}</span>
                                               <div className="content-change-colour">
                                               <Popover content={ renderColorPickerGeneral } trigger="click"
                                                        visible={colorPickerVisibleGeneral[listColour.colorCode]}>
                                                  <div
                                                      className={disabled ? 'icon-change-color disabled' : 'icon-change-color'}
                                                      key={`i-${listColour.colorCode}`}
                                                      style={{backgroundColor: listColour.color ? listColour.color : listColour.defaultColor}}
                                                      onClick={(e) => disabled ? undefined : this.toggleColorPickerGeneralVisible(e, listColour.colorCode)}>
                                                  </div>
                                                </Popover>
                                                     <span className="name-change-colour">{listColour.color ? listColour.color : listColour.defaultColor}</span>
                                               </div>
                                           </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className='navigation-header-contain'>
                        <span className='navigation-header-title'>Navigation Header</span>
                        {
                            listBrandingNavigationColor && listBrandingNavigationColor.length > 0 && listBrandingNavigationColor.map((listColour,index) => {
                                if(listColour.colorName !== 'Home icon') {
                                    return (
                                        <div className="navigation-colour-list" key={listColour.colorCode}>
                                            <div className="navigation-colours-contain">
                                                <span className="change-colour-contain">
                                                     <span className='change-colour-nav-header'>{`${listColour.colorName}` + ':'}</span>
                                               <div className="content-change-colour">
                                               <Popover content={renderColorPickerNavHeader} trigger="click"
                                                        visible={colorPickerVisibleNavHeader[listColour.colorCode]}>
                                                  <div
                                                      className={disabled ? 'icon-change-color disabled' : 'icon-change-color'}
                                                      key={`i-${listColour.colorCode}`}
                                                      style={{backgroundColor: listColour.color ? listColour.color : listColour.defaultColor}}
                                                      onClick={(e) => disabled ? undefined : this.toggleColorPickerNavHeaderVisible(e, listColour.colorCode)}>
                                                  </div>
                                                </Popover>
                                                     <span className="name-change-colour">{listColour.color ? listColour.color : listColour.defaultColor}</span>
                                               </div>
                                           </span>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="navigation-colour-list" key={listColour.colorCode}>
                                            <div className="navigation-colours-contain">
                                                <span className="change-colour-contain">
                                                    <span className='change-colour-nav-header'>Home icon:</span>
                                                    <div className="style-upload-home-icon">
                                                        <div className="home-icon-container ">
                                                            <img id="field_picture_home_icon" className="home-icon" src={showPicture ? showPicture : headerLogo } alt="" onChange={(e) => this.handleChangeHomeIcon(e)} />
                                                        </div>
                                                        <div className="upload-home-icon">
                                                            <ImageUploader
                                                                className="file-upload-home-icon"
                                                                withIcon={false}
                                                                label=''
                                                                labelStyle="styleLabelImageUpload"
                                                                buttonText='Upload image'
                                                                buttonClassName="button-upload-image"
                                                                onChange={this.onDrop}
                                                                imgExtension={['.jpg', '.png', '.jpeg']}
                                                                maxFileSize={3145728}
                                                                withPreview={false}
                                                                imgPreview={false}
                                                                singleImage={true}
                                                                errorStyle={{ color: 'blue', fontSize:'20px' }}
                                                                ref={(ref) => this.imageUpload = ref}/>
                                                        </div>
                                                        {/*<span className={'home-icon-size'}>(40x40 pixel)</span>*/}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    <div className='navigation-header-contain'>
                        <span className='navigation-header-title'>Sub Navigation Header</span>
                        {
                            listBrandingSubNavigationColor && listBrandingSubNavigationColor.length > 0 && listBrandingSubNavigationColor.map((listColour,index) => {
                                    return (
                                        <div className="navigation-colour-list" key={listColour.colorCode}>
                                            <div className="navigation-colours-contain">
                                                <span className="change-colour-contain">
                                                     <span className='change-colour-nav-header'>{`${listColour.colorName}` + ':'}</span>
                                               <div className="content-change-colour">
                                               <Popover content={renderColorPickerSubNavHeader} trigger="click"
                                                        visible={colorPickerVisibleSubNavHeader[listColour.colorCode]}>
                                                  <div
                                                      className={disabled ? 'icon-change-color disabled' : 'icon-change-color'}
                                                      key={`i-${listColour.colorCode}`}
                                                      style={{backgroundColor: listColour.color ? listColour.color : listColour.defaultColor}}
                                                      onClick={(e) => disabled ? undefined : this.toggleColorPickerSubNavHeaderVisible(e, listColour.colorCode)}>
                                                  </div>
                                                </Popover>
                                                     <span className="name-change-colour">{listColour.color ? listColour.color : listColour.defaultColor}</span>
                                               </div>
                                           </span>
                                            </div>
                                        </div>
                                    )
                            })
                        }
                    </div>
                </div>

                <div className= "company-logo">
                            <span className='company-logo-header'>Company logo:</span>
                            <div className="style-upload-home-icon">
                                <div className="home-icon-container ">
                                    <img id="field_picture_home_icon" className="home-icon" src={showPictureCompany ? showPictureCompany : new_Smartbox_Logo } alt="" onChange={(e) => this.handleChangeHomeIcon(e)} />
                                </div>
                                <div className="upload-home-icon">
                                    <ImageUploader
                                        className="file-upload-home-icon"
                                        withIcon={false}
                                        label=''
                                        labelStyle="styleLabelImageUpload"
                                        buttonText={ <span className="button-text-upload"><span className="upload-image"> Upload image</span><span className="upload-size">290 x 80 pixel</span></span> }
                                        buttonClassName="button-upload-image"
                                        onChange={this.onDropCompanyLogo}
                                        imgExtension={['.jpg', '.png', '.jpeg']}
                                        maxFileSize={3145728}
                                        withPreview={false}
                                        imgPreview={false}
                                        singleImage={true}
                                        errorStyle={{ color: 'blue', fontSize:'20px' }}
                                        ref={(ref) => this.imageUpload = ref}/>
                                </div>
                                {/*<span className={'home-icon-size'}>(40x40 pixel)</span>*/}
                            </div>
                </div>

                <div className="footer-profile-settings">
                    <Button className="button-discard-changes" onClick={(e) => this.onDiscardChanges(e) }>Discard Changes</Button>
                    <Button className="button-create" disabled={valueInputProfile === ''} onClick={(e) => this.onCreateBrandingProfile(e)}>
                        {this.props.statusProfile === 'create' ? 'Create' : 'Modify'}</Button>
                </div>
            </div>
        )
    }
}

const stateMap = (state) => ({
    user: state.userReducer.user,
    smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,
})

BrandingProfilesCreate.propTypes = {
    user: PropTypes.object.isRequired
}

export default connect(stateMap)(BrandingProfilesCreate)