import React from "react";
import './SourceMappingPage';
import {Table, Modal, Select, Button, Input,Form} from "antd";
import {customConfirm} from "../../utils/AssetRegister/CustomConfirm";
import {
    apiDeleteSourceMapping, apiEditSourceMappingList,
    apiGetConnectorList,
    apiGetSourceMappingFilterOptions,
    apiGetSourceMappingList,
    apiSaveSourceMapping
} from "../../api/SmartBox";
import {connect} from "react-redux";
// const { Column } = Table;
const { Option } = Select;
const { Column } = Table;


class SourceMappingTable extends React.Component {
    state = {
        columns : [
            {
                title: 'What data is hold',
                dataIndex: 'whatDataHold',
                filters:[{
                    text: '',
                    value: '',
                },],
                onFilter: (value, record) => console.log(value,record),
                sorter: (a, b) => console.log(a,b),
                sortDirections: ['descend'],
            },
            {
                title: 'Where is the data hold',
                dataIndex: 'whereDataHold',
                filters:[{
                    text: '',
                    value: '',
                },],
                onFilter: (value, record) => console.log(value,record),
                sorter: (a, b) => a - b,
                sortDirections: ['descend'],
            },
            {
                title: 'How is the data sourced',
                dataIndex: 'howDataSourced',
                sorter: (a, b) => console.log(a,b),
                sortDirections: ['descend'],
            },
            {
                title: 'Why is the data collected',
                dataIndex: 'whyDataCollected',
                sorter: (a, b) => console.log(a,b),
                sortDirections: ['descend'],
            },
            {
                title: 'When is the data disposed',
                dataIndex: 'whenDataDisposed',
                sorter: (a, b) => console.log(a,b),
                sortDirections: ['descend'],
            },
            {
                title: '',
                dataIndex: 'edit',
                render: (text) => <a onClick={()=> {this.displayAddNewConnectorModal()}}>{text}</a>
            },
        ],
        dataTable: [],
        selectedKeysDelete: [],
        listOptionConnectors: [],
        hoverDelete:false,
        hoverAdd:false,
        hoverEdit:{},
        hoverBtnAddDataHeld:false,
        hoverBtnAddConnector:false,
        hoverBtnAddOtherStorage:false,
        hoverDeleteLabelWhatData:{},
        hoverDeleteLabelConnector:{},
        hoverDeleteLabelOtherStorage:{},
        modalVisible: false,
        valueWhatDataIsHold:'',
        valueConnectorSelect:'',
        valueOtherStorage:'',
        valueHowIsTheDataSourced:'',
        valueWhyIsDataCollected:'',
        valueWhenIsDataDisposed:'',
        buttonAddDisabled:true,
        inputsListDataHold:[0],
        formMapping: {
            inputsListDataHold:[0],
            valueWhatDataIsHold: { 0: '' },
            inputsListOtherStorage:[0],
            valueOtherStorage: { 0: '' },
            valueHowIsTheDataSourced: '',
            valueWhyIsDataCollected: '',
            valueWhenIsDataDisposed: '',
            connectorsSelect: [
                {
                    valueConnectorSelect: null
                }
            ]
        },
        //formMappingCopy: {},
        indexDataHold: 0,
        modeModal: 'create',
        pagination: {
            current: 1,
            pageSize: 10,
            order: null,
            field: null,
            filters: {
                dataHoldingInfoList: [],
                whereDataHold: [],
            }
        },
        loadingTable: false,
        // filtersDataHoldingInfo: [{
        //     value: '',
        //     text: ''
        //
        // }],
        filtersDataHoldingInfo: [],
        // filtersDataLocationListOptions: [{
        //     value: '',
        //     text: ''
        //
        // }],
        filtersDataLocationListOptions: [],
        filteredInfo: null,
        filesSelect: [],
        selectedRowKeys: [],
        nonSelectedRowKeys: [],
        checkSelectAll: false,
        statusPendingSaveSource: false,
        isLoadingActionAdd:false,
        isLoadingCancelAction:false,
        isLoadingActionEditAdd:false,
    }
    componentDidMount() {
      this.onGetConnectorList()
      this.getSourceMappingList()
      this.onGetListOptionFilter()
    }

    getSourceMappingList = (pageNr) => {
        const { pagination, filteredInfo, selectedKeysDelete, checkSelectAll, nonSelectedRowKeys, dataTable } = this.state
        let filterByName = null
        filteredInfo && Object.keys(filteredInfo) && Object.keys(filteredInfo).length > 0 && Object.keys(filteredInfo).forEach((item, index) => {
            if (item === 'dataHoldingInfoList' && filteredInfo[item] && filteredInfo[item].length > 0) {
                filterByName = 'dataHoldingInfo'
            } else  if (item === 'whereDataHold' && filteredInfo[item] && filteredInfo[item].length > 0) {
                filterByName = 'dataLocationList'
            }
        })
        const dtoGetMapping = {
            clientId: this.props.clientId,
            pageNumber: pageNr ? pageNr : pagination.current,
            pageRows: pagination.pageSize,
            sortOrder: pagination.order === 'descend' ? 'DESC' : pagination.order === 'ascend' ? 'ASC' : pagination.order,
            sortBy: pagination.field === 'dataHoldingInfoList' ? 'dataHoldingInfo' : pagination.field === 'whereDataHold' ? 'dataLocationList' :
                pagination.field === 'dataSourcingInfo' ? 'dataSourcingInfo' :
                    pagination.field === 'dataCollectionReason' ? 'dataCollectionReason' :
                        pagination.field === 'dataDisposalTime' ? 'dataDisposalTime' : pagination.field,
            filtersOptions: filteredInfo && filteredInfo.dataHoldingInfoList && filteredInfo.dataHoldingInfoList.length > 0 ? filteredInfo.dataHoldingInfoList :
                           filteredInfo && filteredInfo.whereDataHold && filteredInfo.whereDataHold.length > 0 ? filteredInfo.whereDataHold : null,
            filterBy: filterByName
        }
        this.setState({
            loadingTable: true,
            filteredInfo: null,
            //selectedRowKeys: !checkSelectAll && []
        }, () => {
            apiGetSourceMappingList(dtoGetMapping).then((response) => {
                if (response.status === 200) {
                    let tableResponse = []
                    let selectedRowKeysUpdate = []
                    tableResponse = response.data.sourceMappingDTOS
                    tableResponse && tableResponse.length > 0 && tableResponse.forEach((item, index) => {
                        const connectorList = []
                        const connectorDataList = []
                        if (!checkSelectAll) {
                            if (selectedKeysDelete.includes(item.sourceMappingId)) {
                                selectedRowKeysUpdate.push(index)
                            }
                            this.setState({
                                selectedRowKeys: selectedRowKeysUpdate
                            })
                        } else {
                            if (!nonSelectedRowKeys.includes(item.sourceMappingId)) {
                                selectedRowKeysUpdate.push(index)
                            }
                            this.setState({
                                selectedRowKeys: selectedRowKeysUpdate
                            })
                        }
                        item.connectorList &&  item.connectorList.length > 0 &&  item.connectorList.forEach((item2) => {
                            connectorList.push(item2.connectorProvider)
                        })
                        item.dataLocationList &&  item.dataLocationList.length > 0 && item.dataLocationList.forEach((item2) => {
                            connectorDataList.push(item2.text)
                        })
                        item.whereDataHold = connectorList.concat(connectorDataList)
                        item.key = index
                    })
                    const pagination = { ...this.state.pagination }
                    pagination.total = response.data.totalRows
                    this.setState({
                        dataTable: response.data.sourceMappingDTOS ? response.data.sourceMappingDTOS : [],
                        pagination,
                        loadingTable: false
                    }, () => {
                        this.onGetKeysSelected()
                    })
                }
            })
        })
    }
    onGetKeysSelected = () => {
        const { checkSelectAll, dataTable, nonSelectedRowKeys } = this.state
        const selectedKeys = []
        if (checkSelectAll) {
            dataTable && dataTable.length > 0 && dataTable.forEach((item, index) => {
                if (nonSelectedRowKeys && nonSelectedRowKeys.length > 0) {
                    if(!nonSelectedRowKeys.includes(item.sourceMappingId)) {
                        //selectedKeys.push(`rowKey${item.sourceMappingId}`)
                        selectedKeys.push(index)
                    }
                } else {
                    selectedKeys.push(index)
                }
            })
            this.setState({
                selectedRowKeys: selectedKeys
            })
        }
    }
    onGetListOptionFilter = () => {
        apiGetSourceMappingFilterOptions().then((response) => {
            const listDataHoldingInfo = []
            const listDataLocationListOptions = []
            response.data.dataHoldingInfo && response.data.dataHoldingInfo.length > 0 && response.data.dataHoldingInfo.forEach((item) => {
                listDataHoldingInfo.push({
                    text: item,
                    value: item
                })
            })
            response.data.dataLocationListOptions && response.data.dataLocationListOptions.length > 0 && response.data.dataLocationListOptions.forEach((item) => {
                listDataLocationListOptions.push({
                    text: item,
                    value: item
                })
            })
           this.setState({
               filtersDataHoldingInfo: listDataHoldingInfo,
               filtersDataLocationListOptions: listDataLocationListOptions,
           })
        })
    }
    onGetConnectorList = () => {
        const getConnectorsRequestDTO = {
            clientId: this.props.clientId,
            pageNumber: 1,
            pageRows: 99999,
        }
        apiGetConnectorList(getConnectorsRequestDTO).then((response) => {
            this.setState({
                listOptionConnectors: response.data.connectorList ? response.data.connectorList : []
            })
        })
    }
    onSelectRow = (selectedRowKeys, filesSelect) => {
        const { dataTable, checkSelectAll, nonSelectedRowKeys } = this.state
        const selectIds = []
        const selectIdsNon = []
        filesSelect && filesSelect.length > 0 && filesSelect.forEach((item) => {
              selectIds.push(item.sourceMappingId)
        })
        //let filesSelectedGlobal = this.state.selectedKeysDelete.concat(selectIds)
        let filesSelectedGlobal = [...new Set(this.state.selectedKeysDelete.concat(selectIds))]
        if (checkSelectAll) {
            dataTable && dataTable.length > 0 && dataTable.forEach((item) => {
                if(!selectIds.includes(item.sourceMappingId)) {
                    selectIdsNon.push(item.sourceMappingId)
                }
            })
            let filesSelectedGlobalNon = [...new Set(this.state.nonSelectedRowKeys.concat(selectIdsNon))]
            this.setState({
                selectedKeysDelete: [],
                nonSelectedRowKeys: filesSelectedGlobalNon,
            })
        } else {
            dataTable && dataTable.length > 0 && dataTable.forEach((item) => {
                if(!selectIds.includes(item.sourceMappingId)) {
                    selectIdsNon.push(item.sourceMappingId)
                }
            })
            let filesSelectedGlobalNon = [...new Set(this.state.nonSelectedRowKeys.concat(selectIdsNon))]
            filesSelectedGlobal = filesSelectedGlobal.filter(function(item) {
                return !filesSelectedGlobalNon.includes(item);
            })
            this.setState({
                selectedKeysDelete: filesSelectedGlobal,
                nonSelectedRowKeys: [],
            })
        }
        this.setState({
            selectedRowKeys,
            filesSelect: filesSelect,
        })
    }

    onHoverDeleteButton = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveDeleteButton = () => {
        this.setState({
            hoverDelete:false
        })
    }
    onHoverAddButton = () => {
        this.setState({
            hoverAdd: true
        })
    }
    onLeaveAddButton = () => {
        this.setState({
            hoverAdd:false
        })
    }
    onHoverBtnAddDataHeld = () => {
        this.setState({
            hoverBtnAddDataHeld:true
        })
    }
    onLeaveBtnAddDataHeld = () => {
        this.setState({
            hoverBtnAddDataHeld:false
        })
    }
    onHoverBtnAddConnector = () => {
        this.setState({
            hoverBtnAddConnector: true
        })
    }
    onLeaveBtnAddConnector = () => {
        this.setState({
            hoverBtnAddConnector:false
        })
    }
    onHoverBtnAddOtherStorage = () => {
        this.setState({
            hoverBtnAddOtherStorage: true
        })
    }
    onLeaveBtnAddOtherStorage = () => {
        this.setState({
            hoverBtnAddOtherStorage:false
        })
    }
    showConfirmDeleteSelectedRow = () => {
        const { selectedKeysDelete, checkSelectAll } = this.state
        const contentStatus = checkSelectAll ? 'Delete all elements?' : selectedKeysDelete && selectedKeysDelete.length > 1 ? 'Delete elements?' : 'Delete element?'
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `${contentStatus}`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className:'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.removeSelectedRow()
            }
        })
    }
    removeSelectedRow = () => {
      const { selectedKeysDelete, dataTable, checkSelectAll, nonSelectedRowKeys, pagination } = this.state
        const dataTableDelete = []
        // dataTable && dataTable.length > 0 && dataTable.forEach((item) => {
        //     if (!selectedKeysDelete.includes(item.key)) {
        //         dataTableDelete.push(item)
        //     }
        // })
        // this.setState({
        //     dataTable: dataTableDelete
        // })
        const deleteLastPage = []
        dataTable && dataTable.length > 0 && dataTable.forEach((item) => {
            if (selectedKeysDelete.includes(item.sourceMappingId)) {
                deleteLastPage.push(item.sourceMappingId)
            }

        })
        const deleteSourceMappingDTO = {
            ids: checkSelectAll ? null : selectedKeysDelete,
            deleteAll: checkSelectAll,
            excludedIds: nonSelectedRowKeys && nonSelectedRowKeys.length > 0 ? nonSelectedRowKeys : null
        }
        let pageNr = null
        apiDeleteSourceMapping(deleteSourceMappingDTO).then((response) => {
            if (response.status === 200) {
                if ((dataTable && dataTable.length === 1) || (deleteLastPage.length === dataTable.length)) {
                    pageNr = pagination.current === 1 ? 1 : pagination.current - 1
                    this.setState({
                        pagination: {
                            ...this.state.pagination,
                            current: pagination.current === 1 ? 1 : pagination.current - 1
                        }
                    })
                }
                this.setState({
                    selectedRowKeys: [],
                    selectedKeysDelete: [],
                    filesSelect: [],
                })
                this.getSourceMappingList(pageNr)
                this.onGetListOptionFilter()
            }
        })
    }
    displayAddNewConnectorModal = (e, mode, record) => {
        const { formMapping } = this.state
        this.setState({
            modalVisible: true,
            modeModal: mode,
            statusPendingSaveSource: false,
            isLoadingCancelAction:false,
            isLoadingActionEditAdd:false,
        })
        if (mode === 'create') {
            this.setState({
                formMapping: {
                    inputsListDataHold:[0],
                    valueWhatDataIsHold: { 0: '' },
                    inputsListOtherStorage:[0],
                    valueOtherStorage: { 0: '' },
                    valueHowIsTheDataSourced: '',
                    valueWhyIsDataCollected: '',
                    valueWhenIsDataDisposed: '',
                    connectorsSelect: [
                        {
                            valueConnectorSelect: null
                        }
                    ]
                }
            })
        } else {
            const inputsDataHoldEdit = []
            const valuesDataHoldEdit = {}
            const dataLocationListEdit = []
            const valuesLocationListEdit = {}
            record.dataHoldingInfoList && record.dataHoldingInfoList.length > 0 && record.dataHoldingInfoList.forEach((item, index) => {
                inputsDataHoldEdit.push(0)
                valuesDataHoldEdit[index] = item
            })
            record.dataLocationList && record.dataLocationList.length > 0 && record.dataLocationList.forEach((item, index) => {
                dataLocationListEdit.push(0)
                valuesLocationListEdit[index] = item
            })
            record.connectorList && record.connectorList.length > 0 && record.connectorList.forEach((item, index) => {
                item.valueConnectorSelect = item.connectorId
            })
            // this.setState({
            //     formMapping: {
            //         inputsListDataHold:[0],
            //         valueWhatDataIsHold: { 0: '' },
            //         inputsListOtherStorage:[0],
            //         valueOtherStorage: { 0: '' },
            //         valueHowIsTheDataSourced: '',
            //         valueWhyIsDataCollected: '',
            //         valueWhenIsDataDisposed: '',
            //         connectorsSelect: [
            //             {
            //                 valueConnectorSelect: null
            //             }
            //         ]
            //     },
            // })
            this.setState({
                formMapping: {
                    inputsListDataHold: inputsDataHoldEdit && inputsDataHoldEdit.length > 0 ? inputsDataHoldEdit : [0],
                    valueWhatDataIsHold: Object.keys(valuesDataHoldEdit) && Object.keys(valuesDataHoldEdit).length > 0 ? valuesDataHoldEdit : { 0: record.whatDataHold },
                    inputsListOtherStorage: dataLocationListEdit && dataLocationListEdit.length > 0 ? dataLocationListEdit : [0],
                    valueOtherStorage: Object.keys(valuesLocationListEdit) && Object.keys(valuesLocationListEdit).length > 0 ? valuesLocationListEdit : { 0: '' },
                    valueHowIsTheDataSourced: record.dataSourcingInfo,
                    valueWhyIsDataCollected: record.dataCollectionReason,
                    valueWhenIsDataDisposed: record.dataDisposalTime,
                    key: record.key,
                    sourceMappingId: record.sourceMappingId,
                    connectorsSelect: record.connectorList.length === 0 ? [
                                {
                                    valueConnectorSelect: null
                                }
                            ] : record.connectorList
                },
                formMappingCopy: {
                    inputsListDataHold: inputsDataHoldEdit && inputsDataHoldEdit.length > 0 ? inputsDataHoldEdit : [0],
                    valueWhatDataIsHold: Object.keys(valuesDataHoldEdit) && Object.keys(valuesDataHoldEdit).length > 0 ? valuesDataHoldEdit : { 0: record.whatDataHold },
                    inputsListOtherStorage: dataLocationListEdit && dataLocationListEdit.length > 0 ? dataLocationListEdit : [0],
                    valueOtherStorage: Object.keys(valuesLocationListEdit) && Object.keys(valuesLocationListEdit).length > 0 ? valuesLocationListEdit : { 0: '' },
                    valueHowIsTheDataSourced: record.dataSourcingInfo,
                    valueWhyIsDataCollected: record.dataCollectionReason,
                    valueWhenIsDataDisposed: record.dataDisposalTime,
                    key: record.key,
                    sourceMappingId: record.sourceMappingId,
                    connectorsSelect: record.connectorList
                }
            })
        }
    }
    cancelVisibleNewConnectorModal = (e) => {
        const { modeModal, formMappingCopy } = this.state
        if (modeModal === 'create') {
                this.setState({
                    formMapping: {
                        inputsListDataHold:[0],
                        valueWhatDataIsHold: { 0: '' },
                        inputsListOtherStorage:[0],
                        valueOtherStorage: { 0: '' },
                        valueHowIsTheDataSourced: '',
                        valueWhyIsDataCollected: '',
                        valueWhenIsDataDisposed: '',
                        connectorsSelect: [
                            {
                                valueConnectorSelect: null
                            }
                        ],
                    },
                    valueWhatDataIsHold: "",
                    valueOtherStorage: "",

                })
        } else if (modeModal === 'edit') {
            this.setState({
                formMapping: formMappingCopy
            }, () => {
                this.getSourceMappingList()
            })
        }
        this.setState({
            modalVisible: false,
            isLoadingCancelAction:true,
        })

    }
    onChangeInput = (e, arg, index) => {
        const { formMapping } = this.state
        switch (arg) {
            case 'whatData':
                let { value } =  e.target
                let copyState = formMapping.valueWhatDataIsHold
                if (copyState[index] && copyState[index].text) {
                    copyState[index].text = value
                } else {
                    copyState[index] = {
                        id: null,
                        text: value
                    }
                }
                this.setState({
                    valueWhatDataIsHold: e.target.value,
                    formMapping: {...this.state.formMapping, valueWhatDataIsHold: copyState}
                })
                break
            case 'otherStorage':
                let copyStateOther = formMapping.valueOtherStorage
                if (copyStateOther[index] && copyStateOther[index].text) {
                    copyStateOther[index].text =  e.target.value
                } else {
                    copyStateOther[index] = {
                        id: null,
                        text: e.target.value
                    }
                }
                this.setState({valueOtherStorage: e.target.value,
                    formMapping: {...this.state.formMapping, valueOtherStorage: copyStateOther}
                })
                break
            case 'dataSourced':
                this.setState({valueHowIsTheDataSourced:e.target.value,
                    formMapping: {...this.state.formMapping, valueHowIsTheDataSourced: e.target.value }
                })
                break
            case 'dataCollected':
                this.setState({valueWhyIsDataCollected: e.target.value,
                    formMapping: {...this.state.formMapping, valueWhyIsDataCollected: e.target.value }
                })
                break
            case 'dataDisposed':
                this.setState({valueWhenIsDataDisposed:e.target.value,
                    formMapping: {...this.state.formMapping, valueWhenIsDataDisposed: e.target.value }
                })
                break
            default:
                break
        }
    }
    onChangeSelectConnector = (e, position, key) => {
        const { listOptionConnectors } = this.state
        const settings = this.state.formMapping
            const { connectorsSelect } = settings
        listOptionConnectors && listOptionConnectors.length > 0 && listOptionConnectors.forEach((item2) => {
            if (e === item2.connectorId) {
                connectorsSelect[key].nameConnect = item2.connectorProvider
                connectorsSelect[key].connectionOwner = item2.connectionOwner
                connectorsSelect[key].connectionType = item2.connectionType
            }
        })
        connectorsSelect[key].valueConnectorSelect = e
        connectorsSelect[key].id = key
        // connectorsSelect.push({
        //     valueConnectorSelect: e
        // })
            this.setState({
                formMapping: {
                    ...settings,
                    connectorsSelect
                },
                valueConnectorSelect: e
            })
    }
    addDisabled = (e) => {
        e.preventDefault()
    }
    addNewInputDataHold = (e, arg, index) => {
        const { formMapping, indexDataHold } = this.state
        if(arg === 'INCREMENT') {
            //if ((formMapping.valueWhatDataIsHold[0].text && formMapping.inputsListDataHold.length === Object.keys(formMapping.valueWhatDataIsHold).length) || (formMapping.inputsListDataHold.length === Object.keys(formMapping.valueWhatDataIsHold).length + 1)) {
                this.setState({
                    inputsListDataHold: [...this.state.inputsListDataHold, 0],
                    formMapping: {
                        ...this.state.formMapping,
                        inputsListDataHold: [...this.state.formMapping.inputsListDataHold, 0]
                    },
                    indexDataHold,
                    hoverBtnAddDataHeld:false,
                })
            // }
        } else {
            let removedLast = this.state.formMapping.inputsListDataHold
            let copyState = formMapping.valueWhatDataIsHold
            delete copyState[index]
            if(removedLast.length !==1 ){
                const removedUpdate = []
                 delete removedLast[index]
                const filtersList = removedLast.filter((item, index2) => index2 !== index)
                removedLast && removedLast.length > 0 && removedLast.map((item2, index2) => {
                    if (index2 !== index) {
                        removedUpdate.push(item2)
                    }
                })
                let copyStateUpdate = {}
                Object.keys(copyState) && Object.keys(copyState).length > 0 && Object.keys(copyState).map((elem, index3) => {
                    copyStateUpdate[index3] = copyState[elem]
                    copyState[index3] = copyState[elem]
                })
                this.setState({
                    formMapping: {...this.state.formMapping, inputsListDataHold: removedUpdate, valueWhatDataIsHold: copyStateUpdate}
                })
            }
        }
    }

    addNewInputOtherStorage = (e, arg, index) => {
        const { formMapping } = this.state
        if(arg === 'INCREMENT') {
            if (formMapping.valueOtherStorage[0].text) {
                this.setState({
                    formMapping: {
                        ...this.state.formMapping,
                        inputsListOtherStorage: [...this.state.formMapping.inputsListOtherStorage, 0]
                    },
                    hoverBtnAddOtherStorage:false,
                })
            }
        } else {
            let removedLast = this.state.formMapping.inputsListOtherStorage
            let copyState = formMapping.valueOtherStorage
            delete copyState[index]
            if(removedLast.length !==1 ){
                const removedUpdate = []
                delete removedLast[index]
                removedLast && removedLast.length > 0 && removedLast.map((item2, index2) => {
                    if (index2 !== index) {
                        removedUpdate.push(item2)
                    }
                })
                let copyStateUpdate = {}
                Object.keys(copyState) && Object.keys(copyState).length > 0 && Object.keys(copyState).map((elem, index3) => {
                    copyStateUpdate[index3] = copyState[elem]
                    copyState[index3] = copyState[elem]
                })
                this.setState({
                    formMapping: {...this.state.formMapping, inputsListOtherStorage: removedUpdate, valueOtherStorage: copyStateUpdate}
                })
            }
        }
    }

    addNewSelectConnector = (e, arg, index) => {
        if(arg === 'INCREMENT') {
            const settings = this.state.formMapping
            const { connectorsSelect } = settings
            if (connectorsSelect[0].valueConnectorSelect) {
                connectorsSelect.push({
                    valueConnectorSelect: null
                })
                this.setState({
                    formMapping: {
                        ...settings,
                        connectorsSelect
                    },
                    hoverBtnAddConnector:false,
                })
            }
         } else {
            const settings = this.state.formMapping
            let { connectorsSelect } = settings
            connectorsSelect = connectorsSelect.filter((sPermission, key) => key !== index)
            this.setState({
                formMapping: {
                    ...settings,
                    connectorsSelect
                },
            })
        }
    }
    handleTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            filteredInfo: null
        }, () => {
            const pager = { ...this.state.pagination }
            pager.current = pagination.current
            pager.pageSize = pagination.pageSize
            if (sorter.order === 'descend') {
                pager.order = sorter.order
            } else {
                pager.order = 'ascend'
            }
            pager.field = sorter.field
            this.setState({
                pagination: pager,
                filteredInfo: filters
            }, () => {
                this.getSourceMappingList()
            })
        })
    }
    onCreateSourceMapping = () => {
        const { formMapping } = this.state
        const dataHoldingInfoList = []
        const dataLocationList = []
        const connectorIds = []
        const dataTableAdd = []
        Object.keys(formMapping.valueWhatDataIsHold) &&  Object.keys(formMapping.valueWhatDataIsHold).length > 0 &&  Object.keys(formMapping.valueWhatDataIsHold).forEach((item) => {
            dataHoldingInfoList.push(formMapping.valueWhatDataIsHold[item])
        })
        Object.keys(formMapping.valueOtherStorage) &&  Object.keys(formMapping.valueOtherStorage).length > 0 &&  Object.keys(formMapping.valueOtherStorage).forEach((item) => {
            dataLocationList.push(formMapping.valueOtherStorage[item])
        })
        formMapping.connectorsSelect && formMapping.connectorsSelect.length > 0 && formMapping.connectorsSelect.forEach((item) => {
            connectorIds.push(item.valueConnectorSelect)
        })
        dataTableAdd.push(formMapping)
        const dtoConnectInfo = {
            dataHoldingInfoList: dataHoldingInfoList && dataHoldingInfoList.length > 0 && dataHoldingInfoList[0] !== '' ? dataHoldingInfoList : null,
            connectorIds: connectorIds && connectorIds.length > 0 && connectorIds[0] !== null ? connectorIds : null,
            dataLocationList: dataLocationList && dataLocationList.length > 0 && dataLocationList[0] !== '' ? dataLocationList : null,
            dataSourcingInfo: formMapping.valueHowIsTheDataSourced ? formMapping.valueHowIsTheDataSourced : null,
            dataCollectionReason: formMapping.valueWhyIsDataCollected ? formMapping.valueWhyIsDataCollected : null,
            dataDisposalTime: formMapping.valueWhenIsDataDisposed ? formMapping.valueWhenIsDataDisposed : null,
        }
        this.setState({
            statusPendingSaveSource: true,
            isLoadingActionAdd:true,
        }, () => {
            apiSaveSourceMapping(dtoConnectInfo).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        statusPendingSaveSource: false,
                        isLoadingActionAdd:false,
                    })
                }
                this.getSourceMappingList()
                this.onGetListOptionFilter()
                this.setState({
                    //dataTable: dataTableAdd,
                    modalVisible: false,
                    valueWhatDataIsHold: "",
                    valueOtherStorage: "",
                })
            })
        })
    }
    onEditSourceMapping = () => {
        const { formMapping, dataTable } = this.state
        this.setState({
            modalVisible: false,
            isLoadingActionEditAdd:true,
        })
        dataTable && dataTable.length > 0 && dataTable.forEach((item) => {
           if (item.key === formMapping.key) {
              item.whatDataHold = formMapping.valueWhatDataIsHold["0"]
              item.whenDataDisposed = formMapping.valueWhenIsDataDisposed
              item.whyDataCollected = formMapping.valueWhyIsDataCollected
              item.howDataSourced = formMapping.valueHowIsTheDataSourced
           }
        })
        // this.setState({
        //     dataTable
        // })
        const dataHoldingInfoList = []
        let dataLocationList = []
        const connectorIds = []
        Object.keys(formMapping.valueWhatDataIsHold) &&  Object.keys(formMapping.valueWhatDataIsHold).length > 0 &&  Object.keys(formMapping.valueWhatDataIsHold).forEach((item) => {
            dataHoldingInfoList.push(formMapping.valueWhatDataIsHold[item])
        })
        Object.keys(formMapping.valueOtherStorage) &&  Object.keys(formMapping.valueOtherStorage).length > 0 &&  Object.keys(formMapping.valueOtherStorage).forEach((item) => {
            if (formMapping.valueOtherStorage[item]) {
                dataLocationList.push(formMapping.valueOtherStorage[item])
            } else {
                dataLocationList = []
            }
        })
        formMapping.connectorsSelect && formMapping.connectorsSelect.length > 0 && formMapping.connectorsSelect.forEach((item) => {
            connectorIds.push(item.valueConnectorSelect)
        })
        const dtoConnectInfo = {
            sourceMappingId: formMapping.sourceMappingId,
            dataHoldingInfoList: dataHoldingInfoList,
            connectorIds: connectorIds,
            dataLocationList: dataLocationList,
            dataSourcingInfo: formMapping.valueHowIsTheDataSourced,
            dataCollectionReason: formMapping.valueWhyIsDataCollected,
            dataDisposalTime: formMapping.valueWhenIsDataDisposed,
        }
        apiEditSourceMappingList(dtoConnectInfo).then(() => {
            this.getSourceMappingList()
            this.onGetListOptionFilter()
            this.setState({
                valueWhatDataIsHold: "",
                valueOtherStorage: "",
            })
        })
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        this.setState({
            checkSelectAll: selected,
            nonSelectedRowKeys: [],
            selectedKeysDelete: [],
        })
    }
    onHoverEditButton = (item) => {
        this.setState({
            hoverEdit:item,
        })
    }
    onLeaveEditButton = () => {
        this.setState({
            hoverEdit:{},
        })
    }
    onHoverDeleteLabelWhatData = (index) => {
        this.setState({
            hoverDeleteLabelWhatData:index,
        })
    }
    onLeaveDeleteLabelWhatData = () => {
        this.setState({
            hoverDeleteLabelWhatData:{},
        })
    }
    onHoverDeleteLabelConnector = (index) => {
        this.setState({
            hoverDeleteLabelConnector:index,
        })
    }
    onLeaveDeleteLabelConnector = () => {
        this.setState({
            hoverDeleteLabelConnector:{},
        })
    }
    onHoverDeleteLabelOtherStorage = (index) => {
        this.setState({
            hoverDeleteLabelOtherStorage:index,
        })
    }
    onLeaveDeleteLabelOtherStorage = () => {
        this.setState({
            hoverDeleteLabelOtherStorage:{},
        })
    }

    render (){
        const { hoverAdd, hoverDelete, hoverEdit, hoverBtnAddDataHeld, hoverBtnAddConnector, hoverBtnAddOtherStorage, hoverDeleteLabelWhatData, hoverDeleteLabelConnector, hoverDeleteLabelOtherStorage, modalVisible, dataTable, formMapping, modeModal, loadingTable, filtersDataHoldingInfo, filtersDataLocationListOptions,
            selectedRowKeys, pagination, statusPendingSaveSource, isLoadingActionAdd, isLoadingCancelAction, isLoadingActionEditAdd, selectedKeysDelete, filesSelect,
            checkSelectAll, nonSelectedRowKeys } = this.state
        let { listOptionConnectors, filteredInfo, valueWhatDataIsHold } = this.state

        const { client } = this.props

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectRow,
            onSelectAll: this.onSelectAll
        }
        filteredInfo = filteredInfo || {}
        formMapping.connectorsSelect && formMapping.connectorsSelect.length > 0 && formMapping.connectorsSelect.forEach((form) => {
            listOptionConnectors = listOptionConnectors && listOptionConnectors.length > 0 ? listOptionConnectors.filter((list) => list.connectorId !== form.valueConnectorSelect) : ''
        })
        listOptionConnectors = listOptionConnectors && listOptionConnectors.length > 0 ? listOptionConnectors.sort(function (a,b) {
            return (a.connectorName.toLowerCase().localeCompare(b.connectorName.toLowerCase()) || b.connectorProvider - a.connectorProvider)}) : ""
        return (
          <div className='source-mapping-table'>
              <div className="source-mapping-table__content">
                  <div className="topbar">
                      <div className="btn-container">
                          <button className={hoverAdd ? "icon-v17-add-active":"icon-v17-add"}
                                  onMouseEnter={(e) => this.onHoverAddButton(e)}
                                  onMouseLeave={() => this.onLeaveAddButton()}
                                  onClick={(e) => this.displayAddNewConnectorModal(e, 'create')}>
                          </button>
                          <button className={ hoverDelete ? "icon-v17-delete-active" : "icon-v17-delete"}
                                  onMouseEnter={(e) => this.onHoverDeleteButton(e)}
                                  onMouseLeave={() => this.onLeaveDeleteButton()}
                                  onClick={() => this.showConfirmDeleteSelectedRow()}
                                  disabled={ ( selectedKeysDelete.length === 0 && checkSelectAll === false) || dataTable.length === 0}>
                          </button>

                      </div>
                  </div>
                  <div className="table-source-mapping-content">
                      <Table
                        rowSelection={ rowSelection }
                         //rowKey={ selectedRowKeys }
                        //rowKey = { (record) => { return `rowKey${ record.sourceMappingId }` }}
                        rowKey = { (record, index) =>  index }
                        pagination={this.state.pagination}
                        dataSource={ dataTable }
                        //columns={this.state.columns}
                          onChange={this.handleTableChange}
                        loading={ loadingTable }
                        className="ant-table-filter-dropdown-source"
                      >
                          <Column className="column-error-report"
                                  title = 'What data is held'
                                  dataIndex="dataHoldingInfoList" key="dataHoldingInfoList"
                                  filters = { filtersDataHoldingInfo }
                                  //onFilter = { (value, record) => console.log() }
                                  sorter = {(a, b) => console.log()}
                                  sortDirections = {['descend']}
                                  //filteredValue = { filteredInfo.dataHoldingInfoList || null }
                                  render={(text, record) => {
                                      return (
                                          <span className="text-error-report text-provider text-mapping">{
                                              text && text.length > 0 && text.map((item,i) => {
                                              return (
                                                  <span key={i}>{ `${item.text} ` }</span>
                                              )
                                          })
                                          }</span>
                                      )
                                  }} />
                          <Column className="column-error-report"
                                  title = 'Where is the data held'
                                  dataIndex="whereDataHold" key="whereDataHold"
                                  filters = { filtersDataLocationListOptions }
                                  //onFilter = { (value, record) => console.log() }
                                  //filteredValue = { filteredInfo.whereDataHold || null }
                                  sorter = {(a, b) => console.log()}
                                  sortDirections = {['descend']}
                                  render={(text, record) => {
                                      return (
                                          <span className="text-error-report text-provider text-mapping">
                                              {  text && text.length > 0 && text.map((item,i) => {
                                              return (
                                                  <span key={i}>{ `${item} ` }</span>
                                              )
                                          }) }</span>
                                      )
                                  }} />
                          <Column className="column-error-report"
                                  title = 'How is the data sourced'
                                  dataIndex="dataSourcingInfo" key="dataSourcingInfo"
                                  sorter = {(a, b) => console.log()}
                                  sortDirections = {['descend']}
                                  render={(text, record) => {
                                      return (
                                          <span className="text-error-report text-provider text-mapping-update">{ text }</span>
                                      )
                                  }} />
                          <Column className="column-error-report"
                                  title = 'Why is the data collected'
                                  dataIndex="dataCollectionReason" key="dataCollectionReason"
                                  sorter = {(a, b) => console.log()}
                                  sortDirections = {['descend']}
                                  render={(text, record) => {
                                      return (
                                          <span className="text-error-report text-provider text-mapping-update">{ text }</span>
                                      )
                                  }} />
                          <Column className="column-error-report"
                                  title = 'When is the data disposed'
                                  dataIndex="dataDisposalTime" key="dataDisposalTime"
                                  sorter = {(a, b) => console.log()}
                                  sortDirections = {['descend']}
                                  render={(text, record) => {
                                      return (
                                          <span className="text-error-report text-provider text-mapping-update">{ text }</span>
                                      )
                                  }} />
                          <Column className="column-error-report"
                                  title = ''
                                  dataIndex="edit" key="edit"
                                  render={(text, record) => {
                                      return (
                                          <span onClick={(e)=> {this.displayAddNewConnectorModal(e, 'edit', record)}}
                                                onMouseEnter={() => {this.onHoverEditButton(record)}}
                                                onMouseLeave={() => {this.onLeaveEditButton()}}
                                                className={hoverEdit && hoverEdit.sourceMappingId === record.sourceMappingId ? "text-error-report text-provider icon-v21-edit-active" : "text-error-report text-provider icon-v21-edit" }>{ text }</span>
                                      )
                                  }} />
                      </Table>
                  </div>
              </div>
              <Modal className="source-mapping-modal" title={<div className={"header-content-mapping"}><span>Mapping</span></div>} visible={ modalVisible} footer={null} closable={ false }>
                  <div className="source-mapping-modal-text-field">
                      <div className="title-text-field">What data is held *</div>
                      { formMapping && formMapping.inputsListDataHold && formMapping.inputsListDataHold.length > 0 && formMapping.inputsListDataHold.map((item, index) => {
                          return (
                              <div className="content-inputs-data-hold" key={index}>
                              <Input className="input-what-data-hold" onChange={(e) => this.onChangeInput(e, 'whatData', index)}
                                      value={ formMapping.valueWhatDataIsHold[index] && formMapping.valueWhatDataIsHold[index].text ? formMapping.valueWhatDataIsHold[index].text :  '' }></Input>
                                  { formMapping.inputsListDataHold.length > 1 &&
                                      <button onClick={(e) => this.addNewInputDataHold(e, 'DECREMENT', index)}
                                              className={ hoverDeleteLabelWhatData === index ? 'icon-v16-minus-active' : 'icon-v16-minus'}
                                              onMouseEnter={() => this.onHoverDeleteLabelWhatData(index)}
                                              onMouseLeave={() => this.onLeaveDeleteLabelWhatData()}
                                      />
                                  }
                              </div>
                          )
                      })
                      }
                  </div>
                  <div className="wrapper-btn-add">
                      { ((formMapping.valueWhatDataIsHold && formMapping.valueWhatDataIsHold[Object.keys(formMapping.valueWhatDataIsHold).length - 1] && formMapping.valueWhatDataIsHold[Object.keys(formMapping.valueWhatDataIsHold).length - 1].text && formMapping.inputsListDataHold.length === Object.keys(formMapping.valueWhatDataIsHold).length)) ?
                          <button  className={ hoverBtnAddDataHeld ? 'icon-v17-add-active' :'icon-v17-add'}
                                   onClick={(e) => this.addNewInputDataHold(e, 'INCREMENT')}
                                   onMouseEnter={() => this.onHoverBtnAddDataHeld()}
                                   onMouseLeave={() =>this.onLeaveBtnAddDataHeld()}></button> :
                          <button  className="icon-v17-add" onClick={(e) => this.addDisabled(e, 'INCREMENT')}></button>
                      }
                  </div>

                  <div className="source-mapping-modal-text-field">
                      <div className="title-text-field with-subtitle">Where is the data held *</div>
                      <div className="subtitle-text-field">Connector</div>
                      { formMapping && formMapping.connectorsSelect && formMapping.connectorsSelect.length > 0 && formMapping.connectorsSelect.map((item, index) => {
                          return (
                              <div className="content-inputs-data-hold" key={index}>
                              <Select className=''
                                      placeholder="Select"
                                      dropdownStyle={{border:'1px solid #eda41e'}}
                                      getPopupContainer={trigger => trigger.parentNode}
                                      value={item.nameConnect ? item.nameConnect : item.connectorProvider ? item.connectorProvider : 'Select'}
                                      onChange={(e) => this.onChangeSelectConnector(e, 'valueConnectorSelect', index)}>
                                  {listOptionConnectors && listOptionConnectors.length > 0 && listOptionConnectors.map((item, index) => {
                                      return (<Option className="options-select-connector" value={item.connectorId} key={index}>
                                          {`${item.connectorProvider} - ${item.connectorName} - ${item.connectionStatus}`}
                                      </Option>)
                                  })}
                              </Select>
                                  { formMapping.connectorsSelect.length > 1 &&
                                  <button onClick={(e) => this.addNewSelectConnector(e, 'DECREMENT', index)}
                                          className={ hoverDeleteLabelConnector === index ? 'icon-v16-minus-active' : 'icon-v16-minus'}
                                          onMouseEnter={() => this.onHoverDeleteLabelConnector(index)}
                                          onMouseLeave={() => this.onLeaveDeleteLabelConnector()}
                                  />
                                  }
                              </div>
                          )
                      })

                      }
                      <div className="wrapper-btn-add wrapper-btn-add-update">
                          { formMapping.connectorsSelect && formMapping.connectorsSelect[formMapping.connectorsSelect.length - 1] && formMapping.connectorsSelect[formMapping.connectorsSelect.length - 1].valueConnectorSelect ?
                              <button onClick={(e) => this.addNewSelectConnector(e, 'INCREMENT')}
                                      className={ hoverBtnAddConnector ? "icon-v17-add-active" :"icon-v17-add"}
                                      onMouseEnter={() => this.onHoverBtnAddConnector()}
                                      onMouseLeave={() => this.onLeaveBtnAddConnector()}></button> :
                              <button onClick={(e) => this.addDisabled(e, 'INCREMENT')} className="icon-v17-add"></button>
                          }
                      </div>

                      <div className="subtitle-text-field">Other storage /location</div>
                      { formMapping && formMapping.inputsListOtherStorage && formMapping.inputsListOtherStorage.length > 0 && formMapping.inputsListOtherStorage.map((item, index) => {
                          return (
                              <div className="content-inputs-data-hold" key={index}>
                              <Input className="text-field text-field-update" onChange={(e) => this.onChangeInput(e, 'otherStorage', index)}
                                     value={formMapping.valueOtherStorage[index] && formMapping.valueOtherStorage[index].text ? formMapping.valueOtherStorage[index].text : ''}></Input>
                                  { formMapping.inputsListOtherStorage.length > 1 &&
                                  <button onClick={(e) => this.addNewInputOtherStorage(e, 'DECREMENT', index)}
                                          className={ hoverDeleteLabelOtherStorage === index ? 'icon-v16-minus-active' : 'icon-v16-minus'}
                                          onMouseEnter={() => this.onHoverDeleteLabelOtherStorage(index)}
                                          onMouseLeave={() => this.onLeaveDeleteLabelOtherStorage()}
                                  />
                                  }
                              </div>
                          )
                      })
                      }
                      <div className="wrapper-btn-add">
                          { ((formMapping.valueOtherStorage && formMapping.valueOtherStorage[Object.keys(formMapping.valueOtherStorage).length - 1] && formMapping.valueOtherStorage[Object.keys(formMapping.valueOtherStorage).length - 1].text && formMapping.inputsListOtherStorage.length === Object.keys(formMapping.valueOtherStorage).length)) ?
                          <button onClick={(e) => this.addNewInputOtherStorage(e, 'INCREMENT')}
                                  className={ hoverBtnAddOtherStorage ? "icon-v17-add-active" : "icon-v17-add"}
                                  onMouseEnter={() => this.onHoverBtnAddOtherStorage()}
                                  onMouseLeave={() => this.onLeaveBtnAddOtherStorage()}></button> :
                          <button onClick={(e) => this.addDisabled(e, 'INCREMENT')}  className="icon-v17-add"></button>
                          }
                      </div>
                  </div>

                  <div className="source-mapping-modal-text-field">
                      <div className="title-text-field">How is the data sourced </div>
                      <Input className="text-field" onChange={(e) => this.onChangeInput(e,'dataSourced')}
                             value={ formMapping.valueHowIsTheDataSourced ? formMapping.valueHowIsTheDataSourced : '' }></Input>
                  </div>

                  <div className="source-mapping-modal-text-field">
                      <div className="title-text-field">Why is the data collected</div>
                      <Input className="text-field" onChange={(e) => this.onChangeInput(e,'dataCollected')}
                             value={formMapping.valueWhyIsDataCollected ? formMapping.valueWhyIsDataCollected : ''}></Input>
                  </div>

                  <div className="source-mapping-modal-text-field">
                      <div className="title-text-field">When is the data disposed</div>
                      <Input className="text-field" onChange={(e) => this.onChangeInput(e,'dataDisposed')}
                             value={ formMapping.valueWhenIsDataDisposed ? formMapping.valueWhenIsDataDisposed : '' }></Input>
                  </div>

                  <div className="footer-source-mapping-modal">
                      <Button className={ isLoadingCancelAction ? "action-cancel-loading" :"action-cancel"} onClick={(e) => this.cancelVisibleNewConnectorModal(e)} loading={isLoadingCancelAction}>Cancel</Button>
                      { modeModal === 'create' ?
                          <Button  type="primary" className={ isLoadingActionAdd ?  "action-add-loading" : "action-add"}
                                   loading={isLoadingActionAdd}
                          onClick={(e) => this.onCreateSourceMapping(e)}
                          disabled={statusPendingSaveSource === true || (this.state.valueWhatDataIsHold === '' || ( Object.keys(formMapping.valueWhatDataIsHold ).length === 0 || formMapping.valueWhatDataIsHold[0] && formMapping.valueWhatDataIsHold[0].valueWhatDataIsHold === ''))
                              || (((formMapping.connectorsSelect[0] && formMapping.connectorsSelect[0].valueConnectorSelect === null) && (Object.keys(formMapping.valueOtherStorage).length === 0 || formMapping.valueOtherStorage[0] === '' || this.state.valueOtherStorage === '')))}
                          >Add</Button>  :
                          <Button  type="primary" className={isLoadingActionEditAdd ? "action-add-loading" : "action-add"}
                                   loading={isLoadingActionEditAdd}
                                   // disabled={((formMapping.valueWhatDataIsHold[0] && formMapping.valueWhatDataIsHold[0].text === '' && formMapping.valueWhatDataIsHold[0].text === undefined))
                                   // || (((formMapping.connectorsSelect[0] && formMapping.connectorsSelect[0].valueConnectorSelect === null)))}
                                   disabled={(Object.keys(formMapping.valueWhatDataIsHold ).length === 0 || ((formMapping.valueWhatDataIsHold[0] && formMapping.valueWhatDataIsHold[0].text === '') || (formMapping.valueWhatDataIsHold[0] && formMapping.valueWhatDataIsHold[0].text === undefined))
                                   || (((formMapping.connectorsSelect[0] && formMapping.connectorsSelect[0].valueConnectorSelect === null) && (Object.keys(formMapping.valueOtherStorage).length === 0 || formMapping.valueOtherStorage[0]  === '' ||   formMapping.valueOtherStorage[0].text === '' && this.state.valueOtherStorage === ''))))}

                          onClick={(e) => this.onEditSourceMapping(e)}>Add</Button>
                      }
                  </div>
              </Modal>
              <Form.Item/>
          </div>
        )

    }

}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    client: state.subscriptionReducer.subscription.subscription.client,
})
export default connect(mapStateToProps, {
})(SourceMappingTable)
