import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import './DisclosureWorkflow.scss'
import PackingDisclosure from './ComponentsDisclosure/PackingDisclosure'
import SendDisclosure from './ComponentsDisclosure/SendDisclosure'
import DeliveredDisclosure from './ComponentsDisclosure/DeliveredDisclosure'
import {Select, Steps } from "antd";
import {apiGetDisclosureStepsDetails} from "../../../../../api/SmartBox";
import cookie from "react-cookies";
const { Option } = Select;
const { Step } = Steps;
export class DisclosureWorkflow extends Component {

    state = {
        current: 0,
        statusPacking: 'wait',
        statusSend: 'wait',
        statusDelivered: 'wait',
        packingDisclosure: {},
        packingStepCompleted: false,
        sendEmailDisclosureStepCompleted: false,
        deliveredPageStepCompleted: false,
        loadButtonClicked: false
    }
    componentDidMount() {
        const { current } = this.state
        if (current === 0) {
            this.setState({
                statusPacking: 'process'
            })
        }
        this.onGetDisclosureStepsDetails()
    }
    onGetDisclosureStepsDetails = () => {
        const { selectEditTickets, stepTicketActive } = this.props
        const ticketId = selectEditTickets.ticketId
        const workflowComponentId = stepTicketActive.stepComponentWorkflowId
        apiGetDisclosureStepsDetails(ticketId).then((response) => {
             if (response.data) {
                 this.setState({
                     packingStepCompleted: response.data.packingStepCompleted,
                     sendEmailDisclosureStepCompleted: response.data.sendEmailDisclosureStepCompleted,
                     deliveredPageStepCompleted: response.data.deliveredPageStepCompleted,
                 })
                 if (response.data.packingStepCompleted) {
                     this.setState({
                         statusPacking: 'finish'
                     })
                 }
                 if (response.data.sendEmailDisclosureStepCompleted) {
                     this.setState({
                         statusSend: 'finish',
                     })
                     cookie.remove('emailDisclosureEmailTrackingSteps', { path: '/' })
                 }
                 if (response.data.deliveredPageStepCompleted) {
                     this.setState({
                         statusDelivered: 'finish'
                     })
                 }
                 if (!response.data.packingStepCompleted) {
                     this.setState({
                         current: 0
                     })
                 } else if (!response.data.sendEmailDisclosureStepCompleted) {
                     this.setState({
                         current: 1,
                         loadButtonClicked: true
                     })
                 } else if (!response.data.deliveredPageStepCompleted) {
                     this.setState({
                         current: 2
                     })
                 } else {
                     this.setState({
                         current: 2
                     })
                 }
             }
        })
    }
    onChange = (current) => {
        const { statusPacking, statusSend, statusDelivered} = this.state
         switch (current) {
             case 0:
                 this.setState({
                     statusPacking: statusPacking !== 'finish' ? 'process' : statusPacking,
                     statusSend: statusSend !== 'finish' ? 'wait' : statusSend,
                     statusDelivered: statusDelivered !== 'finish' ? 'wait' : statusDelivered,
                 })
                 break
             case 1:
                 this.setState({
                     statusSend: statusSend !== 'finish' ? 'process' : statusSend,
                     statusDelivered: statusDelivered !== 'finish' ? 'wait' : statusDelivered,
                     statusPacking: statusPacking !== 'finish' ? 'wait' : statusPacking,
                 })
                 break
             case 2:
                 this.setState({
                     statusDelivered: statusDelivered !== 'finish' ? 'process' : statusDelivered,
                     statusSend: statusSend,
                     statusPacking: statusPacking !== 'finish' ? 'wait' : statusPacking,
                 })
                 break
             default: break
         }
        this.setState({ current })
    }
    onCancelDisclosure = () => {
        this.props.onDisplayHeaderStep(false, '')
    }
    generateStatusStepDisclosure = (step, current) => {
        const { statusPacking, statusSend, statusDelivered, packingDisclosure} = this.state
        switch (step) {
            case 'packing':
                return statusPacking
                break
            case 'send':
                return statusSend
                break
            case 'delivered':
                return statusDelivered
                break
            default: return 'wait'
        }
        // if (step) {
        //     if (step === 'packing') {
        //         return statusPacking
        //     }
        //     if (step === 'send') {
        //         return statusSend
        //     }
        //     if (step === 'delivered') {
        //         return statusDelivered
        //     }
        //     return 'wait'
        // }
        return 'wait'
    }
    onEndPackingDisclosure = (status) => {
       if (status === 'packing') {
          this.setState({
              statusPacking: 'finish',
              current: 1,
              loadButtonClicked: true
          })
       }
    }
    onGetDisclosureFirstStepAndFiles = (response) => {
       this.setState({
           packingDisclosure: response
       })
        if (response && response.packingCompleted) {
            this.setState({
                statusPacking: 'finish',
            })
        }
    }

    onGetDisclosureEmailTrackingSteps = (response) => {
        if (response && response.length > 0) {
            response.forEach((item) => {
                if (item && item.emailStepName && item.emailStepName === 'Subject ended request') {
                   this.setState({
                       statusSend: 'finish'
                   })
                }
            })
        }
    }
    render() {
        const { current, packingStepCompleted, sendEmailDisclosureStepCompleted, deliveredPageStepCompleted ,loadButtonClicked,
            statusPacking, statusSend, statusDelivered} = this.state
        const stepStyle = {
            marginBottom: 60,
            boxShadow: '0px -1px 0 0 #e8e8e8 inset',
        }
        return (
            <div className="disclosure-workflow-page">
                <div className="disclosure-workflow-page__header">
                    {/*<span className="icon-v22-back-1" onClick={(e) => this.onCancelDisclosure(e)}></span>*/}
                  <span className="name-disclosure">Disclosure</span>
                </div>
                <div className="disclosure-workflow-page__steps">
                    <Steps className="steps-disclosure" type="navigation" current={current} onChange={this.onChange} style={stepStyle}>
                        <Step status={this.generateStatusStepDisclosure('packing', current)} title="Packing" />
                        <Step disabled={packingStepCompleted === false} status={this.generateStatusStepDisclosure('send', current)} title="Send" />
                        <Step disabled={ sendEmailDisclosureStepCompleted === false } status={this.generateStatusStepDisclosure('delivered', current)} title="Delivered" />
                    </Steps>
                </div>
                <div className="disclosure-workflow-page__components">
                    { current === 0 &&
                        <PackingDisclosure selectEditTickets={this.props.selectEditTickets}
                                           stepHeaderActive={ this.props.stepHeaderActive}
                                           loadButtonClicked = {this.state.loadButtonClicked}
                                           onEndPackingDisclosure={ this.onEndPackingDisclosure }
                                           onGetDisclosureFirstStepAndFiles={this.onGetDisclosureFirstStepAndFiles}
                                           onGetDisclosureStepsDetails={this.onGetDisclosureStepsDetails}
                        />
                    }
                    { current === 1 &&
                    <SendDisclosure
                        selectEditTickets={this.props.selectEditTickets}
                        stepHeaderActive={this.props.stepHeaderActive}
                        selectHeaderTickets={this.props.selectHeaderTickets}
                        onGetDisclosureEmailTrackingSteps={ this.onGetDisclosureEmailTrackingSteps }
                        onGetDisclosureStepsDetails={ this.onGetDisclosureStepsDetails }
                        statusDelivered={ statusDelivered }
                        statusSend={ statusSend }
                    />
                    }
                    { current === 2 &&
                    <DeliveredDisclosure selectEditTickets={this.props.selectEditTickets}
                                         stepHeaderActive={this.props.stepHeaderActive}
                                         selectHeaderTickets={this.props.selectHeaderTickets}
                                         onGetTicketWorkflowAndHeaderDetails={ this.props.onGetTicketWorkflowAndHeaderDetails }
                                         onGetDisclosureStepsDetails={ this.onGetDisclosureStepsDetails }/>
                    }
                </div>
            </div>
        )
    }
}


DisclosureWorkflow.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(DisclosureWorkflow)