import React from "react";
import {connect} from "react-redux";
import {Input, Checkbox, Select} from "antd";
import {apiUserFetchUsersListMinimized} from "../../../../api/User";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {apiGetDefaultEmailTemplatesWorkflow, apiGetEmail} from "../../../../api/Sar";
const { Option } = Select

class InternalWorkflowBuilderAdmin extends React.Component {
    state = {
        formOptions: {
            valueSelect: null,
            internalSelect: [
                {
                    valueInternalSelect: null
                }
            ],
            internalSelectUserIds: [],
            componentName: null,
            idComponent: null,
            userSelect: null,
            emailSelectInternal: null,
            emailSelectInternalName: null,
        },
        hoverDelete: false,
        hoverAddInternal: false,
        hoverRemoveInternal: {},
        listOptionsInternal: [
            {name: 'DPO team'},
            {name: 'Sam Smith'},
        ],
        usersList: [],
        listEmails: [],
    }
    componentDidMount() {
        this.fetchUsersList()
        //this.onGetEmailsTemplate()
    }
    onGetInternalMount = () => {
        const {usersList, listEmails} = this.state
        const {component} = this.props
        const internalSelectUpdate = []
        let getIdComponent = null
        if (component.userIds && component.userIds.length > 0) {
            usersList && usersList.length > 0 && usersList.forEach((user1) => {
                component.userIds && component.userIds.length > 0 && component.userIds.forEach((user2) => {
                    if (user1.value === user2) {
                        internalSelectUpdate.push({
                            valueInternalSelect: user2,
                            nameSelectUser: user1.name,
                        })
                    }
                })
            })
            let nameEmail = null
            listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
                if (list.emailTemplateId === component.idComponent) {
                    nameEmail = list.templateName
                }
            })
            if (component.idComponent) {
                getIdComponent = component.idComponent
            }
            this.setState({
                formOptions: {...this.state.formOptions, internalSelect: internalSelectUpdate, emailSelectInternal: this.props.component.idComponent, componentName: nameEmail,
                    idComponent: getIdComponent, internalSelectUserIds: component.userIds }
            }, () => {
                this.props.onChangeBuilder(this.state.formOptions, this.props.component)
                if (component.idComponent) {
                    this.props.onSelectIdInternalEmail(component.idComponent)
                } else if (component.emailSelectInternal) {
                    this.props.onSelectIdInternalEmail(component.emailSelectInternal)
                }
            })
        }
    }
    fetchUsersList() {
        apiUserFetchUsersListMinimized()
            .then((response) => {
                this.setState({
                    usersList: response.data,
                }, () => {
                    this.onGetEmailsTemplate()
                })
            }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    onGetEmailsTemplate = () => {
        apiGetDefaultEmailTemplatesWorkflow().then((response) => {
            const listEmailsFilter = []
            response.data && response.data.length > 0 && response.data.forEach((email) => {
                if (email && email.emailTemplateType === 'General emails') {
                    listEmailsFilter.push(email)
                }
            })
            this.setState({
                listEmails: listEmailsFilter
            }, () => {
                this.onGetInternalMount()
            })
        })
    }
    onHoverActionComponent = (e, action, index) => {
        switch (action) {
            case 'delete':
                this.setState({
                    hoverDelete: true
                })
                break
            case 'addInternal':
                this.setState({
                    hoverAddInternal: true
                })
                break
            case 'removeInternal':
                this.setState({
                    hoverRemoveInternal: index
                })
                break
            default: break
        }
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false,
            hoverAddInternal: false,
            hoverRemoveInternal: {},
        })
    }
    addNewSelectInternal = (e, arg, index) => {
        if (arg === 'INCREMENT') {
            const settings = this.state.formOptions
            const settings2 = this.state.formOptions
            const { internalSelect } = settings
            const { internalSelectUserIds } = settings2
            internalSelect.push({
                valueInternalSelect: null
            })
            internalSelectUserIds.push(null)
            this.setState({
                formOptions: {
                    ...settings,
                    internalSelect,
                    internalSelectUserIds
                },
                hoverAddInternal: false,
            }, () => {
                this.props.onChangeBuilder(this.state.formOptions, this.props.component)
            })
        } else {
            const settings = this.state.formOptions
            const settings2 = this.state.formOptions
            let { internalSelect } = settings
            let { internalSelectUserIds } = settings2
            internalSelect = internalSelect.filter((sPermission, key) => key !== index)
            internalSelectUserIds = internalSelectUserIds.filter((sPermission, key) => key !== index)
            this.setState({
                formOptions: {
                    ...settings,
                    internalSelect,
                    internalSelectUserIds
                },
            }, () => {
                this.props.onChangeBuilder(this.state.formOptions, this.props.component)
            })
        }
    }
    onChangeSelectorInternal = (e, position, key) => {
        const { usersList } = this.state
        const settings = this.state.formOptions
        const settings2 = this.state.formOptions
        const { internalSelect } = settings
        const { internalSelectUserIds } = settings2
        const selectUserId = []
        selectUserId.push(e)
        if (usersList && usersList.length > 0) {
            usersList.forEach((user) => {
                if (user.value === e) {
                    internalSelect[key].nameSelectUser = user.name
                }
            })
        }
        internalSelect[key].valueInternalSelect = e
        internalSelectUserIds[key] = e
        //internalSelectUserIds.push(e)
        this.setState({
            formOptions: {
                ...settings,
                internalSelect,
                internalSelectUserIds
            },
        }, () => {
            this.props.onChangeBuilder(this.state.formOptions, this.props.component)
        })
    }
    onChangeSelectorInternalEmail = (e) => {
        const { listEmails } = this.state
        let nameEmail = null
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.emailTemplateId === e) {
                nameEmail = list.templateName
            }
        })
        this.setState({
            formOptions: {...this.state.formOptions, emailSelectInternal: e, idComponent: e, componentName: nameEmail}
        }, () => {
            this.props.onChangeBuilder(this.state.formOptions, this.props.component)
            this.props.onSelectIdInternalEmail(e)
        })
    }
    render() {
        const { hoverDelete, hoverAddInternal, formOptions, hoverRemoveInternal, listOptionsInternal, listEmails } = this.state
        let { usersList } = this.state
        const { component, onStateProvidedDraggable } = this.props
        formOptions && formOptions.internalSelect && formOptions.internalSelect.length > 0 && formOptions.internalSelect.forEach((user2) => {
            usersList = usersList && usersList.length > 0 ? usersList.filter((user) => user.value !== user2.valueInternalSelect) : ''
        })
        return (
            <div className="component-form component-form-internal">
                <div className="component-content component-content-new">
                    <div className="header-component">
                        <span className="header-title header-title-collector">Internal Email Notification</span>
                    </div>
                    <div className="select-content select-content-internal">
                        <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                              className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                              onMouseEnter={ (e) => this.onHoverActionComponent(e, 'delete')}
                              onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <div className="content-internal">
                            <div className="contain-internal-email">
                                <Select className="select-email"
                                        dropdownClassName={'select-form-dropdown'}
                                        onChange={(e) => this.onChangeSelectorInternalEmail(e)}
                                        value={formOptions.emailSelectInternal ? formOptions.emailSelectInternal : 'Select email template'}
                                        placeholder="Select group">
                                    { listEmails && listEmails.length > 0 && listEmails.map((item, index) => {
                                        return (<Option className="options-select-connector" value={item.emailTemplateId} key={item.emailTemplateId}>
                                            {item.templateName}
                                        </Option>)
                                    })}
                                </Select>
                            </div>
                            {formOptions && formOptions.internalSelect && formOptions.internalSelect.length > 0 && formOptions.internalSelect.map((item, index) => {
                                return (
                                    <div className="contain-internal" key={index}>
                                        <Select className="select-form select-form-update"
                                                dropdownClassName={'select-form-dropdown'}
                                                onChange={(e) => this.onChangeSelectorInternal(e, 'valueSelect', index)}
                                                value={item.nameSelectUser ? item.nameSelectUser : 'Select recipient'}
                                                placeholder="Select user">
                                            { usersList && usersList.length > 0 && usersList.map((item, index) => {
                                                return (<Option className="options-select-connector" value={item.value} key={item.value}>
                                                    {item.name}
                                                </Option>)
                                            })}
                                        </Select>
                                        { index === 0 &&
                                        <button
                                            key={index}
                                            className={hoverAddInternal ? 'icon-v17-add-active' : "icon-v17-add"}
                                            onClick={(e) => this.addNewSelectInternal(e, 'INCREMENT')}
                                            onMouseEnter={(e) => this.onHoverActionComponent(e, 'addInternal')}
                                            onMouseLeave={() => this.onLeaveActionComponent()}></button>
                                        }
                                        { index !== 0 &&
                                        <button
                                            key={index}
                                            className={ hoverRemoveInternal === index ? 'icon-v16-minus-active' : "icon-v16-minus"}
                                            onClick={(e) => this.addNewSelectInternal(e, 'DECREMENT', index)}
                                            onMouseEnter={(e) => this.onHoverActionComponent(e, 'removeInternal', index)}
                                            onMouseLeave={() => this.onLeaveActionComponent()}></button>
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(InternalWorkflowBuilderAdmin)