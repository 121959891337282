import React from "react";
import './TemplateWorkflow.scss';
import {connect} from "react-redux";
import {Button, Checkbox, Empty, Input, Modal} from "antd";
import {
    apiCreateNewDuplicateWorkflowTemplate,
    apiGetWorkflowBuilder, apiWorkflowBuilderDelete,
    apiWorkflowTemplateSimpleCreate, apiWorkflowTemplateSimpleEdit
} from "../../../../api/SmartBox";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import WorkflowBuilderUi from './WorkflowBuilderUi'

class TemplateWorkflow extends React.Component {
     state = {
         listWorkflowTemplate: [],
         selectedWorkflow: null,
         visibleModal: false,
         formModal: {
             name: '',
         },
         modalAction: 'create',
         hoverEditBtn: false,
         hoverCopyBtn: false,
         hoverDeleteBtn:false,
         hoverAddBtn:false,
         visibleWorkflowBuilderUi: false,
         selectWorkflowTemplate: {},
         hoverOpenWorkflowUi: {},
         disableAddWorkflowTemplate: false,
         selectedWorkflowList: [],
         atLeastOneDefaultSelected:false,
         atLeastOneNormalSelected:false,
         selectedWorkflowListItems: []
     }
    componentDidMount() {
        this.onGetWorkflowTemplate()
    }

    onGetWorkflowTemplate = () => {
        apiGetWorkflowBuilder(this.props.clientId).then((response) => {
            this.setState({
                listWorkflowTemplate: response.data
            })
        })
    }
    onActionEdit = () => {
        const { selectedWorkflow, listWorkflowTemplate, selectedWorkflowList } = this.state
        if(selectedWorkflowList.length === 0) return
        const activeList = listWorkflowTemplate.find(item => item.workflowId === selectedWorkflowList[0])
        listWorkflowTemplate && listWorkflowTemplate.length > 0 && listWorkflowTemplate.forEach((item) => {
            if (activeList !== null && activeList.workflowId === item.workflowId) {
                this.setState({
                    formModal: {
                        name: item.workflowName,
                        id: item.workflowId
                    }
                })
            }
        })
        this.setState({
            modalAction: 'edit',
            visibleModal: true,
        })
    }

    onActionCopy = () => {
        const { selectedWorkflow, listWorkflowTemplate, selectedWorkflowList } = this.state
        let selectWorkflowId = null
        listWorkflowTemplate && listWorkflowTemplate.length > 0 && listWorkflowTemplate.forEach((item) => {
            if (selectedWorkflow !== null && selectedWorkflow.workflowId === item.workflowId) {
                selectWorkflowId = item.workflowId
            }
        })
        if(selectedWorkflowList && selectedWorkflowList.length > 0 ){
            let  selectedWorkflowForDuplicate = []
            selectedWorkflowForDuplicate.push(selectedWorkflow.workflowId)
            const createDuplicateWorkflowDTO = {
                workflowIds: selectedWorkflowList,
                clientId: this.props.clientId,
            }
            apiCreateNewDuplicateWorkflowTemplate(createDuplicateWorkflowDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.onGetWorkflowTemplate()
            })
        }

    }

    showConfirmDeleteWorkflow = (e) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete workflow template?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onDeleteWorkflowTemplate(e)
            }
        })
    }
    onDeleteWorkflowTemplate = () => {
        const { selectedWorkflow, selectedWorkflowList } = this.state
        let {atLeastOneDefaultSelected, atLeastOneNormalSelected, selectedWorkflowListItems} = this.state

        if(selectedWorkflowList && selectedWorkflowList.length > 0){
            // let  selectedWorkflowForDelete = []
            // selectedWorkflowForDelete.push(selectedWorkflow.workflowId)
            apiWorkflowBuilderDelete(selectedWorkflowList).then((response) => {
                if(response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                    selectedWorkflowListItems = [];
                } else if(response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }

                atLeastOneDefaultSelected = false;
                atLeastOneNormalSelected = false;

                    this.setState({
                    selectedWorkflow: null,
                    selectedWorkflowList: [],
                        atLeastOneNormalSelected: atLeastOneNormalSelected,
                        atLeastOneDefaultSelected: atLeastOneDefaultSelected,
                        selectedWorkflowListItems: selectedWorkflowListItems
                }, () => {
                    this.onGetWorkflowTemplate()
                })
            })
        }

    }
    onClickVisibleModal = () => {
      this.setState({
          visibleModal: true,
          formModal: {
              name: '',
          },
          modalAction: 'create'
      })
    }
    addOrRemoveSelectedLists = (e, item) => {
        e.stopPropagation()
        let { selectedWorkflow, selectedWorkflowList, atLeastOneDefaultSelected, selectedWorkflowListItems, atLeastOneNormalSelected} = this.state

        if(e.target.checked) {
            selectedWorkflow = item;
            selectedWorkflowList.push(item.workflowId)
            selectedWorkflowListItems.push(item);

            atLeastOneDefaultSelected = false;
            atLeastOneNormalSelected = false;

            for (let i = 0; i < selectedWorkflowListItems.length; i++) {
                if (selectedWorkflowListItems[i].defaultTemplate)
                    atLeastOneDefaultSelected = true;
                else
                    atLeastOneNormalSelected = true;
            }
        }else{
            selectedWorkflowList = selectedWorkflowList.filter(item2 => item2 !== item.workflowId)
                selectedWorkflowListItems = selectedWorkflowListItems.filter(item2 => item2.workflowId!= item.workflowId)
            selectedWorkflow = null;

            atLeastOneDefaultSelected = false;
            atLeastOneNormalSelected = false;

            for (let i = 0; i < selectedWorkflowListItems.length; i++) {
                if (selectedWorkflowListItems[i].defaultTemplate)
                    atLeastOneDefaultSelected = true;
                else
                    atLeastOneNormalSelected = true;
            }
        }

        this.setState({selectedWorkflow, selectedWorkflowList, atLeastOneDefaultSelected, atLeastOneNormalSelected, selectedWorkflowListItems})

    }
    handleCancel = () => {
        this.setState({
            visibleModal: false,
            formModal: {...this.state.formModal, name: '', id: null }
        })
    }
    updateValue = (e, field) => {
        if (field === 'name') {
            this.setState({
                formModal: {...this.state.formModal, name: e.target.value }
            })
        }
    }
    onCreateWorkflowTemplate = () => {
         const { formModal } = this.state
         const createWorkflowTemplateSimpleRequestDTO = {
             clientId: this.props.clientId,
             workflowName: formModal.name
         }
         const selectWorkflowTemplate = {
             defaultTemplate: false,
             duplicatedTemplate: false,
             workflowId: null,
             workflowName: formModal.name,
         }
        this.setState({
             //disableAddWorkflowTemplate: true,
            visibleWorkflowBuilderUi : true,
            selectWorkflowTemplate
         }, () => {
            this.setState({
                visibleModal: false
            })
             // apiWorkflowTemplateSimpleCreate(createWorkflowTemplateSimpleRequestDTO).then((response) => {
             //     if (response.status === 200) {
             //         if (response.data.title.toLowerCase() === 'success') {
             //             CustomNotificationManager.success(response.data.message, 'Success')
             //         } else if (response.data.title.toLowerCase() === 'info') {
             //             CustomNotificationManager.info(response.data.message, 'Info')
             //         }
             //         this.setState({
             //             visibleModal: false,
             //             formModal: {...this.state.formModal, name: '', id: null},
             //             disableAddWorkflowTemplate: false
             //         })
             //         this.onGetWorkflowTemplate()
             //     }
             // })
         })
    }
    onEditWorkflowTemplate = () => {
        const { selectedWorkflow, formModal } = this.state
        const editWorkflowTemplateSimpleRequestDTO  = {
            clientId: this.props.clientId,
            workflowName: formModal.name ? formModal.name : null,
            idWorkflow: formModal.id ? formModal.id : null,
        }
        this.setState({
            disableAddWorkflowTemplate: true
        }, () => {
            apiWorkflowTemplateSimpleEdit(editWorkflowTemplateSimpleRequestDTO).then((response) => {
                if (response.status === 200) {
                    if (response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if (response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    this.setState({
                        visibleModal: false,
                        disableAddWorkflowTemplate: false,
                        selectedWorkflow: null,
                        formModal: {...this.state.formModal, name: '', id: null},
                        // modalAction: 'create',
                    })
                    this.onGetWorkflowTemplate()
                }
            })
        })
    }
    onHoverAction = (e, status, item) => {
         switch (status) {
             case 'edit':
                 this.setState({
                     hoverEditBtn: true
             })
                 break
             case 'delete':
                 this.setState({
                     hoverDeleteBtn: true
             })
                 break
             case 'add' :
                 this.setState({
                     hoverAddBtn: true
                 })
                 break
             case 'copy':
                 this.setState({
                     hoverCopyBtn: true
                 })
                 break
             case 'open' :
                 this.setState({
                     hoverOpenWorkflowUi: item
                 })
                 break
             default: break
         }
    }
    onLeaveAction = () => {
         this.setState({
             hoverEditBtn:false,
             hoverDeleteBtn:false,
             hoverAddBtn:false,
             hoverCopyBtn:false,
             hoverOpenWorkflowUi: {},
         })
    }
    onOpenWorkflowBuilderUi = (e, template) => {
      this.setState({
          visibleWorkflowBuilderUi: true,
          selectWorkflowTemplate: template
      })
    }
    onBackWorkflowBuilderUi = (e, template) => {
        this.setState({
            visibleWorkflowBuilderUi: false,
            selectWorkflowTemplate: {}
        }, () => {
            this.onGetWorkflowTemplate()
        })
    }
    render() {
         const { listWorkflowTemplate, selectedWorkflow, visibleModal, formModal, modalAction, hoverEditBtn, hoverDeleteBtn, hoverAddBtn,
             visibleWorkflowBuilderUi, selectWorkflowTemplate, hoverOpenWorkflowUi, disableAddWorkflowTemplate, hoverCopyBtn,
             selectedWorkflowList, atLeastOneDefaultSelected, atLeastOneNormalSelected, selectedWorkflowListItems} = this.state
        return (
            <div className="workflow-template-page">
                { visibleWorkflowBuilderUi ?

                    <WorkflowBuilderUi onBackWorkflowBuilderUi={ this.onBackWorkflowBuilderUi } selectWorkflowTemplate={ selectWorkflowTemplate }
                                       onGetWorkflowTemplate={this.onGetWorkflowTemplate}/> :

                <div className="workflow-template-page__content">
                    <div className="workflow-template-header">
                        <span className="title-header">Workflow templates</span>
                    </div>
                    <div className="content-workflow-template-page">
                        <div className="content-buttons-actions">
                            <button className={ hoverCopyBtn ? "icon-v17-copy-active action-edit" : "icon-v17-copy action-edit"}
                                    onClick={(e) => this.onActionCopy(e)}
                                    disabled={ selectedWorkflowList.length == 0 }
                                    onMouseEnter={(e)=> this.onHoverAction(e, 'copy')}
                                    onMouseLeave={() => this.onLeaveAction()}>

                            </button>
                            <button onClick={ (e) => this.onActionEdit(e) }
                                    className={ hoverEditBtn ? "icon-v17-edit-active action-edit" : "icon-v17-edit action-edit"}
                                    disabled={ selectedWorkflowList.length == 0 || selectedWorkflowList.length > 1 }
                                    disabled={ atLeastOneDefaultSelected === true || selectedWorkflowListItems.length >=2  ||selectedWorkflow === null && !atLeastOneNormalSelected}
                                    onMouseEnter={(e)=> this.onHoverAction(e, 'edit')}
                                    onMouseLeave={() => this.onLeaveAction()}>
                            </button>
                            <button
                                    disabled={selectedWorkflowList.length === 0}
                                    disabled={ !atLeastOneNormalSelected }
                                onClick={(e) => this.showConfirmDeleteWorkflow(e)}
                                    className={ hoverDeleteBtn ? "icon-v17-delete-active action-delete": "icon-v17-delete action-delete"}
                                    onMouseEnter={(e) => this.onHoverAction(e,'delete')}
                                    onMouseLeave={() => this.onLeaveAction()}>
                            </button>
                            <button onClick={(e) => this.onClickVisibleModal(e)}
                                    className={ hoverAddBtn ? "icon-v17-add-active" : "icon-v17-add"}
                                    onMouseEnter={(e) => this.onHoverAction(e, 'add')}
                                    onMouseLeave={() => this.onLeaveAction()}>
                            </button>
                        </div>
                        <div className="content-table-workflow-template">
                            { listWorkflowTemplate && listWorkflowTemplate.length > 0 ?
                                <div className="list-process">
                                    { listWorkflowTemplate.map((item, index) => {
                                        return (
                                            <div key={index} className="row-list-process">
                                        <span key={index} className="content-name">
                                            <Checkbox
                                                //checked={selectedWorkflow !== null && selectedWorkflow.workflowId === item.workflowId ? true : false}
                                                checked={selectedWorkflowList.includes(item.workflowId) ? true : false}
                                                onChange={(e) => this.addOrRemoveSelectedLists(e, item)}></Checkbox>
                                            <span onClick={(e) => this.onOpenWorkflowBuilderUi(e, item)}
                                                  onMouseEnter={(e) => this.onHoverAction(e,'open', item)}
                                                  onMouseLeave={() => this.onLeaveAction()}
                                                  className={hoverOpenWorkflowUi && hoverOpenWorkflowUi.workflowName === item.workflowName ? 'name-first name-first-active' : "name-first" }>{item.workflowName}</span>
                                            {/*<span className="name-last">{item.workflowName}</span>*/}
                                        </span>
                                                <i onClick={(e) => this.onOpenWorkflowBuilderUi(e, item)} className="icon-v14-arrow-bold"
                                                   onMouseEnter={(e) => this.onHoverAction(e,'open', item)}
                                                   onMouseLeave={() => this.onLeaveAction()}></i>
                                            </div>
                                        )
                                    })

                                    }
                                </div>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </div>
                    </div>
                </div>

                }
                <Modal className="new-workflow-modal" title={ <span className="content-header-modal"><span>
                    { modalAction === 'create' ? 'Add workflow template' : 'Edit workflow template' }</span></span> }
                       visible={visibleModal}
                       footer={ null } closable={ false }>
                    <div className="new-process-section">
                        <form className="new-process-form">
                            <div className="process-modal-line">
                                <Input value={formModal.name ? formModal.name : ''}
                                       onChange={(e) => this.updateValue(e, 'name')}
                                       placeholder={'Name'}
                                />
                            </div>
                            <div className="content-buttons-modal">
                                <Button className="action-cancel" onClick={(e) => this.handleCancel(e)}>Cancel</Button>
                                { modalAction === 'create' ?
                                    <Button type="primary" className="action-save"
                                            onClick={(e) => this.onCreateWorkflowTemplate(e)}
                                            disabled={formModal.name === '' || disableAddWorkflowTemplate === true}>Create</Button> :
                                    <Button type="primary" className="action-save"
                                            onClick={(e) => this.onEditWorkflowTemplate(e)}
                                            disabled={formModal.name === '' || disableAddWorkflowTemplate === true}>Edit</Button>
                                }
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default connect(mapStateToProps, {
})(TemplateWorkflow)