import React from 'react'
import './ErrorReport.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {Select, Tooltip, Input, Table} from "antd";
import {
    apiCheckExportErrorReportList, apiGetBoxErrorReportDeleteStatus,
    apiSmartErrorsReport,
    deleteSelectedFilesError, downloadFilesError, getErrorReportListFileLocation,
    sendToReanalyseWithAI
} from "../../../../api/SmartBox";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import {setSmartReprocessingFileDelete} from "../../../../actions/smartBoxActions";
import FileDownload from 'js-file-download'
import cookie from "react-cookies";
const Option = Select
const { Search } = Input
const { Column } = Table
const isLocalhost = () => {
    return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
import moment from "moment-timezone";
class ErrorReport extends React.Component {
    state = {
        totalRecords: 0,
        pageLimitDisplay: 20,
        isPrevDisabled: true,
        isNextDisabled: false,
        currentPage: 1,
        inputPage: '',
        filesPerPageLimit: 20,
        tableErrorReport: [],
        selectedRowKeys: [],
        valueInputSearch: '',
        filesSelectRows: [],
        checkSearchError: false,
        statusDeleteError: false,
        statusRefreshError: false,
        onStateStart: false,
        onStateBack: false,
        onStateForth: false,
        onStateEnd: false,
        onCheckSelectAll: false,
        filesSelectRowsGlobal: [],
        valueSorter: 'ascend',
        checkValueSorter: 'ascend',
        sorterColumn: '',
        sorterNameColumn: '',
        selectColumn: {},
        checkProcessingDelete: false,
        checkReprocessingFile: false,
        blockReanalyse: false,
        nrFilesWithErrors: 0,
        nrFilesWithErrorsDeleted: 0,
        nonSelectedRows: [],
        nonSelectedRowsIds: [],
        activeDownload: false,
        activeLocation: {},
        nonSelectedWords: [],
        setIntervalDownloadFilesError: 0,
        responseDownloadFilesError: '',
        checkStatusDeleted: '',
        setIntervalDeleted: 0,
        encodedId: null
    }
    componentDidMount() {
        cookie.remove('checkGetDuplicatesCardInfo', { path: '/' })
        cookie.remove('checkDownloadFilesError', { path: '/' })
        cookie.remove('checkErrorReportStatus', { path: '/' })
        this.setState({
            checkSearchError: true
        })
        this.onGetErrorsReport()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === 'errorReport') {
            cookie.remove('checkGetDuplicatesCardInfo', { path: '/' })
            cookie.remove('checkErrorReportStatus', { path: '/' })
            this.setState({
                checkSearchError: true
            })
            this.onGetErrorsReport()
        }
    }
    onGetErrorsReport = () => {
        const { currentPage, filesPerPageLimit, valueInputSearch, totalRecords, sorterColumn, sorterNameColumn } = this.state
        const errorReportRequestDTO = {
            folderPath: this.props.folder.path,
            nrOfFilesWithErrors: totalRecords.length > 0 ? totalRecords : null,
            pageNumber: currentPage,
            pageRows: filesPerPageLimit,
            searchWord: valueInputSearch ? valueInputSearch : null,
            sortBy: sorterNameColumn ? sorterNameColumn : null,
            sortOrder: sorterColumn ? sorterColumn : null
        }
        apiSmartErrorsReport(errorReportRequestDTO).then((response) => {
            if (response.data.errorListItems && response.data.errorListItems.length > 0) {
                this.setState({
                    checkSearchError: false
                })
            }
            this.props.setSmartReprocessingFileDelete(response.data.blockReanalyse)
            response.data.errorListItems && response.data.errorListItems.length > 0 && response.data.errorListItems.forEach((item, index) => {
                item.key = index
            })
            this.setState({
                tableErrorReport: response.data.errorListItems,
                totalRecords: response.data.nrOfFilesWithErrors,
                blockReanalyse: response.data.blockReanalyse,
                nrFilesWithErrors: response.data.nrOfFilesWithErrors,
                nrFilesWithErrorsDeleted: response.data.nrOfFilesWithErrorsDeleted,
            }, () => {
                this.updatePreviewNextPageNumber()
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    onCheckSelectedRows = () => {
        const { tableErrorReport, filesSelectRows, onCheckSelectAll, nonSelectedRows } = this.state
        const updateKeys = []
        if (onCheckSelectAll) {
            tableErrorReport && tableErrorReport.length > 0 && tableErrorReport.forEach((list, index) => {
                filesSelectRows && filesSelectRows.length > 0 && filesSelectRows.forEach((list2, index2) => {
                        if (nonSelectedRows && nonSelectedRows.length > 0) {
                            if (!nonSelectedRows.includes(list.fileName)) {
                                updateKeys.push(index)
                            }
                        } else {
                            updateKeys.push(index)
                        }
                })
            })
        } else {
            tableErrorReport && tableErrorReport.length > 0 && tableErrorReport.forEach((list, index) => {
                filesSelectRows && filesSelectRows.length > 0 && filesSelectRows.forEach((list2, index2) => {
                    if (list.fileName === list2.fileName) {
                        updateKeys.push(index)
                    }
                })
            })
        }
        const uniqueUpdateKeys = [...new Set(updateKeys)]
        this.setState({
            selectedRowKeys: uniqueUpdateKeys
        })
    }
    updatePreviewNextPageNumber = () => {
        const { totalRecords, currentPage } = this.state
        const totalPages = Math.ceil(totalRecords / this.state.filesPerPageLimit)
        const isPrevDisabled = currentPage > 1 ? false : true
        const isNextDisabled = currentPage < totalPages ? false : true
        this.setState({ isPrevDisabled, isNextDisabled, currentPage }, () => {
            this.onCheckSelectedRows()
        })
    }
    handleMovePage = (e, direction) => {
        const { isPrevDisabled, isNextDisabled, totalRecords, filesPerPageLimit } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / filesPerPageLimit) ? Math.ceil(totalRecords / filesPerPageLimit) : this.state.currentPage + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(Math.ceil(totalRecords/filesPerPageLimit))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPage = (newPage) => {
        const { totalRecords, filesPerPageLimit } = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPage: newPage, inputPage: newPage }, () => {
            this.onGetErrorsReport()
        })
    }
    handleInputPageChange = (e) => this.setState({ inputPage: e.target.value }, () => {
        this.onGetErrorsReport()
    })
    handleInputPageBlur = () => {
        const { inputPage } = this.state
        if (inputPage) {
            const paginationEnd = Math.ceil(this.state.totalRecords / this.state.filesPerPageLimit)
            if (inputPage > 0 && inputPage <= paginationEnd) {
                this.gotoPage(inputPage)
            } else {
                this.gotoPage(1)
                this.setState({ inputPage: 1 })
            }
        }
    }
    handleInputPageEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlur()
        }
    }
    onChangeRowErrorReport = (selectedRowKeys, filesSelect, sorter, extra) => {
        const { blockReanalyse, tableErrorReport, filesSelectRows } = this.state
        const nonSelected = []
        const nonSelectedIds = []
        const nameFilesSelected = []
        if (!blockReanalyse) {
            filesSelect && filesSelect.length > 0 && filesSelect.forEach((error) => {
                nameFilesSelected.push(error.fileName)
            })
            let filesSelectedGlobal = this.state.filesSelectRowsGlobal.concat(filesSelect)
            //const filesSelected = filesSelect
            let filesSelected = this.state.filesSelectRows.concat(filesSelect)
            tableErrorReport && tableErrorReport.length > 0 && tableErrorReport.forEach((error) => {
                if(!nameFilesSelected.includes(error.fileName)) {
                    nonSelected.push(error.fileName)
                    nonSelectedIds.push(error.fileId)
                }
            })
            this.state.filesSelectRowsGlobal && this.state.filesSelectRowsGlobal.length > 0 && this.state.filesSelectRowsGlobal.forEach((error) => {
                if(nonSelected.includes(error.fileName)) {
                    filesSelectedGlobal = this.state.filesSelectRowsGlobal.filter((el) => el.fileName !== error.fileName)
                    this.setState({
                        filesSelectRowsGlobal: filesSelectedGlobal
                    })
                }
            })

            const otherFileSelected = []
            this.state.filesSelectRows && this.state.filesSelectRows.length > 0 && this.state.filesSelectRows.forEach((error) => {
                if(nonSelected.includes(error.fileName)) {
                    filesSelected = this.state.filesSelectRows.filter((el) => el.fileName !== error.fileName)
                    this.setState({
                        filesSelectRows: filesSelected
                    })
                }
            })
            const uniqueVerificationVisited = otherFileSelected && otherFileSelected.length > 0 && otherFileSelected.filter((a, i) => otherFileSelected.findIndex((s) => a.fileName === s.fileName) === i)
            this.setState({
                //nonSelectedRows: nonSelected,
                nonSelectedRows: [...new Set(this.state.nonSelectedRows.concat(nonSelected))],
                nonSelectedRowsIds: [...new Set(this.state.nonSelectedRowsIds.concat(nonSelectedIds))],
            })
            this.setState({
                selectedRowKeys: selectedRowKeys,
                //filesSelectRows: filesSelected,
                filesSelectRows: filesSelected,
                filesSelectRowsGlobal: filesSelectedGlobal
            })
        }
    }
    onChangeNow = (pagination, filters, sorter, extra) => {
        if (sorter.order === undefined) {
           this.setState({
               valueSorter: 'ascend'
           })
        } else {
            this.setState({
                valueSorter: sorter.order
            })
        }
        this.setState({
            checkValueSorter: sorter.order
        })
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
           this.setState({
               filesSelectRows: [],
               filesSelectRowsGlobal: []
           })
        }
        this.setState({
            onCheckSelectAll: selected,
            nonSelectedRows: [],
            nonSelectedRowsIds: [],
        })
    }
    changeFilesPerPageLimit = (e) => {
        this.setState({ filesPerPageLimit: e, currentPage: 1 }, () => {
            this.onGetErrorsReport()
        })
    }
    onChangeSearchErrorReport = (e) => {
        this.setState({
            valueInputSearch: e.target.value.length > 0 ? e.target.value.trim() : e.target.value
        }, () => {
            this.onGetErrorsReport()
        })
    }
    onKeyPressErrorTerm = (e) => {
        this.setState({
            valueInputSearch: e.target.value.length > 0 ? e.target.value.trim() : e.target.value
        }, () => this.onGetErrorsReport())
    }
    onChangeSearchError = (e) => {
        this.setState({
            valueInputSearch: e.length > 0 ? e.trim() : e
        }, () => this.onGetErrorsReport())
    }
    onRefreshTable = () => {
        const { filesSelectRows, checkReprocessingFile, blockReanalyse, filesSelectRowsGlobal } = this.state
        const fileIds = []
        filesSelectRows && filesSelectRows.length > 0 && filesSelectRows.forEach((file) => {
            fileIds.push(file.fileId)
        })
        const data = {
            clientId: this.props.clientId,
            list: fileIds
        }
        if (filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0) {
            this.setState({
                checkReprocessingFile: true
            })
        }

        if (!checkReprocessingFile && !blockReanalyse && filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0) {
            this.setState({
                blockReanalyse: true
            }, () => {
                sendToReanalyseWithAI(data).then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            checkReprocessingFile: false
                        })
                        this.onGetErrorsReport()
                    }
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            })
        }
        //this.onGetErrorsReport()
    }
    onDeleteSelected = () => {
        const { onCheckSelectAll, valueInputSearch, filesSelectRowsGlobal, totalRecords, nonSelectedRowsIds } = this.state
        const idSelected = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((row) => {
            idSelected.push(row.fileId)
        })
        // const parametersForDelete = {
        //     id: idSelected
        // }
        const uniqueIdSelected = [...new Set(idSelected)]
        const valuesForDelete = {
            deleteAll: onCheckSelectAll,
            docIDsSelected: onCheckSelectAll ? [] : uniqueIdSelected && uniqueIdSelected.length > 0 ? uniqueIdSelected : [],
            docIDsExcluded: onCheckSelectAll ? nonSelectedRowsIds : [],
            folderPath: this.props.folder.path,
            searchWord: valueInputSearch ? valueInputSearch : ''
        }
        deleteSelectedFilesError(valuesForDelete).then((response) => {
            if (response.status === 200) {
                const totalRecordsUpdate = totalRecords - idSelected.length
                if (onCheckSelectAll) {
                    this.setState({
                        totalRecords: 0
                    })
                } else {
                    this.setState({
                        totalRecords: totalRecordsUpdate
                    })
                }
                if (response.data === 'Success!') {
                    this.setState({
                        checkProcessingDelete: false
                    }, () => {
                        //this.onGetErrorsReport()
                        this.onGetDeletedErrorsStatus()
                    })
                }
                this.setState({
                    selectedRowKeys: [],
                    filesSelectRows: [],
                    filesSelectRowsGlobal: []
                })
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
        // if (filesSelectRows && filesSelectRows.length > 0) {
        //     deleteSelected(parametersForDelete).then((response) => {
        //         if (response.status === 200) {
        //             this.setState({
        //                 selectedRowKeys: []
        //             })
        //             this.onGetErrorsReport()
        //         }
        //     })
        // }
    }
    showConfirmDeleteError = (e) => {
        const { filesSelectRowsGlobal, checkProcessingDelete, nrFilesWithErrors, onCheckSelectAll, blockReanalyse, nonSelectedRows } = this.state
        const idSelected = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((row) => {
            idSelected.push(row.fileId)
        })
        const uniqueIdSelected = [...new Set(idSelected)]
        const uniqueNonSelectedRows = [...new Set(nonSelectedRows)]
        this.stop(e)
        let totalSelectAll = 0
        if (onCheckSelectAll) {
            if (nonSelectedRows && nonSelectedRows.length > 0) {
                totalSelectAll = nrFilesWithErrors - uniqueNonSelectedRows.length
            } else {
                totalSelectAll = nrFilesWithErrors
            }
        }
        if (!checkProcessingDelete && filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && !blockReanalyse) {
            customConfirm({
                title: <span className="icon-v14-delete"></span>,
                content: `Delete ${ onCheckSelectAll ? totalSelectAll : uniqueIdSelected.length } file(s) ?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal modal-delete-error',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.setState({
                        checkProcessingDelete: true
                    }, () => {
                        this.onDeleteSelected(e)
                    })
                }
            })
        }
    }
    onGetDeletedErrorsStatus = () => {
        const { setIntervalDeleted } = this.state
        const { folder } = this.props
        apiGetBoxErrorReportDeleteStatus(folder.id).then((response) => {
            cookie.save('checkErrorReportStatus', 'getErrorReport', {path: '/'})
            if (response.status === 200) {
                this.setState({
                    checkStatusDeleted: response.data
                })
                if (response.data === 'In Progress') {
                    this.setIntervalErrorStatus()
                } else if (response.data === 'Finished') {
                    clearInterval(setIntervalDeleted)
                    this.setState({setIntervalDeleted})
                    this.onGetErrorsReport()
                }
            }
        })
    }

    setIntervalErrorStatus = () => {
        const {  checkStatusDeleted } = this.state
        let { setIntervalDeleted } = this.state
        // if (setIntervalDeleted === 0) {
            if (checkStatusDeleted === 'In Progress') {
                setIntervalDeleted = setInterval(this.callBackDeletedStatus, 1000)
                this.setState({
                    setIntervalDeleted
                })
            } else {
                clearInterval(setIntervalDeleted)
            }
        //}
    }

    callBackDeletedStatus = () => {
        const { setIntervalDeleted, checkStatusDeleted } = this.state
        const { folder } = this.props
        const itemError = cookie.load('checkErrorReportStatus', { path: '/' })
        if (checkStatusDeleted === 'In Progress' && itemError === 'getErrorReport') {
            apiGetBoxErrorReportDeleteStatus(folder.id).then((response) => {
            if (response.data === 'Finished') {
                this.onGetErrorsReport()
                clearInterval(setIntervalDeleted)
                this.setState({ setIntervalDeleted })
            }  else {
                this.setState({ setIntervalDeleted })
            }
            this.setState({
                checkStatusDeleted: response.data
            })
          })
        }
    }
    stop = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    locationTextTable(text) {
        const textLocation = text.length > 30 ? `${ text.replaceAll("/", " > ").substring(0, 30) } ...` : `${text}`
        return textLocation
    }
    onMouseActiveAction = (e, state) => {
      switch (state) {
          case 'delete':
              this.setState({
                  statusDeleteError: true
              })
              break
          case 'refresh':
              this.setState({
                  statusRefreshError: true
              })
              break
          case 'start':
              this.setState({
                  onStateStart: true
              })
              break
          case 'back':
              this.setState({
                  onStateBack: true
              })
              break
          case 'forth':
              this.setState({
                  onStateForth: true
              })
              break
          case 'end':
              this.setState({
                  onStateEnd: true
              })
              break
          case 'download':
              this.setState({
                  activeDownload: true
              })
              break
          default: break
      }
    }
    onLeaveMouseAction = () => {
        this.setState({
            statusDeleteError: false,
            statusRefreshError: false,
            onStateStart: false,
            onStateBack: false,
            onStateForth: false,
            onStateEnd: false,
            activeDownload: false
        })
    }
    onSorterColumns = (e, column, sorter) => {
        const { tableErrorReport } = this.state
        if (tableErrorReport && tableErrorReport.length > 0) {
            const selectCol = {
                column: column,
                sorter: sorter
            }
            this.setState({
                sorterColumn: sorter,
                sorterNameColumn: column,
                selectColumn: selectCol,
                selectedRowKeys: []
            }, () => {
                    this.onGetErrorsReport()
            })
        }
    }
    rowClassSelected = (record) => {
        if (record.deletedBy) {
            return 'row-deleted'
        }
    }
    onDownloadErrorReport = (e) => {
        const { onCheckSelectAll, valueInputSearch, filesSelectRowsGlobal } = this.state
        const { folder } = this.props
        const idSelected = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((row) => {
            idSelected.push(row.fileId)
        })
        const uniqueIdSelected = [...new Set(idSelected)]
        // const valuesForDelete = {
        //     deleteAll: onCheckSelectAll,
        //     docIDs: onCheckSelectAll ? [] : uniqueIdSelected && uniqueIdSelected.length > 0 ? uniqueIdSelected : [],
        //     folderPath: this.props.folder.path,
        //     searchWord: valueInputSearch ? valueInputSearch : ''
        // }
            const downloadAll = onCheckSelectAll
            const docIDs = onCheckSelectAll ? [] : uniqueIdSelected && uniqueIdSelected.length > 0 ? uniqueIdSelected : []
            const boxId = this.props.folder.id
            const searchWord = valueInputSearch ? valueInputSearch : ''

        if (filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0) {
            downloadFilesError(downloadAll, docIDs, boxId, searchWord).then((response) => {
                FileDownload(response.data, `${folder.name}_ErrorReporting.zip`)
            }).then(() => {
                CustomNotificationManager.cleanBuffer()
            })
        }
    }
    onErrorReportDefault = (e, record) => {
      e.preventDefault()
    }
    onErrorReportListFileLocation = (e, record) => {
        const { filesPerPageLimit } = this.state
        const locationFile = {
            idDocument: record.fileId,
            pageRows: filesPerPageLimit,
        }
        getErrorReportListFileLocation(locationFile).then((response) => {
            if (response.status === 200) {
                if (Object.keys(response.data) && Object.keys(response.data).length > 0) {
                    window.open(`${window.location.origin}/smart-box/folder/${response.data.idFolderDocument}`)
                    cookie.save('errorReport', response.data, {path: '/', maxAge: 60 * 60})
                }
            }
        })
    }
    onActiveLocation = (e, location) => {
        this.setState({
            activeLocation: location
        })
    }
    onNonActiveLocation = () => {
        this.setState({
            activeLocation: {}
        })
    }

    downloadFilesErrorCall = (e, downloadAll, docIDs, boxId, clientId, searchWord) => {
        e.preventDefault()
        const { setIntervalDownloadFilesError, encodedId } = this.state
        const errorReportDownloadDTO = {
            downloadAll: downloadAll,
            docIDs: docIDs,
            boxId: boxId,
            searchWord: searchWord,
            clientId: clientId,
            userId: this.props.user.id,
            encodedId: encodedId,
        }
        apiCheckExportErrorReportList(errorReportDownloadDTO).then((response) => {
            cookie.save('checkDownloadFilesError', 'getDownloadFilesError', {path: '/'})
            this.setState({
                responseDownloadFilesError: response.data.status,
                encodedId: response.data.encodedId,
            })
            if(response.data.messageTextResponseDTO && response.data.messageTextResponseDTO.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.messageTextResponseDTO.message, 'Info')
            }
            if (response.data.status === 'processing') {
                this.setIntervalDownloadFilesError()
            } else {
                window.location.href=`${ host }/osprey-0.1.0/v1/smart-box/error-report-list-download/${response.data.encodedId}`
                clearInterval(setIntervalDownloadFilesError)
                this.setState({setIntervalDownloadFilesError})
            }

        })
       // window.location.href=`${ host }/osprey-0.1.0/v1/smart-box/error-report-list-download/${downloadAll}-${docIDs}-${boxId}-${clientId}-${searchWord}`
    }

    setIntervalDownloadFilesError = () => {
        let { setIntervalDownloadFilesError } = this.state
        const { responseDownloadFilesError } = this.state
        if (setIntervalDownloadFilesError === 0 || setIntervalDownloadFilesError > 0) {
            if (responseDownloadFilesError === 'processing') {
                setIntervalDownloadFilesError = setInterval(this.callBackIntervalDownloadFilesError, 5000)
                this.setState({
                    setIntervalDownloadFilesError
                })
            } else {
                clearInterval(setIntervalDownloadFilesError)
            }
        }
    }

    callBackIntervalDownloadFilesError = () => {
        const { responseDownloadFilesError, setIntervalDownloadFilesError, onCheckSelectAll, filesSelectRowsGlobal, valueInputSearch, encodedId } = this.state
        const downloadAll = onCheckSelectAll
        const idSelected = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((row) => {
            idSelected.push(row.fileId)
        })
        const uniqueIdSelected = [...new Set(idSelected)]
        const docIDs = onCheckSelectAll ? [] : uniqueIdSelected && uniqueIdSelected.length > 0 ? uniqueIdSelected : []
        const boxId = this.props.folder.id
        const searchWord = valueInputSearch ? valueInputSearch : ''
        const errorReportDownloadDTO = {
            downloadAll: downloadAll,
            docIDs: docIDs,
            boxId: boxId,
            searchWord: searchWord,
            clientId: this.props.clientId,
            userId: this.props.user.id,
            encodedId: encodedId,
        }
        const itemDownloadFilesError = cookie.load('checkDownloadFilesError', { path: '/' })
        if (responseDownloadFilesError === 'processing' && itemDownloadFilesError === 'getDownloadFilesError') {
            apiCheckExportErrorReportList(errorReportDownloadDTO).then((response) => {
                this.setState({
                    responseDownloadFilesError: response.data.status,
                    encodedId: response.data.encodedId
                })
                if (response.data.status === 'finished') {
                    window.location.href=`${ host }/osprey-0.1.0/v1/smart-box/error-report-list-download/${response.data.encodedId}`
                    clearInterval(setIntervalDownloadFilesError)
                    this.setState({setIntervalDownloadFilesError})
                    cookie.remove('checkDownloadFilesError', { path: '/' })
                }
            })
        }
    }
    onTimezoneConvert = (time) => {
        const localTimeZone = moment.tz.guess()
        const localDate = moment.utc(time).tz(localTimeZone)
        return `${ moment(localDate).format('YYYY-MM-DD HH:mm:ss').split(' ').join(' at ') }`
    }
    render() {
        const { totalRecords, filesPerPageLimit, isPrevDisabled, isNextDisabled, currentPage, inputPage, tableErrorReport, selectedRowKeys, valueInputSearch,
            filesSelectRows, checkSearchError, statusDeleteError, statusRefreshError, onStateStart, onStateBack, onStateForth, onStateEnd, checkValueSorter, selectColumn,
            filesSelectRowsGlobal, checkProcessingDelete, checkReprocessingFile, blockReanalyse, nrFilesWithErrors, activeDownload, onCheckSelectAll, activeLocation, nrFilesWithErrorsDeleted,
            responseDownloadFilesError, checkStatusDeleted, encodedId } = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        let deletedFiles = []
        deletedFiles = tableErrorReport.filter((error) => error.deletedBy !== null)
        let errorCountFiles = 0
        errorCountFiles = nrFilesWithErrors - deletedFiles.length
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onChangeRowErrorReport,
            onSelectAll: this.onSelectAll,
            disabled: !checkReprocessingFile || !blockReanalyse
        }
        const idSelected = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((row) => {
            idSelected.push(row.fileId)
        })
        const uniqueIdSelected = [...new Set(idSelected)]
        const downloadAll = onCheckSelectAll
        const docIDs = onCheckSelectAll ? [] : uniqueIdSelected && uniqueIdSelected.length > 0 ? uniqueIdSelected : []
        const boxId = this.props.folder.id
        const searchWord = valueInputSearch ? valueInputSearch : ''
        return (
            <div className="error-report-page">
                <div className="error-report-page__content">
                    <div className="header-error-page">
                        <div className="title-header">File errors</div>
                        <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                            <span className="text-information">The Error report page displays all the files that have failed to convert or be processed and the reason for this.</span>
                            <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001222616-error-report', '_blank') }>More info.</span>
                            </div>}>
                            <span className="icon-v14-question"></span>
                        </Tooltip>
                    </div>
                    <div className="content-error-report">
                       <div className="error-report-pagination">
                           <div className= { tableErrorReport.length === 0 ? 'per-page-error per-page-error-disabled' : "per-page-error" }>
                               <Select dropdownClassName="select-per-page-error per-error"
                                       value={<span><span>{ `Show ${ filesPerPageLimit }` } </span><span className="icon-v19-arrow-medium"></span></span> }
                                       disabled={ tableErrorReport.length === 0 }
                                        onChange={(e) => this.changeFilesPerPageLimit(e)}>
                                            <Option value="20">20</Option>
                                            <Option value="50">50</Option>
                                            <Option value="100">100</Option>
                               </Select>
                           </div>
                           <div className="errors-found-content">
                               <div className="count-errors">
                               <span className="count-error">{ nrFilesWithErrors }</span>
                               <span className="error-found">Errors</span>
                               </div>
                               <div className="deleted-error">
                                   <span className="count-error">{ nrFilesWithErrorsDeleted }</span>
                                   <span className="error-found">Deleted</span>
                               </div>
                           </div>
                           <div className="buttons-control-pagination">
                               <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                                <span className={ isPrevDisabled ? 'icon-v14-start-1 disabled' : onStateStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'start')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'first')}/>
                                               </Tooltip>
                                               <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                                <span className={ isPrevDisabled ? 'icon-v21-back-1 disabled' : onStateBack ? 'icon-v21-back-active' : 'icon-v21-back-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'back')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                                               </Tooltip>
                                               { totalPages === 0 &&
                                               <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
                                               }
                                               { totalPages === 1 &&
                                               <span className='buttons-control-pagination__text'>{currentPage} of {totalPages} </span>
                                               }
                                               { totalPages > 1 &&
                                               <span className='buttons-control-pagination__text'>
                                <input
                                    type="number"
                                    className="insert-page"
                                    placeholder= { currentPage }
                                    value= { inputPage }
                                    min="1"
                                    max={totalPages}
                                    onChange={ (e) => this.handleInputPageChange(e) }
                                    onBlur={ () => this.handleInputPageBlur() }
                                    onKeyDown={ (e) => this.handleInputPageEnter(e) }
                                />
                                <span>of {totalPages}</span>
                              </span>
                                               }
                                               <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                                <span className={isNextDisabled ? 'icon-v21-forth-1 disabled' : onStateForth ? 'icon-v21-forth-active' : 'icon-v21-forth-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'forth')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'next')}/>
                                               </Tooltip>
                                               <Tooltip title="last" className={ isNextDisabled ? 'disabled' : '' }>
                                <span className={ isNextDisabled ? 'icon-v14-end-1 disabled' : onStateEnd ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'end')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'last')}/>
                                               </Tooltip>
                           </div>
                           <div className="content-delete-refresh">
                               <Tooltip title="Delete">
                               <span onClick={(e) => this.showConfirmDeleteError(e)}
                                     onMouseEnter={(e) => this.onMouseActiveAction(e, 'delete')}
                                     onMouseLeave={() => this.onLeaveMouseAction()}
                                     className={ checkProcessingDelete ? 'icon-v14-delete disabled-delete' : blockReanalyse ? 'icon-v14-delete disabled-delete' : statusDeleteError && filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 ? 'icon-v14-delete-active' : filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 ? "icon-v14-delete" : blockReanalyse && checkProcessingDelete && statusDeleteError ? 'icon-v14-delete-active' : 'icon-v14-delete disabled-delete' }> </span>
                               </Tooltip>
                               <Tooltip title="Reprocess">
                               <span onClick={ (e) => this.onRefreshTable(e) }
                                     onMouseEnter={(e) => this.onMouseActiveAction(e, 'refresh')}
                                     onMouseLeave={() => this.onLeaveMouseAction()}
                                     className={blockReanalyse ? 'icon-v14-refresh-1 disabled-refresh' : checkReprocessingFile ? 'icon-v14-refresh-1 disabled-refresh' :
                                         filesSelectRowsGlobal.length === 0 ? 'icon-v14-refresh-1 disabled-refresh' : statusRefreshError && filesSelectRows && filesSelectRows.length > 0 ? 'icon-v14-refresh-active' : filesSelectRows && filesSelectRows.length > 0 ? "icon-v14-refresh-1" : blockReanalyse && checkReprocessingFile && statusRefreshError ? 'icon-v14-refresh-active' : 'icon-v14-refresh-1 disabled-refresh'}> </span>
                               </Tooltip>
                               { filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 ?
                                   <button
                                       //onClick={(e) => this.onDownloadErrorReport(e)}
                                       onClick={(e) => this.downloadFilesErrorCall(e, downloadAll, docIDs, boxId, this.props.clientId, searchWord)}
                                       className={filesSelectRowsGlobal.length === 0 ? 'icon-v21-download-1 disabled-download' : activeDownload ? 'icon-v21-download-active-1' : "icon-v21-download-1"}
                                       onMouseEnter={(e) => this.onMouseActiveAction(e, 'download')}
                                       onMouseLeave={() => this.onLeaveMouseAction()}> </button>
                                   :
                                   <a
                                       className={filesSelectRowsGlobal.length === 0 ? 'icon-v21-download-1 disabled-download' : activeDownload ? 'icon-v21-download-active-1' : "icon-v21-download-1"}
                                       onMouseEnter={(e) => this.onMouseActiveAction(e, 'download')}
                                       onMouseLeave={() => this.onLeaveMouseAction()}> </a>
                               }
                           </div>
                           <div className="input-search-error">
                               <Search className="search-error"
                                       allowClear
                                       style={{ width: 200 }}
                                       placeholder="Search"
                                       defaultValue={ valueInputSearch }
                                       disabled={ checkSearchError === true }
                                       onSearch={ this.onChangeSearchError }
                               onChange={ (e) => this.onChangeSearchErrorReport(e)}
                                       onKeyPress={ (e) => this.onKeyPressErrorTerm(e)}/>
                           </div>
                       </div>
                        <div className="table-error-report-content">
                            <Table className={ checkReprocessingFile || blockReanalyse ? 'table-error-files table-error-files-disabled' : checkValueSorter === undefined ? "table-error-files table-error-files-sorter" : 'table-error-files' }
                                   dataSource={ tableErrorReport }
                                   pagination={ false }
                                   // sortDirections={ ["ascend", "descend"] }
                                   // onChange={ this.onChangeNow }
                                   rowClassName={(record) => this.rowClassSelected(record) }
                                   loading={ checkStatusDeleted === 'In Progress' }
                                   rowSelection={ rowSelection }>
                                <Column className="column-error-report"
                                        title= { <span className="content-header-column"><span>File</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ selectColumn.column === 'name' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                     onClick={(e) => this.onSorterColumns(e, 'name', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'name' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'name', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="fileName" key="fileName"
                                        // sorter={true }
                                        // defaultSortOrder={ valueSorter }
                                        // sortDirections={ ["ascend", "descend"] }
                                        //sorter = {(a, b) => a.fileName && b.fileName ? a.fileName.localeCompare(b.fileName) : '' }
                                        render={(text, record) => {
                                    return (
                                      <span className="text-error-report">{ text }</span>
                                    )
                                }} />
                                <Column className="column-error-report"
                                        title= { <span className="content-header-column"><span>Location</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ selectColumn.column === 'location' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumns(e, 'location', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'location' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'location', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="location" key="location"
                                        //sorter = {(a, b) => a.location && b.location ? a.location.localeCompare(b.location) : '' }
                                        // sorter={true }
                                        // defaultSortOrder={ valueSorter }
                                        // sortDirections={ ["ascend", "descend"] }
                                        render={(text, record) => {
                                    return (
                                        <Tooltip title={ text ? text.replaceAll("/", " > ") : ''}>
                                        <span onClick={record.deletedBy === null ? (e) => this.onErrorReportListFileLocation(e, record) : (e) => this.onErrorReportDefault(e)}
                                              className={ activeLocation.fileId === record.fileId && record.deletedBy === null ? "text-error-report text-error-report-location-active" : 'text-error-report text-error-report-location' }
                                              onMouseEnter={(e) => this.onActiveLocation(e, record)}
                                              onMouseLeave={() => this.onNonActiveLocation()}>{ this.locationTextTable(text) }
                                            </span>
                                        </Tooltip>
                                    )
                                }}/>
                                <Column className="column-error-report"
                                        title= { <span className="content-header-column"><span>Upload</span>
                                                 <span className="content-buttons-sorter">
                                                      <span className={ selectColumn.column === 'date' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                            onClick={(e) => this.onSorterColumns(e, 'date', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'date' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'date', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="uploadTime" key="uploadTime"
                                        // sorter={true }
                                        // defaultSortOrder={ valueSorter }
                                        // sortDirections={ ["ascend", "descend"] }
                                        //sorter = {(a, b) => a.uploadTime && b.uploadTime ? a.uploadTime.localeCompare(b.uploadTime) : '' }
                                        render={(text, record) => {
                                    return (
                                        <span className="text-error-report">{ text ? this.onTimezoneConvert(text) : 'unknown' }</span>
                                    )
                                }}/>
                                <Column className="column-error-report"
                                        title= { <span className="content-header-column"><span>Error message</span>
                                                 <span className="content-buttons-sorter">
                                                      <span className={ selectColumn.column === 'message' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                            onClick={(e) => this.onSorterColumns(e, 'message', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'message' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'message', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="errorMessage" key="errorMessage"
                                        // sorter={true }
                                        // defaultSortOrder={ valueSorter }
                                        // sortDirections={ ["ascend", "descend"] }
                                        //sorter = {(a, b) => a.errorMessage && b.errorMessage ? a.errorMessage.localeCompare(b.errorMessage) : '' }
                                        render={(text, record) => {
                                    return (
                                        <span className="text-error-report">{ text }</span>
                                    )
                                }}/>
                                <Column className="column-error-report" title="Deleted by" dataIndex="deletedBy" key="deletedBy"
                                        //sorter = {(a, b) => a.deletedBy && b.deletedBy ? a.deletedBy.localeCompare(b.deletedBy) : '' }
                                        render={(text, record) => {
                                    return (
                                        <span className="text-error-report">{ text }</span>
                                    )
                                }}/>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

ErrorReport.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    user: state.userReducer.user,
    smartBoxParentFolderName: state.smartBox.smartBoxParentFolderName
})
export default compose(
    connect(mapStateToProps, {
        setSmartReprocessingFileDelete
    })
)(ErrorReport)