import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import './EmailAcknowledgedWorkflow.scss'
import {Select, Steps, Button } from "antd";
const { Option } = Select;
const { Step } = Steps;
import {
    apiGetEmailTemplateTextWorkflowTicket,
    apiGetEmailWorkflowTicketTrackingSteps, apiResetCustomEmailWorkflowTicket,
    apiSendEmailWorkflowTicket,
    apiSendInternalEmailNotificationWorkflow, apiUpdateCustomEmailWorkflowTicket,
    getTicketWorkflowAndHeaderDetails
} from "../../../../../api/SmartBox";
import {customConfirm} from "../../../../../utils/AssetRegister/CustomConfirm";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import moment from 'moment-timezone'
import ReactQuill from "react-quill";
export class InternalEmailPageNotificationWorkflow extends Component {

    state = {
        emailTemplateText : '',
        emailTemplateTitle : '',
        emailSendDate : 'Pending',
        emailLinkVisited : 'Pending',
        emailEndedRequest : 'Pending',
        statusSent : 'wait',
        statusLinkVisited : 'wait',
        statusEndedRequest : 'wait',
        emailComponentSendEmailDates: [],
        emailComponentLinkVisitedDates: [],
        checkSendEmail: false,
        loadingButtonSend: false,
        listStepsEmail: {
            emailSendEmailDates: [],
            emailLinkVisitedDates: [],
            emailEndedRequestDates: [],
        },
        emailTemplate: {
            content: ''
        },
        disabledSend: 'start',
        sourceEditor: 'api',
        subjectEmail: ''
    }
    generateClassSteps = (step, status, state) => {
        switch (step) {
            case 'status-link':
                if (state === 'finish' || state === 'process') {
                    return 'status-link status-step-finish'
                }
                return 'status-link'

            case 'status-link-visited':
                if (state === 'finish' || state === 'process') {
                    return 'status-link-visited status-step-finish'
                }
                return 'status-link-visited'
            case 'status-ended-request':
                if (state === 'finish' || state === 'process') {
                    return 'status-ended-request status-step-finish'
                }
                return 'status-ended-request'
            default :
                return 'status'
        }

    }
    generateDate(date) {
        return moment(date).format('DD.MM.YYYY')
    }
    onTimezoneConvert = (time) => {
        console.log('time', time)
        const localTimeZone = moment.tz.guess()
        const localDate = moment.utc(time).tz(localTimeZone)
        return `${ moment(localDate).format('DD.MM.YYYY HH:mm') }`
    }
    generateDescriptionSteps = (step, current) => {
        const { emailComponentSendEmailDates, emailComponentLinkVisitedDates, listStepsEmail } = this.state
        const { stepHeaderActive } = this.props
        switch (step) {
            case 'status-link':
                if (listStepsEmail.emailSendEmailDates && listStepsEmail.emailSendEmailDates.length > 0) {
                    return <React.Fragment>
                        {
                            listStepsEmail.emailSendEmailDates.map((date, i) => <span className="status-finish-info"
                                                                                      key={i}>{date ? this.onTimezoneConvert(date) : ''}</span>)
                        }
                    </React.Fragment>
                }
                return <span className="status-pending">{this.state.emailSendDate}</span>

            case 'status-link-visited':
                if (listStepsEmail.emailLinkVisitedDates && listStepsEmail.emailLinkVisitedDates.length > 0) {
                    return <React.Fragment>
                        {
                            listStepsEmail.emailLinkVisitedDates.map((date, i) => <span className="status-finish-info"
                                                                                        key={i}>{date ? this.onTimezoneConvert(date) : ''}</span>)
                        }
                    </React.Fragment>
                }
                return <span className="status-pending">{this.state.emailLinkVisited}</span>
            case 'status-ended-request':
                if (listStepsEmail.emailEndedRequestDates && listStepsEmail.emailEndedRequestDates.length > 0) {
                    return <React.Fragment>
                        {
                            listStepsEmail.emailEndedRequestDates.map((date, i) => <span className="status-finish-info"
                                                                                         key={i}>{date ? this.onTimezoneConvert(date) : ''}</span>)
                        }
                    </React.Fragment>
                }
                return <span className="status-pending">{this.state.emailEndedRequest}</span>
            default :
                return 0
        }
    }
    customDot = (dot, { index, status }) => {
        switch (index) {
            case 0: {
                return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
            }
            case 1: {
                return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
            }
            case 2: {
                return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
            }
            default:
                return <span></span>
        }
    }
    componentDidMount() {
        this.callApiGetEmailTemplateTextWorkflowTicket()
        this.onGetEmailWorkflowTicketTrackingSteps()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.stepHeaderActive && this.props.stepHeaderActive && prevProps.stepHeaderActive.stepComponentWorkflowId !== this.props.stepHeaderActive.stepComponentWorkflowId) {
            this.callApiGetEmailTemplateTextWorkflowTicket()
            this.onGetEmailWorkflowTicketTrackingSteps()
        }
    }

    onGetEmailWorkflowTicketTrackingSteps = () => {
        const { stepHeaderActive, selectHeaderTickets } = this.props
        const { listStepsEmail } = this.state
        const getEmailWorkflowTrackingStepsDTO = {
            workflowComponentId: this.props.stepHeaderActive.stepComponentWorkflowId,
            ticketId: this.props.selectHeaderTickets.ticketId,
        }
        apiGetEmailWorkflowTicketTrackingSteps(getEmailWorkflowTrackingStepsDTO).then((response) => {
            const emailSendEmailDates = []
            const emailLinkVisitedDates = []
            const emailEndedRequestDates = []
            response.data && response.data.length > 0 && response.data.forEach((elem) => {
                if (elem.emailStepName === 'Email Sent') {
                    emailSendEmailDates.push(elem.emailStepDate)
                }
                if (elem.emailStepName === 'Email link visited') {
                    emailLinkVisitedDates.push(elem.emailStepDate)
                }
                if (elem.emailStepName === 'Form Completed') {
                    emailEndedRequestDates.push(elem.emailStepDate)
                }
            })
            if (emailSendEmailDates && emailSendEmailDates.length > 0) {
                this.setState({
                    statusSent: 'process'
                })
            } else {
                this.setState({
                    statusSent: 'wait'
                })
            }
            if (emailLinkVisitedDates && emailLinkVisitedDates.length > 0) {
                this.setState({
                    statusLinkVisited: 'process'
                })
            } else {
                this.setState({
                    statusLinkVisited: 'wait'
                })
            }
            if (emailEndedRequestDates && emailEndedRequestDates.length > 0) {
                this.setState({
                    statusEndedRequest: 'process'
                })
            } else {
                this.setState({
                    statusEndedRequest: 'wait'
                })
            }
            this.setState({
                listStepsEmail: {...this.state.listStepsEmail, emailSendEmailDates: emailSendEmailDates, emailLinkVisitedDates: emailLinkVisitedDates,
                    emailEndedRequestDates: emailEndedRequestDates }
            })
        })
    }
    validateBody(data) {
        this.checkNameVariable(data)
        return /(?=.*\${name})/.test(data)
    }
    checkNameVariable(data) {
        if (data.includes('{name}')) {
            this.setState({
                isNameLinkPersist: true
            })
        } else {
            this.setState({
                isNameLinkPersist: false
            })
        }
    }
    handleEditorChange = (e, delta, source, editor) => {
        const { sourceEditor } = this.state
        this.setState({
            editMode: true,
            valid: this.validateBody(e)
        })
        if (source === 'user' && sourceEditor !== 'user') {
            this.setState({
                disabledSend: 'update',
                sourceEditor: source
            })
        }
    }
    callApiUpdateEmailWorkflowTicketAPI = () => {
        const {emailTemplate,subjectEmail} = this.state
        const {stepHeaderActive, selectHeaderTickets} = this.props
        const dtoUpdate = {
            ticketId: selectHeaderTickets.ticketId,
            workflowComponentId: stepHeaderActive.stepComponentWorkflowId,
            emailContent: emailTemplate.content,
            emailSubject: subjectEmail.replaceAll('${', '#{')
        }
        apiUpdateCustomEmailWorkflowTicket(dtoUpdate).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
                this.callApiGetEmailTemplateTextWorkflowTicket()
                this.setState({
                    disabledSend: 'finish'
                })
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
        })
    }
    callApiResetEmailWorkflowTicketAPI = () => {
        const {stepHeaderActive, selectHeaderTickets} = this.props
        const dtoUpdate = {
            ticketId: selectHeaderTickets.ticketId,
            workflowComponentId: stepHeaderActive.stepComponentWorkflowId,
        }
        apiResetCustomEmailWorkflowTicket(dtoUpdate).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
                this.setState({
                    disabledSend: 'finish',
                    sourceEditor: 'api',
                })
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.callApiGetEmailTemplateTextWorkflowTicket()
        })
    }
    handleChangeSubjectEmail = (e) => {
        this.setState({
            subjectEmail: e.target.value
        })

        this.setState({
            disabledSend: 'update'
        })
    }
    render() {
        const { stepTicketActive, stepHeaderActive } = this.props
        const { checkSendEmail, loadingButtonSend, listStepsEmail, disabledSend, subjectEmail } = this.state
        return (
            <div className="send-email-update">
                <div className="email-disclosure-content">
                    <div className="email-disclosure-header">
                        <span className="email-subject">{this.state.emailTemplateTitle}</span>
                        {/*<span className="sar-disclosure">SAR disclosure</span>*/}
                    </div>
                    <div className="form-group">
                        <label className="col-md-2">Email Subject*</label>
                        <input type="text" placeholder="Subject" className="col-md-10"
                            onChange={ (e) => this.handleChangeSubjectEmail(e) }
                               value={ subjectEmail ? subjectEmail.replaceAll('#{', '${') : subjectEmail }
                        />
                    </div>
                    <div className="email-subject-content">
                        <form className="form-content">
                            <ReactQuill
                                theme={this.state.theme}
                                className="quill-write"
                                onChange={(value, delta, source, editor) => {
                                    this.setState({
                                        emailTemplate: {
                                            ...this.state.emailTemplate,
                                            content: value
                                        }
                                    }, () => this.handleEditorChange(value, delta, source, editor))
                                }
                                }
                                //value={this.state.emailTemplateText ? this.state.emailTemplateText.replaceAll('#{', '${') : this.state.emailTemplateText}
                                value={this.state.emailTemplate.content}
                                modules={this.modules}
                            />
                        </form>
                    </div>
                    <div className={disabledSend === 'start' ? "email-buttons email-buttons-update" : 'email-buttons'}>
                        { disabledSend !== 'start' &&
                        <div className="email-buttons-reset">
                            <button
                                onClick={(e) => this.callApiResetEmailWorkflowTicketAPI(e)}
                                className={'icon-v14-refresh-1'}></button>
                            <Button className="reset-button"
                                    onClick={(e) => this.callApiUpdateEmailWorkflowTicketAPI(e)}>Update</Button>
                        </div>
                        }
                        {/*<Button className="reset-button" onClick={(e) => this.onResetEmail(e)}>Reset</Button>*/}
                        <Button
                            disabled={disabledSend === 'update'}
                            //disabled={checkSendEmail === true || (this.state.emailTemplateText !== null && this.state.emailTemplateText.length > 0 ? false : true)}
                            //className="button-send-email-acknowledged"
                                loading={loadingButtonSend}
                                className="send-button"
                                onClick={this.callSendEmailWorkflowTicketAPI} >Send</Button>
                    </div>
                </div>
                <div className="tracking-progress-content">
                    <div className="tracking-progress-header">
                        <span className="tracking-name">Tracking progress</span>
                    </div>
                    <div className="tracking-progress-steps">
                        <Steps className="progress-step"
                            //progressDot={ this.customDot }
                               progressDot
                               current={0} direction="vertical">
                            <Step title="Email Sent" description={ this.generateDescriptionSteps('status-link', 0) }
                                  className={ this.generateClassSteps('status-link', 0, this.state.statusSent) } status={ this.state.statusSent }/>
                            {/*<Step title="Email link visited" description={ this.generateDescriptionSteps('status-link-visited', 1) }*/}
                            {/*      className={ this.generateClassSteps('status-link-visited', 1, this.state.statusLinkVisited) } status={ this.state.statusLinkVisited }/>*/}
                            {/*<Step title="Form completed" description={ this.generateDescriptionSteps('status-ended-request', 2) }*/}
                            {/*      className={ this.generateClassSteps('status-ended-request', 2, this.state.statusEndedRequest) } status={ this.state.statusEndedRequest }/>*/}
                        </Steps>
                    </div>
                </div>
            </div>
        )
    }
    callApiGetEmailTemplateTextWorkflowTicket = () => {
        const {stepHeaderActive, selectHeaderTickets} = this.props
        if (stepHeaderActive && (stepHeaderActive.stepComponentName === 'Email' || stepHeaderActive.stepComponentName === 'Web Form Email Template'
        || stepHeaderActive.stepComponentName === 'Internal email notification')) {

            apiGetEmailTemplateTextWorkflowTicket(Number(stepHeaderActive.stepComponentWorkflowId), selectHeaderTickets.ticketId).then(response => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }else{
                    this.setState({
                        emailTemplateText : response.data.content,
                        emailTemplate: {...this.state.emailTemplate, content: response.data.content},
                        subjectEmail: response.data.subject,
                        emailTemplateTitle : response.data.templateName
                    })
                }

            })
        }
    }

    callSendEmailWorkflowTicketAPI = () => {
        const {stepHeaderActive, selectHeaderTickets} = this.props
        if (stepHeaderActive && (stepHeaderActive.stepComponentName === 'Email' || stepHeaderActive.stepComponentName === 'Web Form Email Template'
        || stepHeaderActive.stepComponentName === 'Internal email notification')) {

            const SendInternalEmailNotificationTicketDTO = {
                ticketId: selectHeaderTickets.ticketId,
                workflowComponentId: stepHeaderActive.stepComponentWorkflowId,
                sendEmail: true
            }
            this.setState({
                checkSendEmail: true,
                loadingButtonSend: true,
            }, () => {
                apiSendInternalEmailNotificationWorkflow(SendInternalEmailNotificationTicketDTO).then(response => {
                    if (response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                        let emailComponentSendEmailDates = []
                        let emailComponentLinkVisitedDates = []
                        this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
                        this.onGetEmailWorkflowTicketTrackingSteps()
                        getTicketWorkflowAndHeaderDetails(selectHeaderTickets.ticketId).then(response => {
                            response.data.ticketWorkflowStepDTOS && response.data.ticketWorkflowStepDTOS.length > 0 && response.data.ticketWorkflowStepDTOS.forEach((elem) => {
                                if (elem.stepComponentName === 'Email' || elem.stepComponentName === 'Web Form Email Template' || elem.stepComponentName === 'Internal email notification' ) {
                                    emailComponentSendEmailDates = elem.emailComponentSendEmailDates
                                    emailComponentLinkVisitedDates = elem.emailComponentLinkVisitedDates
                                    this.setState({
                                        emailComponentSendEmailDates: elem.emailComponentSendEmailDates,
                                        emailComponentLinkVisitedDates: elem.emailComponentLinkVisitedDates,
                                    })
                                }
                            })
                        })
                    } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }else{
                        getTicketWorkflowAndHeaderDetails(selectHeaderTickets.ticketId).then(response => {
                        })
                    }

                    this.setState({
                        checkSendEmail: false,
                        loadingButtonSend: false,
                    })
                })
            })
        }
    }

}





InternalEmailPageNotificationWorkflow.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(InternalEmailPageNotificationWorkflow)