import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../Tracker/Tracker.scss'
import './AiAnnotationsSidebar.scss'
import Business from './Business'
import BusinessCopy from './BusinessCopy'
import RegularExpressions from './RegularExpressions'
import RegularExpressionsCopy from './RegularExpressionsCopy'
import swal from "sweetalert2";
import {
    apiSmartBoxSaveQueryStatusMixed,
    apiSmartBoxUpdateRedactionNumber,
} from "../../../api/SmartBox";
import CustomNotificationManager from "../../Common/CustomNotificationManager";

class ManualTabCopy extends React.Component {
    state = {
        activeKeyTab: 'manualTerms',
        visibleLists: false,
        visibleRegExEmails: true,
        visibleBosTerms: true,
        visibleRedactRegEx: false,
        visibleRedactBusiness: false,
        getAllChildrenRegEx: [],
        checkedChildRegEx: [],
        checkedChildBusiness: [],
        startBusinessRedact: false,
        showVisibleDictionary: null,
        visibleDictionaryCat: false,
        showVisibleRegEx: null,
        visibleRegExCat: false,
        unCheckedKeysRegEx2: [],
        removeAllRegEx: false,
        removeAllDictionaries: false,
        statusRemoveAllRegEx: null,
        visibleTrueDict: true

    }
    onSelectActiveTabsOnManual = (statusActiveTab) => {
        this.setState({
            activeKeyTab: statusActiveTab
        })
    }
    onDisplaySelectComponentAi = (component) => {
        const { treeDataAnnotationsBusinessMixed, treeDataAnnotationsRegExMixed } = this.props
        switch (component) {
            case 'lists':
                this.setState({
                    visibleLists: !this.state.visibleLists
                })
                break
            case 'reg-ex':
                if (treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed.length > 0 && treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0) {

                    this.setState({
                        visibleRegExEmails: !this.state.visibleRegExEmails
                    })
                }
                break
            case 'box-terms':
                if (treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed.length > 0 && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0)
                {
                    this.setState({
                        visibleBosTerms: !this.state.visibleBosTerms,
                        visibleTrueDict: !this.state.visibleTrueDict
                    })
                }
                break
            default: break
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.statusHideAllHighlighting !== this.props.statusHideAllHighlighting) {
            if (this.props.statusHideAllHighlighting === true) {
                this.setState({
                    showVisibleDictionary: null
                })
            } else {
                this.setState({
                    showVisibleDictionary: true
                })
            }
            this.setState({
                visibleDictionaryCat: true,
            })
            const { treeDataAnnotationsBusinessMixed } = this.props
            treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed[0].children.length > 0 && treeDataAnnotationsBusinessMixed[0].children.forEach((keyColour) => {
                keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                    keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                        // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                        if (keyColour3 && keyColour3.key) {
                            const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                            for (const element of aiAnnotations) {
                                if (this.props.statusHideAllHighlighting === true) {
                                    element.style.background = keyColour3.backgroundColor
                                    element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                                } else if (this.props.statusHideAllHighlighting === false) {
                                    element.style.background = 'transparent'
                                    element.style.borderColor = 'initial'
                                }
                            }
                        }
                    })
                })
            })

            if (this.props.statusHideAllHighlighting === true) {
                this.setState({
                    showVisibleRegEx: null
                })
            } else {
                this.setState({
                    showVisibleRegEx: true
                })
            }
            this.setState({
                visibleRegExCat: true
            })

            const { treeDataAnnotationsRegExMixed } = this.props
            treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed[0].children.length > 0 && treeDataAnnotationsRegExMixed[0].children.forEach((keyColour) => {
                keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                    keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                        // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                        if (keyColour3 && keyColour3.key) {
                            const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                            for (const element of aiAnnotations) {
                                if (this.props.statusHideAllHighlighting === true) {
                                    element.style.background = keyColour3.backgroundColor
                                    element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                                } else if (this.props.statusHideAllHighlighting === false) {
                                    element.style.background = 'transparent'
                                    element.style.borderColor = 'initial'
                                }
                            }
                        }
                    })
                })
            })
        }
    }

    onGetRegEx = (regList) => {
        const childrenReg = []
        regList && regList.length > 0 && regList[0].children && regList[0].children.length > 0 && regList[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    childrenReg.push(child3)
                })
            })
        })
        // this.setState({
        //     getAllChildrenRegEx: childrenReg
        // })
    }

    onHoverCategory = (e, status) => {
        if (status === 'regex') {
            this.setState({
                visibleRedactRegEx: true,
                visibleRegExCat: true,
            })
        } else if (status === 'business') {
            this.setState({
                visibleRedactBusiness: true,
                visibleDictionaryCat: true,
            })
        }
    }
    onLeaveCategory = () => {
        const { showVisibleDictionary, showVisibleRegEx } = this.state
        if (showVisibleDictionary !== null) {
            this.setState({
                visibleDictionaryCat: true
            })
        } else {
            this.setState({
                visibleDictionaryCat: false
            })
        }
        if (showVisibleRegEx !== null) {
            this.setState({
                visibleRegExCat: true
            })
        } else {
            this.setState({
                visibleRegExCat: false
            })
        }
        this.setState({
            visibleRedactRegEx: false,
            visibleRedactBusiness: false,
        })
    }
    onRedactCategories = (e, status) => {
        const { treeBusiness, listCategories } = this.props
        const { getMixedChildrenRegEx, treeDataAnnotationsRegExMixed, regExListVersion } = this.props
        const { treeDataAnnotationsBusinessMixed, getMixedChildrenBusiness } = this.props
        const selectAllItemsRegEx = []
        const selectAllItemsRegExOcr = []
        const selectRedactionRegExOcr = []
        const selectAllItemsBusiness = []
        const selectAllItemsBusinessOcr = []
        const selectRedactionBusinessOcr = []
        const allWordsRedacted = []
        let allWordsRedactedOcr = []
        let allWordsRedactedMixed = []
        if (status === 'regex') {
            treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed.length > 0 && treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && treeDataAnnotationsRegExMixed[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    if (child2.children.length === 0 && child2.isOcr) {
                        child2.categoryName = 'Regular Expressions'
                        child2.subcategoryName = child.key
                        selectAllItemsRegExOcr.push(child2)
                        selectRedactionRegExOcr.push({
                            word: child2.key,
                            category: 'Regular Expressions',
                            subcategory: child.key,
                        })
                    }
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        selectAllItemsRegEx.push(child3)
                    })
                })
            })
            treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed.length > 0 && treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && treeDataAnnotationsRegExMixed[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    if (child2.children.length === 0 && child2.isOcr) {
                        allWordsRedactedOcr.push(child2)
                    }
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        child3.categoryName = 'Regular Expressions'
                        child3.subcategoryName = child.key
                        allWordsRedacted.push(child3)
                    })
                })
            })
            listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
                elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                    allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                        if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                            item.categoryColor = elem2.subcategoryColorWithAlpha
                        }
                    })
                })
            })
            allWordsRedactedMixed = allWordsRedacted.concat(allWordsRedactedOcr)
            const objectListRedact = {
                selectAllItemsStandard: [],
                selectAllItemsStandardOcr: [],
                selectAllItemsBusiness: selectAllItemsBusiness,
                selectAllItemsBusinessOcr: selectAllItemsBusinessOcr,
                selectAllItemsRegEx: selectAllItemsRegEx,
                selectAllItemsRegExOcr: selectAllItemsRegExOcr,
                allWordsRedacted: allWordsRedacted,
                allWordsRedactedOcr: allWordsRedactedOcr,
                allWordsRedactedMixed: allWordsRedactedMixed,
                selectRedactionStandardOcr: [],
                selectRedactionBusinessOcr: selectRedactionBusinessOcr,
                selectRedactionRegExOcr: selectRedactionRegExOcr,
            }
            if (treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed.length > 0 && treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0) {
                this.onRedactAiAllCategoriesCopy(selectAllItemsRegEx, allWordsRedactedMixed, status, objectListRedact)
            }
        } else if (status === 'business') {
            treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed.length > 0 && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 && treeDataAnnotationsBusinessMixed[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    if (child2.children.length === 0 && child2.isOcr) {
                        child2.categoryName = 'Dictionaries'
                        child2.subcategoryName = child.key
                        selectAllItemsBusinessOcr.push(child2)
                        selectRedactionBusinessOcr.push({
                            word: child2.key,
                            category: 'Dictionaries',
                            subcategory: child.key,
                        })
                    }
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        selectAllItemsBusiness.push(child3)
                    })
                })
            })
            treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed.length > 0 && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 && treeDataAnnotationsBusinessMixed[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    if (child2.children.length === 0 && child2.isOcr) {
                        allWordsRedactedOcr.push(child2)
                    }
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        child3.categoryName = 'Dictionaries'
                        child3.subcategoryName = child.key
                        allWordsRedacted.push(child3)
                    })
                })
            })
            listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
                elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                    allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                        if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                            item.categoryColor = elem2.subcategoryColorWithAlpha
                        }
                    })
                })
            })
            allWordsRedactedMixed = allWordsRedacted.concat(allWordsRedactedOcr)
            const objectListRedact = {
                selectAllItemsStandard: [],
                selectAllItemsStandardOcr: [],
                selectAllItemsBusiness: selectAllItemsBusiness,
                selectAllItemsBusinessOcr: selectAllItemsBusinessOcr,
                selectAllItemsRegEx: selectAllItemsRegEx,
                selectAllItemsRegExOcr: selectAllItemsRegExOcr,
                allWordsRedacted: allWordsRedacted,
                allWordsRedactedOcr: allWordsRedactedOcr,
                allWordsRedactedMixed: allWordsRedactedMixed,
                selectRedactionStandardOcr: [],
                selectRedactionBusinessOcr: selectRedactionBusinessOcr,
                selectRedactionRegExOcr: selectRedactionRegExOcr,
            }
            if (treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed.length > 0 && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0) {
                this.onRedactAiAllCategoriesCopy(selectAllItemsBusiness, allWordsRedactedMixed, status, objectListRedact)
            }
            // if (treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed.length > 0 && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 && getMixedChildrenBusiness.length > 0) {
            //     this.onRedactAiAllCategoriesCopy(selectAllItemsBusiness, status, allWordsRedacted)
            // }
        }
    }

    onRedactAiAllCategoriesCopy = (classList, allWordsRedactedMixed, status, objectListRedact) => {
        const sortedByPageItems = classList.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
        const sortedByPageItemsMixed = allWordsRedactedMixed.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
        this.drawMatchedAllWordsCopy(sortedByPageItems.map((item) => item.key), sortedByPageItemsMixed.map((item) => item.key), status, objectListRedact)
    }

    drawMatchedAllWordsCopy = (classList, classListMixed, status, objectListRedact) => {
        const { treeRegEx, treeBusiness, alreadyRedactedKeys } = this.props
        const { getMixedChildrenRegEx, getMixedChildrenBusiness } = this.props
        //console.log('classListMixed KKKKKKKKKKKKKKKKKKKKKKKKKKKK', classListMixed)
        let unCheckedKeysRegEx = []
        let unCheckedKeysBusiness = []
        let textMessageRedact = ''
        let alreadyRedactedKeysBusinessMixed = []
        alreadyRedactedKeysBusinessMixed = alreadyRedactedKeys.business.concat(alreadyRedactedKeys.businessOCR)
        let alreadyRedactedKeysRegExMixed = []
        alreadyRedactedKeysRegExMixed = alreadyRedactedKeys.regex.concat(alreadyRedactedKeys.regexOCR)
        if (status === 'regex') {
            if (alreadyRedactedKeysRegExMixed && alreadyRedactedKeysRegExMixed.length > 0 && alreadyRedactedKeysRegExMixed.length === this.onGetAllChildrenRegularExpressionsMixed().length) {
                textMessageRedact = classList && classList.length && `Remove ${ classList.length } selected redactions?`
                unCheckedKeysRegEx = classList
            } else {
                textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
            }
        } else if (status === 'business') {
            if (alreadyRedactedKeysBusinessMixed && alreadyRedactedKeysBusinessMixed.length > 0 && alreadyRedactedKeysBusinessMixed.length === this.onGetAllChildrenBusinessMixed().length) {
                textMessageRedact = classList && classList.length && `Remove ${ classList.length } selected redactions?`
                unCheckedKeysBusiness = classList
                //alreadyRedactedKeys.business = []
            } else {
                textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
            }
        }
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: status === 'business' && unCheckedKeysBusiness.length > 0 ? 'Unredact' : status === 'regex' && unCheckedKeysRegEx.length > 0 ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                if (status === 'regex') {
                    if (alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getMixedChildrenRegEx.length) {
                        this.setState({
                            removeAllRegEx: true,
                            statusRemoveAllRegEx: true
                        })
                    } else {
                        this.onUpdateRedactionNumber(classList)
                        this.setState({
                            removeAllRegEx: false,
                            statusRemoveAllRegEx: false
                        })
                    }
                } else if (status === 'business') {
                    if ((alreadyRedactedKeys && alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getMixedChildrenBusiness.length) ||
                        (alreadyRedactedKeys && alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.length === getMixedChildrenBusiness.length)) {
                        this.setState({
                            removeAllDictionaries: true
                        })
                    } else {
                        this.setState({
                            removeAllDictionaries: false
                        })
                        this.onUpdateRedactionNumber(classList)
                    }
                }
                this.setState({
                    redactedKeys: []
                }, () => {
                    if (treeRegEx && treeRegEx.length > 0 && status === 'regex') {
                        //de decomentat
                        this.onSaveQueryStatusMixed(objectListRedact)
                        if (unCheckedKeysRegEx.length > 0) {
                            this.props.drawRedactAllMatchedWordsMixed([], classListMixed, unCheckedKeysRegEx, 'regex', objectListRedact)
                        } else {
                            this.props.drawRedactAllMatchedWordsMixed(classList, classListMixed, unCheckedKeysRegEx, 'regex', objectListRedact)
                        }
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllRegEx: classListMixed,
                            checkedChildRegEx: classListMixed,
                        })
                    } else if (treeBusiness && treeBusiness.length > 0 && status === 'business') {
                        //de decomentat
                        this.onSaveQueryStatusMixed(objectListRedact)
                        if (unCheckedKeysBusiness.length > 0) {
                            this.props.drawRedactAllMatchedWordsMixed([], classListMixed, unCheckedKeysBusiness, 'business', objectListRedact)
                        } else {
                            this.props.drawRedactAllMatchedWordsMixed(classList, classListMixed, unCheckedKeysBusiness, 'business', objectListRedact)
                        }
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllBusiness: classListMixed,
                            checkedChildBusiness: classListMixed,
                            startBusinessRedact: true
                        })
                    }
                })
            }
        })
    }
    onSaveQueryStatusMixed = (objectListRedact) => {
        const keyListWordNonOcr = []
        let keyListWordNonOcrRegex = []
        let keyListWordNonOcrDictionary = []
        const keyListWordOcr = []
        let keyListWordOcrRegex = []
        let keyListWordOcrDictionary = []
        let alreadyRedactedKeysBusinessMixed = []
        alreadyRedactedKeysBusinessMixed = this.props.alreadyRedactedKeys.business.concat(this.props.alreadyRedactedKeys.businessOCR)
        let alreadyRedactedKeysRegExMixed = []
        alreadyRedactedKeysRegExMixed = this.props.alreadyRedactedKeys.regex.concat(this.props.alreadyRedactedKeys.regexOCR)
        if (objectListRedact.selectAllItemsRegEx && objectListRedact.selectAllItemsRegEx.length > 0) {
            if (alreadyRedactedKeysRegExMixed && alreadyRedactedKeysRegExMixed.length > 0 && alreadyRedactedKeysRegExMixed.length === this.onGetAllChildrenRegularExpressionsMixed().length) {
                keyListWordNonOcrRegex = []
                keyListWordNonOcrDictionary = this.props.alreadyRedactedKeys.business
                keyListWordOcrDictionary = this.props.alreadyRedactedKeys.businessOCR
            } else {
                objectListRedact.selectAllItemsRegEx.forEach((regEx) => {
                    //keyListWordNonOcrRegex.push(`${ regEx.key } => ${ regEx.name } => ${ regEx.pageNumber }`)
                    keyListWordNonOcrRegex.push(regEx.keyMixtRedaction)
                })
                keyListWordNonOcrDictionary = this.props.alreadyRedactedKeys.business
                keyListWordOcrDictionary = this.props.alreadyRedactedKeys.businessOCR
            }
        }
        if (objectListRedact.selectAllItemsRegExOcr && objectListRedact.selectAllItemsRegExOcr.length > 0) {
            if (alreadyRedactedKeysRegExMixed && alreadyRedactedKeysRegExMixed.length > 0 && alreadyRedactedKeysRegExMixed.length === this.onGetAllChildrenRegularExpressionsMixed().length) {
                keyListWordOcrRegex = []
                keyListWordNonOcrDictionary = this.props.alreadyRedactedKeys.business
                keyListWordOcrDictionary = this.props.alreadyRedactedKeys.businessOCR
            } else {
                objectListRedact.selectAllItemsRegExOcr.forEach((regEx) => {
                    keyListWordOcrRegex.push(regEx.name)
                })
                keyListWordNonOcrDictionary = this.props.alreadyRedactedKeys.business
                keyListWordOcrDictionary = this.props.alreadyRedactedKeys.businessOCR
            }
        }
        if (objectListRedact.selectAllItemsBusiness && objectListRedact.selectAllItemsBusiness.length > 0) {
            if (alreadyRedactedKeysBusinessMixed && alreadyRedactedKeysBusinessMixed.length > 0 && alreadyRedactedKeysBusinessMixed.length === this.onGetAllChildrenBusinessMixed().length) {
                keyListWordNonOcrDictionary = []
                keyListWordOcrRegex = this.props.alreadyRedactedKeys.regexOCR
                keyListWordNonOcrRegex = this.props.alreadyRedactedKeys.regex
            } else {
                objectListRedact.selectAllItemsBusiness.forEach((dict) => {
                    //keyListWordNonOcrDictionary.push(`${ dict.key } => ${ dict.name } => ${ dict.pageNumber }`)
                    keyListWordNonOcrDictionary.push(dict.keyMixtRedaction)
                })
                keyListWordOcrRegex = this.props.alreadyRedactedKeys.regexOCR
                keyListWordNonOcrRegex = this.props.alreadyRedactedKeys.regex
            }
        }
        if (objectListRedact.selectAllItemsBusinessOcr && objectListRedact.selectAllItemsBusinessOcr.length > 0) {
            if (alreadyRedactedKeysBusinessMixed && alreadyRedactedKeysBusinessMixed.length > 0 && alreadyRedactedKeysBusinessMixed.length === this.onGetAllChildrenBusinessMixed().length) {
                keyListWordOcrDictionary = []
                keyListWordOcrRegex = this.props.alreadyRedactedKeys.regexOCR
                keyListWordNonOcrRegex = this.props.alreadyRedactedKeys.regex
            } else {
                objectListRedact.selectAllItemsBusinessOcr.forEach((dict) => {
                    keyListWordOcrDictionary.push(dict.name)
                })
                keyListWordOcrRegex = this.props.alreadyRedactedKeys.regexOCR
                keyListWordNonOcrRegex = this.props.alreadyRedactedKeys.regex
            }
        }
        let updateStatus = false
        if ((keyListWordNonOcrRegex && keyListWordNonOcrRegex.length > 0) || (keyListWordNonOcrDictionary && keyListWordNonOcrDictionary.length > 0)) {
            updateStatus = true
        } else {
            updateStatus = false
        }
        const saveAnnotationWithStateDTO = {
            keyWordOcr: this.props.alreadyRedactedKeys.standardOCR,
            keyWordOcrRegex: keyListWordOcrRegex,
            keyWordOcrDictionary: keyListWordOcrDictionary,
            keyWordNonOcr: this.props.alreadyRedactedKeys.standard,
            keyWordNonOcrRegex: keyListWordNonOcrRegex,
            keyWordNonOcrDictionary: keyListWordNonOcrDictionary,
            updateRedactions: updateStatus,
            idDocument: this.props.idDocument
        }
        apiSmartBoxSaveQueryStatusMixed(saveAnnotationWithStateDTO).then((response) => {
            this.props.onGetRedactedKeysMixed()
        })
    }
    onRedactAiAllCategories = (classList, status, allWordsRedacted) => {
        //if (classList && classList.length > 0) {
        const sortedByPageItems = classList.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
        this.drawMatchedAllWords(sortedByPageItems.map((item) => item.key), status, allWordsRedacted)
        //}
    }
    drawMatchedAllWords = (classList, status, allWordsRedacted) => {
        const { treeRegEx, treeBusiness, alreadyRedactedKeys } = this.props
        const { getMixedChildrenRegEx, getMixedChildrenBusiness } = this.props
        let unCheckedKeysRegEx = []
        let unCheckedKeysBusiness = []
        let textMessageRedact = ''
        if (status === 'regex') {
            if (alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getMixedChildrenRegEx.length) {
                textMessageRedact = classList && classList.length && `Remove ${ classList.length } selected redactions?`
                unCheckedKeysRegEx = classList
            } else {
                textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
            }
        } else if (status === 'business') {
            if ((alreadyRedactedKeys && alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getMixedChildrenBusiness.length) ||
                (alreadyRedactedKeys && alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.length === getMixedChildrenBusiness.length)) {
                textMessageRedact = classList && classList.length && `Remove ${ classList.length } selected redactions?`
                unCheckedKeysBusiness = classList
                //alreadyRedactedKeys.business = []
            } else {
                textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
            }
        }
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: status === 'business' && unCheckedKeysBusiness.length > 0 ? 'Unredact' : status === 'regex' && unCheckedKeysRegEx.length > 0 ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                if (status === 'regex') {
                    if (alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getMixedChildrenRegEx.length) {
                        this.setState({
                            removeAllRegEx: true,
                            statusRemoveAllRegEx: true
                        })
                    } else {
                        this.onUpdateRedactionNumber(classList)
                        this.setState({
                            removeAllRegEx: false,
                            statusRemoveAllRegEx: false
                        })
                    }
                } else if (status === 'business') {
                    if ((alreadyRedactedKeys && alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getMixedChildrenBusiness.length) ||
                        (alreadyRedactedKeys && alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.length === getMixedChildrenBusiness.length)) {
                        this.setState({
                            removeAllDictionaries: true
                        })
                    } else {
                        this.setState({
                            removeAllDictionaries: false
                        })
                        this.onUpdateRedactionNumber(classList)
                    }
                }
                this.setState({
                    redactedKeys: []
                }, () => {
                    if (treeRegEx && treeRegEx.length > 0 && status === 'regex') {
                        if (unCheckedKeysRegEx.length > 0) {
                            this.props.drawAllMatchedWordsByClassList([], unCheckedKeysRegEx, 'regex', allWordsRedacted)
                        } else {
                            this.props.drawAllMatchedWordsByClassList(classList, unCheckedKeysRegEx, 'regex', allWordsRedacted)
                        }
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllRegEx: classList,
                            checkedChildRegEx: classList,
                        })
                    } else if (treeBusiness && treeBusiness.length > 0 && status === 'business') {
                        if (unCheckedKeysBusiness.length > 0) {
                            this.props.drawAllMatchedWordsByClassList([], unCheckedKeysBusiness, 'dictionaries', allWordsRedacted)
                        } else {
                            this.props.drawAllMatchedWordsByClassList(classList, unCheckedKeysBusiness, 'dictionaries', allWordsRedacted)
                        }
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllBusiness: classList,
                            checkedChildBusiness: classList,
                            startBusinessRedact: true
                        })
                    }
                })
            }
        })
    }
    onStatusRemoveRedaction = (status) => {
        this.setState({
            removeAllRegEx: status
        })
    }
    onStatusRemoveDictionaries = (status) => {
        this.setState({
            removeAllDictionaries: status
        })
    }
    onUnCheckedKeysRegEx = (unCheckedKeysRegEx) => {
        this.setState({
            unCheckedKeysRegEx2: unCheckedKeysRegEx
        })
    }

    onUpdateRedactionNumber = (classList) => {
        const data = {
            doc_id: this.props.idDocument,
            box_number_words_redacted: classList.length
        }
        apiSmartBoxUpdateRedactionNumber(data).then((response) => {

        })
    }
    onShowVisibleDictionary = (e, status) => {
        const category = 'Dictionary'
        const item = {
            children: [{categoryNameUpdate: 'Dictionary'}]
        }
        if (status === false) {
            this.setState({
                showVisibleDictionary: null
            })
            if (this.props.isOcr) {
                this.props.showHideCategoryOcr(e, category, true, item)
            }
        } else {
            this.setState({
                showVisibleDictionary: true
            })
            if (this.props.isOcr) {
                this.props.showHideCategoryOcr(e, category, false, item)
            }
        }
        this.setState({
            //showVisibleDictionary: status,
            visibleDictionaryCat: true,
        })
        this.onShowVisibleCatDictionary(status)
    }
    onShowVisibleCatDictionary = (showVisibleDictionary) => {
        const { treeDataAnnotationsBusinessMixed } = this.props
        treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed[0].children.length > 0 && treeDataAnnotationsBusinessMixed[0].children.forEach((keyColour) => {
            keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                    // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                    if (keyColour3 && keyColour3.key && showVisibleDictionary !== null) {
                        const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                        for (const element of aiAnnotations) {
                            if (showVisibleDictionary === false) {
                                this.props.onChangeStatusIconHideAllHighlighting()
                                element.style.background = keyColour3.backgroundColor
                                element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                            } else if (showVisibleDictionary === true) {
                                element.style.background = 'transparent'
                                element.style.borderColor = 'initial'
                            }
                        }
                    }
                })
            })
        })
    }
    onShowVisibleRegEx = (e, status) => {
        const category = 'RegEx'
        const item = {
            children: [{categoryNameUpdate: 'RegEx'}]
        }
        if (status === false) {
            this.setState({
                showVisibleRegEx: null
            })
            if (this.props.isOcr) {
                this.props.showHideCategoryOcr(e, category, true, item)
            }
        } else {
            this.setState({
                showVisibleRegEx: true
            })
            if (this.props.isOcr) {
                this.props.showHideCategoryOcr(e, category, false, item)
            }
        }
        this.setState({
            //showVisibleRegEx: status,
            visibleRegExCat: true
        })
        this.onShowVisibleCatRegEx(status)
    }
    onShowVisibleCatRegEx = (showVisibleDictionary) => {
        const { treeDataAnnotationsRegExMixed } = this.props
        treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed[0].children.length > 0 && treeDataAnnotationsRegExMixed[0].children.forEach((keyColour) => {
            keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                    // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                    if (keyColour3 && keyColour3.key && showVisibleDictionary !== null) {
                        const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                        for (const element of aiAnnotations) {
                            if (showVisibleDictionary === false) {
                                this.props.onChangeStatusIconHideAllHighlighting()
                                element.style.background = keyColour3.backgroundColor
                                element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                            } else if (showVisibleDictionary === true) {
                                element.style.background = 'transparent'
                                element.style.borderColor = 'initial'
                            }
                        }
                    }
                })
            })
        })
    }
    onGetAllChildrenBusinessMixed = () => {
        const { treeDataAnnotationsBusinessMixed, alreadyRedactedKeys } = this.props
        const getAllChildrenBusinessMixedNonOcr = []
        const getAllChildrenBusinessMixedOcr = []
        let getAllChildrenBusinessMixedFinally = []
        if (treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 ) {
            treeDataAnnotationsBusinessMixed[0].children.forEach((children1) => {
                children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                    if (children2.isOcr) {
                        getAllChildrenBusinessMixedOcr.push(children2)
                    }
                    children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                        getAllChildrenBusinessMixedNonOcr.push(children3)
                    })
                })
            })
        }
        getAllChildrenBusinessMixedFinally = getAllChildrenBusinessMixedNonOcr.concat(getAllChildrenBusinessMixedOcr)
        return getAllChildrenBusinessMixedFinally
    }

    onGetAllChildrenRegularExpressionsMixed = () => {
        const { treeDataAnnotationsRegExMixed, alreadyRedactedKeys } = this.props
        const getAllChildrenRegExMixedNonOcr = []
        const getAllChildrenRegExMixedOcr = []
        let getAllChildrenRegExMixedFinally = []
        if (treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 ) {
            treeDataAnnotationsRegExMixed[0].children.forEach((children1) => {
                children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                    if (children2.isOcr) {
                        getAllChildrenRegExMixedOcr.push(children2)
                    }
                    children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                        getAllChildrenRegExMixedNonOcr.push(children3)
                    })
                })
            })
        }
        getAllChildrenRegExMixedFinally = getAllChildrenRegExMixedNonOcr.concat(getAllChildrenRegExMixedOcr)
        return getAllChildrenRegExMixedFinally
    }

    render() {
        const { activeKeyTab, visibleRegExEmails, visibleBosTerms, visibleRedactRegEx,
            visibleRedactBusiness, checkedChildRegEx, checkedChildBusiness, startBusinessRedact, showVisibleDictionary, visibleDictionaryCat,
            showVisibleRegEx, visibleRegExCat, removeAllRegEx, removeAllDictionaries, statusRemoveAllRegEx, visibleTrueDict } = this.state
        const { alreadyRedactedKeys, treeDataAnnotationsBusinessMixed, treeDataAnnotationsRegExMixed } = this.props
        const { getMixedChildrenRegEx, getMixedChildrenBusiness, getAllChildrenBusinessParent, getAllChildrenRegExParent } = this.props
        if (alreadyRedactedKeys && alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
            alreadyRedactedKeys.dictionaries = alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.filter((a, i) => alreadyRedactedKeys.dictionaries.findIndex((s) => a === s) === i)
        }
        let alreadyRedactedKeysBusinessMixed = []
        alreadyRedactedKeysBusinessMixed = this.props.alreadyRedactedKeys.business.concat(this.props.alreadyRedactedKeys.businessOCR)
        let alreadyRedactedKeysRegExMixed = []
        alreadyRedactedKeysRegExMixed = alreadyRedactedKeys.regex.concat(alreadyRedactedKeys.regexOCR)
           // console.log('this.props --- render MANUAL TAB', this.props)
           // console.log('this.state --- render MANUAL TAB', this.state)
           // console.log('checkedChildBusiness --- render MANUAL TAB', checkedChildBusiness)
           // console.log('treeDataAnnotationsBusinessMixed --- render MANUAL TAB', treeDataAnnotationsBusinessMixed)
           // console.log('alreadyRedactedKeys --- render MANUAL TAB', alreadyRedactedKeys)
            //console.log('onGetAllChildrenBusinessMixed --- render MANUAL TAB', this.onGetAllChildrenBusinessMixed())
            //console.log('this.onGetAllChildrenRegularExpressionsMixed() --- render MANUAL TAB', this.onGetAllChildrenRegularExpressionsMixed())
        return (
            <div className="update-ai-results-manual">
                <div className="component-box-terms">
              <span className={ treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length === 0 ? "tab-update-box-terms-empty tab-update-business-redact" :
                  getMixedChildrenBusiness.length === 0  ? "tab-update-box-terms-empty tab-update-business-redact" :
                      visibleRedactBusiness ? 'tab-update-box-terms tab-update-business-redact' :
                          startBusinessRedact ? 'tab-update-box-terms tab-update-business-redact' :
                              alreadyRedactedKeysBusinessMixed && alreadyRedactedKeysBusinessMixed.length > 0 && alreadyRedactedKeysBusinessMixed.length === this.onGetAllChildrenBusinessMixed().length ? 'tab-update-box-terms tab-update-business-redact' :  'tab-update-box-terms tab-update-business-redact' }
                    onMouseLeave={() => this.onLeaveCategory()}
                    onMouseEnter={(e) => this.onHoverCategory(e, 'business')}>
                  <span className="content-dictionary-terms">
                       <span className={ visibleRedactBusiness && getMixedChildrenBusiness && getMixedChildrenBusiness.length > 0 ? 'icon-v17-redaction-hover' :
                           //startBusinessRedact && !removeAllDictionaries ? 'icon-v17-redaction' :
                           alreadyRedactedKeysBusinessMixed && alreadyRedactedKeysBusinessMixed.length > 0 && alreadyRedactedKeysBusinessMixed.length === this.onGetAllChildrenBusinessMixed().length && !removeAllDictionaries ? 'icon-v17-redaction' :
                               alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getAllChildrenBusinessParent.length && !removeAllDictionaries ? 'icon-v17-redaction' :
                                   treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length === 0 ? 'icon-empty-redaction-disabled' : 'icon-v17-redaction-hover space-empty' }
                             onClick={(e) => this.onRedactCategories(e, 'business')}></span>
                      <span className="title-box-terms" onClick={() => this.onDisplaySelectComponentAi('box-terms')}>Dictionaries</span>
                      {treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed.length > 0 && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 && getMixedChildrenBusiness && getMixedChildrenBusiness.length > 0 &&
                      <React.Fragment>
                          {
                              visibleBosTerms ?
                                  <span className="icon-v21-arrow-bold" onClick={() => this.onDisplaySelectComponentAi('box-terms')}/> :
                                  <span className="icon-v21-arrow-bold rotate-arrow-bold" onClick={() => this.onDisplaySelectComponentAi('box-terms')}/>
                          }
                      </React.Fragment>
                      }
                  </span>
                  { treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed.length > 0 && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 && getMixedChildrenBusiness && getMixedChildrenBusiness.length > 0 &&
                  <React.Fragment>
                      { visibleDictionaryCat &&
                      <span className="content-visible-category">
                                  { !showVisibleDictionary ?
                                      <span className="icon-v12-visible" aria-hidden="true"
                                            onClick={(e) => this.onShowVisibleDictionary(e, true)}/> :
                                      <span className="icon-v12-unvisible" aria-hidden="true"
                                            onClick={(e) => this.onShowVisibleDictionary(e, false)}/>
                                  }
                              </span>
                      }
                  </React.Fragment>
                  }
              </span>
                    { visibleTrueDict &&
                    <BusinessCopy
                        idDocument={ this.props.isNextDocumentId.id ? this.props.isNextDocumentId.id : this.props.idDocument }
                        isSmartBoxApplication={ this.props.isSmartBoxApplication }
                        pageNumber={ this.props.pageNumber }
                        landscape={ this.props.landscape }
                        changePage={ this.props.changePage }
                        drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}
                        toggleLoadingProcess = { this.props.toggleLoadingProcess }
                        activeKeyTab = { activeKeyTab }
                        activeKey = { this.props.activeKey }
                        isDocumentReady={ this.props.isDocumentReady }
                        newListKeysBusiness={ this.props.newListKeysBusiness }
                        checkOnClearAllBusiness={ this.props.checkOnClearAllBusiness }
                        verificationOnClickClearAllBusiness={ this.props.verificationOnClickClearAllBusiness }
                        onCheckedKeysObjectRefsBoxTerms={ this.props.onCheckedKeysObjectRefsBoxTerms }
                        isNextDocumentId={ this.props.isNextDocumentId }
                        allChildrenTreeBusiness={ this.props.allChildrenTreeBusiness }
                        checkedKeysAllBusiness={ this.props.checkedKeysAllBusiness }
                        updateRedactAllStatus={ this.props.updateRedactAllStatus }
                        onDocAnnotationsList={ this.props.onDocAnnotationsList }
                        isOcr = { this.props.isOcr }
                        fetchSmartBoxDataCanvasOcr = { this.props.fetchSmartBoxDataCanvasOcr }
                        alreadyRedactedKeys={this.props.alreadyRedactedKeys}
                        checkedChildBusiness={ checkedChildBusiness }
                        showVisibleDictionary={ showVisibleDictionary }
                        setTreeDataDict = { this.props.setTreeDataDict }
                        onStatusRemoveDictionaries = { this.onStatusRemoveDictionaries }
                        removeAllDictionaries = { removeAllDictionaries }
                        getMixedChildrenBusiness = { getMixedChildrenBusiness }
                        getStatusElementRedaction={ this.props.getStatusElementRedaction }
                        docAnnotationsBusiness={ this.props.docAnnotationsBusiness }
                        docAnnotationsBusinessOcr={ this.props.docAnnotationsBusinessOcr }
                        categoryColoursBusiness={ this.props.categoryColoursBusiness }
                        categoryColoursBusinessOcr={ this.props.categoryColoursBusinessOcr }
                        treeDataAnnotationsBusiness={ this.props.treeDataAnnotationsBusiness }
                        statusNextDocumentBusiness={ this.props.statusNextDocumentBusiness }
                        onGetStatusNextDocumentBusiness={ this.props.onGetStatusNextDocumentBusiness }
                        clientId={this.props.clientId}
                        lockSmartViewChanges={ this.props.lockSmartViewChanges }
                        listCategories={ this.props.listCategories }
                        treeDataAnnotationsBusinessMixed={ this.props.treeDataAnnotationsBusinessMixed }
                        drawRedactAllMatchedWordsMixed={ this.props.drawRedactAllMatchedWordsMixed }
                        startDrawRedactMatchedWordsMixed={ this.props.startDrawRedactMatchedWordsMixed }
                        onGetRedactedKeysMixed={ this.props.onGetRedactedKeysMixed }
                        onCheckedKeysAllMixedBusiness={ this.props.onCheckedKeysAllMixedBusiness }
                        //onGetDictionaryOcrUpdate={ this.props.onGetDictionaryOcrUpdate }
                    >

                    </BusinessCopy>
                    }
                </div>
                <div className="component-reg-ex-emails">
              <span className={ treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length === 0 ? "tab-update-reg-ex-empty tab-update-reg-ex-redact" :
                  treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getMixedChildrenRegEx.length && !removeAllRegEx ? "tab-update-reg-ex tab-update-reg-ex-new-redact" :
                      treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && statusRemoveAllRegEx === false ? "tab-update-reg-ex tab-update-reg-ex-new-redact" :
                          treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && !visibleRedactRegEx && getMixedChildrenRegEx.length > 0 ? "tab-update-reg-ex tab-update-reg-ex-redact" :
                              treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && visibleRedactRegEx && getMixedChildrenRegEx.length > 0 ? 'tab-update-reg-ex tab-update-reg-ex-redact' :
                                  alreadyRedactedKeysRegExMixed && alreadyRedactedKeysRegExMixed.length > 0 && alreadyRedactedKeysRegExMixed.length === this.onGetAllChildrenRegularExpressionsMixed().length ? 'tab-update-reg-ex tab-update-reg-ex-redact' :
                                  alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getMixedChildrenRegEx.length ? 'tab-update-reg-ex tab-update-reg-ex-redact' :
                                      alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegExParent.length ? 'tab-update-reg-ex tab-update-reg-ex-redact' :
                                          'tab-update-reg-ex-empty tab-update-reg-ex-redact' }
                    onMouseLeave={() => this.onLeaveCategory()}
                    onMouseEnter={(e) => this.onHoverCategory(e, 'regex')}>
                  <span className="content-reg-terms">
                   <span className={ visibleRedactRegEx && getMixedChildrenRegEx && getMixedChildrenRegEx.length > 0 ? 'icon-v17-redaction-hover' :
                       alreadyRedactedKeysRegExMixed && alreadyRedactedKeysRegExMixed.length > 0 && alreadyRedactedKeysRegExMixed.length === this.onGetAllChildrenRegularExpressionsMixed().length && !removeAllRegEx ? 'icon-v17-redaction' :
                       // alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getMixedChildrenRegEx.length && !removeAllRegEx ? 'icon-v17-redaction' :
                       //     alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegExParent.length && !removeAllRegEx ? 'icon-v17-redaction' :
                              // statusRemoveAllRegEx === false ? 'icon-v17-redaction' :
                           treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length === 0 ? 'icon-empty-redaction-disabled' :
                               this.onGetAllChildrenRegularExpressionsMixed() && this.onGetAllChildrenRegularExpressionsMixed().length === 0 ? 'icon-empty-redaction-disabled' : 'icon-v17-redaction-hover space-empty' }
                         onClick={(e) => this.onRedactCategories(e, 'regex')}></span>
                  <span className="title-regEx" onClick={() => this.onDisplaySelectComponentAi('reg-ex')}>Regular Expressions</span>
                      { treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && getMixedChildrenRegEx && getMixedChildrenRegEx.length > 0 &&
                      <React.Fragment>
                          {
                              visibleRegExEmails ?
                                  <span className="icon-v21-arrow-bold" onClick={() => this.onDisplaySelectComponentAi('reg-ex')}/> :
                                  <span className="icon-v21-arrow-bold rotate-arrow-bold" onClick={() => this.onDisplaySelectComponentAi('reg-ex')}/>
                          }
                      </React.Fragment>
                      }
                  </span>
                  { treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && getMixedChildrenRegEx && getMixedChildrenRegEx.length > 0 &&
                  <React.Fragment>
                      { visibleRegExCat &&
                      <span className="content-visible-category">
                                  { !showVisibleRegEx ?
                                      <span className="icon-v12-visible" aria-hidden="true"
                                            onClick={(e) => this.onShowVisibleRegEx(e, true)}/> :
                                      <span className="icon-v12-unvisible" aria-hidden="true"
                                            onClick={(e) => this.onShowVisibleRegEx(e, false)}/>
                                  }
                              </span>
                      }
                  </React.Fragment>
                  }
              </span>
                    { visibleRegExEmails &&
                    <RegularExpressionsCopy
                        idDocument={ this.props.isNextDocumentId.id ? this.props.isNextDocumentId.id : this.props.idDocument }
                        isDocumentReady={ this.props.isDocumentReady }
                        documentId={ this.props.idDocument }
                        isSmartBoxApplication={ this.props.isSmartBoxApplication }
                        landscape={ this.props.landscape }
                        changePage={ this.props.changePage }
                        pageNumber={ this.props.pageNumber }
                        toggleLoadingProcess = { this.props.toggleLoadingProcess }
                        activeKeyTab = { activeKeyTab }
                        activeKey = { this.props.activeKey }
                        drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}
                        newListKeysRegEx={ this.props.newListKeysRegEx }
                        checkOnClearAllRegEx={ this.props.checkOnClearAllRegEx }
                        verificationOnClickClearAllRegEx={ this.props.verificationOnClickClearAllRegEx }
                        onCheckedKeysObjectRefsRegEx={ this.props.onCheckedKeysObjectRefsRegEx }
                        isNextDocumentId={ this.props.isNextDocumentId }
                        allChildrenTreeRegEx={ this.props.allChildrenTreeRegEx }
                        checkedKeysAllRegEx={ this.props.checkedKeysAllRegEx }
                        updateRedactAllStatus={ this.props.updateRedactAllStatus }
                        onDocAnnotationsRegEx={ this.props.onDocAnnotationsRegEx }
                        isOcr = { this.props.isOcr }
                        fetchSmartBoxDataCanvasOcr = { this.props.fetchSmartBoxDataCanvasOcr }
                        alreadyRedactedKeys={this.props.alreadyRedactedKeys}
                        onGetRegEx={ this.onGetRegEx }
                        checkedChildRegEx={ checkedChildRegEx }
                        showVisibleRegEx={ showVisibleRegEx }
                        setTreeDataRegEx = { this.props.setTreeDataRegEx }
                        onUnCheckedKeysRegEx={ this.onUnCheckedKeysRegEx }
                        removeAllRegEx={ removeAllRegEx }
                        onStatusRemoveRedaction={ this.onStatusRemoveRedaction }
                        getStatusElementRedaction={ this.props.getStatusElementRedaction }
                        docAnnotationsRegularExp={ this.props.docAnnotationsRegularExp }
                        docAnnotationsRegularExpOcr={ this.props.docAnnotationsRegularExpOcr }
                        categoryColoursRegularExp={ this.props.categoryColoursRegularExp }
                        treeDataAnnotationsRegEx={ this.props.treeDataAnnotationsRegEx }
                        statusNextDocumentRegEx={ this.props.statusNextDocumentRegEx }
                        onGetStatusNextDocumentRegEx={ this.props.onGetStatusNextDocumentRegEx }
                        getMixedChildrenRegEx={ getMixedChildrenRegEx }
                        clientId={this.props.clientId}
                        lockSmartViewChanges={ this.props.lockSmartViewChanges }
                        listCategories={ this.props.listCategories }
                        treeDataAnnotationsRegExMixed={ this.props.treeDataAnnotationsRegExMixed }
                        categoryColoursRegularExpOcr={ this.props.categoryColoursRegularExpOcr }
                        drawRedactAllMatchedWordsMixed={ this.props.drawRedactAllMatchedWordsMixed }
                        startDrawRedactMatchedWordsMixed={ this.props.startDrawRedactMatchedWordsMixed }
                        onGetRedactedKeysMixed={ this.props.onGetRedactedKeysMixed }
                        onCheckedKeysAllMixedRegEx={ this.props.onCheckedKeysAllMixedRegEx }
                    >
                    </RegularExpressionsCopy>
                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    userId: state.userReducer.user.id
})

ManualTabCopy.propTypes = {
    clientId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    isDocumentReady: PropTypes.bool.isRequired,
    hideLoadingBeforeMark: PropTypes.func,
    toggleLoadingProcess: PropTypes.func
}

export default connect(mapStateToProps)(ManualTabCopy)