import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import SubjectsListArchive from './SubjectsListArchive'
import CardList from './CardList'
import LineChart from './LineChart'
import HeaderTicket from './HeaderTicket'
import HeaderWorkflowStep from './HeaderWorkflowStep'
// import Button from '../../../../Common/Button'
import { apiFetchByFilter, apiSarDeleteRequest } from '../../../../../api/Sar'
import '../../../../../style/common.scss'
import './Dashboard.scss'
import { connect } from 'react-redux'
import { Checkbox, Input, Select, Tooltip, Button } from 'antd'
import { customConfirm } from '../../../../../utils/AssetRegister/CustomConfirm'
import { setSarPageLimit } from '../../../../../actions/sarActions'
import CustomNotificationManager from '../../../../Common/CustomNotificationManager';
import withRouter from '../../../../../private-routers/withRouter'
import NewTicketSettingsModal from "./NewTicketSettingsModal";
import {
    getPostTicketWorkflowBoxStatusDashboard,
    getTicketWorkflowAndHeaderDetails,
    getTicketWorkflowBoxStatisticsDashboard,
    getTicketWorkflowBoxStatusDashboard, getTicketWorkflowFiltersDashboard,
    getTicketWorkflowListDashboard,
    downloadDashboardWorkflow
} from "../../../../../api/SmartBox";
import imgBoxEmpty from "../../../../../assets/box/box_empty_20x20.png";
import imgBoxProcessing from "../../../../../assets/box/box_processing_20x20.png";
import imgBox from "../../../../../assets/box/box_20x20.png";

const { Search } = Input
const { Option } = Select

export class ArchiveWorkflow extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            showLearnMessage: true,
            sars: [],
            accordion: {},
            name: null,
            step: null,
            complete: null,
            time: null,
            pageNumber: 1,
            amountPerPage: 2,
            totalElements: null,
            archived: false,
            // itemsPerPageLimit: 10,
            itemsPerPageLimitOptions: [20, 50, 100],
            selectPageLimitValue:20,
            isPrevDisabled: true,
            isNextDisabled: true,
            totalRecords: 0,
            currentPage: 1,
            showInsertPageNumber: false,
            sortCriteria: { column: 'created', order: 'desc' },
            filterCriteria: [],
            filterChanged: false,
            listKeysSar: [],
            selectSarRowTable: {},
            checkSelectAllSar: false,
            checkBoxSelectAll: false,
            onHoverDelete: false,
            onStateStart: false,
            onStateEnd:false,
            onHoverShow:false,
            onHoverBackBtn:false,
            onHoverNextBtn:false,
            onStateDownload:false,
            loadingBtnNewTicket:false,
            visibleNewTicket: false,
            searchTicketList: '',
            dataSourceTicket: [],
            dataSourceTicketBoxStatus: [],
            statisticsDashboard: {},
            ticketStatus: 'Archive',
            statusModalNewTickets: '',
            selectEditTickets: {},
            selectHeaderTickets: {},
            orderBy: null,
            orderType: null,
            ownerListFilter: [],
            workflowNames: [],
            timeframes: [],
            boxStatusList: [],
            filtersMap: [],
            loadingGetListArchive: false,
            ticketsNumberYear: {}
        }
    }

    componentDidMount() {
        window.scrollTo(0,0)
        this.onGetTicketWorkflowListDashboard()
        this.onGetTicketWorkflowBoxStatisticsDashboard()
    }

    onGetTicketWorkflowListDashboard = () => {
        const {currentPage, selectPageLimitValue, searchTicketList, ticketStatus, orderBy, orderType, filtersMap } = this.state
        const mapObjectFilters = Object.fromEntries(filtersMap)
        const getTicketsWorkflowDashboardRequestDTO = {
            pageNumber: currentPage,
            pageRows: selectPageLimitValue,
            ticketStatus: ticketStatus,
            search: searchTicketList,
            orderBy: orderBy,
            orderType: orderType,
            filtersMap: mapObjectFilters,
        }
        this.setState({
            loadingGetListArchive: true
        }, () => {
            getTicketWorkflowListDashboard(getTicketsWorkflowDashboardRequestDTO).then((response) => {
                this.setState({
                    //dataSourceTicket: response.data.ticketWorkflowDashboardDTOList,
                    searchTicketList: '',
                    totalRecords: response.data.totalRows
                }, () => {
                    this.onGetTicketWorkflowBoxStatusDashboard(response.data.ticketWorkflowDashboardDTOList)
                })
            }).catch((error) => {
                this.setState({
                    loadingGetListArchive: false
                })
            })
        })
    }
    onGetTicketWorkflowBoxStatusDashboard = (dataSourceTicket) => {
        const {currentPage, selectPageLimitValue, searchTicketList, ticketStatus, orderBy, orderType, filtersMap } = this.state
        const mapObjectFilters = Object.fromEntries(filtersMap)
        const getTicketsWorkflowDashboardRequestDTO = {
            pageNumber: currentPage,
            pageRows: selectPageLimitValue,
            ticketStatus: ticketStatus,
            search: searchTicketList,
            orderBy: orderBy,
            orderType: orderType,
            filtersMap: mapObjectFilters,
        }
        getPostTicketWorkflowBoxStatusDashboard(getTicketsWorkflowDashboardRequestDTO).then((response) => {
            this.setState({
                dataSourceTicketBoxStatus: response.data
            })
            dataSourceTicket && dataSourceTicket.length > 0 && dataSourceTicket.forEach((list1) => {
                response.data && response.data.length > 0 && response.data.forEach((list2) => {
                    if (list1.ticketId === list2.ticketId) {
                        list1.boxStatus = list2.boxStatus
                        list1.boxId = list2.boxId
                        list1.boxPath = list2.boxPath
                    }
                })
            })
            this.setState({
                dataSourceTicket: dataSourceTicket,
                loadingGetListArchive: false
            }, () => {
                this.updatePreviewNextPageNumber()
                this.onEditHeaderTickets(response.data)
                this.onGetTicketWorkflowFiltersDashboard()
            })
        }).catch((error) => {
            this.setState({
                loadingGetListArchive: false
            })
        })
    }

    onGetTicketWorkflowFiltersDashboard = () => {
        const { ticketStatus } = this.state
        const ownerListFilter = []
        const workflowNames = []
        const timeframes = []
        const boxStatusList = []
        getTicketWorkflowFiltersDashboard(ticketStatus).then((response) => {
            if (response.data.ownerListFilter && response.data.ownerListFilter.length > 0) {
                response.data.ownerListFilter.forEach((item) => {
                    ownerListFilter.push({
                        text: item,
                        value: item,
                    })
                })
                this.setState({
                    ownerListFilter
                })
            } else {
                ownerListFilter.push({
                    text: '',
                    value: '',
                })
                this.setState({
                    ownerListFilter
                })
            }

            if (response.data.workflowNames && response.data.workflowNames.length > 0) {
                response.data.workflowNames.forEach((item) => {
                    workflowNames.push({
                        text: item,
                        value: item,
                    })
                })
                this.setState({
                    workflowNames
                })
            } else {
                workflowNames.push({
                    text: '',
                    value: '',
                })
                this.setState({
                    workflowNames
                })
            }

            if (response.data.timeframes && response.data.timeframes.length > 0) {
                response.data.timeframes.forEach((item) => {
                    timeframes.push({
                        text: (<span className={'color-time-frame-wrapper'}> <span className={ item === "None" ? 'color-time-frame no-color' : item === 'Disclosed' ? 'color-time-frame no-color' : 'color-time-frame'} style={{backgroundColor: item === "White" ? 'white':
                                item === "Yellow" ? '#FFB803' : item === "Orange" ? " #F74006" : item === "Black" ? "#000000" : "" }}></span> <span>{item === 'Disclosed' ? item : item === 'None' ? 'None' : ''}</span>
                        </span>),
                        value: item,
                    })
                })
                this.setState({
                    timeframes
                })
            } else {
                timeframes.push({
                    text: '',
                    value: '',
                })
                this.setState({
                    timeframes
                })
            }

            if (response.data.boxStatusList && response.data.boxStatusList.length > 0) {
                response.data.boxStatusList.forEach((item) => {
                    boxStatusList.push({
                        text: (<span className={ item === "Box does not exists" ? 'box-icon-empty-filter-option' : ""}>
                            <img src={
                                item === "Box does not exists" ? imgBoxEmpty :
                                item ===  "Box does not contains data" ? imgBoxEmpty :
                                item ===  "Box contains data" ? imgBoxProcessing :
                                item === "Box contains data and workflow is disclose" ? imgBox : imgBoxEmpty} /></span>),
                        value: item,
                    })
                })
                this.setState({
                    boxStatusList
                })
            } else {
                boxStatusList.push({
                    text: '',
                    value: '',
                })
                this.setState({
                    boxStatusList
                })
            }

        })
    }

    onGetSorterColumnTickets = (column, sorter) => {
        this.setState({
            orderBy: column,
            orderType: sorter,
        }, () => {
            this.onGetTicketWorkflowListDashboard()
        })
    }

    onGetFiltersColumnTickets = (filters, extra) => {
        const ticketOwnerName = []
        const ticketWorkflowName = []
        const ticketTimeFrame = []
        const box = []
        let allList = []
        if (filters.ticketOwnerName && filters.ticketOwnerName.length > 0) {
            ticketOwnerName.push({
                nameColumns: 'owner',
                nameWorkflow: filters.ticketOwnerName,
            })
        }
        if (filters.ticketWorkflowName && filters.ticketWorkflowName.length > 0) {
            ticketWorkflowName.push({
                nameColumns: 'workflow',
                nameWorkflow: filters.ticketWorkflowName,
            })
        }
        if (filters.ticketTimeFrame && filters.ticketTimeFrame.length > 0) {
            ticketTimeFrame.push({
                nameColumns: 'timeFrame',
                nameWorkflow: filters.ticketTimeFrame,
            })
        }
        if (filters.box && filters.box.length > 0) {
            box.push({
                nameColumns: 'box',
                nameWorkflow: filters.box,
            })
        }
        allList = ticketOwnerName.concat(ticketWorkflowName, ticketTimeFrame, box)
        if (allList && allList.length > 0) {
            const result = new Map(allList.map(i => [i.nameColumns, i.nameWorkflow]))
            this.setState({
                filtersMap: new Map(allList.map(i => [i.nameColumns, i.nameWorkflow])),
                filtersList: allList
            }, () => {
                this.onGetTicketWorkflowListDashboard()
            })
        } else {
            this.setState({
                filtersMap: new Map(),
                filtersList: allList
            }, () => {
                this.onGetTicketWorkflowListDashboard()
            })
        }

    }

    onEditHeaderTickets = (dataSourceTicketBoxStatus) => {
        if (dataSourceTicketBoxStatus && dataSourceTicketBoxStatus.length > 0) {
            getTicketWorkflowAndHeaderDetails(dataSourceTicketBoxStatus[0].ticketId).then((response) => {
                this.setState({
                    selectEditTickets: response.data,
                    selectHeaderTickets: response.data,
                    statusModalNewTickets: 'edit'
                })
            })
        }
    }
    onGetTicketWorkflowBoxStatisticsDashboard = () => {
        const ticketStatus = 'Archive'
        const year = 2024
        getTicketWorkflowBoxStatisticsDashboard(ticketStatus, year).then((response) => {
            this.setState({
                statisticsDashboard: response.data,
                ticketsNumberYear: response.data.ticketsNumberYear,
            })
        })
    }
    updatePreviewNextPageNumber = () => {
        const { totalRecords, currentPage } = this.state
        const totalPages = Math.ceil(totalRecords / this.state.selectPageLimitValue)
        const isPrevDisabled = currentPage > 1 ? false : true
        const isNextDisabled = currentPage < totalPages ? false : true
        this.setState({ isPrevDisabled, isNextDisabled, currentPage })
    }

    setFilterAndSort = (sortCriteria, filterCriteria) => {
        const { currentPage } = this.state
        let { filterChanged } = this.state

        if (currentPage > 1) {
            filterChanged= true
        }
        this.setState({ sortCriteria, filterCriteria, filterChanged })
    }

    setFilter = (filterCriteria) => {
        const { currentPage } = this.state
        let { filterChanged } = this.state
        if (currentPage > 1) {
            filterChanged= true
        }
        this.setState({ filterCriteria, filterChanged })
    }

    updateFilterSearch(e) {
        const searchText = e.target.value
        if (searchText !== '' && searchText.length > 2) {
            const timeout = null
            clearTimeout(timeout)
        } else if (searchText === '') {
        }

        this.setState({
            name: searchText,
            pageNumber: 1
        })
    }

    updateValue = (e, field) => {
        const value = e
        if (field === 'time') {
            this.setState({
                time: Number(value),
                pageNumber: 1
            })
        }

        if (field === 'step') {
            this.setState({
                step: Number(value),
                pageNumber: 1
            })
        }

        if (field === 'complete') {
            this.setState({
                complete: value,
                pageNumber: 1
            })
        }
    }

    changePagination = (page) => {
        this.setState({ currentPage: page })
    }

    changeItemsPerPageLimit = (e) => {
        // this.setState({ filterChanged: true }, () => {
        // this.props.setSarPageLimit(e)
        // })
        this.setState({
            selectPageLimitValue:e
        }, () => {
            this.onGetTicketWorkflowListDashboard()
        })
    }

    handleMovePage = (e, direction) => {
        const { isPrevDisabled, isNextDisabled, totalRecords, selectPageLimitValue } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / selectPageLimitValue) ? Math.ceil(totalRecords / selectPageLimitValue) : this.state.currentPage + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(Math.ceil(totalRecords/selectPageLimitValue))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }

    gotoPage = (newPage) => {
        const { totalRecords, selectPageLimitValue, currentPage } = this.state
        const totalPages = Math.ceil(totalRecords / selectPageLimitValue)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        const isNextDisabled = currentPage < totalPages ? false : true
        this.setState({ currentPage: newPage, isNextDisabled }, () => {
            this.onGetTicketWorkflowListDashboard()
        })
    }

    handleInputPageChange = (e) => this.setState({ inputPage: e.target.value })

    handleInputPageEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlur()
        }
    }

    handleInputPageBlur = () => {
        const { inputPage, selectPageLimitValue } = this.state
        if (inputPage) {
            const paginationEnd = Math.ceil(this.state.totalRecords / selectPageLimitValue)
            if (inputPage > 0 && inputPage <= paginationEnd) {
                this.gotoPage(inputPage)
            } else {
                this.gotoPage(1)
                this.setState({ inputPage: 1 })
            }
        }
    }
    onSelectAll = (e) => {
        const { sars } = this.state
        if (e.target.checked) {
            this.setState({
                checkSelectAllSar: true
            })
        } else {
            this.setState({
                checkSelectAllSar: false
            })
        }
        this.setState({
            listKeysSar: e.target.checked ? sars.map((sar) => sar.id) : [],
            checkBoxSelectAll: e.target.checked
        })
    }
    onSelectSarRow = (sar) => {
        this.setState({
            selectSarRowTable: sar
        })
    }
    handleDeleteRequestDashboard = (e) => {
        const { listKeysSar, selectSarRowTable } = this.state
        if ((listKeysSar && listKeysSar.length > 0) || (selectSarRowTable && selectSarRowTable.id)) {
            customConfirm({
                title: 'Do you want to delete request?',
                content: ``,
                confirmButtonText: 'DELETE',
                cancelButtonText: 'CANCEL',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.onClickDeleteAllSelectSar(e)
                }
            })
        }
    }

    onClickDeleteAllSelectSar(e) {
        const { listKeysSar, selectSarRowTable, sars } = this.state
        const selectDeleteSar = []
        selectDeleteSar.push(selectSarRowTable.id)
        const deleteSarDTO = {
            sarIds: listKeysSar && listKeysSar.length > 0 ? sars.map((sar) => sar.id) : selectDeleteSar && selectDeleteSar.length > 0 ? selectDeleteSar : []
        }
        // apiSarDeleteRequest(deleteSarDTO).then(() => {
        //   this.setState({
        //     selectSarRowTable: {},
        //     checkBoxSelectAll: false,
        //     listKeysSar: [],
        //     checkSelectAllSar: false
        //   })
        // }).catch((error) => {
        //   if (error?.response?.status === 504) {
        //     CustomNotificationManager.cleanBuffer('warning', '')
        //   }else{
        //     console.log(error)
        //   }
        // })
    }
    onMouseEnterOnPanel(e, status) {
        if (status === 'delete') {
            this.setState({
                onHoverDelete: true
            })
        }
    }
    onMouseLeaveOnPanel(e, status) {
        this.setState({
            onHoverDelete: false,
        })
    }
    onMouseEnterAction = (e, state) => {
        switch (state) {
            case 'start':
                this.setState({
                    onStateStart: true,
                })
                break
            case 'end':
                this.setState({
                    onStateEnd: true,
                })
                break
            case 'show':
                this.setState({
                    onHoverShow: true,
                })
                break
            case 'previous':
                this.setState({
                    onHoverBackBtn: true,
                })
                break
            case 'next':
                this.setState({
                    onHoverNextBtn: true,
                })
                break
            case 'download':
                this.setState({
                    onStateDownload: true
                })
                break
            default: break
        }
    }
    onMouseLeaveAction = () => {
        this.setState({
            onStateStart: false,
            onStateEnd: false,
            onHoverShow:false,
            onHoverBackBtn: false,
            onHoverNextBtn:false,
            onStateDownload:false,
        })
    }
    openSarTrackerPage = () => {
        this.setState({
            loadingBtnNewTicket:true,
            visibleNewTicket:true,
            statusModalNewTickets: 'new'
        }, () => {
            this.setState({
                loadingBtnNewTicket: false
            })
        })
        // () => this.props.navigate('/sar/tracker-page')

    }
    onCloseNewTickets = (status) => {
        this.setState({
            visibleNewTicket: status
        })
    }
    onSearchTicket = (e) => {
        this.setState({
            searchTicketList: e
        }, () => {
            this.onGetTicketWorkflowListDashboard()
        })
    }
    onEditModalTickets = (record) => {
        this.onGetTicketWorkflowAndHeaderDetails(record)
        // this.setState({
        //   loadingBtnNewTicket:true,
        //   visibleNewTicket:true,
        //   statusModalNewTickets: 'edit'
        // })
    }

    onGetTicketWorkflowAndHeaderDetails = (record) => {
        getTicketWorkflowAndHeaderDetails(record.ticketId).then((response) => {
            this.setState({
                selectEditTickets: response.data,
                //loadingBtnNewTicket:true,
                visibleNewTicket:true,
                statusModalNewTickets: 'edit'
            })
        })
    }
    onGetChangeChart = (newYear, year) => {
        const { ticketsNumberYear, statisticsDashboard } = this.state
        if (newYear < year) {
            statisticsDashboard.ticketsNumberYear = {}
        } else {
            statisticsDashboard.ticketsNumberYear = ticketsNumberYear
        }
        this.setState({
            statisticsDashboard
        })
    }

    onActionDeleteBoxArchive = (record) => {
        const { dataSourceTicket, dataSourceTicketBoxStatus } = this.state
        dataSourceTicket && dataSourceTicket.length > 0 && dataSourceTicket.forEach((list1) => {
                if (list1.ticketId === record.ticketId) {
                    list1.boxStatus = 'DISABLED'
                }
        })
        // dataSourceTicket && dataSourceTicket.length > 0 && dataSourceTicket.forEach((list1) => {
        //     dataSourceTicketBoxStatus && dataSourceTicketBoxStatus.length > 0 && dataSourceTicketBoxStatus.forEach((list2) => {
        //         if (list1.ticketId === list2.ticketId) {
        //             list1.boxStatus = 'DISABLED'
        //         }
        //     })
        // })
        this.setState({
            dataSourceTicket
        })
    }
    render()
    {
        const { sars, isLoading, itemsPerPageLimitOptions, isPrevDisabled, isNextDisabled, totalRecords,
            currentPage, inputPage, showInsertPageNumber, sortCriteria, filterCriteria, filterChanged, listKeysSar, selectSarRowTable, checkSelectAllSar,
            checkBoxSelectAll, onHoverDelete, onStateStart, onStateEnd, selectPageLimitValue, onHoverShow, onHoverBackBtn, onHoverNextBtn, loadingBtnNewTicket,
            visibleNewTicket, dataSourceTicket, statisticsDashboard, statusModalNewTickets, selectEditTickets, selectHeaderTickets,
            ownerListFilter, workflowNames, timeframes, boxStatusList, onStateDownload, loadingGetListArchive } = this.state
        const totalPages = Math.ceil(totalRecords / selectPageLimitValue)
        return (
            <React.Fragment>
                <div className="sarDashboard-page sarDashboard-page-new sarDashboard-page-archive">
                    <div className='dashboard-workflow-title-page'>
                        <header className="dashboard-workflow-headline">
                            <div className='dashboard-workflow-header'>
                                <h1 className="dashboard-workflow-title">Archived tickets</h1>

                            </div>
                        </header>
                    </div>
                    {/*<div className="sar_statistic_card sar_statistic_card-update">*/}
                    {/*    <CardList statisticsDashboard={statisticsDashboard} status="archive" />*/}
                    {/*    {Object.keys(statisticsDashboard) && Object.keys(statisticsDashboard).length > 0 &&*/}
                    {/*    <LineChart statisticsDashboard={statisticsDashboard} onGetChangeChart={this.onGetChangeChart}/>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className="sar-table-content sar-table-new-content">
                        <div className="sar-table-content__header table-content-header-update">
                            <div className="per-page-wrapper-update">
                                <div className={ `per-page per-page-update icon-v14-show-${selectPageLimitValue }${onHoverShow ? '-active' : ''}`}
                                     onMouseEnter={ (e) => this.onMouseEnterAction(e, 'show') } onMouseLeave={ () => this.onMouseLeaveAction() }>
                                    <Select
                                        value={selectPageLimitValue}
                                        dropdownClassName={'select-per-page-tickets per-tickets'}
                                        onChange={ (e) => this.changeItemsPerPageLimit(e) }>
                                        { itemsPerPageLimitOptions.map((option) => {
                                            return <Option key={option} value={option}>{option}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className='pagination-wrapper pagination-wrapper-new'>
                                <div className="custom-pagination-sb">
                                    <div className="button-control-dashboard-new">
                                        <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                    <span className={ isPrevDisabled ? 'icon-v14-start-1 disabled' : onStateStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                          onClick={ (e) => this.handleMovePage(e, 'first')}
                          onMouseEnter={(e) => this.onMouseEnterAction(e, 'start')}
                          onMouseLeave={() => this.onMouseLeaveAction()}/>
                                        </Tooltip>
                                        <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                    <span className={ isPrevDisabled ? 'icon-v21-back-1 disabled' : onHoverBackBtn ? 'icon-v21-back-active' : 'icon-v21-back-1'}
                          onMouseEnter={(e) => this.onMouseEnterAction(e,'previous')}
                          onMouseLeave={() => this.onMouseLeaveAction()}
                          onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                                        </Tooltip>
                                        { totalPages === 0 &&
                                        <span className='button-control__text'>{1} of {1} </span>
                                        }
                                        { totalPages === 1 &&
                                        <span className='button-control__text'>{currentPage} of {totalPages} </span>
                                        }
                                        { totalPages > 1 &&
                                        <span className='button-control__text'>
                      <input
                          type="number"
                          className="insert-page-new"
                          placeholder= { currentPage }
                          value= { inputPage }
                          onChange={ (e) => this.handleInputPageChange(e) }
                          onBlur={ () => this.handleInputPageBlur() }
                          onKeyDown={ (e) => this.handleInputPageEnter(e) }
                          min="1"
                          max={totalPages}
                      />
                      <span>of {totalPages}</span>
                    </span>
                                        }
                                        <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                    <span className={ isNextDisabled ? 'icon-v21-forth-1 disabled' : onHoverNextBtn ?  'icon-v21-forth-active' : 'icon-v21-forth-1' }
                          onClick={ (e) => this.handleMovePage(e, 'next')}
                          onMouseEnter={(e) => this.onMouseEnterAction(e, 'next')}
                          onMouseLeave={() => this.onMouseLeaveAction()}
                    />
                                        </Tooltip>
                                        <Tooltip title="last" className={isNextDisabled ? 'disabled' : ''}>
                    <span className={ isNextDisabled ? 'icon-v14-end-1 disabled' : onStateEnd ? 'icon-v14-end-active' : 'icon-v14-end-1'  }
                          onClick={ (e) => this.handleMovePage(e, 'last')}
                          onMouseEnter={(e) => this.onMouseEnterAction(e, 'end')}
                          onMouseLeave={() => this.onMouseLeaveAction()}/>
                                        </Tooltip>
                                    </div>
                                    { showInsertPageNumber &&
                                    <Search
                                        className='insert-page'
                                        placeholder={`Insert page number (max ${ Math.ceil(totalRecords / selectPageLimitValue) } )`}
                                        onSearch={(value) => this.goToInputPage(value)}
                                        style={{ width: 250 }}
                                    />
                                    }
                                </div>
                            </div>
                            <div className="content-search-dashboard-workflow">
                                <a href={downloadDashboardWorkflow()}
                                   className={onStateDownload ? 'icon-v21-download-active-1' : 'icon-v21-download-1'}
                                   onMouseEnter={(e) => this.onMouseEnterAction(e, 'download')}
                                   onMouseLeave={() => this.onMouseLeaveAction()}> </a>
                            <div className={'search-content'}>
                                <Search
                                    allowClear
                                    placeholder={'Search'}
                                    onSearch={(e) => this.onSearchTicket(e)}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="styleAccordion">
                        <SubjectsListArchive
                            sars={ sars }
                            isLoading = { isLoading }
                            itemsPerPageLimit = { selectPageLimitValue }
                            currentPage = { currentPage }
                            sortCriteria= { sortCriteria }
                            filterCriteria= { filterCriteria }
                            filterChanged= { filterChanged }
                            setFilterAndSort= { this.setFilterAndSort }
                            setFilter= { this.setFilter }
                            onSelectSarRow={ this.onSelectSarRow }
                            checkSelectAllSar={ checkSelectAllSar }
                            onCloseNewTickets={ this.onCloseNewTickets}
                            dataSourceTicket={ dataSourceTicket }
                            onGetTicketWorkflowListDashboard={ this.onGetTicketWorkflowListDashboard }
                            onEditModalTickets={ this.onEditModalTickets }
                            onGetSorterColumnTickets={ this.onGetSorterColumnTickets }
                            ownerListFilter={ ownerListFilter }
                            workflowNames={ workflowNames }
                            timeframes={ timeframes }
                            boxStatusList={ boxStatusList }
                            onGetFiltersColumnTickets={ this.onGetFiltersColumnTickets }
                            loadingGetListArchive={loadingGetListArchive}
                            onActionDeleteBoxArchive={this.onActionDeleteBoxArchive}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}



ArchiveWorkflow = withRouter(ArchiveWorkflow)

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    sarPageLimit: state.sar.sarPageLimit
})

export default connect(mapStateToProps,{
    setSarPageLimit
})(ArchiveWorkflow)