import React from 'react'
import PropTypes from 'prop-types'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import { Input, Modal, notification, Tooltip, Progress, Spin } from 'antd'
import {
  apiSmartBoxPreviewSarDocByThumbnailId,
  apiSmartBoxPreviewSarDocFinalizedByThumbnailId,
  deleteSmartDocument,
  getFolderChildren,
  getRenameFile,
  getOcrFile,
  apiSmartBoxZip,
  apiSmartBoxFinalisedFolder,
  apiSmartBoxFinalisedDocument,
  apiSmartBoxExportDocument,
  apiSmartBoxCheckBulkFolderFinalise,
  apiSmartBoxMoveFiles,
  apiSmartBoxDownloadFile,
  sendToReanalyseWithAI, deleteSelected, cancelBoxRedaction, apiSmartBoxExportAudit,
} from '../../../api/SmartBox'
import {
  setSmartBoxIntervalForBulkFinalise,
  removeSmartBoxIntervalForBulkFinalise,
  setSmartBoxCurrentFolder,
  setSmartBoxLoadTree,
  setSmartBoxFolderClosed,
  setSmartBoxDeletedFolder,
  setSmartBoxRenamedFolder,
  setSmartBoxSelectedFiles,
  setSmartBoxSelectedDuplicateFile,
  setSmartBoxMovedFiles,
  setSmartBoxMovedFolders,
  setSmartBoxOldParentOfMovedFolders,
  setSmartBoxNewParentOfMovedFolders,
  setSmartBoxSecurityConfiguration,
  setSmartBoxParentStatisticsReady,
  setSmartBoxCurrentFolderName,
  setSmartBoxSourceDrop,
  setSmartBoxClickOutsideFilter,
  setSmartBoxCurrentNewFilters,
  setSmartBoxCurrentNewFiltersSort,
  setSmartBoxSelectedKeys,
  setActiveDeleteFile, setSmartBoxPreviousCurrentPage
} from '../../../actions/smartBoxActions'
import { connect } from 'react-redux'
import { getImageSrc, extractDepth } from '../../../utils/SmartBox/SmartBoxMethods'
import Icon from 'antd/es/icon'
import './SmartBoxFile.scss'
//import imgAnalysed from '../../../assets/analysed.png'
import { ContextMenu, ContextMenuTrigger, MenuItem, SubMenu } from 'react-contextmenu'
import { generateBlobFromBase64 } from '../../../utils/GenerateBlob'
import PDFViewer from '../../../utils/PDFViewer'
import PDFJSBackend from '../../../utils/backends/PDFJs'
import { noop } from '../../../utils/SmartBox/DoubleClickMethods/cancellable-promise-exports'
import pleaseStopTriggeringClicksOnDoubleClick from '../../../utils/SmartBox/DoubleClickMethods/please-stop-triggering-clicks-on-double-click'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import DirectoryNote from './DirectoryNote'
import FileDownload from 'js-file-download'
import { addIntervalToApplication } from '../../../actions/applicationsActions'
import imgFolderParentProcessing from '../../../assets/images/Box processing 80x80.png'
import imgFolderParentEmpty from '../../../assets/images/Box empty 80x80.png'
import imgFolderChildrenEmpty from '../../../assets/images/Folder empty 80x80.png'
import DownloadBoxModalOptionFiles from './DownloadBoxModalOptionFiles'
import imgExtraction from '../../../assets/Extraction.svg'
//import imgCulling from '../../../assets/Culling.svg'
import imgCulling from '../../../assets/Culling.gif'
//import imgFolderSmallParent from "../../../assets/smart-folder-medium-parent.svg";
import moment from "moment";
import withRouter from '../../../private-routers/withRouter'

const nameRgex = /^[A-Za-z\d_\s]+$/
const ClickableBox = ({ typeLower, file, configImage, name, onClick, onDoubleClick, onSelectImages, isNewFolder, renameFileVisible, closeInput, positionOnRenameFolder, parent, onFileEnterOnPanel, listKeysFiles, filesCount, activeFile, filesErrors, getFilesDuplicates, routeChangeDataInsightDuplicate, onDrop, onDragOver, getFilesProprieties,
                        progressBulkData }) => {
  const aiReady = (typeof getFilesProprieties !== 'undefined' && Object.keys(getFilesProprieties).length && getFilesProprieties.aiFinished === true) || (file.aiFinished === 'true' && file.pdfValidation === 'true')
  const converted = (typeof getFilesProprieties !== 'undefined' && Object.keys(getFilesProprieties).length && getFilesProprieties.converted === true) || file.isPdf === 'true'
  return (
    <ContextMenuTrigger id={ `context-menu-file ${ file.id }` } collect={ () => file } key={ 1 } holdToDisplay={ -1 }
                        disable={ true }>
      <div onBlur={ closeInput } className={ `content-file__image ${ typeLower === 'dot' ? '' : typeLower }` }
           onClick={ onClick } onDoubleClick={ onDoubleClick }
           onDrop={ onDrop }
           onDragOver={ onDragOver }>
        { file.type !== 'DIRECTORY' &&
        <div className={`icons-container-file-view ${ !aiReady || !converted ? 'not-ready' : ''}`}
             style={ typeof configImage === 'string' ? { top: '3px' } : { top: '9px' } }>
          {
            file.bigFile !== 'true' ?
              <React.Fragment>
                {/*{*/}
                {/*  file.pdfValidation === 'true' &&*/}
                  <React.Fragment>
                    { (file.conversionStatus === 'Processing' || file.conversionStatus === 'Unconverted') ? <Tooltip title="Converting document"><Icon type="setting" spin/></Tooltip> :
                      (file.conversionStatus && file.conversionStatus === 'Converted' && file.aiFinished === 'false' ? <Tooltip title="AI Processing content"><Icon type={ onSelectImages(file.type) } spin/></Tooltip> : '')}
                    {/* converted ?
                    <Tooltip title="Ready for redaction"><Icon type="file-pdf"/></Tooltip> :
                    <Tooltip title="Preparing document for redacting"><Icon type="setting" spin/></Tooltip>  }
                    { aiReady ?
                      <Tooltip title="Ai data is ready"><img className="analysed-file" src={ imgAnalysed }
                                                             alt={ file.name } style={ { zIndex: 2 } }/></Tooltip>
                      :
                      <Tooltip title="AI Processing content">
                        <Icon type={ onSelectImages(file.type) } spin/>
                      </Tooltip>
                    */}
                  </React.Fragment>
                 {/*}*/}
                { file.pdfValidation === 'true' ? '' : ''

                  // <Tooltip title={ file.errorMessage }><Icon type="close-square"
                  //   style={ { color: 'red' } }/></Tooltip>
                }
              </React.Fragment>
              :
              <Tooltip title="Processing big File"><Icon type="setting" spin/></Tooltip>
          }
        </div>
        }
        <ContextMenuTrigger id={ `context-menu-file ${ file.id }` } collect={ () => file } key={ 1 }
                            holdToDisplay={ 0 }>
          { ((onFileEnterOnPanel && file && onFileEnterOnPanel.id === file.id) || (listKeysFiles && listKeysFiles.length > 0 && listKeysFiles.includes(file.id)) || activeFile === file) &&
          <span className="action-panel-hovering icon-v11-options-small-active"/>
          }
        </ContextMenuTrigger>
        { file.type === 'DIRECTORY' && file.depth === 0 &&
        <React.Fragment>
          { file.workflow_ticket_id  &&
          <Tooltip
              className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing new-bulk-processing' }
              title="Workflow linked"><span
              className={ positionOnRenameFolder ? 'icon-v24-linked icon-finalise-on-rename' : 'icon-v24-linked icon-finalise2' }
          /></Tooltip>
          }
          { file.bulkProcessingStarted && file.bulkProcessingStarted === 'started' && progressBulkData &&
          <Tooltip
            className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing new-bulk-processing' }
            title="Bulk redaction in progress"><Icon type="sync" spin/></Tooltip>
          }
          { file.finaliseFolder && file.finaliseFolder === 'started' &&
          <Tooltip
            className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing new-bulk-processing' }
            title="Bulk finalise in progress"><Icon type="sync" spin/></Tooltip>
          }
          { parent && file.finaliseFolder !== 'true' && file.finaliseFolder !== 'started' && file.bulkProcessingStarted && file.bulkProcessingStarted === 'finished' &&
          // <Tooltip
          //   className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing new-bulk-processing' }
          //   title="Bulk redaction is finished"><Icon type="check"/></Tooltip>
          <Tooltip
              className={ positionOnRenameFolder ? 'bulk-processing position-icons position-icons-update' : 'bulk-processing new-bulk-processing new-bulk-processing-update' }
              title="Bulk redaction is finished">
               <svg id="svg" viewBox="0 0 500 250">
               <symbol id="poly" viewBox="14 25 72 83.3">
                 <polygon points="50,25 86,45.83 86,87.5 50,108.3 14,87.53 14,45.83" />
               </symbol>
               <use href="#poly" x="180" y="40" width="220" height="210" fill='#66536030'/>
             </svg></Tooltip>
          }
          { !parent && file.finaliseFolder !== 'true' && file.finaliseFolder !== 'started' && file.bulkProcessingStarted && file.bulkProcessingStarted === 'finished' &&
          <Tooltip
            className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing new-bulk-processing' }
            title="Bulk redaction is finished"><Icon type="check"/></Tooltip>
          }
          { file.progressBar && file.progressBar.progressBarClutter &&  file.progressBar.progressBarClutter === true &&
            <Tooltip title="Culling">
              {/*<object className="image-animation-culling" type="image/svg+xml" data={ imgCulling }> </object>*/}
              {/*<object className="image-animation-culling" data={ imgCulling }> </object>*/}
              <img className="imgCulling-loading" src={imgCulling} alt="loading..." />
            </Tooltip>
          }
          { file.finaliseFolder && file.finaliseFolder === 'true' && file.bulkProcessingStarted !== 'started' &&
          <Tooltip
            className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing new-bulk-processing' }
            title="Data finalised"><span
            className={ positionOnRenameFolder ? 'icon-v8-smartbox-folder-finalised icon-finalise-on-rename' : 'icon-v8-smartbox-folder-finalised icon-finalise' }/></Tooltip>
          }
        </React.Fragment>
        }
        { file.type === 'DIRECTORY' && file.depth !== 0 &&
        <React.Fragment>
          { file.bulkProcessingStarted && file.bulkProcessingStarted === 'started' &&
          <Tooltip className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing' }
                   title="Bulk redaction in progress"><Icon type="sync" spin/></Tooltip>
          }
          {
            file.finaliseFolder && file.finaliseFolder === 'started' &&
            <Tooltip className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing' }
                     title="Bulk finalise in progress"><Icon type="sync" spin/></Tooltip>
          }
          { file.finaliseFolder !== 'true' && file.finaliseFolder !== 'started' && file.bulkProcessingStarted && file.bulkProcessingStarted === 'finished' &&
          <Tooltip className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing' }
                   title="Bulk redaction is finished"><Icon type="check"/></Tooltip>
          }
          {
            file.finaliseFolder && file.finaliseFolder === 'true' && file.bulkProcessingStarted !== 'started' &&
            <Tooltip className={ positionOnRenameFolder ? 'bulk-processing position-icons' : 'bulk-processing' }
                     title="Data finalised"><span
              className={ positionOnRenameFolder ? '' : 'icon-v8-smartbox-folder-finalised icon-finalise' }/></Tooltip>
          }
        </React.Fragment>
        }

        <div className={ !isNewFolder && !renameFileVisible ? 'image-container is-draggable' : 'image-container' }>
          { file.type === 'DIRECTORY' ?
            <img
              src={
                file.type === 'DIRECTORY' && file.depth === 0 && ((file.progressBar)
                || (file.progressBarForBulk && file.progressBarForBulk.bulkProcessingStarted === 'started'))
                  ?
                imgFolderParentProcessing
                  : file && file.type === 'DIRECTORY' && file.depth === 0 && filesCount === 0 && parent
                      ? imgFolderParentEmpty
                      : file && file.type === 'DIRECTORY' && file.depth === 0 && filesCount === 0 && !parent
                          ? imgFolderChildrenEmpty
                          : file.type === 'DIRECTORY' && parent && file.depth === 0 && file.hasProcessingErrors
                              ? imgFolderParentProcessing
                              : configImage
              }
              alt={ name }
              id={ `${ file.id }-img` }
              draggable={ false }
              className={ (
                file.type === 'DIRECTORY' &&
                file.depth === 0 &&
                ((file.progressBar && (file.progressBar.nrOfDocuments !== file.progressBar.analysed + file.progressBar.failedForAI))
                  || (file.progressBarForBulk && file.progressBarForBulk.bulkProcessingStarted === 'started'))
              ) ? 'processing new-file-box' : parent && !file.hasProcessingErrors ? 'new-file-box' : parent && file.hasProcessingErrors ? 'new-file-box box-error' : '' }
            />
            :
            (typeof configImage === 'string' ?
                <div className={ file.type === 'svg' ? 'thumbnail-container-svg' :
                    file.landscape === 'false' && file.widht === '800.0' ? 'thumbnail-container-landscape' : "thumbnail-container" }>
                  <img src={ configImage } alt={ file.name } id={ `${ file.id }-img` } draggable={ false }/>
                </div> :
                <span className={ configImage.font } style={ { color: configImage.color } }/>
            )
          }
          {
            // file && file.type === 'DIRECTORY' && filesErrors ?
            // <div className="content-name-duplicate-file new-duplicate-file-progress">
            //   <p
            //     className={ file && file.type === 'DIRECTORY' && file.depth === 0 && ((file.progressBar && (file.progressBar.nrOfDocuments !== file.progressBar.analysed + file.progressBar.failedForAI))
            //       || (file.progressBarForBulk && file.progressBarForBulk.bulkProcessingStarted === 'started')) ? 'content-number-files content-number-files-progress' : 'content-number-files' }>{
            //     file && file.type === 'DIRECTORY' ? filesCount !== null && `${ filesCount } ${ filesCount === 1 ? 'file' : 'files' }` : file.totalPages && `${ file.totalPages } ${ file.totalPages === '1' ? 'page' : 'pages' }` }</p>
            //   <span style={ {
            //     textAlign: 'center',
            //     marginTop: '25px',
            //     marginBottom: '3px'
            //   } } className="icon-v12-process-error"></span>
            // </div> :
            file && file.type !== 'DIRECTORY' && file.conversionStatus === 'Failed' ?
              <Tooltip title={ file && file.errorMessage ? `${ file.errorMessage }` : '' }
                       className="error-message-file">
              <span style={ {
                textAlign: 'center',
                marginTop: '8px',
                marginBottom: '-16px'
              } } className="icon-v12-process-error"></span></Tooltip> :
              <div className="content-name-duplicate-file">
                <p
                  className={ file && file.type === 'DIRECTORY' && file.depth === 0 && ((file.progressBar && (file.progressBar.nrOfDocuments !== file.progressBar.analysed + file.progressBar.failedForAI))
                    || (file.progressBarForBulk && file.progressBarForBulk.bulkProcessingStarted === 'started')) ? 'content-number-files content-number-files-progress' : 'content-number-files' }>{
                  // file.type === 'DIRECTORY' && file.depth === 0 && ((file.progressBar && (file.progressBar.extractedForAI !== file.progressBar.processedForAI + file.progressBar.failedForAI))
                  //   || (file.progressBarForBulk && file.progressBarForBulk.bulkProcessingStarted === 'started')) ? '' :
                  file && file.type === 'DIRECTORY' ? filesCount !== null && `${ filesCount } ${ filesCount === 1 ? 'file' : 'files' }` : file.totalPages && `${ file.totalPages } ${ file.totalPages === '1' ? 'page' : 'pages' }` }</p>
                { getFilesDuplicates === 'standard' ?
                  <Tooltip title="Duplicates"><span onClick={ (e) => routeChangeDataInsightDuplicate(e, file) }
                                                    className="icon-v15-duplicate"></span></Tooltip> :
                  getFilesDuplicates === 'gold' ?
                    <Tooltip title="Duplicates"><span onClick={ (e) => routeChangeDataInsightDuplicate(e, file) }
                                                      className="icon-v14-star-active"></span></Tooltip> : <span></span>
                }
              </div>
          }
          {/*{ !filesErrors &&*/ }
          <div
            className={ getFilesDuplicates === 'standard' || getFilesDuplicates === 'gold' ? 'progress-bar-wrapper update-progress-bar-wrapper' : 'progress-bar-wrapper' }>
           {/* { file && file.type === 'DIRECTORY' && extractDepth(file.folderPath, true) === 0 && !file.progressRename && file.progressBar && (file.progressBar.nrOfDocuments !== file.progressBar.analysed + file.progressBar.failedForAI) && !isNewFolder &&*/}


            {/*{ file && file.type === 'DIRECTORY' && extractDepth(file.folderPath, true) === 0 && !file.progressRename && file.progressBar && file.statisticsReady === 'false' && !isNewFolder &&*/}
            {/*  <div className={ (file.progressBar.doc_extraction === 'true' || (file.progressBar.doc_extraction === 'true' && file.progressBar.doc_extraction !== 'false')) ? 'content-progress-extraction width-progress-extraction stripes-progress' :*/}
            {/*      (file.progressBar.doc_extraction === 'false' || (file.progressBar.doc_extraction === 'false' && file.progressBar.doc_extraction !== 'true')) ? 'content-progress-extraction width-progress-update stripes-progress' : 'content-progress-extraction' }>*/}

            {/*    { ( file.progressBar.doc_extraction === 'true' || (file.progressBar.doc_extraction === 'true' && file.progressBar.doc_extraction !== 'false')) &&*/}
            {/*  <Tooltip title="Extracting data">*/}
            {/*    /!*<img alt={ imgExtraction } src={ imgExtraction }/>*!/*/}
            {/*    <object className="image-animation-extract" type="image/svg+xml" data={ imgExtraction }/>*/}
            {/*  </Tooltip>*/}
            {/*    }*/}

            {/*    {*/}
            {/*      (file.progressBar.doc_extraction === 'true' || (file.progressBar.doc_extraction === 'true' && file.progressBar.doc_extraction !== 'false')) &&*/}
            {/*      <Tooltip title={<div>*/}
            {/*        <p>Processed: { (file.progressBar.processed) } / { file.progressBar.nrOfDocuments } </p>*/}
            {/*        <p>Failed: { file.progressBar.failedForAI }</p>*/}
            {/*      </div>}>*/}

            {/*  <Progress*/}
            {/*    percent={ file.progressBar.processed / file.progressBar.nrOfDocuments * 100 }*/}
            {/*    size="small"*/}
            {/*    //strokeColor='#3ED8DB'*/}
            {/*    trailColor='#fff'*/}
            {/*    showInfo={ false }*/}
            {/*  />*/}
            {/*    </Tooltip>}*/}

            {/*    {*/}
            {/*      ((file.progressBar.doc_extraction === 'false' || (file.progressBar.doc_extraction === null && filesCount !== 0) || (file.progressBar.doc_extraction === 'false' && file.progressBar.doc_extraction === 'false')) && file.analyticsReady ==='false')   &&*/}
            {/*      <Tooltip title={<div>*/}
            {/*        <p>Processed: { file.progressBar.processed } / { file.progressBar.nrOfDocuments } </p>*/}
            {/*        <p>Failed: { file.progressBar.failedForAI }</p>*/}
            {/*      </div>}>*/}

            {/*        <Progress*/}
            {/*            percent={ file.progressBar.processed / file.progressBar.nrOfDocuments * 100 }*/}
            {/*            size="small"*/}
            {/*            //strokeColor='#3ED8DB'*/}
            {/*            trailColor='#fff'*/}
            {/*            showInfo={ false }*/}
            {/*        />*/}
            {/*      </Tooltip>}*/}

            {/*    {*/}
            {/*      ((file.progressBar.doc_extraction === 'false' || (file.progressBar.doc_extraction === null && filesCount !== 0) || (file.progressBar.doc_extraction === 'false' && file.progressBar.doc_extraction === 'false')) && file.analyticsReady ==='true') &&*/}
            {/*      <Tooltip title={<div>*/}
            {/*        <p>Generating stats</p>*/}
            {/*      </div>}>*/}

            {/*        <Progress*/}
            {/*            percent={ file.progressBar.processed / file.progressBar.nrOfDocuments * 100 }*/}
            {/*            size="small"*/}
            {/*            //strokeColor='#3ED8DB'*/}
            {/*            trailColor='#fff'*/}
            {/*            showInfo={ false }*/}
            {/*        />*/}
            {/*      </Tooltip>}*/}

            {/*  </div>*/}
            {/*}*/}

            {/*upload file*/}

            { file && file.type === 'DIRECTORY' && extractDepth(file.folderPath, true) === 0 && filesCount !== 0 && !file.progressRename && file.progressBar && file.statisticsReady === 'false' && !isNewFolder &&

            <div className={ (file.progressBar.doc_extraction === 'true' || (file.progressBar.doc_extraction === 'true' && file.progressBar.doc_extraction !== 'false')) ? 'content-progress-extraction width-progress-extraction stripes-progress' :
                (file.progressBar.doc_extraction === 'false' || (file.progressBar.doc_extraction === 'false' && file.progressBar.doc_extraction !== 'true')) ? 'content-progress-extraction width-progress-update stripes-progress' : 'content-progress-extraction' }>

              { ( file.progressBar.doc_extraction === 'true' || (file.progressBar.doc_extraction === 'true' && file.progressBar.doc_extraction !== 'false')) &&
              <Tooltip title="Extracting data">
                <object className="image-animation-extract" type="image/svg+xml" data={ imgExtraction }> </object>
              </Tooltip>
              }
              {
                <Tooltip title={
                  ((file.progressBar.doc_extraction === 'true' && file.progressBar.generatingReport === false) || (file.progressBar.doc_extraction === 'true' && file.progressBar.doc_extraction !== 'false' && file.progressBar.generatingReport === false)) ?
                  <div>
                  <p>Processed: { (file.progressBar.processed) } / { file.progressBar.nrOfDocuments } </p>
                  <p>Failed: { file.progressBar.failedForAI }</p>
                </div>
                      :
                            ((file.progressBar.doc_extraction === 'false' && file.progressBar.generatingReportRedaction === true) || (file.progressBar.doc_extraction === null && filesCount !== 0 && file.progressBar.generatingReportRedaction === true) || (file.progressBar.doc_extraction === 'false' && file.progressBar.doc_extraction === 'false' && file.progressBar.generatingReportRedaction === true)) ?
                                <div>
                                  <p>Generating stats</p>
                                  <p>Processed: { file.progressBar.processed } / { file.progressBar.nrOfDocuments } </p>
                                </div>
                                    :
                                ((file.progressBar.doc_extraction === 'false' && file.progressBar.generatingReport === false) || (file.progressBar.doc_extraction === null && filesCount !== 0 && file.progressBar.generatingReport === false) || (file.progressBar.doc_extraction === 'false' && file.progressBar.doc_extraction === 'false' && file.progressBar.generatingReport === false)) ?
                                <div>
                                  <p>Processed: { file.progressBar.processed } / { file.progressBar.nrOfDocuments } </p>
                                  <p>Failed: { file.progressBar.failedForAI }</p>
                                </div>
                                      :
                                    (file.progressBar.statisticsStatus  && file.progressBar.statisticsStatus === 'Failed') ?
                                        <div>
                                          <p>Generating stats failed</p>
                                        </div> :
                                                  ((file.progressBar.doc_extraction === 'false' && file.progressBar.generatingReport === true) || (file.progressBar.doc_extraction === null && filesCount !== 0 && file.progressBar.generatingReport === true) || (file.progressBar.doc_extraction === 'false' && file.progressBar.generatingReport === true)) ?
                                                      <div>
                                                        <p>Generating stats</p>
                                                      </div>
                                                              :
                                                      <div></div>
                      }>

                  <Progress
                      percent={ file.progressBar.processed / file.progressBar.nrOfDocuments * 100 }
                      size="small"
                      trailColor='#fff'
                      showInfo={ false }
                  />
                </Tooltip>
              }
            </div>
            }


            { file && file.type === 'DIRECTORY' && extractDepth(file.folderPath, true) === 0 && filesCount !== 0 && !file.progressRename && file.progressBar &&  file.statisticsReady === 'true' && file.progressBar.progressBarClutter &&  file.progressBar.progressBarClutter === true && !isNewFolder &&

            <div className={'content-progress-extraction'}>



              {
                <Tooltip title={
                          <div>
                            <p>Culling Files</p>
                            <p>Processed: { file.progressBar.processed } / { file.progressBar.nrOfDocuments } </p>
                          </div>
                }>

                  <Progress
                      percent={ file.progressBar.processed / file.progressBar.nrOfDocuments * 100 }
                      size="small"
                      trailColor='#fff'
                      showInfo={ false }
                  />
                </Tooltip>
              }
            </div>
            }

            { file && file.type === 'DIRECTORY' && extractDepth(file.folderPath, true) === 0 && filesCount !== 0 && !file.progressRename && file.progressBar &&  file.statisticsReady === 'true' && file.progressBar.progressBarGeneratingDuplicates &&  file.progressBar.progressBarGeneratingDuplicates === true && !isNewFolder &&

            <div className={'content-progress-extraction stripes-progress'}>



              {
                <Tooltip title={
                  <div>
                    <p>Generating cull report stats.</p>
                  </div>
                }>

                  <Progress
                      percent={ 100 }
                      size="small"
                      trailColor='#fff'
                      showInfo={ false }
                  />
                </Tooltip>
              }
            </div>
            }



            {/*bulk pricessing*/}

            { file.type === 'DIRECTORY' && extractDepth(file.folderPath, true) === 0 && !file.progressBar && file.progressBarForBulk &&
            file.progressBarForBulk.bulkFinished <= file.progressBarForBulk.bulkTotal &&
            // file.bulkProcessingStarted && file.bulkProcessingStarted === 'started' &&
              <div className={ (file.progressBarForBulk.doc_extraction === 'true' || (file.progressBarForBulk.doc_extraction === 'true' && file.progressBarForBulk.doc_extraction !== 'false')) ? 'content-progress-extraction width-progress-extraction' : 'content-progress-extraction' }>
                { (file.progressBarForBulk.doc_extraction === 'true' || (file.progressBarForBulk.doc_extraction === 'true' && file.progressBarForBulk.doc_extraction !== 'false')) &&
                <Tooltip title="Extracting data">
                  <img alt={ imgExtraction } src={ imgExtraction }/>
                </Tooltip>
                }
                <Tooltip title={ <div>
                  <p>Total files: { file.progressBarForBulk.bulkTotal } </p>
                  <p>Successfully redacted: { file.progressBarForBulk.bulkFinished }</p>
                  <p>Failed redacted: { file.progressBarForBulk.bulkFailedForAI }</p>
                  <p>Remaining to be redacted: { file.progressBarForBulk.bulkStarted }</p>
                </div> }>
              <Progress
                percent={ file.progressBarForBulk.bulkFinished / file.progressBarForBulk.bulkTotal * 100 }
                size="small"
                strokeColor='#3ED8DB'
                trailColor='#fff'
                showInfo={ false }
              />
                </Tooltip>
              </div>
            }
            { file.type === 'DIRECTORY' && extractDepth(file.folderPath, true) === 0 && file.progressRename && (file.progressRename.nrOfDocRenamed < file.progressRename.total) &&
            <div className={ 'content-progress-extraction'}>
              <Tooltip title="Rename data">
                <img alt={imgExtraction} src={imgExtraction}/>
              </Tooltip>
              <Tooltip title={ <div>
                <p>Rename: { file.progressRename.nrOfDocRenamed } / { file.progressRename.total } </p>
              </div> }>
                <Progress
                    percent={ file.progressRename.nrOfDocRenamed / file.progressRename.total * 100 }
                    size="small"
                    strokeColor='#3ED8DB'
                    trailColor='#fff'
                    showInfo={ false }
                />
              </Tooltip>
            </div>
            }
          </div>
          {/*// }*/ }
        </div>
        <div className='content-file__status-icons'>
          <ContextMenuTrigger id={'context-menu-status-icons'}>
            <p className="status-icon-grid-view">
              { file.systemPropertyFileStatusIcon && file.systemPropertyFileStatusIcon.includes('v211') && !file.systemPropertyFileStatusColor &&
                <Tooltip title={file.systemPropertyFileStatus}><span className={ file.systemPropertyFileStatusIcon }></span></Tooltip>
              }
              { file.systemPropertyFileStatusIcon && !file.systemPropertyFileStatusColor &&
              <Tooltip title={file.systemPropertyFileStatus}><Icon type={ file.systemPropertyFileStatusIcon } /></Tooltip>
              }
              { file.systemPropertyFileStatusColor && !file.systemPropertyFileStatusIcon &&
              <Tooltip title={file.systemPropertyFileStatus}><span style={{backgroundColor: file.systemPropertyFileStatusColor }} className="color-status-file"></span></Tooltip>
              }
              { file.systemPropertyFileStatusIcon && file.systemPropertyFileStatusColor &&
              <Tooltip title={file.systemPropertyFileStatus}><span className={ file.systemPropertyFileStatusIcon }></span></Tooltip>
              }
              {/*{file?.systemPropertyFileStatus === 'approved' && <Tooltip title="Approved"><span className="icon-v12-file-approved"></span></Tooltip>}*/}
              {/*{file?.systemPropertyFileStatus === 'redacted' && <Tooltip title="For Review"><span className="icon-v12-file-redacted"></span></Tooltip> }*/}
              {/*{file?.systemPropertyFileStatus === 'sent' && <Tooltip title='Sent'><span className="icon-v12-file-sent"></span></Tooltip> }*/}
            </p>
          </ContextMenuTrigger>
        </div>
      </div>
      { !isNewFolder && !renameFileVisible &&
      <span className="content-file__name is-draggable new-content-file-name" key={ 2 }>
      <Tooltip title={ name }>
        <span className="name-file-smart-box">{ name }</span>
      </Tooltip>
    </span>
      }
    </ContextMenuTrigger>
  )
}

ClickableBox.defaultProps = {
  onClick: noop,
  onDoubleClick: noop,
}
const EnhancedClickableBox = pleaseStopTriggeringClicksOnDoubleClick(ClickableBox)

class SmartBoxFile extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectFolder: false,
      clickTimeout: null,
      selectedFolderStatus: false,
      contentClassSelectFile: '',
      selectFileDates: [],
      renderingBlock: '',
      previewVisible: false,
      previewVisibleOcr: false,
      renameFileVisible: false,
      newNameFolder: '',
      fileContentOcr: '',
      documentNoteVisible: false,
      isIntervalStartedForBulkFinalise: false,
      currentLocation: window.location.pathname,
      folderFinaliseId: '',
      disabledOnClickedFolder: false,
      validEditFolderName: { success: true, reason: 'Invalid name' },
      positionOnRenameFolder: false,
      onSelectedFiles: [],
      onFileEnterOnPanel: {},
      visiblePageModalDownload: false,
      fileSelectDownload: {},
      visibleCollectData: false,
      boxParentBase: {},
      onSelectedFileDrop: {},
      loadingDeleteBox: false,
      loadingCancelDeleteBox: false,
    }
    this.windowDocument = null
  }

  componentDidMount() {
    if (this.props.isNewFolder) {
      this.newFolderInput.focus()
    }
    this.onGetParentBase()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isNewFolder !== this.props.isNewFolder && this.props.isNewFolder) {
      this.newFolderInput.focus()
    }
    if (prevProps.isNewFolder !== this.props.isNewFolder && !this.props.isNewFolder) {
      this.newFolderInput = null
    }
    if (prevProps !== this.props) {
      this.forceUpdate()
    }
  }
   onGetParentBase = () => {
     const { folder, smartBoxBaseList } = this.props
     let parentName = ''
     let idBox = {}
     if (folder && typeof folder.relativePath !== 'undefined') {
       parentName = folder.relativePath.split('/')[1]
     }
     smartBoxBaseList && smartBoxBaseList.length > 0 && smartBoxBaseList.forEach((list) => {
       if (parentName === list.name) {
         idBox = list
       }
     })
     this.setState({
       boxParentBase: idBox
     })
   }
  startIntervalFinalise = () => {
    if (this.props.file) {
      const { currentLocation } = this.state
      const { file } = this.props
      this.setState({
        isIntervalStartedForBulkFinalise: true
      }, () => {
        this.props.setSmartBoxIntervalForBulkFinalise(currentLocation)
        const bulkReadyInterval = setInterval(() => {
          apiSmartBoxCheckBulkFolderFinalise(file.id).then((res) => {
            const message = <span>{'Finalise folder process finished for '}<span
              style={ { textDecoration: 'underline', cursor: 'pointer' } }><span
                onClick={ () => this.props.navigate('/smart-box/management') }>{ file.name }</span></span></span>
            if (res.data) {
              notification.success({ message: message, duration: 1000000 })
              this.props.removeSmartBoxIntervalForBulkFinalise(this.props.intervalForBulkRedaction.filter((path) => path !== currentLocation))
              if (window.location.pathname === currentLocation) {
                this.setState({
                  isIntervalStartedForBulkFinalise: false
                }, () => this.props.fetchFiles())
              }
              clearInterval(bulkReadyInterval)
            }
          }).catch((error) => {
            clearInterval(bulkReadyInterval)
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        }, 10000)
        this.props.addIntervalToApplication(bulkReadyInterval)
      })
    }
  }

  routeChangeFolderOpened = () => {
    const { file } = this.props
    this.props.toggleFilePreview(false, {})
    const selectedBox = []
    selectedBox.push(file.id)
    if (file.type === 'DIRECTORY') {
      const path = `/smart-box/folder/${ file.id }`
      this.props.navigate(path)
      //this.props.removeAllSelected()
      this.props.setSmartBoxCurrentFolder(file.id)
      this.props.setSmartBoxCurrentFolderName(file)
      this.props.setSmartBoxFolderClosed('')
      this.props.setSmartBoxCurrentNewFilters({checkedStatus: [], checkedTag: []})
      this.props.setSmartBoxCurrentNewFiltersSort('creation date')
      if (file.depthFromRoot !== 0 || file.depth !== 0) {
        this.props.setSmartBoxPreviousCurrentPage(this.props.currentPage, 'initial')
      }
      if (file.depthFromRoot === 0) {
        this.props.setSmartBoxSelectedKeys(selectedBox)
      }
      if (file.depthFromRoot === 0 || file.depth === 0) {
        this.props.setSmartBoxParentStatisticsReady(file.statisticsReady)
      }
      if (this.props.smartBoxTree.length === 0) {
        this.props.setSmartBoxLoadTree(true)
      }
    } else {
      this.props.onDoubleClickFilePressed(file)
      this.props.onDoubleClickSelectedFile(file)
    }
  }

  routeChangeFolderSettings(e, record, action) {
    e.stopPropagation()
    const { file } = this.props
    if (file && file.type === 'DIRECTORY') {
      const path = `/smart-box/folder/${ file.id }/analysis-settings`
      this.props.setSmartBoxCurrentFolder(file.id)
      this.props.setSmartBoxCurrentFolderName(file)
      this.props.navigate(path)
      this.props.setSmartBoxSecurityConfiguration('security-settings')
    }
  }

  routeChangeDataInsight(e) {
    e.stopPropagation()
    const { file } = this.props
    if (file.type === 'DIRECTORY') {
      const path = `/smart-box/folder/${ file.id }/data-insights/dashboard`
      this.props.navigate(path)
      this.props.setSmartBoxCurrentFolder(file.id)
      this.props.setSmartBoxCurrentFolderName(file)
      this.props.setSmartBoxSelectedDuplicateFile({ }, '', '')
    }
  }

  routeChangeAnalysisSettings(e) {
    e.stopPropagation()
    const { file } = this.props
    if (file && file.type === 'DIRECTORY') {
      const path = `/smart-box/folder/${ file.id }/analysis-settings`
      this.props.setSmartBoxParentStatisticsReady(file.statisticsReady)
      this.props.navigate(path)
      this.props.setSmartBoxCurrentFolder(file.id)
      this.props.setSmartBoxCurrentFolderName(file)
    }
  }

  routeChangeFolderRedaction(e) {
    e.stopPropagation()
    const { file } = this.props
    if (file.type === 'DIRECTORY') {
      const path = `/smart-box/folder/${ file.id }/folder-redaction`
      this.props.navigate(path)
      this.props.setSmartBoxCurrentFolder(file.id)
      this.props.setSmartBoxCurrentFolderName(file)
    }
  }
  routeChangeCull(e) {
    e.stopPropagation()
    const { file } = this.props
    if (file.type === 'DIRECTORY') {
      const path = `/smart-box/folder/${ file.id }/cull/duplicates`
      this.props.navigate(path)
      this.props.setSmartBoxCurrentFolder(file.id)
      this.props.setSmartBoxCurrentFolderName(file)
    }
  }
  onCancelBoxRedaction(e) {
    e.stopPropagation()
    const { file } = this.props
    cancelBoxRedaction(file.id).then((response) => {
      if (response.status === 200) {
        this.props.fetchFiles()
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  routeChangeFolderSecuritySettings(e) {
    e.stopPropagation()
    const { file } = this.props
    if (file && file.type === 'DIRECTORY') {
      const path = `/smart-box/folder/${ file.id }/security-settings`
      this.props.navigate.push(path)
    }
  }
  handleDeleteManyFile = (file) => {
    const { listKeysFiles, currentPage, listKeysFilesDeleted } = this.props
    const { loadingDeleteBox, loadingCancelDeleteBox } = this.state
    if (listKeysFiles && listKeysFiles.length > 0) {
      const antIcon = <Icon type="loading" style={{ fontSize: 15 }} spin />;
      const antIconCancel = <Icon type="loading" style={{ fontSize: 15 }} spin />;
      const deletedBox = []
      const deletedFolder = []
      if (this.props.root) {
        listKeysFiles && listKeysFiles.length > 0 && listKeysFiles.forEach((item) => {
          if (!item.includes('.')) {
            deletedBox.push(item)
          }
        })
      } else {
        listKeysFiles && listKeysFiles.length > 0 && listKeysFiles.forEach((item) => {
          if (item.includes('.')) {
            deletedBox.push(item)
          }
        })
      }
      this.setState({
        loadingDeleteBox: true,
        loadingCancelDeleteBox: true,
      }, () => {
        if (listKeysFiles.length === 1 && file && file.workflow_ticket_id != null) {
          customConfirm({
            title: <span className="icon-v23-information"></span>,
            content: `Deletion failed.The selected box is part of a workflow.`,
            confirmButtonText: 'Okay',
            cancelButtonText: '',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-error modal-delete-failed',
            centered: true,
            cancelClassName: 'cancel-button ',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
              this.setState({
                loadingCancelDeleteBox: false,
                loadingDeleteBox: false,
              })
            },
            rejectAction: () => {
              this.setState({
                loadingCancelDeleteBox: false,
                loadingDeleteBox: false,
              })
            }
          })
        } else
        {
        customConfirm({
          content: `Are you sure you want to delete selected ${listKeysFiles.length > 1 ? 'items' : 'item'} ?`,
          confirmButtonText: <span><Spin spinning={loadingDeleteBox}
                                         indicator={antIcon}/><span>{'Delete'}</span></span>,
          cancelButtonText: <span><Spin spinning={loadingCancelDeleteBox}
                                        indicator={antIconCancel}/><span>{'Cancel'}</span></span>,
          mask: true,
          maskClosable: false,
          closeHaveLogic: true,
          className: 'asset-register-modal delete-selected-box',
          centered: true,
          cancelClassName: 'cancel-button',
          confirmClassName: 'confirm-button',
          rejectAction: () => {
            this.setState({
              loadingCancelDeleteBox: false
            })
          },
          confirmAction: () => {
            const parametersForDelete = {
              id: listKeysFilesDeleted
            }
            deleteSelected(parametersForDelete).then((response) => {
              if (response.status === 200) {
                // if (response.data === 'Please wait until upload finishes!') {
                //   CustomNotificationManager.info('', 'Please wait until upload finishes!', { duration: 5 })
                // }
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                  CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                  CustomNotificationManager.info(response.data.message, 'Info')
                }
                const remainingFilesOnCurrentPage = this.props.totalRecords % this.props.pageLimit - listKeysFiles.length
                if ((remainingFilesOnCurrentPage === 0) && (Math.ceil(this.props.totalRecords / this.props.pageLimit) >= currentPage)) {
                  const parameters = {
                    currentPage: currentPage - 1 ? currentPage - 1 : 1,
                    pageLimit: this.props.pageLimit,
                    sort: 'A-Z'
                  }
                  this.props.onPageChanged(parameters)
                } else {
                  this.props.setActiveDeleteFile(true)
                  this.props.fetchFiles()
                }
                this.props.setSmartBoxDeletedFolder(listKeysFiles)
                this.props.setSmartBoxSelectedFiles([])
                this.setState({
                  loadingDeleteBox: false
                })
              }
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              } else {
                console.log(error)
              }
            })
          }
        })
        this.setState({
          loadingCancelDeleteBox: false
        })
      }
      })
    }
  }
  showConfirm = async(file, e) => {
    e.stopPropagation()
    e.preventDefault()
    const parentId = {
      id: file.id,
      filePath: file.path,
      folder: file.type === 'DIRECTORY',
    }
    const fileList = await getFolderChildren(parentId)
    this.setState({
      childrenIsNotEmpty: fileList.data.length > 0,
      childrenLength: fileList.data.length
    }, () => {
      if(file.workflow_ticket_id != null){
        customConfirm({
          title: <span className="icon-v23-information"></span>,
          content: `Deletion failed.The selected box is part of a workflow.`,
          confirmButtonText: 'Okay',
          cancelButtonText: '',
          mask: true,
          maskClosable: false,
          closeHaveLogic: false,
          className: 'asset-register-modal modal-delete-error modal-delete-failed',
          centered: true,
          cancelClassName: 'cancel-button ',
          confirmClassName: 'confirm-button',
          confirmAction: () => {
          }
        })
      }else{
        customConfirm({
          confirmAction: () => deleteSmartDocument(parentId).then((resp) => {
            if (resp.data === 'Please wait until upload finishes!') {
              CustomNotificationManager.info('', 'Please wait until upload finishes!', { duration: 5 })
            }
            this.props.handleDeleteFile(file)
            this.props.setSmartBoxDeletedFolder([file.id])
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          }),
          title: <span className="content-delete-box"><span className="icon-v19-delete"></span><span>Delete selected Box ?</span></span>,
          content: `${ file.name } ${ this.state.childrenIsNotEmpty ? `contains ${ this.state.childrenLength } items` : '' } ?`,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          mask: true,
          maskClosable: false,
          closeHaveLogic: false,
          className: 'asset-register-modal modal-delete-box',
          centered: true,
          cancelClassName: 'cancel-button',
          confirmClassName: 'confirm-button'
        })
      }


    })
  }

  onSelectImages = (type) => {
    switch (type) {
      case 'svg':
        return 's'
      default:
        return 'sync'
    }
  }

  onSendInfoParents = (file) => {
    const { onSelectedFiles, visiblePageModalDownload } = this.state
    if (!visiblePageModalDownload) {
      this.props.addOrRemoveValue(this.props.file)
    }
    onSelectedFiles.push(this.props.file.id)
    this.props.setSmartBoxClickOutsideFilter(true)
    this.setState({
      onSelectedFiles
    })
    const allNodes = document.getElementsByClassName('content-file')
    for (let i = 0; i < allNodes.length; i++) {
      allNodes[i].classList.remove('drag-select')
    }
  }

  executeAction = (record, action, e) => {
    e.stopPropagation()
    e.preventDefault()
    const { listKeysFiles, filesCount } = this.props
    switch (action) {
      case 'viewer':
        apiSmartBoxPreviewSarDocByThumbnailId(record.id).then((response) => {
          this.windowDocument = generateBlobFromBase64(response.data.doc)
          this.setState({
            renderingBlock: <PDFViewer backend={ PDFJSBackend } src={ this.windowDocument } toolbar={ 0 }/>,
            previewVisible: true
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
        break
      case 'viewer-finalized':
        apiSmartBoxPreviewSarDocFinalizedByThumbnailId(record.id).then((response) => {
          this.windowDocument = generateBlobFromBase64(response.data)
          this.setState({
            renderingBlock: <PDFViewer backend={ PDFJSBackend } src={ this.windowDocument } toolbar={ 0 }/>,
            previewVisible: true
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
        break
      case 'insights':
        this.routeChangeDataInsight(e)
        break
      case 'analysis':
        this.routeChangeAnalysisSettings(e)
        break
      case 'redaction':
        this.routeChangeFolderRedaction(e)
        break
      case 'cull':
        this.routeChangeCull(e)
        break
      case 'cancel-redaction':
        this.onCancelBoxRedaction(e)
        break
      case 'security':
        this.routeChangeFolderSecuritySettings(e)
        break
      case 'redacting':
        this.props.onFilePressed(record)
        break
      case 'rename':
     /*   if (filesCount > 0 && (record.statisticsReady === 'false' || record.statisticsReady === null)) {
          return false
        } else {
          this.onChangeRenameFile(record.id)
        }*/
        this.onChangeRenameFile(record.id)
        break
      // case 'cut-folder':
      //   this.onCutFolder(record)
      //   break
      // case 'paste':
      //   this.onPasteFolder(record)
      //   break
      case 'export':
        //this.onDownloadZip(record.id, record.name)
        this.onViewOptionsFileModalDownload(record)
        break
      case 'download-file':
        this.onDownloadFileOriginal(record)
        break
      // case 'cut':
      //   this.onCutDocuments(record)
      //   break
      case 'delete':
        if (listKeysFiles && listKeysFiles.length > 0) {
          this.handleDeleteManyFile(record)
        } else {
          this.showConfirm(record, e)
        }
        break
      case 'info':
        this.props.toggleFilePreview(true, record)
        break
      case 'finalise-document':
        this.onFinaliseDocument(e)
        break
      case 'ocr':
        this.previewFilfeOcr(e)
        break
      case 'security-settings':
        this.routeChangeFolderSettings(e, record, action)
        break
      case 'add-note':
        this.setState({ documentNoteVisible: true })
        break
      case 'finalise-folder':
        this.onFinaliseFolder(record.id)
        break
      case 'export-document':
        this.onExportDocument(record.id, record.name, record)
        break
      case 'audit-file':
        this.onDownloadAuditFile(record.id, record.name, record)
        break
      case 'reanalyse-with-ai':
        this.reanalyseWithAI()
        break
      default:
        break
    }
  }
  onViewOptionsFileModalDownload = (file) => {
    this.props.onStatusOpenModalExport(true)
    this.setState({
      visiblePageModalDownload: true,
      visibleCollectData: true,
      fileSelectDownload: file
    })
  }
  onCancelModalDownload = (status) => {
    this.props.onStatusOpenModalExport(status)
    this.setState({
      visiblePageModalDownload: status,
    })

  }
  onDownloadFileOriginal = (record) => {
    const { clientId } = this.props
    const indexRelativePath = record.folderPath ? record.folderPath.indexOf(`smartBox_${ clientId }`) : ''
    const newRelativPath = record.folderPath ? record.folderPath.substr(indexRelativePath + `smartBox_${ clientId }`.length) : ''
    const finallyPath = newRelativPath ? newRelativPath.substr(1, newRelativPath.length) : ''
    const nameFile = record.name
    const nameFileWithoutExtension = record.nameFileWithoutExtension
    const typeFile = record.type
    const hasRight = true
    const finallyPathDownload = `${ finallyPath }${ nameFile }.${ typeFile }`
    const created = moment(record.creationDate).format('DD.MM.YYYY HH:mm:ss')
    const modified = moment(record.modificationDate).format('DD.MM.YYYY HH:mm:ss')
    let createDate = created ? created.replaceAll('.', '-') : ''
    createDate = createDate ? createDate.replaceAll(':', '-') : ''
    let modifiedDate = modified ? modified.replaceAll('.', '-') : ''
    modifiedDate = modifiedDate ? modifiedDate.replaceAll(':', '-') : ''
    let uploadDate = record.uploadTime ? record.uploadTime.replaceAll('.', '-') : ''
    uploadDate = uploadDate ? uploadDate.replaceAll(':', '-') : ''
    const fileId = record.id ? record.id : ''
    const FileDownloadDTO = {
      fileId: fileId,
      hasRight: hasRight
    }
    apiSmartBoxDownloadFile(FileDownloadDTO).then((response) => {
      if (response.status === 200) {
        //FileDownload(response.data, `${ nameFile }.${ typeFile }`)
        FileDownload(response.data, `${ createDate ? createDate : modifiedDate ? modifiedDate : uploadDate }_Original ${ nameFileWithoutExtension }.${ typeFile }`)
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onCutDocuments = (record) => {
    const { filesTable, listSelectedNameIdCutPaste } = this.props
    const listProcessAi = []
    const listPreparingRedaction = []
    const listBulkRedaction = []

    filesTable && filesTable.length > 0 && filesTable.forEach((file) => {
      if (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length > 0 && listSelectedNameIdCutPaste.includes(file.id)) {
        if (file.type !== 'DIRECTORY' && file.isPdf === 'false') {
          listPreparingRedaction.push(file.name)
        }
        if (file.type !== 'DIRECTORY' && file.aiFinished === 'false') {
          listProcessAi.push(file.name)
        }
        if (file.type === 'DIRECTORY' && file.bulkProcessingStarted && file.bulkProcessingStarted === 'started') {
          listBulkRedaction.push(file.name)
        }
        const customNotificationProcessAi = listProcessAi && listProcessAi.length > 0 ? `The ${ listProcessAi.length === 1 ? 'file' : 'files' } ${ listProcessAi } ${ listProcessAi.length === 1 ? 'is' : 'are' } in Ai process.` : ''
        const customNotificationPreparingRedaction = listPreparingRedaction && listPreparingRedaction.length > 0 ? `The ${ listPreparingRedaction.length === 1 ? 'file' : 'files' } ${ listPreparingRedaction } ${ listPreparingRedaction.length === 1 ? 'is' : 'are' }
           in preparing ${ listPreparingRedaction.length === 1 ? 'document' : 'documents' } for redacting.` : ''
        const customNotificationBulkRedaction = listBulkRedaction && listBulkRedaction.length > 0 ? `The ${ listBulkRedaction.length === 1 ? 'file' : 'files' } ${ listBulkRedaction } ${ listBulkRedaction.length === 1 ? 'is' : 'are' }
           in bulk redaction in progress.` : ''

        if ((file.type !== 'DIRECTORY' && file.isPdf === 'false') || (file.type !== 'DIRECTORY' && file.aiFinished === 'false') || (file.type === 'DIRECTORY' && file.bulkProcessingStarted && file.bulkProcessingStarted === 'started')) {
          CustomNotificationManager.error(`${ customNotificationPreparingRedaction } ${ customNotificationProcessAi } ${ customNotificationBulkRedaction }`, 'Aborted')
        }
      }
    })
    this.props.onSendStatusPaste(true)
  }
  onCutFolder = (record) => {
    const { filesTable, listSelectedNameIdCutPaste } = this.props
    const listProcessAi = []
    const listPreparingRedaction = []
    const listBulkRedaction = []

    filesTable && filesTable.length > 0 && filesTable.forEach((file) => {
      if (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length > 0 && listSelectedNameIdCutPaste.includes(file.id)) {
        if (file.type !== 'DIRECTORY' && file.isPdf === 'false') {
          listPreparingRedaction.push(file.name)
        }
        if (file.type !== 'DIRECTORY' && file.aiFinished === 'false') {
          listProcessAi.push(file.name)
        }
        if (file.type === 'DIRECTORY' && file.bulkProcessingStarted && file.bulkProcessingStarted === 'started') {
          listBulkRedaction.push(file.name)
        }
        const customNotificationProcessAi = listProcessAi && listProcessAi.length > 0 ? `The ${ listProcessAi.length === 1 ? 'file' : 'files' } ${ listProcessAi } ${ listProcessAi.length === 1 ? 'is' : 'are' } in Ai process.` : ''
        const customNotificationPreparingRedaction = listPreparingRedaction && listPreparingRedaction.length > 0 ? `The ${ listPreparingRedaction.length === 1 ? 'file' : 'files' } ${ listPreparingRedaction } ${ listPreparingRedaction.length === 1 ? 'is' : 'are' }
           in preparing ${ listPreparingRedaction.length === 1 ? 'document' : 'documents' } for redacting.` : ''
        const customNotificationBulkRedaction = listBulkRedaction && listBulkRedaction.length > 0 ? `The ${ listBulkRedaction.length === 1 ? 'file' : 'files' } ${ listBulkRedaction } ${ listBulkRedaction.length === 1 ? 'is' : 'are' }
           in bulk redaction in progress.` : ''

        if ((file.type !== 'DIRECTORY' && file.isPdf === 'false') || (file.type !== 'DIRECTORY' && file.aiFinished === 'false') || (file.type === 'DIRECTORY' && file.bulkProcessingStarted && file.bulkProcessingStarted === 'started')) {
          CustomNotificationManager.error(`${ customNotificationPreparingRedaction } ${ customNotificationProcessAi } ${ customNotificationBulkRedaction }`, 'Aborted')
        }
      }
    })
    this.props.onSendStatusPaste(true)
  }
  onPasteFolder = (record) => {
    let { clientId, listSelectedFiles, listSelectedNameIdCutPaste } = this.props

    listSelectedNameIdCutPaste = listSelectedNameIdCutPaste.filter(item => item !== record.id)

    this.props.onSendStatusPaste(false)
    const filterFilesIdSelected = []
    listSelectedFiles && listSelectedFiles.length > 0 && listSelectedFiles.forEach((file) => {
      if (file.type !== 'DIRECTORY' && file.isPdf === 'false') {
        filterFilesIdSelected.push(file.id)
      }
      if (file.type !== 'DIRECTORY' && file.aiFinished === 'false') {
        filterFilesIdSelected.push(file.id)
      }
      if (file.type === 'DIRECTORY' && file.bulkProcessingStarted && file.bulkProcessingStarted === 'started') {
        filterFilesIdSelected.push(file.id)
      }
    })
    let duplicateNewListId = []
    duplicateNewListId = new Set([...filterFilesIdSelected])
    const filterFilesIdFinally = [...duplicateNewListId]

    const indexDestinationPath = record.path ? record.path.indexOf(`smartBox_${ clientId }`) : ''
    const newDestinationPath = record.path ? record.path.substr(indexDestinationPath + `smartBox_${ clientId }`.length) : ''
    const newDestinationFinal = newDestinationPath ? newDestinationPath.substr(1, newDestinationPath.length) : ''
    const idDestinationFolder = record.id
    let checkListNameId = []
    checkListNameId = new Set([...listSelectedNameIdCutPaste])
    let checkListNameIdFinally = [...checkListNameId]
    checkListNameIdFinally = checkListNameIdFinally && checkListNameIdFinally.length > 0 ? checkListNameIdFinally.filter((checkId) => checkId !== idDestinationFolder) : []

    filterFilesIdFinally && filterFilesIdFinally.length > 0 && filterFilesIdFinally.forEach((idFile) => {
      checkListNameIdFinally = checkListNameIdFinally && checkListNameIdFinally.length > 0 ? checkListNameIdFinally.filter((checkId) => checkId !== idFile) : []
    })
    const MoveFileDTO = {
      destinationRelativePath: newDestinationFinal,
      id: checkListNameIdFinally
    }
    apiSmartBoxMoveFiles(MoveFileDTO).then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          this.props.fetchFiles()
        }, 2000)
      }
    }).catch((error) => {
      setTimeout(() => {
        this.props.fetchFiles()
      }, 2000)
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
   onExportDocument = (id, name, record) => {
     const created = moment(record.creationDate).format('DD.MM.YYYY HH:mm:ss')
     const modified = moment(record.modificationDate).format('DD.MM.YYYY HH:mm:ss')
     let createDate = created ? created.replaceAll('.', '-') : ''
     createDate = createDate ? createDate.replaceAll(':', '-') : ''
     let modifiedDate = modified ? modified.replaceAll('.', '-') : ''
     modifiedDate = modifiedDate ? modifiedDate.replaceAll(':', '-') : ''
     let uploadDate = record.uploadTime ? record.uploadTime.replaceAll('.', '-') : ''
     uploadDate = uploadDate ? uploadDate.replaceAll(':', '-') : ''
     apiSmartBoxExportDocument(id).then((response) => {
       FileDownload(response.data, `${ createDate ? createDate : modifiedDate ? modifiedDate : uploadDate }_Redacted ${ name }.pdf`)
     }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
     })
   }
  onDownloadAuditFile = (id, name, record) => {
    const created = moment(record.creationDate).format('DD.MM.YYYY HH:mm:ss')
    const modified = moment(record.modificationDate).format('DD.MM.YYYY HH:mm:ss')
    let createDate = created ? created.replaceAll('.', '-') : ''
    createDate = createDate ? createDate.replaceAll(':', '-') : ''
    let modifiedDate = modified ? modified.replaceAll('.', '-') : ''
    modifiedDate = modifiedDate ? modifiedDate.replaceAll(':', '-') : ''
    let uploadDate = record.uploadTime ? record.uploadTime.replaceAll('.', '-') : ''
    uploadDate = uploadDate ? uploadDate.replaceAll(':', '-') : ''
    apiSmartBoxExportAudit(id).then((response) => {
      FileDownload(response.data, `${ createDate ? createDate : modifiedDate ? modifiedDate : uploadDate }_Audit ${ name }.pdf`)
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  reanalyseWithAI = () => {
    if (this.props.listKeysFiles.length) {
      const data = {
        clientId: this.props.clientId,
        list: this.props.listKeysFiles
      }
      sendToReanalyseWithAI(data).then((response) => {
        if (response.status === 200) {
          this.props.fetchFiles()
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }

  onFinaliseDocument = () => {
    const { file } = this.props
    const fileId = file.id
    apiSmartBoxFinalisedDocument(fileId).then(() => {
      this.props.fetchFiles()
    })
  }

  onFinaliseFolder = (id) => {
    apiSmartBoxFinalisedFolder(id).then(() => {
      this.props.refreshFolder()
      this.startIntervalFinalise()

      this.setState({
        folderFinaliseId: id
      })
    }).catch((error) => {
      const errorMessage = 'Folder id does not exist'
      if (error?.response?.status === 500) {
        if (typeof error.response.data.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
          CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
          this.props.navigate('/smart-box')
        }
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onDownloadZip = (id, name) => {
    //CustomNotificationManager.info('Finalised folder is being downloaded as a zip. Please wait...', 'Info')
    apiSmartBoxZip(id, name).then((response) => {
      FileDownload(response.data, `${ name }.zip`)
    }).then(() => {
      CustomNotificationManager.cleanBuffer()
    })
  }
  onChangeRenameFile = (id) => {
    this.setState({
      renameFileVisible: true,
      positionOnRenameFolder: true
    })
  }
  handleNewRenameFolderPressed = (e) => {
    const { file } = this.props
    const { validEditFolderName } = this.state
    validEditFolderName.success= true
    if (e.key === 'Enter') {
      this.setState({
        newNameFolder: e.target.value,
        validEditFolderName
      })
      if (!nameRgex.test(e.target.value) || !this.props.isUnique(e.target.value)) {
        validEditFolderName.success = false
        if (! nameRgex.test(e.target.value)) {
          validEditFolderName.reason = 'Invalid name'
        }
        if (! this.props.isUnique(e.target.value)) {
          validEditFolderName.reason = 'Already exist'
          if (e.target.value === file.name) {
            validEditFolderName.reason = 'Is old name'
          }

        }
        this.setState({
          validEditFolderName,
          renameFileVisible: true,
          positionOnRenameFolder: true
        })
      }
      if (file.name !== e.target.value && nameRgex.test(e.target.value) && this.props.isUnique(e.target.value)) {
        validEditFolderName.success = true
        this.setState({
          renameFileVisible: false,
          positionOnRenameFolder: false,
          newNameFolder: '',
          validEditFolderName
        })
        const newFileName = e.target.value.trim()

        const newRenameFolder = {
          id: file.id,
          name: newFileName
        }
        getRenameFile(newRenameFolder).then((response) => {
          if (response.status === 200) {
            this.setState({
              newNameFolder: ''
            }, () => {
              setTimeout(() => {
                //this.props.fetchFiles()
                this.props.changeFileName(file.id, newFileName)
                this.props.setSmartBoxRenamedFolder({ id: file.id, newName: newFileName })
                this.props.onCallProgressRename()
              }, 2500)
            })
            if(response.data.title.toLowerCase() === 'success') {
              CustomNotificationManager.success(response.data.message, 'Success')
            } else {
              CustomNotificationManager.info(response.data.message, 'Info')
            }
          }
        }).catch((error) => {
          const errorMessage = 'Folder id does not exist'
          if (error?.response?.status === 500) {
            if (typeof error.response.data.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
              CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
              const path = '/smart-box'
              this.props.navigate(path)
            }
          }else if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      }
    }
  }

  closeInput = () => {
    this.setState({
      renameFileVisible: false,
      disabledOnClickedFolder: true,
      positionOnRenameFolder: false,
      newNameFolder: ''
    })
  }

  handleCancel = () => this.setState({ previewVisible: false, renderingBlock: null }, () =>
    window.URL.revokeObjectURL(this.windowDocument))

  handleCancelModal = () => this.setState({ previewVisibleOcr: false }, () =>
    window.URL.revokeObjectURL(this.windowDocument))

  previewFilfeOcr = () => {
    const { file } = this.props
    const fileId = file.id
    const getOCRFileTextDTO = {
      idDocument: fileId
    }
    getOcrFile(getOCRFileTextDTO).then((response) => {
      this.setState({
        //fileContentOcr: response.data,
        fileContentOcr: typeof response.data === "object" ? '' : response.data,
        previewVisibleOcr: true
      })
    }).catch((error) => {
      if (error?.response?.status === 500) {
        const errorMessage = 'OCR transformation was not successful for this file.'
        CustomNotificationManager.error(errorMessage, 'Error')
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  closeDocumentNote = () => {
    this.setState({
      documentNoteVisible: false
    })
  }

  componentWillUnmount() {
    window.URL.revokeObjectURL(this.windowDocument)
    clearTimeout(this.clickTimeout)
  }

  geThumbnailOrImageSrc = (type, parent, fileId, file) => {
    let response = getImageSrc(type, parent)
    if (type !== 'DIRECTORY' && file.conversionStatus !== 'Failed') {
      if (this.props.listOfThumbnails && this.props.listOfThumbnails.length) {
        this.props.listOfThumbnails.forEach((item) => {
          if (item.id === fileId) {
            response = item.thumbnailSrc
          }
        })
      }
    }
    return response
  }
  onMouseEnterOnPanel(e, file) {
    this.setState({
      onFileEnterOnPanel: file,
      onSelectedFileDrop: file,
    })

  }
  onMouseLeaveOnPanel() {
    this.setState({
      onFileEnterOnPanel: {}
    })
  }
  handleClick = (e, data) => {
    this.setState(({ logs }) => ({
      logs: [`Clicked on menu ${ data.item }`, ...logs]
    }))
  }
  routeChangeDataInsightDuplicate = (e, file) => {
    e.stopPropagation()
    const { folder, boxIdBasePath, filesCheckDuplicates } = this.props
    let extractHash = ''
    filesCheckDuplicates && filesCheckDuplicates.length > 0 && filesCheckDuplicates.forEach((fileD) => {
      if ((fileD.path === file.folderPath + file.path) || (fileD.path === `${ file.folderPath + file.path }.${ file.type }`)) {
        extractHash = fileD.hash
      }
    })
    if (folder.type === 'DIRECTORY') {
      const path = `/smart-box/folder/${ boxIdBasePath }/cull/duplicates`
      this.props.navigate(path)
      this.props.setSmartBoxSelectedDuplicateFile(file, 'duplicates', extractHash)
    }
  }

  removeDropNodeSelection = () => {
    const allNodes = document.getElementsByClassName('content-file drop-active')
    for (let i = 0; i < allNodes.length; i++) {
      allNodes[i].classList.remove('drop-active')
    }
  }

  onDrop = (event, dropToFile, onSource) => {
    const { smartBoxSourceFile } = this.props

    
    if(dropToFile.type !== 'DIRECTORY') {
      this.removeDropNodeSelection()
      return
    }
    
    let { clientId, smartBoxSelectedFiles, listSelectedFiles } = this.props
    if(smartBoxSelectedFiles.length === 0 && (!listSelectedFiles || !listSelectedFiles.length) ){
      return CustomNotificationManager.info('Please select at least one item to move its location')
    }
    const fileId = event.dataTransfer.getData('Text').replace('file-','')
    const fileType = event.dataTransfer.getData('type')

    const path = dropToFile.path.substr(dropToFile.path.indexOf(`smartBox_${ clientId }`) +`smartBox_${ clientId }`.length + 1)
    const arIds = [], arFoldersIds = []
    let foldersCount = 0
    let filesCount = 0

    if(smartBoxSelectedFiles.length === 0) {
      smartBoxSelectedFiles = listSelectedFiles
    }
    for (let i=0; i<smartBoxSelectedFiles.length; i++) {
      arIds.push(smartBoxSelectedFiles[i]?.id ? smartBoxSelectedFiles[i].id : smartBoxSelectedFiles[i])
      if (smartBoxSelectedFiles[i].type === 'DIRECTORY') {
        arFoldersIds[i] = smartBoxSelectedFiles[i]?.id ? smartBoxSelectedFiles[i].id : smartBoxSelectedFiles[i]
        foldersCount++
      }
      else filesCount++
    }
    // if no file was selected in Folder Opened, move only the file that was dragged
    if (arIds.length === 0) {
      arIds[arIds.length] = fileId
      if(fileType === 'DIRECTORY') {
        arFoldersIds[arFoldersIds.length] = fileId
      }
    }

    let message = ''
    if (foldersCount) message += `${ foldersCount } folder${ foldersCount > 1 ? 's' : '' }`
    if (foldersCount && filesCount) message += ' and '
    if (filesCount) message += `${ filesCount } file${ filesCount > 1 ? 's' : '' }`
    if (!message) {
      message = 'this item'
    }

    customConfirm({
      confirmAction: () => {
        const MoveFileDTO = {
          destinationRelativePath: path,
          id: arIds
        }
        apiSmartBoxMoveFiles(MoveFileDTO).then((res) => {
          if(res.data.title.toLowerCase() === 'success'){
            CustomNotificationManager.success(res.data.message, 'Success')
          }else if(res.data.title.toLowerCase() === 'info'){
            CustomNotificationManager.info(res.data.message, 'Info')
          }else{
            return CustomNotificationManager.warning(res.data.message, 'Warning')
          }
          if(arIds) this.props.setSmartBoxMovedFiles(arIds)
          if(arFoldersIds) this.props.setSmartBoxMovedFolders(arFoldersIds)
          if(dropToFile?.id) this.props.setSmartBoxNewParentOfMovedFolders(dropToFile.id) // id of the new parent -- "destinationId"
          this.props?.folder?.id ? this.props.setSmartBoxOldParentOfMovedFolders(this.props.folder.id) : this.props.refreshFolder() //old parent Id
        }).catch((e) => {
          CustomNotificationManager.warning('An error occurred moving this file', 'Error')
        })
        this.removeDropNodeSelection()
      },
      rejectAction: () => {
        this.removeDropNodeSelection()
      },
      title: 'Move file ?',
      content: `Are you sure you want to move: ${ message } to Folder "${ dropToFile.name }`,
      confirmButtonText: 'YES',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: true,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button'
    })
  }

  onDragOver = (event) => {
    this.removeDropNodeSelection()
    let el = event.currentTarget

     if(!el.classList.contains('content-file')) {
      el = el.closest('.content-file')
    }
    el.classList.add('drop-active')
    event.preventDefault()
  }
  onCloseCollectData = (status) => {
    this.setState({
      visibleCollectData: status,
    })

  }
  onDragStartFile = (e, file) => {
    this.props.setSmartBoxSourceDrop(file)
    this.setState({
      onSelectedFileDrop: file
    })
  }
  render() {
    const { file, isNewFolder, parent, listKeysFiles, disabledOnClicked, validNewFolderName, folderNewDepth, filesCount, setActiveFile, activeFile, filesErrors, getFilesDuplicates, getFilesProprieties,
      progressBulkData } = this.props
    const { previewVisible, renderingBlock, previewVisibleOcr, renameFileVisible, validEditFolderName, fileContentOcr, documentNoteVisible, positionOnRenameFolder, onFileEnterOnPanel, visiblePageModalDownload,
      fileSelectDownload, visibleCollectData, boxParentBase, onSelectedFileDrop } = this.state
    let type, name
    let folderNewDepthProps = null
    const indexNameWithoutExtension = file && file.nameWithoutExtension ? file.nameWithoutExtension.indexOf('.') : ''
    const newNameWithoutExtension = file && file.nameWithoutExtension ? file.nameWithoutExtension.substr(0, indexNameWithoutExtension) : ''
    if (isNewFolder) {
      type = 'DIRECTORY'
      name = ''
    } else {
      type = file.type
      name = file.type === 'DIRECTORY' ? file.nameWithoutExtension : newNameWithoutExtension ? newNameWithoutExtension : file.nameWithoutExtension
    }
    if (folderNewDepth) {
      folderNewDepthProps = folderNewDepth
    }
    const configImage = this.geThumbnailOrImageSrc(type, parent, file.id, file)
    const typeLower = type ? type.toLowerCase() : ''
    const activeClass = activeFile === file ? 'active' : ''
    return [
      <div
        className={ parent && !isNewFolder && !renameFileVisible ? `${ activeClass } content-file new-content-file-box  ${ listKeysFiles && listKeysFiles.includes(file.id) && 'content-file__select' }` :
          isNewFolder || renameFileVisible ? `${ activeClass } content-file content-file-action  ${ listKeysFiles && listKeysFiles.includes(file.id) && 'content-file__select' }` :
            `${ activeClass } content-file new-content-file-folder  ${ listKeysFiles && listKeysFiles.includes(file.id) && 'content-file__select' }`}
        onBlur={this.closeInput}
        onClick={ (e) => { if (!e.target.classList.contains('action-panel-hovering')) this.onSendInfoParents(file) } }
        draggable={true} id={ `file-${ file.id }` } data-parent-folder={this.props.basePath} data-type={file.type}
        onDragStart={ (event) => {
          //this.onDragStartFile(file)
          let id = event.target.id
          if (id.indexOf('-img') !== -1) {
            id = `file-${ id.replace('-img', '') }`
          }
          const obj = document.getElementById(id)
          obj.parentElement.parentElement.parentElement.parentElement.classList.add('drag-select')
          event.dataTransfer.setData('Text', id)
          event.dataTransfer.setData('name', obj.innerHTML)
          event.dataTransfer.setData('type', obj.getAttribute('data-type'))
          event.dataTransfer.setData('parentFolder', obj.getAttribute('data-parent-folder'))

          event.dataTransfer.effectAllowed = 'move'
        } }
        key={ 1 }
        onMouseDown={(e) => this.onDragStartFile(e, file)}
        onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, file) }
        onMouseLeave={ () => this.onMouseLeaveOnPanel() }
      >
        <EnhancedClickableBox
          onDoubleClick={ () => this.routeChangeFolderOpened() }
          configImage={ configImage }
          typeLower={ typeLower }
          file={ file }
          isNewFolder={ isNewFolder }
          name={ name }
          onSelectImages={ this.onSelectImages }
          renameFileVisible={ renameFileVisible }
          closeInput={ this.closeInput }
          positionOnRenameFolder={ positionOnRenameFolder }
          parent={ parent }
          onFileEnterOnPanel={ onFileEnterOnPanel }
          listKeysFiles = { listKeysFiles }
          filesCount = { filesCount }
          activeFile = { activeFile }
          filesErrors = { filesErrors }
          getFilesDuplicates = { getFilesDuplicates }
          routeChangeDataInsightDuplicate = { this.routeChangeDataInsightDuplicate }
          onDrop={ (event) => this.onDrop(event, file, onSelectedFileDrop) }
          onDragOver={ this.onDragOver }
          getFilesProprieties={ getFilesProprieties }
          progressBulkData={ progressBulkData }
        />
        {
          (visiblePageModalDownload && visibleCollectData) &&
          <DownloadBoxModalOptionFiles
            file={ fileSelectDownload }
            onCancelModalDownload = { this.onCancelModalDownload }
            onCloseCollectData = { this.onCloseCollectData }/>
        }
        { isNewFolder &&
        <span className="content-file__name" key={ 2 }>
          <span className="validation-name">{ validNewFolderName.success ? '' : validNewFolderName.reason}</span>
          <Input
            placeholder="New folder name"
            onKeyPress={ (e) => {
              if (e.key === 'Enter') {
                this.props.handleNewFolderKeyPressed(e)
              }
            }}
            disabled={ disabledOnClicked }
            className="new-folder-input" onBlur={ (e) => {
              this.props.handleNewFolderKeyPressed(e)
              this.props.removeNewFolderFormView()
              this.newFolderInput = null
            } } ref={ (input) => {
              this.newFolderInput = input
            } }/>
        </span>
        }
        {
          renameFileVisible &&
          <span className='content-file__name' onKeyPress={this.handleNewRenameFolderPressed } key={ 2 }>
            <span className="validation-name">{ validEditFolderName.success ? '' : validEditFolderName.reason }</span>
            <Input placeholder={file.name} className="new-folder-input"
            />
          </span>
        }

      </div>,
      <ContextMenu className="context-menu-directory" id={ `context-menu-file ${ file.id }` } key='cm'
        onShow={ (e) => setActiveFile(e, file) } onHide={ (e) => setActiveFile(e, {}) } >
        { file.type !== 'DIRECTORY' && [
          file.conversionStatus === 'Converted' && <MenuItem className="menu-item-directory" key={ 1 } onClick={ (e, props) => this.executeAction(props, 'redacting', e) }>
           Smartview
          </MenuItem>,
          <div className="context-sub-menu" key={ `context-sub-menu-1-${ file.id }` }>
            <SubMenu className={file.conversionStatus === 'Converted' ? "context-menu-directory2" : "context-menu-directory2-update"} title="View">
              { file.finalizedDocPath && <MenuItem className="menu-item-directory" key={ 3 } onClick={ (e, props) => this.executeAction(props, 'viewer-finalized', e) }>
            Redacted file
              </MenuItem> }
              { file.conversionStatus === 'Converted' && <MenuItem className="menu-item-directory" key={ 2 } onClick={ (e, props) => this.executeAction(props, 'viewer', e) }>
                Raw file
              </MenuItem> }
              { file.isOcr !== 'false' && <MenuItem className="menu-item-directory" key={ 4 } onClick={ (e, props) => this.executeAction(props, 'ocr', e) } >
                OCR
              </MenuItem> }
            </SubMenu>
            { file.type !== 'DIRECTORY' && <MenuItem key={ 52 } className="menu-item-directory8">

            </MenuItem> }
          </div>,
          <div className="context-sub-menu" key={ `context-sub-menu-2-${ file.id }` }>
            <SubMenu className="context-menu-directory2" title="Download">
              { file.type !== 'DIRECTORY' && file.finalizedDocPath &&
              <MenuItem className={ file.visibleToDownload === true ? 'menu-item-directory' : 'menu-item-directory3' } disabled={ file.visibleToDownload !== true || !file.finalizedDocPath } key={ 7 } onClick={ (e, props) => this.executeAction(props, 'export-document', e) }>
                Redacted file
              </MenuItem>
              }
              { file.type !== 'DIRECTORY' &&
              <MenuItem className={ file.visibleToDownload === true ? 'menu-item-directory' : 'menu-item-directory2' } key={ 25 } disabled={ file.visibleToDownload !== true }
                onClick={ (e, props) => this.executeAction(props, 'download-file', e) }>
               Original file
              </MenuItem>
              }
              { file.type !== 'DIRECTORY' && file.auditRedactionPath !== null &&
              <MenuItem className={ file.visibleToDownload === true ? 'menu-item-directory' : 'menu-item-directory2' } key={ 26 } disabled={ file.visibleToDownload !== true }
                        onClick={ (e, props) => this.executeAction(props, 'audit-file', e) }>
                Audit file
              </MenuItem>
              }
            </SubMenu>
          </div>
        ]
        }
        {/*{ file.isPdf === 'true' && file.finalizedDocPath &&*/}
        {/*<MenuItem className="menu-item-directory" key={ 5 } onClick={ (e, props) => this.executeAction(props, 'finalise-document', e) }>*/}
        {/*  Update redacted*/}
        {/*</MenuItem>*/}
        {/*}*/}
        {/*{ file.isPdf === 'true' && !file.finalizedDocPath &&*/}
        {/*<MenuItem className="menu-item-directory" key={ 6 } onClick={ (e, props) => this.executeAction(props, 'finalise-document', e) }>*/}
        {/*  Create redacted*/}
        {/*</MenuItem>*/}
        {/*}*/}
        {this.props.listKeysFiles.length > 0 &&
        <MenuItem className="menu-item-directory" key={ 27 }
          onClick={(e, props) => this.executeAction(props, 'reanalyse-with-ai', e)}>
          Reprocess
        </MenuItem>
        }
        { file.type !== 'DIRECTORY' && <MenuItem key={ 53 } className="menu-item-directory8">
        </MenuItem>
        }
        { file.isPdf === 'true' &&
        <MenuItem className="menu-item-directory" key={ 8 } onClick={ (e, props) => this.executeAction(props, 'info', e) }>
          File info
        </MenuItem>
        }
        {/*{ file.type !== 'DIRECTORY' &&*/}
        {/*<MenuItem className={ (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length === 0) ? 'menu-item-directory2' : 'menu-item-directory' } key={ 10 }*/}
        {/*  disabled={ (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length === 0) }*/}
        {/*  onClick={ (e, props) => this.executeAction(props, 'cut', e) }>*/}
        {/*  Cut*/}
        {/*</MenuItem>*/}
        {/*}*/}
        { file.type !== 'DIRECTORY' &&
        <MenuItem className="menu-item-directory" key={ 9 } onClick={ (e, props) => this.executeAction(props, 'delete', e) }>
          Delete
        </MenuItem>
        }

        { file.type === 'DIRECTORY' && [
          parent && [
            !isNewFolder && file.depth === 0 && <MenuItem disabled={ file.statisticsReady === 'false' && filesCount > 0 }
              className={ (file.statisticsReady === 'false' && filesCount > 0) ? 'menu-item-directory menu-item-directory-disabled' : "menu-item-directory" } key={ 2 }
              onClick={ (e, props) => this.executeAction(props, 'analysis', e) }>
              {
                (file.statisticsReady === 'false' && filesCount > 0) ?
                    <Tooltip title="Generating stats. Please wait.">
                    <span>Box settings</span>
                    </Tooltip> :
                    <span>Box settings</span>
              }
            </MenuItem>,
            <MenuItem disabled={ file.statisticsReady === 'false' }
              className={ file.statisticsReady === 'false' ? 'menu-item-directory menu-item-directory-disabled' : "menu-item-directory" } key={ 11 }
              onClick={ (e, props) => this.executeAction(props, 'insights', e) }>
              { file.statisticsReady === 'false' ?
                  <Tooltip title={filesCount === 0 ? '' : "Generating stats. Please wait." }>
                  <span>Report</span>
                  </Tooltip> :
                  <span>Report</span>
              }
            </MenuItem>,
            <MenuItem disabled={ file.statisticsReady === 'false' }
              className={ file.statisticsReady === 'false' ? 'menu-item-directory menu-item-directory-disabled' : "menu-item-directory" } key={ 55 }
              onClick={ (e, props) => this.executeAction(props, 'redaction', e) }>
              {file.statisticsReady === 'false' ?
                  <Tooltip title={filesCount === 0 ? '' : "Generating stats. Please wait." }>
                  <span>Redaction</span>
                  </Tooltip> :
                  <span>Redaction</span>
              }
            </MenuItem>,
            <MenuItem disabled={ file.statisticsReady === 'false' }
                      className={ file.statisticsReady === 'false' ? 'menu-item-directory menu-item-directory-disabled' : "menu-item-directory" } key={ 60 }
                      onClick={ (e, props) => this.executeAction(props, 'cull', e) }>
              {file.statisticsReady === 'false' ?
                  <Tooltip title={filesCount === 0 ? '' : "Generating stats. Please wait." }>
                    <span>Cull</span>
                  </Tooltip> :
                  <span>Cull</span>
              }
            </MenuItem>,
            !isNewFolder && file.depth === 0 && file.bulkProcessingStarted && file.bulkProcessingStarted === 'started' && <MenuItem className="menu-item-directory" key={ 12 } onClick={ (e, props) => this.executeAction(props, 'cancel-redaction', e) }>
              Cancel Redaction
            </MenuItem>,
          /*  <MenuItem className={ file.bulkProcessingStarted === 'finished' && file.visibleToDownload === true ? 'menu-item-directory' : 'menu-item-directory menu-item-directory4' }
              disabled={ (file.bulkProcessingStarted !== 'finished' || file.visibleToDownload !== true) } key={ 13 } onClick={ (e, props) => this.executeAction(props, 'export', e) }>
              Download Finalised
            </MenuItem>,*/
            <MenuItem className={ file.visibleToDownload === false ? 'menu-item-directory menu-item-directory-disabled-export' : "menu-item-directory" }
                      disabled={ file.visibleToDownload === false}
                       key={ 13 } onClick={ (e, props) => this.executeAction(props, 'export', e) }>
              Export
            </MenuItem>
          ],
          <MenuItem key={ 14 } className="menu-item-category menu-item-category3">
          </MenuItem>,
           // console.log("testaaa", this.props),
          //<MenuItem className={filesCount > 0 && (file.statisticsReady === 'false' || file.statisticsReady === null) ? 'menu-item-directory menu-item-directory-disabled-rename' : "menu-item-directory" } key={ 15 } onClick={ (e, props) => this.executeAction(props, 'rename', e) }>
          <MenuItem className={this.props.root !== null && (this.props.root && ( (file.statisticsReady !== null && file.statisticsReady === 'true') || filesCount === 0 ) ) ||
          ( !this.props.root &&  ( (this.props.folder !== null && this.props.folder.statisticsReady !== null && this.props.folder.statisticsReady === 'true') || filesCount === 0 ) )   ?  "menu-item-directory" : 'menu-item-directory menu-item-directory-disabled-rename'  } key={ 15 } onClick={ (e, props) => this.executeAction(props, 'rename', e) }>
            Rename
          </MenuItem>,
          // folderNewDepthProps && folderNewDepthProps > 0 && <MenuItem
          //   className={ (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length === 0) ? 'menu-item-directory2' : 'menu-item-directory' }
          //   key={ 23 } disabled={ (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length === 0) }
          //   onClick={ (e, props) => this.executeAction(props, 'cut-folder', e) }>
          //   Cut
          // </MenuItem>,
          // folderNewDepthProps && folderNewDepthProps > 0 && <MenuItem className={ checkStatusPaste === false ? 'menu-item-directory2' : 'menu-item-directory' }
          //   key={ 24 } disabled={ checkStatusPaste === false }
          //   onClick={ (e, props) => this.executeAction(props, 'paste', e) }>
          //   Paste
          // </MenuItem>,
          <MenuItem className="menu-item-directory" key={ 16 } onClick={ (e, props) => this.executeAction(props, 'delete', e) }>
            Delete
          </MenuItem>,
          <MenuItem className="menu-item-directory" key={ 17 } onClick={ (e, props) => this.executeAction(props, 'add-note', e) }>
            Add a Note
          </MenuItem>,
          // <MenuItem className={file.bulkProcessingStarted === 'started' ? 'menu-item-directory menu-disabled' : 'menu-item-directory' } key={ 18 } disabled={ file.bulkProcessingStarted === 'started' } onClick={ (e, props) => this.executeAction(props, 'finalise-folder', e) }>
          //   { file.finaliseFolder ? 'Update Redaction Version' : 'Create Redacted Version' }
          // </MenuItem>,
          <MenuItem key={ 19 } className="menu-item-category">
          </MenuItem>,
          !folderNewDepthProps > 0 && file.depth === 0 ?
              <MenuItem className="menu-item-directory" key={ 20 } onClick={ (e, props) => this.executeAction(props, 'info', e) }>
                Box Info
              </MenuItem> :
              <MenuItem className="menu-item-directory" key={ 20 } onClick={ (e, props) => this.executeAction(props, 'info', e) }>
                Folder Info
              </MenuItem>,
          folderNewDepthProps === null && !folderNewDepthProps > 0 && !isNewFolder && file.depth === 0 && <MenuItem
              disabled={ file.statisticsReady === 'false' && filesCount > 0 }
              className={ (file.statisticsReady === 'false' && filesCount > 0) ? 'menu-item-directory menu-item-directory-disabled' : "menu-item-directory" } key={ 21 }
            onClick={ (e, props) => this.executeAction(props, 'security-settings', e) }>
            {
              (file.statisticsReady === 'false' && filesCount > 0) ?
                  <Tooltip title="Generating stats. Please wait.">
                    <span>Security Settings</span>
                  </Tooltip> :
                  <span>Security Settings</span>
            }
          </MenuItem>
        ]
        }
      </ContextMenu>,
      <Modal key={ 22 } visible={ previewVisible } footer={ null } onCancel={ this.handleCancel } className="pdf-modal">
        <div className="custom-modal-body">
          { renderingBlock }
        </div>
      </Modal>,
      <Modal key={ 23 } visible={ previewVisibleOcr } onCancel={ this.handleCancelModal } footer={ null } className="pdf-modal">
        <div className="custom-modal-body align-text">
          <p>{ fileContentOcr }</p>
        </div>
      </Modal>,
      <React.Fragment key={`notes-${ file.id }`}>
        { documentNoteVisible ? <DirectoryNote closeDocumentNote={ this.closeDocumentNote } documentId={ file.id } documentName={ file.name }
                                               user={ this.props.user } depthFromRoot={ file.depthFromRoot } type={ file.type } /> : '' }
      </React.Fragment>
    ]
  }
}

const mapStateToProps = (state) => ({
  intervalForBulkRedaction: state.smartBox.intervalForBulkRedaction,
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxTree: state.smartBox.smartBoxTree,
  smartBoxSelectedFiles: state.smartBox.smartBoxSelectedFiles,
  smartBoxBaseList: state.smartBox.smartBoxBaseList,
  smartBoxSourceFile: state.smartBox.smartBoxSourceFile,
})

SmartBoxFile.propTypes = {
  file: PropTypes.object.isRequired,
  onFilePressed: PropTypes.func,
  onDoubleClickFilePressed: PropTypes.func,
  user: PropTypes.object,
  isUnique: PropTypes.func
}
SmartBoxFile= withRouter(SmartBoxFile)

export default connect(mapStateToProps, {
  addIntervalToApplication,
  setSmartBoxIntervalForBulkFinalise,
  removeSmartBoxIntervalForBulkFinalise,
  setSmartBoxCurrentFolder,
  setSmartBoxLoadTree,
  setSmartBoxFolderClosed,
  setSmartBoxDeletedFolder,
  setSmartBoxRenamedFolder,
  setSmartBoxSelectedFiles,
  setSmartBoxSelectedDuplicateFile,
  setSmartBoxMovedFiles,
  setSmartBoxMovedFolders,
  setSmartBoxOldParentOfMovedFolders,
  setSmartBoxNewParentOfMovedFolders,
  setSmartBoxSecurityConfiguration,
  setSmartBoxParentStatisticsReady,
  setSmartBoxCurrentFolderName,
  setSmartBoxSourceDrop,
  setSmartBoxClickOutsideFilter,
  setSmartBoxCurrentNewFilters,
  setSmartBoxCurrentNewFiltersSort,
  setSmartBoxSelectedKeys,
  setActiveDeleteFile,
  setSmartBoxPreviousCurrentPage
})(SmartBoxFile)