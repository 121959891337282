import React from 'react'
import PropTypes from 'prop-types'
import {notification, Tree} from 'antd'
import {
    renderTreeSearchRegEX,
    generateHtmlForRegularExpressions, renderTreeSearchRegEXCopy
} from '../../../utils/SmartBox/SmartBoxMethods'
import {
    newGetRegexWords,
    getRegexCategory,
    getRegexCategoryColor,
    removeCategoryRegularExpressions,
    apiSmartBoxGetNewQueryStatusRegEx,
    apiSmartBoxUpdateRedactionNumber,
    apiSmartBoxCheckAllCanvasPoints, apiSmartBoxSaveQueryStatusMixed
} from '../../../api/SmartBox'
import './NewManualTabs.scss'
import Mark from 'mark.js'
import _ from 'lodash'
import swal from 'sweetalert2'
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import {customConfirm} from "../../../utils/AssetRegister/CustomConfirm";

class RegularExpressionsCopy extends React.Component {

    state = {
        redactedKeys: [],
        expandedKeys: [ 'select-all' ],
        autoExpandParent: true,
        checkedKeys: [],
        unCheckedKeys: [],
        selectedKeys: [],
        pageNumberSelect: '',
        valueRadioSelect: '',
        newRegularExpressions: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        colorCategories: {},
        selectedChildrenBold: [],
        disableRemoveCategory: false,
        sendStatusOnClearAll: false,
        selectedPgNrBoldRegEx: [],
        selectedItemRegEx: {},
        newCheckedKeys: [],
        newRegularExpressionsOcr: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        setIntervalCheckPdfCanvas: 0,
        responseCheckPdfCanvas: '',
    }
    componentDidMount() {
        if (this.props.isSmartBoxApplication) {
            //this.newFetchDataRegularExpressions(this.props.idDocument)
            this.props.onCheckedKeysObjectRefsRegEx([])
            // this.onRegexCheckedKeysOcr()
            // this.onRegexCheckedKeys()
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (prevProps.updateRedactAllStatus !== this.props.updateRedactAllStatus) {
        //     this.setState({
        //         checkedKeys: this.props.checkedKeysAllRegEx
        //     })
        // }
        // if (prevProps.checkedChildRegEx !== this.props.checkedChildRegEx && this.props.checkedChildRegEx.length > 0) {
        //     this.setState({
        //         checkedKeys: this.props.checkedChildRegEx
        //     })
        // }
        if (prevProps !== this.props && this.props.removeAllRegEx) {
            this.state.newRegularExpressions && this.state.newRegularExpressions[0].children.length > 0 && this.state.newRegularExpressions[0].children.forEach((keyColour) => {
                keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                    keyColour2.redacted = false
                })
            })
            // this.setState({
            //     checkedKeys: []
            // })
        }
        if (prevProps.alreadyRedactedKeys.regex !== this.props.alreadyRedactedKeys.regex) {
            // this.onRegexCheckedKeysOcr()
            // this.onRegexCheckedKeys()
        }
        if (prevProps !== this.props && this.props.treeDataAnnotationsRegExMixed[0].children.length > 0 && this.props.statusNextDocumentRegEx) {
            //console.log('VVVVVVVVVVVVVVVVVVVVVVVVv 1111111111111111111111111111111111')
            this.props.onGetStatusNextDocumentRegEx(false)
            //this.newFetchDataRegularExpressions(this.props.idDocument)
            this.newFetchDataRegularExpressionsCopy(this.props.idDocument)
        }
        if (prevProps !== this.props && this.props.treeDataAnnotationsRegExMixed[0].children.length > 0 && !this.props.statusNextDocumentRegEx) {
            //console.log('VVVVVVVVVVVVVVVVVVVVVVVVv 22222222222222222222222222222222222')
            this.props.onGetStatusNextDocumentRegEx(false)
            this.newFetchDataRegularExpressionsCopy(this.props.idDocument)
            //this.newCategoryRegularExpressions(this.props.idDocument)
        }
    }

    newCategoryRegularExpressions = () => {
        const { docAnnotationsRegularExp, categoryColoursRegularExp, categoryColoursRegularExpOcr, treeDataAnnotationsRegEx, docAnnotationsRegularExpOcr, treeDataAnnotationsRegExMixed } = this.props
        let { expandedKeys, checkedKeys } = { ...this.state }
        this.setState({
            colorCategories: categoryColoursRegularExp,
            expandedKeys: [ ...expandedKeys, ...Object.keys(categoryColoursRegularExp).concat(Object.keys(categoryColoursRegularExpOcr)) ],
            newRegularExpressions: treeDataAnnotationsRegExMixed,
           // newRegularExpressionsOcr: treeDataAnnotationsRegEx,
        })
    }
    newCategoryUpdateRegularExpressions = async(docId) => {
        const { docAnnotationsRegularExp, categoryColoursRegularExp, treeDataAnnotationsRegEx, docAnnotationsRegularExpOcr } = this.props
        this.props.onDocAnnotationsRegEx(docAnnotationsRegularExp)
        if (docAnnotationsRegularExp.message && docAnnotationsRegularExp.title) {
            this.setState({
                docAnnotations: {},
                docAnnotationsRegExOcr: {},
            })
        } else {
            this.setState({
                docAnnotations: docAnnotationsRegularExp,
                docAnnotationsRegExOcr: docAnnotationsRegularExpOcr,
            })
        }
        this.setState({
            colorCategories: categoryColoursRegularExp,
            newRegularExpressions: treeDataAnnotationsRegEx,
            newRegularExpressionsOcr: treeDataAnnotationsRegEx,
        }, () => {
            this.renderCategoryUpdateRegularExpressions()
        })
    }

    renderCategoryUpdateRegularExpressions = () => {
        const { newRegularExpressions, docAnnotations, colorCategories, newRegularExpressionsOcr, categoriesColors } = { ...this.state }
        let { expandedKeys, checkedKeys } = { ...this.state }
        this.props.allChildrenTreeRegEx(newRegularExpressions)
        if (!this.props.isOcr) {
            this.props.onGetRegEx(newRegularExpressions)
            this.props.setTreeDataRegEx(newRegularExpressions)
        }

        const checkedKeysUpdateRegEx = []
        if (newRegularExpressions[0].children && newRegularExpressions[0].children.length > 0 && this.props.alreadyRedactedKeys.regex && this.props.alreadyRedactedKeys.regex.length > 0 ) {
            newRegularExpressions[0].children.forEach((children1) => {
                children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                    children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                        if ((this.props.alreadyRedactedKeys.regex.includes(`${children3.name.toLowerCase()}-${children3.index}`)) ||
                            this.props.alreadyRedactedKeys.regex.includes(`${children3.name.toLowerCase()}-all`)) {
                            checkedKeysUpdateRegEx.push(children3.key)
                        }
                    })
                })
            })
        }
        checkedKeys = checkedKeysUpdateRegEx
        this.setState({
            newRegularExpressions,
            newRegularExpressionsOcr,
            autoExpandParent: false,
            expandedKeys: [ ...expandedKeys, ...Object.keys(colorCategories) ],
            checkedKeys
        }, () => {
            if (this.props.isOcr) {
                this.checkDocAnnotationCategoryOcrRegEx()
            }
        })
    }

    onRegexCheckedKeysOcr = () => {
        if (this.props.isOcr) {
            const checkedKeysOcr = []
            this.props.alreadyRedactedKeys.regex && this.props.alreadyRedactedKeys.regex.length > 0 && this.props.alreadyRedactedKeys.regex.forEach((regOcr) => {
                const newKeysOcr = regOcr.substr(0, regOcr.length - 2)
                checkedKeysOcr.push(newKeysOcr)
            })
            // this.setState({
            //     checkedKeys: checkedKeysOcr
            // })
        }
    }

    onRegexCheckedKeys = () => {
        if (!this.props.isOcr) {
            if (this.props.alreadyRedactedKeys.regex && this.props.alreadyRedactedKeys.regex.length > 0) {
                this.setState({
                    checkedKeys: this.props.alreadyRedactedKeys.regex
                })
            }
        }
    }

    newFetchDataRegularExpressionsCopy = () => {
        const { docAnnotationsRegularExp, categoryColoursRegularExp, treeDataAnnotationsRegEx, docAnnotationsRegularExpOcr,
            treeDataAnnotationsRegExMixed, categoryColoursRegularExpOcr } = this.props
        let { expandedKeys, checkedKeys } = { ...this.state }
        this.props.allChildrenTreeRegEx(treeDataAnnotationsRegEx)
            this.props.onGetRegEx(treeDataAnnotationsRegExMixed)
            this.props.setTreeDataRegEx(treeDataAnnotationsRegExMixed)
        this.setState({
            docAnnotations: docAnnotationsRegularExp,
            docAnnotationsRegExOcr: docAnnotationsRegularExpOcr,
            categoriesColors: categoryColoursRegularExpOcr,
            colorCategories: categoryColoursRegularExp,
            newRegularExpressions: treeDataAnnotationsRegExMixed,
            newRegularExpressionsOcr: treeDataAnnotationsRegExMixed,
            autoExpandParent: false,
            expandedKeys: [ ...expandedKeys, ...Object.keys(categoryColoursRegularExp).concat(Object.keys(categoryColoursRegularExpOcr)) ],
        }, () => {
            let checkedKeysNonOcr = []
            let checkedKeysOcr = []

            const checkedKeysUpdateMount = []
            const checkedKeysUpdateMountOcr = []
            if (this.props.alreadyRedactedKeys.regex && this.props.alreadyRedactedKeys.regex.length > 0) {
                if (treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 ) {
                    treeDataAnnotationsRegExMixed[0].children.forEach((children1) => {
                        children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                            children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                                if ((this.props.alreadyRedactedKeys.regex.includes(children3.keyMixtRedaction)) ||
                                    (this.props.alreadyRedactedKeys.regex.includes(children3.keyMixtRedaction.toLowerCase()))) {
                                    checkedKeysUpdateMount.push(children3.key)
                                }
                            })
                        })
                    })
                }
                checkedKeysNonOcr = checkedKeysUpdateMount
            }

            if (this.props.alreadyRedactedKeys.regexOCR && this.props.alreadyRedactedKeys.regexOCR.length > 0) {
                if (treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 ) {
                    treeDataAnnotationsRegExMixed[0].children.forEach((children1) => {
                        children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                            if (children2.isOcr) {
                                if ((this.props.alreadyRedactedKeys.regexOCR.includes(children2.name)) || (this.props.alreadyRedactedKeys.regexOCR.includes(children2.name.toLowerCase()))) {
                                    checkedKeysUpdateMountOcr.push(children2.key)
                                }
                            }
                        })
                    })
                }
                checkedKeysOcr = checkedKeysUpdateMountOcr
            }
            checkedKeys = checkedKeysNonOcr.concat(checkedKeysOcr)
            this.props.onCheckedKeysAllMixedRegEx(checkedKeys)
            this.setState({
                checkedKeys
            })
        })
    }

    newFetchDataRegularExpressions = async(docId) => {
        const { docAnnotationsRegularExp, categoryColoursRegularExp, treeDataAnnotationsRegEx, docAnnotationsRegularExpOcr,
            treeDataAnnotationsRegExMixed } = this.props
        let response
        let response2
        let response3
        if (this.props.isSmartBoxApplication) {
            //response = await newGetRegexWords(docId)
            response2 = await getRegexCategory(docId)
            //response3 = await getRegexCategoryColor(docId)
        }
        this.props.onDocAnnotationsRegEx(docAnnotationsRegularExp)
        if (docAnnotationsRegularExp.message && docAnnotationsRegularExp.title) {
            // notification.info({message: 'You are not advised to make redactions until box analytics report is ready!', duration: 6})
            this.setState({
                docAnnotations: {},
                docAnnotationsRegExOcr: {},
            })
        } else {
            this.setState({
                docAnnotations: docAnnotationsRegularExp,
                docAnnotationsRegExOcr: docAnnotationsRegularExpOcr,
            })
        }
        this.setState({
            // docAnnotations: response.data,
            // docAnnotationsRegExOcr: response.data,
            categoriesColors: response2.data,
            colorCategories: categoryColoursRegularExp,
            newRegularExpressions: treeDataAnnotationsRegExMixed,
            newRegularExpressionsOcr: treeDataAnnotationsRegEx,
        }, () => {
            this.renderCategoryRegExUpdate()
        })
    }
    renderCategoryRegExUpdate = () => {
        const { newRegularExpressions, docAnnotations, colorCategories, newRegularExpressionsOcr, categoriesColors, } = { ...this.state }
        let { expandedKeys, checkedKeys } = { ...this.state }
        const { landscape, treeDataAnnotationsRegExMixed } = this.props
        const newCategoryRegEx = []
        let newCategoryRegExUpdate = {}
        // for (const categoryParent of Object.keys(docAnnotations)) {
        //   const title2 = categoryParent.replace('doc_annotate_', '')
        //   newCategoryRegEx.push(categoryParent)
        //   newCategoryRegExUpdate[title2] = ''
        // }
        // const titleCategory = []
        // for (const scanItem of newCategoryRegEx) {
        //   if (docAnnotations[scanItem] && Array.isArray(docAnnotations[scanItem])) {
        //     let title = scanItem.replace('doc_annotate_', '')
        //     title = title.replace('_', ' ')
        //     titleCategory.push(title.toLowerCase())
        //   }
        // }
        // for (const categoryColor of Object.keys(newCategoryRegExUpdate)) {
        //   if (titleCategory.includes(categoryColor.toLowerCase())) {
        //     const {
        //       resultCategoryRegEx,
        //       expandedSubCategoryParent
        //     } = generateHtmlForRegularExpressions(categoryColor, colorCategories[categoryColor], docAnnotations, landscape, newCategoryRegEx)
        //     expandedKeys = [...expandedKeys, ...expandedSubCategoryParent]
        //     newRegularExpressions[0].children.push(resultCategoryRegEx)
        //     newRegularExpressionsOcr[0].children.push(resultCategoryRegEx)
        //   }
        // }
        this.props.allChildrenTreeRegEx(newRegularExpressions)
        if (!this.props.isOcr) {
            this.props.onGetRegEx(newRegularExpressions)
            this.props.setTreeDataRegEx(newRegularExpressions)
        }
        this.setState({
            newRegularExpressions: treeDataAnnotationsRegExMixed,
            newRegularExpressionsOcr,
            autoExpandParent: false,
            expandedKeys: [ ...expandedKeys, ...Object.keys(colorCategories) ],
        }, () => {

            if (this.props.isOcr) {
                this.checkDocAnnotationCategoryOcrRegEx()
            }
        })
    }

    checkDocAnnotationCategoryOcrRegEx = () => {
        const { docAnnotationsRegExOcr, newRegularExpressionsOcr } = this.state
        newRegularExpressionsOcr && newRegularExpressionsOcr[0].children && newRegularExpressionsOcr[0].children.length > 0 && newRegularExpressionsOcr[0].children.forEach((children1) => {
            if (docAnnotationsRegExOcr && Object.keys(docAnnotationsRegExOcr).length) {
                Object.keys(docAnnotationsRegExOcr).forEach((category) => {
                    let title = category && category.replace('doc_annotate_', '')
                    title = title && title.replace('_', ' ')
                    if (children1.key === title) {
                        docAnnotationsRegExOcr[category] && docAnnotationsRegExOcr[category].length > 0 && docAnnotationsRegExOcr[category].forEach((nameParent, index) => {
                            const parentAllCategory = {
                                backgroundColor: children1.backgroundColor,
                                children: [],
                                key: `${ nameParent }`,
                                identifier: 'children',
                                name: nameParent,
                                index: index,
                                title: nameParent,
                                pageNumber: '1',
                                isOcr: this.props.isOcr
                            }
                            children1.children.push(parentAllCategory)
                        })
                    }
                })
            }

        })
        this.props.onGetRegEx(newRegularExpressionsOcr)
        this.setState({
            newRegularExpressions: newRegularExpressionsOcr
        })
    }

    addNewCategory = (e) => {
        e.stopPropagation()
        this.setState({
            addNewCategory: !this.state.addNewCategory,
            inputValue: ''
        })
    }

    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys,
            autoExpandParent: true,
        })
    };

    onSelect = (selectedKeys, info) => {
        this.setState({ selectedKeys })
    }

    extractLeafKeys = (node) => {
        const { unCheckedKeys } = this.state
        if (node.children && node.children.length) {
            node.children.forEach((item) => {
                this.extractLeafKeys(item.props)
            })
        } else {
            if (!unCheckedKeys.includes(node['data-key'])) {
                unCheckedKeys.push(node['data-key'])
            }
            this.props.onUnCheckedKeysRegEx(unCheckedKeys)
            this.setState({ unCheckedKeys })
        }
    }

    onCheck = (checkedKeys, { checkedNodes, checked, node }) => {
        const { unCheckedKeys, sendStatusOnClearAll } = this.state
        const newFilterCategoryReg = checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-parent')
        if (this.props.isSmartBoxApplication) {
            this.props.checkOnClearAllRegEx(sendStatusOnClearAll)
        }
        if (!checked) {
            this.extractLeafKeys(node.props)
        } else if (unCheckedKeys.length) {
            unCheckedKeys.forEach((item, index) => {
                if (checkedKeys.includes(item)) {
                    unCheckedKeys.splice(index, 1)
                }
            })
            this.props.onUnCheckedKeysRegEx(unCheckedKeys)
        }
        // if (unCheckedKeys.length) {
        //   unCheckedKeys.forEach((item, index) => {
        //     if (checkedKeys.includes(item)) {
        //       unCheckedKeys.splice(index, 1)
        //     }
        //   })
        // }

        if (newFilterCategoryReg.length > 0) {
            this.setState({
                disableRemoveCategory: true
            })
        } else {
            this.setState({
                disableRemoveCategory: false
            })
        }
        const checkedNodesRef = checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.key)
        this.props.onCheckedKeysObjectRefsRegEx(checkedNodesRef)
        this.setState({
            //checkedKeys,
            unCheckedKeys,
            checkedKeysForDelete: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-parent'),
            checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord),
            checkedKeysObjectRefsOcr: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isOcr),
            checkedKeysObjectRefsNonOcr: checkedNodes.map((node) => node.props.dataRef).filter((obj) => !obj.isOcr && obj.isSearchedWord),
        })
    };

    removeCategory = (e) => {
        e.stopPropagation()
        const { expandedKeys, colorCategories, checkedKeysForDelete, newRegularExpressions, checkedKeys } = { ...this.state }
        const newListNameRemove = []
        const newKeysDelete = []
        const keysDeleteRegEx = []
        let checkKeysDeleteFinally = []
        checkKeysDeleteFinally = checkedKeys
        checkedKeysForDelete && checkedKeysForDelete.forEach((check) => {
            keysDeleteRegEx.push(check.key)
            check && check.children.forEach((check2) => {
                keysDeleteRegEx.push(check2.key)
                check2 && check2.children.forEach((check3) => {
                    keysDeleteRegEx.push(check3.key)
                })
            })
        })
        keysDeleteRegEx && keysDeleteRegEx.length > 0 && keysDeleteRegEx.forEach((newCheck) => {
            checkKeysDeleteFinally = checkKeysDeleteFinally.filter((check) => check !== newCheck)
        })
        for (const checkedKey of checkedKeysForDelete.map((cKey) => cKey.key)) {
            const aiAnnotations = document.querySelectorAll(`.${ checkedKey }`)
            for (const aiAnnotate of aiAnnotations) {
                aiAnnotate.style.background = 'transparent'
            }
            const options = {
                className: checkedKey
            }
            newKeysDelete.push(checkedKey)
            const instance = new Mark(document.querySelector('#pdf-page'))
            instance.unmark(options)
            newRegularExpressions[0].children && newRegularExpressions[0].children.map((check) => {
                if (check.key === checkedKey) {
                    check.children && check.children.map((childrenKey) => {
                        newListNameRemove.push(childrenKey.name)
                        return true
                    })
                    return true
                }
                return true
            })

            newRegularExpressions[0].children = newRegularExpressions[0].children.filter((child) => child.key !== checkedKey)

            const data = {
                id: this.props.idDocument,
                listKeysDelete: newListNameRemove
            }

            if (this.props.isSmartBoxApplication) {
                removeCategoryRegularExpressions(data).then(() => {
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            }
            // if (checkKeysDeleteFinally && checkKeysDeleteFinally.length === 1 && checkKeysDeleteFinally[0] === 'select-all') {
            //     this.setState({
            //         checkedKeys: [],
            //     })
            // } else {
            //     this.setState({
            //         checkedKeys: checkKeysDeleteFinally,
            //     })
            // }
            this.setState({
                categoriesColors: _.omit(colorCategories, checkedKey),
                expandedKeys: expandedKeys.filter((eKey) => eKey !== checkedKey),
                autoExpandParent: false,
                newRegularExpressions,
                checkedKeysForDelete: [],
                disableRemoveCategory: false
            })
        }
    }

    expandHideCategory = (e, key, action) => {
        e.stopPropagation()
        let { expandedKeys } = { ...this.state }
        if (action === 'add') {
            expandedKeys.push(key)
        } else {
            expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
        }
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        })
    }
    findElementRegularExpresion(selectedKeys, node) {
        let { expandedKeys } = { ...this.state }
        const checkNodeRegEx = []
        if (!this.props.isOcr) {
            if (node && node.props.dataRef.key) {
                checkNodeRegEx.push(node.props.dataRef.key)
                this.setState({
                    selectedChildrenBold: checkNodeRegEx,
                    selectedPgNrBoldRegEx: checkNodeRegEx
                })
            }
            if (node.props.dataRef.key.includes('found-parent-all') || node.props.dataRef.identifier === 'category-parent' || node.props.dataRef.identifier === 'children-parent') {
                if (!expandedKeys.includes(node.props.dataRef.key)) {
                    expandedKeys.push(node.props['data-key'])
                    this.setState({
                        expandedKeys,
                        autoExpandParent: false
                    })
                } else {
                    expandedKeys = expandedKeys.filter((eKey) => eKey !== node.props['data-key'])
                    this.setState({
                        expandedKeys,
                        autoExpandParent: false
                    })
                }
            } else {
                this.setState({
                    selectedWord: selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : ''
                }, () => {
                    if (this.state.selectedWord) {
                        const {backgroundColor, pageNumber} = node.props.dataRef
                        const oldElement = document.querySelector('#matched-word--current')
                        if (oldElement) {
                            oldElement.removeAttribute('id')
                        }
                        if (+pageNumber !== +this.props.pageNumber) {
                            this.props.changePage(+pageNumber)
                        }
                        const element = document.getElementsByClassName(this.state.selectedWord)
                        if (element && element.length > 0) {
                            element[0].id = 'matched-word--current'
                            element[0].style.borderColor = backgroundColor.replace('0.3', '1')
                            element[0].scrollIntoView(false)
                        }
                    } else {
                        const element = document.querySelector('#matched-word--current')
                        element.removeAttribute('id')
                    }
                })
            }
        }
    }

    onUpdateRedactionNumber = (classList) => {
        const data = {
            doc_id: this.props.idDocument,
            box_number_words_redacted: classList.length
        }
        apiSmartBoxUpdateRedactionNumber(data).then((response) => {

        })
    }

    onCheckAllCanvasPoints = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
            this.setState({
                responseCheckPdfCanvas: response.data
            })
            if(response.data === 'Processing') {
                this.setIntervalCheckAllCanvasPoints()
            } else {
                this.props.fetchSmartBoxDataCanvasOcr()
            }
        })
    }

    setIntervalCheckAllCanvasPoints = () => {
        const { responseCheckPdfCanvas} = this.state
        let { setIntervalCheckPdfCanvas} = this.state
        if (setIntervalCheckPdfCanvas === 0) {
            if (responseCheckPdfCanvas === 'Processing') {
                setIntervalCheckPdfCanvas = setInterval(this.callBackCheckAllCanvasPoints, 5000)
                this.setState({
                    setIntervalCheckPdfCanvas
                })
            } else {
                clearInterval(setIntervalCheckPdfCanvas)
            }
        }
    }

    callBackCheckAllCanvasPoints = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        if(responseCheckPdfCanvas === 'Processing') {
            apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
                this.setState({
                    responseCheckPdfCanvas: response.data
                })
                if (response.data === 'Finished') {
                    this.props.fetchSmartBoxDataCanvasOcr()
                }
            })
        }
    }

    onCheckDisableSelectAll = () => {
        return false
    }

    displayCheckKeysNonDeleteManual() {
        const { newListKeysRegEx } = this.props
        if (newListKeysRegEx) {
            return newListKeysRegEx
        }
    }

    onDisplayDifferenceColourOnActiveTab = () => {
        const { newRegularExpressions } = this.state
        newRegularExpressions && newRegularExpressions[0].children && newRegularExpressions[0].children.length > 0 && newRegularExpressions[0].children.map((keyc) => {
            const aiAnnotations = document.querySelectorAll(`.${ keyc.key }`)
            for (const aiAnnotate of aiAnnotations) {
                const backgroundColorNew = 'rgba(107, 91, 149, 0.3)'
                // if (activeKeyTab === 'regEx' && activeKey === 'manualTab') {
                if (!keyc.backgroundColor) {
                    keyc.backgroundColor = backgroundColorNew
                }
                aiAnnotate.style.background = keyc.backgroundColor
                aiAnnotate.style.borderColor = keyc.backgroundColor ? keyc.backgroundColor.replace('0.3', '1') : keyc.backgroundColor
                // } else {
                //   aiAnnotate.style.background = 'transparent'
                //   aiAnnotate.style.borderColor = 'initial'
                // }
            }
            return true
        })
    }
    onCheckSelectedTreeRedactedReg = () => {
        const { lockSmartViewChanges } = this.props
        if (!lockSmartViewChanges) {
            this.setState({
                disabledCheckBoxTree: true
            }, () => {
                this.onRedactAiTagCopy()
            })
        }
    }

    onRedactAiTagCopy = () => {
        const { checkedKeysObjectRefs, checkedKeysObjectRefsOcr, checkedKeysObjectRefsNonOcr } = this.state
            const sortedByPageItems = checkedKeysObjectRefs.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            this.drawMatchedWordsCopy(sortedByPageItems.map((item) => item.key), sortedByPageItems, checkedKeysObjectRefsNonOcr, checkedKeysObjectRefsOcr)
    }

    drawMatchedWordsCopy = (classList, sortedByPageItems, checkedKeysObjectRefsNonOcr, checkedKeysObjectRefsOcr) => {
        const { newRegularExpressions } = this.state
        const { listCategories, treeDataAnnotationsRegExMixed } = this.props
        const { unCheckedKeys, checkedKeys, newCheckedKeys } = this.state
        const checkKeysRedacted = []
        const classListMixed = []

        const selectedKeysOcr = []
        const selectRedactionRegExOcr = []
        const selectRedactionRegExOcrFilter = []
        checkedKeysObjectRefsOcr && checkedKeysObjectRefsOcr.length > 0 && checkedKeysObjectRefsOcr.forEach((elem) => {
            // selectRedactionRegExOcr.push({
            //     word: elem.key,
            //     category: elem.categoryName,
            //     subcategory: elem.subcategoryName,
            // })
            selectRedactionRegExOcrFilter.push(elem.key)
        })
        this.props.onStatusRemoveRedaction(false)
        // if (this.props.isOcr) {
        //     this.state.newRegularExpressions && this.state.newRegularExpressions[0].children.length > 0 && this.state.newRegularExpressions[0].children.forEach((item) => {
        //         item && item.children && item.children.length > 0 && item.children.forEach((item2) => {
        //             if (item2 !== undefined) {
        //                 checkedKeys && checkedKeys.forEach((key) => {
        //                     if (item2.key === key) {
        //                         selectedKeysOcr.push(item2.key)
        //                     }
        //                 })
        //             }
        //         })
        //     })
        // }
        const allWordsRedacted = []
        const allWordsRedactedOcr = []
        treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && treeDataAnnotationsRegExMixed[0].children.forEach((item) => {
            item && item.children.forEach((item2) => {
                if (item2.children.length === 0 && item2.isOcr) {
                    if (selectRedactionRegExOcrFilter.includes(item2.key)) {
                        selectRedactionRegExOcr.push({
                            word: item2.key,
                            category: 'Dictionaries',
                            subcategory: item.key,
                        })
                    }
                    allWordsRedactedOcr.push(item2)
                }
                item2 && item2.children.forEach((item3) => {
                    if (classList.includes(item3.key)) {
                        item3.categoryName = 'Regular Expressions'
                        item3.subcategoryName = item.key
                        allWordsRedacted.push(item3)
                        classListMixed.push(item3.key)
                    }
                })
            })
        })
        listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
            elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                    if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                        item.categoryColor = elem2.subcategoryColorWithAlpha
                    }
                })
            })
        })
        treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed[0].children.forEach((item) => {
            item && item.children.forEach((item2) => {
                item2 && item2.children.forEach((item3) => {
                    classList && classList.forEach((check) => {
                        if (item3.key === check) {
                            checkKeysRedacted.push(check)
                        }
                    })
                })
            })
        })
        const keysRedacted = this.props.isOcr ? selectedKeysOcr : checkKeysRedacted
        const textMessageRedact = unCheckedKeys && unCheckedKeys.length > 0 ? `Remove ${ unCheckedKeys.length } selected redactions?` : keysRedacted && keysRedacted.length === 1 ? `Redact ${ keysRedacted.length } selected?` : `Redact all ${ keysRedacted.length } selected?`
        swal.fire({
            text: 'Redact all selected?',
            html: `  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title">${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: unCheckedKeys && unCheckedKeys.length > 0 ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                this.props.getStatusElementRedaction('elementRegEx')
                this.setState({
                    //checkedKeys,
                    newCheckedKeys: checkedKeys
                })
            } else {
                // this.setState({
                //     checkedKeys: newCheckedKeys
                // })
            }
            if (result.value) {
                // this.props.toggleLoadingProcess(true, 'redact')
                const existingRedactedKeys= new Set([...this.state.redactedKeys, ...classList])
                const newUnCheckedKeys = []
                if (this.props.isSmartBoxApplication) {
                    //de decomentat
                    if (unCheckedKeys.length === 0) {
                        this.onUpdateRedactionNumber(keysRedacted)
                    }
                    if (unCheckedKeys && unCheckedKeys.length) {
                        unCheckedKeys.forEach((item) => {
                            if (existingRedactedKeys.has(item)) {
                                existingRedactedKeys.delete(item)
                            }
                            if (!classList.includes(item)) {
                                newUnCheckedKeys.push(item)
                            }
                        })
                    }
                }
                const objectListRedact = {
                    allWordsRedacted: allWordsRedacted,
                    allWordsRedactedOcr: allWordsRedactedOcr,
                    classList: classList,
                    classListMixed: classListMixed,
                    selectAllItemsRegEx: checkedKeysObjectRefsNonOcr,
                    selectAllItemsRegExOcr: checkedKeysObjectRefsOcr,
                    selectRedactionRegExOcr: selectRedactionRegExOcr,
                }
                //de decomentat
                this.onSaveQueryStatusMixed(objectListRedact)
                this.setState({
                    redactedKeys: existingRedactedKeys
                }, () => {
                    //this.props.drawAllMatchedWordsByClassList(classList, unCheckedKeys, 'regex', allWordsRedacted)
                    this.props.drawRedactAllMatchedWordsMixed(classList, classListMixed, unCheckedKeys, 'regex', objectListRedact)
                    this.setState({ unCheckedKeys: [] })
                })
            }
        })
    }
    onSaveQueryStatusMixed = (objectListRedact) => {
        const keyListWordNonOcrRegex = []
        const keyListWordOcrRegex = []
        if (objectListRedact.selectAllItemsRegEx && objectListRedact.selectAllItemsRegEx.length > 0) {
            objectListRedact.selectAllItemsRegEx.forEach((dict) => {
                //keyListWordNonOcrRegex.push(`${ dict.key } => ${ dict.name } => ${ dict.pageNumber }`)
                keyListWordNonOcrRegex.push(dict.keyMixtRedaction)
            })
        }
        if (objectListRedact.selectAllItemsRegExOcr && objectListRedact.selectAllItemsRegExOcr.length > 0) {
            objectListRedact.selectAllItemsRegExOcr.forEach((regEx) => {
                keyListWordOcrRegex.push(regEx.name)
            })
        }
        let updateStatus = false
        if ((keyListWordNonOcrRegex && keyListWordNonOcrRegex.length > 0)) {
            updateStatus = true
        } else {
            updateStatus = false
        }
        const saveAnnotationWithStateDTO = {
            keyWordOcr: this.props.alreadyRedactedKeys.standardOCR,
            keyWordOcrRegex: keyListWordOcrRegex,
            keyWordOcrDictionary: this.props.alreadyRedactedKeys.businessOCR,
            keyWordNonOcr: this.props.alreadyRedactedKeys.standard,
            keyWordNonOcrRegex: keyListWordNonOcrRegex,
            keyWordNonOcrDictionary: this.props.alreadyRedactedKeys.business,
            updateRedactions: updateStatus,
            idDocument: this.props.idDocument
        }
        apiSmartBoxSaveQueryStatusMixed(saveAnnotationWithStateDTO).then((response) => {
            this.props.onGetRedactedKeysMixed()
        })
    }
    onFindElementTreeRegEx = (e, item) => {
        e.stopPropagation()
        if (!item.isOcr) {
            let checkedKeysRef = []
            const selectBoldItem = []
            selectBoldItem.push(item.key)
            if (item && item.children.length > 0) {
                item.children.forEach((item) => {
                    checkedKeysRef.push(item)
                })
            } else {
                checkedKeysRef.push(item)
            }
            if (item && item.children && item.children.length > 0) {
                this.props.onCheckedKeysObjectRefsRegEx(checkedKeysRef)
            } else {
                this.findElementByClassChildren(item)
            }
            this.setState({
                selectedChildrenBold: selectBoldItem
            })
        }
    }
    findElementByClassChildren(className) {
        const element = document.getElementsByClassName(className.key)
        const oldElement = document.querySelector('#matched-word--current')
        if (className) {
            if (oldElement) {
                oldElement.removeAttribute('id')
            }
            if (+className.pageNumber !== +this.props.pageNumber) {
                this.props.changePage(+className.pageNumber)
            }
            if (element && element.length > 0) {
                element[0].id = 'matched-word--current'
                element[0].style.borderColor = className.backgroundColor.replace('0.3', '1')
                element[0].scrollIntoView(false)
            }
        } else {
            const element = document.querySelector('#matched-word--current')
            element.removeAttribute('id')
        }
    }
    onHoverItemRegEx = (e, item) => {
        this.setState({
            selectedItemRegEx: item
        })
    }
    onLeaveItemRegEx = () => {
        this.setState({
            selectedItemRegEx: {}
        })
    }
    onSelectOcr = () => {
        return true
    }
    onShowVisibleCatRegEx = () => {
        const { newRegularExpressions } = this.state
        const { showVisibleRegEx } = this.props

        newRegularExpressions && newRegularExpressions[0].children.length > 0 && newRegularExpressions[0].children.forEach((keyColour) => {
            keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                    if (keyColour3 && keyColour3.key && showVisibleRegEx !== null) {
                        const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                        for (const element of aiAnnotations) {
                            if (showVisibleRegEx === false) {
                                element.style.background = keyColour3.backgroundColor
                                element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                            } else if (showVisibleRegEx === true) {
                                element.style.background = 'transparent'
                                element.style.borderColor = 'initial'
                            }
                        }
                    }
                })
            })
        })
    }
    render() {
        const { expandedKeys,
            redactedKeys,
            newRegularExpressions,
            colorCategories,
            selectedChildrenBold,
            disableRemoveCategory,
            selectedItemRegEx, autoExpandParent, docAnnotations } = this.state
        let { checkedKeys } = this.state
        const { verificationOnClickClearAllRegEx, isOcr, docAnnotationsRegularExp } = this.props
        // console.log('checkedKeys --- RENDER REG EX', checkedKeys)
         //console.log('newRegularExpressions --- RENDER REG EX', newRegularExpressions)
        //this.onDisplayDifferenceColourOnActiveTab()
        // if (verificationOnClickClearAllRegEx && this.props.isSmartBoxApplication) {
        //     checkedKeys = this.displayCheckKeysNonDeleteManual()
        // }
        //this.onShowVisibleCatRegEx()
        let treeSavedRegEx = {}
        if (checkedKeys && checkedKeys.length > 0) {
            for (let element of checkedKeys) {
                element = element.substring(0, element.lastIndexOf('-'))
                if (treeSavedRegEx[element]) {
                    treeSavedRegEx[element] += 1
                } else {
                    treeSavedRegEx[element] = 1
                }
            }
        } else {
            treeSavedRegEx = {}
        }
        return (
            <div className="new-tabs-page update-reg-ex">
                <div className="new-tab-container">
                    {/*<div className="new-tab-container__title-tab">Regular Expressions</div>*/}
                    { newRegularExpressions && newRegularExpressions[0].children.length > 0 &&
                    <div className="new-tab-container__content-tab image-tags-scroll ai-tags-scroll">
                        <Tree
                            checkable
                            onExpand={this.onExpand}
                            defaultExpandAll={true}
                            expandedKeys={expandedKeys}
                            defaultExpandedKeys={expandedKeys}
                            autoExpandParent={ autoExpandParent }
                            onCheck={newRegularExpressions && newRegularExpressions[0].children.length === 0 ? this.onCheckDisableSelectAll : this.onCheck}
                            checkedKeys={checkedKeys}
                            onSelect={this.props.isOcr ? this.onSelectOcr :(selectedKeys, {node}) => this.findElementRegularExpresion(selectedKeys, node)}
                            selectedKeys={this.state.selectedKeys}

                        >
                            {renderTreeSearchRegEXCopy(
                                newRegularExpressions[0].children,
                                expandedKeys,
                                this.expandHideCategory,
                                redactedKeys,
                                colorCategories,
                                this.removeCategory,
                                checkedKeys,
                                selectedChildrenBold,
                                disableRemoveCategory,
                                this.onHoverItemRegEx,
                                selectedItemRegEx,
                                this.onCheckSelectedTreeRedactedReg,
                                this.onFindElementTreeRegEx,
                                isOcr,
                                treeSavedRegEx,
                                this.onLeaveItemRegEx)}
                        </Tree>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

RegularExpressionsCopy.propTypes = {
    isDocumentReady: PropTypes.bool.isRequired,
    toggleLoadingProcess: PropTypes.func
}

export default RegularExpressionsCopy