import React from "react";
import './BusinessProcessPage.scss';
import BusinessProcessTable from "./BusinessProcessTable";
import BusinessProcessBuilder from "./BusinessProcessBuilder";
import {connect} from "react-redux";

class BusinessProcessPage extends React.Component {

    state = {
        visibleBusinessProcessBuilder: false,
        selectBusiness: {}
    }
    onVisibleBusinessProcessBuilder = (status, item) => {
        this.setState({
            visibleBusinessProcessBuilder: status,
            selectBusiness: item,
        })
    }
    render() {
        const { visibleBusinessProcessBuilder, selectBusiness } = this.state
        return (
            <div className="business-process-page">
                <div className="business-process-page__content">
                    { !visibleBusinessProcessBuilder &&
                        <div className="business-process-header">
                            <span className="title-header">Business process activities</span>
                        </div>
                    }
                    <div className="content-business-process-page">
                        { !visibleBusinessProcessBuilder ?
                            <BusinessProcessTable clientId={this.props.clientId} client={ this.props.client } onVisibleBusinessProcessBuilder={this.onVisibleBusinessProcessBuilder}/>
                            :
                        <BusinessProcessBuilder onVisibleBusinessProcessBuilder={this.onVisibleBusinessProcessBuilder} clientId={this.props.clientId}
                                                selectBusiness={ selectBusiness }/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    client: state.subscriptionReducer.subscription.subscription.client,
})
export default connect(mapStateToProps, {
})(BusinessProcessPage)