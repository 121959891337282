import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import './ClutterPage.scss'
import {Checkbox, Input, Select, Table, Tooltip} from "antd";
import {apiGetClutterEmailThreadingListFirstPage, apiGetClutterEmptyFolderList} from "../../../../../api/SmartBox";
const {Option} = Select
const { Search } = Input
const { Column } = Table
class EmailThreadsTable extends React.Component {
    state = {
        filesPerPageLimit: 20,
        pageLimitDisplay: 20,
        totalRecords: 0,
        currentPage: 1,
        isPrevDisabled: true,
        isNextDisabled: false,
        inputPage: '',
        valueInputSearch: '',
        statusOpenedRow: false,
        onHoverForth: false,
        onHoverStart: false,
        onHoverBack: false,
        onHoverEnd: false,
        tableEmailThreads: [],
        statusPaginationEmptyFolder: false,
        selectedRowKeys: [],
        filesSelect: [],
        filesSelectRowsGlobal: [],
        filesSelectRows: [],
        nonSelectedRows: [],
        nonSelectedRowsIds: [],
        nonSelectedWords: [],
        valueAutoSelectedAll: false,
        checkSelectAll: false,
        activePageBack: false,
        selectEmailThreadHash: [],
        excludedEmailThreadHash: [],
        selectAll: false
    }
    componentDidMount() {
       this.onGetClutterEmailThreadingListFirstPage()
        this.props.onGetSearchEmptyFolder(null)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStatusClutter && this.props.checkStatusClutter !== 'In Progress') {
            this.onGetClutterEmailThreadingListFirstPage()
        }
    }
    onGetClutterEmailThreadingListFirstPage = () => {
        const { currentPage, filesPerPageLimit, valueInputSearch } = this.state
        const { folder } = this.props
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        const getEmailThreadingListRequestDTO  = {
            pageNumber: currentPage,
            pageRows: filesPerPageLimit,
            search: valueInputSearch,
            boxPath: rootPathParent,
        }
        apiGetClutterEmailThreadingListFirstPage(getEmailThreadingListRequestDTO).then((response) => {
            if (response.status === 200) {
                this.setState({
                    tableEmailThreads: response.data.items,
                    totalRecords: response.data.total,
                    statusPaginationEmptyFolder: false
                }, () => {
                    this.updatePreviewNextPageNumber()
                })
            }
        })
    }
    autoSelectedAll = (e) => {
        const { tableEmailThreads, selectEmailThreadHash } = this.state
        const selectAllFiles = []
        const selectedAllKeys = []
        if (e.target.checked) {
            this.props.onGetDeclutterEmailsFirstPageHashListNew([], [])
            this.props.onGetSelectAllEmailThreads(true)
            this.setState({
                selectAll: true,
                selectEmailThreadHash: [],
                excludedEmailThreadHash: [],
            })
        } else {
            this.props.onGetSelectAllEmailThreads(false)
            this.props.onGetDeclutterEmailsFirstPageHashListNew(selectEmailThreadHash, [])
            this.setState({
                selectAll: false,
                excludedEmailThreadHash: [],
            })
        }

        if (e.target.checked) {
            tableEmailThreads && tableEmailThreads.length > 0 && tableEmailThreads.forEach((file, index) => {
                file.key = index
                selectedAllKeys.push(index)
                selectAllFiles.push(file)
            })
            //this.props.onGetSelectFilesEmptyFolder(selectAllFiles)
            //this.props.onGetDeclutterEmailsFirstPageHashList([])
            this.setState({
                    selectedRowKeys: selectedAllKeys,
                    checkSelectAll: true,
                    filesSelect: selectAllFiles }
                //     , () => {
                //   this.onGetListDuplicateFileBox()
                // }
            )
        } else {
            //this.props.onGetSelectFilesEmptyFolder([])
            this.setState({
                selectedRowKeys: [],
                checkSelectAll: false,
                filesSelect: [] })
        }

        if (tableEmailThreads && tableEmailThreads.length > 0) {
            this.setState({
                valueAutoSelectedAll: e.target.checked
            })
        } else {
            this.setState({
                valueAutoSelectedAll: false
            })
        }
    }
    changeFilesPerPageLimit = (e) => {
        this.setState({ filesPerPageLimit: e }, () => {
            this.onGetClutterEmailThreadingListFirstPage()
        })
    }

    onMouseEnterOnPanel(e, status) {
        switch (status) {
            case 'forth' :
                this.setState({
                    onHoverForth: true
                })
                break
            case 'start' :
                this.setState({
                    onHoverStart: true
                })
                break
            case 'back' :
                this.setState({
                    onHoverBack: true
                })
                break
            case 'end' :
                this.setState({
                    onHoverEnd: true
                })
                break
            case 'back-files' :
                this.setState({
                    activePageBack: true
                })
                break
            default: break
        }
    }
    onMouseLeaveOnPanel(e, status) {
        this.setState({
            onHoverForth: false,
            onHoverStart: false,
            onHoverBack: false,
            onHoverEnd: false,
            activePageBack: false,
        })

    }
    handleMovePage = (e, direction) => {
        const { isPrevDisabled, isNextDisabled, totalRecords, filesPerPageLimit, statusPaginationEmptyFolder } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.setState({
                        statusPaginationEmptyFolder: true
                    }, () => {
                        this.gotoPage(1)
                    })
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.setState({
                        statusPaginationEmptyFolder: true
                    }, () => {
                        this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
                    })
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.setState({
                        statusPaginationEmptyFolder: true
                    }, () => {
                        this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / filesPerPageLimit) ? Math.ceil(totalRecords / filesPerPageLimit) : this.state.currentPage + 1)
                    })
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.setState({
                        statusPaginationEmptyFolder: true
                    }, () => {
                        this.gotoPage(Math.ceil(totalRecords/filesPerPageLimit))
                    })
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPage = (newPage) => {
        const { totalRecords, filesPerPageLimit } = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPage: newPage }, () => {
            this.onGetClutterEmailThreadingListFirstPage()
        })
    }
    handleInputPageChange = (e) => this.setState({ inputPage: e.target.value })
    handleInputPageEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlur()
        }
    }

    handleInputPageBlur = () => {
        const { inputPage } = this.state
        if (inputPage) {
            const paginationEnd = Math.ceil(this.state.totalRecords / this.state.filesPerPageLimit)
            if (inputPage > 0 && inputPage <= paginationEnd) {
                this.setState({
                    statusPaginationEmptyFolder: true
                }, () => {
                    this.gotoPage(inputPage)
                })
            } else {
                this.setState({
                    statusPaginationEmptyFolder: true
                }, () => {
                    this.gotoPage(1)
                })
                this.setState({ inputPage: 1 })
            }
        }
    }
    onSearchClutter = (e) => {
        const { tableEmailThreads } = this.state
        this.props.onGetSearchEmptyFolder(e)
        this.setState({
            valueInputSearch: e
        }, () => {
            if (e.length > 0) {
                if (tableEmailThreads && tableEmailThreads.length > 0) {
                    this.onGetClutterEmailThreadingListFirstPage()
                }
            }
            if (e === '') {
                this.onGetClutterEmailThreadingListFirstPage()
            }
        })
    }
    updatePreviewNextPageNumber = () => {
        const { totalRecords, currentPage } = this.state
        const totalPages = Math.ceil(totalRecords / this.state.filesPerPageLimit)
        const isPrevDisabled = currentPage > 1 ? false : true
        const isNextDisabled = currentPage < totalPages ? false : true
        this.setState({ isPrevDisabled, isNextDisabled, currentPage }, () => {
            //this.onCheckSelectedRows()
            this.onCheckKeysSelectedRows()
        })
    }
    onCheckKeysSelectedRows = () => {
      const { selectAll, tableEmailThreads, excludedEmailThreadHash, selectEmailThreadHash } = this.state
        const updateKeys = []
        if (selectAll) {
            tableEmailThreads && tableEmailThreads.length > 0 && tableEmailThreads.forEach((list, index) => {
                if (!excludedEmailThreadHash.includes(list.emailThreadHash)) {
                    updateKeys.push(index)
                }
            })
        } else {
            tableEmailThreads && tableEmailThreads.length > 0 && tableEmailThreads.forEach((list, index) => {
                if (selectEmailThreadHash.includes(list.emailThreadHash)) {
                    updateKeys.push(index)
                }
            })
        }
        const uniqueUpdateKeys = [...new Set(updateKeys)]
        this.setState({
            selectedRowKeys: uniqueUpdateKeys
        })
    }
    onCheckSelectedRows = () => {
        const { tableEmailThreads, filesSelectRows, checkSelectAll, nonSelectedRows } = this.state
        const updateKeys = []

        if (checkSelectAll) {
            tableEmailThreads && tableEmailThreads.length > 0 && tableEmailThreads.forEach((list, index) => {
                filesSelectRows && filesSelectRows.length > 0 && filesSelectRows.forEach((list2, index2) => {
                    if (nonSelectedRows && nonSelectedRows.length > 0) {
                        if (!nonSelectedRows.includes(list.emailThreadHash)) {
                            updateKeys.push(index)
                        }
                    } else {
                        updateKeys.push(index)
                    }
                })
            })
        } else {
            tableEmailThreads && tableEmailThreads.length > 0 && tableEmailThreads.forEach((list, index) => {
                filesSelectRows && filesSelectRows.length > 0 && filesSelectRows.forEach((list2, index2) => {
                    if (list.emailThreadHash === list2.emailThreadHash) {
                        updateKeys.push(index)
                    }
                })
            })
        }

        const uniqueUpdateKeys = [...new Set(updateKeys)]
        this.setState({
            selectedRowKeys: uniqueUpdateKeys
        })
    }
    onSelectDeduplicate = (selectedRowKeys, filesSelect, e) => {
        const { tableEmailThreads, checkSelectAll, selectAll } = this.state
        const nonSelected = []
        const nonSelectedIds = []
        const nameFilesSelected = []
        const selectEmailThreadHashNew = []
        let selectEmailThreadHashGlobal = []
        const nonSelectEmailThreadHash = []
        let nonSelectEmailThreadHashGlobal = []
        filesSelect && filesSelect.length > 0 && filesSelect.forEach((error) => {
            nameFilesSelected.push(error.emailThreadHash)
        })

        if (!selectAll) {
            filesSelect && filesSelect.length > 0 && filesSelect.forEach((error) => {
                selectEmailThreadHashNew.push(error.emailThreadHash)
            })
            selectEmailThreadHashGlobal = this.state.selectEmailThreadHash.concat(selectEmailThreadHashNew)
            tableEmailThreads && tableEmailThreads.length > 0 && tableEmailThreads.forEach((error) => {
                if (!nameFilesSelected.includes(error.emailThreadHash)) {
                    selectEmailThreadHashGlobal = selectEmailThreadHashGlobal.filter((el) => el !== error.emailThreadHash)
                }
            })
        } else if (selectAll) {
            tableEmailThreads && tableEmailThreads.length > 0 && tableEmailThreads.forEach((error) => {
                if (!nameFilesSelected.includes(error.emailThreadHash)) {
                    nonSelectEmailThreadHash.push(error.emailThreadHash)
                }
            })
            nonSelectEmailThreadHashGlobal = this.state.excludedEmailThreadHash.concat(nonSelectEmailThreadHash)
            nameFilesSelected && nameFilesSelected.length > 0 && nameFilesSelected.forEach((error) => {
                if (nonSelectEmailThreadHashGlobal.includes(error)) {
                    nonSelectEmailThreadHashGlobal = nonSelectEmailThreadHashGlobal.filter((el) => el !== error)
                }
            })
        }

        let filesSelectedGlobal = this.state.filesSelectRowsGlobal.concat(filesSelect)
        //const filesSelected = filesSelect
        let filesSelected = this.state.filesSelectRows.concat(filesSelect)
        tableEmailThreads && tableEmailThreads.length > 0 && tableEmailThreads.forEach((error) => {
            if(!nameFilesSelected.includes(error.emailThreadHash)) {
                nonSelected.push(error.emailThreadHash)
                nonSelectedIds.push(error.emailThreadHash)
            }
        })
        this.state.filesSelectRowsGlobal && this.state.filesSelectRowsGlobal.length > 0 && this.state.filesSelectRowsGlobal.forEach((error) => {
            if(nonSelected.includes(error.emailThreadHash)) {
                filesSelectedGlobal = this.state.filesSelectRowsGlobal.filter((el) => el.emailThreadHash !== error.emailThreadHash)
                this.setState({
                    filesSelectRowsGlobal: filesSelectedGlobal
                })
            }
        })

        const otherFileSelected = []
        this.state.filesSelectRows && this.state.filesSelectRows.length > 0 && this.state.filesSelectRows.forEach((error) => {
            if(nonSelected.includes(error.emailThreadHash)) {
                filesSelected = this.state.filesSelectRows.filter((el) => el.emailThreadHash !== error.emailThreadHash)
                // this.setState({
                //     filesSelectRows: filesSelected
                // })
            }
        })

        const uniqueFilesSelectedGlobal = filesSelectedGlobal && filesSelectedGlobal.length > 0 && filesSelectedGlobal.filter((a, i) => filesSelectedGlobal.findIndex((s) => a.emailThreadHash === s.emailThreadHash) === i)
        const uniqueFilesSelected = filesSelected && filesSelected.length > 0 && filesSelected.filter((a, i) => filesSelected.findIndex((s) => a.emailThreadHash === s.emailThreadHash) === i)

        const uniqueSelectedEmailThreadHashGlobal = selectEmailThreadHashGlobal && selectEmailThreadHashGlobal.length > 0 && selectEmailThreadHashGlobal.filter((a, i) => selectEmailThreadHashGlobal.findIndex((s) => a === s) === i)
        const uniqueNonSelectedEmailThreadHashGlobal = nonSelectEmailThreadHashGlobal && nonSelectEmailThreadHashGlobal.length > 0 && nonSelectEmailThreadHashGlobal.filter((a, i) => nonSelectEmailThreadHashGlobal.findIndex((s) => a === s) === i)

        this.setState({
            //nonSelectedRows: nonSelected,
            nonSelectedRows: [...new Set(this.state.nonSelectedRows.concat(nonSelected))],
            nonSelectedRowsIds: [...new Set(this.state.nonSelectedRowsIds.concat(nonSelectedIds))],
        })
        this.props.onGetDeclutterEmailsFirstPageHashListNew(uniqueSelectedEmailThreadHashGlobal === false ? [] : uniqueSelectedEmailThreadHashGlobal,
            uniqueNonSelectedEmailThreadHashGlobal === false ? [] : uniqueNonSelectedEmailThreadHashGlobal)
        this.setState({
            selectedRowKeys: selectedRowKeys,
            //filesSelect: selectedRowKeys,
            //filesSelectRows: filesSelected,
            filesSelectRows: uniqueFilesSelected === false ? [] : uniqueFilesSelected,
            filesSelectRowsGlobal: uniqueFilesSelectedGlobal === false ? [] : uniqueFilesSelectedGlobal,
            selectEmailThreadHash: uniqueSelectedEmailThreadHashGlobal === false ? [] : uniqueSelectedEmailThreadHashGlobal,
            excludedEmailThreadHash: uniqueNonSelectedEmailThreadHashGlobal === false ? [] : uniqueNonSelectedEmailThreadHashGlobal,
        })

        const emailThreadHashList = []
        uniqueFilesSelectedGlobal && uniqueFilesSelectedGlobal.length > 0 && uniqueFilesSelectedGlobal.forEach((item) => {
            emailThreadHashList.push(item.emailThreadHash)
        })
        //this.props.onGetDeclutterEmailsFirstPageHashList(emailThreadHashList)
        this.setState({
           // selectedRowKeys: selectedRowKeys,
            filesSelect: filesSelect })
        if (filesSelect && filesSelect.length > 0) {
            this.setState({
                valueAutoSelectedAll: true
            })
        } else {
            this.setState({
                valueAutoSelectedAll: false
            })
        }
        if (tableEmailThreads.length > selectedRowKeys.length) {
            this.setState({
                checkSelectAll: false
            })
        }
    }
    onSelectedRowEmailThreads = (e, record) => {
        const emailThreadHashList = []
        emailThreadHashList.push(record.emailThreadHash)
        this.props.onVisibleEmailThreadsList(true)
        this.props.selectFileDuplicateBox(record)
        this.props.onGetDeclutterEmailsFirstPageHashList(emailThreadHashList)
    }
    onClickBackList = () => {
        this.props.onBackFilesEmptyFolder(true)
    }
    render() {
        const { totalRecords, filesPerPageLimit, currentPage, isPrevDisabled, isNextDisabled, inputPage, tableEmailThreads, onHoverForth,
            onHoverStart, onHoverBack, onHoverEnd, statusPaginationEmptyFolder, valueInputSearch, selectedRowKeys, activePageBack,
            selectEmailThreadHash, excludedEmailThreadHash, selectAll} = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectDeduplicate,
        }
        return (
            <div className="new-clutter-page-contain">
                <div className="content-back-files">
                    <span className="header-back-file"
                          onMouseEnter={(e) => this.onMouseEnterOnPanel(e, 'back-files')}
                          onMouseLeave={(e) => this.onMouseLeaveOnPanel(e)}>
                                 <span onClick={ this.onClickBackList } className={ activePageBack ? 'icon-v21-back-active' : "icon-v21-back-1" }></span>
                    </span>
                </div>
                <div className="new-clutter-page-header">
                    <div className="content-select-all-new-clutter">
                        <div className={ '' }>
                            <div className={'checkbox-auto' }>
                                <Checkbox
                                    //checked={ filesSelect.length === 0 ? false : true }
                                    className="select-deduplicate-checkbox"
                                    onChange={(e) => this.autoSelectedAll(e)}></Checkbox>
                            </div>
                        </div>
                        <div className="select-auto">
                            <span
                                //className={ typeof tableDuplicatesFile === 'undefined' || tableDuplicatesFile.length === 0 ? 'option-select-all-disabled' : '' }
                            >Select all</span>
                        </div>
                    </div>

                    <div className="per-page-new-clutter">
                        <Select dropdownClassName="select-per-page-duplicate per-duplicate"
                                value={'Show'+ ' '+ `${filesPerPageLimit}`}
                                onChange={ (e) => this.changeFilesPerPageLimit(e) }>
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </div>

                    <div className="buttons-control-pagination">
                        <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                <span className={ tableEmailThreads && tableEmailThreads.length === 0 ? 'icon-v14-start-1 disabled-pagination' :
                    statusPaginationEmptyFolder ? 'icon-v14-start-1 disabled-back' : isPrevDisabled ? 'icon-v14-start-1 disabled-back' : onHoverStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'start') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'first')}/>
                        </Tooltip>
                        <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                <span className={ tableEmailThreads && tableEmailThreads.length === 0 ? 'icon-v14-back-1 disabled-pagination' :
                    statusPaginationEmptyFolder ? 'icon-v14-back-1 disabled-back' : isPrevDisabled ? 'icon-v14-back-1 disabled-back' : onHoverBack ? 'icon-v21-back-active' : 'icon-v14-back-1' }
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'back') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                        </Tooltip>
                        { totalPages === 0 &&
                        <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
                        }
                        { totalPages === 1 &&
                        <span className='buttons-control-pagination__text'>{currentPage} of {totalPages} </span>
                        }
                        { totalPages > 1 &&
                        <span className='buttons-control-pagination__text'>
                <input
                    type="number"
                    className="insert-page"
                    placeholder= { currentPage }
                    value= { inputPage }
                    min="1"
                    disabled={ statusPaginationEmptyFolder === true }
                    max={totalPages}
                    onChange={ (e) => this.handleInputPageChange(e) }
                    onBlur={ () => this.handleInputPageBlur() }
                    onKeyDown={ (e) => this.handleInputPageEnter(e) }
                />
                <span>of {totalPages}</span>
              </span>
                        }
                        <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                <span className={ tableEmailThreads && tableEmailThreads.length === 0 ? 'icon-v14-forth-1 disabled-pagination' :
                    statusPaginationEmptyFolder ? 'icon-v14-forth-1 disabled-next' : isNextDisabled ? 'icon-v14-forth-1 disabled-next' : onHoverForth ? 'icon-v21-forth-active' : 'icon-v14-forth-1' }
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'forth') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'next')}/>
                        </Tooltip>
                        <Tooltip title="last" className={ isNextDisabled ? 'disabled' : '' }>
                <span className={tableEmailThreads && tableEmailThreads.length === 0 ? 'icon-v14-end-1 disabled-pagination' :
                    statusPaginationEmptyFolder ? 'icon-v14-end-1 disabled-next' : isNextDisabled ? 'icon-v14-end-1 disabled-next' : onHoverEnd ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'end') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ (e) => this.handleMovePage(e, 'last')}/>
                        </Tooltip>
                    </div>

                    <div className="input-search-clutter">
                        <Search defaultValue={ valueInputSearch } className="search-duplicates" placeholder="Search"
                                allowClear
                            //disabled={ searchInputStatus === true }
                            //onChange={(e) => this.onChangeSearchDuplicates(e)}
                                onSearch={this.onSearchClutter}
                                style={{ width: 200 }} />
                    </div>

                </div>

                <div className="table-new-clutter-content">
                    <Table className="table-duplicates-files"
                           dataSource={ tableEmailThreads }
                           pagination={ false }
                           showHeader={ false }
                           loading={ this.props.checkStatusClutter === 'In Progress'}
                           rowSelection={ rowSelection }
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (e) => this.onSelectedRowEmailThreads(e, record)
                            }
                        }}
                    >
                        <Column className="column-name-duplicate" dataIndex="docName" key="docName" render={(text, record) => {
                            return (
                                <span key={ record.docName } className="name-duplicate-content">
                                    <span className="text-style">{text}</span>
                                </span>
                            )
                        }}/>
                        <Column className="total-files" dataIndex="emailsNumber" key="emailsNumber"render={(text, record) => {
                            return (
                                <span key={ record.emailThreadHash } className="total-files-content">
                                    <span className="number-duplicate"><span className="nr-dupl">{ record.emailsNumber }</span></span>
                                    <span className="text-style icon-v16-arrow-medium rotate-arrow-medium"></span>
                              </span>
                            )
                        }}/>
                    </Table>
                </div>

            </div>
        )

    }
}

EmailThreadsTable.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps)
)(EmailThreadsTable)