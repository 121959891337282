import axios from 'axios'
import { objectToFormData } from '../utils/FormDataBuilder'
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
// const host = isLocalhost() ? 'https://staging.ospreysmart.com' : ''
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const apiUserSignUp = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/signup`,
    data
  })
}
export const stripePayment = (charge) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/payment/charge`,
    data: objectToFormData(charge)
  })
}

export const apiUserLogin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/login`,
    data
  })
}

export const apiGetUserLogin = (search) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/branding-profile/get-branding-profile-by-name${search}`,
  })
}

export const apiUserPasswordRecovery = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/passwordrecovery`,
    data
  })
}

export const apiUserActivate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/activate`,
    data
  })
}

export const checkResetLink = (token) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/user/valid-reset-link/${ token }`,
  })
}

export const apiUserFetchInfo = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/user/userinfo`
  })
}

export const apiMasterServiceToken = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/master/authenticate`
  })
}

export const apiUserFetchInfoByUserId = (userId) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/userinfo`,
    data: { userId }
  })
}
export const apiReviewActivityUser = (userId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/user/${ userId }/reviewactivityuser`
  })
}

export const apiPostReviewActivityUser = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/reviewactivityuser`,
    data
  })
}

export const apiDowngradePlan = (planId, clientId) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/payment/downgrade/${ planId }/${ clientId }`
  })
}

export const apiClearReviewActivityUser = (userId) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/user/${ userId }/clearreviewactivityuser`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken }
  })
}

export const apiUserInvite = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/create`,
    data
  })
}
export const apiUserInviteMultipleEmails = (emails) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/createmultiple`,
    data: { listUsers: emails }
  })
}

export const apiUserCreate = (data) => {
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/create`,
    data: { 'id_token': idToken, 'user': data }
  })
}

export const apiUserUpdateRoles = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/updateroles`,
    data
  })
}

export const apiUserResendInvite = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/resendinvite`,
    data
  })
}
export const apiUserResendInviteGodAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/resend-invite-god-admin`,
    data
  })
}

export const apiUserUpdate = (data) => {
  const bodyFormData = objectToFormData(data)
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/update`,
    data: bodyFormData
  })
}

export const apiUserChangePassword = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/changepassword`,
    data
  })
}

export const apiUserFetchUsersList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/user/list`,
  })
}

export const apiUserFetchUsersListMinimized = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/user/for-select`,
  })
}

export const apiUserFetchUser = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/userinfo`,
    data
  })
}

export const apiUserLimitReached = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/subscribeemail`,
    data
  })
}

export const apiUserContactSales = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/email/sales`,
    data
  })
}

export const apiUserFilterList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/filter-list`,
    data
  })
}

export const apiRemoveUser = (data) => {
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  const dpiaToken = localStorage.getItem('dpiaToken')
  
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/deactivate-user`,
    data: { 'dpiaToken': dpiaToken, 'data': data, 'id_token': idToken }
  })
}

export const apiResendActivationLink = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/resend-activation-link`,
    data: data
  })
}

export const getTenants = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/multitenancy/tenant`,
  })
}

export const setTenantId = (data) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/multitenancy/switch-tenant`,
    data
  })
}

export const apiUserMultitenancySignUp = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/multitenancy/invite`,
    data
  })
}

export const apiAcceptTenancy = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/multitenancy/acceptTenancy`,
    data
  })
}

export const checkMultitenancyToken = (token) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/multitenancy/accept-user/${ token }`
  })
}

export const checkMultitenancyTokenOldUser = (token) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/multitenancy/accept/${ token }`
  })
}

export const apiResetMfaUser = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/resetMfaUser`,
    data
  })
}
export const apiDisabledMfaUser = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/disableMfaUser`,
    data
  })
}
export const apiResetMfaAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/resetMfaAdmin`,
    data
  })
}
export const apiResetWordListDownload = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/reset-word-list-download`,
    responseType: 'arraybuffer',
    data
  })
}
export const apiResetMfaUserLogin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/resetMfaUserLogin`,
    data
  })
}
export const apiUserCreateFieldSolr = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/user/create-field-solr`,
    data
  })
}

export const userTokenRefresh = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/user/refresh-access-token`,
  })
}

export const apiWarmupTrigger = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup`,
  })
}

export const apiWarmupTriggerGetConnectList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/get-connector-list`,
  })
}

export const apiWarmupTriggerCreateBusinessProcess = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-business-process`,
  })
}

export const apiWarmupTriggerDeleteBusinessProcess = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/delete-business-process`,
  })
}

export const apiWarmupTriggerCreateDirectory = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-directory`,
  })
}

export const apiWarmupTriggerGetSarSteps = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/get-sar-steps`,
  })
}

export const apiWarmupTriggerUpdateClientData = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/update-client-data`,
  })
}

export const apiWarmupTriggerCreateSar = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-sar`,
  })
}

export const apiWarmupTriggerGetAnnotations = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/get-annotations`,
  })
}

export const apiWarmupTriggerCreateBusinessTermList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-business-term-list`,
  })
}

export const apiWarmupTriggerEditBusinessTermList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/edit-business-term-list`,
  })
}

export const apiWarmupTriggerGetCompanyDetails = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/get-company-details`,
  })
}

export const apiWarmupTriggerUpdateUser = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/update-user`,
  })
}
export const apiWarmupTriggerDeactivateUser = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/deactivate-user`,
  })
}

export const apiWarmupTriggerGetAnnotationColorList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/get-annotation-color-list`,
  })
}
export const apiWarmupTriggerCreateUser = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-user`,
  })
}

export const apiWarmupTriggerLoginUser = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/login-user`,
  })
}

export const apiWarmupTriggerSaveRedactionFilters = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/save-redaction-filters-selections`,
    data
  })
}

export const apiWarmupTriggerFieldNames = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/field-names`,
  })
}

export const apiWarmupTriggerDocLocker = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/doc-locker`,
  })
}

export const apiWarmupAddCustomTagClient = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/add-custom-tag-client-admin`,
    data
  })
}

export const apiWarmupAssignMember = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/assign-member`,
  })
}

export const apiWarmupResendInvite = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/resendinvite`,
  })
}

export const apiWarmupEditBusinessProcessComponents = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/edit-business-process-components`,
    data
  })
}

export const apiWarmupCreateWorkflowTicket = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-workflow-ticket`,
    data
  })
}

export const apiWarmupDeleteEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/delete-email-template-workflow`,
    data
  })
}

export const apiWarmupSendEmailWorkflowTicket = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/send-email-workflow-ticket`,
    data
  })
}

export const apiWarmupCreateNewEmailTemplateSimple = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-new-email-template-simple`,
    data
  })
}

export const apiWarmupSaveFormSectionsDataTicketWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/save-form-sections-data-ticket-workflow`,
    data
  })
}

export const apiWarmupCreateDuplicateFormWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-duplicate-form`,
    data
  })
}

export const apiWarmupCreateDuplicateNewWorkflowTemplate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-new-duplicate-workflow-template`,
    data
  })
}

export const apiWarmupSaveDataCollectorOptionsWorkflowTicket = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/save-data-collector-options-workflow-ticket`,
    data
  })
}
export const apiWarmupCreateRedactionColorProfileWarmup = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/create-redaction-color-profile-warmup`,
    data
  })
}

export const apiWarmupSaveRedactionColorProfileCategoriesWarmUp = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/warmup/trigger-warmup/save-redaction-color-profiles-categories`,
    data
  })
}


export const apiPostDownloadActivityUser = (userId, uid) => {
  return `${ host }/osprey-0.1.0/v1/user/activityuser/download/${userId}/${uid}`
}


