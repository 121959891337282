import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import imgBoxEmpty from '../../../../../assets/box/box_empty_20x20.png'
import imgBoxEmptyNeg from '../../../../../assets/box/box_empty_negative_20x20.png'
import imgBoxProcessing from '../../../../../assets/box/box_processing_20x20.png'
import imgBox from '../../../../../assets/box/box_20x20.png'
import {Select} from "antd";
import {customConfirm} from "../../../../../utils/AssetRegister/CustomConfirm";
import {
    apiCreateFormTicketWorkflow,
    apiEditFormTicketWorkflow,
    apiSaveFormSectionsDataTicketWorkflow,
    apiMarkTicketWorkflowAsCompleted,
    apiSendInternalEmailNotificationWorkflow, getTicketWorkflowAndHeaderDetails, apiSetStepStatusTicketWorkflow
} from "../../../../../api/SmartBox";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import cookie from "react-cookies";
const { Option } = Select;
export class HeaderWorkflowStep extends Component {

    state = {
        hoverRequestDetail: false,
        hoverIconDate: false,
        listOptionStatus:['Open', 'Archive', 'Complete'],
        selectOptionStatus: 'Open',
        hoverForm: false,
        hoverInternalEmailNot: false,
        hoverEmail: false,
        hoverDefinedWork: false,
        hoverConnector: false,
        hoverFormSimple: false,
        hoverDisclosure: false,
        hoverCompleted: false,
        activeStepHeader: '',
        statusFormWorkflowUpdate: 'create',
        activityHeader: 'form',
        hoverIconsSteps: {},
        stepComponentWorkflowId: null,
        activeStep: {},
        disabledSaveBack: false,
        statusBackNext: null
    }
    componentDidMount() {
        if (this.props.startFirstStepTicket) {
            if (this.props.activeFirstStepTicket) {
                this.props.onDisplayHeaderStep(true, this.props.activeFirstStepTicket)
                this.setState({
                    activeStepHeader: this.props.activeFirstStepTicket.stepComponentName,
                    stepComponentWorkflowId: this.props.activeFirstStepTicket.stepComponentWorkflowId,
                    activeStep: this.props.activeFirstStepTicket,
                }, () => {
                    this.props.onEditModalTickets(this.props.selectHeaderTickets)
                    this.props.onGetActiveStep(this.props.activeFirstStepTicket)
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.startSaveFormSectionsData) {
            this.onCreateFormTicketWorkflow('start')
        }
        if (prevProps.stepHeaderActive.stepComponentWorkflowId !== this.props.stepHeaderActive.stepComponentWorkflowId && this.props.stepHeaderActive.stepComponentWorkflowId) {
            const { ticketWorkflowStepDTOS } = this.props
            cookie.remove('emailWorkflowTicketTrackingSteps', { path: '/' })
            ticketWorkflowStepDTOS && ticketWorkflowStepDTOS.length > 0 && ticketWorkflowStepDTOS.forEach((item) => {
                if (this.props.stepHeaderActive.stepComponentWorkflowId === item.stepComponentWorkflowId) {
                    this.setState({
                        activeStep: item
                    })
                }

            })
        }
    }

    onMouseEnter = (e, step) => {
        if (step.stepCompleted) {
            this.setState({
                hoverIconsSteps: step
            })
            switch (step) {
                case 'Form':
                    this.setState({
                        hoverForm: true
                    })
                    break
                case 'Internal email notification':
                    this.setState({
                        hoverInternalEmailNot: true
                    })
                    break
                case 'Email':
                    this.setState({
                        hoverEmail: true
                    })
                    break
                case 'Web Form Email Template':
                    this.setState({
                        hoverEmail: true
                    })
                    break
                case 'define':
                    this.setState({
                        hoverDefinedWork: true
                    })
                    break
                case 'Collector':
                    this.setState({
                        hoverConnector: true
                    })
                    break
                case 'formSimple':
                    this.setState({
                        hoverFormSimple: true
                    })
                    break
                case 'Disclosure':
                    this.setState({
                        hoverDisclosure: true
                    })
                    break
                case 'completed':
                    this.setState({
                        hoverCompleted: true
                    })
                    break
                default: break
            }
        }
    }

    onMouseLeave = () => {
       this.setState({
           hoverForm: false,
           hoverInternalEmailNot: false,
           hoverEmail: false,
           hoverDefinedWork: false,
           hoverConnector: false,
           hoverFormSimple: false,
           hoverDisclosure: false,
           hoverCompleted: false,
           hoverIconsSteps: {},
       })
    }
    onChangeSelect = () => {

    }

    onCLickStepHeaderActive = (e, step) => {
        const { ticketWorkflowStepDTOS } = this.props
        cookie.remove('checkCollectorPageUploadHistoryData', { path: '/' })
        cookie.remove('emailDisclosureEmailTrackingSteps', { path: '/' })
        cookie.remove('openTicketArchive', { path: '/' })
        if (ticketWorkflowStepDTOS && ticketWorkflowStepDTOS.length > 0) {
            if ((step.stepCompleted) || (ticketWorkflowStepDTOS[0].stepComponentWorkflowId === step.stepComponentWorkflowId)) {
                switch (step.stepComponentName) {
                    case 'Form':
                        this.props.onDisplayHeaderStep(true, step)
                        break
                    case 'Email':
                        this.props.onDisplayHeaderStep(true, step)
                        break
                    case 'Web Form Email Template':
                        this.props.onDisplayHeaderStep(true, step)
                        break
                    case 'Collector':
                        this.props.onDisplayHeaderStep(true, step)
                        break
                    case 'Disclosure':
                        this.props.onDisplayHeaderStep(true, step)
                        break
                    case 'Internal email notification':
                        this.props.onDisplayHeaderStep(true, step)
                        break
                    default:
                        break
                }
                this.setState({
                    activeStepHeader: step.stepComponentName,
                    stepComponentWorkflowId: step.stepComponentWorkflowId,
                    activeStep: step,
                }, () => {
                    this.props.onEditModalTickets(this.props.selectHeaderTickets)
                    this.props.onGetActiveStep(step)
                })
            }
        }
    }

    onNextStepHeaderWorkflow = (e) => {
        const { selectHeaderTickets, selectEditTickets, ticketWorkflowStepDTOS } = this.props
        const { activeStepHeader, activityHeader } = this.state
        const { formTicketWorkflow, stepHeaderActive } = this.props
        cookie.remove('checkCollectorPageUploadHistoryData', { path: '/' })
        cookie.remove('openTicketArchive', { path: '/' })
        const recordTicket = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId
        }
        this.props.onGetStatusNextBackSteps('next')
        switch (activityHeader) {
            case 'form' :
                //if (activeStepHeader === 'Form') {
                if (stepHeaderActive.stepComponentName === 'Form' && stepHeaderActive.blockFormEdit !== true) {
                    this.setState({
                        statusBackNext: 'next'
                    }, () => {
                        this.onCreateFormTicketWorkflow('next')
                    })
                } else if (stepHeaderActive.stepComponentName === 'Form' && stepHeaderActive.blockFormEdit === true) {
                    this.setState({
                        statusBackNext: 'next'
                    }, () => {
                        this.onSetStepStatusTicketWorkflow('next')
                    })
                } else {
                    this.onSetStepStatusTicketWorkflowReadyToComplete()
                    this.props.onStatusResponseSaveFormSectionsData(200)
                    this.props.onGetTicketWorkflowAndHeaderDetailsNextBack(recordTicket, 'next')
                    if (!this.props.startSaveFormSectionsData) {
                        this.props.onGetAfterSaveComponentFromWorkflow(true)
                    }
                    this.setState({
                        disabledSaveBack: false
                    })
                }
                //}
                break
            case 'email':
                customConfirm({
                    title: <span className="icon-v22-nav-notifications"></span>,
                    content: `Send internal email notification to move on to the next step.`,
                    confirmButtonText: 'Send',
                    cancelButtonText: 'Cancel',
                    mask: true,
                    maskClosable: false,
                    closeHaveLogic: false,
                    className: 'asset-register-modal modal-send-email-step-workflow',
                    centered: true,
                    cancelClassName: 'cancel-button',
                    confirmClassName: 'confirm-button',
                    confirmAction: () => {
                    }
                })
                break
            default:{
                this.onSetStepStatusTicketWorkflowReadyToComplete()
            }
                break
        }
    }

    onBackStepHeaderWorkflow = (e) => {
        const { selectHeaderTickets, selectEditTickets, stepHeaderActive } = this.props
        const { activeStepHeader, activityHeader } = this.state
        const { formTicketWorkflow } = this.props
        cookie.remove('checkCollectorPageUploadHistoryData', { path: '/' })
        cookie.remove('openTicketArchive', { path: '/' })
        const recordTicket = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId
        }
        this.props.onGetStatusNextBackSteps('back')
        switch (activityHeader) {
            case 'form' :
                if (stepHeaderActive.stepComponentName === 'Form') {
                    this.setState({
                        statusBackNext: 'back'
                    }, () => {
                        this.onCreateFormTicketWorkflow('back')
                    })
                } else {
                    this.props.onStatusResponseSaveFormSectionsData(200)
                    this.props.onGetTicketWorkflowAndHeaderDetailsNextBack(recordTicket, 'back')
                    if (!this.props.startSaveFormSectionsData) {
                        this.props.onGetAfterSaveComponentFromWorkflow(true)
                    }
                    this.setState({
                        disabledSaveBack: false
                    })
                }
                break
            default: break
        }
    }
    onCreateFormTicketWorkflow = (status) => {
      const { formTicketWorkflow, selectHeaderTickets, statusFormWorkflow, ticketWorkflowFormId, selectEditTickets, formId, listComponentsTemplateFormProps} = this.props
        const { statusFormWorkflowUpdate, stepComponentWorkflowId, activeStep, statusBackNext } = this.state
        const formSectionDataTicketDTOList = []
        const recordTicket = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId
        }
        listComponentsTemplateFormProps && listComponentsTemplateFormProps.length > 0 && listComponentsTemplateFormProps.forEach((elem) => {
            formSectionDataTicketDTOList.push({
                formSectionType: elem.sectionType,
                formSectionId: elem.sectionId,
                shortTextValue: elem.shortTextValue ? elem.shortTextValue : null,
                longTextValue: elem.longTextValue ? elem.longTextValue : null,
                firstNameValue: elem.firstNameValue ? elem.firstNameValue : null,
                lastNameValue: elem.lastNameValue ? elem.lastNameValue : null,
                emailAddressValue: elem.emailAddressValue ? elem.emailAddressValue : null,
                streetAddressValue: elem.streetAddressValue ? elem.streetAddressValue : null,
                street2AddressValue: elem.street2AddressValue ? elem.street2AddressValue : null,
                cityAddressValue: elem.cityAddressValue ? elem.cityAddressValue : null,
                stateAddressValue: elem.stateAddressValue ? elem.stateAddressValue : null,
                postalCodeValue: elem.postalCodeValue ? elem.postalCodeValue : null,
                phoneNumber: elem.phoneNumber ? elem.phoneNumber : null,
                selectedSectionOptionIDs: elem.selectedSectionOptionIDs && elem.selectedSectionOptionIDs.length > 0 ? elem.selectedSectionOptionIDs : null,
                signature: elem.signature ? elem.signature : null,
                fileIDs: elem.fileIDs && elem.fileIDs.length > 0 ? elem.fileIDs : null,
            })
        })
        const SaveFormDataTicketWorkflowDTO = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId,
            ticketStepId: activeStep.stepId,
            workflowComponentId: activeStep.stepComponentWorkflowId,
            workflowComponentFormSelectedId: formId,
            formSectionDataTicketDTOList: formSectionDataTicketDTOList,
            markStepAsCompleted: (status === 'back' || statusBackNext === 'back') ? false : this.props.startSaveFormSectionsData ? false : true
        }
        this.setState({
            disabledSaveBack: true
        }, () => {
            apiSaveFormSectionsDataTicketWorkflow(SaveFormDataTicketWorkflowDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.props.onStatusResponseSaveFormSectionsData(response.status)
                this.props.onGetTicketWorkflowAndHeaderDetailsNextBack(recordTicket, status)
                this.props.onGetAfterSaveComponentFromWorkflow(true)
                if (!this.props.startSaveFormSectionsData) {
                    this.props.onGetAfterSaveComponentFromWorkflow(true)
                }
                this.setState({
                    disabledSaveBack: false
                })
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        })
        // if (statusFormWorkflow && statusFormWorkflow === 'create') {
        //     const createFormTicketWorkflowRequestDTO = {
        //         ticketId: selectEditTickets.ticketId,
        //         componentWorkflowId: stepComponentWorkflowId,
        //         firstName: formTicketWorkflow.firstName,
        //         lastName: formTicketWorkflow.lastName,
        //         streetAddressFirst: formTicketWorkflow.streetAddressFirst,
        //         streetAddressSecond: formTicketWorkflow.streetAddressSecond,
        //         stateProvince: formTicketWorkflow.stateProvince,
        //         city: formTicketWorkflow.city,
        //         postalCode: formTicketWorkflow.postalCode,
        //         email: formTicketWorkflow.email,
        //         telephone: formTicketWorkflow.telephone,
        //     }
        //     apiCreateFormTicketWorkflow(createFormTicketWorkflowRequestDTO).then((response) => {
        //         if (response.data.title && response.data.title.toLowerCase() === 'success') {
        //             CustomNotificationManager.success(response.data.message, 'Success')
        //             this.setState({
        //                 //activityHeader: 'email'
        //             })
        //         } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        //             CustomNotificationManager.info(response.data.message, 'Info')
        //         }
        //     })
        // } else  if (statusFormWorkflow && statusFormWorkflow === 'edit') {
        //     const editFormTicketWorkflowRequestDTO = {
        //         ticketWorkflowFormId: ticketWorkflowFormId,
        //         componentWorkflowId: stepComponentWorkflowId,
        //         firstName: formTicketWorkflow.firstName,
        //         lastName: formTicketWorkflow.lastName,
        //         streetAddressFirst: formTicketWorkflow.streetAddressFirst,
        //         streetAddressSecond: formTicketWorkflow.streetAddressSecond,
        //         stateProvince: formTicketWorkflow.stateProvince,
        //         city: formTicketWorkflow.city,
        //         postalCode: formTicketWorkflow.postalCode,
        //         email: formTicketWorkflow.email,
        //         telephone: formTicketWorkflow.telephone,
        //     }
        //     apiEditFormTicketWorkflow(editFormTicketWorkflowRequestDTO).then((response) => {
        //         if (response.data.title && response.data.title.toLowerCase() === 'success') {
        //             CustomNotificationManager.success(response.data.message, 'Success')
        //             this.setState({
        //                 //activityHeader: 'email'
        //             })
        //         } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        //             CustomNotificationManager.info(response.data.message, 'Info')
        //         }
        //     })
        // }
    }

    onSetStepStatusTicketWorkflow = (status) => {
        const { formTicketWorkflow, selectHeaderTickets, statusFormWorkflow, ticketWorkflowFormId, selectEditTickets, formId, listComponentsTemplateFormProps} = this.props
        const { statusFormWorkflowUpdate, stepComponentWorkflowId, activeStep, statusBackNext } = this.state
        const formSectionDataTicketDTOList = []
        const recordTicket = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId
        }
        const SaveFormDataTicketWorkflowDTO = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId,
            workflowComponentId: activeStep.stepComponentWorkflowId,
            markStepAsCompleted: true
        }
        this.setState({
            disabledSaveBack: true
        }, () => {
            apiSetStepStatusTicketWorkflow(SaveFormDataTicketWorkflowDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.props.onStatusResponseSaveFormSectionsData(response.status)
                this.props.onGetTicketWorkflowAndHeaderDetailsNextBack(recordTicket, status)
                this.props.onGetAfterSaveComponentFromWorkflow(true)
                if (!this.props.startSaveFormSectionsData) {
                    this.props.onGetAfterSaveComponentFromWorkflow(true)
                }
                this.setState({
                    disabledSaveBack: false
                })
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        })
    }

    onSetStepStatusTicketWorkflowReadyToComplete = () => {
        const {selectHeaderTickets, selectEditTickets} = this.props
        const {activeStep} = this.state
        const recordTicket = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId
        }
        const SaveFormDataTicketWorkflowDTO = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId,
            workflowComponentId: activeStep.stepComponentWorkflowId,
            markStepAsCompleted: true
        }
        this.setState({
            disabledSaveBack: true
        }, () => {
            apiSetStepStatusTicketWorkflow(SaveFormDataTicketWorkflowDTO).then((response) => {

                this.props.onGetTicketWorkflowAndHeaderDetailsNextBack(recordTicket)

                this.setState({
                    disabledSaveBack: false
                })
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        })
    }

    onGenerateClassNameStepsHeader = (step) => {
        const { hoverIconsSteps, activeStep} = this.state
        const { activeStepHeader } = this.props
        switch (step.stepComponentName) {
            case 'Form':
                if (step.stepIconStatus === 'DISABLED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-form active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-form active-icon-hover'
                    }
                    return 'icon-v22-form disabled-icon'
                } else if (step.stepIconStatus === 'COMPLETED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-form-done active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-form-done active-icon-hover'
                    }
                    return 'icon-v22-form-done active-icon'
                } else if (step.stepIconStatus === 'DEFAULT') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-form active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-form active-icon-hover'
                    }
                    return 'icon-v22-form active-icon'
                } else
                if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                    return 'icon-v22-form-active active-icon'
                }
                return 'icon-v22-form inactive-icon'
                break
            case 'Email':
                if (step.stepIconStatus === 'DISABLED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-1 active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-1 active-icon-hover'
                    }
                    return 'icon-v22-email-1 disabled-icon'
                }  else if (step.stepIconStatus === 'COMPLETED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-sent-done active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-sent-done active-icon-hover'
                    }
                    return 'icon-v22-email-sent-done active-icon'
                } else if (step.stepIconStatus === 'DEFAULT') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-1 active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-1 active-icon-hover'
                    }
                    return 'icon-v22-email-1 active-icon'
                } else
                if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                    return 'icon-v22-email-active disabled-icon'
                }
                return 'icon-v22-email-1 inactive-icon'
                break
            case 'Web Form Email Template':
                if (step.stepIconStatus === 'DISABLED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-1 active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-1 active-icon-hover'
                    }
                    return 'icon-v22-email-1 disabled-icon'
                }  else if (step.stepIconStatus === 'COMPLETED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-sent-done active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-sent-done active-icon-hover'
                    }
                    return 'icon-v22-email-sent-done active-icon'
                } else if (step.stepIconStatus === 'DEFAULT') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-1 active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-email-1 active-icon-hover'
                    }
                    return 'icon-v22-email-1 active-icon'
                } else
                if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                    return 'icon-v22-email-active disabled-icon'
                }
                return 'icon-v22-email-1 inactive-icon'
                break
            case 'Collector':
                if (step.stepIconStatus === 'DISABLED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-connector active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-connector active-icon-hover'
                    }
                    return 'icon-v22-connector disabled-icon'
                }  else if (step.stepIconStatus === 'COMPLETED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-connector active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-connector-active active-icon-hover'
                    }
                    return 'icon-v22-connector active-icon'
                } else if (step.stepIconStatus === 'DEFAULT') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-connector active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-connector active-icon-hover'
                    }
                    return 'icon-v22-connector active-icon'
                } else
                if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                    return 'icon-v22-connector-active active-icon'
                }
                return 'icon-v22-connector inactive-icon'
                break
            case 'Disclosure':
                if (step.stepIconStatus === 'DISABLED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-disclosure active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-disclosure active-icon-hover'
                    }
                    return 'icon-v22-disclosure disabled-icon'
                } else if (step.stepIconStatus === 'COMPLETED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-disclosure active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-disclosure-active active-icon-hover'
                    }
                    return 'icon-v22-disclosure active-icon'
                } else if (step.stepIconStatus === 'DEFAULT') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-disclosure active-icon-hover'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-disclosure active-icon-hover'
                    }
                    return 'icon-v22-disclosure active-icon'
                } else
                if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                    return 'icon-v22-disclosure-active active-icon'
                }
                return 'icon-v22-disclosure inactive-icon'
                break
            case 'Internal email notification':
                if (step.stepIconStatus === 'DISABLED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-radio-button-off active-icon-hover-notification'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-radio-button-off active-icon-hover-notification'
                    }
                    return 'icon-v22-radio-button-off disabled-icon-notification'
                } else if (step.stepIconStatus === 'COMPLETED') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-internal-email-complete-active active-icon-hover-notification'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-internal-email-complete-active active-icon-hover-notification'
                    }
                    return 'icon-internal-email-complete active-icon-notification'
                } else if (step.stepIconStatus === 'DEFAULT') {
                    if (activeStep.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-radio-button-off active-icon-hover-notification'
                    }
                    if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                        return 'icon-v22-radio-button-off active-icon-hover-notification'
                    }
                    return 'icon-v22-radio-button-off active-icon-notification'
                } else
                if (hoverIconsSteps.stepComponentWorkflowId === step.stepComponentWorkflowId) {
                    return 'icon-v22-radio-button-off active-icon-notification'
                }
                return 'icon-v22-radio-button-off icon-notification-inactive'
                break
            default: break
        }
    }
    onNextDisabled = () => {
        return true
    }
    onBackDisabled = () => {
        return true
    }
    onClickDefaultStepHeaderActive = (e, step) => {
        const { ticketWorkflowStepDTOS } = this.props
        if (ticketWorkflowStepDTOS && ticketWorkflowStepDTOS.length > 0) {
            if ((ticketWorkflowStepDTOS[0].stepId && step.stepId && ticketWorkflowStepDTOS[0].stepId === step.stepId) || (ticketWorkflowStepDTOS[0].stepComponentWorkflowId === step.stepComponentWorkflowId)) {
                this.props.onDisplayHeaderStep(true, step)
                this.setState({
                    activeStepHeader: step.stepComponentName,
                    stepComponentWorkflowId: step.stepComponentWorkflowId,
                    activeStep: step,
                }, () => {
                    this.props.onEditModalTickets(this.props.selectHeaderTickets)
                    this.props.onGetActiveStep(step)
                })
            }
        }
    }


    showCompleteTicketModal= (e) => {
        customConfirm({
            title: <span className="icon-v22-radio-button-tick"></span>,
            //content: `Complete workflow?`,
            content: `End ticket?`,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-send-email-step-workflow modal-internal-email-notification',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.callApiMarkTicketWorkflowAsCompleted()
            }
        })
    }


    callApiMarkTicketWorkflowAsCompleted = () => {
        const { selectHeaderTickets} = this.props
            const MarkTicketAsCompletedDTO = {
                ticketId: selectHeaderTickets.ticketId,
            }
            apiMarkTicketWorkflowAsCompleted(MarkTicketAsCompletedDTO).then(response => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
                // getTicketWorkflowAndHeaderDetails(selectHeaderTickets.ticketId).then(response => {
                // })
            })

    }

    render() {
        const { hoverForm, hoverInternalEmailNot, hoverEmail, hoverDefinedWork, hoverConnector, hoverFormSimple, hoverDisclosure, hoverCompleted, disabledSaveBack,
            activeStep, activeStepHeader, statusBackNext } = this.state
        const { selectHeaderTickets, formTicketWorkflow, ticketWorkflowStepDTOS, nextLast, backFirst, indexStartHeaderDetails, stepHeaderActive, stepTicketActive,
            ticketCompleted, activeOpenEditTickets, checkMandatoryForm, checkMandatoryFormShort, checkMandatoryFormLong, checkMandatoryFormAddress,
            checkMandatoryFormEmail, checkMandatoryFormPhone, checkMandatoryFormRadio, checkMandatoryFormDropdown, checkMandatoryFormMultiple,
            checkMandatoryFormSignature, indexTicketHeader } = this.props
        return (

            <div className={ this.props.visibleSubheaderSteps ? 'header-workflow-step-page header-workflow-step-page-visible' : "header-workflow-step-page" }>
              <div className="header-workflow-step-components">
                  <span className="title-workflow-step">{ selectHeaderTickets && selectHeaderTickets.ticketWorkflowName ? selectHeaderTickets.ticketWorkflowName : 'SAR former employee process:' }</span>
                  { ticketWorkflowStepDTOS && ticketWorkflowStepDTOS.length > 0 && ticketWorkflowStepDTOS.map((step, index) => {
                      return (
                          <span className={this.onGenerateClassNameStepsHeader(step)}
                                onClick={((step.stepCompleted) || (ticketWorkflowStepDTOS[0].stepComponentWorkflowId === activeStep.stepComponentWorkflowId)) ?
                                    (e) => this.onCLickStepHeaderActive(e, step, indexStartHeaderDetails) :
                                    (e) => this.onClickDefaultStepHeaderActive(e, step, indexStartHeaderDetails)}
                                onMouseEnter={(e) => this.onMouseEnter(e, step)}
                                onMouseLeave={() => this.onMouseLeave()}
                                key={index}>
                          </span>
                      )
                  })

                  }
                  {/*<span className={ticketCompleted ? 'icon-v22-completed-active icon-v22-completed-active-completed' : selectHeaderTickets && selectHeaderTickets.workflowCompletedStatusIcon === 'disabled' ? 'icon-v22-completed-active disabled-icon' :*/}
                  {/*    hoverCompleted ? 'icon-v22-completed-active icon-v22-completed-active-completed': "icon-v22-completed-active inactive-icon" }*/}
                  {/*      onClick={()=>this.showCompleteTicketModal()}*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'completed')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}



                  {/*<span className={selectHeaderTickets && selectHeaderTickets.formPanelStatusIcon === 'disabled' ? 'icon-v22-form-done disabled-icon' :*/}
                  {/*    hoverForm ? 'icon-v22-form-done-active active-icon' : "icon-v22-form-done inactive-icon" }*/}
                  {/*      onClick={(e) => this.onCLickStepHeaderActive(e, 'email-employee')}*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'form')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}
                  {/*<span className={selectHeaderTickets && selectHeaderTickets.internalEmailNotificationPanelStatusIcon === 'disabled' ? 'icon-v22-radio-button-off disabled-icon' :*/}
                  {/*    hoverInternalEmailNot ? 'icon-v22-radio-button-off' : "icon-v22-radio-button-off" }*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'notification')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}
                  {/*<span className={selectHeaderTickets && selectHeaderTickets.emailPanelStatusIcon === 'disabled' ? 'icon-v22-email-1 disabled-icon' :*/}
                  {/*    hoverEmail ? 'icon-v22-email-active active-icon' : "icon-v22-email-1 inactive-icon" }*/}
                  {/*      onClick={(e) => this.onCLickStepHeaderActive(e, 'acknowledged')}*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'email')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}
                  {/*<span className={hoverDefinedWork ? 'icon-v22-radio-button-off' : "icon-v22-radio-button-off" }*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'define')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}
                  {/*<span className={selectHeaderTickets && selectHeaderTickets.dataCollectorPanelStatusIcon === 'disabled' ? 'icon-v22-connector disabled-icon' :*/}
                  {/*    hoverConnector ? 'icon-v22-connector-active active-icon' : "icon-v22-connector inactive-icon" }*/}
                  {/*      onClick={(e) => this.onCLickStepHeaderActive(e, 'data-collection')}*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'connector')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}
                  {/*<span className={selectHeaderTickets && selectHeaderTickets.formPanelStatusIcon === 'disabled' ? "icon-v22-form disabled-icon" :*/}
                  {/*    hoverFormSimple ? 'icon-v22-form-active active-icon' : "icon-v22-form inactive-icon" }*/}
                  {/*      onClick={(e) => this.onCLickStepHeaderActive(e, 'additional-information')}*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'formSimple')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}
                  {/*<span className={*/}
                  {/*    //selectHeaderTickets && selectHeaderTickets.disclosurePanelStatusIcon === 'disabled' ? 'icon-v22-disclosure disabled-icon' :*/}
                  {/*    hoverDisclosure ? 'icon-v22-disclosure-active active-icon' : "icon-v22-disclosure inactive-icon" }*/}
                  {/*      onClick={(e) => this.onCLickStepHeaderActive(e, 'disclosure')}*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'disclosure')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}
                  {/*<span className={selectHeaderTickets && selectHeaderTickets.workflowCompletedStatusIcon === 'disabled' ? 'icon-v22-completed-active disabled-icon' :*/}
                  {/*    hoverCompleted ? 'icon-v22-completed-active active-icon' : "icon-v22-completed-active inactive-icon" }*/}
                  {/*      onMouseEnter={(e) => this.onMouseEnter(e, 'completed')}*/}
                  {/*      onMouseLeave={() => this.onMouseLeave()}></span>*/}
              </div>
                <div className="header-pagination-components">
                    { indexStartHeaderDetails !== null && indexStartHeaderDetails !== 0 ?
                      <span className={((checkMandatoryForm === true || checkMandatoryFormShort === true || checkMandatoryFormLong === true
                          || checkMandatoryFormAddress === true || checkMandatoryFormEmail === true || checkMandatoryFormPhone === true
                      || checkMandatoryFormRadio === true || checkMandatoryFormDropdown === true || checkMandatoryFormMultiple === true
                      || checkMandatoryFormSignature === true) && stepHeaderActive.stepComponentName === 'Form') ?
                          "content-back content-back-disabled"
                          : activeOpenEditTickets ? "content-back content-back-disabled" :
                          //backFirst === true ? "content-back content-back-disabled" :
                              "content-back"}
                          onClick={((checkMandatoryForm === true || checkMandatoryFormShort === true || checkMandatoryFormLong === true
                              || checkMandatoryFormAddress === true || checkMandatoryFormEmail === true || checkMandatoryFormPhone === true
                              || checkMandatoryFormRadio === true || checkMandatoryFormDropdown === true || checkMandatoryFormMultiple === true
                              || checkMandatoryFormSignature === true) && stepHeaderActive.stepComponentName === 'Form') ?
                              () => this.onBackDisabled()
                              : activeOpenEditTickets ? () => this.onBackDisabled()
                              //: backFirst === true ? () => this.onBackDisabled()
                                  :
                                  () => this.onBackStepHeaderWorkflow()}>
                        <span className="icon-v22-back-1 moving-icon"></span>
                        <span className="moving">Back</span>
                         </span>
                        :
                        <span className='content-back'>
                            <span className="moving"></span>
                        </span>
                    }
                    <span className={((checkMandatoryForm === true || checkMandatoryFormShort === true || checkMandatoryFormLong === true
                    || checkMandatoryFormAddress === true || checkMandatoryFormEmail === true || checkMandatoryFormPhone === true
                        || checkMandatoryFormRadio === true || checkMandatoryFormDropdown === true || checkMandatoryFormMultiple === true
                        || checkMandatoryFormSignature === true) && stepHeaderActive.stepComponentName === 'Form') ? 'content-forth content-forth-disabled' :
                        activeOpenEditTickets ? 'content-forth content-forth-disabled' : ( stepTicketActive && stepTicketActive.stepCompleted !== true && stepHeaderActive.stepReadyToComplete !== true && stepTicketActive.stepComponentName !== 'Form') ? 'content-forth content-forth-disabled' : "content-forth" }
                          onClick={ ((checkMandatoryForm === true || checkMandatoryFormShort === true || checkMandatoryFormLong === true
                              || checkMandatoryFormAddress === true || checkMandatoryFormEmail === true || checkMandatoryFormPhone === true
                              || checkMandatoryFormRadio === true || checkMandatoryFormDropdown === true || checkMandatoryFormMultiple === true
                              || checkMandatoryFormSignature === true) && stepHeaderActive.stepComponentName === 'Form') ? (e) => this.onNextDisabled(e) :
                              activeOpenEditTickets ? (e) => this.onNextDisabled(e) : stepTicketActive && ( stepTicketActive.stepCompleted === true || (stepHeaderActive.stepReadyToComplete !== null && stepHeaderActive.stepReadyToComplete === true) ) ? (e) => this.onNextStepHeaderWorkflow(e) :
                              stepTicketActive && stepTicketActive.stepComponentName === 'Form' ? (e) => this.onNextStepHeaderWorkflow(e) : (e) => this.onNextDisabled(e)}
                          // onClick={nextLast === true ? (e) => this.onNextDisabled(e) :
                          //     disabledSaveBack === true ? (e) => this.onNextDisabled(e) : (e) => this.onNextStepHeaderWorkflow(e)}
                    >
                        {
                             stepHeaderActive !== null && ( (stepHeaderActive.stepCompleted !== null && stepHeaderActive.stepCompleted === true)  || (stepHeaderActive.stepReadyToComplete !== null && stepHeaderActive.stepReadyToComplete === true)  ) ?
                                <span className='moving'>{indexTicketHeader + 1 === ticketWorkflowStepDTOS.length ? 'End' : 'Next' }</span> :
                                <span className='moving'>Complete</span>
                        }
                        {
                            stepHeaderActive !== null &&  ( (stepHeaderActive.stepCompleted !== null && stepHeaderActive.stepCompleted === true)  || (stepHeaderActive.stepReadyToComplete !== null && stepHeaderActive.stepReadyToComplete === true)  ) ?
                          <span className={indexTicketHeader + 1 === ticketWorkflowStepDTOS.length ? "" : 'icon-v22-forth-1 moving-icon' }></span> :
                          <span className="icon-v22-forth-1 moving-icon"></span>
                        }
                    </span>
                </div>
            </div>
        )
    }
}



HeaderWorkflowStep.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    visibleSubheaderSteps: state.smartBox.visibleSubheaderSteps,
})
export default compose(
    connect(mapStateToProps),
)(HeaderWorkflowStep)