import React from "react";
import './TemplateWorkflowAdmin.scss';
import {connect} from "react-redux";
import {Button, Checkbox, Empty, Input, List, Modal, Select} from "antd";
import EmailWorkflowBuilderAdmin from "./componentsWorkflowBuilderAdmin/EmailWorkflowBuilderAdmin";
import FormWorkflowBuilderAdmin from "./componentsWorkflowBuilderAdmin/FormWorkflowBuilderAdmin";
import CollectorWorkflowBuilderAdmin from "./componentsWorkflowBuilderAdmin/CollectorWorkflowBuilderAdmin";
import DisclosureWorkflowBuilderAdmin from "./componentsWorkflowBuilderAdmin/DisclosureWorkflowBuilderAdmin";
import InternalWorkflowBuilderAdmin from "./componentsWorkflowBuilderAdmin/InternalWorkflowBuilderAdmin";
import WebFormEmailWorkflowBuilderAdmin from "./componentsWorkflowBuilderAdmin/WebFormEmailWorkflowBuilderAdmin";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {customConfirm} from "../../../utils/AssetRegister/CustomConfirm";
import {
    apiEditDefaultWorkflowTemplateWithComponents,
    apiGetDefaultFormListWorkflow,
    apiGetFormBuilderListWorkflow,
    apiGetWorkflowSelected,
    apiWorkflowBuilderCreate,
    apiWorkflowBuilderCreateNewDefaultWorkflowTemplate,
    apiWorkflowBuilderCreateNewWorkflowTemplate,
    apiWorkflowBuilderEdit
} from "../../../api/SmartBox";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import {setGetVariablesEmailsWorkflow} from "../../../actions/smartBoxActions";
const CheckboxGroup = Checkbox.Group
const { Option } = Select
class WorkflowBuilderUiAdmin extends React.Component {
    state = {
        onHoverBack:false,
        plainOptions: ['Deduplicate', 'Near deduplicate', 'De-thread', 'Delete empty folders'],
        checkedList: [],
        listWorkflowSteps: [
            {id: 'Form template',key: 0, name: 'Form'},
            {id: 'Email template',key: 1, name: 'Email'},
            {id: 'Web Form Email Template',key: 2, name: 'Web Form'},
            {id: 'Collector',key: 3, name: 'Data Collector'},
            {id: 'Disclosure',key: 4, name: 'Disclosure'},
            {id: 'Internal email notification',key: 5, name: 'Internal Email Notification'},
        ],
        listFormComponents: [],
        keyComp: 0,
        actionProvidedDraggable: {},
        idForm: null,
        listOptionConfiguration: [{configurationId: 'defaultConfiguration', name: 'Default', key: 0}],
        valueConfigurationSelect: 'Default',
        formVariables: [],
        listFormsWorkflow: [],
        listWebFormsWorkflow: [],
        idFormWebformEmailSend: null,
        statusWorkflowTemplate: '',
        workflowId: null
    }
    componentDidMount() {
        if (this.props.selectWorkflowTemplate.workflowId) {
            this.onGetWorkflowSelected()
            this.setState({
                statusWorkflowTemplate: 'edit'
            })
        } else {
            this.setState({
                statusWorkflowTemplate: 'create'
            })
        }
        this.onGetFormBuilderListWorkflow()
        this.props.setGetVariablesEmailsWorkflow([])
    }
    onGetFormBuilderListWorkflow = () => {
        apiGetDefaultFormListWorkflow().then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((elem) => {
                elem.disabledForm = false
                elem.disabledWebForm = false
            })
            this.setState({
                listFormsWorkflow: response.data,
            })
        })
    }
    onGetWorkflowSelected = () => {
        const { listFormComponents, workflowId } = this.state
        apiGetWorkflowSelected(this.props.selectWorkflowTemplate.workflowId ? this.props.selectWorkflowTemplate.workflowId : workflowId).then((response) => {
            const workflowComponentResponseDTO = []
            response.data && response.data.workflowTemplateComponentResponseDTOList && response.data.workflowTemplateComponentResponseDTOList.length > 0 && response.data.workflowTemplateComponentResponseDTOList.forEach((list, index) => {
                // if (list.componentType === 'Form template' || list.componentType === 'Email template' || list.componentType === 'Collector' || list.componentType === 'Internal email notification'
                //     || list.componentType === 'Disclosure') {
                workflowComponentResponseDTO.push({
                    name: list.componentType,
                    componentType: list.componentType,
                    id: list.componentType,
                    componentName: list.componentName,
                    idComponent: list.idComponent,
                    idComponent22: list.idComponent,
                    key: index,
                    workflowTemplateVariableList: list.workflowTemplateVariableList,
                    userIds: list.userIds,
                    internalSelectUserIds: list.userIds,
                    resultUrl: list.resultUrl,
                    emailDisclosure: list.emailDisclosure,
                    idFormWebformEmail: list.idFormComponent,
                    idFormWebformEmailName: list.componentNameForm,
                    idForm: list.idForm,
                    idFormComponent: list.idFormComponent,
                    componentNameForm: list.componentNameForm,
                    id2: list.id,
                    position: list.position,
                    sendWebformComplitionNotification: list.sendWebformComplitionNotification,
                    userIdNotification: list.userIdNotification,
                    emailTemplateIdNotification: list.emailTemplateIdNotification,
                })
                //}
            })
            const checkBoxInput = []
            if (response.data.boxInput) {
                if (response.data.boxInput.duplication) {
                    checkBoxInput.push('Duplication')
                }
                if (response.data.boxInput.emailThreads) {
                    checkBoxInput.push('Email threads')
                }
                if (response.data.boxInput.emptyFolders) {
                    checkBoxInput.push('Empty folders')
                }
                if (response.data.boxInput.nearDuplicates) {
                    checkBoxInput.push('Near duplicates')
                }
            }
            workflowComponentResponseDTO && workflowComponentResponseDTO.length > 0 && workflowComponentResponseDTO.forEach((elem) => {

            })
            this.setState({
                listFormComponents: workflowComponentResponseDTO,
                checkedList : checkBoxInput
            })

        })
    }
    onMouseEnterActions = (e, status) => {
        switch (status) {
            case 'back':
                this.setState({
                    onHoverBack: true
                })
                break
        }
    }
    onMouseLeaveActions = () => {
        this.setState({
            onHoverBack: false
        })
    }
    onChangeCheckbox = (checkedList) => {
        this.setState({
            checkedList
        })
    }
    onClickListElement = (e, item) => {
        const { listFormComponents, keyComp } = this.state
        const sectionNames = []
        listFormComponents && listFormComponents.length > 0 && listFormComponents.forEach((list) => {
            sectionNames.push(list.id)
        })
        switch(item.id) {
            case 'Form template':
                //if (!sectionNames.includes('Form template')) {
                listFormComponents.push({id: 'Form template', name: 'Form template'})
                //}
                break
            case 'Email template':
                //if (!sectionNames.includes('Email template')) {
                listFormComponents.push({id: 'Email template', name: 'Email template'})
                //}
                break
            case 'Web Form Email Template':
                //if (!sectionNames.includes('Email template')) {
                listFormComponents.push({id: 'Web Form Email Template', name: 'Web Form Email Template'})
                //}
                break
            case 'Collector':
                //if (!sectionNames.includes('Collector')) {
                listFormComponents.push({id: 'Collector', name: 'Collector'})
                //}
                break
            case 'Disclosure':
                if (!sectionNames.includes('Disclosure')) {
                    listFormComponents.push({id: 'Disclosure', name: 'Disclosure'})
                }
                break
            case 'Internal email notification':
                //if (!sectionNames.includes('Internal email notification')) {
                listFormComponents.push({id: 'Internal email notification', name: 'Internal email notification'})
                //}
                break
            default: break
        }
        listFormComponents && listFormComponents.length > 0 && listFormComponents.forEach((list, index) => {
            list.key = index
        })
        this.setState({
            listFormComponents,
            keyComp: keyComp + 1,
        })
    }
    onGenerateComponentsBuilder = (component, onStateProvidedDraggable, showConfirmDeleteSection, onChangeBuilder, onSelectIdForm, onSelectIdEmailTemplate, formVariables, formVariables2,
                                   onSelectIdBusiness, onSelectIdInternalEmail, onSelectIdDisclosureEmail, listFormComponents, listFormsWorkflow, onSelectIdFormGenerateListForms,
                                   listWebFormsWorkflow, onSelectIdWebFormGenerateListForms, idFormWebformEmailSend) => {
        switch(component.name) {
            case 'Form template':
                return <FormWorkflowBuilderAdmin component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                            onChangeBuilder={ onChangeBuilder } onSelectIdForm={onSelectIdForm} formVariables={formVariables} formVariables2={formVariables2}
                                            listFormComponents={ listFormComponents } listFormsWorkflow = {listFormsWorkflow} onSelectIdFormGenerateListForms={onSelectIdFormGenerateListForms}
                />
                break
            case 'Email template':
                return <EmailWorkflowBuilderAdmin component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                             onChangeBuilder={ onChangeBuilder } onSelectIdEmailTemplate={onSelectIdEmailTemplate} formVariables={formVariables} formVariables2={formVariables2}
                />
                break
            case 'Web Form Email Template':
                return <WebFormEmailWorkflowBuilderAdmin component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                                    onChangeBuilder={ onChangeBuilder } onSelectIdEmailTemplate={onSelectIdEmailTemplate} formVariables={formVariables} formVariables2={formVariables2}
                                                    listWebFormsWorkflow={ listWebFormsWorkflow } onSelectIdWebFormGenerateListForms={ onSelectIdWebFormGenerateListForms }
                                                    listFormComponents={ listFormComponents } listFormsWorkflow = {listFormsWorkflow} onSelectIdFormGenerateListForms={onSelectIdFormGenerateListForms}
                                                    idFormWebformEmailSend={ idFormWebformEmailSend }
                />
                break
            case 'Collector':
                return <CollectorWorkflowBuilderAdmin component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                                 onChangeBuilder={ onChangeBuilder } formVariables={formVariables} onSelectIdBusiness={onSelectIdBusiness}
                />
                break
            case 'Disclosure':
                return <DisclosureWorkflowBuilderAdmin component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                                  onChangeBuilder={ onChangeBuilder } formVariables={formVariables} onSelectIdDisclosureEmail={ onSelectIdDisclosureEmail }
                />
                break
            case 'Internal email notification':
                return <InternalWorkflowBuilderAdmin component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                                onChangeBuilder={ onChangeBuilder } formVariables={formVariables} onSelectIdInternalEmail={ this.onSelectIdInternalEmail }
                />
                break
            default:
                break
        }
        this.setState({
            valueSection: 'Add section'
        })
    }

    handleDropComponent = (droppedItem) => {
        if (!droppedItem.destination) return
        const updatedList = [...this.state.listFormComponents];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // updatedList && updatedList.length > 0 && updatedList.forEach((item, index) => {
        //     item.key = index
        // })
        this.setState({
            listFormComponents: updatedList
        })
    }
    onProvidedDraggable = (provided) => {
        return {...provided.draggableProps}
    }
    onStateProvidedDraggable = (state) => {
        this.setState({
            actionProvidedDraggable: state
        })
    }
    onLeaveProvidedDraggable = (state) => {
        this.setState({
            actionProvidedDraggable: {}
        })
    }
    onSelectIdForm = (id) => {
        this.setState({
            idForm: id
        })
    }

    onSelectIdFormGenerateListForms = (listFormComponents, listForms) => {
        const selectFormList = []
        const listWebFormsWorkflowSelected = []
        listFormComponents && listFormComponents.length > 0 && listFormComponents.forEach((list2) => {
            if (list2.id === 'Form template') {
                selectFormList.push(list2.selectForm)
            }
        })
        listForms && listForms.length > 0 && listForms.forEach((list) => {
            if (selectFormList.includes(list.formId)) {
                list.disabledForm = true
                listWebFormsWorkflowSelected.push(list)
            } else {
                list.disabledForm = false
            }
        })
        this.setState({
            listFormsWorkflow: listForms,
            listWebFormsWorkflow: listWebFormsWorkflowSelected
        })
    }
    onSelectIdWebFormGenerateListForms = (listFormComponents, listWebFormsWorkflow, idFormWebformEmail) => {
        const selectFormList = []
        const listWebFormsWorkflowSelected = []
        listFormComponents && listFormComponents.length > 0 && listFormComponents.forEach((list2) => {
            if (list2.id === 'Web Form Email Template') {
                selectFormList.push(list2.selectForm)
            }
        })
        listWebFormsWorkflow && listWebFormsWorkflow.length > 0 && listWebFormsWorkflow.forEach((list) => {
            if (selectFormList.includes(list.formId)) {
                list.disabledWebForm = true
                listWebFormsWorkflowSelected.push(list)
            } else {
                list.disabledWebForm = false
            }
        })
        this.setState({
            listWebFormsWorkflow: listWebFormsWorkflow,
            idFormWebformEmailSend: idFormWebformEmail
        })
    }
    showConfirmDeleteSection = (e, component) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete section?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className:'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onActionDeleteSection(e, component)
            }
        })
    }
    onActionDeleteSection = (e, component) => {
        const { listFormComponents } = this.state
        if (listFormComponents && listFormComponents.length > 0) {
            let filtersForm = []
            filtersForm = listFormComponents.filter((elem) => elem.key !== component.key)
            // filtersForm && filtersForm.length > 0 && filtersForm.forEach((list, index) => {
            //     list.key = index
            // })
            this.setState({
                listFormComponents: filtersForm
            })
        }
    }

    onCLickSaveBuilder = () => {
        const { listFormComponents, valueWorkflowName, valuesAddSection, idForm, idEmailTemplate, idBusinessProcess, valueConfigurationSelect,
            checkedList, idCollectorBusiness, idInternalEmail, idDisclosureEmail, statusWorkflowTemplate, workflowId } = this.state
        const { clientId, selectWorkflowTemplate } = this.props
        const sectionWorkflow = []
        const valuesSections = []
        let workflowComponents = []
        const workflowTemplateVariableList2 = []
        const workflowTemplateVariableListDisclosure = []
        let workflowTemplateVariableList3 = []
        const idForm3 = ''
        const internalUsers = []
        listFormComponents && listFormComponents.length > 0 && listFormComponents.forEach((list, index) => {
            valuesSections.push(list.name)
            list.variablesForm && list.variablesForm.variables && list.variablesForm.variables.length > 0 && list.variablesForm.variables.forEach((item) => {
                workflowTemplateVariableList2.push({
                    email_template_variable_id: item.id,
                    form_variable: item.formVariable,
                    form_id: idForm ? idForm : list.idComponentForm2 && list.idComponentForm2.length > 0 ? list.idComponentForm2 : list.selectForm ? list.selectForm : null,
                })
            })
            list.variablesFormDisclosure && list.variablesFormDisclosure.variables && list.variablesFormDisclosure.variables.length > 0 && list.variablesFormDisclosure.variables.forEach((item) => {
                workflowTemplateVariableListDisclosure.push({
                    email_template_variable_id: item.id,
                    form_variable: item.formVariable,
                    form_id: list.idComponentForm2 ? list.idComponentForm2 : list.selectForm ? list.selectForm : idForm,
                })
            })
            list.internalSelect && list.internalSelect.length > 0 && list.internalSelect.forEach((userId) => {
                if (userId.valueInternalSelect ) {
                    if( internalUsers.indexOf(userId.valueInternalSelect) === -1) {
                        internalUsers.push(userId.valueInternalSelect)
                    }
                }
            })
            if (list.workflowTemplateVariableList && list.workflowTemplateVariableList.length > 0) {
                workflowTemplateVariableList3 = list.workflowTemplateVariableList
            }
            workflowComponents.push({
                idComponent: list.idComponent,
                id: list.id2 ? list.id2 : null,
                position: index,
                componentName: list.componentName,
                componentType: list.name,
                emailDisclosure: list.emailDisclosure ? list.emailDisclosure : null,
                resultUrl: list.resultUrl ? list.resultUrl : null,
                idFormWebformEmail: list.idFormWebformEmail ? list.idFormWebformEmail : null,
                idFormWebformEmailName: list.idFormWebformEmailName ? list.idFormWebformEmailName : null,
                idForm: list.idForm ? list.idForm : null,
                //userIds: internalUsers,
                userIds: list.internalSelectUserIds,
                sendWebformComplitionNotification: list.sendWebformComplitionNotification,
                userIdNotification: list.userIdNotification,
                emailTemplateIdNotification: list.emailTemplateIdNotification,
                workflowTemplateVariableList: list.name === 'Disclosure' ? workflowTemplateVariableListDisclosure : workflowTemplateVariableList2 && workflowTemplateVariableList2.length > 0 ? workflowTemplateVariableList2 :
                    list.name === 'Email template' ? workflowTemplateVariableList3 : [],
            })
        })
        let newComponent = {}
        let workflowComponentsSlice = []
        //workflowComponentsSlice = workflowComponents
        let index2 = 0
        workflowComponents && workflowComponents.length > 0 && workflowComponents.forEach((elem, index) => {
            elem.position = index2
            index2 = index2 + 1
            workflowComponentsSlice.push(elem)
            if (elem.componentType === 'Web Form Email Template') {
                newComponent = {
                    componentName: elem.idFormWebformEmailName,
                    componentType: 'Form template',
                    id: elem.idForm,
                    idComponent: elem.idFormWebformEmail,
                    workflowTemplateVariableList: [],
                    resultUrl: null,
                    userIds: elem.userIds,
                    position: index2,
                }
                index2 = index2 + 1
                workflowComponentsSlice.push(newComponent)
                //  workflowComponents = workflowComponents.sort((a, b) => a.position - b.position)
            }
        })
        //workflowComponents.splice(index2, 0, newComponent)
        workflowComponents = workflowComponentsSlice
        const createNewWorkflowRequestDTO  = {
            //clientId: clientId,
            idWorkflow: selectWorkflowTemplate.workflowId ? selectWorkflowTemplate.workflowId : workflowId,
            workflowComponents: workflowComponents,
            boxInput: {
                configurationType: valueConfigurationSelect,
                duplication: checkedList.includes('Deduplicate') ? true : false,
                nearDuplicates: checkedList.includes('Near deduplicate') ? true : false,
                emailThreads: checkedList.includes('De-thread') ? true : false,
                emptyFolders: checkedList.includes('Delete empty folders') ? true : false,
            }
        }
        const createFirstNewWorkflowRequestDTO  = {
            workflowName: selectWorkflowTemplate.workflowName,
            clientId: clientId,
            workflowComponents: workflowComponents,
            boxInput: {
                configurationType: valueConfigurationSelect,
                duplication: checkedList.includes('Deduplicate') ? true : false,
                nearDuplicates: checkedList.includes('Near deduplicate') ? true : false,
                emailThreads: checkedList.includes('De-thread') ? true : false,
                emptyFolders: checkedList.includes('Delete empty folders') ? true : false,
            }
        }
        if (statusWorkflowTemplate  === 'edit') {
            apiEditDefaultWorkflowTemplateWithComponents(createNewWorkflowRequestDTO).then((response) => {
                if (response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
            })
        } else if (statusWorkflowTemplate  === 'create') {
            apiWorkflowBuilderCreateNewDefaultWorkflowTemplate(createFirstNewWorkflowRequestDTO).then((response) => {
                if (response.data.messageTextResponseDTO.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.messageTextResponseDTO.message, 'Success')
                    this.setState({
                        statusWorkflowTemplate: 'edit',
                        workflowId: response.data.workflowTemplateId,
                    })
                } else if(response.data.messageTextResponseDTO.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.messageTextResponseDTO.message, 'Info')
                }
            })
        }
    }
    onChangeBuilder = (builder, component) => {
        const { listFormComponents } = this.state
        listFormComponents && listFormComponents.length > 0 &&  listFormComponents.forEach((list) => {
            if (list.key === component.key) {
                list.selectEmail = builder.selectEmail
                list.selectForm = builder.selectForm
                list.componentName = builder.componentName
                list.idComponent = builder.idComponent
                list.variablesForm = builder.variablesForm
                list.emailSelectInternal = builder.emailSelectInternal
                list.internalSelect = builder.internalSelect
                list.workflowTemplateVariableList = builder.workflowTemplateVariableList
                list.emailDisclosure = builder.emailDisclosure
                list.resultUrl = builder.resultUrl
                list.variablesFormDisclosure = builder.variablesFormDisclosure
                list.internalSelectUserIds = builder.internalSelectUserIds
                list.idFormWebformEmail = builder.idFormWebformEmail
                list.idFormWebformEmailName = builder.idFormWebformEmailName,
                    list.sendWebformComplitionNotification = builder.sendWebformComplitionNotification
                    list.userIdNotification = builder.userIdNotification
                    list.emailTemplateIdNotification = builder.emailTemplateIdNotification
                //list.idComponentForm2 = builder.idComponentForm
            }
            list.formVariables = builder.formVariables && builder.formVariables.length > 0 ? builder.formVariables : component.formVariables
            //if (list.name === 'Form template') {
            list.idComponentForm = builder.idComponent
            list.idComponentForm2 = builder.idComponentForm
            //}
        })
        this.setState({
            listFormComponents,
            formVariables: builder.formVariables
        }, () => {
            this.setState({
                listFormComponents,
                formVariables: builder.formVariables
            })
        })
    }
    onSelectIdEmailTemplate = (id) => {
        this.setState({
            idEmailTemplate: id
        })
    }
    onSelectIdBusiness = (id) => {
        this.setState({
            idCollectorBusiness: id
        })
    }
    onSelectIdInternalEmail = (id) => {
        this.setState({
            idInternalEmail: id
        })
    }
    onSelectIdDisclosureEmail = (id) => {
        this.setState({
            idDisclosureEmail: id
        })
    }
    onCLickCancelBuilder = () => {
        // this.setState({
        //     valueWorkflowName: '',
        //     valueSection: '',
        //     listFormComponents: [],
        //     valueSelectWorkflow: ''
        // })
        this.onGetWorkflowSelected()
    }
    onClickBackWorkflow = () => {
        this.props.onBackWorkflowBuilderUi()
    }
    onSelectConfiguration = (e) => {
        this.setState({
            valueConfigurationSelect: e
        })
    }
    render() {
        const { onHoverBack, plainOptions, checkedList, listWorkflowSteps, listFormComponents, listOptionConfiguration, valueConfigurationSelect, formVariables,
            listFormsWorkflow, listWebFormsWorkflow, idFormWebformEmailSend, statusWorkflowTemplate } = this.state
        const { selectWorkflowTemplate } = this.props
        return (
            <div className="workflow-builder-ai-page">
                <div className="workflow-builder-ai-header">
                    <div className="title-builder-ai-header">
                        <span className={ onHoverBack ? 'icon-v21-back-active' : "icon-v17-back-1" }
                              onClick={ () => this.onClickBackWorkflow()}
                              onMouseEnter={(e) => this.onMouseEnterActions(e, 'back')}
                              onMouseLeave={() => this.onMouseLeaveActions()}></span>
                        <span className="title-ui">{ selectWorkflowTemplate.workflowName ? selectWorkflowTemplate.workflowName : 'SAR Workflow' }</span>
                    </div>
                    <div className="action-header-builder-ai">
                        <Button className="button-cancel-template"
                                disabled={statusWorkflowTemplate === 'create'}
                                onClick={() => this.onCLickCancelBuilder()}>Cancel</Button>
                        <Button className="button-save-template"
                                disabled={listFormComponents.length === 0 }
                               // disabled={selectWorkflowTemplate.defaultTemplate === true}
                                onClick={(e) => this.onCLickSaveBuilder(e)}
                        >Save</Button>

                    </div>
                </div>

                <div className="workflow-builder-ai-content">
                    <div className="workflow-builder-ai-left">
                        <div className="form-workflow-content">
                            <List
                                header={<div>Workflow steps:</div>}
                                bordered
                                dataSource={listWorkflowSteps}
                                renderItem={item => (
                                    <List.Item onClick={(e) => this.onClickListElement(e, item)}>
                                       <span className="content-steps-workflow">
                                           { item.name === 'Internal Email Notification' ?
                                               <span className="content-internal-email">
                                                   <span className="header-internal" onClick={e=>e.stopPropagation()}>Internal steps:</span>
                                                   <span>{item.name}</span>
                                               </span>
                                               :
                                               <span>{item.name}</span>
                                           }
                                       </span>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                    <div className="workflow-builder-ai-right">
                        <div className="template-workflow-box">
                            <span className="workflow-box">Box</span>
                            <span className='workflow-box-all-action'>
                           <span className="action-configuration">
                               <span className="workflow-configuration">Configuration:</span>
                               <Select className="select-workflow" placeholder="Default"
                                       dropdownClassName={'select-workflow-dropdown'}
                                       onChange={(e) => this.onSelectConfiguration(e)}
                                       value={valueConfigurationSelect}>
                                   { listOptionConfiguration && listOptionConfiguration.length > 0 && listOptionConfiguration.map((item) => {
                                       return (
                                           <Option value={item.name} key={item.name}>{item.name}</Option>
                                       )
                                   })

                                   }
                               </Select>
                           </span>
                           <span className="action-configuration-workflow">
                               <span className="automations">Automations: </span>
                               <CheckboxGroup
                                   options={plainOptions}
                                   value={checkedList}
                                   onChange={this.onChangeCheckbox}
                               />
                           </span>
                           </span>
                        </div>

                        <div className="page-builder-content">
                            <div className="form-builder-content">
                                <DragDropContext onDragEnd={this.handleDropComponent}>
                                    <Droppable droppableId="list-container">
                                        {(provided) => (
                                            <div
                                                className="list-container"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    listFormComponents && listFormComponents.length > 0 && listFormComponents.map((component, index) => {
                                                        return (
                                                            <Draggable key={component.key} draggableId={`${component.key}`} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        id={index}
                                                                        className="item-container"
                                                                        ref={provided.innerRef}
                                                                        {...provided.dragHandleProps}
                                                                        {...this.onProvidedDraggable(provided)}
                                                                    >
                                                                        {
                                                                            this.onGenerateComponentsBuilder(component, this.onStateProvidedDraggable, this.showConfirmDeleteSection,
                                                                                this.onChangeBuilder, this.onSelectIdForm, this.onSelectIdEmailTemplate, formVariables, component.formVariables,
                                                                                this.onSelectIdBusiness, this.onSelectIdInternalEmail, this.onSelectIdDisclosureEmail, listFormComponents,
                                                                                listFormsWorkflow, this.onSelectIdFormGenerateListForms, listWebFormsWorkflow, this.onSelectIdWebFormGenerateListForms,
                                                                                idFormWebformEmailSend )
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default connect(mapStateToProps, {
    setGetVariablesEmailsWorkflow
})(WorkflowBuilderUiAdmin)