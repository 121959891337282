import React, { Component } from 'react'
import ToggleAccordion from '../../Common/ToggleAccordion'
import Accordion from '../../Common/Accordion'
import './ArchiveList.scss'
import moment from 'moment-timezone'
import { Link, Navigate } from 'react-router-dom'
import Button from '../../Common/Button'
import { apiSarUnarchiveSteps, apiSarDeleteRequest } from '../../../api/Sar'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import swal from 'sweetalert2'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import {connect} from "react-redux";
import withRouter from '../../../private-routers/withRouter'

export class ArchiveList extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      showLearnMessage: true,
      accordion: {},
      redirectToDashboardSar: false,
      isSending: false,
      onHoverDownloadArchive: false,
      checkSelectedSarDeleted: ''
    }
    this.navigateToDpiaDashboard = this.navigateToDpiaDashboard.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  navigateToDpiaDashboard() {
    this.props.navigate('/sar/dashboard')
  }

  toggleAccordion(accordion, index) {
    this.setState({
      accordion: {
        ...accordion,
        [index]: !accordion[index]
      },
      checkSelectedSarDeleted: ''
    })
  }
  getStepState(stepState) {
    switch (stepState) {
      case 'REUPLOAD' :
        return '(Requested proof id)'
      case 'UPLOAD' :
        return '(Requested proof id)'
      default:
        return ''
    }
  }
  getName(step) {

    switch (step) {
      case 'INITIAL_REQUEST' :
        return 'Initial Request'
      case 'ACKNOWLEDGE' :
        return 'Acknowledge'
      case 'CHECK_ID' :
        return 'Check Id'
      case 'RESPOND' :
        return 'Respond'
      case 'COMPLETE' :
        return 'Completed'
      case 'SEARCH_FIND_ADD_DOCUMENTS' :
        return 'Search Find Add Documents'
      case 'REVIEW_AND_REDACT' :
        return 'Review And Redact'
      case 'SEND_SECURELY' :
        return 'Send Securely'
      default:
        return 'No status'
    }

  }

  getDaysLeft(time) {

    if (time && time[0] >= 7) {
      return (
        <span>{ `${ time[0] }d ${ time[1] }h`}</span>
      )
    } else if (time && time[0] < 0) {
      return (
        <span className="time-span-color">{ `${ time[0] }d ${ -time[1] }h`}</span>
      )
    } else if (time && time[0] >= 0 && time[0] < 7 && time[1] > 0) {
      return (
        <span className="time-span-color-yellow">{ `${ time[0] }d ${ time[1] }h`}</span>
      )
    } else if (time && time[0] === 0 && time[1] < 0) {
      return (
        <span className="time-span-color">{ `-${ time[0] }d ${ -time[1] }h`}</span>
      )
    }
  }

  unarchiveStepClicked(e, id) {
    e.preventDefault()
    swal.fire({
      text: 'This step will be unarchived so that your reports will still be accessible.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes, unarchive it!'
    }).then((result) => {
      if (result.value) {
        this.confirmedActionToUnarchive(id)
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  confirmedActionToUnarchive(id) {
    this.setState({
      isSending: true
    })

    apiSarUnarchiveSteps(id)
      .then((response) => {
        CustomNotificationManager.success('Unarchived successfully', 'Success')
        this.setState({
          isSending: false,
          redirectToDashboardSar: true
        })
        
      }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onMouseEnterOnPanel(e, obj, objId) {
    const { sars } = this.props
    const sarsIds = []
    sars && sars.length > 0 && sars.forEach((sar) => {
      sarsIds.push(sar.id)
    })
    if (sarsIds.includes(objId)) {
      this.setState({
        onHoverDownloadArchive: true,
        checkSelectedSarDeleted: objId
      })
    }
  }
  onMouseLeaveOnPanel() {
    this.setState({
      onHoverDownloadArchive: false,
      checkSelectedSarDeleted: ''
    })
  }
  handleDeleteRequestArchive = (e, objId, name) => {
    customConfirm({
      title: 'Do you want to delete request from archive?',
      content: `${ name }`,
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
      confirmAction: () => {
        this.onClickDeleteSarArchive(e, objId)
      }
    })
  }
  onClickDeleteSarArchive(e, objId, name) {
    const sarSelected = []
    sarSelected.push(objId)
    const deleteSarDTO = {
      sarIds: sarSelected
    }
    apiSarDeleteRequest(deleteSarDTO).then((response) => {
      this.props.fetchSars()
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  renderContentAccordion(obj) {
    const { checkSelectedSarDeleted } = this.state
    let details = 'Without details'
    if (typeof obj.details !== undefined) {
      if (obj.details !== null && obj.details.trim() !== '') {
        details = obj.details
      }
    }
    return (
      <React.Fragment>
        <div className="subject-list-info-archive">
          <div className="details-accordion-archive">
            <p>{ details }</p>
          </div>
          <div className="email-accordion-archive">
            <p>email: <span className="font-weight-bold">{ obj.email }</span></p>
          </div>
          <div className="archive-button-archive">
            <Button onClick={(e) => this.unarchiveStepClicked(e, obj.id)}>
              Unarchive
            </Button>
          </div>
          <div className="archive-button-delete">
            <span onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, obj, obj.id) }
              onMouseLeave={ () => this.onMouseLeaveOnPanel() }
              className={ checkSelectedSarDeleted === obj.id ? 'icon-v14-delete-active' : 'icon-v14-delete' }
              onClick={ (e) => this.handleDeleteRequestArchive(e, obj.id, obj.subject) }></span>
          </div>
        </div>
      </React.Fragment>
    )
  }
  onTimezoneConvert = (time) => {
    const localTimeZone = moment.tz.guess()
    const localDate = moment.utc(time).tz(localTimeZone)
    return  moment(localDate).format('DD/MM/YYYY HH:mm')
  }
  render() {
    const { accordion, redirectToDashboardSar } = this.state
    const { isLoading, sars } = this.props
    return (
      <div>
        { redirectToDashboardSar &&
        <Navigate to="/sar/dashboard" />
        }
        { isLoading &&
            <div className="dpiaDashboard-loading">

            </div>
        }
        {sars && sars.length > 0 ?
          (<div className="styleAccordion">
            {
              sars &&
              sars.length > 0 &&
              sars.map((sar) => {
                const index = sar.id
                return (
                  <div key={sar.id}>
                    <div className="unlockedDashboard-wrapper" key={sar.id}
                      onClick={this.toggleAccordion.bind(this, accordion, index)}>
                      <Accordion
                        opened={accordion[index]}
                        header={
                          <div className="unlockedDashboard-itemHeader">
                            <div className="SubjectList-infoContainer">
                              <div className="subjectsList-name">
                                  SUBJECT<br/>
                                <span
                                  className='subjectsList-span'>{`“${ sar.subject }”`}</span>
                              </div>
                              <div className="subjectsList-date">
                                CREATED AT<br/>
                                <span className='subjectsList-span'>{ this.onTimezoneConvert(sar.creationDate) ? this.onTimezoneConvert(sar.creationDate) : '' }</span>
                              </div>
                              <div className="subjectsList-step">
                                STEP<br/>
                                <span className='subjectsList-span'>{ this.getName(sar.step) } <span>{sar.step === 'CHECK_ID' ? `${ this.getStepState(sar.stepState) }` : ''}</span></span>
                              </div>
                              <div className="subjectsList-found">
                                  FOUND<br/>
                                <span
                                  className='subjectsList-span'>{`${ sar.found ? `${ sar.found } documents` : 'Waiting for search' }`}</span>
                              </div>
                              <div className="subjectsList-time">
                                DAYS LEFT<br/>
                                <span className="subjectsList-spanRedacting" >{ sar.timeLeft ? this.getDaysLeft(sar.timeLeft) : '0 days'}</span>
                              </div>
                              <div className="dpiaDashboard-continueButton">
                                {
                                  sar.step &&
                                    <Link to={`/sar/tracker/${ sar.id }`}>
                                      {
                                        <Button theme="view">
                                                View
                                        </Button>
                                      }
                                    </Link>
                                }
                              </div>
                            </div>
                            <ToggleAccordion
                              oppened={accordion[index]}
                              index={index}
                              toggleAccordion={this.toggleAccordion.bind(this, accordion, index)}
                            />
                          </div>
                        }
                        content={this.renderContentAccordion(sar)}
                      />
                    </div>
                  </div>
                )
              })
            }
          </div>) : (<div className="noArchive"> ARCHIVE EMPTY </div>)
        }
      </div>
    )
  }
}

ArchiveList = withRouter(ArchiveList)
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default connect(mapStateToProps, {})(ArchiveList)