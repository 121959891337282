import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import './CullReport.scss'
import {Table, Tooltip} from "antd";
import {
    apiSmartCullReportDuplicateList,
    apiSmartCullReportEmptyList,
    apiSmartCullReportNearList,
    apiSmartCullReportThreadList,
    downloadExcelCullReport,
    getCullReportDataSecond
} from "../../../../api/SmartBox";
import moment from "moment";
import { handleByteConversion } from '../../../../utils'
const { Column } = Table
class CullReportList extends React.Component {
    state = {
        activePageBack: false,
        tableDuplicates: [],
        cursorMark: '',
        activeDownload: false,
        tableNearDuplicates: [],
        tableEmailThreads: [],
        tableEmptyFolders: [],
        onStateStart: false,
        onStateBack: false,
        onStateForth: false,
        onStateEnd: false,
        totalRecordsDuplicates: 0,
        isPrevDisabledDuplicates: true,
        isNextDisabledDuplicates: false,
        currentPageDuplicates: 1,
        inputPageDuplicates: '',
        perPageLimitDuplicates: 5,
        onStateStartNear: false,
        onStateBackNear: false,
        onStateForthNear: false,
        onStateEndNear: false,
        totalRecordsNear: 0,
        isPrevDisabledNear: true,
        isNextDisabledNear: false,
        currentPageNear: 1,
        inputPageNear: '',
        perPageLimitNear: 5,
        onStateStartEmpty: false,
        onStateBackEmpty: false,
        onStateForthEmpty: false,
        onStateEndEmpty: false,
        totalRecordsEmpty: 0,
        isPrevDisabledEmpty: true,
        isNextDisabledEmpty: false,
        currentPageEmpty: 1,
        inputPageEmpty: '',
        perPageLimitEmpty: 5,
        onStateStartThread: false,
        onStateBackThread: false,
        onStateForthThread: false,
        onStateEndThread: false,
        totalRecordsThread: 0,
        isPrevDisabledThread: true,
        isNextDisabledThread: false,
        currentPageThread: 1,
        inputPageThread: '',
        perPageLimitThread: 5,
    }
    componentDidMount() {
      //this.getCullReportList()
      this.onGetCullDuplicatesList()
      this.onGetCullNearList()
      this.onGetCullEmptyList()
      this.onGetCullThreadList()
    }

    getCullReportList = () => {
        // const { currentPage, filesPerPageLimit, cursorMark, selectColumnDuplicate} = this.state
        const { selectCullCategory } = this.props
        // let parentFolderName = ''
        // if (typeof folder.relativePath !== 'undefined') {
        //     parentFolderName = folder.relativePath.split('/')[1]
        // }
        // const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        // const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        // const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        // const requestDuplicateFileBoxSecondDTO = {
        //     clientId: this.props.clientId,
        //     hash: selectCullCategory && selectCullCategory.hash ? selectCullCategory.hash : hashFile ? hashFile : '',
        //     pageNr: currentPage,
        //     rows: filesPerPageLimit,
        //     folderPath: rootPathParent,
        //     cursorMark: currentPage === 1 ? '*' : cursorMark,
        //     searchWord: null,
        // }
        const requestCullSecondDTO = {
            cullReportId: selectCullCategory.id
        }
        // getCullReportDataSecond(requestCullSecondDTO).then((response) => {
        //     if (response.status === 200) {
        //         this.setState({
        //             // tableDuplicates: response.data.dupsData,
        //             // tableNearDuplicates: response.data.nearDupsData,
        //             // tableEmailThreads: response.data.emailThreadsData,
        //             // tableEmptyFolders: response.data.emptyFoldersData,
        //         })
        //     }
        // })
    }
    onClickBackList = () => {
        this.props.onVisibleCullPage(false)
    }
    onMouseEnterActive = (e, status) => {
        switch (status) {
            case 'back-page':
                this.setState({
                    activePageBack: true
                })
                break;
            case 'download':
                this.setState({
                    activeDownload: true
                })
                break;
            default: break
        }
    }
    onMouseLeaveActive() {
        this.setState({
            activePageBack: false,
            activeDownload: false,
        })
    }
    onMouseActiveAction = (e, state) => {
        switch (state) {
            case 'start':
                this.setState({
                    onStateStart: true,
                })
                break
            case 'back':
                this.setState({
                    onStateBack: true,
                })
                break
            case 'forth':
                this.setState({
                    onStateForth: true,
                })
                break
            case 'end':
                this.setState({
                    onStateEnd: true,
                })
                break
            default: break
        }
    }
    onMouseActiveActionNear = (e, state) => {
        switch (state) {
            case 'start':
                this.setState({
                    onStateStartNear: true,
                })
                break
            case 'back':
                this.setState({
                    onStateBackNear: true,
                })
                break
            case 'forth':
                this.setState({
                    onStateForthNear: true,
                })
                break
            case 'end':
                this.setState({
                    onStateEndNear: true,
                })
                break
            default: break
        }
    }
    onMouseActiveActionEmpty = (e, state) => {
        switch (state) {
            case 'start':
                this.setState({
                    onStateStartEmpty: true,
                })
                break
            case 'back':
                this.setState({
                    onStateBackEmpty: true,
                })
                break
            case 'forth':
                this.setState({
                    onStateForthEmpty: true,
                })
                break
            case 'end':
                this.setState({
                    onStateEndEmpty: true,
                })
                break
            default: break
        }
    }
    onMouseActiveActionThread = (e, state) => {
        switch (state) {
            case 'start':
                this.setState({
                    onStateStartThread: true,
                })
                break
            case 'back':
                this.setState({
                    onStateBackThread: true,
                })
                break
            case 'forth':
                this.setState({
                    onStateForthThread: true,
                })
                break
            case 'end':
                this.setState({
                    onStateEndThread: true,
                })
                break
            default: break
        }
    }
    onLeaveMouseAction = () => {
        this.setState({
            onStateStart: false,
            onStateBack: false,
            onStateForth: false,
            onStateEnd: false,
            onStateStartNear: false,
            onStateBackNear: false,
            onStateForthNear: false,
            onStateEndNear: false,
            onStateStartEmpty: false,
            onStateBackEmpty: false,
            onStateForthEmpty: false,
            onStateEndEmpty: false,
            onStateStartThread: false,
            onStateBackThread: false,
            onStateForthThread: false,
            onStateEndThread: false,

        })
    }
    handleMovePage = (e, direction) => {
        const { isPrevDisabledDuplicates, isNextDisabledDuplicates, totalRecordsDuplicates, perPageLimitDuplicates } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabledDuplicates) {
                    this.gotoPage(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabledDuplicates) {
                    this.gotoPage(this.state.currentPageDuplicates - 1 ? this.state.currentPageDuplicates - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabledDuplicates) {
                    this.gotoPage(this.state.currentPageDuplicates + 1 > Math.ceil(totalRecordsDuplicates / perPageLimitDuplicates) ? Math.ceil(totalRecordsDuplicates / perPageLimitDuplicates) : this.state.currentPageDuplicates + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabledDuplicates) {
                    this.gotoPage(Math.ceil(totalRecordsDuplicates/perPageLimitDuplicates))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPage = (newPage) => {
        const { totalRecordsDuplicates, perPageLimitDuplicates } = this.state
        const totalPages = Math.ceil(totalRecordsDuplicates / perPageLimitDuplicates)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPageDuplicates: newPage, inputPageDuplicates: newPage }, () => {
            this.onGetCullDuplicatesList()
        })
    }
    handleInputPageChange = (e) => this.setState({ inputPageDuplicates: e.target.value }, () => {
        this.onGetCullDuplicatesList()
    })
    handleInputPageBlur = () => {
        const { inputPageDuplicates } = this.state
        if (inputPageDuplicates) {
            const paginationEnd = Math.ceil(this.state.totalRecordsDuplicates / this.state.perPageLimitDuplicates)
            if (inputPageDuplicates > 0 && inputPageDuplicates <= paginationEnd) {
                this.gotoPage(inputPageDuplicates)
            } else {
                this.gotoPage(1)
                this.setState({ inputPageDuplicates: 1 })
            }
        }
    }
    handleInputPageEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlur()
        }
    }
    onGetCullDuplicatesList = () => {
        const { selectCullCategory } = this.props
        const { currentPageDuplicates, perPageLimitDuplicates } = this.state
        const cullReportDuplicateRequestDTO = {
            pageNr: currentPageDuplicates,
            pageRows: perPageLimitDuplicates,
            cullReportId: selectCullCategory.id
        }
        apiSmartCullReportDuplicateList(cullReportDuplicateRequestDTO).then((response) => {
             this.setState({
                 totalRecordsDuplicates: response.data.total,
                 tableDuplicates: response.data.cullReportDataEntityList,
             }, () => {
                 this.updatePreviewNextPageNumber()
             })
        })
    }
    updatePreviewNextPageNumber = () => {
        const { totalRecordsDuplicates, currentPageDuplicates } = this.state
        const totalPages = Math.ceil(totalRecordsDuplicates / this.state.perPageLimitDuplicates)
        const isPrevDisabledDuplicates = currentPageDuplicates > 1 ? false : true
        const isNextDisabledDuplicates = currentPageDuplicates < totalPages ? false : true
        this.setState({ isPrevDisabledDuplicates, isNextDisabledDuplicates, currentPageDuplicates }, () => {

        })
    }
    handleMovePageNear = (e, direction) => {
        const { isPrevDisabledNear, isNextDisabledNear, totalRecordsNear, perPageLimitNear } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabledNear) {
                    this.gotoPageNear(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabledNear) {
                    this.gotoPageNear(this.state.currentPageNear - 1 ? this.state.currentPageNear - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabledNear) {
                    this.gotoPageNear(this.state.currentPageNear + 1 > Math.ceil(totalRecordsNear / perPageLimitNear) ? Math.ceil(totalRecordsNear / perPageLimitNear) : this.state.currentPageNear + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabledNear) {
                    this.gotoPageNear(Math.ceil(totalRecordsNear/perPageLimitNear))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPageNear = (newPage) => {
        const { totalRecordsNear, perPageLimitNear } = this.state
        const totalPages = Math.ceil(totalRecordsNear / perPageLimitNear)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPageNear: newPage, inputPageNear: newPage }, () => {
            this.onGetCullNearList()
        })
    }
    handleInputPageChangeNear = (e) => this.setState({ inputPageNear: e.target.value }, () => {
        this.onGetCullNearList()
    })
    handleInputPageBlurNear = () => {
        const { inputPageNear } = this.state
        if (inputPageNear) {
            const paginationEnd = Math.ceil(this.state.totalRecordsNear / this.state.perPageLimitNear)
            if (inputPageNear > 0 && inputPageNear <= paginationEnd) {
                this.gotoPageNear(inputPageNear)
            } else {
                this.gotoPageNear(1)
                this.setState({ inputPageNear: 1 })
            }
        }
    }
    handleInputPageEnterNear = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlurNear()
        }
    }
    onGetCullNearList = () => {
        const { selectCullCategory } = this.props
        const { currentPageNear, perPageLimitNear } = this.state
        const cullReportNearRequestDTO = {
            pageNr: currentPageNear,
            pageRows: perPageLimitNear,
            cullReportId: selectCullCategory.id
        }
        apiSmartCullReportNearList(cullReportNearRequestDTO).then((response) => {
            this.setState({
                totalRecordsNear: response.data.total,
                tableNearDuplicates: response.data.cullReportDataEntityList,
            }, () => {
                this.updatePreviewNextPageNumberNear()
            })
        })
    }
    updatePreviewNextPageNumberNear = () => {
        const { totalRecordsNear, currentPageNear } = this.state
        const totalPages = Math.ceil(totalRecordsNear / this.state.perPageLimitDuplicates)
        const isPrevDisabledNear = currentPageNear > 1 ? false : true
        const isNextDisabledNear = currentPageNear < totalPages ? false : true
        this.setState({ isPrevDisabledNear, isNextDisabledNear, currentPageNear }, () => {

        })
    }

    handleMovePageEmpty = (e, direction) => {
        const { isPrevDisabledEmpty, isNextDisabledEmpty, totalRecordsEmpty, perPageLimitEmpty } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabledEmpty) {
                    this.gotoPageEmpty(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabledEmpty) {
                    this.gotoPageEmpty(this.state.currentPageEmpty - 1 ? this.state.currentPageEmpty - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabledEmpty) {
                    this.gotoPageEmpty(this.state.currentPageEmpty + 1 > Math.ceil(totalRecordsEmpty / perPageLimitEmpty) ? Math.ceil(totalRecordsEmpty / perPageLimitEmpty) : this.state.currentPageEmpty + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabledEmpty) {
                    this.gotoPageEmpty(Math.ceil(totalRecordsEmpty/perPageLimitEmpty))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPageEmpty = (newPage) => {
        const { totalRecordsEmpty, perPageLimitEmpty } = this.state
        const totalPages = Math.ceil(totalRecordsEmpty / perPageLimitEmpty)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPageEmpty: newPage, inputPageEmpty: newPage }, () => {
            this.onGetCullEmptyList()
        })
    }
    handleInputPageChangeEmpty = (e) => this.setState({ inputPageEmpty: e.target.value }, () => {
        this.onGetCullEmptyList()
    })
    handleInputPageBlurEmpty = () => {
        const { inputPageEmpty } = this.state
        if (inputPageEmpty) {
            const paginationEnd = Math.ceil(this.state.totalRecordsEmpty / this.state.perPageLimitEmpty)
            if (inputPageEmpty > 0 && inputPageEmpty <= paginationEnd) {
                this.gotoPageEmpty(inputPageEmpty)
            } else {
                this.gotoPageEmpty(1)
                this.setState({ inputPageEmpty: 1 })
            }
        }
    }
    handleInputPageEnterEmpty = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlurEmpty()
        }
    }
    onGetCullEmptyList = () => {
        const { selectCullCategory } = this.props
        const { currentPageEmpty, perPageLimitEmpty } = this.state
        const cullReportEmptyRequestDTO = {
            pageNr: currentPageEmpty,
            pageRows: perPageLimitEmpty,
            cullReportId: selectCullCategory.id
        }
        apiSmartCullReportEmptyList(cullReportEmptyRequestDTO).then((response) => {
            this.setState({
                totalRecordsEmpty: response.data.total,
                tableEmptyFolders: response.data.cullReportDataEntityList,
            }, () => {
                this.updatePreviewNextPageNumberEmpty()
            })
        })
    }
    updatePreviewNextPageNumberEmpty = () => {
        const { totalRecordsEmpty, currentPageEmpty } = this.state
        const totalPages = Math.ceil(totalRecordsEmpty / this.state.perPageLimitEmpty)
        const isPrevDisabledEmpty = currentPageEmpty > 1 ? false : true
        const isNextDisabledEmpty = currentPageEmpty < totalPages ? false : true
        this.setState({ isPrevDisabledEmpty, isNextDisabledEmpty, currentPageEmpty }, () => {

        })
    }

    handleMovePageThread = (e, direction) => {
        const { isPrevDisabledThread, isNextDisabledThread, totalRecordsThread, perPageLimitThread } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabledThread) {
                    this.gotoPageThread(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabledThread) {
                    this.gotoPageThread(this.state.currentPageThread - 1 ? this.state.currentPageThread - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabledThread) {
                    this.gotoPageThread(this.state.currentPageThread + 1 > Math.ceil(totalRecordsThread / perPageLimitThread) ? Math.ceil(totalRecordsThread / perPageLimitThread) : this.state.currentPageThread + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabledThread) {
                    this.gotoPageThread(Math.ceil(totalRecordsThread/perPageLimitThread))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPageThread = (newPage) => {
        const { totalRecordsThread, perPageLimitThread } = this.state
        const totalPages = Math.ceil(totalRecordsThread / perPageLimitThread)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPageThread: newPage, inputPageThread: newPage }, () => {
            this.onGetCullThreadList()
        })
    }
    handleInputPageChangeThread = (e) => this.setState({ inputPageThread: e.target.value }, () => {
        this.onGetCullThreadList()
    })
    handleInputPageBlurThread = () => {
        const { inputPageThread } = this.state
        if (inputPageThread) {
            const paginationEnd = Math.ceil(this.state.totalRecordsThread / this.state.perPageLimitThread)
            if (inputPageThread > 0 && inputPageThread <= paginationEnd) {
                this.gotoPageThread(inputPageThread)
            } else {
                this.gotoPageThread(1)
                this.setState({ inputPageThread: 1 })
            }
        }
    }
    handleInputPageEnterThread = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlurThread()
        }
    }
    onGetCullThreadList = () => {
        const { selectCullCategory } = this.props
        const { currentPageThread, perPageLimitThread } = this.state
        const cullReportThreadRequestDTO = {
            pageNr: currentPageThread,
            pageRows: perPageLimitThread,
            cullReportId: selectCullCategory.id
        }
        apiSmartCullReportThreadList(cullReportThreadRequestDTO).then((response) => {
            this.setState({
                totalRecordsThread: response.data.total,
                tableEmailThreads: response.data.cullReportDataEntityList,
            }, () => {
                this.updatePreviewNextPageNumberThread()
            })
        })
    }
    updatePreviewNextPageNumberThread = () => {
        const { totalRecordsThread, currentPageThread } = this.state
        const totalPages = Math.ceil(totalRecordsThread / this.state.perPageLimitThread)
        const isPrevDisabledThread = currentPageThread > 1 ? false : true
        const isNextDisabledThread = currentPageThread < totalPages ? false : true
        this.setState({ isPrevDisabledThread, isNextDisabledThread, currentPageThread }, () => {

        })
    }
    onTimezoneConvert = (time) => {
        const localTimeZone = moment.tz.guess()
        const localDate = moment.utc(time).tz(localTimeZone)
        return `${ moment(localDate).format('DD.MM.YYYY HH:mm:ss').split(' ').join(' at ') }`
    }
    render() {
        const { selectCullCategory } = this.props
        const { activePageBack, tableDuplicates, activeDownload, tableNearDuplicates, tableEmailThreads, tableEmptyFolders, totalRecordsDuplicates, perPageLimitDuplicates,
            onStateStart, onStateBack, onStateForth, onStateEnd, isPrevDisabledDuplicates, isNextDisabledDuplicates, currentPageDuplicates, inputPageDuplicates,
            totalRecordsNear, perPageLimitNear,
            onStateStartNear, onStateBackNear, onStateForthNear, onStateEndNear, isPrevDisabledNear, isNextDisabledNear, currentPageNear, inputPageNear,
            totalRecordsEmpty, perPageLimitEmpty,
            onStateStartEmpty, onStateBackEmpty, onStateForthEmpty, onStateEndEmpty, isPrevDisabledEmpty, isNextDisabledEmpty, currentPageEmpty, inputPageEmpty,
            totalRecordsThread, perPageLimitThread,
            onStateStartThread, onStateBackThread, onStateForthThread, onStateEndThread, isPrevDisabledThread, isNextDisabledThread, currentPageThread, inputPageThread} = this.state
        const totalPages = Math.ceil(totalRecordsDuplicates / perPageLimitDuplicates)
        const totalPagesNear = Math.ceil(totalRecordsNear / perPageLimitNear)
        const totalPagesEmpty = Math.ceil(totalRecordsEmpty / perPageLimitEmpty)
        const totalPagesThread = Math.ceil(totalRecordsThread / perPageLimitThread)
        return (
            <div className="cull-report">
                <div className="cull-report-header cull-report-header-list">
                    <div className="name-files-header">
                       <span className="header-back-file">
                         <span onClick={ this.onClickBackList }
                               onMouseEnter={(e) => this.onMouseEnterActive(e, 'back-page')}
                               onMouseLeave={(e) => this.onMouseLeaveActive(e)}
                               className={ activePageBack ? 'icon-v21-back-active' : "icon-v21-back-1" }></span>
                           <span className={ "title-files" }>
                               {selectCullCategory.reportDate ? this.onTimezoneConvert(selectCullCategory.reportDate) : 'unknown' }</span>
                       </span>
                    </div>
                    <span className="title-header-cull">
                            { `by ${ selectCullCategory.userFirstName }` }
                        </span>
                    <span className="title-header-cull">
                            {selectCullCategory.reportType && selectCullCategory.reportType === 'CULL' ? 'Quick cull' : (selectCullCategory.reportType && (selectCullCategory.reportType.charAt(0).toUpperCase() + selectCullCategory.reportType.slice(1).toLowerCase()).replace('_', ' '))}
                        </span>
                    <span className="content-action-cull">
                            <span className="number-items">{`${ selectCullCategory.deleted_files} items`}</span>
                        { ((tableDuplicates && tableDuplicates.length > 0) || (tableNearDuplicates && tableNearDuplicates.length > 0)
                            || (tableEmailThreads && tableEmailThreads.length > 0) || (tableEmptyFolders && tableEmptyFolders.length > 0)) ?
                            <a  href={downloadExcelCullReport(selectCullCategory.id, this.props.clientId, this.props.user.id)}
                                onMouseEnter={(e) => this.onMouseEnterActive(e, 'download')}
                                onMouseLeave={(e) => this.onMouseLeaveActive(e)}
                                className={activeDownload ? 'icon-v21-download-active-1' : 'icon-v21-download-1'}> </a> :
                            <a href={() => false}
                                className={ 'icon-v21-download-1 disabled-download '}> </a>
                        }
                        </span>
                </div>
                <div className="all-tables-cull">
                    { tableDuplicates && tableDuplicates.length > 0 &&
                        <div className="table-cull-content">
                            <div className="buttons-control-pagination">
                                <Tooltip title="first" className={isPrevDisabledDuplicates ? 'disabled' : ''}>
                                <span className={ isPrevDisabledDuplicates ? 'icon-v14-start-1 disabled' : onStateStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'start')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'first')}/>
                                </Tooltip>
                                <Tooltip title="previous" className={isPrevDisabledDuplicates ? 'disabled' : ''}>
                                <span className={ isPrevDisabledDuplicates ? 'icon-v21-back-1 disabled' : onStateBack ? 'icon-v21-back-active' : 'icon-v21-back-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'back')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                                </Tooltip>
                                { totalPages === 0 &&
                                <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
                                }
                                { totalPages === 1 &&
                                <span className='buttons-control-pagination__text'>{currentPageDuplicates} of {totalPages} </span>
                                }
                                { totalPages > 1 &&
                                <span className='buttons-control-pagination__text'>
                                <input
                                    type="number"
                                    className="insert-page"
                                    placeholder= { currentPageDuplicates }
                                    value= { inputPageDuplicates }
                                    min="1"
                                    max={totalPages}
                                    onChange={ (e) => this.handleInputPageChange(e) }
                                    onBlur={ () => this.handleInputPageBlur() }
                                    onKeyDown={ (e) => this.handleInputPageEnter(e) }
                                />
                                <span>of {totalPages}</span>
                              </span>
                                }
                                <Tooltip title="next" className={isNextDisabledDuplicates ? 'disabled' : ''}>
                                <span className={isNextDisabledDuplicates ? 'icon-v21-forth-1 disabled' : onStateForth ? 'icon-v21-forth-active' : 'icon-v21-forth-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'forth')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'next')}/>
                                </Tooltip>
                                <Tooltip title="last" className={ isNextDisabledDuplicates ? 'disabled' : '' }>
                                <span className={ isNextDisabledDuplicates ? 'icon-v14-end-1 disabled' : onStateEnd ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'end')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'last')}/>
                                </Tooltip>
                            </div>
                            <Table className="table-cull-files"
                                   dataSource={tableDuplicates}
                                   pagination={false}
                                   showHeader={true}
                            >
                                <Column className="column-name-cull" title="Duplicates" dataIndex="fileName"
                                        key="fileName" render={(text, record) => {
                                    return (
                                        <span className="text-style">{text}</span>
                                    )
                                }}/>
                                <Column className="column-name-cull column-size" title="Size" dataIndex="size" key="size"
                                        render={(text, record) => {
                                            return (
                                                <span className="text-style">{handleByteConversion(text)}</span>
                                            )
                                        }}/>
                            </Table>
                        </div>
                    }
                    { tableNearDuplicates && tableNearDuplicates.length > 0 &&
                    <div className="table-cull-content margin-table">
                        <div className="buttons-control-pagination">
                            <Tooltip title="first" className={isPrevDisabledNear ? 'disabled' : ''}>
                                <span className={ isPrevDisabledNear ? 'icon-v14-start-1 disabled' : onStateStartNear ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionNear(e, 'start')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageNear(e, 'first')}/>
                            </Tooltip>
                            <Tooltip title="previous" className={isPrevDisabledNear ? 'disabled' : ''}>
                                <span className={ isPrevDisabledNear ? 'icon-v21-back-1 disabled' : onStateBackNear ? 'icon-v21-back-active' : 'icon-v21-back-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionNear(e, 'back')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageNear(e, 'previous')}/>
                            </Tooltip>
                            { totalPagesNear === 0 &&
                            <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
                            }
                            { totalPagesNear === 1 &&
                            <span className='buttons-control-pagination__text'>{currentPageNear} of {totalPagesNear} </span>
                            }
                            { totalPagesNear > 1 &&
                            <span className='buttons-control-pagination__text'>
                                <input
                                    type="number"
                                    className="insert-page"
                                    placeholder= { currentPageNear }
                                    value= { inputPageNear }
                                    min="1"
                                    max={totalPagesNear}
                                    onChange={ (e) => this.handleInputPageChangeNear(e) }
                                    onBlur={ () => this.handleInputPageBlurNear() }
                                    onKeyDown={ (e) => this.handleInputPageEnterNear(e) }
                                />
                                <span>of {totalPagesNear}</span>
                              </span>
                            }
                            <Tooltip title="next" className={isNextDisabledNear ? 'disabled' : ''}>
                                <span className={isNextDisabledNear ? 'icon-v21-forth-1 disabled' : onStateForthNear ? 'icon-v21-forth-active' : 'icon-v21-forth-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionNear(e, 'forth')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageNear(e, 'next')}/>
                            </Tooltip>
                            <Tooltip title="last" className={ isNextDisabledNear ? 'disabled' : '' }>
                                <span className={ isNextDisabledNear ? 'icon-v14-end-1 disabled' : onStateEndNear ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionNear(e, 'end')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageNear(e, 'last')}/>
                            </Tooltip>
                        </div>
                        <Table className="table-cull-files"
                               dataSource={tableNearDuplicates}
                               pagination={false}
                               showHeader={true}
                        >
                            <Column className="column-name-cull" title="Near duplicates" dataIndex="fileName"
                                    key="fileName" render={(text, record) => {
                                return (
                                    <span className="text-style">{text}</span>
                                )
                            }}/>
                            <Column className="column-name-cull column-size" title="Size" dataIndex="size" key="size"
                                    render={(text, record) => {
                                        return (
                                            <span className="text-style">{handleByteConversion(text)}</span>
                                        )
                                    }}/>
                        </Table>
                    </div>
                    }
                    {tableEmailThreads && tableEmailThreads.length > 0 &&
                    <div className="table-cull-content margin-table">
                        <div className="buttons-control-pagination">
                            <Tooltip title="first" className={isPrevDisabledThread ? 'disabled' : ''}>
                                <span className={ isPrevDisabledThread ? 'icon-v14-start-1 disabled' : onStateStartThread ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionThread(e, 'start')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageThread(e, 'first')}/>
                            </Tooltip>
                            <Tooltip title="previous" className={isPrevDisabledThread ? 'disabled' : ''}>
                                <span className={ isPrevDisabledThread ? 'icon-v21-back-1 disabled' : onStateBackThread ? 'icon-v21-back-active' : 'icon-v21-back-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionThread(e, 'back')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageThread(e, 'previous')}/>
                            </Tooltip>
                            { totalPagesThread === 0 &&
                            <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
                            }
                            { totalPagesThread === 1 &&
                            <span className='buttons-control-pagination__text'>{currentPageThread} of {totalPagesThread} </span>
                            }
                            { totalPagesThread > 1 &&
                            <span className='buttons-control-pagination__text'>
                                <input
                                    type="number"
                                    className="insert-page"
                                    placeholder= { currentPageThread }
                                    value= { inputPageThread }
                                    min="1"
                                    max={totalPagesThread}
                                    onChange={ (e) => this.handleInputPageChangeThread(e) }
                                    onBlur={ () => this.handleInputPageBlurThread() }
                                    onKeyDown={ (e) => this.handleInputPageEnterThread(e) }
                                />
                                <span>of {totalPagesThread}</span>
                              </span>
                            }
                            <Tooltip title="next" className={isNextDisabledThread ? 'disabled' : ''}>
                                <span className={isNextDisabledThread ? 'icon-v21-forth-1 disabled' : onStateForthThread ? 'icon-v21-forth-active' : 'icon-v21-forth-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionThread(e, 'forth')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageThread(e, 'next')}/>
                            </Tooltip>
                            <Tooltip title="last" className={ isNextDisabledThread ? 'disabled' : '' }>
                                <span className={ isNextDisabledThread ? 'icon-v14-end-1 disabled' : onStateEndThread ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionThread(e, 'end')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageThread(e, 'last')}/>
                            </Tooltip>
                        </div>
                        <Table className="table-cull-files"
                               dataSource={tableEmailThreads}
                               pagination={false}
                               showHeader={true}
                        >
                            <Column className="column-name-cull" title="Email threads" dataIndex="fileName"
                                    key="fileName" render={(text, record) => {
                                return (
                                    <span className="text-style">{text}</span>
                                )
                            }}/>
                            <Column className="column-name-cull column-size" title="Size" dataIndex="size" key="size"
                                    render={(text, record) => {
                                        return (
                                            <span className="text-style">{handleByteConversion(text)}</span>
                                        )
                                    }}/>
                        </Table>
                    </div>
                    }
                    {tableEmptyFolders && tableEmptyFolders.length > 0 &&
                    <div className="table-cull-content margin-table">
                        <div className="buttons-control-pagination">
                            <Tooltip title="first" className={isPrevDisabledEmpty ? 'disabled' : ''}>
                                <span className={ isPrevDisabledEmpty ? 'icon-v14-start-1 disabled' : onStateStartEmpty ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionEmpty(e, 'start')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageEmpty(e, 'first')}/>
                            </Tooltip>
                            <Tooltip title="previous" className={isPrevDisabledEmpty ? 'disabled' : ''}>
                                <span className={ isPrevDisabledEmpty ? 'icon-v21-back-1 disabled' : onStateBackEmpty ? 'icon-v21-back-active' : 'icon-v21-back-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionEmpty(e, 'back')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageEmpty(e, 'previous')}/>
                            </Tooltip>
                            { totalPagesEmpty === 0 &&
                            <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
                            }
                            { totalPagesEmpty === 1 &&
                            <span className='buttons-control-pagination__text'>{currentPageEmpty} of {totalPagesEmpty} </span>
                            }
                            { totalPagesEmpty > 1 &&
                            <span className='buttons-control-pagination__text'>
                                <input
                                    type="number"
                                    className="insert-page"
                                    placeholder= { currentPageEmpty }
                                    value= { inputPageEmpty }
                                    min="1"
                                    max={totalPagesEmpty}
                                    onChange={ (e) => this.handleInputPageChangeEmpty(e) }
                                    onBlur={ () => this.handleInputPageBlurEmpty() }
                                    onKeyDown={ (e) => this.handleInputPageEnterEmpty(e) }
                                />
                                <span>of {totalPagesEmpty}</span>
                              </span>
                            }
                            <Tooltip title="next" className={isNextDisabledEmpty ? 'disabled' : ''}>
                                <span className={isNextDisabledEmpty ? 'icon-v21-forth-1 disabled' : onStateForthEmpty ? 'icon-v21-forth-active' : 'icon-v21-forth-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionEmpty(e, 'forth')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageEmpty(e, 'next')}/>
                            </Tooltip>
                            <Tooltip title="last" className={ isNextDisabledEmpty ? 'disabled' : '' }>
                                <span className={ isNextDisabledEmpty ? 'icon-v14-end-1 disabled' : onStateEndEmpty ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                                      onMouseEnter={(e) => this.onMouseActiveActionEmpty(e, 'end')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePageEmpty(e, 'last')}/>
                            </Tooltip>
                        </div>
                        <Table className="table-cull-files"
                               dataSource={tableEmptyFolders}
                               pagination={false}
                               showHeader={true}
                        >
                            <Column className="column-name-cull" title="Empty folders" dataIndex="fileName"
                                    key="fileName" render={(text, record) => {
                                return (
                                    <span className="text-style">{text}</span>
                                )
                            }}/>
                            <Column className="column-name-cull column-size" title="Size" dataIndex="size" key="size"
                                    render={(text, record) => {
                                        return (
                                            <span className="text-style">{handleByteConversion(text)}</span>
                                        )
                                    }}/>
                        </Table>
                    </div>
                    }
                </div>
            </div>
        )

    }
}

CullReportList.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    user: state.userReducer.user
})
export default compose(
    connect(mapStateToProps),
)(CullReportList)