import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../Tracker/Tracker.scss'
import './AiAnnotationsSidebar.scss'
import Business from './Business'
import RegularExpressions from './RegularExpressions'
import swal from "sweetalert2";
import {
    apiSmartBoxUpdateRedactionNumber,
    saveKeysRedactedBusiness,
    saveKeysRedactedOnRegEx
} from "../../../api/SmartBox";
import CustomNotificationManager from "../../Common/CustomNotificationManager";

class ManualTab extends React.Component {
  state = {
    activeKeyTab: 'manualTerms',
      visibleLists: false,
      visibleRegExEmails: true,
      visibleBosTerms: true,
      dictionaryList: [],
      regExList: [],
      regExListVersion: {},
      visibleRedactRegEx: false,
      visibleRedactBusiness: false,
      getAllChildrenRegEx: [],
      getAllChildrenBusiness: [],
      checkedChildRegEx: [],
      checkedChildBusiness: [],
      startBusinessRedact: false,
      showVisibleDictionary: null,
      visibleDictionaryCat: false,
      showVisibleRegEx: null,
      visibleRegExCat: false,
      unCheckedKeysRegEx2: [],
      removeAllRegEx: false,
      removeAllDictionaries: false,
      statusRemoveAllRegEx: null,
      visibleTrueDict: true

  }
  onSelectActiveTabsOnManual = (statusActiveTab) => {
    this.setState({
      activeKeyTab: statusActiveTab
    })
  }
  onDisplaySelectComponentAi = (component) => {
      const { regExList } = this.state
      const { dictionaryList } = this.props
       switch (component) {
           case 'lists':
               this.setState({
                   visibleLists: !this.state.visibleLists
               })
               break
           case 'reg-ex':
               if (regExList && regExList.length > 0 && regExList[0].children && regExList[0].children.length > 0) {

                   this.setState({
                       visibleRegExEmails: !this.state.visibleRegExEmails
                   })
               }
               break
           case 'box-terms':
               if (dictionaryList && dictionaryList.length > 0 && dictionaryList[0].children && dictionaryList[0].children.length > 0)
               {
                   this.setState({
                       visibleBosTerms: !this.state.visibleBosTerms,
                       visibleTrueDict: !this.state.visibleTrueDict
                   })
               }
               break
           default: break
       }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
      //const childrenBusiness = []
      // if (prevProps !== this.props && this.props.treeDataAnnotationsBusiness[0].children.length > 0) {
      //     this.props.treeDataAnnotationsBusiness && this.props.treeDataAnnotationsBusiness[0].children && this.props.treeDataAnnotationsBusiness[0].children.length > 0 && this.props.treeDataAnnotationsBusiness[0].children.forEach((child) => {
      //         child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
      //             child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
      //                 childrenBusiness.push(child3)
      //             })
      //         })
      //     })
      //     this.setState({
      //         dictionaryList: this.props.treeDataAnnotationsBusiness,
      //         getAllChildrenBusiness: childrenBusiness
      //     })
      // }
      if (prevProps.statusHideAllHighlighting !== this.props.statusHideAllHighlighting) {
          if (this.props.statusHideAllHighlighting === true) {
              this.setState({
                  showVisibleDictionary: null
              })
          } else {
              this.setState({
                  showVisibleDictionary: true
              })
          }
          this.setState({
              visibleDictionaryCat: true,
          })
          const { dictionaryList } = this.props
          dictionaryList && dictionaryList[0].children.length > 0 && dictionaryList[0].children.forEach((keyColour) => {
              keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                  keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                      // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                      if (keyColour3 && keyColour3.key) {
                          const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                          for (const element of aiAnnotations) {
                              if (this.props.statusHideAllHighlighting === true) {
                                  element.style.background = keyColour3.backgroundColor
                                  element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                              } else if (this.props.statusHideAllHighlighting === false) {
                                  element.style.background = 'transparent'
                                  element.style.borderColor = 'initial'
                              }
                          }
                      }
                  })
              })
          })

          if (this.props.statusHideAllHighlighting === true) {
              this.setState({
                  showVisibleRegEx: null
              })
          } else {
              this.setState({
                  showVisibleRegEx: true
              })
          }
          this.setState({
              visibleRegExCat: true
          })

          const { regExList } = this.props
          regExList && regExList[0].children.length > 0 && regExList[0].children.forEach((keyColour) => {
              keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                  keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                      // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                      if (keyColour3 && keyColour3.key) {
                          const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                          for (const element of aiAnnotations) {
                              if (this.props.statusHideAllHighlighting === true) {
                                  element.style.background = keyColour3.backgroundColor
                                  element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                              } else if (this.props.statusHideAllHighlighting === false) {
                                  element.style.background = 'transparent'
                                  element.style.borderColor = 'initial'
                              }
                          }
                      }
                  })
              })
          })
      }
  }

    onGetDictionary = (dictList) => {
      const childrenBusiness = []
      dictList && dictList[0].children && dictList[0].children.length > 0 && dictList[0].children.forEach((child) => {
          child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
              child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                  childrenBusiness.push(child3)
              })
          })
      })
      // this.setState({
      //     dictionaryList: dictList,
      //     getAllChildrenBusiness: childrenBusiness
      // })
  }
    onGetRegEx = (regList) => {
        const childrenReg = []
        regList && regList.length > 0 && regList[0].children && regList[0].children.length > 0 && regList[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    childrenReg.push(child3)
                })
            })
        })
        this.setState({
            regExList: regList,
            getAllChildrenRegEx: childrenReg
        })
    }
    onGetRegExVersion = (regList) => {
        this.setState({
            regExListVersion: regList
        })
    }
    onHoverCategory = (e, status) => {
      if (status === 'regex') {
          this.setState({
              visibleRedactRegEx: true,
              visibleRegExCat: true,
          })
      } else if (status === 'business') {
          this.setState({
              visibleRedactBusiness: true,
              visibleDictionaryCat: true,
          })
      }
    }
    onLeaveCategory = () => {
      const { showVisibleDictionary, showVisibleRegEx } = this.state
        if (showVisibleDictionary !== null) {
            this.setState({
                visibleDictionaryCat: true
            })
        } else {
            this.setState({
                visibleDictionaryCat: false
            })
        }
        if (showVisibleRegEx !== null) {
            this.setState({
                visibleRegExCat: true
            })
        } else {
            this.setState({
                visibleRegExCat: false
            })
        }
        this.setState({
            visibleRedactRegEx: false,
            visibleRedactBusiness: false,
        })
    }
    onRedactCategories = (e, status) => {
        const { treeBusiness, listCategories } = this.props
        //const { getAllChildrenBusiness } = this.state
        const { getAllChildrenRegEx, regExList, regExListVersion } = this.props
        const { dictionaryList, getAllChildrenBusiness } = this.props
        const selectAllItemsRegEx = []
        const selectAllItemsBusiness = []
        if (status === 'regex') {
            regExList && regExList.length > 0 && regExList[0].children && regExList[0].children.length > 0 && regExList[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        selectAllItemsRegEx.push(child3)
                    })
                })
            })
            const allWordsRedacted = []
            regExList && regExList.length > 0 && regExList[0].children && regExList[0].children.length > 0 && regExList[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        child3.categoryName = 'Regular Expressions'
                        child3.subcategoryName = child.key
                        allWordsRedacted.push(child3)
                    })
                })
            })
            listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
                elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                    allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                        if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                            item.categoryColor = elem2.subcategoryColorWithAlpha
                        }
                    })
                })
            })
            if (Object.keys(regExListVersion) && Object.keys(regExListVersion).length > 0 && getAllChildrenRegEx && getAllChildrenRegEx.length > 0) {

            }
            this.onRedactAiAllCategories(selectAllItemsRegEx, status)
        } else if (status === 'business') {
            treeBusiness && treeBusiness.length > 0 && treeBusiness[0].children && treeBusiness[0].children.length > 0 && treeBusiness[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        selectAllItemsBusiness.push(child3)
                    })
                })
            })
            const allWordsRedacted = []
            treeBusiness && treeBusiness.length > 0 && treeBusiness[0].children && treeBusiness[0].children.length > 0 && treeBusiness[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        child3.categoryName = 'Dictionaries'
                        child3.subcategoryName = child.key
                        allWordsRedacted.push(child3)
                    })
                })
            })
            listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
                elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                    allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                        if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                            item.categoryColor = elem2.subcategoryColorWithAlpha
                        }
                    })
                })
            })
            if (dictionaryList && dictionaryList.length > 0 && dictionaryList[0].children && dictionaryList[0].children.length > 0 && getAllChildrenBusiness.length > 0) {
                this.onRedactAiAllCategories(selectAllItemsBusiness, status, allWordsRedacted)
            }
        }
    }
    onRedactAiAllCategories = (classList, status, allWordsRedacted) => {
        //if (classList && classList.length > 0) {
            const sortedByPageItems = classList.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            this.drawMatchedAllWords(sortedByPageItems.map((item) => item.key), status, allWordsRedacted)
        //}
    }
    drawMatchedAllWords = (classList, status, allWordsRedacted) => {
        const { treeRegEx, treeBusiness, alreadyRedactedKeys } = this.props
        //const { getAllChildrenBusiness } = this.state
        const { getAllChildrenRegEx, getAllChildrenBusiness } = this.props
        let unCheckedKeysRegEx = []
        let unCheckedKeysBusiness = []
        let textMessageRedact = ''
        if (status === 'regex') {
            if (alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegEx.length) {
                textMessageRedact = classList && classList.length && `Remove ${ classList.length } selected redactions?`
                unCheckedKeysRegEx = classList
            } else {
                textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
            }
        } else if (status === 'business') {
            if ((alreadyRedactedKeys && alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getAllChildrenBusiness.length) ||
                (alreadyRedactedKeys && alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.length === getAllChildrenBusiness.length)) {
                textMessageRedact = classList && classList.length && `Remove ${ classList.length } selected redactions?`
                unCheckedKeysBusiness = classList
                //alreadyRedactedKeys.business = []
            } else {
                textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
            }
        }
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: status === 'business' && unCheckedKeysBusiness.length > 0 ? 'Unredact' : status === 'regex' && unCheckedKeysRegEx.length > 0 ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                if (status === 'regex') {
                    if (alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegEx.length) {
                        this.setState({
                            removeAllRegEx: true,
                            statusRemoveAllRegEx: true
                        })
                    } else {
                        this.onSaveKeysRedactedRegEx()
                        this.onUpdateRedactionNumber(classList)
                        this.setState({
                            removeAllRegEx: false,
                            statusRemoveAllRegEx: false
                        })
                    }
                } else if (status === 'business') {
                    if ((alreadyRedactedKeys && alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getAllChildrenBusiness.length) ||
                        (alreadyRedactedKeys && alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.length === getAllChildrenBusiness.length)) {
                        this.setState({
                            removeAllDictionaries: true
                        })
                    } else {
                        this.setState({
                            removeAllDictionaries: false
                        })
                        this.onSaveKeysBusiness()
                        this.onUpdateRedactionNumber(classList)
                    }
                }
                this.setState({
                    redactedKeys: []
                }, () => {
                    if (treeRegEx && treeRegEx.length > 0 && status === 'regex') {
                        if (unCheckedKeysRegEx.length > 0) {
                            this.props.drawAllMatchedWordsByClassList([], unCheckedKeysRegEx, 'regex', allWordsRedacted)
                        } else {
                            this.props.drawAllMatchedWordsByClassList(classList, unCheckedKeysRegEx, 'regex', allWordsRedacted)
                        }
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllRegEx: classList,
                            checkedChildRegEx: classList,
                        })
                    } else if (treeBusiness && treeBusiness.length > 0 && status === 'business') {
                        if (unCheckedKeysBusiness.length > 0) {
                            this.props.drawAllMatchedWordsByClassList([], unCheckedKeysBusiness, 'dictionaries', allWordsRedacted)
                        } else {
                            this.props.drawAllMatchedWordsByClassList(classList, unCheckedKeysBusiness, 'dictionaries', allWordsRedacted)
                        }
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllBusiness: classList,
                            checkedChildBusiness: classList,
                            startBusinessRedact: true
                        })
                    }
                })
            }
        })
    }
    onStatusRemoveRedaction = (status) => {
        this.setState({
            removeAllRegEx: status
        })
    }
    onStatusRemoveDictionaries = (status) => {
        this.setState({
            removeAllDictionaries: status
        })
    }
    onUnCheckedKeysRegEx = (unCheckedKeysRegEx) => {
      this.setState({
          unCheckedKeysRegEx2: unCheckedKeysRegEx
      })
    }
    onSaveKeysBusiness = () => {
        const { treeBusiness } = this.props
        const selectedKeys = []
        treeBusiness && treeBusiness[0].children.forEach((item) => {
            item && item.children.forEach((item2) => {
                item2 && item2.children.forEach((item3) => {
                    selectedKeys.push(`${ item3.key } => ${ item3.name } => ${ item3.pageNumber }`)
                })
            })
        })
        const data = {
            id: this.props.idDocument,
            listKeys: selectedKeys
        }
        saveKeysRedactedBusiness(data).then(() => {

        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    onSaveKeysRedactedRegEx = () => {
        const { treeRegEx } = this.props
        const selectedKeys = []
        treeRegEx && treeRegEx[0].children.forEach((item) => {
            item && item.children.forEach((item2) => {
                item2 && item2.children.forEach((item3) => {
                    selectedKeys.push(`${ item3.key } => ${ item3.name } => ${ item3.pageNumber }`)
                })
            })
        })
        const data = {
            id: this.props.idDocument,
            listKeys: selectedKeys
        }
        saveKeysRedactedOnRegEx(data).then(() => {

        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    onUpdateRedactionNumber = (classList) => {
        const data = {
            doc_id: this.props.idDocument,
            box_number_words_redacted: classList.length
        }
        apiSmartBoxUpdateRedactionNumber(data).then((response) => {

        })
    }
    onShowVisibleDictionary = (e, status) => {
        const category = 'Dictionary'
        const item = {
            children: [{categoryNameUpdate: 'Dictionary'}]
        }
      if (status === false) {
          this.setState({
              showVisibleDictionary: null
          })
          if (this.props.isOcr) {
              this.props.showHideCategoryOcr(e, category, true, item)
          }
      } else {
          this.setState({
              showVisibleDictionary: true
          })
          if (this.props.isOcr) {
              this.props.showHideCategoryOcr(e, category, false, item)
          }
      }
           this.setState({
               //showVisibleDictionary: status,
               visibleDictionaryCat: true,
           })
        this.onShowVisibleCatDictionary(status)
    }
    onShowVisibleCatDictionary = (showVisibleDictionary) => {
        //const { dictionaryList } = this.state
        const { dictionaryList } = this.props
        dictionaryList && dictionaryList[0].children.length > 0 && dictionaryList[0].children.forEach((keyColour) => {
            keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                    // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                    if (keyColour3 && keyColour3.key && showVisibleDictionary !== null) {
                        const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                        for (const element of aiAnnotations) {
                            if (showVisibleDictionary === false) {
                                this.props.onChangeStatusIconHideAllHighlighting()
                                element.style.background = keyColour3.backgroundColor
                                element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                            } else if (showVisibleDictionary === true) {
                                element.style.background = 'transparent'
                                element.style.borderColor = 'initial'
                            }
                        }
                    }
                })
            })
        })
    }
    onShowVisibleRegEx = (e, status) => {
      const category = 'RegEx'
        const item = {
          children: [{categoryNameUpdate: 'RegEx'}]
        }
        if (status === false) {
            this.setState({
                showVisibleRegEx: null
            })
            if (this.props.isOcr) {
                this.props.showHideCategoryOcr(e, category, true, item)
            }
        } else {
            this.setState({
                showVisibleRegEx: true
            })
            if (this.props.isOcr) {
                this.props.showHideCategoryOcr(e, category, false, item)
            }
        }
        this.setState({
            //showVisibleRegEx: status,
            visibleRegExCat: true
        })
        this.onShowVisibleCatRegEx(status)
    }
    onShowVisibleCatRegEx = (showVisibleDictionary) => {
        //const { regExList } = this.state
        const { regExList } = this.props
        regExList && regExList[0].children.length > 0 && regExList[0].children.forEach((keyColour) => {
            keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                    // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                    if (keyColour3 && keyColour3.key && showVisibleDictionary !== null) {
                        const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                        for (const element of aiAnnotations) {
                            if (showVisibleDictionary === false) {
                                this.props.onChangeStatusIconHideAllHighlighting()
                                element.style.background = keyColour3.backgroundColor
                                element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                            } else if (showVisibleDictionary === true) {
                                element.style.background = 'transparent'
                                element.style.borderColor = 'initial'
                            }
                        }
                    }
                })
            })
        })
    }
  render() {
    const { activeKeyTab, visibleRegExEmails, visibleBosTerms, visibleRedactRegEx,
        visibleRedactBusiness, checkedChildRegEx, checkedChildBusiness, startBusinessRedact, showVisibleDictionary, visibleDictionaryCat,
        showVisibleRegEx, visibleRegExCat, removeAllRegEx, removeAllDictionaries, statusRemoveAllRegEx, visibleTrueDict} = this.state
      const { alreadyRedactedKeys } = this.props
      const { getAllChildrenRegEx, regExListVersion, dictionaryList, getAllChildrenBusiness, getAllChildrenBusinessParent, getAllChildrenRegExUpdateParent } = this.props
      if (alreadyRedactedKeys && alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
          alreadyRedactedKeys.dictionaries = alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.filter((a, i) => alreadyRedactedKeys.dictionaries.findIndex((s) => a === s) === i)
      }
    return (
      <div className="update-ai-results-manual">
          <div className="component-box-terms">
              <span className={ dictionaryList && dictionaryList.length > 0 && dictionaryList[0].children && dictionaryList[0].children.length === 0 ? "tab-update-box-terms-empty" :
                  getAllChildrenBusiness.length === 0  ? "tab-update-box-terms-empty" :
                  visibleRedactBusiness ? 'tab-update-box-terms tab-update-business-redact' :
                      startBusinessRedact ? 'tab-update-box-terms tab-update-business-redact' :
                          alreadyRedactedKeys && alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getAllChildrenBusiness.length ? 'tab-update-box-terms tab-update-business-redact' :
                          alreadyRedactedKeys && alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getAllChildrenBusinessParent.length ? 'tab-update-box-terms tab-update-business-redact' :
                              alreadyRedactedKeys && alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.length === getAllChildrenBusiness.length ? 'tab-update-box-terms tab-update-business-redact' : 'tab-update-box-terms' }
                    onMouseLeave={() => this.onLeaveCategory()}
                    onMouseEnter={(e) => this.onHoverCategory(e, 'business')}>
                  <span className="content-dictionary-terms">
                       <span className={ visibleRedactBusiness && getAllChildrenBusiness && getAllChildrenBusiness.length > 0 ? 'icon-v17-redaction-hover' :
                           //startBusinessRedact && !removeAllDictionaries ? 'icon-v17-redaction' :
                           alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getAllChildrenBusiness.length && !removeAllDictionaries ? 'icon-v17-redaction' :
                           alreadyRedactedKeys.business && alreadyRedactedKeys.business.length > 0 && alreadyRedactedKeys.business.length === getAllChildrenBusinessParent.length && !removeAllDictionaries ? 'icon-v17-redaction' :
                               alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0 && alreadyRedactedKeys.dictionaries.length === getAllChildrenBusiness.length && !removeAllDictionaries ? 'icon-v17-redaction' : '' }
                             onClick={(e) => this.onRedactCategories(e, 'business')}></span>
                      <span className="title-box-terms" onClick={() => this.onDisplaySelectComponentAi('box-terms')}>Dictionaries</span>
                      {dictionaryList && dictionaryList.length > 0 && dictionaryList[0].children && dictionaryList[0].children.length > 0 && getAllChildrenBusiness && getAllChildrenBusiness.length > 0 &&
                          <React.Fragment>
                              {
                                  visibleBosTerms ?
                                      <span className="icon-v21-arrow-bold" onClick={() => this.onDisplaySelectComponentAi('box-terms')}/> :
                                      <span className="icon-v21-arrow-bold rotate-arrow-bold" onClick={() => this.onDisplaySelectComponentAi('box-terms')}/>
                              }
                          </React.Fragment>
                      }
                  </span>
                  { dictionaryList && dictionaryList.length > 0 && dictionaryList[0].children && dictionaryList[0].children.length > 0 && getAllChildrenBusiness && getAllChildrenBusiness.length > 0 &&
                      <React.Fragment>
                          { visibleDictionaryCat &&
                              <span className="content-visible-category">
                                  { !showVisibleDictionary ?
                                      <span className="icon-v12-visible" aria-hidden="true"
                                            onClick={(e) => this.onShowVisibleDictionary(e, true)}/> :
                                      <span className="icon-v12-unvisible" aria-hidden="true"
                                            onClick={(e) => this.onShowVisibleDictionary(e, false)}/>
                                  }
                              </span>
                          }
                      </React.Fragment>
                  }
              </span>
              { visibleTrueDict &&
              <Business
                  idDocument={ this.props.isNextDocumentId.id ? this.props.isNextDocumentId.id : this.props.idDocument }
                  isSmartBoxApplication={ this.props.isSmartBoxApplication }
                  pageNumber={ this.props.pageNumber }
                  landscape={ this.props.landscape }
                  changePage={ this.props.changePage }
                  drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}
                  toggleLoadingProcess = { this.props.toggleLoadingProcess }
                  activeKeyTab = { activeKeyTab }
                  activeKey = { this.props.activeKey }
                  isDocumentReady={ this.props.isDocumentReady }
                  newListKeysBusiness={ this.props.newListKeysBusiness }
                  checkOnClearAllBusiness={ this.props.checkOnClearAllBusiness }
                  verificationOnClickClearAllBusiness={ this.props.verificationOnClickClearAllBusiness }
                  onCheckedKeysObjectRefsBoxTerms={ this.props.onCheckedKeysObjectRefsBoxTerms }
                  isNextDocumentId={ this.props.isNextDocumentId }
                  allChildrenTreeBusiness={ this.props.allChildrenTreeBusiness }
                  checkedKeysAllBusiness={ this.props.checkedKeysAllBusiness }
                  updateRedactAllStatus={ this.props.updateRedactAllStatus }
                  onDocAnnotationsList={ this.props.onDocAnnotationsList }
                  isOcr = { this.props.isOcr }
                  fetchSmartBoxDataCanvasOcr = { this.props.fetchSmartBoxDataCanvasOcr }
                  alreadyRedactedKeys={this.props.alreadyRedactedKeys}
                  onGetDictionary={ this.onGetDictionary }
                  checkedChildBusiness={ checkedChildBusiness }
                  showVisibleDictionary={ showVisibleDictionary }
                  setTreeDataDict = { this.props.setTreeDataDict }
                  onStatusRemoveDictionaries = { this.onStatusRemoveDictionaries }
                  removeAllDictionaries = { removeAllDictionaries }
                  getAllChildrenBusiness = { getAllChildrenBusiness }
                  getStatusElementRedaction={ this.props.getStatusElementRedaction }
                  responseStatusQuery={ this.props.responseStatusQuery }
                  docAnnotationsBusiness={ this.props.docAnnotationsBusiness }
                  docAnnotationsBusinessOcr={ this.props.docAnnotationsBusinessOcr }
                  categoryColoursBusiness={ this.props.categoryColoursBusiness }
                  treeDataAnnotationsBusiness={ this.props.treeDataAnnotationsBusiness }
                  statusNextDocumentBusiness={ this.props.statusNextDocumentBusiness }
                  onGetStatusNextDocumentBusiness={ this.props.onGetStatusNextDocumentBusiness }
                  xeroxUpload={ this.props.xeroxUpload }
                  docAnnotationsBusinessXerox={this.props.docAnnotationsBusinessXerox}
                  clientId={this.props.clientId}
                  onGetCanvasPointsXeroxRedaction={ this.props.onGetCanvasPointsXeroxRedaction }
                  lockSmartViewChanges={ this.props.lockSmartViewChanges }
                  listCategories={ this.props.listCategories }
                  //onGetDictionaryOcrUpdate={ this.props.onGetDictionaryOcrUpdate }
              >

              </Business>
              }
          </div>
          <div className="component-reg-ex-emails">
              <span className={ Object.keys(regExListVersion) && Object.keys(regExListVersion).length === 0 ? "tab-update-reg-ex-empty" :
                  Object.keys(regExListVersion) && Object.keys(regExListVersion).length > 0 && alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegEx.length && !removeAllRegEx ? "tab-update-reg-ex tab-update-reg-ex-new-redact" :
                  Object.keys(regExListVersion) && Object.keys(regExListVersion).length > 0 && statusRemoveAllRegEx === false ? "tab-update-reg-ex tab-update-reg-ex-new-redact" :
                  Object.keys(regExListVersion) && Object.keys(regExListVersion).length > 0 && !visibleRedactRegEx && getAllChildrenRegEx.length > 0 ? "tab-update-reg-ex" :
                      Object.keys(regExListVersion) && Object.keys(regExListVersion).length > 0 && visibleRedactRegEx && getAllChildrenRegEx.length > 0 ? 'tab-update-reg-ex tab-update-reg-ex-redact' :
                          alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegEx.length ? 'tab-update-reg-ex tab-update-reg-ex-redact' :
                          alreadyRedactedKeys && alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegExUpdateParent.length ? 'tab-update-reg-ex tab-update-reg-ex-redact' :
                              'tab-update-reg-ex-empty' }
              onMouseLeave={() => this.onLeaveCategory()}
              onMouseEnter={(e) => this.onHoverCategory(e, 'regex')}>
                  <span className="content-reg-terms">
                   <span className={ visibleRedactRegEx && getAllChildrenRegEx && getAllChildrenRegEx.length > 0 ? 'icon-v17-redaction-hover' :
                       alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegEx.length && !removeAllRegEx ? 'icon-v17-redaction' :
                       alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0 && alreadyRedactedKeys.regex.length === getAllChildrenRegExUpdateParent.length && !removeAllRegEx ? 'icon-v17-redaction' :
                           statusRemoveAllRegEx === false ? 'icon-v17-redaction' : '' }
                   onClick={(e) => this.onRedactCategories(e, 'regex')}></span>
                  <span className="title-regEx" onClick={() => this.onDisplaySelectComponentAi('reg-ex')}>Regular Expressions</span>
                  { Object.keys(regExListVersion) && Object.keys(regExListVersion).length > 0 && getAllChildrenRegEx && getAllChildrenRegEx.length > 0 &&
                      <React.Fragment>
                          {
                              visibleRegExEmails ?
                                  <span className="icon-v21-arrow-bold" onClick={() => this.onDisplaySelectComponentAi('reg-ex')}/> :
                                  <span className="icon-v21-arrow-bold rotate-arrow-bold" onClick={() => this.onDisplaySelectComponentAi('reg-ex')}/>
                          }
                      </React.Fragment>
                  }
                  </span>
                  { Object.keys(regExListVersion) && Object.keys(regExListVersion).length > 0 && getAllChildrenRegEx && getAllChildrenRegEx.length > 0 &&
                  <React.Fragment>
                      { visibleRegExCat &&
                      <span className="content-visible-category">
                                  { !showVisibleRegEx ?
                                      <span className="icon-v12-visible" aria-hidden="true"
                                            onClick={(e) => this.onShowVisibleRegEx(e, true)}/> :
                                      <span className="icon-v12-unvisible" aria-hidden="true"
                                            onClick={(e) => this.onShowVisibleRegEx(e, false)}/>
                                  }
                              </span>
                      }
                  </React.Fragment>
                  }
              </span>
              { visibleRegExEmails &&
              <RegularExpressions
                  idDocument={ this.props.isNextDocumentId.id ? this.props.isNextDocumentId.id : this.props.idDocument }
                  isDocumentReady={ this.props.isDocumentReady }
                  documentId={ this.props.idDocument }
                  isSmartBoxApplication={ this.props.isSmartBoxApplication }
                  landscape={ this.props.landscape }
                  changePage={ this.props.changePage }
                  pageNumber={ this.props.pageNumber }
                  toggleLoadingProcess = { this.props.toggleLoadingProcess }
                  activeKeyTab = { activeKeyTab }
                  activeKey = { this.props.activeKey }
                  drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}
                  newListKeysRegEx={ this.props.newListKeysRegEx }
                  checkOnClearAllRegEx={ this.props.checkOnClearAllRegEx }
                  verificationOnClickClearAllRegEx={ this.props.verificationOnClickClearAllRegEx }
                  onCheckedKeysObjectRefsRegEx={ this.props.onCheckedKeysObjectRefsRegEx }
                  isNextDocumentId={ this.props.isNextDocumentId }
                  allChildrenTreeRegEx={ this.props.allChildrenTreeRegEx }
                  checkedKeysAllRegEx={ this.props.checkedKeysAllRegEx }
                  updateRedactAllStatus={ this.props.updateRedactAllStatus }
                  onDocAnnotationsRegEx={ this.props.onDocAnnotationsRegEx }
                  isOcr = { this.props.isOcr }
                  fetchSmartBoxDataCanvasOcr = { this.props.fetchSmartBoxDataCanvasOcr }
                  alreadyRedactedKeys={this.props.alreadyRedactedKeys}
                  onGetRegEx={ this.onGetRegEx }
                  onGetRegExVersion={ this.onGetRegExVersion }
                  checkedChildRegEx={ checkedChildRegEx }
                  showVisibleRegEx={ showVisibleRegEx }
                  setTreeDataRegEx = { this.props.setTreeDataRegEx }
                  onUnCheckedKeysRegEx={ this.onUnCheckedKeysRegEx }
                  removeAllRegEx={ removeAllRegEx }
                  onStatusRemoveRedaction={ this.onStatusRemoveRedaction }
                  getStatusElementRedaction={ this.props.getStatusElementRedaction }
                  docAnnotationsRegularExp={ this.props.docAnnotationsRegularExp }
                  docAnnotationsRegularExpOcr={ this.props.docAnnotationsRegularExpOcr }
                  categoryColoursRegularExp={ this.props.categoryColoursRegularExp }
                  treeDataAnnotationsRegEx={ this.props.treeDataAnnotationsRegEx }
                  statusNextDocumentRegEx={ this.props.statusNextDocumentRegEx }
                  onGetStatusNextDocumentRegEx={ this.props.onGetStatusNextDocumentRegEx }
                  getAllChildrenRegEx={ getAllChildrenRegEx }
                  xeroxUpload={ this.props.xeroxUpload }
                  docAnnotationsRegularXerox={this.props.docAnnotationsRegularXerox}
                  clientId={this.props.clientId}
                  onGetCanvasPointsXeroxRedaction={ this.props.onGetCanvasPointsXeroxRedaction }
                  lockSmartViewChanges={ this.props.lockSmartViewChanges }
                  listCategories={ this.props.listCategories }
              >
              </RegularExpressions>
              }
          </div>

          {/*<div className="component-manual-lists">*/}
          {/*    <span className="tab-update-lists" onClick={() => this.onDisplaySelectComponentAi('lists')}>*/}
          {/*        <span className="title-lists">Box terms</span>*/}
          {/*        {*/}
          {/*            visibleLists ?*/}
          {/*                <Icon type="down" /> :*/}
          {/*                <Icon type="up" />*/}
          {/*        }*/}
          {/*    </span>*/}
          {/*    { visibleLists &&*/}
          {/*    <ManualTerms*/}
          {/*        isDocumentReady={ this.props.isDocumentReady }*/}
          {/*        isSmartBoxApplication={ this.props.isSmartBoxApplication }*/}
          {/*        documentId={ this.props.documentId }*/}
          {/*        landscape={ this.props.landscape }*/}
          {/*        changePage={ this.props.changePage }*/}
          {/*        pageNumber={ this.props.pageNumber }*/}
          {/*        drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}*/}
          {/*        hideLoadingBeforeMark={this.props.hideLoadingBeforeMark}*/}
          {/*        newListKeysManual={ this.props.newListKeysManual }*/}
          {/*        verificationOnClickClearAllManual={ this.props.verificationOnClickClearAllManual }*/}
          {/*        checkOnClearAllManual={ this.props.checkOnClearAllManual }*/}
          {/*        idDocument={ this.props.isNextDocumentId.id ? this.props.isNextDocumentId.id : this.props.idDocument }*/}
          {/*        toggleLoadingProcess = { this.props.toggleLoadingProcess }*/}
          {/*        activeKeyTab = { activeKeyTab }*/}
          {/*        activeKey = { this.props.activeKey }*/}
          {/*        onCheckedKeysObjectRefsList={ this.props.onCheckedKeysObjectRefsList }*/}
          {/*        isNextDocumentId={ this.props.isNextDocumentId }*/}
          {/*    >*/}
          {/*    </ManualTerms>*/}
          {/*    }*/}
          {/*</div>*/}

        {/*<Tabs defaultActiveKey={ activeKeyTab } onChange={ this.onSelectActiveTabsOnManual }>*/}
        {/*  <TabPane tab="Manual" key="manualTerms">*/}
        {/*    <ManualTerms*/}
        {/*      isDocumentReady={ this.props.isDocumentReady }*/}
        {/*      isSmartBoxApplication={ this.props.isSmartBoxApplication }*/}
        {/*      documentId={ this.props.documentId }*/}
        {/*      landscape={ this.props.landscape }*/}
        {/*      changePage={ this.props.changePage }*/}
        {/*      pageNumber={ this.props.pageNumber }*/}
        {/*      drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}*/}
        {/*      hideLoadingBeforeMark={this.props.hideLoadingBeforeMark}*/}
        {/*      newListKeysManual={ this.props.newListKeysManual }*/}
        {/*      verificationOnClickClearAllManual={ this.props.verificationOnClickClearAllManual }*/}
        {/*      checkOnClearAllManual={ this.props.checkOnClearAllManual }*/}
        {/*      idDocument={ this.props.idDocument }*/}
        {/*      toggleLoadingProcess = { this.props.toggleLoadingProcess }*/}
        {/*      activeKeyTab = { activeKeyTab }*/}
        {/*      activeKey = { this.props.activeKey }*/}
        {/*    >*/}
        {/*    </ManualTerms>*/}
        {/*  </TabPane>*/}
        {/*  <TabPane tab="Business" key="business">*/}
        {/*    <Business*/}
        {/*      idDocument={ this.props.idDocument }*/}
        {/*      isSmartBoxApplication={ this.props.isSmartBoxApplication }*/}
        {/*      pageNumber={ this.props.pageNumber }*/}
        {/*      landscape={ this.props.landscape }*/}
        {/*      changePage={ this.props.changePage }*/}
        {/*      drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}*/}
        {/*      toggleLoadingProcess = { this.props.toggleLoadingProcess }*/}
        {/*      activeKeyTab = { activeKeyTab }*/}
        {/*      activeKey = { this.props.activeKey }*/}
        {/*      isDocumentReady={ this.props.isDocumentReady }*/}
        {/*      newListKeysBusiness={ this.props.newListKeysBusiness }*/}
        {/*      checkOnClearAllBusiness={ this.props.checkOnClearAllBusiness }*/}
        {/*      verificationOnClickClearAllBusiness={ this.props.verificationOnClickClearAllBusiness }*/}
        {/*    >*/}

        {/*    </Business>*/}
        {/*  </TabPane>*/}
        {/*  <TabPane tab="RegEx" key="regEx">*/}
        {/*    <RegularExpressions*/}
        {/*      idDocument={ this.props.idDocument }*/}
        {/*      isDocumentReady={ this.props.isDocumentReady }*/}
        {/*      documentId={ this.props.idDocument }*/}
        {/*      isSmartBoxApplication={ this.props.isSmartBoxApplication }*/}
        {/*      landscape={ this.props.landscape }*/}
        {/*      changePage={ this.props.changePage }*/}
        {/*      pageNumber={ this.props.pageNumber }*/}
        {/*      toggleLoadingProcess = { this.props.toggleLoadingProcess }*/}
        {/*      activeKeyTab = { activeKeyTab }*/}
        {/*      activeKey = { this.props.activeKey }*/}
        {/*      drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}*/}
        {/*      newListKeysRegEx={ this.props.newListKeysRegEx }*/}
        {/*      checkOnClearAllRegEx={ this.props.checkOnClearAllRegEx }*/}
        {/*      verificationOnClickClearAllRegEx={ this.props.verificationOnClickClearAllRegEx }*/}
        {/*    >*/}
        {/*    </RegularExpressions>*/}
        {/*  </TabPane>*/}
        {/*</Tabs>*/}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  userId: state.userReducer.user.id
})

ManualTab.propTypes = {
  clientId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isDocumentReady: PropTypes.bool.isRequired,  
  hideLoadingBeforeMark: PropTypes.func,
  toggleLoadingProcess: PropTypes.func
}

export default connect(mapStateToProps)(ManualTab)